import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { Card, Col, Row } from 'react-bootstrap';
import apiService from '../../apiService';
import { Formik } from 'formik';
import { showHistoricValue, showDayList } from '../../../redux/slices/historicalSlice';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';
import { showFilterList } from '../../../redux/slices/indicatorFilterSlice';

const ManualProcessControlChart = ({ idx, workCenter, variable, dimensionalUnit }) => {
  const now = useRef(new Date());
  const [from, setFrom] = useState(now.current);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dates, setDates] = useState([]);
  const [productFilter, setProductFilter] = useState({});
  const [nextDay, setNextDay] = useState();
  const [excelTableData, setExcelTableData] = useState([]);
  const [showTableData, setShowTableData] = useState(false);
  const [paginationCount, setPaginationCount] = useState(0);
  const filterList = useSelector(showFilterList);

  const filterString = `Filtered by work center ${workCenter}, variable ${variable}, dimensional unit ${dimensionalUnit}, ${
    filterList.from
  } ${filterList.to ? '- ' + filterList.to + ' (historic)' : '(daily)'}.`;

  // historical data
  const isHistoric = useSelector(showHistoricValue);
  const dayList = useSelector(showDayList);

  useEffect(() => {
    if (dayList.length >= 0) {
      const today = new Date(dayList[dayList.length - 1]);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      setNextDay(tomorrow);
    }
  }, [dayList]);

  useQuery({
    queryKey: ['manualProcessControl', workCenter, variable, from],
    queryFn: () =>
      apiService.getManualProcessControlFromDate(
        workCenter,
        variable,
        from.toISOString().split('T')[0]
      ),
    enabled: !isHistoric,
    refetchInterval: 15000,
    onSuccess: (data) => {
      setFilteredData(data);
    },
  });

  useQuery({
    queryKey: ['manualProcessControl', workCenter, variable, dayList],
    queryFn: () =>
      apiService.getManualProcessControlFromToDate(
        workCenter,
        variable,
        dayList[0],
        nextDay.toISOString().split('T')[0]
      ),
    enabled: isHistoric && !!nextDay,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setFilteredData(data);
    },
  });

  //Export data
  const dataToExport = () => {
    let dataToExport = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        dataToExport.push({
          timestamp: new Date(data[i][0]).toLocaleString(),
          work_center: workCenter,
          variable: variable,
          dimensional_unit: dimensionalUnit,
          value: data[i][1],
          production_order: data[i][3],
          product: data[i][2],
        });
      }
      setExcelTableData(dataToExport);
      setPaginationCount(dataToExport.length);
    }
  };

  useEffect(() => {
    dataToExport();
  }, [data]);

  function generateDayWiseTimeSeries() {
    var series = [];
    filteredData.map((item) => {
      var x = item.end_date;
      var y = item.value;
      var z = item.product;
      var op = item.production_order;
      series.push([x, y, z, op]);
    });
    return series;
  }

  function listUniqueProps(obj, prop) {
    return obj.reduce(function (acc, item) {
      let key = item[prop];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  }

  function getDailyData(data) {
    var currentDate = new Date().toISOString().split('T', 1)[0];
    var currentData = [];
    for (let i in data) {
      var date = data[i][0].split('T', 1)[0];
      date == currentDate ? currentData.push(data[i]) : null;
    }
    return currentData;
  }

  function getRangeData(data) {
    var minDate = new Date(dayList[0]);
    minDate.setHours(0, 0, 0, 0);
    minDate.setDate(minDate.getDate() + 1);
    var maxDate = new Date(dayList[dayList.length - 1]);
    maxDate.setHours(23, 59, 59, 999);
    maxDate.setDate(maxDate.getDate() + 1);
    var dateData = [];
    for (let day in data) {
      let date = new Date(data[day][0]);
      date >= minDate && date <= maxDate ? dateData.push(data[day]) : null;
    }
    return dateData;
  }

  useEffect(() => {
    if (filteredData.length !== 0) {
      const onlyDates = filteredData.map((item) => {
        return item.end_date;
      });
      setDates(onlyDates);
    }
  }, [filteredData]);

  useEffect(() => {
    if (filteredData.length !== 0) {
      const data = generateDayWiseTimeSeries();
      isHistoric ? setData(getRangeData(data)) : setData(getDailyData(data));
      let grouped = listUniqueProps(filteredData, 'product');
      setProductFilter(Object.keys(grouped));
    }
  }, [filteredData]);

  const series = [
    {
      data: data,
    },
  ];

  const options = {
    chart: {
      id: 'chart2' + idx,
      type: 'area',
      foreColor: '#000',
      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
    },
    colors: ['#00BAEC'],
    stroke: {
      width: 3,
    },
    grid: {
      borderColor: '#d8d8d8',
      clipMarkers: false,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.9,
        opacityTo: 0,
      },
    },
    markers: {
      size: 3,
      colors: ['#000524'],
      strokeColor: '#00BAEC',
      strokeWidth: 2,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (end_date) {
          return new Date(end_date).toLocaleTimeString();
        },
      },
    },
    yaxis: {
      title: {
        text: `${variable} (${dimensionalUnit})`,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box m-2">' +
          '<h5>' +
          new Date(w.globals.initialSeries[0].data[dataPointIndex][0]).toString().substring(0, 34) +
          '</h5>' +
          '<p>' +
          variable +
          ': ' +
          '<b>' +
          series[seriesIndex][dataPointIndex] +
          ' ' +
          dimensionalUnit +
          '</b>' +
          '</p>' +
          '<p>Producto: ' +
          '<b>' +
          w.globals.initialSeries[0].data[dataPointIndex][2] +
          '</b>' +
          '</p>' +
          '<p>Production Order: ' +
          '<b>' +
          w.globals.initialSeries[0].data[dataPointIndex][3] +
          '</b>' +
          '</p>' +
          '</div>'
        );
      },
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
  };

  const seriesLine = [
    {
      data: data,
    },
  ];
  const optionsLine = {
    chart: {
      id: 'chart1' + idx,
      height: 130,
      type: 'line',
      foreColor: '#000',
      brush: {
        target: 'chart2' + idx,
        enabled: true,
        autoScaleYaxis: true,
      },
      selection: {
        enabled: true,
        xaxis: {
          max: new Date(dates[dates.length - 1]).getTime(),
        },
      },
    },
    colors: ['#FF0080'],
    stroke: {
      width: 2,
    },
    grid: {
      borderColor: '#d8d8d8',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (end_date) {
          return new Date(end_date).toLocaleString();
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      tickAmount: 2,
    },
  };

  const handleCloseModal = () => {
    setShowTableData(false);
  };

  return (
    <Formik validateOnChange={true}>
      <>
        <Card Card className="p-3 my-2">
          <Card.Body>
            <Row className="px-0 pb-3 d-flex ">
              <h4 className="col-auto mr-auto">
                {workCenter} | {variable} | {dimensionalUnit}
              </h4>
              <Dropdown as={ButtonGroup} className="col-auto">
                <Dropdown.Toggle className="fpb-btn-chart mb-3" id="dropdown-download" />
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setShowTableData(true);
                    }}
                  >
                    Show Data
                  </Dropdown.Item>
                  <ExportExcel
                    excelData={excelTableData}
                    fileName={
                      'Manual Process Control Report (' + filterList[0] + ' -' + filterList[1] + ')'
                    }
                    indicator="Manual Process Control"
                    filters={filterString}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Row>
            <SimpleTableModal
              title={'Manual Process Control'}
              headers={[
                'timestamp',
                'work_center',
                'variable',
                'dimensional_unit',
                'value',
                'production_order',
                'product',
              ]}
              tableData={excelTableData}
              show={showTableData}
              handleClose={handleCloseModal}
              totalCount={paginationCount}
            />
            <div id="wrapper">
              <div id={'chart-line2' + idx}>
                <Chart options={options} series={series} type="line" height={320} />
              </div>
              <div id={'chart-line' + idx}>
                <Chart options={optionsLine} series={seriesLine} type="area" height={130} />
              </div>
            </div>
          </Card.Body>
        </Card>
      </>
    </Formik>
  );
};

export default ManualProcessControlChart;
