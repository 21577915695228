import { useState, useRef } from 'react';
import axios from 'axios';
import FPBTableSimple from '../../FPBTable/FPBTableSimple';
import Urls from '../../Urls';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { BsPlusCircleFill } from 'react-icons/bs';
import { SpinnerWithText } from '../../Loading';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const DataAnalysis = () => {
  const [token] = useState(localStorage.getItem('accessToken'));
  const [filterSubtitle] = useState(localStorage.getItem('filterSubtitle'));
  const [transactionFilters] = useState(localStorage.getItem('transactionQuery').replace('&', '?'));
  const [plusClicked, setPlusClicked] = useState(false);
  const url = Urls.compute;
  const [tableData, setTableData] = useState(null);
  const [tableHeaders, setTableHeaders] = useState(null);
  const [showGroups, setShowGroups] = useState(false);
  const [checkFilters, setCheckFilters] = useState({});
  const [groupFilters, setGroupFilters] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [firstGroupSelected, setFirstGroupSelected] = useState(null);
  const [secondGroupSelected, setSecondGroupSelected] = useState(null);
  const [columnsToHide, setColumnsToHide] = useState([]);
  const firstCheck = useRef(null);
  const secondCheck = useRef(null);
  const thirdCheck = useRef(null);
  const fourthCheck = useRef(null);

  const groupByOption = [
    { value: 'item', label: 'Item' },
    { value: 'batch', label: 'Batch' },
    { value: 'storage_center', label: 'Storage Center' },
    { value: 'location', label: 'Location' },
    { value: 'child_category', label: 'Child Category' },
    { value: 'parent_category', label: 'Parent Category' },
    { value: 'customer', label: 'Customer' },
    { value: 'supplier', label: 'Supplier' },
  ];

  const FilterSubtitle = () => (
    <p style={{ fontSize: '12px' }} className="text-secondary">
      Filtered by: {filterSubtitle}
    </p>
  );

  const onChangeFirstCheck = () => {
    secondCheck.current.checked = false;
    thirdCheck.current.checked = false;
    fourthCheck.current.checked = false;
    if (firstCheck.current.checked) {
      setShowGroups(true);
      setCheckFilters({ compute_type: 'total' });
    } else {
      setShowGroups(false);
      setDisableSubmit(true);
      setCheckFilters({});
    }
  };
  const onChangeSecondCheck = () => {
    firstCheck.current.checked = false;
    thirdCheck.current.checked = false;
    fourthCheck.current.checked = false;
    if (secondCheck.current.checked) {
      setShowGroups(true);
      setCheckFilters({ compute_type: 'entry' });
    } else {
      setShowGroups(false);
      setDisableSubmit(true);
      setCheckFilters({});
    }
  };
  const onChangeThirdCheck = () => {
    firstCheck.current.checked = false;
    secondCheck.current.checked = false;
    fourthCheck.current.checked = false;
    if (thirdCheck.current.checked) {
      setShowGroups(true);
      setCheckFilters({ compute_type: 'output' });
    } else {
      setShowGroups(false);
      setDisableSubmit(true);
      setCheckFilters({});
    }
  };
  const onChangeFourthCheck = () => {
    firstCheck.current.checked = false;
    secondCheck.current.checked = false;
    thirdCheck.current.checked = false;
    if (fourthCheck.current.checked) {
      setShowGroups(true);
      setCheckFilters({ compute_type: 'return' });
    } else {
      setShowGroups(false);
      setDisableSubmit(true);
      setCheckFilters({});
    }
  };

  const onChangeGroupSelect = (selectedOption) => {
    if (selectedOption) {
      setFirstGroupSelected(selectedOption.value);
      setDisableSubmit(false);
      setGroupFilters({ group_by: selectedOption.value });
    } else {
      setDisableSubmit(true);
      setGroupFilters({});
      setFirstGroupSelected(null);
      setPlusClicked(false);
    }
  };

  const onChangeSecondGroupSelect = (selectedOption) => {
    if (selectedOption) {
      setSecondGroupSelected(selectedOption.value);
      setGroupFilters({ ...groupFilters, group_by_2: selectedOption.value });
    } else {
      setSecondGroupSelected(null);
      setGroupFilters({ group_by: firstGroupSelected });
    }
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    let filter = '';
    Object.keys(checkFilters).forEach((key) => {
      if (checkFilters[key] !== '') {
        filter = `${filter}&${key}=${encodeURIComponent(checkFilters[key])}`;
      }
    });
    Object.keys(groupFilters).forEach((key) => {
      if (groupFilters[key] !== '') {
        filter = `${filter}&${key}=${encodeURIComponent(groupFilters[key])}`;
      }
    });
    let queryString = '';
    if (transactionFilters !== '') {
      queryString = `${url}${transactionFilters}${filter}`;
    } else {
      filter = filter.replace('&', '?');
      queryString = `${url}${filter}`;
    }
    axios
      .get(queryString, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data) {
          const headersList = () => {
            const firstEntry = Object.keys(res.data)[0];
            const result = Object.keys(res.data[firstEntry]);
            return result;
          };
          const tableInformation = () => {
            let result = [];
            Object.keys(res.data).forEach((key) => {
              result.push(res.data[key]);
            });
            return result;
          };
          setTableHeaders(headersList());
          setTableData(tableInformation());
          setIsLoading(false);
          setDataLoaded(true);
        } else {
          setTableData([]);
          setTableHeaders([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (secondGroupSelected) {
      setColumnsToHide([
        'storage_center_description',
        'storage_location_description',
        'item_description',
        'batch_description',
        'item_category_description',
        'parent_category_description',
        'customer_type',
        'company_name',
        'supplier_type',
      ]);
    } else {
      setColumnsToHide([]);
    }
  };

  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'report' + fileExtension);
  };

  return (
    <>
      <HeaderTitle title="Data Analysis" />
      <h4>Select Computation Options</h4>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Group className="mb-3 ml-4" controlId="totalComputation">
            <Form.Check
              type="switch"
              label="Total Stock Level"
              ref={firstCheck}
              onChange={onChangeFirstCheck}
            />
          </Form.Group>
          <Form.Group className="mb-4 ml-4" controlId="entryComputation">
            <Form.Check
              type="switch"
              label="Entry Stock Level"
              ref={secondCheck}
              onChange={onChangeSecondCheck}
            />
          </Form.Group>
          <Form.Group className="mb-4 ml-4" controlId="outputComputation">
            <Form.Check
              type="switch"
              label="Ouput Stock Level"
              ref={thirdCheck}
              onChange={onChangeThirdCheck}
            />
          </Form.Group>
          <Form.Group className="mb-4 ml-4" controlId="returnedComputation">
            <Form.Check
              type="switch"
              label="Returned and Pending"
              ref={fourthCheck}
              onChange={onChangeFourthCheck}
            />
          </Form.Group>
        </Row>
        {showGroups ? (
          <Row>
            <Col sm={3}>
              <Form.Label>Group By</Form.Label>
              <Select
                className="mb-3"
                isClearable={true}
                options={groupByOption}
                onChange={onChangeGroupSelect}
              />
            </Col>

            {firstGroupSelected ? (
              plusClicked ? null : (
                <BsPlusCircleFill
                  className="align-self-center mt-3 btn-svg"
                  onClick={() => setPlusClicked(true)}
                />
              )
            ) : null}
            {plusClicked ? (
              <Col sm={3}>
                <Form.Label>Second Group</Form.Label>
                <Select
                  key={JSON.stringify(firstGroupSelected)}
                  className="mb-3"
                  isClearable={true}
                  options={groupByOption.filter((option) => {
                    if (firstGroupSelected === 'storage_center') {
                      return option.value !== firstGroupSelected && option.value !== 'location';
                    } else if (firstGroupSelected === 'location') {
                      return (
                        option.value !== firstGroupSelected && option.value !== 'storage_center'
                      );
                    } else {
                      return option.value !== firstGroupSelected;
                    }
                  })}
                  onChange={onChangeSecondGroupSelect}
                />
              </Col>
            ) : null}
            <Col xs={3}>
              <Form.Group className="mb-3" controlId="selectGroup">
                <Form.Label></Form.Label>
              </Form.Group>
            </Col>
          </Row>
        ) : null}
        <Button className="fpb-btn mb-3" type="submit" disabled={disableSubmit}>
          Run Analysis
        </Button>
        {dataLoaded ? (
          <Button className="fpb-btn-inverse mb-3 ml-3" onClick={exportToExcel}>
            Export (.xlsx)
          </Button>
        ) : null}
      </Form>
      {isLoading ? (
        <Row className="justify-content-center" style={{ 'margin-top': '100px' }}>
          <SpinnerWithText text="Loading Data..." />
        </Row>
      ) : dataLoaded ? (
        <>
          <FilterSubtitle />
          <FPBTableSimple
            tableHeaders={tableHeaders}
            tableData={tableData}
            columnsToHide={columnsToHide}
          />
        </>
      ) : null}
    </>
  );
};

export default DataAnalysis;
