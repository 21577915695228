import React, { useEffect } from 'react';
import IotProcessControlChart from '../IndustrialGraph/IotProcessControlChart';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import FiltersIOTControl from '../FiltersIOTControl';
import { setHistoric } from '../../../redux/slices/historicalSlice';
import { useSelector, useDispatch } from 'react-redux';
import { showSelectedWorkCenterData } from '../../../redux/slices/indicatorFilterSlice';

const IotProcessControl = () => {
  const workCenters = useSelector(showSelectedWorkCenterData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHistoric(false));
  }, [dispatch]);

  return (
    <>
      <HeaderTitle title="IoT Process Control " />
      <FiltersIOTControl />
      {workCenters.length !== 0
        ? workCenters.map((element) => (
            <div className=" pr-4 py-3" key={element.id}>
              <IotProcessControlChart
                key={element.id}
                workCenter={element.work_center}
                product={element.product}
                variable={element.variable}
                dimensionalUnit={element.entry_dimensional_unit}
                idx={element.id}
              />
            </div>
          ))
        : null}
    </>
  );
};

export default IotProcessControl;
