import { useState, useEffect } from 'react';
import axios from 'axios';
import Urls from '../components/Urls';
import { useSelector } from 'react-redux';

export const useFetchVariableFromIoT = (reportType) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('accessToken');
  const currentWorkCenter = useSelector((state) => state.workCenters.currentWorkCenter);
  const currentWorkCenterHasIoT = useSelector((state) => state.workCenters.currentWorkCenterHasIoT);

  const fetchVariablesFromIoT = async () => {
    await axios
      .get(
        `${Urls.producedUnitsInTameRange}?work_center=${currentWorkCenter.code}&report=${reportType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchVariablesFromIoT();
    const interval = setInterval(() => {
      // disable refetch if workcenter has no IoT
      if (currentWorkCenterHasIoT) {
        fetchVariablesFromIoT();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return { data, loading, error };
};
