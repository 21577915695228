import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';
import Urls from '../Urls';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import apiService from '../apiService';
import InventoryBanner from './InventoryBanner';
import FilterSelect from './FilterSelect';
import RealtimeChart from './Graph/RealtimeChart';

const RealtimeDashboard = () => {
  const [token] = useState(localStorage.getItem('accessToken') || null);
  const [excelTableData, setExcelTableData] = useState([]);

  // graph information
  const [cashData, setCashData] = useState([]);
  const [stockData, setStockData] = useState([]);

  // banner
  const [totalCash, setTotalCash] = useState();
  const [totalStock, setTotalStock] = useState();
  const [storageCenterCodes, setStorageCenterCodes] = useState([]);
  const [lastTransaction, setLastTransaction] = useState();
  const [itemUnit, setItemUnit] = useState('');

  // filters
  const [itemFiltered, setItemFiltered] = useState(false);
  const [filtersSubtitle, setFiltersSubtitle] = useState('total');
  const [filterSelected, setFilterSelected] = useState({
    label: 'Total',
    name: 'total',
    endpoint: `${Urls.stockPerStorageCenter}`,
  });
  const [filterCode, setFilterCode] = useState();
  const [filterValue, setFilterValue] = useState();
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [clear, setClear] = useState(false);

  const filterObjects = [
    {
      label: 'Total',
      name: 'total',
      endpoint: `${Urls.currentStockPerStorageCenter}`,
    },
    {
      label: 'Item',
      name: 'item',
      endpoint: `${Urls.currentStockPerBatch}?item`,
      code: 'reference_code',
    },
    {
      label: 'Storage Center',
      name: 'storageCenter',
      endpoint: `${Urls.currentStockPerBatch}?storage_center`,
      code: 'storage_center_code',
    },
    {
      label: 'Location',
      name: 'storageLocation',
      endpoint: `${Urls.currentStockPerBatch}?location`,
      code: 'location_code',
    },
    {
      label: 'Batch',
      name: 'batch',
      endpoint: `${Urls.currentStockPerBatch}?batch`,
      code: 'batch_code',
    },
    {
      label: 'Parent Category',
      name: 'itemCategory',
      secondaryName: 'parentCategory',
      endpoint: `${Urls.currentStockPerBatch}?parent_category`,
      code: 'item_category_code',
    },
    {
      label: 'Child Category',
      name: 'itemCategory',
      secondaryName: 'childCategory',
      endpoint: `${Urls.currentStockPerBatch}?child_category`,
      code: 'item_category_code',
    },
  ];

  const [filterData, setFilterData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const Style = {
    banner: {
      display: 'inline-block',
      boxShadow: '2px 1px 5px #ececec',
    },
  };

  // unit for the stock banner when item is selected in the filters
  const configureItemUnit = async () => {
    const itemResponse = await axios.get(`${Urls.item}/${filterValue}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const consolidationLevelN0 = itemResponse.data.consolidation_level_N0;
    const dimensionalUnitResponse = await axios.get(
      `${Urls.dimensionalUnit}/${consolidationLevelN0}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setItemUnit(dimensionalUnitResponse.data.description);
  };

  useEffect(() => {
    if (filterSelected.name === 'total') {
      setFiltersSubtitle('Filtered by total');
    } else {
      setFiltersSubtitle(`Filtered by ${filterSelected.name} = ${filterCode} `);
    }
  }, [isFiltered]);

  // request and configure indicator information
  useEffect(() => {
    if (filterSelected.name === 'total') {
      axios
        .get(`${Urls.currentStockPerStorageCenter}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStorageCenterCodes(res.data?.storage_centers);
          setTotalCash(res.data.total_cash);
          setCashData(res.data?.series);
        });
    }
  }, [isFiltered]);

  // request for batch, child category and parent category filters
  useEffect(() => {
    const url = `${filterSelected.endpoint}=${filterValue}`;
    if (typeof storageCenterCodes !== 'undefined') {
      if (
        filterSelected.name === 'batch' ||
        filterSelected.secondaryName === 'childCategory' ||
        filterSelected.secondaryName === 'parentCategory'
      ) {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setStorageCenterCodes(res.data?.storage_centers);
            setTotalCash(res.data?.total_cash);
            setCashData(res.data?.series);
          });
      }
    }
  }, [isFiltered]);

  useEffect(() => {
    if (filterSelected.name === 'item') {
      setItemFiltered(true);
    } else {
      setItemFiltered(false);
    }
  }, [isFiltered]);

  // request for specific storage center or storage location
  useEffect(async () => {
    if (filterSelected.name === 'storageCenter') {
      axios
        .get(`${filterSelected.endpoint}=${filterValue}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTotalCash(res.data?.total_cash);
          setCashData(res.data?.series);
          setStorageCenterCodes(res.data?.storage_locations);
        });
    } else if (filterSelected.name === 'storageLocation') {
      const url = `${filterSelected.endpoint}=${filterValue}`;
      const locationData = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalCash(locationData.data?.total_cash);
      setCashData(locationData.data?.series);
      setStorageCenterCodes(locationData.data?.items);
    }
  }, [isFiltered]);

  // request for item filter
  useEffect(() => {
    if (filterSelected.name === 'item') {
      const url = `${filterSelected.endpoint}=${filterValue}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTotalCash(res.data?.cash.total_cash);
          setTotalStock(res.data?.stock.total_stock);
          setCashData(res.data?.cash.series);
          setStockData(res.data?.stock.series);
          setStorageCenterCodes(res.data?.storage_centers);
          configureItemUnit();
        });
    }
  }, [isFiltered]);

  // fill select with filter codes
  useEffect(async () => {
    if (filterSelected.secondaryName === 'childCategory') {
      setClear(!clear);
      setSelectedOption('');
      const res = await apiService.getChildCategories();
      setFilterData({
        headers: res.headersList,
        tableData: res.data,
        options: res.results,
      });
    } else if (filterSelected.secondaryName === 'parentCategory') {
      setClear(!clear);
      setSelectedOption('');
      const res = await apiService.getParentCategories();
      setFilterData({
        headers: res.headersList,
        tableData: res.data,
        options: res.results,
      });
    } else if (filterSelected.name !== '' && filterSelected.name !== 'total') {
      setClear(!clear);
      setSelectedOption('');
      const res = await apiService.getData(Urls[filterSelected.name], filterSelected.code);
      setFilterData({
        headers: res.headersList,
        tableData: res.data,
        options: res.results,
      });
    } else {
      setFilterValue();
      setSelectedOption('');
      setFilterData({
        headers: [],
        tableData: [],
        options: [],
      });
    }
  }, [filterSelected]);

  // configure last transaction
  useEffect(async () => {
    const res = await axios.get(Urls.transaction, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const lastTransactionDate = res.data.results[0].transaction_date.split('T', 1)[0];
    const lastTransactionHour = res.data.results[0].transaction_date.split('T')[1].split('.')[0];
    setLastTransaction(`${lastTransactionDate} (${lastTransactionHour})`);
  }, []);

  const handleApplyFilter = () => {
    setIsFiltered(!isFiltered);
  };

  return (
    <div>
      <div>
        <HeaderTitle title="Real Time Dashboard" />
      </div>
      <Form.Group as={Row} className="ml-2">
        <FilterSelect
          filterSelected={filterSelected}
          filterObjects={filterObjects}
          selectedOption={selectedOption}
          filterData={filterData}
          setFilterSelected={setFilterSelected}
          setFilterValue={setFilterValue}
          setFilterCode={setFilterCode}
          setSelectedOption={setSelectedOption}
        />
        <Button className="fpb-btn" onClick={handleApplyFilter}>
          Apply Filters
        </Button>
      </Form.Group>
      <Row>
        <Col>
          <div className="px-4 py-3 mt-3 card" style={Style.banner}>
            <h4 style={{ color: '#082d45' }}>Inventory value (cash)</h4>
            <InventoryBanner total={totalCash} />
            <h6>
              <span className="text-secondary"> Last Transaction: </span>
              {lastTransaction}
            </h6>
          </div>
        </Col>
      </Row>
      <Row className="pr-3">
        <Col>
          <RealtimeChart
            indicatorName={'Realtime'}
            data={cashData}
            labels={storageCenterCodes}
            title={'INVENTORY VALUE BY STORAGE CENTER (CASH) '}
            subtitle={filtersSubtitle}
          />
        </Col>
      </Row>
      <Row>
        {itemFiltered && (
          <Col>
            <div className="px-4 py-3 mt-3 card" style={Style.banner}>
              <h4 style={{ color: '#082d45' }}>Inventory value (stock)</h4>
              <InventoryBanner total={totalStock} itemUnit={itemUnit} />

              <h6>
                <span className="text-secondary"> Last Transaction: </span>
                {lastTransaction}
              </h6>
            </div>
          </Col>
        )}
      </Row>
      <Row className="pr-3">
        {itemFiltered && (
          <Col>
            <RealtimeChart
              indicatorName={'Realtime'}
              data={stockData}
              labels={storageCenterCodes}
              title={'INVENTORY VALUE BY STORAGE CENTER (STOCK) '}
              subtitle={filtersSubtitle}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RealtimeDashboard;
