import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';
import { CompanySelection, ModuleSelection } from '../SelectionComponents';

const companyModule = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});

  const moduleValidate = Yup.object({
    company: Yup.string().required('Required'),
    module: Yup.string().required('Required'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={moduleValidate}
        onSubmit={async (data) => {
          const processedValues = {
            company: data.company,
            module: data.module,
          };
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          if (data.id == null) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'companyModule',
              code: data.nameModule,
            });

            if (created.statusText !== 'Created') {
              notification('Error creating item - duplicate item', 'error');
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'companyModule',
              code: data.nameModule,
            });
            if (updated.statusText == 'OK') {
              onEdited(processedValues);
              setAddForm(false);
              notification('Item updated successfully', 'success');
            } else {
              notification('Error updating item - duplicate item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.id,
          company: values.company_name || '',
          module: values.module_name || '',
        }}
      >
        {({ values, touched, handleBlur, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <CompanySelection
              onChange={(option) => {
                setFieldValue('company', option ? option.value : '');
              }}
              name="company"
              defaultValue={{
                value: values.company,
                label: values.company,
              }}
              value={values.CompanySelection}
            />

            <ModuleSelection
              onChange={(option) => {
                setFieldValue('module', option ? option.value : '');
              }}
              name="module"
              defaultValue={{
                value: values.module,
                label: values.module,
              }}
              value={values.ModuleSelection}
            />

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default companyModule;
