import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';
import Urls from '../Urls';

const EventType = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [descriptions, setDescriptions] = useState({ eventCategoryDescription: '' });
  const [eventCategoryData, setEventCategoryData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const eventTypeValidate = Yup.object({
    code: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string().required('Required'),
    eventCategory: Yup.string(),
    planned: Yup.string().required('Required'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(async () => {
    try {
      const resCategory = await apiService.getData(Urls.eventCategory, 'code');
      setEventCategoryData({
        headers: resCategory.headersList,
        tableData: resCategory.data,
        options: resCategory.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    eventCategoryData.options?.forEach((option) => {
      console.log(values);
      if (option.value === values.event_category) {
        setDescriptions(
          option
            ? { ...descriptions, eventCategoryDescription: option.description }
            : { ...descriptions, eventCategoryDescription: '' }
        );
      }
    });
  }, [eventCategoryData]);

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={eventTypeValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            code: data.code,
            description: data.description,
            planned: data.planned,
            event_category: data.eventCategory || null,
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
          };
          setProcessedFormValues(processedValues);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Event type',
              code: data.code,
            });
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Event type',
              code: data.code,
            });
            if (updated.statusText == 'OK') {
              setAddForm(false);
              onEdited(processedValues);
            }
          }
        }}
        initialValues={{
          id: values.code,
          code: values.code || '',
          description: values.description || '',
          planned: values.planned || '',
          eventCategory: values.event_category || '',
          createdBy: values.created_by || '',
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField label="Event Type Code" name="code" type="string" value={values.code} />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('eventCategory', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, eventCategoryDescription: option.description }
                      : { ...descriptions, eventCategoryDescription: '' }
                  );
                }}
                label="Event Category Code"
                name="eventCategory"
                options={eventCategoryData.options}
                defaultValue={{
                  value: values.eventCategory,
                  label: values.eventCategory,
                }}
                description={descriptions.eventCategoryDescription}
                value={{
                  value: values.eventCategory,
                  label: values.eventCategory,
                }}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('planned', option ? option.value : '');
                }}
                label="Planned"
                name="planned"
                options={[
                  { value: 'yes', label: 'yes' },
                  { value: 'no', label: 'no' },
                ]}
                defaultValue={{ value: values.planned, label: values.planned }}
              />
            </Form.Row>
            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EventType;
