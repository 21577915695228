import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';
import Urls from '../../components/Urls';
import * as FaIcons from 'react-icons/fa';
import axios from 'axios';

const ChangePassword = () => {
  const changepass = Urls.change_password;
  const token = localStorage.getItem('accessToken') || null;
  const iconEye = <FaIcons.FaEye size={25} />;
  const iconEyeSlash = <FaIcons.FaEyeSlash size={25} />;
  const [iconNew, setIconNew] = useState(iconEyeSlash);
  const [typeNew, setTypeNew] = useState('password');
  const [iconConfirm, setIconConfirm] = useState(iconEyeSlash);
  const [typeConfirm, setTypeConfirm] = useState('password');
  const [iconCurrent, setIconCurrent] = useState(iconEyeSlash);
  const [typeCurrent, setTypeCurrent] = useState('password');
  const urlLogout = Urls.logout;
  const refreshToken = localStorage.getItem('refreshToken');
  const accessToken = localStorage.getItem('accessToken');

  const logout = () => {
    axios
      .post(
        urlLogout,
        { refresh: refreshToken },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(() => {
        localStorage.clear();
        window.location.href = '/';
      })
      .catch(() => {
        localStorage.clear();
        window.location.href = '/';
      });
  };

  const passwordsValidate = Yup.object({
    old_password: Yup.string().required('Required'),
    new_password: Yup.string().required('Required'),
    confirm_new_password: Yup.string().required('Required'),
  });

  const handleToggle = () => {
    if (typeNew === 'password') {
      setIconNew(iconEye);
      setTypeNew('text');
    } else {
      setIconNew(iconEyeSlash);
      setTypeNew('password');
    }
  };
  const handleToggleConfirm = () => {
    if (typeConfirm === 'password') {
      setIconConfirm(iconEye);
      setTypeConfirm('text');
    } else {
      setIconConfirm(iconEyeSlash);
      setTypeConfirm('password');
    }
  };

  const handleToggleCurrent = () => {
    if (typeCurrent === 'password') {
      setIconCurrent(iconEye);
      setTypeCurrent('text');
    } else {
      setIconCurrent(iconEyeSlash);
      setTypeCurrent('password');
    }
  };

  const updatePassword = async (updatedPass) => {
    return await fetch(`${changepass}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        old_password: String(updatedPass.old_password).trim(),
        new_password: String(updatedPass.new_password).trim(),
        confirm_new_password: String(updatedPass.confirm_new_password).trim(),
      }),
    });
  };

  const redirectLogin = () => {
    setTimeout(() => {
      logout();
    }, 3000);
  };

  return (
    <Formik
      validationSchema={passwordsValidate}
      onSubmit={async (data) => {
        console.log(data);
        const processedValues = {
          old_password: data.old_password,
          new_password: data.new_password,
          confirm_new_password: data.confirm_new_password,
        };
        try {
          let res;
          res = await updatePassword(processedValues);
          const data = await res.json();
          if (data.status === 'success') {
            redirectLogin();
            notification('Password successfully changed', 'success');
          } else if (data.status === 'error') {
            notification('New passwords dont match', 'warning');
          } else if (data.status === 'error_pass') {
            notification('The password entered does not match the current password.', 'warning');
          } else if (data.error === 'passwordValidate') {
            notification(data.message, 'warning');
          }
        } catch (error) {
          console.log(error);
        }
      }}
      initialValues={{
        old_password: '',
        new_password: '',
        confirm_new_password: '',
      }}
    >
      {({ values, touched, handleBlur, handleSubmit }) => (
        <div
          className="row d-flex align-items-center"
          style={{ backgroundColor: '#FFFFFF', height: '50vh' }}
        >
          <div
            className="col-6 col offset-3 card-profile shadow p-5"
            style={{
              backgroundColor: '#E4E4E4',
              borderRadius: '10px',
            }}
          >
            <Form className="text-left" onSubmit={handleSubmit}>
              <Form.Row>
                <TextField
                  label="Current password"
                  name="old_password"
                  type={typeCurrent}
                  value={values.old_password}
                />
                <span className="currentpassword-toogle-icon" onClick={handleToggleCurrent}>
                  {iconCurrent}
                </span>
              </Form.Row>
              <Form.Row>
                <TextField
                  label="New Password"
                  name="new_password"
                  type={typeNew}
                  value={values.new_password}
                />
                <span className="password-toogle-icon" onClick={handleToggle}>
                  {iconNew}
                </span>
              </Form.Row>
              <Form.Row>
                <TextField
                  label="Confirm New Password"
                  name="confirm_new_password"
                  type={typeConfirm}
                  value={values.confirm_new_password}
                />
                <span className="password-toogle-icon2" onClick={handleToggleConfirm}>
                  {iconConfirm}
                </span>
              </Form.Row>
              <Button className="fpb-btn mt-3" type="submit">
                Change
              </Button>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ChangePassword;
