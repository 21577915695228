import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Container, Alert } from 'react-bootstrap';
import * as BsIcons from 'react-icons/bs';
import axios from 'axios';
import Papa from 'papaparse';
import urls from '../Urls';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import templatesUrls from './templatesUrls';
import { GiCancel } from 'react-icons/gi';
import { ProgressBar } from 'react-bootstrap';
import csvIcon from './csvIcon.png';

const urlUpload = urls.upload;
const fileDownload = require('js-file-download');

let fileNameDownload = '';

const IndustrialTemplatesView = ({ title }) => {
  const [parsedFile, setParsedFile] = useState();
  const [fileLength, setFileLength] = useState(0);
  const [successNumber, setSuccessNumber] = useState(0);
  const [errorNumber, setErrorNumber] = useState(0);
  const [progress, setProgress] = useState(0);
  const [postStarted, setPostStarted] = useState(false);
  const [shortFileName, setShortFileName] = useState('');
  const [uploadDone, setUploadDone] = useState(false);
  const [fileName, setFileName] = useState('');
  const [buttonState, setButtonState] = useState(true);
  const [extraSpace, setExtraSpace] = useState(3);
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [messages, setMessages] = useState([]);
  const [showMessages, setShowMessages] = useState(false);

  const userEmail = userProfile.email ? userProfile.email : '';

  const pageTitle = `Table Name: ${title}`;
  const accessToken = localStorage.getItem('accessToken');

  const header = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const uploadFile = () => {
    const uploadData = new FormData();
    uploadData.append('title', 'Test title');
    uploadData.append('file', fileName);
    axios
      .post(urlUpload, uploadData, header)
      .then((res) => {
        setButtonState(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const newFile = (file) => {
    const csvFile = file.target.files[0];
    setPostStarted(false);
    setSuccessNumber(0);
    setErrorNumber(0);
    setFileName(file.target.value);
    setShortFileName(csvFile?.name);
    if (file.target.value.length > 30) {
      setExtraSpace(6);
    } else {
      setExtraSpace(3);
    }

    setButtonState(false);
    if (csvFile) {
      Papa.parse(csvFile, {
        skipEmptyLines: true,
        complete: function (results) {
          // callback function that will be called once parsing is completed
          let data = results.data; // results is an object with the data, errors and meta data
          let filteredData = data.filter((line) => {
            // here we filter line by line
            return !line.every((field) => field === ''); // if all fields are empty, we return false, else true, by returning false we filter out blank lines
          });
          setParsedFile(filteredData);
        },
      });
    }
  };

  async function processData() {
    setUploadDone(false);
    const data = parsedFile;
    // File length is the number of rows in the file minus the header row
    setFileLength(data?.length - 1);
    // TODO file should be uploaded when S3 is ready
    //uploadFile();
    const keys = data[0];
    let processedValues;
    let url;
    var errorFlag = 0;
    setButtonState(true);

    for (let i = 1; i <= data.length - 1; i++) {
      const values = data[i];
      const mapArrays = (keys, values) => {
        const obj = {};
        for (let i = 0; i < keys.length; i++) {
          if (values) {
            obj[keys[i]] = values[i];
          } else {
            obj[keys[i]] = '';
          }
        }
        return obj;
      };
      const res = mapArrays(keys, values);
      switch (title) {
        case 'Product':
          url = urls.product;
          processedValues = {
            code: res.code,
            description: res.description,
            product_category: res.product_category || null,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Unit Transformation':
          url = urls.unitTransformation;
          processedValues = {
            transformation_value:
              data.transformation_value !== '' ? parseInt(res.transformation_value) : 0,
            work_center: res.work_center,
            product: res.product,
            variable: res.variable,
            entry_dimensional_unit: res.entry_dimensional_unit,
            output_dimensional_unit: res.output_dimensional_unit,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Work Center':
          url = urls.workCenter;
          processedValues = {
            code: res.code,
            description: res.description,
            status: res.status,
            work_center_category: res.work_center_category || null,
            operation_center: res.operation_center,
            quality_source: res.quality_source,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Work Center Capacity':
          url = urls.workCenterCapacity;
          processedValues = {
            work_center: res.work_center,
            product: res.product,
            process: res.process,
            variable: res.variable,
            minimum_threshold: parseFloat(res.minimum_threshold) || null,
            maximum_threshold: parseFloat(res.maximum_threshold) || null,
            rated_capacity: parseFloat(res.rated_capacity) || null,
            dimensional_unit: res.dimensional_unit,
            minimum_unit_standard_time: parseFloat(res.minimum_unit_standard_time) || null,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Event Category':
          url = urls.eventCategory;
          processedValues = {
            code: res.code,
            description: res.description,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Product Category':
          url = urls.productCategory;
          processedValues = {
            code: res.code,
            description: res.description,
            status: res.status,
            parent_category: res.parent_category || null,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Work Center Category':
          url = urls.workCenterCategory;
          processedValues = {
            code: res.code,
            description: res.description,
            parent_category: res.parent_category || null,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Dimensional Unit':
          url = urls.dimensionalUnitA;
          processedValues = {
            code: res.code,
            description: res.description,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Event Type':
          url = urls.eventType;
          processedValues = {
            code: res.code,
            description: res.description,
            planned: res.planned,
            event_category: res.event_category || null,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Operation Center':
          url = urls.operationCenter;
          processedValues = {
            code: res.code,
            description: res.description,
            address: res.address,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Quality Dimension':
          url = urls.qualityDimension;
          processedValues = {
            code: res.code,
            description: res.description,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Variable':
          url = urls.variable;
          processedValues = {
            code: res.code,
            calculation_variable: res.calculation_variable,
            description: res.description,
            comments: res.comments,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Shift':
          url = urls.shift;
          processedValues = {
            code: res.code,
            start_hour: res.start_hour,
            end_hour: res.end_hour,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Process':
          url = urls.process;
          processedValues = {
            code: res.code,
            description: res.description,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Route':
          url = urls.route;
          processedValues = {
            process_order: res.process_order,
            product: res.product,
            process: res.process,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Work Center Per Process':
          url = urls.workCenterPerProcess;
          processedValues = {
            data_source: res.data_source,
            partial_reporting_time: res.partial_reporting_time,
            comments: res.comments,
            process: res.process,
            work_center: res.work_center,
            variable: res.variable,
            entry_dimensional_unit: res.entry_dimensional_unit,
            output_dimensional_unit: res.output_dimensional_unit,
            created_by: userEmail,
            updated_by: userEmail,
          };
          break;
        case 'Production Order':
          url = urls.simplifiedProductionOrder;
          processedValues = {
            code: res.code,
            operation_center: res.operation_center,
            planned_closing_date: res.planned_closing_date || null,
            product: res.product,
            process: res.process || null,
            work_center: res.work_center || null,
            amount_1: parseInt(res.amount_1) || 0,
            dispatch_date: res.dispatch_date || null,
            production_batch: res.production_batch || null,
            responsible: res.responsible || '',
            dimensional_unit_1: res.dimensional_unit_1 || null,
            dimensional_unit_2: res.dimensional_unit_2 || null,
            amount_2: res.amount_2 || null,
            amount_3: res.amount_3 || null,
          };
          break;
        case 'Production Batch':
          url = urls.productionBatch;
          processedValues = {
            code: res.code,
            manufacturing_date: res.manufacturing_date !== '' ? res.manufacturing_date : null,
            expiration_date: res.expiration_date !== '' ? res.expiration_date : null,
            responsible: res.responsible,
            attachment_file: res.attachment_file,
            created_by: userEmail,
            updated_by: userEmail,
            quality_characteristics: '',
          };
          let errorMessageProductionBatch = messages;
          // Validate el campo obligatorio 'code'
          if (res.code == '' && values.length !== 1) {
            errorMessageProductionBatch.push(`Row ${i}: Code ${res.code} cannot be empty`);
            setMessages(errorMessageProductionBatch);
            errorFlag = 1;
          }
          break;
      }
      if (url !== '') {
        setPostStarted(true);
        await axios
          .post(url, processedValues, header)
          .then((res) => {
            if (res.status === 201) {
              setSuccessNumber((successNumber) => successNumber + 1);
            }
          })
          .catch((err) => {
            errorFlag = 1;
            setErrorNumber((errorNumber) => errorNumber + 1);
          });
        setProgress((i * 100) / (data.length - 1));
        if (i === data.length - 1) {
          setUploadDone(true);
        }
      }
    }
    setShowMessages(true);
  }

  async function downloadFile() {
    switch (title) {
      case 'Product':
        fileNameDownload = templatesUrls.product;
        break;
      case 'Unit Transformation':
        fileNameDownload = templatesUrls.unitTransformation;
        break;
      case 'Work Center':
        fileNameDownload = templatesUrls.workCenter;
        break;
      case 'Work Center Capacity':
        fileNameDownload = templatesUrls.workCenterCapacity;
        break;
      case 'Event Category':
        fileNameDownload = templatesUrls.eventCategory;
        break;
      case 'Product Category':
        fileNameDownload = templatesUrls.productCategory;
        break;
      case 'Work Center Category':
        fileNameDownload = templatesUrls.workCenterCategory;
        break;
      case 'Dimensional Unit':
        fileNameDownload = templatesUrls.dimensionalUnitA;
        break;
      case 'Event Type':
        fileNameDownload = templatesUrls.eventType;
        break;
      case 'Operation Center':
        fileNameDownload = templatesUrls.operationCenter;
        break;
      case 'Quality Dimension':
        fileNameDownload = templatesUrls.qualityDimension;
        break;
      case 'Variable':
        fileNameDownload = templatesUrls.variable;
        break;
      case 'Shift':
        fileNameDownload = templatesUrls.shift;
        break;
      case 'Process':
        fileNameDownload = templatesUrls.process;
        break;
      case 'Route':
        fileNameDownload = templatesUrls.route;
        break;
      case 'Work Center Per Process':
        fileNameDownload = templatesUrls.workCenterPerProcess;
        break;
      case 'Production Order':
        fileNameDownload = templatesUrls.productionOrder;
        break;
      case 'Simplified Production Order':
        fileNameDownload = templatesUrls.simplifiedProductionOrder;
        break;
      case 'Production Batch':
        fileNameDownload = templatesUrls.productionBatch;
        break;
    }
    axios
      .get(fileNameDownload, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `${title}.xlsx`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Container>
        <HeaderTitle title={pageTitle} />
        <h4>Download the .xlsx template</h4>
        <Row>
          <BsIcons.BsCloudDownload
            size={120}
            className="mb-4 col-3"
            style={{
              margin: 'auto',
            }}
          />
        </Row>
        <Row>
          <Button
            className="fpb-btn col-4"
            style={{
              margin: 'auto',
            }}
            onClick={downloadFile}
          >
            Download
          </Button>
        </Row>
        <h4>Upload the .csv file</h4>
        <Row>
          <BsIcons.BsCloudUpload
            size={120}
            className="mb-4 col-3"
            style={{
              margin: 'auto',
            }}
          />
        </Row>
        <Row>
          <Button
            className="fpb-btn col-4"
            style={{
              margin: 'auto',
            }}
            onClick={processData}
            disabled={buttonState}
          >
            Upload
          </Button>
        </Row>
        <Row className="mt-2">
          <Col xs={extraSpace}>
            <input
              type="file"
              onChange={newFile}
              className="mb-3 mr-0"
              accept=".csv"
              value={fileName}
            />
          </Col>
          {fileName !== '' ? (
            <Col>
              <GiCancel
                size={20}
                onClick={() => {
                  setFileName('');
                  setMessages([]);
                  setShowMessages(false);
                }}
              />
            </Col>
          ) : null}
        </Row>
        {showMessages
          ? messages?.map((message, idx) => {
              return (
                <Alert key={idx} variant="danger">
                  {message}
                </Alert>
              );
            })
          : null}
        {postStarted ? (
          <div className="mt-4">
            <h4>Upload Process</h4>
            <Row className="mt-3">
              <Col xs lg="2" align="center">
                <img src={csvIcon} alt={'csvIcon'} />
                <div className="small-text">{shortFileName}</div>
              </Col>
              <Col xs lg="3">
                <ProgressBar animated now={progress} label={uploadDone ? 'Done!' : ''} />
                <div className="small-text">
                  <div>Number of records: {fileLength}</div>
                  <div>Success: {successNumber}</div>
                  <div>Error: {errorNumber}</div>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
      </Container>
    </>
  );
};
export default IndustrialTemplatesView;
