import Urls from '../components/Urls';
import axios from 'axios';

const uploadFiles = async (
  file,
  setUrl,
  setName,
  setLoading,
  notification,
  setError,
  accessToken
) => {
  const baseUrl = Urls.baseFile;
  setLoading(true);
  const fileToUpload = file.target.files[0];
  const token = accessToken || localStorage.getItem('accessToken');

  if (fileToUpload.size < 10242880) {
    if (fileToUpload) {
      const uploadData = new FormData();
      uploadData.append('title', fileToUpload.name);
      uploadData.append('file', fileToUpload);
      await axios
        .post(Urls.upload, uploadData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let documentUrl = '';
          let documentName = '';
          documentUrl = `${baseUrl}${res.data.file}`;
          documentName = documentUrl.split('/')[5];
          setUrl(documentUrl);
          setName(documentName);
          notification('File succesfuly uploaded', 'success');
        })
        .catch((err) => {
          console.log(err);
          setError('An error occurred while uploading the file');
          notification('There was an error while uploading the file', 'error');
        });
    }
  } else {
    notification('The file exceeds 10 MB', 'error');
  }
  setLoading(false);
};

export default uploadFiles;
