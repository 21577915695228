import { React, useRef, useLayoutEffect } from 'react';
import { select, scaleLinear, axisBottom } from 'd3';

export const XAxisLineChart = ({ xDomain, tickCount, width, height, data }) => {
  const xAxisRef = useRef(null);
  let finalTicks;
  if (data.length > 20) {
    finalTicks = data.length / 4;
  } else if (data.length > 10 && data.length % 2 !== 0) {
    finalTicks = data.length / 2;
  } else if (data.length > 10 && data.length % 2 === 0) {
    finalTicks = data.length / 3;
  } else {
    finalTicks = data.length;
  }
  useLayoutEffect(() => {
    const x = scaleLinear()
      .domain(xDomain)
      .range([70, width - 100])
      .nice();

    var tickValues = [].concat(x.ticks(finalTicks), x.domain()[1]);

    const axis = axisBottom;
    const xAxis = axis(x)
      .tickValues(tickValues)
      .tickFormat((d) => {
        if (data.length > 10 && data.length % 2 === 0 && d === data.length) {
          return data[d - 1] && data[d - 1].date;
        }
        return data[d] && data[d].date;
      });
    xAxis.tickSizeOuter(5);

    select(xAxisRef.current).attr('class', 'axisX').call(xAxis).style('font-size', '13px');
    select(xAxisRef.current).selectAll('line').style('color', 'gray');
    select(xAxisRef.current).selectAll('text').attr('transform', 'rotate(45) translate(30, -5)');
  }, [tickCount, width, xDomain, data]);

  return <g transform={`translate(0, ${height - 60})`} ref={xAxisRef} />;
};
