import Urls from '../components/Urls';

const routesList = [
  {
    formName: 'item',
    title: 'Item',
    path: '/item',
    url: Urls.item,
    columnToTransform: [
      {
        name: 'item_category',
        url: 'itemCategory',
        field: 'item_category_code',
      },
      {
        name: 'label',
        url: 'label',
        field: 'label_code',
      },
      {
        name: 'consolidation_level_N0',
        url: 'dimensionalUnit',
        field: 'dimensional_unit_code',
      },
      {
        name: 'consolidation_level_N1',
        url: 'dimensionalUnit',
        field: 'dimensional_unit_code',
      },
      {
        name: 'consolidation_level_N2',
        url: 'dimensionalUnit',
        field: 'dimensional_unit_code',
      },
      {
        name: 'consolidation_level_N3',
        url: 'dimensionalUnit',
        field: 'dimensional_unit_code',
      },
      {
        name: 'consolidation_level_N4',
        url: 'dimensionalUnit',
        field: 'dimensional_unit_code',
      },
    ],
  },
  {
    formName: 'storageCenter',
    title: 'Storage Center',
    path: '/storage_center',
    url: Urls.storageCenter,
    columnToTransform: [],
  },
  {
    formName: 'storageLocation',
    title: 'Storage Location',
    path: '/storage_location',
    url: Urls.storageLocation,
    columnToTransform: [
      {
        name: 'storage_center_code',
        url: 'storageCenter',
        field: 'storage_center_code',
      },
    ],
  },
  {
    formName: 'itemCategory',
    title: 'Item Category',
    path: '/item_category',
    url: Urls.itemCategory,
    columnToTransform: [
      {
        name: 'parent_category',
        url: 'itemCategory',
        field: 'item_category_code',
      },
    ],
  },
  {
    formName: 'label',
    title: 'Label',
    path: '/label',
    url: Urls.label,
    columnToTransform: [],
  },
  {
    formName: 'dimensionalUnit',
    title: 'Dimensional Unit',
    path: '/dimensional_unit',
    url: Urls.dimensionalUnit,
    columnToTransform: [],
  },
  {
    formName: 'customer',
    title: 'Customer',
    path: '/customer',
    url: Urls.customer,
    columnToTransform: [],
  },
  {
    formName: 'supplier',
    title: 'Supplier',
    path: '/supplier',
    url: Urls.supplier,
    columnToTransform: [],
  },
  {
    formName: 'adjustment',
    title: 'Adjustment',
    path: '/adjustment',
    url: Urls.adjustment,
    columnToTransform: [],
  },
  {
    formName: 'cost',
    title: 'Cost',
    path: '/cost',
    url: Urls.cost,
    columnToTransform: [
      {
        name: 'unique_cost_type_code',
        url: 'costType',
        field: 'unique_cost_type_code',
      },
      {
        name: 'item_reference_code',
        url: 'item',
        field: 'reference_code',
      },
      {
        name: 'dimensional_unit_code',
        url: 'dimensionalUnit',
        field: 'dimensional_unit_code',
      },
    ],
  },
  {
    formName: 'costType',
    title: 'Cost Type',
    path: '/cost_type',
    url: Urls.costType,
    columnToTransform: [
      {
        name: 'customer_identification',
        url: 'customer',
        field: 'customer_identification',
      },
      {
        name: 'supplier_identification',
        url: 'supplier',
        field: 'supplier_identification',
      },
    ],
  },
];

const indicatorTables = [
  {
    formName: 'stockPerBatch',
    title: 'Stock Per Batch',
    path: '/indicators/stock_per_batch',
    url: Urls.stockPerBatch,
    columnToTransform: [
      {
        name: 'storage_center_code',
        url: 'storageCenter',
        field: 'storage_center_code',
      },
      {
        name: 'batch_code',
        url: 'batch',
        field: 'batch_code',
      },
      {
        name: 'item_reference_code',
        url: 'item',
        field: 'reference_code',
      },
    ],
  },
  {
    formName: 'stockPerStorageCenter',
    title: 'Stock Per Storage Center',
    path: '/indicators/stock_per_storage_center',
    url: Urls.stockPerStorageCenter,
    columnToTransform: [
      {
        name: 'storage_center_code',
        url: 'storageCenter',
        field: 'storage_center_code',
      },

      {
        name: 'item_reference_code',
        url: 'item',
        field: 'reference_code',
      },
    ],
  },
];

const operationalTables = [
  {
    formName: 'entryOrder',
    title: 'Entry Order',
    path: '/inventory/entry_order',
    url: Urls.entryOrder,
    columnToTransform: [
      {
        name: 'supplier_identification',
        url: 'supplier',
        field: 'supplier_identification',
      },
    ],
  },
  {
    formName: 'dispatchOrder',
    title: 'Dispatch Order',
    path: '/inventory/dispatch_order',
    url: Urls.dispatchOrder,
    columnToTransform: [
      {
        name: 'customer_identification',
        url: 'customer',
        field: 'customer_identification',
      },
    ],
  },
  {
    formName: 'batch',
    title: 'Batch',
    path: '/inventory/batch',
    url: Urls.batch,
    columnToTransform: [],
  },
  {
    formName: 'itemBatchRelation',
    title: 'Item Batch Relation',
    path: '/inventory/item_batch_relation',
    url: Urls.itemBatchRelation,
    columnToTransform: [
      {
        name: 'item_reference_code',
        url: 'item',
        field: 'reference_code',
      },
      {
        name: 'batch_code',
        url: 'batch',
        field: 'batch_code',
      },
      {
        name: 'unique_unit_cost_code',
        url: 'cost',
        field: 'unique_unit_cost_code',
      },
    ],
  },
  {
    formName: 'itemStorageCenterRelation',
    title: 'Item Storage Center Relation',
    path: '/inventory/item_storagecenter_relation',
    url: Urls.itemStorageRelation,
    columnToTransform: [
      {
        name: 'item_reference_code',
        url: 'item',
        field: 'reference_code',
      },
      {
        name: 'storage_center_code',
        url: 'storageCenter',
        field: 'storage_center_code',
      },
    ],
  },
];

const transactionRoutes = [
  {
    title: 'Entry transaction',
    transactionType: 'entry',
    path: '/transactions/entry',
  },
  {
    title: 'Output transaction',
    transactionType: 'output',
    path: '/transactions/output',
  },
  {
    title: 'Transfer transaction',
    transactionType: 'transfer',
    path: '/transactions/transfer',
  },
  {
    title: 'Adjustment transaction',
    transactionType: 'adjustment',
    path: '/transactions/adjustment',
  },
];

const uploadMainTables = [
  {
    title: 'Item',
    path: '/upload/item',
  },
  {
    title: 'Storage Center',
    path: '/upload/center',
  },
  {
    title: 'Storage Location',
    path: '/upload/location',
  },
  {
    title: 'Item Category',
    path: '/upload/category',
  },
  {
    title: 'Cost',
    path: '/upload/cost',
  },
  {
    title: 'Cost Type',
    path: '/upload/type',
  },
  {
    title: 'Dimensional Unit',
    path: '/upload/dimensional',
  },
  {
    title: 'Supplier',
    path: '/upload/supplier',
  },
  {
    title: 'Customer',
    path: '/upload/customer',
  },
  {
    title: 'Adjustment',
    path: '/upload/adjustment',
  },
];

const uploadOperationalTables = [
  {
    title: 'Entry Order',
    path: '/upload/entry',
  },
  {
    title: 'Dispatch Order',
    path: '/upload/dispatch',
  },
  {
    title: 'Batch',
    path: '/upload/batch',
  },
  {
    title: 'Item Batch Relation',
    path: '/upload/relation',
  },
  {
    title: 'Item Storage Center Relation',
    path: '/upload/storage',
  },
];

//INDUSTRIAL ROUTES
const industrialMainTables = [
  {
    formName: 'product',
    title: 'Product',
    path: '/industrial/product',
    url: Urls.product,
    columnToTransform: [],
  },
  {
    formName: 'unitTransformation',
    title: 'Unit Transformation',
    path: '/industrial/unit_transformation',
    url: Urls.unitTransformation,
    columnToTransform: [],
  },
  {
    formName: 'workCenter',
    title: 'Work Center',
    path: '/industrial/work_center',
    url: Urls.workCenter,
    columnToTransform: [],
  },
  {
    formName: 'workCenterCapacity',
    title: 'Work Center Capacity',
    path: '/industrial/work_center_capacity',
    url: Urls.workCenterCapacity,
    columnToTransform: [],
  },
  {
    formName: 'dimensionalUnitA',
    title: 'Dimensional Unit',
    path: '/industrial/dimensional_unit',
    url: Urls.dimensionalUnitA,
    columnToTransform: [],
  },
  {
    formName: 'eventType',
    title: 'Event Type',
    path: '/industrial/event_type',
    url: Urls.eventType,
    columnToTransform: [],
  },
  {
    formName: 'eventCategory',
    title: 'Event Category',
    path: '/industrial/event_category',
    url: Urls.eventCategory,
    columnToTransform: [],
  },
  {
    formName: 'operationCenter',
    title: 'Operation Center',
    path: '/industrial/operation_center',
    url: Urls.operationCenter,
    columnToTransform: [],
  },
  {
    formName: 'productCategory',
    title: 'Product Category',
    path: '/industrial/product_category',
    url: Urls.productCategory,
    columnToTransform: [],
  },
  {
    formName: 'qualityDimension',
    title: 'Quality Dimension',
    path: '/industrial/quality_dimension',
    url: Urls.qualityDimension,
    columnToTransform: [],
  },
  {
    formName: 'shift',
    title: 'Shift',
    path: '/industrial/shift',
    url: Urls.shift,
    columnToTransform: [],
  },
  {
    formName: 'workCenterCategory',
    title: 'Work Center Category',
    path: '/industrial/work_center_category',
    url: Urls.workCenterCategory,
    columnToTransform: [],
  },
  {
    formName: 'variable',
    title: 'Variable',
    path: '/industrial/variable',
    url: Urls.variable,
    columnToTransform: [],
  },
  {
    formName: 'process',
    title: 'Process',
    path: '/industrial/process',
    url: Urls.process,
    columnToTransform: [],
  },
  {
    formName: 'route',
    title: 'Route',
    path: '/industrial/route',
    url: Urls.route,
    columnToTransform: [],
  },
  {
    formName: 'workCenterPerProcess',
    title: 'Work Center Per Process',
    path: '/industrial/workCenterPerProcess',
    url: Urls.workCenterPerProcess,
    columnToTransform: [],
  },
];

const industrialOperationalTables = [
  {
    formName: 'productionBatch',
    title: 'Production Batch',
    path: '/industrial/production_batch',
    url: Urls.productionBatch,
    columnToTransform: [],
    disableAdd: false,
  },
  {
    formName: 'quality',
    title: 'Quality Report',
    path: '/industrial/quality_report',
    url: Urls.qualityReport,
    columnToTransform: [],
    disableAdd: true,
  },
];

const processSection = [
  {
    formName: 'processControl',
    title: 'Process Control',
    path: '/industrial/process_control',
    url: Urls.processControl,
    columnToTransform: [],
  },
];
const productionOrderSection = [
  {
    formName: 'productionOrder',
    title: 'Production Order',
    path: '/industrial/production_order',
    url: Urls.productionOrder,
    columnToTransform: [],
  },
];
const shiftAssignment = [
  {
    formName: 'shiftAssignment',
    title: 'Shift Assignment',
    path: '/industrial/shift_assignment',
    url: Urls.shiftAssignment,
    columnToTransform: [],
  },
];

const industrialUploadMainTables = [
  {
    title: 'Product',
    path: '/upload/product',
  },
  {
    title: 'Unit Transformation',
    path: '/upload/unit_transformation',
  },
  {
    title: 'Work Center',
    path: '/upload/work_center',
  },
  {
    title: 'Work Center Capacity',
    path: '/upload/work_center_capacity',
  },
  {
    title: 'Event Category',
    path: '/upload/event_category',
  },
  {
    title: 'Product Category',
    path: '/upload/product_category',
  },
  {
    title: 'Work Center Category',
    path: '/upload/work_center_category',
  },
  {
    title: 'Dimensional Unit',
    path: '/upload/dimensional_unit',
  },
  {
    title: 'Event Type',
    path: '/upload/event_type',
  },
  {
    title: 'Operation Center',
    path: '/upload/operation_center',
  },
  {
    title: 'Quality Dimension',
    path: '/upload/quality_dimension',
  },
  {
    title: 'Variable',
    path: '/upload/variable',
  },
  {
    title: 'Shift',
    path: '/upload/shift',
  },
  {
    title: 'Process',
    path: '/upload/process',
  },
  {
    title: 'Route',
    path: '/upload/process_route',
  },
  {
    title: 'Work Center Per Process',
    path: '/upload/work_center_per_process',
  },
];

const accomplishment = [
  {
    formName: 'timeAccomplishment',
    title: 'Accomplishment in Time',
    path: '/industrial/accomplishment_in_time',
    url: Urls.timeAccomplishment,
    columnToTransform: [],
  },
  {
    formName: 'AccomplishmentByQuantity',
    title: 'Accomplishment by Quantity',
    path: '/industrial/accomplishment_by_quantity',
    url: Urls.accomplishmentByQuantity,
    columnToTransform: [],
  },
];

const industrialUploadOperationalTables = [
  {
    title: 'Production Order',
    path: '/upload/simplified_production_order',
  },
  {
    title: 'Production Batch',
    path: '/upload/production_batch',
  },
];

//AUTH
const auth = [
  {
    formName: 'company',
    title: 'Company',
    path: '/administration/company',
    url: Urls.company,
    columnToTransform: [],
  },
  {
    formName: 'user',
    title: 'User',
    path: '/administration/user',
    url: Urls.user,
    columnToTransform: [],
  },
  {
    formName: 'module',
    title: 'Module',
    path: '/administration/module',
    url: Urls.module,
    columnToTransform: [],
  },
  {
    formName: 'companyModule',
    title: 'Company / Module',
    path: '/administration/company_module',
    url: Urls.company_module,
    columnToTransform: [],
  },
  {
    formName: 'groupPermission',
    title: 'Group / Permission',
    path: '/administration/group_permission',
    url: Urls.group_permission,
    columnToTransform: [],
  },
  {
    formName: 'userGroupPermission',
    title: 'User / Group',
    path: '/administration/usergroup_permission',
    url: Urls.usergroup_permission,
    columnToTransform: [],
  },
];

export {
  routesList,
  operationalTables,
  indicatorTables,
  transactionRoutes,
  uploadMainTables,
  uploadOperationalTables,
  industrialMainTables,
  industrialOperationalTables,
  processSection,
  productionOrderSection,
  shiftAssignment,
  industrialUploadMainTables,
  industrialUploadOperationalTables,
  auth,
  accomplishment,
};
