import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import apiService from '../apiService';
import Urls from '../Urls';
import Data from '../dataService';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import DimensionalUnitField from './DimensionalUnitField';
import getUniqueValues from '../../utils/getUniqueValues';

const UnitTransformation = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [workCenterPerProcess, setWorkCenterPerProcess] = useState([]);
  const [workCenter, setWorkCenter] = useState(null);
  const [workCenterCapacityAllowed, setWorkCenterCapacityAllowed] = useState(null);
  const [product, setProduct] = useState(null);
  const [variable, setVariable] = useState(null);
  const [entryDimensionalUnitCode, setEntryDimensionalUnitCode] = useState('');
  const [outputDimensionalUnitCode, setOutputDimensionalUnitCode] = useState('');

  const [descriptions, setDescriptions] = useState({
    workCenterDescription: '',
    productDescription: '',
    variableDescription: '',
    entryDimensionalUnitDescription: '',
    outputDimensionalUnitDescription: '',
  });
  const [workCenterData, setWorkCenterData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [productData, setProductData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [variableData, setVariableData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const configureProductOptions = (products) => {
    const options = products.map((product) => ({
      description: product.description,
      value: product.code,
      label: product.code,
      id: product.id,
    }));
    return options;
  };

  const configureWorkCenterOptions = (workCenterCapacity) => {
    const options = workCenterCapacity.map((wCCapacity) => ({
      description: wCCapacity.work_center_description,
      value: wCCapacity.work_center,
      label: wCCapacity.work_center,
      id: wCCapacity.id,
    }));

    const uniqueOptions = getUniqueValues(options);
    return uniqueOptions;
  };

  const configureVariableOptions = (workCenterCapacityFiltered) => {
    const options = workCenterCapacityFiltered.map((workCenterCapacity) => ({
      description: workCenterCapacity.variable_description,
      value: workCenterCapacity.variable,
      label: workCenterCapacity.variable,
      id: workCenterCapacity.id,
    }));

    const uniqueOptions = getUniqueValues(options);
    return uniqueOptions;
  };

  const products = useSelector((state) => state.industrial.products);

  useEffect(() => {
    const options = configureProductOptions(products);
    setProductData({ options: options });
  }, []);

  useEffect(() => {
    const getWorkCenterCapacityInfo = async () => {
      const workCenterCapacityByProduct = await apiService.getDataWithFilters(
        Urls.workCenterCapacity,
        [`product__code=${product}`]
      );
      const workCenterOptions = configureWorkCenterOptions(workCenterCapacityByProduct);
      setWorkCenterCapacityAllowed(workCenterCapacityByProduct);
      setWorkCenterData({ options: workCenterOptions });
    };
    getWorkCenterCapacityInfo();
  }, [product]);

  useEffect(() => {
    const workCenterCapacityByWorkCenter = workCenterCapacityAllowed?.filter(
      (row) => row.work_center === workCenter
    );
    if (workCenterCapacityByWorkCenter !== undefined) {
      const variableOptions = configureVariableOptions(workCenterCapacityByWorkCenter);
      setVariableData({ options: variableOptions });
    }
  }, [workCenter]);

  const unitTransformationValidate = Yup.object({
    transformationValue: Yup.number().required('Required'),
    workCenter: Yup.string(),
    product: Yup.string(),
    variable: Yup.string(),
    entryDimensionalUnit: Yup.string(),
    outputDimensionalUnit: Yup.string(),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(() => {
    const obtainConfigurationsTableInfo = async () => {
      try {
        const configurationsTable = await Data.getWorkCenterPerProcess();
        setWorkCenterPerProcess(configurationsTable);
      } catch (err) {
        console.log(err);
      }
    };
    obtainConfigurationsTableInfo();
  }, []);

  useEffect(() => {
    const configureEntryOutputDimensionalUnits = () => {
      const filterData = workCenterPerProcess.find(
        (row) => row.variable === variable && row.work_center === workCenter
      );
      const entryDimensionalUnit = filterData?.entry_dimensional_unit;
      const outputDimensionalUnit = filterData?.output_dimensional_unit;
      setEntryDimensionalUnitCode(entryDimensionalUnit);
      setOutputDimensionalUnitCode(outputDimensionalUnit);
    };
    variable && workCenter && configureEntryOutputDimensionalUnits();
  }, [variable]);

  const clearVariableValues = (setFieldValue) => {
    setFieldValue('entryDimensionalUnit', '');
    setFieldValue('outputDimensionalUnit', '');
    setFieldValue('variable', '');
    setOutputDimensionalUnitCode('');
    setEntryDimensionalUnitCode('');
    setVariable('');
  };

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={unitTransformationValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            transformation_value: data.transformationValue,
            work_center: data.workCenter,
            product: data.product,
            variable: data.variable || null,
            entry_dimensional_unit: data.entryDimensionalUnit || null,
            output_dimensional_unit: data.outputDimensionalUnit || null,
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
          };
          setProcessedFormValues(processedValues);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues);
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues);
            if (updated.statusText == 'OK') {
              setAddForm(false);
              onEdited(processedValues);
            }
          }
        }}
        initialValues={{
          id: values.id,
          transformationValue: values.transformation_value || '',
          workCenter: values.work_center || null,
          product: values.product || null,
          variable: values.variable || null,
          entryDimensionalUnit: values.entry_dimensional_unit || null,
          outputDimensionalUnit: values.output_dimensional_unit || null,
          createdBy: values.created_by || '',
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('product', option?.value || '');
                  setProduct(option?.value || '');
                  setDescriptions(
                    option
                      ? { ...descriptions, productDescription: option.description }
                      : { ...descriptions, productDescription: '' }
                  );
                }}
                label="Product Code"
                name="product"
                options={productData.options}
                defaultValue={{ value: values.product, label: values.product }}
                description={descriptions.productDescription}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('workCenter', option?.value || '');
                  clearVariableValues(setFieldValue);
                  setWorkCenter(option?.value || '');
                  setDescriptions(
                    option
                      ? { ...descriptions, workCenterDescription: option.description }
                      : { ...descriptions, workCenterDescription: '' }
                  );
                }}
                label="Work Center Code"
                name="workCenter"
                options={workCenterData.options}
                defaultValue={{ value: values.workCenter, label: values.workCenter }}
                description={descriptions.workCenterDescription}
              />
            </Form.Row>
            <Form.Row>
              <CustomSelect
                onChange={(option) => {
                  //option is null after we clear the field
                  !option && clearVariableValues(setFieldValue);
                  setFieldValue('variable', option?.value || '');
                  setVariable(option?.value || '');
                  setDescriptions(
                    option
                      ? { ...descriptions, variableDescription: option.description }
                      : { ...descriptions, variableDescription: '' }
                  );
                }}
                label="Variable Code"
                name="variable"
                options={variableData.options}
                value={{
                  value: values.variable,
                  label: values.variable,
                }}
                defaultValue={{
                  value: values.variable,
                  label: values.variable,
                }}
                description={descriptions.variableDescription}
              />
              <DimensionalUnitField
                dimensionalUnit={{ label: 'Entry Dimensional Unit', name: 'entryDimensionalUnit' }}
                dimensionalUnitValue={entryDimensionalUnitCode}
              />
              <DimensionalUnitField
                dimensionalUnit={{
                  label: 'Output Dimensional Unit',
                  name: 'outputDimensionalUnit',
                }}
                dimensionalUnitValue={outputDimensionalUnitCode}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Transformation Value"
                name="transformationValue"
                type="number"
                value={values.transformationValue}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UnitTransformation;
