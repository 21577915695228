import { Link } from 'react-router-dom';

const colors = { mainColor: '#49158b', pinkColor: '#ff3c77', blueColor: '#08bfff' };
const DashboardCard = ({ title, description, icon, path, color }) => (
  <Link
    to={path}
    className="card grow shadow flex-md-shrink-0 flex-md-grow-1"
    style={{
      backgroundColor: colors[color],
      color: 'white',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1.2rem',
    }}
  >
    <div className="text-center p-3 mr-3" style={{ borderRadius: '10px' }}>
      <span style={{ color: 'white', fontSize: '2.5em' }}>{icon}</span>
    </div>
    <div className="d-flex flex-column">
      <h4 className="card-title mb-0">{title}</h4>
      <p className="m-0">{description}</p>
    </div>
  </Link>
);

export default DashboardCard;
