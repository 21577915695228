import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import TableModal from '../TableModal/TableModal';
import apiService from '../apiService';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import Urls from '../Urls';
import { useDispatch } from 'react-redux';
import { setIsModalPaginationAction } from '../../redux/actions/paginationAction';
import { notification } from '../AlertMessage/ToastifyAlert';

const StorageLocation = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [valuesToEdit, setValuesToEdit] = useState({});
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [show, setShow] = useState(false);
  const [optionFromModal, setShowOptionFromModal] = useState(false);
  const [storageCenterDefaultOptions, setStorageCenterDefaultOptions] = useState({});
  const [storageCenterCodeTransformed, setStorageCenterCodeTransformed] = useState();
  const [descriptions, setDescriptions] = useState({
    storageCenterDescription: '',
  });
  const [storageCenterData, setStorageCenterData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const storageColumnToTransform = [
    {
      name: 'storage_center_code',
      url: 'storageCenter',
      field: 'storage_center_code',
    },
  ];

  const storageLocationValidate = Yup.object({
    locationCode: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string().required('Required'),
    storageCenterCode: Yup.string().required('Required'),
    coordinateX: Yup.number().typeError('Must be a number').positive('Must be a positive number'),
    coordinateY: Yup.number().typeError('Must be a number').positive('Must be a positive number'),
    coordinateZ: Yup.number().typeError('Must be a number').positive('Must be a positive number'),
  });

  //pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
        storage_center_code: storageCenterCodeTransformed,
      });
    }
  }, [added]);

  useEffect(async () => {
    const resStorageCenter = await apiService.getData(Urls.storageCenter, 'storage_center_code');

    setStorageCenterData({
      headers: resStorageCenter.headersList,
      tableData: resStorageCenter.data,
      options: resStorageCenter.results,
    });
  }, []);

  useEffect(() => {
    storageCenterData.options.forEach((option) => {
      if (option.value === values.storage_center_code) {
        setDescriptions(
          option
            ? { ...descriptions, storageCenterDescription: option.description }
            : { ...descriptions, storageCenterDescription: '' }
        );
      }
    });
  }, [storageCenterData]);

  const handleCloseTableModal = () => {
    setIsModalPagination();
    setShow(false);
  };

  const handleStorageCenterConfirm = (selected, title, setFieldValue) => {
    setStorageCenterDefaultOptions({
      value: selected.id,
      label: selected.storage_center_code,
      description: selected.description,
    });
    setValuesToEdit({
      ...valuesToEdit,
      storage_center_code: selected.id,
    });
    setFieldValue('storageCenterCode', selected.id);
    setStorageCenterCodeTransformed(selected.storage_center_code);
    setShow(false);
    setShowOptionFromModal(true);
  };

  return (
    <Formik
      validateOnChange
      validationSchema={storageLocationValidate}
      onSubmit={async (data) => {
        const processedValues = {
          location_code: data.locationCode,
          description: data.description,
          storage_center_code: data.storageCenterCode,
          coordinate_x: data.coordinateX !== '' ? data.coordinateX : null,
          coordinate_y: data.coordinateY !== '' ? data.coordinateY : null,
          coordinate_z: data.coordinateZ !== '' ? data.coordinateZ : null,
        };
        setProcessedFormValues(processedValues);
        setOriginalFormValues(data);
        if (data.id == null) {
          const created = await handleAPIRequest(processedValues, {
            entity: 'Location',
            code: data.locationCode,
          });
          if (created.statusText !== 'Created') {
            notification('Error creating item - duplicate item', 'error');
          }
        } else {
          const editedValues = {
            location_code: data.locationCode,
            description: data.description,
            storage_center_code: storageCenterCodeTransformed || values.storage_center_code,
            coordinate_x: data.coordinateX,
            coordinate_y: data.coordinateY,
            coordinate_z: data.coordinateZ,
          };
          const updated = await handleAPIRequestUpdate(data.id, valuesToEdit, {
            entity: 'Location',
            code: data.locationCode,
          });
          if (updated.statusText == 'OK') {
            onEdited(editedValues);
            setAddForm(false);
            notification('Item updated successfully', 'success');
          } else {
            notification('Error updating item - duplicate item', 'error');
          }
        }
      }}
      initialValues={{
        id: values.id,
        locationCode: values.location_code || '',
        storageCenterCode: values.storage_center_code || '',
        description: values.description || '',
        coordinateX: values.coordinate_x || '',
        coordinateY: values.coordinate_y || '',
        coordinateZ: values.coordinate_z || '',
      }}
    >
      {({ setFieldValue, values, handleChange, handleSubmit }) => (
        <>
          {show ? (
            <TableModal
              url={Urls.storageCenter}
              title="Storage Center Code"
              show={show}
              handleClose={handleCloseTableModal}
              formName={'storageCenter'}
              columnToTransform={[]}
              handleConfirmation={handleStorageCenterConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Location Code"
                name="locationCode"
                type="text"
                value={values.locationCode}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    location_code: e.target.value,
                  });
                }}
              />

              {optionFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      storage_center_code: option ? option.id : '',
                    });
                    setFieldValue('storageCenterCode', option ? option.id : '');
                    setStorageCenterCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            storageCenterDescription: option.description,
                          }
                        : { storageCenterDescription: '' }
                    );
                    setShowOptionFromModal(false);
                  }}
                  name="storageCenterCode"
                  label="Storage Center Code"
                  options={storageCenterData.options}
                  setShow={setShow}
                  defaultValue={storageCenterDefaultOptions}
                  value={storageCenterDefaultOptions}
                  description={storageCenterDefaultOptions.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      storage_center_code: option ? option.id : '',
                    });
                    setFieldValue('storageCenterCode', option ? option.id : '');
                    setStorageCenterCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            storageCenterDescription: option.description,
                          }
                        : { storageCenterDescription: '' }
                    );
                  }}
                  name="storageCenterCode"
                  label="Storage Center Code"
                  options={storageCenterData.options}
                  setShow={setShow}
                  defaultValue={{
                    value: values.storageCenterCode,
                    label: values.storageCenterCode,
                  }}
                  description={descriptions.storageCenterDescription}
                />
              )}
            </Form.Row>
            <Form.Row>
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    description: e.target.value,
                  });
                }}
              />
            </Form.Row>

            <Form.Row className="justify-content-start px-1">
              <Form.Group controlId="formGridCoordinates" style={{ width: '100%' }}>
                <Form.Label>Coordinates</Form.Label>
                <InputGroup>
                  <Form.Control
                    style={{ width: '3rem', display: 'inline' }}
                    type="text"
                    name="coordinateX"
                    placeholder="X"
                    value={values.coordinateX}
                    onChange={(e) => {
                      handleChange(e);
                      setValuesToEdit({
                        ...valuesToEdit,
                        coordinate_x: e.target.value || 0,
                      });
                    }}
                  />
                  <Form.Control
                    style={{ width: '3rem', display: 'inline' }}
                    type="text"
                    name="coordinateY"
                    placeholder="Y"
                    value={values.coordinateY}
                    onChange={(e) => {
                      handleChange(e);
                      setValuesToEdit({
                        ...valuesToEdit,
                        coordinate_y: e.target.value || 0,
                      });
                    }}
                  />
                  <Form.Control
                    style={{ width: '3rem', display: 'inline' }}
                    type="text"
                    name="coordinateZ"
                    placeholder="Z"
                    value={values.coordinateZ}
                    onChange={(e) => {
                      handleChange(e);
                      setValuesToEdit({
                        ...valuesToEdit,
                        coordinate_z: e.target.value || 0,
                      });
                    }}
                  />
                </InputGroup>

                <ErrorMessage component="div" name="coordinateX" className="fpb-error" />
                <ErrorMessage component="div" name="coordinateY" className="fpb-error" />
                <ErrorMessage component="div" name="coordinateZ" className="fpb-error" />
              </Form.Group>
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default StorageLocation;
