import React from 'react';
import ManualProcessControlChart from '../IndustrialGraph/ManualProcessControlChart';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import FiltersManualControl from '../FiltersManualControl';
import { useSelector } from 'react-redux';
import { showSelectedWorkCenterData } from '../../../redux/slices/indicatorFilterSlice';

const ManualProcessControl = () => {
  const workCenters = useSelector(showSelectedWorkCenterData);

  return (
    <>
      <HeaderTitle title="Manual Process Control" />
      <FiltersManualControl />
      {workCenters.length !== 0
        ? workCenters.map((element) => (
            <div className=" pr-4 py-3" key={element.id}>
              <ManualProcessControlChart
                key={element.id}
                workCenter={element.work_center}
                product={element.product}
                variable={element.variable}
                dimensionalUnit={element.entry_dimensional_unit}
                idx={element.id}
              />
            </div>
          ))
        : null}
    </>
  );
};

export default ManualProcessControl;
