import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { showTimeAnalysis } from '../../../redux/slices/timeAnalysisSlice';
import { showSeriesView } from '../../../redux/slices/indicatorFilterSlice';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup, Row } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';
import { showFilterList } from '../../../redux/slices/indicatorFilterSlice';

const PlannedEvents = ({ indicatorName }) => {
  //redux
  const see = useSelector(showSeriesView);
  const data = useSelector(showTimeAnalysis);
  const plannedEvents = data?.plannedEvents || [];
  const dates = data?.dates || [];
  const filters = useSelector((state) => state.timeAnalysis.filters);
  const { workCenter, product, productionOrder, shift, from, to, temporality } = filters;
  const [subtitle, setSubtitle] = useState('');
  const [categories, setCategories] = useState([]);
  const filterList = useSelector(showFilterList);
  const [seriesByMinutes, setSeriesByMinutes] = useState([]);
  const [seriesByHours, setSeriesByHours] = useState([]);

  useEffect(() => {
    const subtitle = `Filtered by work center ${workCenter}${
      product ? ', product ' + product : ''
    }${productionOrder ? ', production order ' + productionOrder : ''}${
      shift ? ', shift ' + shift : ''
    }, from ${from} to ${to} ${'(' + temporality + ')'}.`;
    setSubtitle(subtitle);
  }, [filters]);

  const [paginationCount, setPaginationCount] = useState(0);
  const [excelTableData, setExcelTableData] = useState([]);
  const [headerNames, setHeaderNames] = useState([]);

  useEffect(() => {
    if (plannedEvents.length > 0) {
      let eventsMinutes = plannedEvents.map((event) => {
        return { name: event.name, data: event.dataByMinutes };
      });
      let eventsHours = plannedEvents.map((event) => {
        return { name: event.name, data: event.dataByHours };
      });
      setSeriesByMinutes(eventsMinutes);
      setSeriesByHours(eventsHours);
      setCategories(dates);
    } else {
      setSeriesByMinutes([]);
      setSeriesByHours([]);
      setCategories([]);
    }
  }, [data]);

  // useEffect(() => {
  //   if (names.length > 0 && values.length > 0) {
  //     const element = [];
  //     names.forEach((serie, index) => {
  //       element.push({ name: serie, data: values[index] });
  //     });
  //     setSeries(element);

  //     console.log('names', names);
  //     console.log('values', values);
  //     let tableData = [];
  //     for (let i = 0; i < categories.length; i++) {
  //       tableData.push({});
  //       tableData[i].date = categories[i];
  //       for (let j = 0; j < names.length; j++) {
  //         tableData[i][names[j]] = values[j][i];
  //       }
  //     }
  //     setExcelTableData(tableData);
  //     setPaginationCount(tableData.length);
  //   }
  // }, [values]);

  // useEffect(() => {
  //   let headers = ['date'].concat(names);
  //   setHeaderNames(headers);
  // }, [names]);

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    colors: ['#ff3c77', '#49158b', '#ff4f2b', '#08bfff', '#ffae16'],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    title: {
      text: 'Planned events',
    },
    subtitle: {
      text: subtitle,
      align: 'left',
      offsetY: 20,
      style: {
        color: '#333',
        fontSize: '10px',
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: see,
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
      },
      offsetX: 0,
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
  };

  // const [showTableData, setShowTableData] = useState(false);
  // const handleCloseModal = () => {
  //   setShowTableData(false);
  // };

  return (
    <>
      <Card className="p-3 my-2">
        {/* <SimpleTableModal
          title={'Unplanned Events'}
          headers={headerNames}
          tableData={excelTableData}
          show={showTableData}
          handleClose={handleCloseModal}
          totalCount={paginationCount}
        />
        <div>
          <Row className="flex-row-reverse">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle className="fpb-btn-chart" id="dropdown-download" />
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowTableData(true);
                  }}
                >
                  Show Data
                </Dropdown.Item>
                <ExportExcel
                  excelData={excelTableData}
                  fileName={
                    indicatorName + ' Report (' + filterList[0] + ' -' + filterList[1] + ')'
                  }
                  indicator={indicatorName}
                  filters={filterList}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </div> */}
        <Chart
          options={JSON.parse(JSON.stringify(options))}
          series={JSON.parse(JSON.stringify(see == 'minutes' ? seriesByMinutes : seriesByHours))}
          type="bar"
          height={420}
        />
      </Card>
    </>
  );
};

export default PlannedEvents;
