import { Row, Col, Button } from 'react-bootstrap';
import { FaPause, FaCheck } from 'react-icons/fa';
import PropTypes from 'prop-types';

const ControlButtons = ({ handlePause, timer, handleDone, ...props }) => {
  return (
    <Row className="d-flex pt-3">
      <Col className="justify-content-center col-md-3 col-lg-2">
        <Button
          className="btn-light control-button text-center"
          onClick={handlePause}
          disabled={props.disabled ? props.disabled : false}
        >
          <FaPause fontSize={22} />
        </Button>
        <b>
          <p className="text-center my-0 py-0">Pause</p>
        </b>
      </Col>
      <Col className="justify-content-center col-md-6 col-lg-7">
        <div className="clock text-center">
          <p className="text-center m-0">{timer || '00:00:00'}</p>
        </div>
        <b>
          <p className="text-center my-0 py-0">Elapsed Time</p>
        </b>
      </Col>
      <Col className="justify-content-center col-md-3 col-lg-2">
        <Button
          className="btn-light control-button text-center"
          onClick={handleDone}
          disabled={props.disabled ? props.disabled : false}
        >
          <FaCheck fontSize={22} />
        </Button>
        <b>
          <p className="text-center my-0 py-0">Done</p>
        </b>
      </Col>
    </Row>
  );
};

ControlButtons.propTypes = {
  handlePause: PropTypes.func.isRequired,
  handleDone: PropTypes.func.isRequired,
  timer: PropTypes.string,
};

export default ControlButtons;
