import React, { useState } from 'react';
import { Grid, Table, TableHeaderRow, TableFilterRow } from '@devexpress/dx-react-grid-bootstrap4';
import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import * as HiIcons from 'react-icons/hi';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NotesModal from '../NotesModal/NotesModal';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';

const UploadFilesTable = () => {
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState([{}]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mainColumns = [
    { name: 'name', title: 'Table' },
    { name: 'notes', title: 'Notes' },
    { name: 'template', title: 'Template' },
  ];
  const mainRows = [
    {
      name: 'Item',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/item" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Storage Center',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/center" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Storage Location',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/location" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Item Category',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/category" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Cost',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/cost" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Cost Type',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/type" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Dimensional Unit',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/dimensional" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Supplier',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/supplier" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Customer',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/customer" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Adjustment',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/adjustment" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
  ];

  const operationalColumns = [
    { name: 'name', title: 'Table' },
    { name: 'notes', title: 'Notes' },
    { name: 'template', title: 'Template' },
  ];
  const operationalRows = [
    {
      name: 'Item Storage Center Relation',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/storage" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Batch',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/batch" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Entry Order',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/entry" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Dispatch Order',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/dispatch" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Item Batch Relation',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/relation" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
  ];

  return (
    <>
      <HeaderTitle title="Main Tables" />
      <div className="card">
        <Grid rows={mainRows} columns={mainColumns}>
          <FilteringState
            columnExtensions={[
              { columnName: 'notes', filteringEnabled: false },
              { columnName: 'template', filteringEnabled: false },
            ]}
            filters={filters}
            onFiltersChange={setFilters}
          />
          <IntegratedFiltering />
          <Table />
          <TableFilterRow />
          <TableHeaderRow />
        </Grid>
      </div>
      <br />
      <HeaderTitle title="Operational Tables" />
      <div className="card">
        <Grid rows={operationalRows} columns={operationalColumns}>
          <FilteringState filters={filters} onFiltersChange={setFilters} />
          <IntegratedFiltering />
          <Table />
          <TableHeaderRow />
        </Grid>
      </div>
      <NotesModal show={show} handleClose={handleClose} />
    </>
  );
};

export default UploadFilesTable;
