import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTitle from '../../components/CoreComponents/PageTitle/PageTitle';
import WorkCenterCard from '../../components/Cards/WorkCenterCard';
import {
  fetchWorkCenters,
  fetchWorkCenterCategories,
  selectAllWorkCenters,
  selectWorkCenterCategories,
  changeCategorySelected,
} from '../../redux/slices/workCenterSlice';

const ProcessControlSection = ({ title }) => {
  const dispatch = useDispatch();
  const workCenters = useSelector(selectAllWorkCenters);
  const workCenterCategories = useSelector(selectWorkCenterCategories);

  useEffect(() => {
    dispatch(fetchWorkCenters());
    dispatch(fetchWorkCenterCategories());
  }, [dispatch]);

  return (
    <>
      <HeaderTitle title={title} />
      <Row>
        <Button
          pill="true"
          className="btn-light secondary ml-4 mb-4 purple"
          key="all"
          onClick={() => dispatch(changeCategorySelected('all'))}
        >
          All
        </Button>
        {workCenterCategories.map((workCenterCategory) => (
          <Button
            pill="true"
            className="btn-light secondary ml-3 mb-4 purple"
            key={workCenterCategory.code}
            onClick={() => dispatch(changeCategorySelected(workCenterCategory.code))}
          >
            {workCenterCategory.description}
          </Button>
        ))}
      </Row>
      <Row className="mb-3 p-2">
        <Col className="column-container py-3 px-1">
          <p className="text-muted px-2 font-weight-bold">AVAILABLE</p>
          {workCenters.map((workCenter) => {
            if (workCenter.status === 'available') {
              return (
                <WorkCenterCard
                  key={workCenter.code}
                  code={workCenter.code}
                  description={workCenter.description}
                  operationCenter={workCenter.operation_center}
                  status="Available"
                />
              );
            }
          })}
        </Col>
        <Col className="column-container py-3 px-1 mx-0">
          <p className="text-muted px-2 font-weight-bold">IN PROCESS</p>
          {workCenters?.map((workCenter) => {
            if (workCenter.status === 'in process') {
              return (
                <WorkCenterCard
                  key={workCenter.code}
                  code={workCenter.code}
                  description={workCenter.description}
                  status="In process"
                />
              );
            }
          })}
        </Col>
        <Col className="column-container py-3 px-1">
          <p className="text-muted px-2 font-weight-bold">IN EVENT</p>
          {workCenters?.map((workCenter) => {
            if (workCenter.status === 'in event') {
              return (
                <WorkCenterCard
                  key={workCenter.code}
                  code={workCenter.code}
                  description={workCenter.description}
                  status="In event"
                />
              );
            }
          })}
        </Col>
      </Row>
    </>
  );
};

export default ProcessControlSection;
