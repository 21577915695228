import React from 'react';
import { Modal } from 'react-bootstrap';

const ActionConfirmationWarning = ({ show, handleClose, title, message }) => {
  return (
    message && (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
      </Modal>
    )
  );
};

export default ActionConfirmationWarning;
