import React, { useEffect } from 'react';
import { FaBoxes, FaPallet, FaClipboardList } from 'react-icons/fa';
import LogoFPB from '../CoreComponents/LogoFPB/LogoFPB';
import DashboardCard from '../CoreComponents/DashboardCard/DashboardCard';
import apiService from '../apiService';
import Urls from '../Urls';

const Home = () => {
  useEffect(async () => {
    apiService
      .getData(Urls.item, 'reference_code')
      .then()
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <h1>Industrial Home</h1>
      <LogoFPB logoWidth="450px" />
      <div className="d-flex flex-wrap justify-content-start fpb-cards">
        <DashboardCard
          title="Shift Assignment"
          description="Shift assignment calendar"
          icon={<FaBoxes />}
          path="/industrial/shift_assignment"
          color="blueColor"
        />
        <DashboardCard
          title="Process Control"
          description="Process control tables"
          icon={<FaPallet />}
          path="/industrial/process_control"
          color="pinkColor"
        />
        <DashboardCard
          title="Production Orders"
          description="Prodcution orders table"
          icon={<FaClipboardList />}
          path="/industrial/production_order"
          color="mainColor"
        />
      </div>
    </div>
  );
};

export default Home;
