import { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Form } from 'react-bootstrap';
import Urls from '../Urls';
import axios from 'axios';

const CompanySelection = ({ onChange, value, ...props }) => {
  const [token] = useState(localStorage.getItem('accessToken'));
  const url = Urls.company;

  const getUpdatedCompany = async (inputValue, loadedOptions, { page }) => {
    const query = `${url}?page=${page}&page_size=${10}&name__icontains=${inputValue}`;
    let optionsResults = [];
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            optionsResults.push({
              value: result.id,
              label: result.name,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return {
      options: optionsResults,
      hasMore: optionsResults.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <>
      <Form.Label className="label">{props.label || 'Company'}</Form.Label>
      <AsyncPaginate
        {...props}
        isClearable={true}
        defaultOptions
        value={value}
        loadOptions={getUpdatedCompany}
        onChange={onChange}
        additional={{
          page: 1,
        }}
      />
    </>
  );
};

export default CompanySelection;
