import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';
import Urls from '../../components/Urls';
import { getFiltersString } from '../../utils/tableFilters';

export const fetchWorkCentersPaginated = createAsyncThunk(
  'workCenter/fetchWorkCenters',
  async ({ currentPage, pageSize, filters }) => {
    try {
      const filtersString = getFiltersString(filters);
      const url = `${Urls.workCenter}?page=${currentPage + 1}&page_size=${pageSize}`;
      const res = await apiService.industrialClient().get(url);
      const { results } = res.data || 0;

      if (results.length > 0) {
        // discard the fields the user will not visualize in tables
        const headersList = Object.keys(results[0]).filter((elem) => {
          let returnColumn;
          if (elem === 'id' || elem === 'work_center_category' || elem === 'operation_center') {
            returnColumn = false;
          } else {
            returnColumn = true;
          }
          return returnColumn;
        });
        return {
          currentPage,
          pageSize,
          total: res.data.count,
          data: res.data.results,
          headers: headersList,
        };
      }
      return {
        currentPage,
        pageSize,
        total: res.data.count,
        data: res.data.results,
        headers: [],
      };
    } catch (err) {
      return err.message;
    }
  }
);

export const fetchWorkCenters = createAsyncThunk('workCenters/fetchWorkCenters', async () => {
  const res = await apiService.getWorkCenters();
  return res.data;
});

export const fetchWorkCenterCategories = createAsyncThunk(
  'workCenters/fetchWorkCenterCategories',
  async () => {
    const res = await apiService.getWorkCenterCategory();
    return res.data;
  }
);

export const fetchWorkCenterEvent = createAsyncThunk(
  'workCenters/fetWorkCenterEvent',
  async ({ workCenter }) => {
    const res = await apiService.getWorkCenterEvent(workCenter);
    if (res.data) {
      return res.data;
    }
    return {};
  }
);

const initialState = {
  currentWorkCenter: {},
  currentWorkCenterHasIoT: false,
  currentWorkCenterHasQualityIoT: false,
  currentWorkCenterConfigurations: [],
  workCenterEvents: {},
  workCenters: [],
  workCenterCategories: [],
  workCentersByCategory: [],
  categorySelected: 'all',
  statusToColors: {
    'In process': '#ffae16',
    'In event': '#ff3c77',
    Available: '#6cffa7',
  },
  workCenterModal: {
    page: 0,
    pageSize: 5,
    total: 0,
    data: [],
    headers: [],
  },
};

const workCenterSlice = createSlice({
  name: 'workCenter',
  initialState,
  reducers: {
    setCurrentWorkCenter: (state, action) => {
      state.currentWorkCenter = action.payload;
    },
    setCurrentWorkCenterHasQualityIoT: (state, action) => {
      state.currentWorkCenterHasQualityIoT = action.payload;
    },
    setCurrentWorkCenterConfigurations: (state, action) => {
      state.currentWorkCenterConfigurations = action.payload;
      const hasIoT = action.payload.some((config) => config.data_source === 'iot');
      state.currentWorkCenterHasIoT = hasIoT;
    },
    changeCategorySelected: (state, action) => {
      const categorySelected = action.payload;
      const workCenters = state.workCenters;
      state.categorySelected = action.payload;
      if (categorySelected === 'all') {
        state.workCentersByCategory = workCenters;
      } else {
        state.workCentersByCategory = workCenters.filter((workCenter) => {
          return workCenter.work_center_category === categorySelected;
        });
      }
    },
    setWorkCenterPageSize: (state, action) => {
      state.workCenterModal.pageSize = action.payload;
    },
    setWorkCenterCurrentPage: (state, action) => {
      state.workCenterModal.page = action.payload;
      console.log(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkCenters.fulfilled, (state, action) => {
      state.workCenters = action.payload;
      state.workCentersByCategory = action.payload;
    });
    builder.addCase(fetchWorkCenterCategories.fulfilled, (state, action) => {
      state.workCenterCategories = action.payload;
    });
    builder.addCase(fetchWorkCenterEvent.fulfilled, (state, action) => {
      action.payload.forEach((event) => {
        state.workCenterEvents[event.work_center] = event;
      });
    });
    builder.addCase(fetchWorkCentersPaginated.fulfilled, (state, action) => {
      state.workCenterModal.data = action.payload.data;
      state.workCenterModal.total = action.payload.total;
      state.workCenterModal.headers = action.payload.headers;
    });
  },
});

export const selectAllWorkCenters = (state) => state.workCenters.workCentersByCategory;
export const selectWorkCenterCategories = (state) => state.workCenters.workCenterCategories;
export const workCenterEvents = (state) => state.workCenters.workCenterEvents;

export const selectModalWorkCenters = (state) => state.workCenters.workCenterModal.data;
export const selectWorkCenterHeaders = (state) => state.workCenters.workCenterModal.headers;
export const selectWorkCentersTotalCount = (state) => state.workCenters.workCenterModal.total;
export const selectWorkCentersCurrentPage = (state) => state.workCenters.workCenterModal.page;
export const selectWorkCentersPageSize = (state) => state.workCenters.workCenterModal.pageSize;
export const selectWorkCenterColumnsToHide = (state) => state.workCenters.columnsToHide;

export const {
  setCurrentWorkCenter,
  setCurrentWorkCenterHasQualityIoT,
  setCurrentWorkCenterConfigurations,
  changeCategorySelected,
  setWorkCenterPageSize,
  setWorkCenterCurrentPage,
} = workCenterSlice.actions;

export default workCenterSlice.reducer;
