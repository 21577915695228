import { useSelector } from 'react-redux';
import LockedMessage from './LockedMessage';

const ProtectedRoute = ({ children, module }) => {
  const user = useSelector((state) => state.user);

  if (user.defaultModule === 'All') {
    return children;
  }

  if (module === 'Inventory' && user.defaultModule !== 'Inventory') {
    return <LockedMessage />;
  } else if (module === 'Industrial' && user.defaultModule !== 'Industrial') {
    return <LockedMessage />;
  }

  return children;
};

export default ProtectedRoute;
