import React, { useState } from 'react';
import { Grid, Table, TableHeaderRow, TableFilterRow } from '@devexpress/dx-react-grid-bootstrap4';
import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import * as HiIcons from 'react-icons/hi';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NotesModal from '../NotesModal/NotesModal';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';

const IndustrialUploadFilesTable = () => {
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState([{}]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mainColumns = [
    { name: 'name', title: 'Table' },
    { name: 'notes', title: 'Notes' },
    { name: 'template', title: 'Template' },
  ];
  const mainRows = [
    {
      name: 'Product',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/product" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Unit Transformation',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/unit_transformation" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Work Center',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/work_center" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Work Center Capacity',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/work_center_capacity" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Event Category',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/event_category" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Product Category',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/product_category" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Work Center Category',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/work_center_category" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Dimensional Unit',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/dimensional_unit" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Event Type',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/event_type" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Operation Center',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/operation_center" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Quality Dimension',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/quality_dimension" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Variable',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/variable" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Shift',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/shift" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Process',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/process" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Route',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/process_route" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Work Center Per Process',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/work_center_per_process" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
  ];

  const operationalColumns = [
    { name: 'name', title: 'Table' },
    { name: 'notes', title: 'Notes' },
    { name: 'template', title: 'Template' },
  ];
  const operationalRows = [
    {
      name: 'Production Order',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/simplified_production_order" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
    {
      name: 'Production Batch',
      notes: (
        <Button className="fpb-btn" onClick={handleShow}>
          View
        </Button>
      ),
      template: (
        <Link to="/upload/production_batch" className="page-link">
          <HiIcons.HiTemplate />
        </Link>
      ),
    },
  ];
  return (
    <>
      <HeaderTitle title="Main Tables" />
      <div className="card mb-5">
        <Grid rows={mainRows} columns={mainColumns}>
          <FilteringState
            columnExtensions={[
              { columnName: 'notes', filteringEnabled: false },
              { columnName: 'template', filteringEnabled: false },
            ]}
            filters={filters}
            onFiltersChange={setFilters}
          />
          <IntegratedFiltering />
          <Table />
          <TableFilterRow />
          <TableHeaderRow />
        </Grid>
      </div>
      <br />
      <HeaderTitle title="Operational Tables" />
      <div className="card">
        <Grid rows={operationalRows} columns={operationalColumns}>
          <FilteringState filters={filters} onFiltersChange={setFilters} />
          <IntegratedFiltering />
          <Table />
          <TableHeaderRow />
        </Grid>
      </div>
      <NotesModal show={show} handleClose={handleClose} />
    </>
  );
};

export default IndustrialUploadFilesTable;
