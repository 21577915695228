import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import apiService from '../apiService';
import 'react-toastify/dist/ReactToastify.css';
import EventTypeSelection from '../SelectionComponents/EventTypeSelection';

const StartEventWithoutOrder = ({
  added,
  setAdded,
  setAddForm,
  setAlertMessage,
  setErrorMessage,
}) => {
  //redux
  const currentWorkCenter = useSelector((state) => state.workCenters.currentWorkCenter);
  const [formDate, setFormDate] = useState(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"));

  const manualValidate = Yup.object({
    eventType: Yup.object({
      value: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
    })
      .required('Required')
      .nullable(),
  });

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={manualValidate}
        onSubmit={async (data) => {
          const transformedDate = new Date(formDate);
          const processedValues = {
            workCenter: currentWorkCenter.code,
            startDate: transformedDate.toISOString(),
            eventType: data.eventType.value,
          };

          try {
            const created = await apiService.postStartEventWithoutOrder(processedValues);
            if (created.status === 201) {
              setAlertMessage('Event reported successfully');
              setAddForm(false);
              setAdded(true);
            }
          } catch (err) {
            console.log(err);
            if (err.error) {
              setErrorMessage(err.error);
            } else {
              setErrorMessage('Error while creating event');
            }
            setAddForm(false);
            setAdded(false);
          }
        }}
        initialValues={{
          eventType: null,
        }}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <EventTypeSelection
                value={values.eventType}
                name={'eventType'}
                onChange={(option) => {
                  setFieldValue('eventType', option ? option : {});
                }}
              />
              <TextField
                label="Date"
                name="date"
                type="datetime-local"
                defaultValue={format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}
                value={formDate}
                onChange={(e) => setFormDate(e.target.value)}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Confirm'}
            </Button>
            <Button
              className="fpb-btn-inverse mt-3 ml-2"
              onClick={() => setAddForm(false)}
              disabled={isSubmitting}
            >
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default StartEventWithoutOrder;
