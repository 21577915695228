import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';

const LabelForm = ({ onAdded, transformCode, values, handleAPIRequest, added, setAddForm }) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});

  const costTypeValidate = Yup.object({
    labelCode: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string(),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);
  return (
    <>
      <Formik
        validateOnChange
        validationSchema={costTypeValidate}
        onSubmit={(data) => {
          const processedValues = {
            label_code: data.labelCode,
            description: data.description,
          };
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          handleAPIRequest(processedValues, {
            entity: 'Label',
            code: data.labelCode,
          });
        }}
        initialValues={{
          labelCode: '',
          description: '',
        }}
      >
        {({ values, touched, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField label="Label code" name="labelCode" type="text" />
            </Form.Row>
            <Form.Row>
              <TextField label="Description" name="description" type="text" />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LabelForm;
