import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import TextField from '../FormComponents/TextField/TextField';
import SelectItems from '../FormComponents/SelectItems/SelectItems';
import TableModal from '../TableModal/TableModal';
import apiService from '../apiService';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import Urls from '../Urls';
import { setIsModalPaginationAction } from '../../redux/actions/paginationAction';
import CustomAlert from '../AlertMessage/CustomAlert';
import { notification } from '../AlertMessage/ToastifyAlert';

const Cost = ({
  onAdded,
  handleAPIRequest,
  added,
  setAddForm,
  values,
  handleAPIRequestUpdate,
  onEdited,
}) => {
  const [valuesToEdit, setValuesToEdit] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [showItemModal, setShowItemModal] = useState(false);
  const [showCostTypeModal, setShowCostTypeModal] = useState(false);
  const [showDimensionalUnitModal, setShowDimensionalUnitModal] = useState(false);
  const [itemCodeTransformed, setItemCodeTransformed] = useState();
  const [dimensionalUnitCodeTransformed, setDimensionalUnitCodeTransformed] = useState();
  const [uniqueUnitCostCodeTransformed, setUniqueUnitCostCodeTransformed] = useState();
  const [uniqueCostTypeDefaultOptions, setUniqueCostTypeDefaultOptions] = useState({});
  const [uniqueCostTypeFromModal, setUniqueCostTypeFromModal] = useState(false);
  const [itemReferenceCodeDefaultOptions, setItemReferenceCodeDefaultOptions] = useState({});
  const [itemReferenceCodeFromModal, setItemReferenceCodeFromModal] = useState(false);
  const [dimensionalUnitDefaultOptions, setDimensionalUnitDefaultOptions] = useState({});
  const [dimensionalUnitFromModal, setDimensionalUnitFromModal] = useState(false);

  //Modal warning
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [stateCost, setStateCost] = useState(values.unit_cost_state || 'active');
  const [itemCode, setItemCode] = useState('');
  const [costTypeCode, setCostTypeCode] = useState('');

  const [descriptions, setDescriptions] = useState({
    costDescription: '',
    itemDescription: '',
    dimensionalDescription: '',
  });
  const [itemData, setItemData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [dimensionalUnitData, setDimensionalUnitData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [costTypeData, setCostTypeData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [activeCostData, setActiveCostData] = useState([]);

  const costValidate = Yup.object({
    monetaryValueN0: Yup.number()
      .typeError('Must be a  number (5, 5.6, 7.85)')
      .positive('Must be a positive number'),
    unitCostState: Yup.string()
      .required('Required')
      .oneOf(['active', 'inactive'], 'Not valid state'),
    uniqueCostTypeCode: Yup.string().required('Required'),
    itemReferenceCode: Yup.string().required('Required'),
    dimensionalUnitCode: Yup.string().required('Required'),
  });

  //pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };
  const itemColumnToTransform = [
    {
      name: 'item_category',
      url: 'itemCategory',
      field: 'item_category_code',
    },
    {
      name: 'label',
      url: 'label',
      field: 'label_code',
    },
    {
      name: 'consolidation_level_N0',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N1',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N2',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N3',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N4',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
  ];

  const costTypeColumnToTransform = [
    {
      name: 'customer_identification',
      url: 'customer',
      field: 'customer_identification',
    },
    {
      name: 'supplier_identification',
      url: 'supplier',
      field: 'supplier_identification',
    },
  ];

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
        unique_cost_type_code: itemCodeTransformed,
        item_reference_code: uniqueUnitCostCodeTransformed,
        dimensional_unit_code: dimensionalUnitCodeTransformed,
      });
    }
  }, [added]);

  useEffect(async () => {
    try {
      const resItem = await apiService.getData(Urls.item, 'reference_code');
      const resDimensionalUnit = await apiService.getData(
        Urls.dimensionalUnit,
        'dimensional_unit_code'
      );
      const resCostType = await apiService.getData(Urls.costType, 'unique_cost_type_code');
      const resCost = await apiService.getData(Urls.cost, 'unique_unit_cost_code');
      const filteredCosts = resCost.data.filter((cost) => cost.unit_cost_state === 'active');

      setActiveCostData(filteredCosts);

      setItemData({
        headers: resItem.headersList,
        tableData: resItem.data,
        options: resItem.results,
      });

      setDimensionalUnitData({
        headers: resDimensionalUnit.headersList,
        tableData: resDimensionalUnit.data,
        options: resDimensionalUnit.results,
      });

      setCostTypeData({
        headers: resCostType.headersList,
        tableData: resCostType.data,
        options: resCostType.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCloseItemModal = () => {
    setIsModalPagination();
    setShowItemModal(false);
  };

  const handleCloseCostTypeModal = () => {
    setIsModalPagination();
    setShowCostTypeModal(false);
  };

  const handleCloseDimensionalTypeModal = () => {
    setIsModalPagination();
    setShowDimensionalUnitModal(false);
  };

  const handleUniqueCostTypeConfirm = (selected, title, setFieldValue) => {
    setUniqueCostTypeDefaultOptions({
      value: selected.id,
      label: selected.unique_cost_type_code,
      description: selected.description,
    });
    setValuesToEdit({
      ...valuesToEdit,
      unique_cost_type_code: selected.id,
    });
    setFieldValue('uniqueCostTypeCode', selected.id);
    setUniqueUnitCostCodeTransformed(selected.unique_cost_type_code);
    setCostTypeCode(selected.id);
    setShowCostTypeModal(false);
    setUniqueCostTypeFromModal(true);
  };

  const handleItemReferenceConfirm = (selected, title, setFieldValue) => {
    setItemReferenceCodeDefaultOptions({
      value: selected.id,
      label: selected.reference_code,
      description: selected.item_description,
    });
    setValuesToEdit({
      ...valuesToEdit,
      item_reference_code: selected.id,
    });
    setItemCode(selected.id);
    setFieldValue('itemReferenceCode', selected.id);
    setItemCodeTransformed(selected.reference_code);
    setShowItemModal(false);
    setItemReferenceCodeFromModal(true);
  };

  const handleDimensionalUnitConfirm = (selected, title, setFieldValue) => {
    setDimensionalUnitDefaultOptions({
      value: selected.id,
      label: selected.dimensional_unit_code,
      description: selected.description,
    });
    setValuesToEdit({
      ...valuesToEdit,
      dimensional_unit_code: selected.id,
    });
    setFieldValue('dimensionalUnitCode', selected.id);
    setDimensionalUnitCodeTransformed(selected.dimensional_unit_code);
    setShowDimensionalUnitModal(false);
    setDimensionalUnitFromModal(true);
  };

  const validateCost = () => {
    //When saving or editing an active cost,
    //check that there is no other active cost in the database with the same combination of item and cost type.
    if (stateCost === 'active' && itemCode !== '' && costTypeCode !== '') {
      const previusCost = activeCostData.filter(
        (cost) =>
          cost.item_reference_code === itemCode &&
          cost.unique_cost_type_code === costTypeCode &&
          cost.unit_cost_state === 'active' &&
          cost.unique_unit_cost_code !== values.unique_unit_cost_code
      );
      if (previusCost[0] !== undefined) {
        setWarningMessage(
          `An active cost already exists for the selected item and cost type combination. If you save this new cost with active status, the previous cost with code [${previusCost[0].unique_unit_cost_code}] will be inactivated.`
        );
        setShowModalWarning(true);
      }
    } else {
      setShowModalWarning(false);
    }
  };

  useEffect(() => {
    validateCost();
  }, [stateCost, itemCode, costTypeCode]);

  useEffect(() => {
    itemData.options.forEach((option) => {
      if (option.value === values.item_reference_code) {
        setDescriptions(
          option
            ? { ...descriptions, itemDescription: option.description }
            : { ...descriptions, itemCenterDescription: '' }
        );
        setItemCode(option.id);
      }
    });
  }, [itemData]);

  useEffect(() => {
    costTypeData?.options.forEach((option) => {
      if (option.value === values.unique_cost_type_code) {
        setDescriptions(
          option
            ? { ...descriptions, costDescription: option.description }
            : { ...descriptions, costDescription: '' }
        );
        setCostTypeCode(option.id);
      }
    });
  }, [costTypeData]);

  useEffect(() => {
    dimensionalUnitData.options.forEach((option) => {
      if (option.value === values.dimensional_unit_code) {
        setDescriptions(
          option
            ? { ...descriptions, dimensionalDescription: option.description }
            : { ...descriptions, dimensionalDescription: '' }
        );
      }
    });
  }, [dimensionalUnitData]);

  return (
    <Formik
      validateOnChange
      validationSchema={costValidate}
      onSubmit={async (data) => {
        const processedValues = {
          unique_unit_cost_code: data.uniqueUnitCostCode,
          monetary_value_N0: data.monetaryValueN0,
          unit_cost_state: data.unitCostState,
          unique_cost_type_code: data.uniqueCostTypeCode,
          item_reference_code: data.itemReferenceCode,
          dimensional_unit_code: data.dimensionalUnitCode,
        };
        setProcessedFormValues(processedValues);
        if (values.id == null) {
          const created = await handleAPIRequest(processedValues, {
            entity: 'Cost',
            code: data.uniqueUnitCostCode,
          });
          if (created.statusText !== 'Created') {
            notification('Error creating item - duplicate item', 'error');
          }
        } else {
          const editedValues = {
            unique_unit_cost_code: data.uniqueUnitCostCode,
            monetary_value_N0: data.monetaryValueN0,
            unit_cost_state: data.unitCostState,
            unique_cost_type_code: uniqueUnitCostCodeTransformed || values.unique_cost_type_code,
            item_reference_code: itemCodeTransformed || values.item_reference_code,
            dimensional_unit_code: dimensionalUnitCodeTransformed || values.dimensional_unit_code,
          };
          const updated = await handleAPIRequestUpdate(data.id, valuesToEdit, {
            entity: 'Cost',
            code: data.uniqueUnitCostCode,
          });
          if (updated.statusText == 'OK') {
            onEdited(editedValues);
            setAddForm(false);
            notification('Item updated successfully', 'success');
            setTimeout('window.location.reload(false);', 1500);
          } else {
            notification('Error updating item - duplicate item', 'error');
          }
        }
      }}
      initialValues={{
        id: values.id,
        uniqueUnitCostCode: values.unique_unit_cost_code,
        monetaryValueN0: values.monetary_value_N0 || 0,
        unitCostState: values.unit_cost_state || 'active',
        uniqueCostTypeCode: values.unique_cost_type_code || '',
        itemReferenceCode: values.item_reference_code,
        dimensionalUnitCode: values.dimensional_unit_code,
      }}
    >
      {({ setFieldValue, values, handleChange, handleBlur, handleSubmit }) => (
        <>
          {showItemModal ? (
            <TableModal
              url={Urls.item}
              title="Item Code"
              show={showItemModal}
              handleClose={handleCloseItemModal}
              formName={'item'}
              columnToTransform={itemColumnToTransform}
              handleConfirmation={handleItemReferenceConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showCostTypeModal ? (
            <TableModal
              url={Urls.costType}
              title="Cost Type"
              show={showCostTypeModal}
              handleClose={handleCloseCostTypeModal}
              formName={'costType'}
              columnToTransform={costTypeColumnToTransform}
              handleConfirmation={handleUniqueCostTypeConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showDimensionalUnitModal ? (
            <TableModal
              url={Urls.dimensionalUnit}
              title="Dimensional Unit Code"
              show={showDimensionalUnitModal}
              handleClose={handleCloseDimensionalTypeModal}
              formName={'dimensionalUnit'}
              columnToTransform={[]}
              handleConfirmation={handleDimensionalUnitConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          <Form onSubmit={handleSubmit}>
            <Form.Row className="g-2">
              <SelectItems
                options={['active', 'inactive']}
                label="Unit Cost State"
                name="unitCostState"
                handleChange={handleChange}
                onBlur={handleBlur}
                value={values.unitCostState}
                className="col-md-6 col-sm-12"
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    unit_cost_state: e.target.value,
                  });
                  setStateCost(e.target.value);
                }}
              />
              {uniqueCostTypeFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      unique_cost_type_code: option ? option.id : '',
                    });
                    setFieldValue('uniqueCostTypeCode', option ? option.id : '');
                    setUniqueUnitCostCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, costDescription: option.description }
                        : { ...descriptions, costDescription: '' }
                    );
                    setCostTypeCode(option ? option.id : '');
                    setUniqueCostTypeFromModal(false);
                  }}
                  name="uniqueCostTypeCode"
                  label="Unique Cost Type Code"
                  options={costTypeData.options}
                  setShow={setShowCostTypeModal}
                  defaultValue={uniqueCostTypeDefaultOptions}
                  value={uniqueCostTypeDefaultOptions}
                  description={uniqueCostTypeDefaultOptions.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      unique_cost_type_code: option ? option.id : '',
                    });
                    setFieldValue('uniqueCostTypeCode', option ? option.id : '');
                    setUniqueUnitCostCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, costDescription: option.description }
                        : { ...descriptions, costDescription: '' }
                    );
                    setCostTypeCode(option ? option.id : '');
                  }}
                  name="uniqueCostTypeCode"
                  label="Unique Cost Type Code"
                  options={costTypeData.options}
                  setShow={setShowCostTypeModal}
                  defaultValue={{
                    value: values.uniqueCostTypeCode,
                    label: values.uniqueCostTypeCode,
                  }}
                  description={descriptions.costDescription}
                />
              )}
            </Form.Row>
            <Form.Row>
              <TextField
                label="Monetary value N0"
                name="monetaryValueN0"
                type="text"
                value={values.monetaryValueN0}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    monetary_value_N0: e.target.value,
                  });
                }}
              />
              {itemReferenceCodeFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      item_reference_code: option ? option.id : '',
                    });
                    setFieldValue('itemReferenceCode', option ? option.id : ''),
                      setItemCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, itemDescription: option.description }
                        : { ...descriptions, itemDescription: '' }
                    );
                    setItemCode(option ? option.id : '');
                    setItemReferenceCodeFromModal(false);
                  }}
                  name="itemReferenceCode"
                  label="Item Reference Code"
                  options={itemData.options}
                  setShow={setShowItemModal}
                  defaultValue={itemReferenceCodeDefaultOptions}
                  value={itemReferenceCodeDefaultOptions}
                  description={itemReferenceCodeDefaultOptions.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      item_reference_code: option ? option.id : '',
                    });
                    setFieldValue('itemReferenceCode', option ? option.id : ''),
                      setItemCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, itemDescription: option.description }
                        : { ...descriptions, itemDescription: '' }
                    );
                    setItemCode(option ? option.id : '');
                  }}
                  name="itemReferenceCode"
                  label="Item Reference Code"
                  options={itemData.options}
                  setShow={setShowItemModal}
                  defaultValue={{
                    value: values.itemReferenceCode,
                    label: values.itemReferenceCode,
                  }}
                  description={descriptions.itemDescription}
                />
              )}
            </Form.Row>
            <Form.Row>
              {dimensionalUnitFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      dimensional_unit_code: option ? option.id : '',
                    });
                    setFieldValue('dimensionalUnitCode', option ? option.id : '');
                    setDimensionalUnitCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            dimensionalDescription: option.description,
                          }
                        : { ...descriptions, dimensionalDescription: '' }
                    );
                    setDimensionalUnitFromModal(false);
                  }}
                  name="dimensionalUnitCode"
                  label="Dimensional Unit Code"
                  options={dimensionalUnitData.options}
                  setShow={setShowDimensionalUnitModal}
                  defaultValue={dimensionalUnitDefaultOptions}
                  value={dimensionalUnitDefaultOptions}
                  description={dimensionalUnitDefaultOptions.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      dimensional_unit_code: option ? option.id : '',
                    });
                    setFieldValue('dimensionalUnitCode', option ? option.id : '');
                    setDimensionalUnitCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            dimensionalDescription: option.description,
                          }
                        : { ...descriptions, dimensionalDescription: '' }
                    );
                  }}
                  name="dimensionalUnitCode"
                  label="Dimensional Unit Code"
                  options={dimensionalUnitData.options}
                  setShow={setShowDimensionalUnitModal}
                  defaultValue={{
                    value: values.dimensionalUnitCode,
                    label: values.dimensionalUnitCode,
                  }}
                  description={descriptions.dimensionalDescription}
                />
              )}
            </Form.Row>
            {showModalWarning ? (
              <CustomAlert message={warningMessage} variant="warning" />
            ) : (
              <div />
            )}

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Cost;
