import React, { useState, useEffect } from 'react';
import { Form, Button, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';
import Urls from '../Urls';
import Step from '../Cards/Step';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentProductionOrderCode,
  setOpenPPOForm,
  setTouchedOrder,
} from '../../redux/slices/productionOrderSlice';
import styled from 'styled-components';
import { Formik, useFormikContext } from 'formik';
import { ProductSelection, ProductionBatchSelection } from '../SelectionComponents';
import { SpinnerWithText } from '../Loading';
import ErrorBoundary from '../../utils/ErrorBoundary';

const ProductPerProdOrder = ({
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  onEdited,
  setOpenEditForm,
  status,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialValuesLoading, setInitialValuesLoading] = useState(true);
  const [statusProductionOrder, setStatusProductionOrder] = useState(status);
  const [product, setProduct] = useState('');
  const [productionBatch, setProductionBatch] = useState(values?.production_batch || '');
  const [warningMessage, setWarningMessage] = useState(null);
  const [calculationVariableMap, setCalculationVariableMap] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [formValues, setFormValues] = useState({});
  const [stepsInfo, setStepsInfo] = useState(null);
  const currentProductionOrder = useSelector(selectCurrentProductionOrderCode);
  const errorForm = useSelector((state) => state.productPerProductionOrders.errorForm);
  const isEditForm = onEdited !== undefined;
  const productionOrder = isEditForm ? values.production_order : currentProductionOrder;
  const [descriptions, setDescriptions] = useState({
    workCenterDescription: '',
    productDescription: '',
    dimensionalUnit1Description: '',
    dimensionalUnit2Description: '',
    dimensionalUnit3Description: '',
  });
  const [productionBatchData, setProductionBatchData] = useState({});

  const [processPathData, setProcessPathData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const existingProducts = useSelector((state) => state.productionOrders.existingProducts);
  const dispatch = useDispatch();
  const dispatchOpenPPOForm = (val) => {
    dispatch(setOpenPPOForm(val));
  };

  useEffect(async () => {
    onEdited && setProduct(values.product);
    setDescriptions(
      values
        ? { ...descriptions, productDescription: values.product_description }
        : { ...descriptions, productDescription: '' }
    );
    try {
      const resProductionBatch = await apiService.getData(Urls.productionBatch, 'code');
      setProductionBatchData({
        headers: resProductionBatch.headersList,
        tableData: resProductionBatch.data,
        options: resProductionBatch.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const industrialClient = apiService.industrialClient();

  useEffect(() => {
    const getAssociatedPPO = async () => {
      const productionOrder = isEditForm ? values.production_order : currentProductionOrder;
      const url = `${Urls.productPerProductionOrders}?page_size=0&production_order__code=${productionOrder}&product__code=${product}&filter1=1`;
      const res = await industrialClient.get(url);
      setStepsInfo(res.data);
      setInitialValuesLoading(false);
    };
    const getPOStatus = async () => {
      const productionOrder = isEditForm ? values.production_order : currentProductionOrder;
      const url = `${Urls.productionOrder}/${productionOrder}`;
      const res = await industrialClient.get(url);
      setStatusProductionOrder(res.data.manufacturing_status);
    };

    if (product) {
      getAssociatedPPO();
      getPOStatus();
    }
  }, [product]);

  const configureProcessPath = async () => {
    try {
      const resProcessPath = await apiService.getProductRoute(product);
      setProcessPathData({
        tableData: resProcessPath.data,
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (existingProducts.includes(product) && !isEditForm) {
      setWarningMessage('Duplicated product');
      setProcessPathData({ tableData: [] });
    }
    if (product !== '') {
      configureProcessPath();
    }
  }, [product]);

  useEffect(() => {
    processPathData.tableData.filter((route) => route.product === product);
    if (existingProducts.includes(product) && !isEditForm)
      setWarningMessage('Product is already declared');
    else if (product && processPathData.tableData.length === 0) {
      setWarningMessage(`No route associated with the product ${product}`);
    } else {
      setWarningMessage(null);
    }
  }, [processPathData]);

  const getVariables = async () => {
    const variables = await apiService.getData(Urls.variable, 'code');
    return variables.data;
  };

  const createCalculationVariableMap = async () => {
    const variables = await getVariables();
    const variablesMap = {};
    variables.forEach((variable) => {
      variablesMap[variable.code] = {
        code: variable.code,
        isCalculation: variable.calculation_variable === 'yes',
      };
    });
    return variablesMap;
  };

  useEffect(() => {
    const setVariableMap = async () => {
      const res = await createCalculationVariableMap();
      setCalculationVariableMap(res);
    };
    setVariableMap();
  }, []);

  const submitForm = async () => {
    dispatchOpenPPOForm(false);
    const userEmail = userProfile.email ? userProfile.email : '';
    const productPerProductionOrders = Object.keys(formValues).map((e, idx) => {
      const i = idx + 1;
      const processedValues = {
        amount_1: formValues[i].amount1 || 0,
        amount_2: formValues[i].amount2,
        amount_3: formValues[i].amount3,
        dispatch_date: formValues[i].dispatchDate || null,
        responsible: formValues[i].responsible,
        production_order: productionOrder,
        production_batch: productionBatch || null,
        product: formValues[i].product,
        dimensional_unit_1: formValues[i].dimensionalUnit1,
        dimensional_unit_2: formValues[i].dimensionalUnit2,
        dimensional_unit_3: formValues[i].dimensionalUnit3,
        work_center: formValues[i].workCenter || null,
        process: formValues[i].process,
        created_by: onEdited ? formValues[i].createdBy : userEmail,
        updated_by: userEmail,
      };
      if (onEdited) processedValues.id = formValues[i].id;
      return processedValues;
    });
    if (onEdited) {
      for (const PPO of productPerProductionOrders) {
        await handleAPIRequestUpdate(PPO.id, PPO);
        dispatch(setTouchedOrder({ key: Math.random().toString(16) }));
      }
      setOpenEditForm(false);
    } else {
      for (const PPO of productPerProductionOrders) {
        await handleAPIRequest(PPO);
      }
    }
  };

  const TooltipMessage = (props) =>
    errorForm ? <Tooltip {...props}>Validate the product route</Tooltip> : <Tooltip></Tooltip>;
  const formik = useFormikContext();
  return (
    <>
      <Formik
        initialValues={{
          product: null,
          productionBatch: values?.production_batch,
        }}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
          <Form>
            <Form.Row>
              {isEditForm ? (
                <>
                  <div className="flex column pl-2 col-6">
                    <p className="mb-1">{'Product'}</p>
                    <ProductCode>{product}</ProductCode>
                    <ProductDescription>{descriptions.productDescription}</ProductDescription>
                  </div>
                  <Formik>
                    {(props) => (
                      <CustomSelect
                        disabled={statusProductionOrder == 'closed' ? true : false}
                        onChange={(option) => {
                          setProductionBatch(option?.value || '');
                        }}
                        label="Production Batch"
                        name="productionBatch"
                        options={productionBatchData.options}
                        defaultValue={{
                          value: values.productionBatch,
                          label: values.productionBatch,
                        }}
                      />
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  <ProductSelection
                    value={values.product}
                    name={'product'}
                    onChange={(option) => {
                      setFieldValue(
                        'product',
                        option
                          ? option
                          : { value: 'Error', label: 'Select...', description: 'Error' }
                      );
                      setProduct(option?.value || '');
                    }}
                  />
                  <ProductionBatchSelection
                    value={values.productionBatch}
                    name={'productionBatch'}
                    label={'Production Batch'}
                    onChange={(option) => {
                      setFieldValue(
                        'productionBatch',
                        option
                          ? option
                          : { value: 'Error', label: 'Select...', description: 'Error' }
                      );
                      setProductionBatch(option?.value || '');
                    }}
                  />
                </>
              )}
            </Form.Row>
            {processPathData && product && (
              <span className="mr-4 d-flex align-items-center pb-4">
                <b style={{ fontSize: 'large' }}>Process route</b>
              </span>
            )}
            {warningMessage && <Alert variant="warning">{warningMessage}</Alert>}
            {product !== '' && isLoading ? <SpinnerWithText text="Computing Route" /> : null}
            {!isLoading &&
              !initialValuesLoading &&
              processPathData?.tableData.map(function (step, idx) {
                return (
                  <ErrorBoundary
                    key={idx}
                    fallback={
                      <Form>
                        <Form.Row className="ml-2">
                          <Button className="control-button text-center ml-3 big-title mt-1">
                            x
                          </Button>
                          <span className="big-title pl-4 pt-2">
                            Something went wrong with this process
                          </span>
                        </Form.Row>
                      </Form>
                    }
                  >
                    <Step
                      key={idx}
                      index={idx}
                      step={step}
                      onEdited={onEdited}
                      stepValues={onEdited ? stepsInfo : step}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      calculationVariableMap={calculationVariableMap}
                      statusProductionOrder={statusProductionOrder}
                    />
                  </ErrorBoundary>
                );
              })}
            <OverlayTrigger placement="top" overlay={TooltipMessage}>
              <span>
                <Button
                  disabled={errorForm}
                  className="fpb-btn mt-3"
                  onClick={submitForm}
                  style={errorForm ? { pointerEvents: 'none' } : {}}
                >
                  Confirm
                </Button>
              </span>
            </OverlayTrigger>
            <Button
              className="fpb-btn-inverse mt-3 ml-2"
              onClick={() => {
                dispatchOpenPPOForm(false);
                setOpenEditForm(false);
              }}
            >
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProductPerProdOrder;

const ProductCode = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
`;
const ProductDescription = styled.p`
  font-size: 14px;
`;
