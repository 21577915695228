import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select';
import Urls from '../Urls';
import defaultImg from './default_image.png';
import apiService from '../apiService';

const QrDataDisplay = ({ qrData, costUnitTransformed }) => (
  <>
    <Col md={4}>
      <p className="pb-0 mb-0 font-weight-bold">Item code</p>
      <p>{qrData.item_reference_code}</p>

      <p className="pb-0 mb-0 font-weight-bold">Item description</p>
      <p>{qrData.item_description}</p>

      <p className="pb-0 mb-0 font-weight-bold">Item image</p>
      <img
        className="pb-2"
        style={{ width: '50px' }}
        src={qrData.item_image ? qrData.item_image : defaultImg}
      />
    </Col>
    <Col md={4}>
      <p className="pb-0 mb-0 font-weight-bold">Batch Code</p>
      <p>{qrData.batch_code}</p>
      <p className="pb-0 mb-0 font-weight-bold">Unit</p>
      <p>{qrData.consolidationLevelSelected ? qrData.consolidationLevelSelected : null}</p>

      <p className="pb-0 mb-0 font-weight-bold">Cost</p>
      <p>
        {qrData.cost} {costUnitTransformed}
      </p>
    </Col>
  </>
);

const QRModal = ({ show, handleClose, title, qrData, QRImage, setSelectedPrinter }) => {
  const componentRef = useRef();
  const [confirmQR, setConfirmQR] = useState(true);
  const [costUnitTransformed, setCostUnitTransformed] = useState();
  const inventoryClient = apiService.inventoryClient();

  const printers = [
    { value: 'printerZebra', label: 'Zebra lp2844' },
    { value: 'printerTSC', label: 'TSC TE200' },
    { value: 'printerZebraGC-10x15', label: 'Zebra GC420d 10x15' },
    { value: 'printerZebraGC-10x6.5', label: 'Zebra GC420d 10x6.5' },
    { value: 'printerZebraGC-3.2x1.3', label: 'Zebra GC420d 3.2x1.3' },
    { value: 'printerZebraGC-5x2.5', label: 'Zebra GC420d 5x2.5' },
    { value: 'printerZebra7.6x5.1', label: 'Zebra GC420d 7.6x5.1' },
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const configureDimensionalUnitCost = async () => {
      const url = `${Urls.dimensionalUnit}/${qrData.cost_unit}`;
      const res = await inventoryClient.get(url);
      setCostUnitTransformed(res.data.dimensional_unit_code);
    };
    qrData && configureDimensionalUnitCost();
  }, [qrData]);

  const [QRDimension, setQRDimension] = useState('30mm');
  const [printer, setPrinter] = useState();

  useEffect(() => {
    switch (printer) {
      case 'printerZebra':
        setQRDimension('54mm');
        break;

      case 'printerTSC':
        setQRDimension('54mm');
        break;

      case 'printerZebraGC-10x15':
        setQRDimension('160mm');
        break;

      case 'printerZebraGC-10x6.5':
        setQRDimension('110mm');
        break;

      case 'printerZebraGC-3.2x1.3':
        setQRDimension('35mm');
        break;

      case 'printerZebraGC-5x2.5':
        setQRDimension('60mm');
        break;

      case 'printerZebra7.6x5.1':
        setQRDimension('70mm');
        break;
    }
  }, [printer]);

  const VerticalPrintQR = ({ QRImage, QRDimension, qrData }) => {
    return (
      <div className="d-flex flex-column ">
        <img
          src={`data:image/png;base64,${QRImage}`}
          style={{ width: QRDimension, height: QRDimension }}
        />
        <div className="d-flex pt-1 pl-1 qr-data">
          <div className="flex-column">
            <div className="pr-2 pb-0 mb-1">
              <p className="pb-0 mb-0 font-weight-bold qr-title">Item code</p>
              <p className="pb-0 mb-0 qr-description">{qrData.item_reference_code}</p>
            </div>
            <div className="pb-0 mb-0">
              <p className="pb-0 mb-0 font-weight-bold qr-title">Elaboration date</p>
              <p className="pb-0 mb-0 qr-description">{qrData.manufacturing_date}</p>
            </div>
          </div>
          <div className="flex-column pl-2">
            <div className="pl-2 pb-0 mb-1">
              <p className="pb-0 mb-0 font-weight-bold qr-title">Batch Code</p>
              <p className="pb-0 mb-0 qr-description">{qrData.batch_code}</p>
            </div>

            <div className="pl-2">
              <p className="pb-0 mb-0 font-weight-bold qr-title">Expiration date</p>
              <p className="pb-0 mb-0  qr-description">{qrData.expiration_date}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const HorizontalPrintQR = ({ QRImage, QRDimension, qrData }) => {
    return (
      <div className="d-flex flex-row ">
        <img
          src={`data:image/png;base64,${QRImage}`}
          style={{ width: QRDimension, height: QRDimension }}
        />
        <div className="qr-data pr-4">
          <div className="d-flex flex-column pt-1 pl-1">
            <div className="pr-2 pb-0 mb-1">
              <p className="pb-0 mb-1 font-weight-bold qr-title">Item code</p>
              <p className="pb-0 mb-2 qr-description">{qrData.item_reference_code}</p>
              <div className="pb-0 mb-1">
                <p className="pb-0 mb-1 font-weight-bold qr-title">Elaboration date</p>
                <p className="pb-0 mb-2 qr-description">{qrData.manufacturing_date}</p>
              </div>
            </div>
            <div className=" pb-0 mb-1">
              <p className="pb-0 mb-1 font-weight-bold qr-title">Batch Code</p>
              <p className="pb-0 mb-2 qr-description">{qrData.batch_code}</p>
            </div>

            <div className="">
              <p className="pb-0 mb-1 font-weight-bold qr-title">Expiration date</p>
              <p className="pb-0 mb-2  qr-description">{qrData.expiration_date}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {show && (
        <div ref={componentRef} className="qr-print">
          {/* TODO: Enable manual selection of horizontal and vertical orientation */}
          {printer === 'printerZebra7.6x5.1' ? (
            <HorizontalPrintQR QRImage={QRImage} QRDimension={QRDimension} qrData={qrData} />
          ) : (
            <VerticalPrintQR QRImage={QRImage} QRDimension={QRDimension} qrData={qrData} />
          )}
        </div>
      )}
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          setConfirmQR(true);
        }}
        dialogClassName="modal-80w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title.replaceAll('_', ' ').toUpperCase()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={4}>
                <img src={`data:image/png;base64,${QRImage}`} width="100%" />
              </Col>
              {qrData && (
                <QrDataDisplay qrData={qrData} costUnitTransformed={costUnitTransformed} />
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="modalqr-label">
            Select a <b>printer</b> and press on <b>confirm</b> please.
          </div>
          <div className="modalqr-printers">
            <Select
              options={printers}
              onChange={(el) => {
                setSelectedPrinter(el.value);
                setPrinter(el.value);
                setConfirmQR(true);
              }}
            />
          </div>
          <Button
            className="fpb-btn-inverse"
            onClick={() => {
              setConfirmQR(false);
            }}
          >
            Confirm
          </Button>
          <Button
            className="fpb-btn"
            variant="warning"
            disabled={confirmQR}
            onClick={() => {
              handlePrint();
            }}
          >
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QRModal;
