import React from 'react';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import OEEChart from '../IndustrialGraph/OEEChart';
import OEETrendChart from '../IndustrialGraph/OEETrendChart';
import { Row, Table, Col } from 'react-bootstrap';
import FiltersOEEControl from '../FiltersOEEControl';
import * as FaIcons from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { showOee } from '../../../redux/slices/oeeSlice';
import { ProgressBarWithText } from '../../Loading';

const OEEControl = () => {
  const data = useSelector(showOee);
  const availabilityStatus = useSelector((state) => state.availability.status);
  const qualityStatus = useSelector((state) => state.oee_quality.status);
  const PerformanceStatus = useSelector((state) => state.performance.status);
  const progress = useSelector((state) => state.performance.progress);
  const oeeStatus = useSelector((state) => state.oee.status);
  const oeeProgress = useSelector((state) => state.oee.progress);

  const indicatorName = 'OEE Control';
  const headers = ['OEE', 'Scale', 'Observation'];
  const rows = [
    {
      oee: '0% < OEE < 65%',
      scale: 'Unacceptable',
      observation: 'Very low competitiveness.',
      color: '#E2482D',
    },
    {
      oee: '65% < OEE < 75%',
      scale: 'Regular',
      observation: 'Low competitiveness. Acceptable only if it is in the process of improvement.',
      color: '#DDDF0D',
    },
    {
      oee: '75% < OEE < 85%',
      scale: 'Acceptable',
      observation: 'Continue improvement to move towards World Class.',
      color: '#77DD77',
    },
    {
      oee: '85% < OEE < 95%',
      scale: 'Good competitiveness',
      observation: 'Enter world class values.',
      color: '#71BC78',
    },
    {
      oee: '95% < OEE < 100%',
      scale: 'Excellent competitiveness',
      observation: 'World Class Values.',
      color: '#009D71',
    },
  ];

  return (
    <>
      <HeaderTitle title={indicatorName} />
      <FiltersOEEControl indicatorName={indicatorName} />
      {oeeStatus === 'PROGRESS' ? (
        <Row className="justify-content-center" style={{ 'margin-top': '100px' }}>
          <ProgressBarWithText
            progress={oeeProgress.current}
            label={`${oeeProgress.current}`}
            text={"Computing OEE's values"}
          />
        </Row>
      ) : null}
      {data && Object.keys(data).length > 0 && oeeStatus === 'SUCCESS' ? (
        <>
          <div className="row mt-3 mr-3">
            <div className="col-sm-6 col-lg-4">
              <div className="card mb-4 text-white" style={{ backgroundColor: '#ff4f2b' }}>
                <div className="card-body pb-1 d-flex justify-content-between align-items-start">
                  <div>
                    <div>Availability</div>
                    <h4>{data?.availability || ''}% </h4>
                  </div>
                  <FaIcons.FaRulerCombined size="48px" />
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card mb-4 text-white" style={{ backgroundColor: '#08bfff' }}>
                <div className="card-body pb-1 d-flex justify-content-between align-items-start">
                  <div>
                    <div>Performance</div>
                    <h4>{data?.performance || ''}%</h4>
                  </div>
                  <FaIcons.FaChartBar size="48px" />
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card mb-4 text-white" style={{ backgroundColor: '#ffae16' }}>
                <div className="card-body pb-1 d-flex justify-content-between align-items-start">
                  <div>
                    <div>Quality</div>
                    <h4>{data?.quality || ''}% </h4>
                  </div>
                  <FaIcons.FaChartPie size="48px" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Row>
              <Col sm={4}>
                <OEEChart />
              </Col>
              <Col sm={8} className="pr-5">
                <Table responsive sm={8} bordered size="sm">
                  <thead style={{ backgroundColor: '#E2EAF3' }}>
                    <tr>
                      {headers.map((header, idx) => {
                        return <th key={idx}>{header}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{row.oee}</td>
                          <td style={{ backgroundColor: row.color }}>{row.scale}</td>
                          <td>{row.observation}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </>
      ) : null}
      {data && Object.keys(data).length === 0 && oeeStatus === 'SUCCESS' ? (
        <Row className="justify-content-center" style={{ 'margin-top': '100px' }}>
          <h2>Non-existing data with selected filter settings</h2>
        </Row>
      ) : null}
      {qualityStatus === 'PROGRESS' ||
      availabilityStatus === 'PROGRESS' ||
      PerformanceStatus === 'PROGRESS' ? (
        <Row className="justify-content-center" style={{ 'margin-top': '100px' }}>
          <ProgressBarWithText
            progress={progress.current}
            label={`${progress.current}`}
            text={'Computing Chart'}
          />
        </Row>
      ) : null}
      {data && Object.keys(data).length > 0 && oeeStatus === 'SUCCESS' ? (
        <div className="mr-4">
          <OEETrendChart />
        </div>
      ) : null}
    </>
  );
};

export default OEEControl;
