import React from 'react';
import { Button, Form, Col } from 'react-bootstrap';

const Profile = ({ user }) => (
  <div className="row">
    <div className="col-xl-6 offset-xl-3 col-sm-12 order-xl-2 mb-5 mb-xl-0">
      <div className="card card-profile shadow">
        <div className="row justify-content-center">
          <div className="col-lg-3 order-lg-2">
            <div className="card-profile-image text-center">
              <a href="#" />
              <img src={user.logo} style={{ width: '100px' }} />
            </div>
          </div>
        </div>
        <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <div className="d-flex justify-content-between" />
        </div>
        <div className="card-body pt-0 pt-md-4">
          <div className="row">
            <div className="col" />
          </div>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Name</Form.Label>
              <Form.Control
                style={{ textTransform: 'capitalize' }}
                disabled
                type="text"
                placeholder={`${user.first_name} ${user.last_name}`}
                name="storage_center_code"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Age</Form.Label>
              <Form.Control
                style={{ textTransform: 'capitalize' }}
                disabled
                type="text"
                placeholder={user.age}
                name="storage_center_code"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Company</Form.Label>
              <Form.Control
                style={{ textTransform: 'capitalize' }}
                disabled
                type="text"
                placeholder={user.company}
                name="storage_center_code"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Role</Form.Label>
              <Form.Control
                style={{ textTransform: 'capitalize' }}
                disabled
                type="text"
                placeholder={user.role}
                name="storage_center_code"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                style={{ textTransform: 'capitalize' }}
                disabled
                type="text"
                placeholder={user.phone_number}
                name="storage_center_code"
              />
            </Form.Group>
          </Form.Row>
          <div className="pt-4">
            <Button className="fpb-btn">Edit</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Profile;
