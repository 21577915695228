import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import TextField from '../FormComponents/TextField/TextField';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import apiService from '../apiService';
import 'react-toastify/dist/ReactToastify.css';
import { getFinishEventSchema } from '../../utils/reportsValidations';
import { getCalculationVariableIndex } from '../../utils/variablesValidations';
import { useFetchVariableFromIoT } from '../../hooks/useFetchVariableFromIoT';
import IoTVariables from '../FormComponents/IoTVariables/IoTVariables';

const FinishEventWithoutOrder = ({
  added,
  setAdded,
  setAddForm,
  setAlertMessage,
  setErrorMessage,
}) => {
  const [variables, setVariables] = useState({});
  const [finishEventSchema, setFinishEventSchema] = useState(null);
  const [calculationVariableIndex, setCalculationVariableIndex] = useState(null);
  const [formDate, setFormDate] = useState(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"));

  //redux
  const currentWorkCenterConfiguration = useSelector(
    (state) => state.workCenters.currentWorkCenterConfigurations
  );
  const currentWorkCenter = useSelector((state) => state.workCenters.currentWorkCenter);
  const currentWorkCenterHasIoT = useSelector((state) => state.workCenters.currentWorkCenterHasIoT);

  const { data: iotData, loading: iotLoading, error: iotError } = useFetchVariableFromIoT('event');

  useEffect(() => {
    const variables = {};
    for (const index in currentWorkCenterConfiguration) {
      if (currentWorkCenterConfiguration[index].data_source === 'manual')
        variables[`variable_${index}`] = null;
      else if (currentWorkCenterConfiguration[index].data_source === 'iot')
        variables[`variable_${index}`] = 0;
    }
    setVariables(variables);
  }, [currentWorkCenterConfiguration]);

  useEffect(() => {
    const configureCalculationVariableIndex = async () => {
      const calculationVariableIndex = await getCalculationVariableIndex(
        currentWorkCenterConfiguration
      );
      setCalculationVariableIndex(calculationVariableIndex);
    };
    configureCalculationVariableIndex();
  }, []);

  useEffect(() => {
    const configureSchema = () => {
      const finishEventSchema = getFinishEventSchema(
        calculationVariableIndex,
        currentWorkCenterConfiguration
      );
      setFinishEventSchema(finishEventSchema);
    };
    calculationVariableIndex !== null &&
      currentWorkCenterConfiguration.length !== 0 &&
      configureSchema();
  }, [calculationVariableIndex, currentWorkCenterConfiguration]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={finishEventSchema}
        onSubmit={async (data) => {
          let variables = {};
          for (let i = 0; i < currentWorkCenterConfiguration.length; i++) {
            variables[currentWorkCenterConfiguration[i].variable] = {
              value: data[`variable_${i}`],
              dimensional_unit: currentWorkCenterConfiguration[i].entry_dimensional_unit,
              source: currentWorkCenterConfiguration[i].data_source,
            };
          }
          const transformedDate = new Date(formDate);
          const processedValues = {
            workCenter: currentWorkCenter.code,
            endDate: transformedDate.toISOString(),
            variables: variables,
            comments: data.comments,
          };

          try {
            const created = await apiService.postFinishEventWithoutOrder(processedValues);
            if (created.status === 201) {
              setAlertMessage('Event finished successfully');
              setAddForm(false);
              setAdded(true);
            }
          } catch (err) {
            console.log(err);
            if (err.error) {
              setErrorMessage(err.error);
            } else {
              setErrorMessage('Error while finishing event');
            }
            setAddForm(false);
            setAdded(false);
          }
        }}
        initialValues={{
          ...variables,
          comments: '',
        }}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Date"
                name="date"
                type="datetime-local"
                defaultValue={format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}
                value={formDate}
                onChange={(e) => setFormDate(e.target.value)}
              />
            </Form.Row>
            {currentWorkCenterHasIoT && (
              <IoTVariables
                workCenterConfiguration={currentWorkCenterConfiguration}
                data={iotData}
                loading={iotLoading}
                error={iotError}
              />
            )}
            {currentWorkCenterConfiguration?.map((configuration, index) => {
              if (configuration.data_source !== 'iot') {
                return (
                  <Form.Row key={index}>
                    <TextField
                      label={`${configuration.variable_description} (${configuration.entry_dimensional_unit})`}
                      name={`variable_${index}`}
                      type="number"
                      value={values[`variable_${index}`]}
                    />
                  </Form.Row>
                );
              }
            })}
            <Form.Row>
              <TextField label="Comments" name="comments" type="text" />
            </Form.Row>
            <Button className="fpb-btn mt-3" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Confirm'}
            </Button>
            <Button
              className="fpb-btn-inverse mt-3 ml-2"
              onClick={() => setAddForm(false)}
              disabled={isSubmitting}
            >
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FinishEventWithoutOrder;
