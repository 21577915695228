const columnOrder = [
  'unique_transaction_code',
  'ibr',
  'item_reference_code',
  'item_description',
  'item_category',
  'item_parent_category',
  'batch_code',
  'transaction_type',
  'transaction_subtype',
  'amount',
  'n0_units',
  'n0_cost',
  'supplier_identification',
  'customer_identification',
  'dimensional_unit_code',
  'entry_order_code',
  'dispatch_order_code',
  'storage_center_code',
  'storage_center_code_destination',
  'storage_location',
  'storage_location_destination',
  'return_transaction',
  'return_approved',
  'user',
  'return_approval_user',
  'return_approval_date',
  'adjustment_code',
  'justification',
  'transaction_date',
];

const columnsToHide = [
  'id',
  'ibr',
  'item_category',
  'item_parent_category',
  'storage_center_code',
  'storage_location',
  'storage_center_code_destination',
  'storage_location_destination',
  'return_transaction',
  'justification',
  'user',
  'username',
  'item_description',
  'transaction_subtype',
  'item_id',
  'batch_id',
  'supplier_identification',
  'customer_identification',
  'n0_units',
  'n0_cost',
];

export { columnOrder, columnsToHide };
