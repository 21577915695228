import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';
import Urls from '../Urls';
import DimensionalUnitField from './DimensionalUnitField';
import getUniqueValues from '../../utils/getUniqueValues';

const configureProductOptions = (products) => {
  const options = products.map((product) => ({
    description: product.description,
    value: product.code,
    label: product.code,
    id: product.id,
  }));
  return options;
};

const configureProcessOptions = (routes, product) => {
  const options = routes
    .filter((route) => route.product === product)
    .map((route) => ({
      description: route.process_description,
      value: route.process,
      label: route.process,
      id: route.id,
    }));

  const uniqueOptions = getUniqueValues(options);
  return uniqueOptions;
};

const configureWorkCenterOptions = (workCenterPerProcess) => {
  const options = workCenterPerProcess.map((el) => ({
    description: el.work_center_description,
    value: el.work_center,
    label: el.work_center,
    id: el.id,
  }));

  const uniqueOptions = getUniqueValues(options);
  return uniqueOptions;
};

const configureVariableOptions = (workCenterPerProcess) => {
  const options = workCenterPerProcess.map((el) => ({
    description: el.variable_description,
    value: el.variable,
    label: el.variable,
    id: el.id,
  }));

  const uniqueOptions = getUniqueValues(options);
  return uniqueOptions;
};

const WorkCenterCapacity = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [descriptions, setDescriptions] = useState({
    workCenterDescription: '',
    productDescription: '',
    variableDescription: '',
    dimensionalUnitsDescription: '',
    dimensionalUnitDescription: '',
    calculationVariableDescription: '',
  });
  const [dimensionalUnitCode, setDimensionalUnitCode] = useState('');
  const [calculationVariable, setCalculationVariable] = useState('');
  const [variable, setVariable] = useState(null);
  const [product, setProduct] = useState(null);
  const [process, setProcess] = useState(null);

  //states to fill selectable fields
  const responseData = { headers: [], tableData: [], options: [] };
  const [productData, setProductData] = useState(responseData);
  const [processData, setProcessData] = useState(responseData);
  const [workCenterData, setWorkCenterData] = useState(responseData);
  const [workCenterPerProcess, setWorkCenterPerProcess] = useState([]);
  const [variableData, setVariableData] = useState(responseData);
  const [dimensionalUnitData, setDimensionalUnitData] = useState(responseData);

  const workCenterCapacityValidate = Yup.object({
    workCenter: Yup.string().required('Required'),
    product: Yup.string().required('Required'),
    variable: Yup.string().required('Required'),
    maximumThreshold: Yup.number(),
    minimumThreshold: Yup.number(),
    ratedCapacity: calculationVariable === 'yes' ? Yup.number().required('Required') : Yup.string(),
    ratedCapacityDimensionalUnit:
      calculationVariable === 'yes' ? Yup.string().required('Required') : Yup.string(),
    minimumUnitStandardTime:
      calculationVariable === 'yes' ? Yup.number().required('Required') : Yup.string(),
    partialReportingTime: Yup.number(),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  const products = useSelector((state) => state.industrial.products);
  const routes = useSelector((state) => state.industrial.routes);
  useEffect(() => {
    const routeProducts = routes.map((routes) => routes.product);
    const filteredProducts = products.filter((product) => routeProducts.includes(product.code));
    const options = configureProductOptions(filteredProducts);
    setProductData({ options: options });
  }, []);

  useEffect(() => {
    const options = configureProcessOptions(routes, product);
    setProcessData({ options: options });
  }, [product]);

  useEffect(() => {
    const getWorkCenterPerProcessInfo = async () => {
      const workCenterCapacityFiltered = await apiService.getDataWithFilters(
        Urls.workCenterPerProcess,
        [`process__code=${process}`]
      );
      const workCenterOptions = configureWorkCenterOptions(workCenterCapacityFiltered);
      const variableOptions = configureVariableOptions(workCenterCapacityFiltered);
      setWorkCenterPerProcess(workCenterCapacityFiltered);
      setWorkCenterData({ options: workCenterOptions });
      setVariableData({ options: variableOptions });
    };

    getWorkCenterPerProcessInfo();
  }, [process]);

  useEffect(async () => {
    const filterData = workCenterPerProcess.find((row) => row.variable === variable);
    setDimensionalUnitCode(filterData?.entry_dimensional_unit);
    const variablesFiltered = await apiService.getDataWithFilters(Urls.variable, [
      `code=${variable}`,
    ]);
    const isCalculationVariable = variablesFiltered[0]?.calculation_variable;
    setCalculationVariable(isCalculationVariable);
  }, [variable, workCenterPerProcess]);

  useEffect(async () => {
    const resDimensionalUnit = await apiService.getData(Urls.dimensionalUnitA, 'code');

    setDimensionalUnitData({
      headers: resDimensionalUnit.headersList,
      tableData: resDimensionalUnit.data,
      options: resDimensionalUnit.results,
    });
  }, []);

  //This component is an artifact because we use Formik tag instead of useFormik hook
  //We need to access to form values from outside Formik tag when editting a row.
  const EmptyField = ({ calculationVariable }) => {
    const methods = useFormikContext();

    useEffect(() => {
      if (calculationVariable === 'no') {
        methods.setFieldValue('ratedCapacityDimensionalUnit', '');
        methods.setFieldValue('ratedCapacity', '');
        methods.setFieldValue('minimumUnitStandardTime', '');
      }
      setProduct(methods.values.product);
      setProcess(methods.values.process);
      setVariable(methods.values.variable);
    }, []);
    return <></>;
  };
  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={workCenterCapacityValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            work_center: data.workCenter,
            process: data.process,
            product: data.product,
            variable: data.variable,
            maximum_threshold: data.maximumThreshold || null,
            minimum_threshold: data.minimumThreshold || null,
            rated_capacity: data.ratedCapacity || null,
            dimensional_unit: data.ratedCapacityDimensionalUnit || null,
            minimum_unit_standard_time: data.minimumUnitStandardTime || null,
            partial_reporting_time: data.partialReportingTime || null,
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
          };
          setProcessedFormValues(processedValues);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Work center',
              code: data.workCenter,
            });
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Work center',
              code: data.workCenter,
            });
            if (updated.statusText == 'OK') {
              setAddForm(false);
              onEdited(processedValues);
            }
          }
        }}
        initialValues={{
          id: values.id,
          workCenter: values.work_center || '',
          process: values.process || '',
          product: values.product || '',
          variable: values.variable || '',
          maximumThreshold: values.maximum_threshold || '',
          minimumThreshold: values.minimum_threshold || '',
          dimensionalUnitDescription: values.dimensional_unit_description,
          ratedCapacity: values.rated_capacity || '',
          ratedCapacityDimensionalUnit: values.dimensional_unit || '',
          minimumUnitStandardTime: values.minimum_unit_standard_time || '',
          partialReportingTime: values.partial_reporting_time || '',
          createdBy: values.created_by || '',
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('product', option ? option.value : '');
                  setProduct(option.value || '');
                  setDescriptions(
                    option
                      ? { ...descriptions, productDescription: option.description }
                      : { ...descriptions, productDescription: '' }
                  );
                }}
                label="Product Code"
                name="product"
                options={productData.options}
                defaultValue={{
                  value: values.product,
                  label: values.product,
                }}
                description={descriptions.productDescription}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('process', option?.value || '');
                  setProcess(option?.value || '');
                  setDescriptions(
                    option
                      ? { ...descriptions, processDescriptions: option.description }
                      : { ...descriptions, processDescriptions: '' }
                  );
                }}
                label="Process Code"
                name="process"
                options={processData.options}
                defaultValue={{
                  value: values.process,
                  label: values.process,
                }}
                description={descriptions.processDescriptions}
                value={{
                  value: values.process,
                  label: values.process,
                }}
              />
            </Form.Row>
            <Form.Row>
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('workCenter', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, workCenterDescription: option.description }
                      : { ...descriptions, workCenterDescription: '' }
                  );
                }}
                label="Work Center Code"
                name="workCenter"
                options={workCenterData.options}
                defaultValue={{
                  value: values.workCenter,
                  label: values.workCenter,
                }}
                description={descriptions.workCenterDescription}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('variable', option?.value || '');
                  setDescriptions(
                    option
                      ? { ...descriptions, variableDescription: option.description }
                      : { ...descriptions, variableDescription: '' }
                  );
                  setVariable(option?.value || '');
                }}
                label="Variable Code"
                name="variable"
                options={variableData.options}
                defaultValue={{
                  value: values.variable,
                  label: values.variable,
                }}
                description={descriptions.variableDescription}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Maximum Threshold"
                name="maximumThreshold"
                type="number"
                value={values.maximumThreshold}
              />
              <TextField
                label="Minimun Threshold"
                name="minimumThreshold"
                type="number"
                value={values.minimumThreshold}
              />
              <DimensionalUnitField
                dimensionalUnit={{ label: 'Dimensional Unit', name: 'variableDimensionalUnit' }}
                dimensionalUnitValue={dimensionalUnitCode}
              />
              <EmptyField calculationVariable={calculationVariable} />
            </Form.Row>

            {calculationVariable === 'yes' ? (
              <Form.Row>
                <TextField
                  label="Rated Capacity"
                  name="ratedCapacity"
                  type="number"
                  value={values.ratedCapacity}
                />
                <CustomSelect
                  onChange={(option) => {
                    setFieldValue('ratedCapacityDimensionalUnit', option ? option.value : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, dimensionalUnitDescription: option.description }
                        : { ...descriptions, dimensionalUnitDescription: '' }
                    );
                  }}
                  label="Rated Capacity Dimensional Unit"
                  name="ratedCapacityDimensionalUnit"
                  options={dimensionalUnitData.options}
                  defaultValue={{
                    value: values.ratedCapacityDimensionalUnit,
                    label: values.ratedCapacityDimensionalUnit,
                  }}
                  description={descriptions.dimensionalUnitDescription}
                />
                <TextField
                  label="Standard Time (minutes)"
                  name="minimumUnitStandardTime"
                  type="number"
                  value={values.minimumUnitStandardTime}
                />
              </Form.Row>
            ) : null}
            <Form.Row>
              <TextField
                label="Partial Reporting Time (minutes)"
                name="partialReportingTime"
                type="number"
                value={values.partialReportingTime}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WorkCenterCapacity;
