import React, { useState, useEffect } from 'react';
import { Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import TextField from '../FormComponents/TextField/TextField';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import apiService from '../apiService';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import { GiCancel } from 'react-icons/gi';
import { HiExternalLink } from 'react-icons/hi';
import { IoMdHelpCircle } from 'react-icons/io';
import 'react-toastify/dist/ReactToastify.css';
import uploadFiles from '../../utils/uploadFiles';
import { notification } from '../AlertMessage/ToastifyAlert';
import Urls from '../Urls';
import { getDoneSchema } from '../../utils/reportsValidations';
import { getCalculationVariableIndex } from '../../utils/variablesValidations';
import { useFetchVariableFromIoT } from '../../hooks/useFetchVariableFromIoT';
import { useFetchQualityFromIoT } from '../../hooks/useFetchQualityFromIoT';
import IoTVariables from '../FormComponents/IoTVariables/IoTVariables';
import NonconformingQuantity from '../FormComponents/IoTVariables/NonconformingQuantity';

const Done = ({ setAdded, setAddForm, setAlertMessage, setErrorMessage }) => {
  const [variables, setVariables] = useState({});
  const [doneSchema, setDoneSchema] = useState(null);
  const [calculationVariableIndex, setCalculationVariableIndex] = useState(null);
  const [calculationVariable, setCalculationVariable] = useState('');
  const [formDate, setFormDate] = useState(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"));

  //redux
  const currentOrder = useSelector(
    (state) => state.productPerProductionOrders.currentProductPerProductionOrder
  );
  const currentWorkCenterConfiguration = useSelector(
    (state) => state.workCenters.currentWorkCenterConfigurations
  );
  const currentWorkCenter = useSelector((state) => state.workCenters.currentWorkCenter);
  const currentWorkCenterHasIoT = useSelector((state) => state.workCenters.currentWorkCenterHasIoT);
  const currentWorkCenterHasQualityIoT = useSelector(
    (state) => state.workCenters.currentWorkCenterHasQualityIoT
  );
  const { data: iotData, loading: iotLoading, error: iotError } = useFetchVariableFromIoT('manual');
  const {
    data: iotQualityData,
    loading: iotQualityLoading,
    error: iotQualityError,
  } = useFetchQualityFromIoT();

  //Quality
  const [document, setDocument] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [documentLoading, setDocumentLoading] = useState(false);
  const [error, setError] = useState();

  const [descriptions, setDescriptions] = useState({
    qualityDimensionDescription: '',
  });
  const [qualityDimensionData, setQualityDimensionData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  useEffect(async () => {
    try {
      const resQualityDimension = await apiService.getData(Urls.qualityDimension, 'code');
      setQualityDimensionData({
        headers: resQualityDimension.headersList,
        tableData: resQualityDimension.data,
        options: resQualityDimension.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      File Maximum Size: 10MB
    </Tooltip>
  );

  useEffect(() => {
    const variables = {};
    for (const index in currentWorkCenterConfiguration) {
      if (currentWorkCenterConfiguration[index].data_source === 'manual')
        variables[`variable_${index}`] = null;
      else if (currentWorkCenterConfiguration[index].data_source === 'iot')
        variables[`variable_${index}`] = 0;
    }
    setVariables(variables);
  }, [currentWorkCenterConfiguration]);

  useEffect(() => {
    const configureCalculationVariableIndex = async () => {
      const calculationVariableIndex = await getCalculationVariableIndex(
        currentWorkCenterConfiguration
      );
      setCalculationVariableIndex(calculationVariableIndex);
    };
    configureCalculationVariableIndex();
  }, []);

  useEffect(() => {
    const configureSchema = () => {
      const doneSchema = getDoneSchema(calculationVariableIndex, currentWorkCenterConfiguration);
      setDoneSchema(doneSchema);
    };
    calculationVariableIndex !== null &&
      currentWorkCenterConfiguration.length !== 0 &&
      configureSchema();
    if (currentWorkCenterConfiguration[calculationVariableIndex] !== undefined) {
      setCalculationVariable(
        currentWorkCenterConfiguration[calculationVariableIndex].entry_dimensional_unit
      );
    }
  }, [calculationVariableIndex, currentWorkCenterConfiguration]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={doneSchema}
        onSubmit={async (data) => {
          const calculationUnit = iotData.hasOwnProperty('calculation_variable')
            ? iotData.calculation_variable
            : '';
          let variables = {};
          for (let i = 0; i < currentWorkCenterConfiguration.length; i++) {
            variables[currentWorkCenterConfiguration[i].variable] = {
              value: data[`variable_${i}`],
              dimensional_unit: currentWorkCenterConfiguration[i].entry_dimensional_unit,
              source: currentWorkCenterConfiguration[i].data_source,
            };
          }

          const transformedDate = new Date(formDate);
          const processedValues = {
            id: currentOrder.id,
            workCenter: currentWorkCenter.code,
            commentsReport: data.comments,
            endDate: transformedDate.toISOString(),
            variables: variables,
            // quality
            quantityNonconforming: data.quantityNonconforming,
            qualityDimension: data.qualityDimension,
            reportedQuantity: data[`variable_${calculationVariableIndex}`] || 0,
            evidenceFile: document,
            commentsQuality: data.qualityComments,
          };

          try {
            if (
              currentWorkCenterHasIoT &&
              calculationUnit !== '' &&
              iotData[calculationUnit] < processedValues.quantityNonconforming
            ) {
              setErrorMessage('Quantity nonconforming is greater than the quantity produced');
              return;
            }
            const created = await apiService.postEndProductionOrder(processedValues);
            if (created.status === 201) {
              setAlertMessage(`Order ${currentOrder.production_order} finished`);
              setAddForm(false);
              setAdded(true);
            }
          } catch (err) {
            console.log(err);
            if (err.error) {
              setErrorMessage(err.error);
            } else {
              setErrorMessage('Error while trying to finish the order');
            }
            setAddForm(false);
            setAdded(false);
          }
        }}
        initialValues={{
          ...variables,
          comments: '',
          quantityNonconforming: 0,
          qualityComments: '',
        }}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Date"
                name="startDate"
                type="datetime-local"
                defaultValue={format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}
                value={formDate}
                onChange={(e) => setFormDate(e.target.value)}
              />
            </Form.Row>
            {currentWorkCenterHasIoT && (
              <IoTVariables
                workCenterConfiguration={currentWorkCenterConfiguration}
                data={iotData}
                loading={iotLoading}
                error={iotError}
                label={'Reported by IoT'}
              />
            )}
            {currentWorkCenterConfiguration?.map((configuration, index) => {
              if (configuration.data_source !== 'iot') {
                return (
                  <Form.Row key={index}>
                    <TextField
                      label={`${configuration.variable_description} (${configuration.entry_dimensional_unit})`}
                      name={`variable_${index}`}
                      type="number"
                      value={values[`variable_${index}`]}
                    />
                  </Form.Row>
                );
              }
            })}
            <Form.Row>
              <TextField label="Comments" name="comments" type="text" value={values.comments} />
            </Form.Row>
            <h4>Quality</h4>
            {currentWorkCenterHasQualityIoT ? (
              <NonconformingQuantity
                data={iotQualityData}
                loading={iotQualityLoading}
                error={iotQualityError}
                label={'Nonconforming quantity reported by IoT'}
              />
            ) : (
              <Form.Row>
                <TextField
                  label={`Quantity nonconforming (${calculationVariable || ''})`}
                  name="quantityNonconforming"
                  type="number"
                  step="any"
                  value={values.quantityNonconforming}
                />
              </Form.Row>
            )}
            <Form.Row>
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('qualityDimension', option ? option.value : undefined);
                  setDescriptions(
                    option
                      ? { ...descriptions, qualityDimensionDescription: option.description }
                      : { ...descriptions, qualityDimensionDescription: '' }
                  );
                }}
                label="Quality dimension"
                name="qualityDimension"
                options={qualityDimensionData.options}
                defaultValue={{
                  value: values.qualityDimension,
                  label: values.qualityDimension,
                }}
                description={descriptions.qualityDimensionDescription}
              />
            </Form.Row>
            <Form.Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label className="label">
                  Select Evidence File
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <IoMdHelpCircle />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(file) =>
                    uploadFiles(
                      file,
                      setDocument,
                      setDocumentName,
                      setDocumentLoading,
                      notification,
                      setError
                    )
                  }
                  accept=".docx,.pdf,.odt,.txt"
                  size="sm"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <TextField
                label="Attached Document"
                name="evidenceFile"
                type="text"
                value={documentName}
                readOnly
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    evidenceFile: e.target.value || '',
                  });
                }}
              />
              {documentLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    marginTop: '2.6rem',
                    marginLeft: '0.5rem',
                  }}
                ></Spinner>
              ) : (
                <GiCancel
                  size={23}
                  style={{
                    marginTop: '2.6rem',
                    marginLeft: '0.5rem',
                  }}
                  onClick={() => {
                    setDocument('');
                    setDocumentName('');
                  }}
                />
              )}
              {documentName !== '' ? (
                <a target="_blank" href={document} rel="noreferrer">
                  <HiExternalLink
                    size={26}
                    style={{
                      marginTop: '2.5rem',
                      marginLeft: '0.5rem',
                    }}
                  />
                </a>
              ) : null}
            </Form.Row>
            <Form.Row>
              <TextField
                label="Comments"
                name="qualityComments"
                type="text"
                value={values.qualityComments}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Confirm'}
            </Button>
            <Button
              className="fpb-btn-inverse mt-3 ml-2"
              onClick={() => setAddForm(false)}
              disabled={isSubmitting}
            >
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Done;
