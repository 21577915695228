import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../Forms/FormContainer';
import FPBTable from '../FPBTable/FPBTable';
import useForm from '../useForm';
import { notification } from '../../components/AlertMessage/ToastifyAlert';
import { setCurrentPageAction } from '../../redux/actions/paginationAction';
import { useFormikContext } from 'formik';
import apiService from '../apiService';
import {
  selectCurrentProductionOrderCode,
  setOpenPPOForm,
} from '../../redux/slices/productionOrderSlice';
import ActionConfirmationWarning from '../ActionConfirmation/ActionConfirmationWarning';
import ActionConfirmation from '../ActionConfirmation/ActionConfirmation';
import {
  deleteProductPerProductionOrder,
  getDeleteMessages,
  getInfoToDelete,
} from '../../utils/productionOrdersDeletion';
import {
  selectShiftsTotalCount,
  fetchShifts,
  selectShiftsCurrentPage,
  selectModalShifts,
  selectShiftHeaders,
  selectShiftsPageSize,
} from '../../redux/slices/shiftSlice';
import {
  fetchWorkCentersPaginated,
  selectModalWorkCenters,
  selectWorkCenterHeaders,
  selectWorkCentersCurrentPage,
  selectWorkCentersPageSize,
  selectWorkCentersTotalCount,
} from '../../redux/slices/workCenterSlice';
import {
  fetchProductPerProductionOrdersPerOrder,
  selectModalProductPerProductionOrder,
  selectProductPerProductionOrderCurrentPage,
  selectProductPerProductionOrderHeaders,
  selectProductPerProductionOrderPageSize,
  selectProductPerProductionOrderTotalCount,
  setProductPerProductionOrderModal,
  ppoIsLoading,
  setProductPerProductionOrderCurrentPage,
} from '../../redux/slices/productPerProductionOrderSlice';

const ProductTableSection = ({
  url,
  formName,
  title,
  withCheckBox,
  readOnly,
  productionOrder,
  setSelected,
  handleSelectedTran,
  status,
}) => {
  const [addReload, setAddReload] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteRows, setDeleteRows] = useState();
  const [deleteChange, setDeleteChange] = useState(false);
  const [addedRow, setAddedRow] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageUpdate, setErrorMessageUpdate] = useState('');
  const [columnsToHide, setColumnsToHide] = useState();
  const [columnOrder, setColumnOrder] = useState();
  const [isNewProductionOrder, setIsNewProductionOrder] = useState(false);
  // pagination
  const [filters, setFilters] = useState([]);
  const { values: formValues } = useFormikContext();
  const openPPO = useSelector((state) => state.productionOrders.openPPOForm);
  const dispatch = useDispatch();
  const dispatchOpenPPOForm = (val) => {
    dispatch(setOpenPPOForm(val));
  };
  const isEditForm = formValues?.id !== undefined;
  const currentOrder = useSelector(selectCurrentProductionOrderCode);
  const currentProductionOrderCode = isEditForm ? productionOrder : currentOrder;

  useEffect(async () => {
    const getProductionOrderSelected = async () => {
      const productionOrderSelected = await apiService.getProductionOrderByCode(
        currentProductionOrderCode
      );
      return productionOrderSelected?.data?.results ? productionOrderSelected : null;
    };
    if (currentProductionOrderCode && currentProductionOrderCode !== '') {
      const res = await getProductionOrderSelected();
      res === null ? setIsNewProductionOrder(true) : setIsNewProductionOrder(false);
    } else {
      setIsNewProductionOrder(true);
    }
  }, [currentProductionOrderCode]);

  useEffect(() => {
    setColumnsToHide([
      'created_by',
      'created_at',
      'updated_by',
      'updated_at',
      'amount_2',
      'amount_3',
      'dimensional_unit_1_description',
      'product_description',
      'dimensional_unit_2',
      'dimensional_unit_3',
      'start_date',
      'end_date',
      'responsible',
      'work_center_description',
      'process_order',
    ]);
    setColumnOrder([
      'product',
      'process',
      'production_status',
      'work_center',
      'amount_1',
      'dimensional_unit_1',
      'dispatch_date',
    ]);
  }, []);

  const { handleChange, values, handleAPIRequest, handleAPIRequestUpdate, added, edited } = useForm(
    url,
    dispatchOpenPPOForm,
    setAlertMessage,
    setErrorMessage,
    setErrorMessageUpdate,
    errorMessageUpdate
  );

  const onAdded = (r) => {
    dispatchOpenPPOForm(false);
    setAddedRow(r);
    setAddReload(!addReload);
  };

  const handleConfirmation = async () => {
    const productPerProductionOrdersId = await getInfoToDelete(
      deleteRows,
      'productPerProductionOrder'
    );
    deleteProductPerProductionOrder(productPerProductionOrdersId);
    setDeleteChange(true);
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
    setShowWarning(false);
    setDeleteConfirmationMessage(null);
  };

  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const industrialClient = apiService.industrialClient();

  const handleDelete = async (id) => {
    setDeleteConfirmationMessage(null);
    setDeleteRows(id);
    const type = formName;
    const deleteMessages = await getDeleteMessages(id, type);
    setDeleteConfirmationMessage(deleteMessages.message);
    if (deleteMessages.type === 'confirmation') {
      setShow(true);
    } else {
      setShowWarning(true);
    }
  };

  const nullableFields = [
    'dimensional_unit_3',
    'dimensional_unit_2',
    'amount_2',
    'amount_3',
    'dispatch_date',
    'responsible',
    'work_center',
    'start_date',
    'end_date',
  ];

  // data is the array of modified fields ([{field_name:value},{}...{}])
  const handleEdit = async (id, data) => {
    // get the name of the fields to edit
    const fieldsName = Object.keys(data);
    const editedRow = {};
    const res = await Promise.all(
      fieldsName.map(async (fieldName) => {
        // if the edited field is of type date or integer we send null instead of '' (when deleting field content)
        if (nullableFields.includes(fieldName) && data[fieldName] === '') {
          editedRow[fieldName] = null;
          return editedRow;
        }
        editedRow[fieldName] = data[fieldName];
        return editedRow;
      })
    );
    const dataToUpdate = res[res.length - 1];

    industrialClient
      .patch(`${url}/${id}`, { ...dataToUpdate })
      .then(() => {})
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (confirmDelete === true) {
      industrialClient.delete(`${url}/${deleteRows}`).then(() => {
        setDeleteChange(true);
        setConfirmDelete(false);
        setDeleteChange(false);
      });
    }
  }, [confirmDelete]);

  useEffect(() => {
    if (filters.length > 0) {
      dispatch(setCurrentPageAction(0));
    }
  }, [filters]);

  useEffect(() => {
    dispatch(setProductPerProductionOrderCurrentPage(0));
  }, [dispatch]);

  let totalCount;
  let currentPage;
  let tableData = [];
  let headers;
  let pageSize;

  const shiftsTotalCount = useSelector(selectShiftsTotalCount);
  const shiftsCurrentPage = useSelector(selectShiftsCurrentPage);
  const shiftsData = useSelector(selectModalShifts);
  const shiftHeaders = useSelector(selectShiftHeaders);
  const shiftPageSize = useSelector(selectShiftsPageSize);

  const workCentersTotalCount = useSelector(selectWorkCentersTotalCount);
  const workCentersCurrentPage = useSelector(selectWorkCentersCurrentPage);
  const workCentersData = useSelector(selectModalWorkCenters);
  const workCenterHeaders = useSelector(selectWorkCenterHeaders);
  const workCenterPageSize = useSelector(selectWorkCentersPageSize);

  const ppoTotalCount = useSelector(selectProductPerProductionOrderTotalCount);
  const ppoCurrentPage = useSelector(selectProductPerProductionOrderCurrentPage);
  const ppoData = useSelector(selectModalProductPerProductionOrder);
  const ppoHeaders = useSelector(selectProductPerProductionOrderHeaders);
  const ppoPageSize = useSelector(selectProductPerProductionOrderPageSize);

  if (formName === 'shifts') {
    totalCount = shiftsTotalCount;
    currentPage = shiftsCurrentPage;
    tableData = shiftsData;
    headers = shiftHeaders;
    pageSize = shiftPageSize;
  }
  if (formName === 'shiftWorkCenters') {
    totalCount = workCentersTotalCount;
    currentPage = workCentersCurrentPage;
    tableData = workCentersData;
    headers = workCenterHeaders;
    pageSize = workCenterPageSize;
  }
  if (formName === 'productPerProductionOrder') {
    totalCount = ppoTotalCount;
    currentPage = ppoCurrentPage;
    tableData = ppoData;
    headers = ppoHeaders;
    pageSize = ppoPageSize;
  }

  useEffect(() => {
    dispatch(fetchShifts({ currentPage: shiftsCurrentPage, pageSize: shiftPageSize, filters }));
  }, [shiftPageSize, shiftsCurrentPage, filters]);

  useEffect(() => {
    dispatch(
      fetchWorkCentersPaginated({
        currentPage: workCentersCurrentPage,
        pageSize: workCenterPageSize,
        filters,
      })
    );
  }, [workCentersCurrentPage, workCenterPageSize, filters]);

  useEffect(() => {
    if (currentProductionOrderCode) {
      dispatch(
        fetchProductPerProductionOrdersPerOrder({
          currentPage: ppoCurrentPage,
          pageSize: ppoPageSize,
          productionOrder: currentProductionOrderCode,
          filters,
        })
      );
    } else {
      dispatch(
        setProductPerProductionOrderModal({
          data: [],
          total: 0,
          page: 0,
          pageSize: 5,
          headers: [],
        })
      );
    }
  }, [
    url,
    ppoPageSize,
    ppoCurrentPage,
    filters,
    added,
    isNewProductionOrder,
    currentProductionOrderCode,
  ]);

  useEffect(() => {
    alertMessage !== '' ? notification(alertMessage, 'success') : null;
  }, [alertMessage]);

  useEffect(() => {
    errorMessage !== '' ? notification(errorMessage, 'error') : null;
  }, [errorMessage]);
  return (
    <>
      <ActionConfirmation
        show={show}
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
        message={deleteConfirmationMessage}
      />
      <ActionConfirmationWarning
        show={showWarning}
        handleClose={handleClose}
        title={'Warning'}
        message={deleteConfirmationMessage}
      />

      <div className="d-flex justify-content-between my-3">
        <span className="mr-4 d-flex align-items-center">
          <b style={{ fontSize: 'large' }}>{title}</b>
        </span>

        <Modal
          show={openPPO}
          onHide={() => {
            dispatch(setProductPerProductionOrderCurrentPage(0));
            dispatchOpenPPOForm(false);
          }}
          size="xl"
          onExit={() => {
            dispatch(setProductPerProductionOrderCurrentPage(0));
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Add {title} {productionOrder}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="scrollable">
            <FormContainer
              handleChange={handleChange}
              values={values}
              handleAPIRequest={handleAPIRequest}
              added={added}
              formName={formName}
              onAdded={onAdded}
              setAlert={setAlertMessage}
              setError={setErrorMessage}
              productionOrder={productionOrder}
              setAddForm={() => {}}
              status={status}
            />
          </Modal.Body>
        </Modal>
      </div>
      <FPBTable
        withCheckBox={withCheckBox}
        readOnly={readOnly}
        headers={headers}
        tableData={tableData}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        deleteChange={deleteChange}
        addedRow={addedRow}
        columnsToHide={columnsToHide}
        customColumnOrder={columnOrder}
        handleChange={handleChange}
        handleAPIRequestUpdate={handleAPIRequestUpdate}
        added={added}
        formName={formName}
        onAdded={onAdded}
        setAddForm={dispatchOpenPPOForm}
        setAlert={setAlertMessage}
        setError={setErrorMessageUpdate}
        edited={edited}
        errorMessageUpdate={errorMessageUpdate}
        title={title}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        setFilters={setFilters}
        productionOrder={productionOrder}
        setSelected={setSelected}
        handleSelectedTran={handleSelectedTran}
        status={status}
      />
    </>
  );
};

export default ProductTableSection;
