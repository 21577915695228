import React, { useEffect, useState } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import { ErrorMessage, useField } from 'formik';
import { setIsModalPaginationAction } from '../../../redux/actions/paginationAction';
import { useDispatch } from 'react-redux';

const CustomSelectGroup = ({ onChange, options, value, label, clear, defaultValue, ...props }) => {
  const [field] = useField(props);
  const [selectedOption, setSelectedOption] = useState('');
  // pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };

  useEffect(() => {
    setSelectedOption('');
  }, [clear]);

  return (
    <Form.Group as={Row} className="mx-2">
      <span className="input-group-text" id="basic-addon1">
        {label}
      </span>

      <Col className="px-0">
        <Select
          isDisabled={props.disabled ? props.disabled : false}
          defaultValue={defaultValue}
          options={options}
          value={value}
          onChange={(value) => {
            onChange(value);
            setSelectedOption(value);
          }}
          isClearable
          openMenuOnClick={false}
          aria-describedby="basic-addon1"
        />
        <ErrorMessage component="div" name={field.name} className="fpb-error" />
      </Col>
    </Form.Group>
  );
};

export default CustomSelectGroup;
