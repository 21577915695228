import React, { useRef, useLayoutEffect } from 'react';
import { line, select, scaleLinear, pointer, bisect } from 'd3';

export const LineChart = ({ data, width, height, xDomain, yDomain, name }) => {
  const margin = {
    top: 50,
    bottom: 60,
    left: 70,
    right: 100,
  };
  const xScale = scaleLinear()
    .domain(xDomain)
    .range([margin.left, width - margin.right]);
  const yScale = scaleLinear()
    .domain(yDomain)
    .range([height - margin.bottom, margin.top]);

  const containerRef = useRef(null);
  const containerRef2 = useRef(null);

  useLayoutEffect(() => {
    select(containerRef.current).selectAll('*').remove();

    const tooltip = select(containerRef2.current).style('opacity', 0);

    const mouseover = () => {
      tooltip.transition().duration(200).style('opacity', 1);
    };

    const mouseleave = () => {
      tooltip.transition().duration(200).style('opacity', 0);
    };

    const mousemove = (event) => {
      const xPos = pointer(event)[0];
      // indexElements identify each element of the x axis
      const indexElements = [...Array(xDomain[1]).keys()];
      const x0 = bisect(indexElements, xScale.invert(xPos));
      const d0 = data[x0];

      const tooltipContainer = select(`.tooltipContainer${name}`)
        .attr('x', 10)
        .attr('y', -25)
        .attr('width', 100)
        .attr('height', 50)
        .attr('rx', 4)
        .attr('ry', 4);

      const text = select(`.tooltip-area__text${name}`).attr('x', 20).attr('y', 15);
      const text2 = select(`.tooltip-area__text2${name}`).attr('x', 20).attr('y', -5);

      const suf = name === 'b3' ? '$' : 'units';
      name === 'b3' ? text2.text('Total cash') : text2.text('Total stock');

      d0 !== undefined && name === 'b3'
        ? text.text(`${suf}${d0.value}`)
        : d0 !== undefined && text.text(`${d0.value} ${suf}`);
      const [x, y] = pointer(event);
      d0 !== undefined && tooltip.attr('transform', `translate(${xScale(x0)},${yScale(d0.value)})`);
    };

    const x = scaleLinear()
      .domain(xDomain)
      .range([margin.left, width - margin.right]);

    const y = scaleLinear()
      .domain(yDomain)
      .range([height - margin.bottom, margin.top]);

    const lineFn = line()
      .x((_, i) => x(i))
      .y((d) => y(d.value));

    if (typeof data !== 'undefined') {
      select(containerRef.current)
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', () => '#082d45')
        .attr('stroke-width', 2)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('d', lineFn);
    }
    select(containerRef.current)
      .append('rect')
      .style('fill', 'transparent')
      .attr('width', width)
      .attr('height', height)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave)
      .on('mouseover', mouseover);

    select(containerRef2.current).append('circle').attr('r', 6).style('fill', '#091924');

    select(containerRef2.current).append('rect').attr('class', `tooltipContainer${name}`);

    select(containerRef2.current).append('text').attr('class', `tooltip-area__text${name}`);

    select(containerRef2.current).append('text').attr('class', `tooltip-area__text2${name}`);
  }, [data, xDomain, yDomain]);
  return (
    <svg>
      <g ref={containerRef} />
      <g ref={containerRef2} />
    </svg>
  );
};
