import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';
import { CompanySelection } from '../SelectionComponents';
import ResendMailConfirmation from '../Forms/ReSendMailConfirmation';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';

const User = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [editPassword, setEditPassword] = useState(false);
  const [checkIsValid, setCheckIsValid] = useState(0);
  const [defaultModuleData, setDefaultModuleData] = useState([]);
  const [companySelection, setCompanySelection] = useState('Cognitiva');
  const firstCheck = useRef(null);

  const onChangeCheck = () => {
    if (firstCheck.current.checked) {
      setCheckIsValid(1);
    } else {
      setCheckIsValid(0);
    }
  };

  const costTypeValidate = Yup.object({
    userName: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    time_zone: Yup.string().required('Required'),
  });

  // Every time the company selection changes, the module data is updated
  useEffect(async () => {
    try {
      const company = values.company ? values.company : companySelection;
      const moduleData = await apiService.authentication.getCompanyModules(company);
      const selectData = moduleData.results?.map((item) => {
        return {
          value: item.module,
          label: item.module_name,
        };
      });
      setDefaultModuleData([...selectData, { value: null, label: 'All' }]);
    } catch (err) {
      console.log(err);
    }
  }, [companySelection]);

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(() => {
    if (onEdited !== undefined) {
      setEditPassword(true);
      if (values.is_verified) {
        firstCheck.current.checked = true;
        setCheckIsValid(1);
      } else {
        firstCheck.current.checked = false;
        setCheckIsValid(0);
      }
    }
  }, []);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={costTypeValidate}
        onSubmit={async (data) => {
          const processedValues = {
            email: data.email,
            user_code: data.user_code,
            username: data.userName,
            first_name: data.first_name,
            last_name: data.last_name,
            phone_number: data.phone_number,
            company: data.company,
            picture: data.picture,
            default_module: data.defaultModule,
            time_zone: data.time_zone,
            is_verified: checkIsValid,
          };
          if (onEdited == undefined) {
            processedValues.password = 'GDT%$&SGFSH23432HGFSHGS/(/&&';
          }
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          if (data.id == null) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'User',
              code: data.userName,
            });
            if (created.error) {
              notification(created.error, 'error');
              return;
            }
            if (created.statusText !== 'Created') {
              notification('Error creating item - duplicate item', 'error');
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'User',
              code: data.userName,
            });
            if (updated.statusText == 'OK') {
              onEdited(updated.data);
              setAddForm(false);
              notification('Item updated successfully', 'success');
            } else {
              notification('Error updating item - duplicate item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.id,
          userName: values.username || '',
          email: values.email || '',
          user_code: values.user_code || '',
          password: values.password || '',
          first_name: values.first_name || '',
          last_name: values.last_name || '',
          phone_number: values.phone_number || '',
          company: values.company || '',
          defaultModule: values.default_module || null,
          defaultModuleName: values.default_module_name || '',
          picture: values.picture || '',
          time_zone: values.time_zone || '',
          is_verified: values.is_verified || '',
        }}
      >
        {({ values, touched, handleBlur, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField label="Username" name="userName" type="text" value={values.userName} />
              <TextField label="User Code" name="user_code" type="text" value={values.user_code} />
            </Form.Row>
            <Form.Row>
              <TextField label="Email" name="email" type="text" value={values.email} />
              <TextField
                label="Password"
                name="password"
                type="password"
                value={values.password}
                disabled={true}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="First Name"
                name="first_name"
                type="text"
                value={values.first_name}
              />
              <TextField label="Last Name" name="last_name" type="text" value={values.last_name} />
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridState" className="mb-2">
                <CompanySelection
                  onChange={(option) => {
                    setFieldValue('company', option ? option.label : '');
                    setCompanySelection(option ? option.label : '');
                  }}
                  name="company"
                  defaultValue={{
                    value: values.company,
                    label: values.company,
                  }}
                  value={values.CompanySelection}
                />
              </Form.Group>
              <TextField
                label="Phone Number"
                name="phone_number"
                type="text"
                value={values.phone_number}
              />
            </Form.Row>
            <Form.Row>
              <CustomSelect
                setShow={() => {}}
                onChange={(option) => {
                  setFieldValue('defaultModule', option ? option.value : '');
                }}
                label="Default Module"
                name="defaultModule"
                options={defaultModuleData}
                defaultValue={{
                  value: values.defaultModule,
                  label: values.defaultModuleName,
                }}
              />
              <TextField label="Time Zone" name="time_zone" type="text" value={values.time_zone} />
            </Form.Row>
            <Form.Row>
              <TextField label="Picture" name="picture" type="text" value={values.picture} />
            </Form.Row>
            <Form.Row>
              <Form.Check
                style={{ zIndex: 0 }}
                id="check_switch"
                name="is_verified"
                type="switch"
                label="User Verified"
                onChange={onChangeCheck}
                ref={firstCheck}
              />
            </Form.Row>
            <Row>
              <Col>
                <Button className="fpb-btn mt-3" type="submit">
                  Confirm
                </Button>
                <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
                  Close
                </Button>
              </Col>
              {onEdited ? (
                <Col align="end">
                  <Button
                    className="fpb-btn mt-3"
                    onClick={() => ResendMailConfirmation({ email: values.email })}
                  >
                    Re send mail confirmation
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default User;
