import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import LogoFPB from '../CoreComponents/LogoFPB/LogoFPB';
import Urls from '../Urls';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../redux/slices/userSlice';

const Login = ({ setLogged }) => {
  const dispatch = useDispatch();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const loginUrl = Urls.login;

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post(loginUrl, {
        email: login,
        password,
      })
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data));
        localStorage.setItem('refreshToken', res.data.tokens.refresh);
        localStorage.setItem('accessToken', res.data.tokens.access);
        if (res.data.default_module === 'All') {
          localStorage.setItem('menu', res.data.menu);
        } else {
          localStorage.setItem('menu', res.data.default_module);
        }
        // dispatch loginAction to have the user info in redux store
        dispatch(
          loginAction({
            user: res.data.email,
            timeZone: res.data.time_zone,
            paymentNotification: res.data.payment_notification,
            company: res.data.company,
            defaultModule: res.data.default_module,
            companyModule: res.data.menu,
          })
        );
        setLogged(res.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className="row d-flex align-items-center"
      style={{ backgroundColor: '#49158b', height: '100vh' }}
    >
      <div
        className="col-6 col offset-3 card-profile shadow p-5"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
        }}
      >
        <LogoFPB logoWidth="80%" />
        <Form className="text-left" onSubmit={onSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email </Form.Label>
            <Form.Control
              type="email"
              placeholder="Email address"
              onChange={(e) => setLogin(e.target.value)}
            />
            <Form.Text className="text-muted" />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              name="password"
            />
          </Form.Group>
          <div style={{ float: 'right', color: '#08bfff' }}>
            <a href="reset_password">Forgot Password?</a>
          </div>
          <Button variant="primary" type="submit" className="fpb-btn mt-3">
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
