import React, { useState, useEffect } from 'react';
import { Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IoMdHelpCircle } from 'react-icons/io';
import { GiCancel } from 'react-icons/gi';
import { HiExternalLink } from 'react-icons/hi';
import TextField from '../FormComponents/TextField/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadFiles from '../../utils/uploadFiles';
import { notification } from '../AlertMessage/ToastifyAlert';

const ProductionBatch = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setError,
  setAddForm,
  onEdited,
}) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [document, setDocument] = useState(values.attachment_file || '');
  const [documentName, setDocumentName] = useState(
    values.attachment_file ? values.attachment_file.split('/')[5] : ''
  );
  const [documentLoading, setDocumentLoading] = useState(false);

  const accessToken = localStorage.getItem('accessToken');
  const batchValidate = Yup.object({
    batchCode: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    manufacturingDate: Yup.string(),
    expirationDate: Yup.string(),
    ratedLife: Yup.number().typeError('Must be a  number').positive('Must be a positive number'),
    extendedLifetime: Yup.number()
      .typeError('Must be a  number')
      .positive('Must be a positive number'),
    qualityCharacteristics: Yup.string(),
    responsible: Yup.string(),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      File Maximum Size: 10MB
    </Tooltip>
  );

  useEffect(() => {
    console.log(onEdited);
  }, [onEdited]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={batchValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            code: data.batchCode,
            manufacturing_date: data.manufacturingDate !== '' ? data.manufacturingDate : null,
            expiration_date: data.expirationDate !== '' ? data.expirationDate : null,
            rated_life: data.ratedLife !== '' ? data.ratedLife : 0,
            extended_lifetime: data.extendedLifetime !== '' ? data.extendedLifetime : 0,
            quality_characteristics: data.qualityCharacteristics,
            responsible: data.responsible,
            attachment_file: document,
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
          };
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Batch',
              code: data.batchCode,
            });
            if (created.statusText !== 'Created') {
              notification('Error creating item - duplicate item', 'error');
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Batch',
              code: data.batchCode,
            });
            if (updated.statusText == 'OK') {
              onEdited(processedValues);
              setAddForm(false);
              notification('Item updated successfully', 'success');
            } else {
              notification('Error updating item - duplicate item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.code,
          batchCode: values.code || '',
          manufacturingDate: values.manufacturing_date || '',
          expirationDate: values.expiration_date || '',
          ratedLife: values.rated_life || '',
          extendedLifetime: values.extended_lifetime || '',
          qualityCharacteristics: values.quality_characteristics || '',
          responsible: values.responsible || '',
          attachmentFile: document || '',
          createdBy: values.created_by || '',
        }}
      >
        {({ values, handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField label="Batch code" name="batchCode" type="text" />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Manufacturing date"
                name="manufacturingDate"
                type="date"
                value={values.manufacturingDate}
              />
              <TextField
                label="Expiration date"
                name="expirationDate"
                type="date"
                value={values.expirationDate}
              />
            </Form.Row>
            {false && (
              <Form.Row>
                <TextField
                  label="Rated life"
                  name="ratedLife"
                  type="text"
                  value={values.ratedLife}
                />
                <TextField
                  label="Extended lifetime"
                  name="extendedLifetime"
                  type="text"
                  value={values.extendedLifetime}
                />
              </Form.Row>
            )}
            <Form.Row>
              <TextField
                label="Quality Characteristics"
                name="qualityCharacteristics"
                type="text"
                readOnly
              />
              <TextField label="Responsible" name="responsible" type="text" />
            </Form.Row>
            <Form.Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label className="label">
                  Attach File
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <IoMdHelpCircle />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(file) =>
                    uploadFiles(
                      file,
                      setDocument,
                      setDocumentName,
                      setDocumentLoading,
                      notification,
                      setError,
                      accessToken
                    )
                  }
                  accept=".docx,.pdf,.odt,.txt"
                  size="sm"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <TextField
                label="Attached File"
                name="attatch"
                type="text"
                value={documentName}
                readOnly
              />
              {documentLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                ></Spinner>
              ) : (
                <GiCancel
                  size={23}
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                  onClick={() => {
                    setDocument('');
                    setDocumentName('');
                  }}
                />
              )}
              {documentName !== '' ? (
                <a target="_blank" href={document} rel="noreferrer">
                  <HiExternalLink
                    size={26}
                    style={{
                      'margin-top': '2.5rem',
                      'margin-left': '0.5rem',
                    }}
                  />
                </a>
              ) : null}
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProductionBatch;
