export const SET_USER = 'SETUSER';

// Pagination
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';

// Pagination at modal tables
export const SET_MODAL_TOTAL_COUNT = 'SET_MODAL_TOTAL_COUNT';
export const SET_MODAL_CURRENT_PAGE = 'SET_MODAL_CURRENT_PAGE';
export const SET_MODAL_PAGE_SIZE = 'SET_MODAL_PAGE_SIZE';
export const SET_IS_MODAL_PAGINATION = 'SET_IS_MODAL_PAGINATION';

//Menu
export const SET_MENU = 'SET_MENU';

// Transaction Query
export const SET_TRANSACTION_QUERY = 'SET_TRANSACTION_QUERY';

// Industrial
export const SET_ROUTES = 'SET_ROUTES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
