import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';
import Urls from '../../components/Urls';
import { getFiltersString } from '../../utils/tableFilters';

export const fetchProductionOrders = createAsyncThunk(
  'productionOrder/fetchProductionOrders',
  async ({ currentPage, pageSize, filters }) => {
    try {
      const filtersString = getFiltersString(filters);
      const url = `${Urls.productionOrder}?page=${
        currentPage + 1
      }&page_size=${pageSize}&${filtersString}&manufacturing_status=closed`;
      const res = await apiService.industrialClient().get(url);
      const { results } = res.data || 0;
      if (results.length > 0) {
        // discard the fields the user will not visualize in tables
        const headersList = Object.keys(results[0]).filter((elem) => {
          let returnColumn;
          if (
            elem === 'id' ||
            elem === 'assignment_date' ||
            elem === 'created_at' ||
            elem === 'created_by' ||
            elem === 'loading_date' ||
            elem === 'updated_at' ||
            elem === 'updated_by' ||
            elem === 'manufacturing_status' ||
            elem === 'event' ||
            elem === 'quality_report'
          ) {
            returnColumn = false;
          } else {
            returnColumn = true;
          }
          return returnColumn;
        });
        return {
          currentPage,
          pageSize,
          total: res.data.count,
          data: res.data.results,
          headers: headersList,
        };
      }
      return {
        currentPage,
        pageSize,
        total: res.data.count,
        data: res.data.results,
        headers: [],
      };
    } catch (err) {
      return err.message;
    }
  }
);

const initialState = {
  workCentersAllowed: [],
  existingProducts: [],
  openPPOForm: false,
  touchedOrder: {}, //either deleted or modified
  currentProductionOrderCode: null,
  productionOrder: [],
  productionOrderModal: {
    page: 0,
    pageSize: 5,
    total: 0,
    data: [],
    headers: [],
  },
  orderToTrace: null,
  orderToTraceProducts: [],
};

const productionOrderSlice = createSlice({
  name: 'productionOrder',
  initialState,
  reducers: {
    setWorkCentersAllowed: (state, action) => {
      state.workCentersAllowed = action.payload;
    },
    setExistingProducts: (state, action) => {
      state.existingProducts = action.payload;
    },
    setOpenPPOForm: (state, action) => {
      state.openPPOForm = action.payload;
    },
    setTouchedOrder: (state, action) => {
      state.touchedOrder = action.payload;
    },
    setCurrentProductionOrderCode: (state, action) => {
      state.currentProductionOrderCode = action.payload;
    },
    setProductionOrderPageSize: (state, action) => {
      state.productionOrderModal.pageSize = action.payload;
    },
    setProductionOrderCurrentPage: (state, action) => {
      state.productionOrderModal.page = action.payload;
    },
    setOrderToTrace: (state, action) => {
      state.orderToTrace = action.payload;
    },
    setOrderToTraceProducts: (state, action) => {
      state.orderToTraceProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteProductPerProductionOrders.fulfilled, (state, action) => {
      state.touchedOrder = { key: Math.random().toString(16) };
    });
    builder.addCase(deleteProductionOrder.fulfilled, (state, action) => {
      state.touchedOrder = { key: Math.random().toString(16) };
    });
    builder.addCase(fetchProductionOrders.fulfilled, (state, action) => {
      state.productionOrderModal.data = action.payload.data;
      state.productionOrderModal.total = action.payload.total;
      state.productionOrderModal.headers = action.payload.headers;
    });
  },
});

export const touchedOrder = (state) => state.productionOrders.touchedOrder;
export const selectCurrentProductionOrderCode = (state) =>
  state.productionOrders.currentProductionOrderCode;

export const selectModalProductionOrder = (state) =>
  state.productionOrders.productionOrderModal.data;
export const selectProductionOrderHeaders = (state) =>
  state.productionOrders.productionOrderModal.headers;
export const selectProductionOrderTotalCount = (state) =>
  state.productionOrders.productionOrderModal.total;
export const selectProductionOrderCurrentPage = (state) =>
  state.productionOrders.productionOrderModal.page;
export const selectProductionOrderPageSize = (state) =>
  state.productionOrders.productionOrderModal.pageSize;

export const {
  setWorkCentersAllowed,
  setExistingProducts,
  setOpenPPOForm,
  setTouchedOrder,
  setCurrentProductionOrderCode,
  setCurrentProductionOrder,
  setProductionOrderPageSize,
  setProductionOrderCurrentPage,
  setOrderToTrace,
  setOrderToTraceProducts,
} = productionOrderSlice.actions;

export default productionOrderSlice.reducer;

const industrialClient = apiService.industrialClient();

export const deleteProductPerProductionOrders = createAsyncThunk(
  'productionOrder/deleteProductPerProductionOrders',
  async (productPerProductionOrdersIdList) => {
    try {
      for (const order of productPerProductionOrdersIdList) {
        await industrialClient.delete(`${Urls.productPerProductionOrders}/${order}`);
      }
      return res.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const deleteProductionOrder = createAsyncThunk(
  'productionOrder/deleteProductionOrder',
  async ({ productionOrderCode, productPerProductionOrdersIdList }) => {
    try {
      for (const order of productPerProductionOrdersIdList) {
        await industrialClient.delete(`${Urls.productPerProductionOrders}/${order}`);
      }
      await industrialClient.delete(`${Urls.productionOrder}/${productionOrderCode}`);
    } catch (err) {
      return err.message;
    }
  }
);
