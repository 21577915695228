import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Urls from '../Urls';
import axios from 'axios';
import TableModal from '../TableModal/SimpleTableModal';
import { FaTools, FaClipboard } from 'react-icons/fa';
import { notification } from '../AlertMessage/ToastifyAlert';
import {
  setIsModalPaginationAction,
  setModalCurrentPageAction,
  setModalPageSizeAction,
  setModalTotalCountAction,
} from '../../redux/actions/paginationAction';
import {
  setCurrentWorkCenter,
  setCurrentWorkCenterConfigurations,
  fetchWorkCenterEvent,
  workCenterEvents,
  fetchWorkCenters,
} from '../../redux/slices/workCenterSlice';
import ProductionOrderModal from '../TableModal/ProductionOrderModal';
import {
  setCurrentProductPerProductionOrder,
  productPerProductionOrderColumnsToHide,
} from '../../redux/slices/productPerProductionOrderSlice';
import apiService from '../apiService';
import StartEventWithoutOrder from '../IndustrialForms/StartEventWithoutOrder';
import FinishEventWithoutOrder from '../IndustrialForms/FinishEventWithoutOrder';
import StartEvent from '../IndustrialForms/StartEvent';

const WorkCenterCard = ({ code, description, status, operationCenter }) => {
  const dispatch = useDispatch();
  const columnsToHide = useSelector(productPerProductionOrderColumnsToHide);
  const currentWorkCenter = useSelector((state) => state.workCenters.currentWorkCenter);

  const eventData = useSelector(workCenterEvents);
  const [show, setShow] = useState(false);
  const [process, setProcess] = useState(null);
  const [workCenter] = useState({
    code: code,
    description: description,
    status: status,
  });
  const [productPerProductionOrder, setProductPerProductionOrder] = useState({});
  const [productData, setProductData] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [ordersLength, setOrdersLength] = useState(0);
  const [workCenterConfig, setWorkCenterConfig] = useState([]);
  //orders are allowed according to the operation center of the selected work center
  const [ordersAllowed, setOrdersAllowed] = useState('');

  // Emergent tables (modals)
  const [orderData, setOrderData] = useState([]);
  const [orderHeaders, setOrderHeaders] = useState([]);
  const [formName, setFormName] = useState(null);

  const handleShow = () => setShow(true);

  // event reports
  const [showStartEvent, setShowStartEvent] = useState(false);
  const [showFinishEvent, setShowFinishEvent] = useState(false);
  const [startEventSuccess, setStartEventSuccess] = useState(false);
  const [finishEventSuccess, setFinishEventSucess] = useState(false);
  const [showStartEventWithOrder, setShowStartEventWithOrder] = useState(false);
  const [startEventWithOrderSuccess, setStartEventWithOrderSuccess] = useState(false);

  const handleShowEndEvent = () => setShowEndEvent(true);

  const [showProductionOrder, setShowProductionOrder] = useState(false);
  const handleCloseProductionOrder = () => setShowProductionOrder(false);
  const [showTable, setShowTable] = useState(false);

  const handleShowTable = () => {
    dispatch(setCurrentWorkCenterConfigurations(workCenterConfig));
    dispatch(setCurrentWorkCenter(workCenter));
    setShowTable(true);
  };

  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState(localStorage.getItem('accessToken') || null);

  const industrialClient = apiService.industrialClient();

  const obtainOrdersAllowed = async () => {
    const url = `${Urls.productionOrder}?page_size=0&operation_center__code=${operationCenter}`;
    const productionOrdersByOperationCenter = await industrialClient(url);
    const ordersCode = productionOrdersByOperationCenter.data.map((order) => order.code);
    const ordersCodeString = ordersCode.join(',');
    setOrdersAllowed(ordersCodeString);
  };

  useEffect(() => {
    obtainOrdersAllowed();
  }, []);

  // pagination
  const [filters, setFilters] = useState([]);
  const [filtersModal, setFiltersModal] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const [lastQueryModal, setLastQueryModal] = useState(null);
  const modalCurrentPage = useSelector((state) => state.pagination.modalCurrentPage);
  const modalPageSize = useSelector((state) => state.pagination.modalPageSize);
  const totalCount = useSelector((state) => state.pagination.modalTotalCount);

  const setModalTotalCount = (totalCount) => {
    dispatch(setModalTotalCountAction(totalCount));
  };
  const setIsModalPagination = () => {
    dispatch(setIsModalPaginationAction());
  };
  const setModalCurrentPage = (currentPage) => {
    dispatch(setModalCurrentPageAction(currentPage));
  };
  const setModalPageSize = (pageSize) => {
    dispatch(setModalPageSizeAction(pageSize));
  };

  const getProcessFromConfigurationsTable = async (workCenterCode) => {
    const inventoryClient = apiService.inventoryClient();
    try {
      const res = await inventoryClient.get(
        `${Urls.workCenterPerProcess}?work_center__code=${workCenterCode}&page_size=0`
      );
      const results = res.data;
      const process = results[0].process;
      setWorkCenterConfig(results);
      return process;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const setProcessCode = async () => {
      const workCenterCode = code;
      const process = await getProcessFromConfigurationsTable(workCenterCode);
      setProcess(process);
    };
    setProcessCode();
  }, []);

  //filter product per production orders
  const getQueryStringModal = () => {
    const urlProdPerOrder = `${Urls.productPerProductionOrders}?page=${
      modalCurrentPage + 1
    }&page_size=${modalPageSize}&planned_unplanned_orders=${code}&process__code=${process}&production_order__code__in=${ordersAllowed}`;
    return urlProdPerOrder;
  };

  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('accessToken');
      if (item) {
        setToken(item);
      }
    }
    window.addEventListener('storage', checkUserData);

    return () => {
      window.removeEventListener('storage', checkUserData);
    };
  }, []);

  useEffect(() => {
    if (filters.length > 0) {
      dispatch(setModalCurrentPageAction(0));
    }
  }, [filters]);

  // Emergent table (modal) to select an order
  const handleSelectedOrder = (selected) => {
    setShowTable(false);
    setProductPerProductionOrder(selected);
    dispatch(setCurrentProductPerProductionOrder(selected));
    setShowProductionOrder(true);
  };

  const handleShowOrder = () => {
    dispatch(setCurrentProductPerProductionOrder(productData));
    dispatch(setCurrentWorkCenterConfigurations(workCenterConfig));
    dispatch(setCurrentWorkCenter(workCenter));
    setShowProductionOrder(true);
  };

  const handleShowReportOrderEvent = () => {
    dispatch(setCurrentProductPerProductionOrder(productData));
    dispatch(setCurrentWorkCenterConfigurations(workCenterConfig));
    dispatch(setCurrentWorkCenter(workCenter));
    setShowStartEventWithOrder(true);
  };

  useEffect(() => {
    alertMessage !== '' ? notification(alertMessage, 'success') : null;
  }, [alertMessage]);

  useEffect(() => {
    errorMessage !== '' ? notification(errorMessage, 'error') : null;
  }, [errorMessage]);

  useEffect(() => {
    const queryString = getQueryStringModal();
    const configureOrdersLength = async () => {
      const res = await industrialClient.get(queryString);
      const productPerProductionOrdersCount = res.data.count;
      setOrdersLength(productPerProductionOrdersCount);
    };
    process && ordersAllowed && configureOrdersLength();
  }, [process, ordersAllowed]);

  const getTableDataModal = async () => {
    const queryString = getQueryStringModal();
    try {
      if (code === currentWorkCenter.code) {
        const res = await industrialClient.get(queryString);
        const productPerProductionOrders = res.data.results;
        const productPerProductionOrdersCount = res.data.count;
        setModalTotalCount(productPerProductionOrdersCount);
        const headersList =
          productPerProductionOrders &&
          Object.keys(productPerProductionOrders[0])?.filter((item) => item !== 'id');
        setOrderData(productPerProductionOrders);
        setOrderHeaders(headersList);
      }
    } catch (err) {
      console.log(err);
    }
    setLastQueryModal(queryString);
  };

  useEffect(() => {
    process && ordersAllowed && getTableDataModal();
  }, [modalPageSize, modalCurrentPage, filtersModal, process, ordersAllowed, showTable]);

  useEffect(() => {
    // Only fetch product_per_production_orders if work center is in process
    if (status !== 'Available') {
      const getProductData = () => {
        const queryString = `${Urls.productionOrderByWorkCenter}?work_center=${code}`;
        if (queryString !== lastQuery) {
          axios
            .get(queryString, {
              headers: {
                Authorization: `Bearer  ${token}`,
              },
            })
            .then(async (res) => {
              if (res.status === 200) {
                const data = res.data;
                setProductData(data);
              }
            })
            .catch((err) => {
              console.log('error: ', err);
            });
          setLastQuery(queryString);
        }
      };
      getProductData();
    }
  }, [status]);

  const handleCloseTable = () => {
    setShowTable(false);
    setModalCurrentPage(0);
    setIsModalPagination();
  };

  // re-fetch workcenters if start/finish event is successful
  useEffect(() => {
    if (startEventSuccess || finishEventSuccess || startEventWithOrderSuccess) {
      dispatch(fetchWorkCenters());
    }
  }, [startEventSuccess, finishEventSuccess, startEventWithOrderSuccess]);

  // fetch events if work center is in event
  useEffect(() => {
    if (status === 'In event') {
      dispatch(fetchWorkCenterEvent({ workCenter: code }));
    }
  }, [dispatch, code, status]);

  return (
    <Card className="mt-1">
      <Card.Body className="container p-2">
        <Card.Title className="h6 font-weight-bold pb-0 mb-0 pt-2">{`[${code}] ${description}`}</Card.Title>

        <div className="d-flex justify-content-between pt-3">
          <Modal show={showStartEvent} onHide={setShowStartEvent} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Work Center Event Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>{`[${workCenter.code}] ${workCenter.description}`}</h5>
              <StartEventWithoutOrder
                added={startEventSuccess}
                setAdded={setStartEventSuccess}
                setAddForm={setShowStartEvent}
                setAlertMessage={setAlertMessage}
                setErrorMessage={setErrorMessage}
              />
            </Modal.Body>
          </Modal>

          <Modal show={showFinishEvent} onHide={setShowFinishEvent} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Work Center Finish Event Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>{`[${workCenter.code}] ${workCenter.description}`}</h5>
              <FinishEventWithoutOrder
                added={finishEventSuccess}
                setAdded={setFinishEventSucess}
                setAddForm={setShowFinishEvent}
                setAlertMessage={setAlertMessage}
                setErrorMessage={setErrorMessage}
              />
            </Modal.Body>
          </Modal>

          <Modal show={showStartEventWithOrder} onHide={setShowStartEventWithOrder} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Event Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>{`[${workCenter.code}] ${workCenter.description}`}</h5>
              {Object.keys(productData).length > 0 && (
                <h5>{`[${productData.product}] ${productData.product_description}`}</h5>
              )}
              <StartEvent
                added={startEventSuccess}
                setAdded={setStartEventWithOrderSuccess}
                setAddForm={setShowStartEventWithOrder}
                setAlertMessage={setAlertMessage}
                setErrorMessage={setErrorMessage}
              />
            </Modal.Body>
          </Modal>

          <ProductionOrderModal
            show={showProductionOrder}
            handleCloseProductionOrder={handleCloseProductionOrder}
          />

          <TableModal
            title={'product_per_production_order'}
            headers={orderHeaders}
            tableData={orderData}
            show={showTable}
            handleClose={handleCloseTable}
            handleSelectedOrder={handleSelectedOrder}
            formName={formName}
            confirmButton={true}
            setFilters={setFiltersModal}
            setModalCurrentPage={setModalCurrentPage}
            columnsToHide={columnsToHide}
            modalCurrentPage={modalCurrentPage}
            setModalPageSize={setModalPageSize}
            pageSize={modalPageSize}
            code={code}
            description={description}
            status={status}
            totalCount={totalCount}
          />

          {status === 'Available' ? (
            <div className="container p-0">
              {workCenterConfig.length > 0 ? (
                <div className="p-2 mb-3">
                  <Card.Text>{`${workCenterConfig[0].process}: ${workCenterConfig[0].process_description}`}</Card.Text>
                </div>
              ) : (
                'This work center has no process configured yet'
              )}
              <div className="d-flex justify-content-between">
                <Button
                  size="sm"
                  className="btn-dark fpb-btn-secondary col mr-2"
                  onClick={() => {
                    setShowStartEvent(true);
                    dispatch(setCurrentWorkCenter(workCenter));
                  }}
                >
                  <FaTools className="mr-2" />
                  Report event
                </Button>
                <Button
                  size="sm"
                  className="btn-dark fpb-btn-default col"
                  onClick={handleShowTable}
                  disabled={ordersLength === 0 || ordersLength === undefined}
                >
                  <FaClipboard className="mr-2" />
                  Select order
                  <small className="text-muted">{` (${ordersLength || 0})`}</small>
                </Button>
              </div>
            </div>
          ) : null}
          {status === 'In process' ? (
            <div className="container p-0">
              {productData ? (
                <div className="p-2 mb-3">
                  <small className="text-muted">{`Processing order: ${productData.production_order}`}</small>
                  <Card.Text>{`[${productData.product}] ${productData.product_description}`}</Card.Text>
                  <Card.Text>{`${productData.amount_1} ${productData.dimensional_unit_1}`}</Card.Text>
                  {workCenterConfig.length > 0 ? (
                    <Card.Text>{`${workCenterConfig[0].process}: ${workCenterConfig[0].process_description}`}</Card.Text>
                  ) : (
                    'This work center has no process configured yet'
                  )}
                </div>
              ) : null}
              <div className="d-flex justify-content-between">
                <Button
                  size="sm"
                  className="btn-dark fpb-btn-secondary col mr-2"
                  onClick={handleShowReportOrderEvent}
                >
                  <FaTools className="mr-2" />
                  Report event
                </Button>
                <Button
                  size="sm"
                  className="btn-dark fpb-btn-default col"
                  onClick={handleShowOrder}
                >
                  <FaClipboard className="mr-2" />
                  Go to order
                </Button>
              </div>
            </div>
          ) : null}
          {status === 'In event' ? (
            <div className="container p-0">
              {Object.keys(productData).length > 0 ? (
                <div className="p-2 mb-3">
                  <small className="text-muted">{`Processing order: ${productData.production_order}`}</small>
                  <Card.Text>{`[${productData.product}] ${productData.product_description}`}</Card.Text>
                  <Card.Text>{`${productData.amount_1} ${productData.dimensional_unit_1}`}</Card.Text>
                  {workCenterConfig.length > 0 ? (
                    <Card.Text>{`${workCenterConfig[0].process}: ${workCenterConfig[0].process_description}`}</Card.Text>
                  ) : (
                    'This work center has no process configured yet'
                  )}
                </div>
              ) : null}
              {eventData[code] && (
                <div className="alert alert-warning p-3 mb-4">
                  <Card.Text className="font-weight-bold mb-0">{`${eventData[code].event_type_description}`}</Card.Text>
                  <small className="text-muted">{`${new Date(eventData[code].start_date)}`}</small>
                  <Card.Text className="mt-2">{`${eventData[code].comments}`}</Card.Text>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <Button
                  size="sm"
                  className="fbtn-dark fpb-btn-secondary col mr-2"
                  onClick={() => {
                    dispatch(setCurrentWorkCenter(workCenter));
                    dispatch(setCurrentWorkCenterConfigurations(workCenterConfig));
                    setShowFinishEvent(true);
                  }}
                >
                  <FaTools className="mr-2" />
                  End event
                </Button>

                {Object.keys(productData).length > 0 && (
                  <Button
                    size="sm"
                    className="btn-dark fpb-btn-default col"
                    onClick={handleShowOrder}
                  >
                    <FaClipboard className="mr-2" />
                    Go to order
                  </Button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
};

export default WorkCenterCard;
