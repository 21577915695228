import Urls from '../components/Urls';
import mapTable from '../components/mapTableService';

const getQueryString = (currentPage, pageSize, filters, additionalFilters) => {
  let filter = filters
    .reduce((acc, { columnName, operation, value }) => {
      console.log(operation, columnName, value);
      const lowerCaseValue = value.toLowerCase();
      if (columnName.includes('consolidation_level')) {
        const dimensionalUnitId = mapTable.getDimensionalUnitIdFromCode(lowerCaseValue);
        acc.push(`${columnName}__id=${encodeURIComponent(dimensionalUnitId)}`);
      } else {
        switch (operation) {
          case 'contains': {
            acc.push(`${columnName}__icontains=${encodeURIComponent(value)}`);
            break;
          }
          case 'equal': {
            acc.push(`${columnName}=${encodeURIComponent(value)}`);
            break;
          }
          case 'greaterThanOrEqual': {
            acc.push(`${columnName}__gte=${encodeURIComponent(value)}`);
            break;
          }
          case 'lessThanOrEqual': {
            acc.push(`${columnName}__lte=${encodeURIComponent(value)}`);
            break;
          }
          default:
            acc.push(`${columnName}__${operation}=${encodeURIComponent(value)}`);
            break;
        }
      }
      return acc;
    }, [])
    .join('&');
  if (filters.length > 1) {
    filter = `${filter}`;
  }
  const urlT = `${Urls.transaction}?page=${
    currentPage + 1
  }&page_size=${pageSize}&${filter}${additionalFilters}`;
  return urlT;
};

export default getQueryString;
