import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Col,
  Form,
  Row,
  ButtonGroup,
  Spinner,
} from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import axios from 'axios';
import { DateRange } from 'react-date-range';
import {
  subDays,
  differenceInMonths,
  lastDayOfMonth,
  startOfMonth,
  lastDayOfWeek,
  startOfYear,
  endOfYear,
} from 'date-fns';
import { XAxisBarChart } from './Graph/XAxisBarChart';
import { XAxisLineChart } from './Graph/XAxisLineChart';
import { YAxis } from './Graph/YAxis';
import Urls from '../Urls';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import { jsPDF } from 'jspdf';
import { BarChart } from './Graph/BarChart';
import { LineChart } from './Graph/LineChart';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { XLabel } from './Graph/XLabel';
import { YLabel } from './Graph/YLabel';
import apiService from '../apiService';
import IndicatorPercentages from './IndicatorPercentages';
import InventoryBanner from './InventoryBanner';
import FilterSelect from './FilterSelect';
import dateFuncs from '../../utils/dateFuncs';
import SimpleTableModal from '../TableModal/SimpleTableModal';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { notification } from '../AlertMessage/ToastifyAlert';
let flag = 0;

const LoadingSpinner = () => (
  <div className="text-center">
    <Spinner className="mt-5 mb-5" animation="border" role="status" size="sm"></Spinner>
  </div>
);
const IndicatorsSection = () => {
  const saveSvgAsPng = require('save-svg-as-png');
  const [token] = useState(localStorage.getItem('accessToken') || null);
  const [isLoadingLineChart, setIsLoadingLineChart] = useState(true);
  const [isLoadingBarChart, setIsLoadingBarChart] = useState(true);
  //const [isLoadingLineChartStock, setIsLoadingLineChartStock] = useState(true);
  //const [isLoadingBarChartStock, setIsLoadingBarChartStock] = useState(true);

  const inventoryClient = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // graph information
  const [barChartCashData, setBarChartCashData] = useState([]);
  const [barChartCashDataTable, setBarChartCashDataTable] = useState([]);
  const [barChartStockData, setBarChartStockData] = useState([]);
  const [barChartStockDataTable, setBarChartStockDataTable] = useState([]);
  const [yDomainBarChartCash, setYDomainBarChartCash] = useState([]);
  const [yDomainBarChartStock, setYDomainBarChartStock] = useState([]);
  const [yDomainLineChart, setYDomainLineChart] = useState([]);
  const [yDomainLineChart2, setYDomainLineChart2] = useState([]);
  const [xDomain, setXDomain] = useState([]);
  const [tickCount, setTickCount] = useState();
  const [totalCash, setTotalCash] = useState();
  const [totalStock, setTotalStock] = useState();
  const [storageCenterCodes, setStorageCenterCodes] = useState();
  const [lineChartCashData, setLineChartCashData] = useState([]);
  const [lineChartCashDataTable, setLineChartCashDataTable] = useState([]);
  const [lineChartStockData, setLineChartStockData] = useState([]);
  const [lineChartStockDataTable, setLineChartStockDataTable] = useState([]);
  const [rangeData, setRangeData] = useState([]);
  const [xKeys, setXKeys] = useState('Days');
  const [xKeysBarChart, setXKeysBarChart] = useState('Storage Center');

  // filters
  const [filterSelected, setFilterSelected] = useState({
    label: 'Total',
    name: 'total',
    endpoint: `${Urls.stockPerStorageCenter}`,
  });
  const [filterValue, setFilterValue] = useState();
  const [filterCode, setFilterCode] = useState();
  const [predefinedTimeFilter, setPredefinedTimeFilter] = useState('');
  const [dateFilterSelected, setDateFilterSelected] = useState('day');
  const [itemFiltered, setItemFiltered] = useState();
  const [filtersSubtitle, setFiltersSubtitle] = useState();
  const [clear, setClear] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [filterApplied, setFilterApplied] = useState();
  const [totalClear, setTotalClear] = useState(false);

  // table information
  const tableHeaders = ['storageCenter', 'value', 'unit'];
  const tableHeadersTime = ['date', 'value', 'unit'];
  const [showTableModalCash, setShowTableModalCash] = useState(false);
  const [showTableModalStock, setShowTableModalStock] = useState(false);
  const [showTableModalTimeCash, setShowTableModalTimeCash] = useState(false);
  const [showTableModalTimeStock, setShowTableModalTimeStock] = useState(false);

  // dates
  const yesterday = useRef(new Date(Date.now() - 24 * 60 * 60 * 1000));
  const [to, setTo] = useState(yesterday.current);
  const [from, setFrom] = useState(subDays(yesterday.current, 6));
  const [inputTo, setInputTo] = useState(yesterday.current);
  const [inputFrom, setInputFrom] = useState(subDays(yesterday.current, 6));
  const [dayList, setDayList] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [dayPicker, setDayPicker] = useState(false);
  const [isEndDateInput, setIsEndDateInput] = useState();
  const [isStartDateInput, setIsStartDateInput] = useState();
  const [dayFilterCalendar, setDayFilterCalendar] = useState(false);

  const [isCustomRange, setIsCustomRange] = useState(false);
  const widthGraph = itemFiltered ? 450 : 650;
  const heightGraph = itemFiltered ? 290 : 320;
  const [itemUnit, setItemUnit] = useState('');

  // percentage calculations
  const [percentage, setPercentage] = useState();
  const [percentageColor, setPercentageColor] = useState('gray');
  const [percentageSign, setPercentageSign] = useState('positive');
  const [stockPercentage, setStockPercentage] = useState();
  const [stockPercentageColor, setStockPercentageColor] = useState('gray');
  const [stockPercentageSign, setStockPercentageSign] = useState('positive');

  const Style = {
    dropdown: { minWidth: '9rem' },
    requiredLabel: {
      color: 'red',
      fontSize: '12px',
    },
    filterSubtitle: { fontSize: '12px' },
    invisible: { opacity: '0.2', position: 'relative' },
    customFiltersButton: {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    chartTitle: {
      borderRadius: '5px',
      backgroundColor: '#fafcfd',
      marginBottom: '-0.2rem',
      display: 'inline-block',
      padding: '10px 20px 2px 2px',
    },
    chart: {
      border: '1px solid #d2d2d2',
      borderRadius: '5px',
      padding: '10px',
      backgroundColor: 'white',
    },
    banner: {
      display: 'inline-block',
      boxShadow: '2px 1px 5px #ececec',
    },
  };

  const ClearDataAlert = () => (
    <div
      style={{
        position: 'absolute',
        top: '40%',
        left: 0,
        right: 0,
        margin: 'auto',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <h2>No data to display</h2>
    </div>
  );

  const FilterSubtitle = () => (
    <p style={Style.filterSubtitle} className="text-secondary">
      {filtersSubtitle}
    </p>
  );

  const filterObjects = [
    {
      label: 'Total',
      name: 'total',
      endpoint: `${Urls.stockPerStorageCenter}`,
    },
    {
      label: 'Item',
      name: 'item',
      endpoint: `${Urls.stockPerStorageCenter}?item`,
      code: 'reference_code',
    },
    {
      label: 'Storage Center',
      name: 'storageCenter',
      endpoint: `${Urls.stockPerStorageCenter}?storage_center`,
      code: 'storage_center_code',
    },
    {
      label: 'Location',
      name: 'storageLocation',
      endpoint: `${Urls.stockPerStorageCenter}?location`,
      code: 'location_code',
    },
    {
      label: 'Batch',
      name: 'batch',
      endpoint: `${Urls.stockPerBatch}?batch`,
      code: 'batch_code',
    },
    {
      label: 'Parent Category',
      name: 'itemCategory',
      secondaryName: 'parentCategory',
      endpoint: `${Urls.stockPerStorageCenter}?parent_category`,
      code: 'item_category_code',
    },
    {
      label: 'Child Category',
      name: 'itemCategory',
      secondaryName: 'childCategory',
      endpoint: `${Urls.stockPerStorageCenter}?child_category`,
      code: 'item_category_code',
    },
  ];

  const [filterData, setFilterData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  useEffect(() => {
    setSelectedOption('');
  }, [clear]);

  // fill select with filter codes
  useEffect(async () => {
    if (filterSelected !== undefined && filterSelected.secondaryName === 'childCategory') {
      setClear(!clear);
      setSelectedOption('');
      const res = await apiService.getChildCategories();
      setFilterData({
        headers: res.headersList,
        tableData: res.data,
        options: res.results,
      });
    } else if (filterSelected !== undefined && filterSelected.secondaryName === 'parentCategory') {
      setClear(!clear);
      setSelectedOption('');
      const res = await apiService.getParentCategories();
      setFilterData({
        headers: res.headersList,
        tableData: res.data,
        options: res.results,
      });
    } else if (filterSelected !== undefined && filterSelected.name !== 'total') {
      setClear(!clear);
      const res = await apiService.getData(Urls[filterSelected.name], filterSelected.code);
      setFilterData({
        headers: res.headersList,
        tableData: res.data,
        options: res.results,
      });
    } else {
      // remove previous selected filter value
      setFilterValue();
      setFilterCode('');
      setSelectedOption('');
      setFilterData({
        headers: [],
        tableData: [],
        options: [],
      });
    }
  }, [filterSelected]);

  // handle selection of days in day picker
  const handleSelect = useCallback(({ selection: { startDate, endDate } }) => {
    let daylist = dateFuncs.getDaysArray(startDate, endDate);
    daylist = daylist.map((v) => v.toISOString().split('T', 1)[0]);
    setDayList(daylist);
    setFrom(startDate);
    setTo(endDate);
    daylist.length > 2 ? setDayPicker(false) : null;
  });

  const getCustomRangeInventoryValue = async (start, end) => {
    const filtersUrl = getFiltersUrl();
    const url = `${filtersUrl}start_date=${start}&end_date=${end}`;
    const res = await inventoryClient.get(url);
    if (filterValue && filterSelected.name === 'item') {
      const { cash } = res.data;
      const { stock } = res.data;
      const totalInventoryValue = res.data.cash.total_cash.toFixed(2);
      const totalStock = stock.total_stock !== null ? stock.total_stock : 0;

      return [stock, cash, totalInventoryValue, totalStock];
    }
    const { cash } = res.data;
    const totalInventoryValue = res.data.cash.total_cash.toFixed(2);
    return [cash, totalInventoryValue];
  };

  const getFiltersUrl = () => {
    // check that filterValue exist
    let queryFilter;
    if (filterValue) {
      queryFilter = `${filterSelected.endpoint}=${filterValue}&`;
    } else {
      queryFilter = `${Urls.stockPerStorageCenter}?`;
    }
    return queryFilter;
  };

  // from a list of days obtain total cash
  const getDailyCash = async (days) => {
    const filtersUrl = getFiltersUrl();
    const stockData = await Promise.all(
      days.map(async (day) => {
        try {
          const url = `${filtersUrl}start_date=${day}&end_date=${day}`;
          const stockResults = await inventoryClient.get(url);
          return stockResults.data;
        } catch (err) {
          console.log(err);
        }
      })
    );
    const dailyCashList = stockData.map((dayStock) => dayStock.cash.total_cash);
    return dailyCashList;
  };

  // from a list of days retrieve the corresponding stock values
  const getDailyStock = async () => {
    const filtersUrl = getFiltersUrl();
    const result = await Promise.all(
      dayList.map(async (el, idx) => {
        try {
          if (typeof dayList[idx] !== 'undefined') {
            const res = await inventoryClient.get(
              `${filtersUrl}start_date=${dayList[idx]}&end_date=${dayList[idx]}`
            );
            return res.data;
          }
        } catch (err) {
          console.log(err);
        }
      })
    );
    const dailyStockList = result.map((el) => el.stock.total_stock);
    return dailyStockList;
  };

  // unit for the stock banner when item is selected in the filters
  const configureItemUnit = async () => {
    const itemResponse = await inventoryClient.get(`${Urls.item}/${filterValue}`);

    const consolidationLevelN0 = itemResponse.data.consolidation_level_N0;

    const dimensionalUnitResponse = await inventoryClient.get(
      `${Urls.dimensionalUnit}/${consolidationLevelN0}`
    );

    setItemUnit(dimensionalUnitResponse.data.description);
  };

  // configure axes and data for line chart
  const setLineChartInformationDaily = async () => {
    const dailyCashValues = await getDailyCash(dayList);
    if (typeof dailyCashValues !== 'undefined') {
      const timeData = dayList.map((el, i) => {
        const date = new Date(`${el} 12:00:00`);
        const month = date.toLocaleString('default', {
          month: 'short',
        });
        const day = date.getDate();
        const transformedDate = `${month} ${day}`;
        return { date: transformedDate, value: dailyCashValues[i] };
      });

      const limit = parseFloat(
        dailyCashValues.reduce((a, b) => (parseFloat(a) > parseFloat(b) ? a : b))
      );
      setLineChartCashData(timeData);
      setRangeData([0, timeData.length - 1]);
      setYDomainLineChart([0, limit > 1 ? limit : 1]);
      setXKeys('Days');
    }
    if (filterValue && filterSelected.name === 'item') {
      const dailyStockValues = await getDailyStock();
      if (typeof dailyStockValues !== 'undefined') {
        const timeData = dayList.map((el, i) => {
          const date = new Date(`${el} 12:00:00`);
          const month = date.toLocaleString('default', {
            month: 'short',
          });
          const day = date.getDate();
          const transformedDate = `${month} ${day}`;

          return { date: transformedDate, value: dailyStockValues[i] };
        });
        const limit = parseFloat(
          dailyStockValues.reduce((a, b) => (parseFloat(a) > parseFloat(b) ? a : b))
        );
        setLineChartStockData(timeData);
        setYDomainLineChart2([0, limit > 1 ? limit : 1]);
        setXKeys('Days');
      }
    }
  };

  // from the list of weeks retrieve the corresponding cash values
  const getWeeklyCash = async (weeksObject) => {
    const filtersUrl = getFiltersUrl();
    const result = await Promise.all(
      weeksObject.map(async (week) => {
        const url = `${filtersUrl}start_date=${
          week.start.toISOString().split('T', 1)[0]
        }&end_date=${week.end.toISOString().split('T', 1)[0]}`;
        const res = await inventoryClient.get(url);
        return res.data;
      })
    );

    const weeklyCashList = result.map((el) => el.cash.total_cash);
    return weeklyCashList;
  };

  // from the list of weeks retrieve the corresponding stock values
  const getWeeklyStock = async (weeksObject) => {
    // check that filterValue exist
    const filtersUrl = getFiltersUrl();
    const result = await Promise.all(
      weeksObject.map(async (week) => {
        const url = `${filtersUrl}start_date=${
          week.start.toISOString().split('T', 1)[0]
        }&end_date=${week.end.toISOString().split('T', 1)[0]}`;
        const res = await inventoryClient.get(url);
        return res.data;
      })
    );
    if (filterValue) {
      const weeklyCashList = result.map((el) => el.stock.total_stock);
      return weeklyCashList;
    }
    const weeklyCashList = result.map((el) => el.total_stock);
    return weeklyCashList;
  };

  // configure axes and data for line chart (week)
  const setLineChartInformationWeekly = async (weeksObject) => {
    const weeklyCashValues = await getWeeklyCash(weeksObject);
    if (typeof weeklyCashValues !== 'undefined') {
      const timeData = weeklyCashValues.map((el, i) => ({ date: weeksObject[i].label, value: el }));

      const limit = parseFloat(
        weeklyCashValues.reduce((a, b) => (parseFloat(a) > parseFloat(b) ? a : b))
      );
      setLineChartCashData(timeData);
      setRangeData([0, timeData.length - 1]);
      setYDomainLineChart([0, limit > 1 ? limit : 1]);
      setXKeys('Weeks');
    }
    if (filterValue && filterSelected.name === 'item') {
      const weeklyStockValues = await getWeeklyStock(weeksObject);
      if (typeof weeklyStockValues !== 'undefined') {
        const timeData = weeklyStockValues.map((el, i) => ({
          date: weeksObject[i].label,
          value: el,
        }));
        const limit = parseFloat(
          weeklyStockValues.reduce((a, b) => (parseFloat(a) > parseFloat(b) ? a : b))
        );
        setLineChartStockData(timeData);
        setYDomainLineChart2([0, limit > 1 ? limit : 1]);
        setXKeys('Weeks');
      }
    }
  };

  // from the list of days retrieve the corresponding cash values
  const getMonthlyCash = async (monthsObject) => {
    const filtersUrl = getFiltersUrl();
    const result = await Promise.all(
      monthsObject.map(async (month) => {
        const url = `${filtersUrl}start_date=${
          month.start.toISOString().split('T', 1)[0]
        }&end_date=${month.end.toISOString().split('T', 1)[0]}`;
        const res = await inventoryClient.get(url);
        return res.data;
      })
    );
    // get only the total cash from each response
    const monthlyCashList = result.map((el) => el.cash.total_cash);
    return monthlyCashList;
  };

  // from the list of days retrieve the corresponding cash values
  const getMonthlyStock = async (monthsObject) => {
    const filtersUrl = getFiltersUrl();
    const result = await Promise.all(
      monthsObject.map(async (month) => {
        const url = `${filtersUrl}start_date=${
          month.start.toISOString().split('T', 1)[0]
        }&end_date=${month.end.toISOString().split('T', 1)[0]}`;
        const res = await inventoryClient.get(url);
        return res.data;
      })
    );

    const monthlyStockList = result.map((el) => el.stock.total_stock);
    return monthlyStockList;
  };

  // configure axes and data for line chart (month)
  const setLineChartInformationMonthly = async (monthsObject, label) => {
    try {
      const monthlyCashValues = await getMonthlyCash(monthsObject);
      if (typeof monthlyCashValues !== 'undefined') {
        const timeData = monthlyCashValues.map((el, i) => ({
          date: monthsObject[i].label,
          value: el,
        }));
        const limit = parseFloat(
          monthlyCashValues.reduce((a, b) => (parseFloat(a) > parseFloat(b) ? a : b))
        );
        setLineChartCashData(timeData);
        setRangeData([0, timeData.length - 1]);
        setYDomainLineChart([0, limit > 1 ? limit : 1]);
        setXKeys(label);
      }

      if (filterValue && filterSelected.name === 'item') {
        const monthlyStockValues = await getMonthlyStock(monthsObject);
        if (typeof monthlyStockValues !== 'undefined') {
          const timeData = monthlyStockValues.map((el, i) => ({
            date: monthsObject[i].label,
            value: el,
          }));
          const limit = parseFloat(
            monthlyStockValues.reduce((a, b) => (parseFloat(a) > parseFloat(b) ? a : b))
          );
          setLineChartStockData(timeData);
          setYDomainLineChart2([0, limit > 1 ? limit : 1]);
          setXKeys(label);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // configure inventory value banner
  const configureInventoryValueBanner = async (start, end) => {
    // when filtered by item we show 2 banners
    if (filterSelected && filterSelected.name === 'item') {
      const [_tI, _tS, totalInventoryValue, totalStockValue] = await getCustomRangeInventoryValue(
        start,
        end
      );
      setTotalCash(totalInventoryValue);
      setTotalStock(totalStockValue);
      if (totalInventoryValue > 0) {
        if (flag === 1) {
          notification('Data loaded successfully', 'success');
        }
      } else {
        notification('There is no data to show', 'error');
      }
    }
    // when other filter is selected we use that filter + the date range selected
    else {
      const [_, totalInventoryValue] = await getCustomRangeInventoryValue(start, end);
      setTotalCash(totalInventoryValue);
      if (totalInventoryValue > 0) {
        if (flag === 1) {
          notification('Data loaded successfully', 'success');
        }
      } else {
        notification('There is no data to show', 'error');
      }
    }
  };

  const ranges = useMemo(
    () => [
      {
        startDate: from,
        endDate: to,
        key: 'selection',
      },
    ],
    [from, to]
  );

  const validateYearFilter = () => {
    if (
      parseInt(inputFrom) > 2000 &&
      parseInt(inputTo) > 2000 &&
      parseInt(inputTo) < new Date().getFullYear() + 1 &&
      parseInt(inputFrom) < parseInt(inputTo)
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (dateFilterSelected === 'year') {
      setInputFrom(yesterday.current.getFullYear() - 2);
      setInputTo(yesterday.current.getFullYear());
    }
  }, [dateFilterSelected]);

  useEffect(() => {
    let isValidDate = true;
    if (dateFilterSelected === 'year') {
      isValidDate = validateYearFilter();
    }

    if (from !== '' && to !== '' && isValidDate) {
      const sd = from;
      const ed = to;
      let daylist = dateFuncs.getDaysArray(sd, ed);
      daylist = daylist.map((v) => v.toISOString().split('T', 1)[0]);
      setDayList(daylist);
    }
  }, [to, from]);

  // get StorageCenterCodes for x Axes in bar graphs
  useEffect(async () => {
    const res = await apiService.getStorageCenters();
    const storageCenters = res.map((elem) => elem.storage_center_code);
    setStorageCenterCodes(storageCenters);
  }, []);

  // configure barChart with custom information according to filters and date range selected
  const configureBarChartInformation = async (start, end) => {
    // when item is selected we display 2 bar charts (cash and stock)
    if (filterValue && filterSelected && filterSelected.name === 'item') {
      const [stock, cash, _tI, _tS] = await getCustomRangeInventoryValue(start, end);
      const resultCash = storageCenterCodes.map((elem, i) => {
        const obj = {};
        obj.storageCenter = elem;
        obj.value = cash[elem];
        obj.id = i;
        return obj;
      });
      setBarChartCashData(resultCash);
      setXDomain([0, storageCenterCodes.length - 1]);
      const limit = parseFloat(
        resultCash.reduce((a, b) => (parseFloat(a.value) > parseFloat(b.value) ? a : b)).value
      );
      setYDomainBarChartCash([0, limit]);
      setXKeysBarChart('Storage Centers');

      const resultStock = storageCenterCodes.map((elem, i) => {
        const obj = {};
        obj.storageCenter = elem;
        obj.value = stock[elem];
        obj.id = i;
        return obj;
      });
      setBarChartStockData(resultStock);
      setXDomain([0, storageCenterCodes.length - 1]);
      const limit2 = parseFloat(
        resultStock.reduce((a, b) => (parseFloat(a.value) > parseFloat(b.value) ? a : b)).value
      );
      setYDomainBarChartStock([0, limit2]);
    } else if (filterValue && filterSelected.name === 'storageCenter') {
      const [cash, tI] = await getCustomRangeInventoryValue(start, end);

      const storageLocationCodes = Object.keys(cash).slice(1, cash.length);
      const storageLocationObjects = storageLocationCodes.map((elem, i) => {
        const obj = {};
        obj.storageCenter = elem;
        obj.value = cash[elem];
        obj.id = i;
        return obj;
      });
      setBarChartCashData(storageLocationObjects);
      setXDomain([0, storageLocationObjects.length - 1]);
      const limit = parseFloat(
        storageLocationObjects.reduce((a, b) => (parseFloat(a.value) > parseFloat(b.value) ? a : b))
          .value
      );
      setYDomainBarChartCash([0, limit]);
      setXKeysBarChart('Storage Locations');
    }
    // if other filter is selected we only display 1 bar chart (cash)
    else if (filterValue && filterSelected && filterSelected.name === 'storageLocation') {
      const [cash, tI] = await getCustomRangeInventoryValue(start, end);
      const storageLocationCodes = Object.keys(cash).slice(1, cash.length);
      const storageLocationObjects = storageLocationCodes.map((elem, i) => {
        const obj = {};
        obj.storageCenter = elem;
        obj.value = cash[elem];
        obj.id = i;
        return obj;
      });

      setBarChartCashData(storageLocationObjects);
      setXDomain([0, storageLocationObjects.length - 1]);
      if (storageLocationObjects.length > 0) {
        const limit = parseFloat(
          storageLocationObjects.reduce((a, b) =>
            parseFloat(a.value) > parseFloat(b.value) ? a : b
          ).value
        );
        setYDomainBarChartCash([0, limit]);
      }
      setXKeysBarChart('Items');
    } else {
      const [cash, tI] = await getCustomRangeInventoryValue(start, end);
      const resultCash = storageCenterCodes.map((elem, i) => {
        const obj = {};
        obj.storageCenter = elem;
        obj.value = cash[elem];
        obj.id = i;
        return obj;
      });
      setBarChartCashData(resultCash);
      setXDomain([0, storageCenterCodes.length - 1]);
      const limit = parseFloat(
        resultCash.reduce((a, b) => (parseFloat(a.value) > parseFloat(b.value) ? a : b)).value
      );
      setYDomainBarChartCash([0, limit]);
      setXKeysBarChart('Storage Centers');
    }
  };

  useEffect(() => {
    setFilterApplied(false);
  }, [filterApplied]);

  const [defaultFilters, setDefaultFilters] = useState(true);

  const configureDayGraphs = useCallback(
    async (start, end) => {
      if (storageCenterCodes && dayList) {
        await setLineChartInformationDaily();
        setIsLoadingLineChart(false);
        await configureBarChartInformation(start, end);
        await configureInventoryValueBanner(start, end);
        setIsLoadingBarChart(false);
      }
    },
    [storageCenterCodes, dayList, filterValue]
  );

  const configureWeekGraphs = useCallback(
    async (start, end) => {
      if (storageCenterCodes && inputFrom) {
        const weeksObject = getWeekRange();
        await setLineChartInformationWeekly(weeksObject);
        setIsLoadingLineChart(false);
        await configureBarChartInformation(start, end);
        await configureInventoryValueBanner(start, end);
        setIsLoadingBarChart(false);
      }
    },
    [storageCenterCodes, inputFrom, from, to, filterValue]
  );

  const configureMonthGraphs = useCallback(
    async (start, end) => {
      if (storageCenterCodes) {
        const monthsObject = getMonthRange();
        await setLineChartInformationMonthly(monthsObject, 'Months');
        setIsLoadingLineChart(false);
        await configureBarChartInformation(start, end);
        await configureInventoryValueBanner(start, end);
        setIsLoadingBarChart(false);
      }
    },
    [storageCenterCodes, from, to, filterValue]
  );

  const configureYearGraphs = async () => {
    const yearS = parseInt(inputFrom);
    const yearE = parseInt(inputTo);
    const yearsObject = dateFuncs.getCompleteYearRanges(yearS, yearE);
    await setLineChartInformationMonthly(yearsObject, 'Years');
    setIsLoadingLineChart(false);
    const startYear = new Date(`${inputFrom}-01-01T05:00:00`);
    const start = startYear.toISOString().split('T', 1)[0];
    const endYear = new Date(`${inputTo}-12-31T05:00:00`);
    const end = endYear.toISOString().split('T', 1)[0];
    configureInventoryValueBanner(start, end);
    await configureBarChartInformation(start, end);
    setIsLoadingBarChart(false);
  };

  const configureFirstTime = async () => {
    const start = from.toISOString().split('T', 1)[0];
    const end = to.toISOString().split('T', 1)[0];
    setDefaultFilters(false);
    await setLineChartInformationDaily();
    setIsLoadingLineChart(false);
    configureInventoryValueBanner(start, end);
    await configureBarChartInformation(start, end);
    setIsLoadingBarChart(false);
  };

  useEffect(() => {
    // if clicked "apply filter" button or selected new date range, configure bar and line charts
    if (filterApplied) {
      if (from === '' || to === '') {
        console.log('enter valid dates');
      } else {
        setIsLoadingLineChart(true);
        setIsLoadingBarChart(true);
        const start = from.toISOString().split('T', 1)[0];
        const end = to.toISOString().split('T', 1)[0];
        setTotalClear(false);
        if (dateFilterSelected === 'day') {
          configureDayGraphs(start, end);
        } else if (dateFilterSelected === 'week') {
          configureWeekGraphs(start, end);
        } else if (dateFilterSelected === 'month') {
          configureMonthGraphs(start, end);
        } else if (dateFilterSelected === 'year') {
          const intFrom = parseInt(inputFrom);
          const intTo = parseInt(inputTo);
          if (
            inputFrom === '' ||
            inputTo === '' ||
            intFrom < 2000 ||
            intTo < intFrom ||
            inputTo > 2022
          ) {
            notification('Invalid range', 'error');
            setIsLoadingLineChart(false);
            setIsLoadingBarChart(false);
          } else {
            configureYearGraphs();
          }
        }
      }
    }
    //predefined filters
    if (filterApplied && storageCenterCodes !== undefined && predefinedTimeFilter !== '') {
      const start = from.toISOString().split('T', 1)[0];
      const end = to.toISOString().split('T', 1)[0];
      configureDayGraphs(start, end);
      // this loads the charts information the first time
    } else if (dayList.length > 1 && storageCenterCodes !== undefined && defaultFilters) {
      configureFirstTime();
    }
  }, [
    dayList,
    filterApplied,
    storageCenterCodes,
    configureDayGraphs,
    configureWeekGraphs,
    configureMonthGraphs,
    from,
    to,
  ]);

  // TODO store current filters in local storage and check those filters to update graph every minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      // TODO
      // check current filters
      // update charts
    }, 6000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClearFilter = () => {
    setFiltersSubtitle('');
    setItemFiltered(false);
    setBarChartCashData([]);
    setLineChartCashData([]);
    setFilterSelected();
    setSelectedOption('');
    setFilterCode('');
    setTotalCash(0);
    setDateFilterSelected('');
    setFrom('');
    setTo('');
    setInputFrom('');
    setInputTo('');
    setPredefinedTimeFilter('');
    setTotalClear(true);
  };

  const handleDateFilter = (filterItem) => {
    setPredefinedTimeFilter('');
    setIsCustomRange(true);
    setFrom(yesterday.current);
    setTo(yesterday.current);
    if (filterItem == 'Week') {
      setDateFilterSelected('week');
    } else if (filterItem == 'Month') {
      setDateFilterSelected('month');
    } else if (filterItem == 'Day') {
      setDateFilterSelected('day');
      setDayPicker(true);
    } else if (filterItem == 'Year') {
      setInputFrom('');
      setInputTo('');
      setDateFilterSelected('year');
    }
  };

  const handlePredefinedFilter = (filterItem) => {
    setDateFilterSelected('');
    if (filterItem === 'Current Day') {
      setPredefinedTimeFilter('Current Day');
      setFrom(subDays(yesterday.current, 1));
      setTo(yesterday.current);
    } else if (filterItem === 'Current Week') {
      const daysToSubstract = yesterday.current.getDay() !== 0 ? yesterday.current.getDay() - 1 : 6;
      setPredefinedTimeFilter('Current Week');
      setFrom(subDays(yesterday.current, daysToSubstract));
      setTo(yesterday.current);
    } else if (filterItem === 'Current Month') {
      setPredefinedTimeFilter('Current Month');
      setFrom(subDays(yesterday.current, yesterday.current.getDate() - 1));
      setTo(yesterday.current);
    }
  };

  const handleDateInput = () => {
    setDayPicker(true);
  };

  useEffect(() => {
    setFiltersSubtitle(
      `Filtered by starting date ${from.toISOString().split('T', 1)[0]}, ending date ${
        to.toISOString().split('T', 1)[0]
      }`
    );
  }, []);

  const handleApplyFilter = () => {
    const userTimezoneOffset = from.getTimezoneOffset() * 60000;
    const fromModified = new Date(from.getTime() - userTimezoneOffset);
    const toModified = new Date(to.getTime() - userTimezoneOffset);
    try {
      flag = 1;
      setFilterApplied(true);
      if (dateFilterSelected === 'year') {
        const isDateValid = validateYearFilter();
        isDateValid &&
          setFiltersSubtitle(
            `Filtered by total, starting date ${
              fromModified.toISOString().split('T', 1)[0]
            }, ending date ${toModified.toISOString().split('T', 1)[0]} (${dateFilterSelected})`
          );
      } else if (filterSelected.name === 'total') {
        setFiltersSubtitle(
          `Filtered by total, starting date ${
            fromModified.toISOString().split('T', 1)[0]
          }, ending date ${toModified.toISOString().split('T', 1)[0]} (${
            dateFilterSelected !== '' ? dateFilterSelected : 'day'
          })`
        );
      } else if (filterSelected && filterCode) {
        setFiltersSubtitle(
          `Filtered by ${filterSelected.name} = ${filterCode}, starting date ${
            from.toISOString().split('T', 1)[0]
          }, ending date ${to.toISOString().split('T', 1)[0]} (${dateFilterSelected})`
        );
      } else {
        setFiltersSubtitle(
          `Filtered by starting date ${from.toISOString().split('T', 1)[0]}, ending date ${
            to.toISOString().split('T', 1)[0]
          } (${dateFilterSelected})`
        );
      }

      if (filterCode && filterSelected.name === 'item') {
        setItemFiltered(true);
        configureItemUnit();
      } else {
        setItemFiltered(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Percentages calculations
  const getPercentage = (startValue, endValue) => {
    if (parseInt(startValue) === 0 && parseInt(endValue) === 0) {
      return 0;
    }
    if (parseInt(startValue) === 0) {
      return 100;
    }
    const difference = endValue - startValue;
    const percentage = (difference / startValue) * 100;
    const twoDecimalPercentage = percentage.toFixed(2);
    const percentageToDisplay =
      twoDecimalPercentage % 1 === 0 ? Math.round(percentage) : twoDecimalPercentage;
    return percentageToDisplay;
  };

  const getCashPercentage = async () => {
    try {
      //if range filters are selected
      if (dateFilterSelected === 'day') {
        const daysObject = getPartialDayRanges();
        const daysList = daysObject.map((day) => day.start);
        const [startDayCash, endDayCash] = await getDailyCash(daysList);
        return getPercentage(startDayCash, endDayCash);
      }
      if (dateFilterSelected === 'week') {
        const weeksObject = getPartialWeekRanges();
        const [startWeekCash, endWeekCash] = await getWeeklyCash(weeksObject);
        return getPercentage(startWeekCash, endWeekCash);
      }
      if (dateFilterSelected === 'month') {
        const monthObject = getPartialMonthRanges();
        const [startMonthCash, endMonthCash] = await getMonthlyCash(monthObject);
        return getPercentage(startMonthCash, endMonthCash);
      }
      if (dateFilterSelected === 'year') {
        const yearObject = getPartialYearRanges();
        const [startYearCash, endYearCash] = await getMonthlyCash(yearObject);
        return getPercentage(startYearCash, endYearCash);
      }
      //if predefined filter is selected
      if (predefinedTimeFilter !== '') {
        const start = from.toISOString().split('T', 1)[0];
        const end = to.toISOString().split('T', 1)[0];
        const predefinedDaysList = [start, end];
        const [startDayCash, endDayCash] = await getDailyCash(predefinedDaysList);
        return getPercentage(startDayCash, endDayCash);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getStockPercentage = async () => {
    const dailyStockResult = await getDailyStock();
    if (dailyStockResult.length > 1) {
      const stockPercentage = getPercentage(
        dailyStockResult[0],
        dailyStockResult[dailyStockResult.length - 1]
      );
      return stockPercentage;
    } else return getPercentage(dailyStockResult[0], dailyStockResult[0]);
  };

  const percentagesInfo = {
    positive: {
      color: 'green',
      sign: '+',
    },
    negative: {
      color: 'red',
      sign: '-',
    },
    zero: {
      color: '#aaaaa',
      sign: 'none',
    },
  };

  const getPercentageType = (percentage) => {
    let type;
    if (percentage === 0) {
      type = 'zero';
    } else if (percentage > 0) {
      type = 'positive';
    } else if (percentage < 0) {
      type = 'negative';
    }
    return type;
  };
  const configureCashPercentage = async () => {
    const percentage = await getCashPercentage();
    const percentageType = getPercentageType(percentage);
    setPercentage(Math.abs(percentage));
    setPercentageColor(percentagesInfo[percentageType].color);
    setPercentageSign(percentagesInfo[percentageType].sign);
  };

  const configureStockPercentage = async () => {
    const percentage = await getStockPercentage();
    const percentageType = getPercentageType(percentage);
    setStockPercentage(Math.abs(percentage));
    setStockPercentageColor(percentagesInfo[percentageType].color);
    setStockPercentageSign(percentagesInfo[percentageType].sign);
  };

  useEffect(() => {
    configureCashPercentage();
    if (filterSelected.name === 'item') configureStockPercentage();
  }, [filterApplied]);

  function getDateOfISOWeek(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  // Returns the ISO week of the date.
  Date.prototype.getWeek = function () {
    const date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
      )
    );
  };

  function getDateRangeOfWeek(weekNo, y) {
    let d1;
    let numOfdaysPastSinceLastMonday;
    let rangeIsFrom;
    let rangeIsTo;
    d1 = new Date(`${(parseInt(y) + 1).toString()}`);
    numOfdaysPastSinceLastMonday = d1.getDay() - 1;
    d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
    d1.setDate(d1.getDate() + 7 * (weekNo - d1.getWeek()));
    rangeIsFrom = `${d1.getFullYear()}-${parseInt(d1.getMonth()) + 1}-${d1.getDate()}`;
    d1.setDate(d1.getDate() + 6);
    rangeIsTo = `${d1.getFullYear()}-${parseInt(d1.getMonth()) + 1}-${d1.getDate()}`;
    return {
      start: new Date(rangeIsFrom),
      end: new Date(rangeIsTo),
      label: `${y}-W${weekNo}`,
    };
  }

  const getPartialDayRanges = () => {
    // The endpoint makes start 00:00 and end 23:59
    const firstDayStart = from;
    const firstDayEnd = firstDayStart;
    const secondDayStart = to;
    const secondDayEnd = secondDayStart;
    const dayList = [
      {
        start: firstDayStart.toISOString().split('T', 1)[0],
        end: firstDayEnd.toISOString().split('T', 1)[0],
      },
      {
        start: secondDayStart.toISOString().split('T', 1)[0],
        end: secondDayEnd.toISOString().split('T', 1)[0],
      },
    ];
    return dayList;
  };

  const getPartialWeekRanges = () => {
    let weekList = [];
    const year = inputFrom.split('-', 1)[0];
    const fromWeek = parseInt(inputFrom.split('W')[1]);
    const toWeek = parseInt(inputTo.split('W')[1]);
    [fromWeek, toWeek].map((weekNumber) => {
      const weekIsoRange = getDateRangeOfWeek(weekNumber, year);
      weekList = [...weekList, weekIsoRange];
    });
    return weekList;
  };

  function weekDiff(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    return Math.abs(Math.round(diff));
  }

  const getWeekRange = () => {
    const startWeek = parseInt(inputFrom.split('W')[1]) - 1;
    let year = inputFrom.split('-', 1)[0];
    const d1 = from;
    const d2 = to;
    const weeksContainer = [];
    let diff = weekDiff(d1, d2);
    let inc = 0;
    while (diff > 0) {
      const weekNumber = ((startWeek + inc) % 52) + 1;
      if (weekNumber === 1) {
        year++;
      }
      const weekIso = getDateRangeOfWeek(weekNumber, year);
      weeksContainer.push(weekIso);
      inc++;
      diff--;
    }
    return weeksContainer;
  };

  const twoDigitMonth = (n) => (n < 10 ? `0${n}` : `${n}`);

  const getWeekNumber = () => {
    const currentdate = new Date();
    const oneJan = new Date(currentdate.getFullYear(), 0, 1);
    const numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
    const result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
    return result;
  };

  const maxDates = {
    day: yesterday.current,
    week: `${yesterday.current.getFullYear()}-W${getWeekNumber() - 1}`,
    month: `${yesterday.current.getFullYear()}-${twoDigitMonth(yesterday.current.getMonth() + 1)}`,
    year: yesterday.current.getFullYear(),
  };
  //Get the first and the last day of start and end months selected
  const getPartialMonthRanges = () => {
    // months are 0 indexed
    const monthStart = from.getMonth();
    const monthEnd = to.getMonth();
    const yearStart = from.getFullYear();
    const yearEnd = to.getFullYear();

    const dateContainer = [
      { month: monthStart, year: yearStart },
      { month: monthEnd, year: yearEnd },
    ];
    const res = dateContainer.map((elem) => {
      const firstDayOfM = startOfMonth(new Date(elem.year, elem.month));
      const lastDayOfM = lastDayOfMonth(new Date(elem.year, elem.month));
      const label = `${elem.year}-${twoDigitMonth(elem.month + 1)}`;
      return {
        start: new Date(firstDayOfM),
        end: new Date(lastDayOfM),
        label,
      };
    });

    return res;
  };

  //Get the first and the last day of every month from the start to end months selected
  const getMonthRange = () => {
    const startMonth = from.getMonth();
    let year = from.getFullYear();
    const d1 = from;
    const d2 = to;
    const monthsContainer = [];
    let diff = differenceInMonths(d2, d1);
    diff = diff + 1;
    let inc = 0;
    while (diff > 0) {
      const monthNumber = (startMonth + inc) % 12;
      if (monthNumber === 0 && inc !== 0) {
        year++;
      }
      const firstDayOfM = startOfMonth(new Date(year, monthNumber));
      const lastDayOfM = lastDayOfMonth(new Date(year, monthNumber));
      const label = `${year}-${twoDigitMonth(monthNumber + 1)}`;
      monthsContainer.push({
        start: new Date(firstDayOfM),
        end: new Date(lastDayOfM),
        label,
      });
      inc++;
      diff--;
    }
    return monthsContainer;
  };

  const getPartialYearRanges = () => {
    const yearS = inputFrom;
    const yearE = inputTo;

    return [yearS, yearE].map((year) => ({
      start: new Date(`${year}-01-01`),
      end: new Date(`${year}-12-31`),
      label: `${year}`,
    }));
  };

  const handleInputChangeStart = (date) => {
    if (dateFilterSelected === 'week') {
      const year = date.split('-', 1)[0];
      const week = date.split('W')[1];
      const startDayOfW = getDateOfISOWeek(week, year);
      //From contain the date of the first day of the week
      setFrom(new Date(startDayOfW));
      //InputFrom contain the raw input (2022-W10)
      setInputFrom(date);
    } else if (dateFilterSelected === 'month') {
      const startMonth = `${date}-01T00:00:00`;
      setFrom(new Date(startMonth));
      setInputFrom(date);
    } else if (dateFilterSelected === 'year') {
      const startYear = startOfYear(new Date(date, 0, 1, 0, 0, 0));
      setInputFrom(date);
      setFrom(startYear);
    }
  };

  const handleInputChangeEnd = (date) => {
    if (dateFilterSelected === 'week') {
      //week format is 2022-W10
      const year = date.split('-', 1)[0];
      const week = date.split('W')[1];
      const startDate = getDateOfISOWeek(week, year);
      const lastDayOfW = lastDayOfWeek(startDate, { weekStartsOn: 1 });
      //To contain the date of the last day of the week
      setTo(lastDayOfW);
      //InputTo contain the raw input (2022-W10)
      setInputTo(date);
    } else if (dateFilterSelected === 'month') {
      const lastDate = lastDayOfMonth(
        new Date(parseInt(date.split('-')[0]) - 1, parseInt(date.split('-')[1]))
      );
      const lastDay = new Date(lastDate).getDate();
      const endMonth = `${date}-${lastDay}`;
      setTo(new Date(endMonth));
      setInputTo(date);
    } else if (dateFilterSelected === 'year') {
      const endYear = endOfYear(new Date(date, 11, 31, 12, 0, 0));
      setTo(endYear);
      setInputTo(date);
    }
  };

  const handleCloseModal = () => {
    setShowTableModalCash(false);
    setShowTableModalStock(false);
    setShowTableModalTimeCash(false);
    setShowTableModalTimeStock(false);
  };

  // download chart as png image
  const downloadSvgImage = (id, name) => {
    const svg = document.getElementById(id);
    saveSvgAsPng.saveSvgAsPng(svg, `${name}.png`, {
      scale: 1,
      width: widthGraph,
      height: heightGraph,
    });
  };

  // function to download svg as pdf
  async function downloadSvgAsPdf(id, name) {
    const graph = document.getElementById(id);
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfCanvas = document.createElement('canvas');
    pdfCanvas.setAttribute('width', '1000');
    pdfCanvas.setAttribute('height', '1000');

    const dataURI = await saveSvgAsPng.svgAsPngUri(graph, {
      scale: 1,
      width: widthGraph,
      height: heightGraph,
    });

    if (name.length > 50) {
      pdf.setFontSize(9);
    } else {
      pdf.setFontSize(12);
    }
    pdf.text('Indicators Section', 0, 20);
    pdf.text(name, 0, 40);
    pdf.addImage(dataURI, 'PNG', 0, 60);
    pdf.save(`${name}.pdf`);
  }

  // function to download data as excel
  const exportToExcel = (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // add dimenional unit to table data
  useEffect(() => {
    let cashData = [];
    let stockData = [];
    let lineCashData = [];
    let lineStockData = [];
    barChartCashData.forEach((element) => {
      cashData.push({
        storageCenter: element.storageCenter,
        value: element.value,
        unit: 'USD',
      });
    });
    barChartStockData.forEach((element) => {
      stockData.push({
        storageCenter: element.storageCenter,
        value: element.value,
        unit: itemUnit,
      });
    });
    lineChartCashData.forEach((element) => {
      lineCashData.push({
        date: element.date,
        value: element.value,
        unit: 'USD',
      });
    });
    lineChartStockData.forEach((element) => {
      lineStockData.push({
        date: element.date,
        value: element.value,
        unit: itemUnit,
      });
    });
    setBarChartStockDataTable(stockData);
    setBarChartCashDataTable(cashData);
    setLineChartStockDataTable(lineStockData);
    setLineChartCashDataTable(lineCashData);
  }, [barChartCashData, barChartStockData, lineChartCashData, lineChartStockData]);

  return (
    <>
      <SimpleTableModal
        title={`Inventory value by storage center (Cash) ${filtersSubtitle}`}
        headers={tableHeaders}
        tableData={barChartCashDataTable}
        show={showTableModalCash}
        handleClose={handleCloseModal}
      />
      <SimpleTableModal
        title={`Inventory value by storage center (Stock) ${filtersSubtitle}`}
        headers={tableHeaders}
        tableData={barChartStockDataTable}
        show={showTableModalStock}
        handleClose={handleCloseModal}
      />
      <SimpleTableModal
        title={`Inventory value in time (Cash) ${filtersSubtitle}`}
        headers={tableHeadersTime}
        tableData={lineChartCashDataTable}
        show={showTableModalTimeCash}
        handleClose={handleCloseModal}
      />
      <SimpleTableModal
        title={`Inventory value in time (Stock) ${filtersSubtitle}`}
        headers={tableHeadersTime}
        tableData={lineChartStockDataTable}
        show={showTableModalTimeStock}
        handleClose={handleCloseModal}
      />
      <HeaderTitle title="Historical Dashboard " />
      <Row className="px-0 pb-3">
        <Col className="d-flex">
          <Button
            style={Style.customFiltersButton}
            className="mr-3"
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            {showFilters ? 'Hide filters ' : 'Show filters '}
            {showFilters ? <RiIcons.RiEyeOffFill /> : <RiIcons.RiEyeFill />}
          </Button>
          <DropdownButton
            style={Style.dropdown}
            className="pr-3"
            variant={predefinedTimeFilter ? 'secondary' : 'outline-secondary'}
            title={predefinedTimeFilter || 'Predefined range'}
            id="input-group-dropdown-2"
          >
            {['Current Day', 'Current Week', 'Current Month'].map((filterItem, idx) => (
              <Dropdown.Item key={idx} href="#" onClick={() => handlePredefinedFilter(filterItem)}>
                {filterItem}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <Button className="fpb-btn" onClick={handleApplyFilter}>
            Apply Filters
          </Button>
          <Button className="fpb-btn-inverse ml-2" onClick={handleClearFilter}>
            Clear Filters
          </Button>
        </Col>
      </Row>
      <div className="filtersContainer" id={showFilters ? 'show' : 'hide'}>
        <Row className="px-0 pb-3">
          <Col className="d-flex">
            <FilterSelect
              filterSelected={filterSelected}
              filterObjects={filterObjects}
              selectedOption={selectedOption}
              filterData={filterData}
              setFilterSelected={setFilterSelected}
              setFilterValue={setFilterValue}
              setFilterCode={setFilterCode}
              setSelectedOption={setSelectedOption}
            />
          </Col>
        </Row>
        <Row className="px-0 ">
          <Col className="d-flex">
            <DropdownButton
              style={{ width: 'auto' }}
              className="pr-3"
              variant={dateFilterSelected ? 'secondary' : 'outline-secondary'}
              title={dateFilterSelected || 'Custom range'}
              id="input-group-dropdown-2"
            >
              {['Day', 'Week', 'Month', 'Year'].map((filterItem, idx) => (
                <Dropdown.Item key={idx} href="#" onClick={() => handleDateFilter(filterItem)}>
                  {filterItem}
                </Dropdown.Item>
              ))}
            </DropdownButton>

            {/* if filter is year, month or  week, show default date inputs */}

            <div
              className="row"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="col-md-6 px-1">
                <div className="input-group  mb-3 pl-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Start
                    </span>
                  </div>
                  <input
                    className="form-control"
                    style={
                      dateFilterSelected == 'year'
                        ? { paddingLeft: '3rem', paddingRight: '1rem' }
                        : null
                    }
                    type={dateFilterSelected == 'year' ? 'number' : dateFilterSelected}
                    value={
                      dateFilterSelected === 'day' || predefinedTimeFilter !== ''
                        ? from.toISOString().split('T', 1)[0]
                        : inputFrom
                    }
                    min={dateFilterSelected === 'year' ? '2018' : undefined}
                    max={dateFilterSelected === 'year' ? maxDates.year : undefined}
                    disabled={!isCustomRange}
                    onChange={(e) => {
                      handleInputChangeStart(e.target.value);
                    }}
                    onClick={() => {
                      handleDateInput();
                      setIsEndDateInput();
                      setIsStartDateInput(true);
                      setDayFilterCalendar(true);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 pl-0">
                <div className="input-group  mb-3 pl-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      End
                    </span>
                  </div>
                  <input
                    className="form-control"
                    style={
                      dateFilterSelected == 'year'
                        ? { paddingLeft: '3rem', paddingRight: '1rem' }
                        : null
                    }
                    min={dateFilterSelected === 'year' ? '2018' : undefined}
                    type={dateFilterSelected == 'year' ? 'number' : dateFilterSelected}
                    value={
                      dateFilterSelected === 'day' || predefinedTimeFilter !== ''
                        ? to.toISOString().split('T', 1)[0]
                        : inputTo
                    }
                    max={
                      dateFilterSelected === 'year'
                        ? maxDates.year
                        : dateFilterSelected === 'month'
                        ? maxDates.month
                        : dateFilterSelected === 'week'
                        ? maxDates.week
                        : dateFilterSelected === 'day'
                        ? maxDates.day
                        : ''
                    }
                    disabled={!isCustomRange}
                    onChange={(e) => handleInputChangeEnd(e.target.value)}
                    onClick={() => {
                      handleDateInput();
                      setIsEndDateInput(true);
                      setIsStartDateInput();
                      setDayFilterCalendar(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* day filter */}
        {dateFilterSelected === 'day' && dayPicker && (
          <Row>
            <DateRange
              ranges={ranges}
              onChange={handleSelect}
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              calendarFocus="forwards"
              maxDate={maxDates.day}
              retainEndDateOnFirstSelection
              {...(dayFilterCalendar && {
                focusedRange: isEndDateInput ? [0, 1] : isStartDateInput ? [0, 0] : {},
              })}
            />
          </Row>
        )}
        <Form.Group as={Row} />
      </div>

      <Row>
        <Col>
          <div className="px-4 py-3 mt-3 card" style={Style.banner}>
            <h4 style={{ color: '#082d45' }}>Inventory value (cash)</h4>
            {percentage !== undefined && (
              <IndicatorPercentages
                percentage={percentage}
                percentageColor={percentageColor}
                percentageSign={percentageSign}
              />
            )}

            <InventoryBanner total={totalCash} />
          </div>
        </Col>
        {itemFiltered && (
          <Col>
            <div className="px-4 py-3 mt-3 card" style={Style.banner}>
              <h4 style={{ color: '#082d45' }}>Inventory value (stock)</h4>
              {percentage !== undefined && (
                <IndicatorPercentages
                  percentage={stockPercentage}
                  percentageColor={stockPercentageColor}
                  percentageSign={stockPercentageSign}
                />
              )}
              <InventoryBanner total={totalStock} itemUnit={itemUnit} />
            </div>
          </Col>
        )}
      </Row>
      <div className="my-2">
        <Row>
          <Col md={6}>
            {totalClear && <ClearDataAlert />}
            <div style={totalClear ? Style.invisible : {}}>
              <Col>
                <Dropdown as={ButtonGroup}>
                  <h4 className="mt-2" style={Style.chartTitle}>
                    Inventory value by storage center (cash)
                  </h4>
                  <Dropdown.Toggle className="fpb-btn-small" id="dropdown-download" />
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        downloadSvgImage(
                          'inventoryStorageCenterCash',
                          `Inventory_Value_Storage_Center_Cash_${filtersSubtitle}`
                        )
                      }
                    >
                      Download (.png)
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        downloadSvgAsPdf(
                          'inventoryStorageCenterCash',
                          `Inventory_Value_Storage_Center_Cash_${filtersSubtitle}`
                        )
                      }
                    >
                      Download (.pdf)
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowTableModalCash(true)}>
                      Show Data
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        exportToExcel(
                          barChartCashDataTable,
                          `Inventory_Value_Storage_Center_Cash_${filtersSubtitle}`
                        )
                      }
                    >
                      Export Data
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <FilterSubtitle />
              </Col>
              {isLoadingBarChart ? (
                <LoadingSpinner />
              ) : (
                <svg
                  width={widthGraph}
                  height={heightGraph}
                  viewBox={`0,10,${widthGraph + 30},${heightGraph + 20}`}
                  style={Style.chart}
                  id={'inventoryStorageCenterCash'}
                >
                  <XAxisBarChart
                    xDomain={xDomain}
                    width={widthGraph}
                    height={heightGraph}
                    tickCount={tickCount}
                    data={barChartCashData}
                  />
                  <YAxis yDomain={yDomainBarChartCash} height={heightGraph} tickCount={tickCount} />
                  <XLabel
                    keys={[xKeysBarChart]}
                    xDomain={xDomain}
                    width={widthGraph}
                    height={heightGraph}
                  />
                  <YLabel
                    keys={['Total cash value ($)']}
                    yDomain={yDomainBarChartCash}
                    width={widthGraph}
                    height={heightGraph}
                  />
                  <BarChart
                    xDomain={xDomain}
                    yDomain={yDomainBarChartCash}
                    width={widthGraph}
                    height={heightGraph}
                    tickCount={tickCount}
                    data={barChartCashData}
                    name="b1"
                  />
                </svg>
              )}
            </div>
          </Col>

          {itemFiltered && (
            <Col md={6}>
              {totalClear && <ClearDataAlert />}
              <div style={totalClear ? Style.invisible : {}}>
                <Dropdown as={ButtonGroup}>
                  <h4 className="mt-2" style={Style.chartTitle}>
                    Inventory value by storage center (stock)
                  </h4>
                  <Dropdown.Toggle className="fpb-btn-small" split id="dropdown-download" />
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        downloadSvgImage(
                          'inventoryStorageCenterStock',
                          `Inventory_Value_Storage_Center_Stock_${filtersSubtitle}`
                        )
                      }
                    >
                      Download (.png)
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        downloadSvgAsPdf(
                          'inventoryStorageCenterStock',
                          `Inventory_Value_Storage_Center_Stock_${filtersSubtitle}`
                        )
                      }
                    >
                      Download (.pdf)
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowTableModalStock(true)}>
                      Show Data
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        exportToExcel(
                          barChartStockDataTable,
                          `Inventory_Value_Storage_Center_Stock_${filtersSubtitle}`
                        )
                      }
                    >
                      Export Data
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <FilterSubtitle />
                {isLoadingBarChart ? (
                  <LoadingSpinner />
                ) : (
                  <svg
                    width={widthGraph}
                    height={heightGraph - 30}
                    viewBox={`0,10,${widthGraph},${heightGraph}`}
                    style={Style.chart}
                    id={'inventoryStorageCenterStock'}
                  >
                    <BarChart
                      xDomain={xDomain}
                      yDomain={yDomainBarChartStock}
                      width={widthGraph}
                      height={heightGraph}
                      tickCount={tickCount}
                      data={barChartStockData}
                      name="b2"
                    />
                    <XAxisBarChart
                      xDomain={xDomain}
                      width={widthGraph}
                      height={heightGraph}
                      tickCount={tickCount}
                      data={barChartStockData}
                    />
                    <YAxis
                      yDomain={yDomainBarChartStock}
                      height={heightGraph}
                      tickCount={tickCount}
                    />
                    <XLabel
                      keys={[xKeysBarChart]}
                      xDomain={xDomain}
                      width={widthGraph}
                      height={heightGraph}
                    />
                    <YLabel
                      keys={[`Total stock value (${itemUnit})`]}
                      yDomain={yDomainBarChartStock}
                      width={widthGraph}
                      height={heightGraph}
                    />
                  </svg>
                )}
              </div>
            </Col>
          )}
        </Row>
      </div>
      <div className="my-2">
        <Row>
          <Col md={6}>
            {totalClear && <ClearDataAlert />}
            <div style={totalClear ? Style.invisible : {}}>
              <Dropdown as={ButtonGroup}>
                <h4 className="mt-2" style={Style.chartTitle}>
                  Inventory value in time (cash)
                </h4>
                <Dropdown.Toggle className="fpb-btn-small" split id="dropdown-download" />
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      downloadSvgImage(
                        'inventoryValueTimeCash',
                        `Inventory_Value_Time_Cash_${filtersSubtitle}`
                      )
                    }
                  >
                    Download (.png)
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      downloadSvgAsPdf(
                        'inventoryValueTimeCash',
                        `Inventory_Value_Time_Cash_${filtersSubtitle}`
                      )
                    }
                  >
                    Download (.pdf)
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowTableModalTimeCash(true)}>
                    Show Data
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      exportToExcel(
                        lineChartCashDataTable,
                        `Inventory_Value_Time_Cash_${filtersSubtitle}`
                      )
                    }
                  >
                    Export Data
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <FilterSubtitle />
              {isLoadingLineChart ? (
                <LoadingSpinner />
              ) : (
                <svg
                  width={widthGraph}
                  height={heightGraph - 30}
                  viewBox={`0,10,${widthGraph},${heightGraph}`}
                  style={Style.chart}
                  id={'inventoryValueTimeCash'}
                >
                  <>
                    <XAxisLineChart
                      xDomain={rangeData}
                      width={widthGraph}
                      height={heightGraph}
                      data={lineChartCashData.filter((e, i) => i !== lineChartCashData.length)}
                    />
                    <YAxis yDomain={yDomainLineChart} height={heightGraph} />
                    <XLabel
                      keys={[xKeys]}
                      xDomain={rangeData}
                      width={widthGraph}
                      height={heightGraph}
                    />
                    <YLabel
                      keys={['Total cash value ($)']}
                      yDomain={yDomainLineChart}
                      width={widthGraph}
                      height={heightGraph}
                    />
                    <LineChart
                      xDomain={rangeData}
                      yDomain={yDomainLineChart}
                      width={widthGraph}
                      height={heightGraph}
                      data={lineChartCashData}
                      name="b3"
                    />{' '}
                  </>
                </svg>
              )}
            </div>
          </Col>
          {itemFiltered && (
            <Col md={6}>
              {totalClear && <ClearDataAlert />}
              <div style={totalClear ? Style.invisible : {}}>
                <Dropdown as={ButtonGroup}>
                  <h4 className="mt-2" style={Style.chartTitle}>
                    Inventory value in time (stock)
                  </h4>
                  <Dropdown.Toggle className="fpb-btn-small" split id="dropdown-download" />
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        downloadSvgImage(
                          'inventoryValueTimeStock',
                          `Inventory_Value_Time_Stock_${filtersSubtitle}`
                        )
                      }
                    >
                      Download (.png)
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        downloadSvgAsPdf(
                          'inventoryValueTimeStock',
                          `Inventory_Value_Time_Stock_${filtersSubtitle}`
                        )
                      }
                    >
                      Download (.pdf)
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setShowTableModalTimeStock(true)}>
                      Show Data
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        exportToExcel(
                          lineChartStockDataTable,
                          `Inventory_Value_Time_Stock_${filtersSubtitle}`
                        )
                      }
                    >
                      Export Data
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <FilterSubtitle />
                {isLoadingLineChart ? (
                  <LoadingSpinner />
                ) : (
                  <svg
                    width={widthGraph}
                    height={heightGraph - 30}
                    viewBox={`0,10,${widthGraph},${heightGraph}`}
                    style={Style.chart}
                    id={'inventoryValueTimeStock'}
                  >
                    <XAxisLineChart
                      xDomain={rangeData}
                      width={widthGraph}
                      height={heightGraph}
                      data={lineChartStockData.filter((e, i) => i !== lineChartStockData.length)}
                    />
                    <YAxis yDomain={yDomainLineChart2} height={heightGraph} />
                    <XLabel
                      keys={[xKeys]}
                      xDomain={rangeData}
                      width={widthGraph}
                      height={heightGraph}
                    />
                    <YLabel
                      keys={[`Total stock value (${itemUnit})`]}
                      yDomain={yDomainLineChart2}
                      width={widthGraph}
                      height={heightGraph}
                    />
                    <LineChart
                      xDomain={rangeData}
                      yDomain={yDomainLineChart2}
                      width={widthGraph}
                      height={heightGraph}
                      data={lineChartStockData}
                      name="b4"
                    />
                  </svg>
                )}
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default IndicatorsSection;
