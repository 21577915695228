import { createSlice } from '@reduxjs/toolkit';

const historicalSlice = createSlice({
  name: 'historical',
  initialState: {
    historic: false,
    dayList: [],
  },
  reducers: {
    setHistoric: (state, action) => {
      state.historic = action.payload;
    },
    setDayList: (state, action) => {
      state.dayList = action.payload;
    },
  },
});

export const setHistoricValue = (bool) => (dispatch) => {
  dispatch(setHistoric(bool));
};
export const setDayListArray = (array) => (dispatch) => {
  dispatch(setDayList(array));
};

export const { setHistoric, setDayList } = historicalSlice.actions;
export const showHistoricValue = (state) => state.historical.historic;
export const showDayList = (state) => state.historical.dayList;

export default historicalSlice.reducer;
