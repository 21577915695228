import { React, useRef, useLayoutEffect } from 'react';
import { select, scaleLinear } from 'd3';

export const YLabel = ({ keys, yDomain, width, height }) => {
  const legendRef = useRef(null);

  useLayoutEffect(() => {
    const y = scaleLinear()
      .domain(yDomain)
      .range([height - 60, 50]);

    select(legendRef.current)
      .selectAll('text')
      .data(keys)
      .join('text')
      .attr('text-anchor', 'end')
      .attr('transform', 'rotate(-90)')
      .attr('y', -50)
      .attr('x', -50)
      .attr('fill', '#5e5e5e')
      .text((d) => d);
  }, [keys, width, yDomain]);

  return <g transform="translate(65, 25)" ref={legendRef} />;
};
