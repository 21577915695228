import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as FaIcons from 'react-icons/fa';

const SidebarLink = styled(NavLink)`
  display: flex;
  color: #e1e9fc;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem !important;
  list-style: none;
  text-decoration: none;
  margin: 0.5rem 0rem;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    color: #6cffa7;
    text-decoration: none;
    margin: 0.5rem 0rem;
    padding: 0.75rem 1.5rem !important;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;
const SidebarLabelSub = styled.p`
  margin-left: 16px;
  margin-bottom: 0;
`;

const DropdownLink = styled(NavLink)`
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  padding-left: ${({ sidebarFull }) => (sidebarFull ? '3rem' : '1.7rem')};
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  margin: 0.5rem 0rem;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    padding-left: ${({ sidebarFull }) => (sidebarFull ? '3rem' : '1.7rem')};
    margin: 0.5rem 0rem;
    color: #6cffa7;
    text-decoration: none;
  }
`;

const SubNav = ({ item, index, sidebarFull }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = (e) => {
    setSubnav(!subnav);
    if (!item.path) {
      e.preventDefault();
    }
  };

  if (item.title === 'Dashboard') {
    return (
      <>
        <SidebarLink
          exact
          to={item.path ? item.path : ''}
          onClick={item.subsubmenu && showSubnav}
          activeClassName="activeSidebar"
          style={{
            marginLeft: '16px',
          }}
        >
          <div>
            {item.icon}
            {sidebarFull ? <SidebarLabel>{item.title}</SidebarLabel> : <></>}
          </div>
          <div className="pl-2">
            {item.subsubmenu && subnav ? item.iconOpened : item.subsubmenu ? item.iconClosed : null}
          </div>
        </SidebarLink>
        {subnav &&
          item.subsubmenu.map((item, index) => (
            <DropdownLink
              to={item.path}
              key={index}
              activeClassName="activeSidebar"
              style={{
                paddingLeft: '38px',
                fontSize: '14px',
                margin: '0.5rem 0.5rem 0.5rem 1rem',
              }}
            >
              {item.icon}
              {sidebarFull ? <SidebarLabel>{item.title}</SidebarLabel> : <></>}
            </DropdownLink>
          ))}
      </>
    );
  }
  return (
    <>
      <DropdownLink
        onClick={item.subsubmenu && showSubnav}
        to={item.path}
        key={index}
        activeClassName="activeSidebar"
        style={{ paddingLeft: '42px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <div>{item.icon}</div>
            <div>{sidebarFull ? <SidebarLabelSub>{item.title}</SidebarLabelSub> : <></>}</div>
          </div>
          <div className="pl-2">
            {item.subsubmenu && subnav ? item.iconOpened : item.subsubmenu ? item.iconClosed : null}
          </div>
        </div>
      </DropdownLink>
    </>
  );
};

const SubMenu = ({ item, sidebarFull }) => {
  const [subnav, setSubnav] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem('user')) || {};
  const menu = localStorage.getItem('menu') !== null ? localStorage.getItem('menu') : 'INVENTORY';

  const showSubnav = (e) => {
    setSubnav(!subnav);
    if (!item.path) {
      e.preventDefault();
    }
  };

  return (
    <>
      <SidebarLink
        exact
        to={item.path ? item.path : ''}
        onClick={item.subNav && showSubnav}
        activeClassName="activeSidebar"
      >
        <div>
          {item.icon}
          {sidebarFull ? <SidebarLabel>{item.title}</SidebarLabel> : <></>}
        </div>
        <div>{item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}</div>
      </SidebarLink>
      {item.subNav !== undefined &&
        subnav &&
        item.subNav.map((item, index) => (
          <SubNav item={item} key={index} sidebarFull={sidebarFull} />
        ))}
      {(userProfile.company === 'Cognitiva' || userProfile.company === 'Wiibiq') &&
      menu === 'INDUSTRIAL' &&
      item.title === 'Indicators' ? (
        <SidebarLink exact to="/industrial/documents" activeClassName="activeSidebar">
          <div>
            <FaIcons.FaUserFriends />
            {sidebarFull ? <SidebarLabel>Documents</SidebarLabel> : <></>}
          </div>
        </SidebarLink>
      ) : null}
    </>
  );
};

export default SubMenu;
