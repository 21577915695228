import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';
import CustomSelectDefault from '../FormComponents/CustomSelect/CustomSelectDefault';

const Company = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});

  const costTypeValidate = Yup.object({
    companyName: Yup.string().required('Required'),
    phone_number: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    website: Yup.string().required('Required'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={costTypeValidate}
        onSubmit={async (data) => {
          const processedValues = {
            name: data.companyName,
            phone_number: data.phone_number,
            email: data.email,
            website: data.website,
            payment_notification: data.paymentNotification,
          };
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          if (data.id == null) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Company',
              code: data.companyName,
            });
            if (created.statusText !== 'Created') {
              notification('Error creating item - duplicate item', 'error');
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Company',
              code: data.companyName,
            });
            if (updated.statusText == 'OK') {
              onEdited(processedValues);
              setAddForm(false);
              notification('Item updated successfully', 'success');
            } else {
              notification('Error updating item - duplicate item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.id,
          companyName: values.name || '',
          phone_number: values.phone_number || '',
          email: values.email || '',
          website: values.website || '',
        }}
      >
        {({ values, touched, handleBlur, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Company name"
                name="companyName"
                type="text"
                value={values.companyName}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Phone Number"
                name="phone_number"
                type="text"
                value={values.phone_number}
              />
            </Form.Row>
            <Form.Row>
              <TextField label="email" name="email" type="text" value={values.email} />
            </Form.Row>
            <Form.Row>
              <TextField label="Website" name="website" type="text" value={values.website} />
            </Form.Row>
            <div className="p-0 m-0">
              <CustomSelectDefault
                onChange={(option) => {
                  setFieldValue('paymentNotification', option ? option.value : '');
                }}
                name="paymentNotification"
                label="Payment Notification"
                options={[
                  { value: 'no', label: 'No' },
                  { value: 'yes', label: 'Yes' },
                ]}
                showIcon={false}
              />
            </div>
            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Company;
