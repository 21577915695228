const apiBaseURL = process.env.REACT_APP_API_URL;
const regularExp = /^.+?[^\/:](?=[?\/]|$)/;

const Urls = {
  baseUrl: `${apiBaseURL}`,
  baseFile: regularExp.exec(apiBaseURL),
  item: `${apiBaseURL}/inventory/item`,
  batch: `${apiBaseURL}/inventory/batch`,
  storageCenter: `${apiBaseURL}/inventory/storage_center`,
  storageLocation: `${apiBaseURL}/inventory/storage_location`,
  entryOrder: `${apiBaseURL}/inventory/entry_order`,
  dispatchOrder: `${apiBaseURL}/inventory/dispatch_order`,
  dimensionalUnit: `${apiBaseURL}/inventory/dimensional_unit`,
  adjustment: `${apiBaseURL}/inventory/adjustment`,
  transaction: `${apiBaseURL}/inventory/transaction`,
  itemBatchRelation: `${apiBaseURL}/inventory/item_batch_relation`,
  shelfLife: `${apiBaseURL}/inventory/shelf_life`,
  itemStorageRelation: `${apiBaseURL}/inventory/item_storagecenter_relation`,
  itemCategory: `${apiBaseURL}/inventory/item_category`,
  supplier: `${apiBaseURL}/inventory/supplier`,
  customer: `${apiBaseURL}/inventory/customer`,
  cost: `${apiBaseURL}/inventory/cost`,
  costType: `${apiBaseURL}/inventory/cost_type`,
  label: `${apiBaseURL}/inventory/label`,
  stockPerStorageCenter: `${apiBaseURL}/inventory/stock_per_storage_center`,
  stockPerBatch: `${apiBaseURL}/inventory/stock_per_batch`,
  currentStockPerStorageCenter: `${apiBaseURL}/inventory/current_stock_per_storage_center`,
  currentStockPerBatch: `${apiBaseURL}/inventory/current_stock_per_batch`,
  createQR: `${apiBaseURL}/qr/create`,
  refresh: `${apiBaseURL}/auth/token/refresh/`,
  logout: `${apiBaseURL}/auth/logout/`,
  login: `${apiBaseURL}/auth/login/`,
  upload: `${apiBaseURL}/inventory/files`,
  compute: `${apiBaseURL}/inventory/compute`,

  //INDUSTRIAL URLS
  createPartialReport: `${apiBaseURL}/industrial/create_partial_report`,
  operationCenter: `${apiBaseURL}/industrial/operation_center`,
  dimensionalUnitA: `${apiBaseURL}/industrial/dimensional_unit`,
  unitTransformation: `${apiBaseURL}/industrial/unit_transformation`,
  product: `${apiBaseURL}/industrial/product`,
  productCategory: `${apiBaseURL}/industrial/product_category`,
  workCenter: `${apiBaseURL}/industrial/work_center`,
  workCenterCategory: `${apiBaseURL}/industrial/work_center_category`,
  workCenterCapacity: `${apiBaseURL}/industrial/work_center_capacity`,
  variable: `${apiBaseURL}/industrial/variable`,
  qualityDimension: `${apiBaseURL}/industrial/quality_dimension`,
  productionBatch: `${apiBaseURL}/industrial/production_batch`,
  endOrderAtAnyPoint: `${apiBaseURL}/industrial/end_production_order_at_any_point`,

  // shift control urls
  shift: `${apiBaseURL}/industrial/shift`,
  shiftAssignment: `${apiBaseURL}/industrial/shift_assignment`,

  // manual reports urls
  manualTable: `${apiBaseURL}/industrial/manual_table`,
  manualQuantities: `${apiBaseURL}/industrial/register_manual_quantities`,
  manualReport: `${apiBaseURL}/industrial/manual_report`,

  // production order control urls
  productionOrder: `${apiBaseURL}/industrial/production_order`,
  productionOrderWithList: `${apiBaseURL}/industrial/production_order_with_product_list`,
  simplifiedProductionOrder: `${apiBaseURL}/industrial/simplified_production_order`,
  productPerProductionOrders: `${apiBaseURL}/industrial/product_per_production_order`,
  quantityRegister: `${apiBaseURL}/industrial/quantity_register`,

  // process control urls
  startProductionOrder: `${apiBaseURL}/industrial/start_product_per_production_order`,
  pauseProductionOrder: `${apiBaseURL}/industrial/pause_product_per_production_order`,
  resumeProductionOrder: `${apiBaseURL}/industrial/resume_product_per_production_order`,
  endProductionOrder: `${apiBaseURL}/industrial/end_product_per_production_order`,

  // event control urls
  event: `${apiBaseURL}/industrial/event`,
  eventType: `${apiBaseURL}/industrial/event_type`,
  eventQuantities: `${apiBaseURL}/industrial/quantity_register`,
  eventCategory: `${apiBaseURL}/industrial/event_category`,
  startEvent: `${apiBaseURL}/industrial/start_event`,
  endEvent: `${apiBaseURL}/industrial/finish_event`,
  startEventWithoutOrder: `${apiBaseURL}/industrial/start_event_without_order`,
  endEventWithoutOrder: `${apiBaseURL}/industrial/finish_event_without_order`,

  // process control urls
  process: `${apiBaseURL}/industrial/process`,
  route: `${apiBaseURL}/industrial/process_route`,
  workCenterPerProcess: `${apiBaseURL}/industrial/workcenter_per_process`,
  qualityReport: `${apiBaseURL}/industrial/quality`,
  generateRoute: `${apiBaseURL}/industrial/generate_product_route`,

  // iot urls
  iotProcessControl: `${apiBaseURL}/industrial/iot_table`,
  producedUnitsInTameRange: `${apiBaseURL}/industrial/produced_units_in_time_range`,
  timeSeriesIot: `${apiBaseURL}/industrial/timeseries_iot`,
  iotQuality: `${apiBaseURL}/industrial/quality_produced_units_in_time_range`,

  //industrial indicators
  timeAnalysis: `${apiBaseURL}/industrial/time_analysis`,
  productionOrderByWorkCenter: `${apiBaseURL}/industrial/production_order_by_work_center`,
  producedUnits: `${apiBaseURL}/industrial/produced_units`,
  availability: `${apiBaseURL}/industrial/availability`,
  workCenterPerProcessFilter: `${apiBaseURL}/industrial/work_center_per_process_filter`,
  quality: `${apiBaseURL}/industrial/oee_quality`,
  performance: `${apiBaseURL}/industrial/performance`,
  timeAccomplishment: `${apiBaseURL}/industrial/accomplishment_in_time`,
  oeeTrend: `${apiBaseURL}/industrial/oee_trend`,
  accomplishmentByQuantity: `${apiBaseURL}/industrial/accomplishment_by_quantity`,
  orderTraceability: `${apiBaseURL}/industrial/history_report_order`,

  // CELERY
  celery: `${apiBaseURL}/industrial/celery-progress`,

  // AUTH URLS
  company: `${apiBaseURL}/auth/company`,
  user: `${apiBaseURL}/auth/user`,
  module: `${apiBaseURL}/auth/module`,
  company_module: `${apiBaseURL}/auth/company_module`,
  change_password: `${apiBaseURL}/auth/change-password/`,
  group_permission: `${apiBaseURL}/auth/group_permission`,
  usergroup_permission: `${apiBaseURL}/auth/usergroup_permission`,
  permissions: `${apiBaseURL}/auth/permissions`,
  resend_mail: `${apiBaseURL}/auth/resend-registermail/`,
  reset_password: `${apiBaseURL}/auth/request-reset-email/`,
  password_reset_complete: `${apiBaseURL}/auth/password-reset-complete/`,
};

export default Urls;
