import React from 'react';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import FiltersAvailability from '../FiltersAvailability';
import AvailabilityChart from '../IndustrialGraph/AvailabilityChart';
import { useSelector } from 'react-redux';
import { ProgressBarWithText } from '../../Loading';
import { Row } from 'react-bootstrap';

const Availability = () => {
  const indicatorName = 'Availability';
  const progress = useSelector((state) => state.availability.progress);
  const queryStatus = useSelector((state) => state.availability.status);
  return (
    <>
      <HeaderTitle title={indicatorName} />
      <FiltersAvailability />
      {queryStatus === 'PROGRESS' ? (
        <Row className="justify-content-center" style={{ 'margin-top': '100px' }}>
          <ProgressBarWithText
            progress={progress.current}
            label={`${progress.current}`}
            text={"We're working on your request"}
          />
        </Row>
      ) : (
        <div className="pr-4 py-3">
          <AvailabilityChart indicatorName={indicatorName} />
        </div>
      )}
    </>
  );
};

export default Availability;
