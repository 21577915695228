import { Button, Modal } from 'react-bootstrap';

const NotesModal = ({ show, handleClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Note example</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>Fields marked with an asterisk (*) must be filled.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotesModal;
