import { React, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AccountMenu from './components/AccountMenu/AccountMenu';
import Login from './components/Login/Login';
import Sidebar from './components/Sidebar/Sidebar';
import TableSection from './components/TableSection/TableSection';
import IndustrialTableSection from './pages/industrial/TableSection';
import SidebarModules from './components/SidebarModules/SidebarModules';
import Profile from './components/Profile/Profile';
import InventoryHome from './components/Home/InventoryHome';
import IndustrialHome from './components/Home/IndustrialHome';
import RegisteredTransactions from './components/Transactions/RegisteredTransactions';
import AssociateOrders from './components/Transactions/AssociateOrders/AssociateOrders';
import TransactionSection from './components/Transactions/TransactionSection/TransactionSection';
import QRSection from './components/QRSection/QRSection';
import useLocalStorage from './components/useLocalStorage';
import IndicatorsSection from './components/Indicators/IndicatorsSection';
import RealtimeDashboard from './components/Indicators/RealtimeDashboard';
import UploadFilesTable from './components/UploadFilesTable/UploadFilesTable';
import TemplatesView from './components/TemplatesView/TemplatesView';
import IndustrialTemplatesView from './components/TemplatesView/IndustrialTemplatesView';
import AproveReturn from './components/Transactions/AproveReturn';
import AdvancedFilters from './components/Indicators/AdvancedFilters';
import DataAnalysis from './components/Indicators/Analysis/DataAnalysis';
import ShelfLife from './components/TableSection/ShelfLifeTableSection';
import OrderTraceability from './components/Indicators/Reports/OrderTraceability';
import Urls from './components/Urls';
import ProductionOrderSection from './pages/industrial/ProductionOrderSection';
import ProcessControlSection from './pages/industrial/ProcessControlSection';
import ShiftAssignment from './pages/industrial/ShiftAssignmentSection';
import DocSection from './pages/industrial/DocSection';
import axiosInterceptor from './components/axiosInterceptor'; // eslint-disable-line
import ManualReportTableSection from './components/TableSection/ManualReportTableSection';
import QualityReportTableSection from './components/TableSection/QualityReportTableSection';
import EventReportTableSection from './components/TableSection/EventReportTableSection';
import ProducedUnits from './components/Indicators/IndustrialIndicatorSection/ProducedUnits';
import ResetPasswordByEmail from './components/Forms/ResetPasswordByEmail';
import ChangePasswordByEmail from './components/Forms/ChangePasswordByEmail';
import ChangePassword from './components/Forms/ChangePassword';
import {
  routesList,
  operationalTables,
  indicatorTables,
  transactionRoutes,
  uploadMainTables,
  uploadOperationalTables,
  industrialMainTables,
  industrialOperationalTables,
  processSection,
  productionOrderSection,
  shiftAssignment,
  industrialUploadMainTables,
  industrialUploadOperationalTables,
  auth,
  accomplishment,
} from './utils/routesInfo';
import './App.css';
import IndustrialUploadFilesTable from './components/UploadFilesTable/IndustrialUploadFilesTable';
import TimeAnalysis from './components/Indicators/IndustrialIndicatorSection/TimeAnalysis';
import IotProcessControl from './components/Indicators/IndustrialIndicatorSection/IotProcessControl';
import TimeAccomplishment from './components/Indicators/IndustrialIndicatorSection/TimeAccomplishment';
import Availability from './components/Indicators/IndustrialIndicatorSection/Availability';
import ManualProcessControl from './components/Indicators/IndustrialIndicatorSection/ManualProcessControl';
import OEEQuality from './components/Indicators/IndustrialIndicatorSection/OEEQuality';
import OEEControl from './components/Indicators/IndustrialIndicatorSection/OEEControl';
import Performance from './components/Indicators/IndustrialIndicatorSection/Performance';
import AccomplishmentByQuantity from './components/Indicators/IndustrialIndicatorSection/AccomplishmentByQuantity';
import PaymentNotificationFooter from './components/CoreComponents/Footer/PaymentNotificationFooter';
import ProtectedRoute from './components/CoreComponents/Router/ProtectedRoute';

function App() {
  const userInfo = useSelector((state) => state.user);
  const companyModule = userInfo?.companyModule?.toLowerCase() || 'inventory';
  const queryClient = new QueryClient();
  const [accessToken] = useLocalStorage('accessToken', localStorage.getItem('accessToken'));

  const [logged, setLogged] = useState(!!accessToken);
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});

  const handleLogged = (user) => {
    setLogged(true);
    setUserProfile(user);
  };

  const styles = {
    container: {
      minHeight: '100vh',
      flex: '2 0 0',
      float: 'none',
      overflow: 'hidden',
      marginLeft: logged ? '40px' : 0,
      marginRight: logged ? '40px' : 0,
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div data-testid="App" className="d-flex mainComponent justify-items-between">
        {logged && (
          <>
            <div className="sidebar-module">
              <SidebarModules />
            </div>
            <div>
              <Sidebar isLogged={logged} />
            </div>
          </>
        )}
        <div className="d-flex flex-column" style={styles.container}>
          {logged && <AccountMenu username={userProfile.username} company={userProfile.company} />}
          <Switch>
            <Route exact path="/">
              {logged && userInfo.defaultModule !== 'All' ? (
                <Redirect to={`${userInfo.defaultModule.toLowerCase()}/home`} />
              ) : logged && userInfo.defaultModule === 'All' ? (
                <Redirect to={`${companyModule}/home`} />
              ) : (
                <Login setLogged={handleLogged} />
              )}
            </Route>
            <Route path="/inventory/home">
              <InventoryHome />
            </Route>
            {/* //principal tables */}
            {routesList.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Inventory">
                  <TableSection
                    url={item.url}
                    formName={item.formName}
                    title={item.title}
                    columnToTransform={item.columnToTransform}
                    path={item.path}
                  />
                </ProtectedRoute>
              </Route>
            ))}
            {/* //operational tables */}
            {operationalTables.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Inventory">
                  <TableSection
                    url={item.url}
                    formName={item.formName}
                    title={item.title}
                    columnToTransform={item.columnToTransform}
                  />
                </ProtectedRoute>
              </Route>
            ))}
            {/* //indicators (tables) */}
            {indicatorTables.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Inventory">
                  <TableSection
                    disableAdd
                    url={item.url}
                    formName={item.formName}
                    title={item.title}
                    columnToTransform={item.columnToTransform}
                  />
                </ProtectedRoute>
              </Route>
            ))}
            {/* //auth tables */}
            {auth.map((item, index) => (
              <Route path={item.path} key={index}>
                <TableSection
                  url={item.url}
                  formName={item.formName}
                  title={item.title}
                  columnToTransform={item.columnToTransform}
                  path={item.path}
                />
              </Route>
            ))}
            <Route path="/transactions/registered">
              <ProtectedRoute module="Inventory">
                <RegisteredTransactions />
              </ProtectedRoute>
            </Route>
            <Route path="/transactions/return">
              <ProtectedRoute module="Inventory">
                <AproveReturn />
              </ProtectedRoute>
            </Route>
            {transactionRoutes.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Inventory">
                  <TransactionSection title={item.title} transactionType={item.transactionType} />
                </ProtectedRoute>
              </Route>
            ))}
            <Route path="/transactions/associate_orders">
              <ProtectedRoute module="Inventory">
                <AssociateOrders />
              </ProtectedRoute>
            </Route>
            <Route path="/profile">
              <Profile user={userProfile} />
            </Route>
            <Route path="/reset_password">
              <ResetPasswordByEmail />
            </Route>
            <Route path="/change_passwordbyemail">
              <ChangePasswordByEmail />
            </Route>
            <Route path="/qr">
              <ProtectedRoute module="Inventory">
                <QRSection />
              </ProtectedRoute>
            </Route>
            <Route path="/indicators/dashboard">
              <ProtectedRoute module="Inventory">
                <IndicatorsSection />
              </ProtectedRoute>
            </Route>
            <Route path="/indicators/real_time_dashboard">
              <ProtectedRoute module="Inventory">
                <RealtimeDashboard />
              </ProtectedRoute>
            </Route>
            <Route path="/indicators/advanced_filters">
              <ProtectedRoute module="Inventory">
                <AdvancedFilters />
              </ProtectedRoute>
            </Route>
            <Route path="/indicators/data_analysis">
              <ProtectedRoute module="Inventory">
                <DataAnalysis />
              </ProtectedRoute>
            </Route>
            <Route path="/indicators/shelf_life">
              <ProtectedRoute module="Inventory">
                <ShelfLife />
              </ProtectedRoute>
            </Route>
            <Route path="/files">
              <ProtectedRoute module="Inventory">
                <UploadFilesTable />
              </ProtectedRoute>
            </Route>
            <Route path="/changePassword">
              <ChangePassword />
            </Route>
            {uploadMainTables.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Inventory">
                  <TemplatesView title={item.title} />
                </ProtectedRoute>
              </Route>
            ))}
            {uploadOperationalTables.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Inventory">
                  <TemplatesView title={item.title} />
                </ProtectedRoute>
              </Route>
            ))}
            {/* INDUSTRIAL */}
            <Route path={'/industrial/home'}>
              <IndustrialHome />
            </Route>
            <Route path="/industrial/files">
              <ProtectedRoute module="Industrial">
                <IndustrialUploadFilesTable />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/documents">
              <ProtectedRoute module="Industrial">
                <DocSection />
              </ProtectedRoute>
            </Route>
            {industrialUploadMainTables.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Industrial">
                  <IndustrialTemplatesView title={item.title} />
                </ProtectedRoute>
              </Route>
            ))}
            {industrialUploadOperationalTables.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Industrial">
                  <IndustrialTemplatesView title={item.title} />
                </ProtectedRoute>
              </Route>
            ))}
            {/* //principal tables */}
            {industrialMainTables.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Industrial">
                  <IndustrialTableSection
                    url={item.url}
                    formName={item.formName}
                    title={item.title}
                    path={item.path}
                  />
                </ProtectedRoute>
              </Route>
            ))}
            <Route path="/industrial/manual_report">
              <ProtectedRoute module="Industrial">
                <ManualReportTableSection />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/event_report">
              <ProtectedRoute module="Industrial">
                <EventReportTableSection />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/quality_report">
              <ProtectedRoute module="Industrial">
                <QualityReportTableSection />
              </ProtectedRoute>
            </Route>
            {/* //operational tables */}
            {industrialOperationalTables.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Industrial">
                  <IndustrialTableSection
                    url={item.url}
                    formName={item.formName}
                    title={item.title}
                    path={item.path}
                    disableAdd={item.disableAdd}
                  />
                </ProtectedRoute>
              </Route>
            ))}
            {productionOrderSection.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Industrial">
                  <ProductionOrderSection
                    url={item.url}
                    formName={item.formName}
                    title={item.title}
                    path={item.path}
                  />
                </ProtectedRoute>
              </Route>
            ))}
            {processSection.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Industrial">
                  <ProcessControlSection
                    url={item.url}
                    formName={item.formName}
                    title={item.title}
                    path={item.path}
                  />
                </ProtectedRoute>
              </Route>
            ))}
            {shiftAssignment.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Industrial">
                  <ShiftAssignment
                    url={item.url}
                    formName={item.formName}
                    title={item.title}
                    path={item.path}
                  />
                </ProtectedRoute>
              </Route>
            ))}
            {/* Industrial Indicators */}
            <Route path="/industrial/time_analysis">
              <ProtectedRoute module="Industrial">
                <TimeAnalysis />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/iot_process_control">
              <ProtectedRoute module="Industrial">
                <IotProcessControl />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/produced_units">
              <ProtectedRoute module="Industrial">
                <ProducedUnits />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/availability">
              <ProtectedRoute module="Industrial">
                <Availability />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/performance">
              <ProtectedRoute module="Industrial">
                <Performance />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/oee_quality">
              <ProtectedRoute module="Industrial">
                <OEEQuality />
              </ProtectedRoute>
            </Route>
            {accomplishment.map((item, index) => (
              <Route path={item.path} key={index}>
                <ProtectedRoute module="Industrial">
                  <TimeAccomplishment
                    url={item.url}
                    formName={item.formName}
                    title={item.title}
                    path={item.path}
                  />
                </ProtectedRoute>
              </Route>
            ))}
            <Route path="/industrial/oee_control">
              <ProtectedRoute module="Industrial">
                <OEEControl />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/manual_process_control">
              <ProtectedRoute module="Industrial">
                <ManualProcessControl />
              </ProtectedRoute>
            </Route>
            <Route path="/industrial/order_traceability">
              <ProtectedRoute module="Industrial">
                <OrderTraceability />
              </ProtectedRoute>
            </Route>
          </Switch>
        </div>
      </div>
      {userInfo.paymentNotification === 'yes' ? <PaymentNotificationFooter /> : null}
    </QueryClientProvider>
  );
}

export default App;
