import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import TableModal from '../TableModal/TableModal';
import apiService from '../apiService';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import Urls from '../Urls';
import { setIsModalPaginationAction } from '../../redux/actions/paginationAction';
import { notification } from '../AlertMessage/ToastifyAlert';

const ItemBatchRelation = ({
  onAdded,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  values,
  onEdited,
}) => {
  const [valuesToEdit, setValuesToEdit] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [currentSerialNum, setCurrentSerialNum] = useState(localStorage.getItem('serialNum') || 1);

  const [showBatchModal, setShowBatchModal] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [showCostTypeModal, setShowCostTypeModal] = useState(false);
  const [itemSelected, setItemSelected] = useState();
  const [batchCodeTransformed, setBatchCodeTransformed] = useState();
  const [itemCodeTransformed, setItemCodeTransformed] = useState();
  const [uniqueUnitCostCodeTransformed, setUniqueUnitCostCodeTransformed] = useState();
  const [uniqueUnitCostCodeDisabled, setUniqueUnitCostCodeDisabled] = useState(true);
  const [uniqueUnitCostCodeFromModal, setUniqueUnitCostCodeFromModal] = useState(false);
  const [uniqueUnitCostCodeDefaultValues, setUniqueUnitCostCodeDefaultValues] = useState({});
  const [itemReferenceFromModal, setItemReferenceFromModal] = useState(false);
  const [itemReferenceDefaultValues, setItemReferenceDefaultValues] = useState({});
  const [batchCodeFromModal, setBatchCodeFromModal] = useState(false);
  const [batchCodeDefaultValues, setBatchCodeDefaultValues] = useState({});
  const [descriptions, setDescriptions] = useState({ itemDescription: '' });
  const [itemData, setItemData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [batchData, setBatchData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [costTypeData, setCostTypeData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const itemBatchRelationValidate = Yup.object({
    itemReferenceCode: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    batchCode: Yup.string().required('Required'),
    uniqueUnitCostCode: Yup.string().required('Required'),
  });

  //pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };

  const itemColumnToTransform = [
    {
      name: 'item_category',
      url: 'itemCategory',
      field: 'item_category_code',
    },
    {
      name: 'label',
      url: 'label',
      field: 'label_code',
    },
    {
      name: 'consolidation_level_N0',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N1',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N2',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N3',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N4',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
  ];

  const costTypeColumnToTransform = [
    {
      name: 'unique_cost_type_code',
      url: 'costType',
      field: 'unique_cost_type_code',
    },
    {
      name: 'item_reference_code',
      url: 'item',
      field: 'reference_code',
    },
    {
      name: 'dimensional_unit_code',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
  ];

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
        item_reference_code: itemCodeTransformed,
        batch_code: batchCodeTransformed,
        unique_unit_cost_code: uniqueUnitCostCodeTransformed,
      });
    }
  }, [added]);

  useEffect(async () => {
    if (itemSelected !== undefined) {
      const resCostType = await apiService.getActiveCosts(itemSelected);
      const filteredCost2 = resCostType.data.results.map((el) => ({
        value: el.unique_unit_cost_code,
        label: el.unique_unit_cost_code,
        id: el.id,
      }));
      setCostTypeData({
        headers: resCostType.headersList,
        tableData: resCostType.data,
        options: filteredCost2,
      });
    }
  }, [itemSelected]);

  useEffect(async () => {
    const resItem = await apiService.getData(Urls.item, 'reference_code');
    const resBatch = await apiService.getData(Urls.batch, 'batch_code');

    setItemData({
      headers: resItem.headersList,
      tableData: resItem.data,
      options: resItem.results,
    });

    setBatchData({
      headers: resBatch.headersList,
      tableData: resBatch.data,
      options: resBatch.results,
    });
  }, []);

  const handleCloseItemModal = () => {
    setIsModalPagination();
    setShowItemModal(false);
  };
  const handleCloseBatchModal = () => {
    setIsModalPagination();
    setShowBatchModal(false);
  };
  const handleCloseCostTypeModal = () => {
    setIsModalPagination();
    setShowCostTypeModal(false);
  };

  const createCode = (currentNumber) => {
    const zerosNumber = 9 - currentNumber.toString().length;
    const code = `IBC-${'0'.repeat(zerosNumber)}${currentNumber}`;
    return code;
  };

  useEffect(() => {
    itemData.options.forEach((option) => {
      if (option.value === values.item_reference_code) {
        setDescriptions(
          option
            ? { ...descriptions, itemDescription: option.description }
            : { ...descriptions, itemDescription: '' }
        );
      }
    });
  }, [itemData]);

  const handleItemReferenceConfirm = (selected, title, setFieldValue) => {
    setItemReferenceDefaultValues({
      value: selected.id,
      label: selected.reference_code,
      description: selected.item_description,
    });
    setValuesToEdit({
      ...valuesToEdit,
      item_reference_code: selected.id,
    });
    setItemSelected(selected.id);
    setFieldValue('itemReferenceCode', selected.id);
    setItemCodeTransformed(selected.reference_code);
    setUniqueUnitCostCodeDisabled(false);
    setShowItemModal(false);
    setItemReferenceFromModal(true);
  };

  const handleBatchCodeConfirm = (selected, title, setFieldValue) => {
    setBatchCodeDefaultValues({
      value: selected.id,
      label: selected.batch_code,
    });
    setValuesToEdit({
      ...valuesToEdit,
      batch_code: selected.id,
    });
    setFieldValue('batchCode', selected.id);
    setBatchCodeTransformed(selected.batch_code);
    setShowBatchModal(false);
    setBatchCodeFromModal(true);
  };

  const handleUnitCostCodeConfirm = (selected, title, setFieldValue) => {
    setUniqueUnitCostCodeDefaultValues({
      value: selected.id,
      label: selected.unique_unit_cost_code,
    });
    setValuesToEdit({
      ...valuesToEdit,
      unique_unit_cost_code: selected.unique_unit_cost_code,
    });
    setFieldValue('uniqueUnitCostCode', selected.unique_unit_cost_code);
    setUniqueUnitCostCodeTransformed(selected.unique_unit_cost_code);
    setShowCostTypeModal(false);
    setUniqueUnitCostCodeFromModal(true);
  };

  return (
    <Formik
      validateOnChange
      validationSchema={itemBatchRelationValidate}
      onSubmit={async (data) => {
        const processedValues = {
          item_batch_relation_code: null,
          item_reference_code: data.itemReferenceCode,
          batch_code: data.batchCode,
          unique_unit_cost_code: data.uniqueUnitCostCode,
        };
        setProcessedFormValues(processedValues);
        if (data.id == null) {
          const created = await handleAPIRequest(processedValues, {
            entity: 'Item batch relation',
            code: data.itemBatchRelationCode,
          });
          if (created.statusText !== 'Created') {
            notification('Error creating item - duplicate item', 'error');
          }
        } else {
          const editedValues = {
            item_batch_relation_code: null,
            item_reference_code: itemCodeTransformed || data.itemReferenceCode,
            batch_code: batchCodeTransformed || data.batchCode,
            unique_unit_cost_code: uniqueUnitCostCodeTransformed || data.uniqueUnitCostCode,
          };
          const updated = await handleAPIRequestUpdate(data.id, valuesToEdit, {
            entity: 'Item batch relation',
            code: data.itemBatchRelationCode,
          });
          if (updated.statusText == 'OK') {
            onEdited(editedValues);
            setAddForm(false);
            notification('Item updated successfully', 'success');
          } else {
            notification('Error updating item - duplicate item', 'error');
          }
        }
      }}
      initialValues={{
        id: values.id,
        itemBatchRelationCode: values.item_batch_relation_code || '',
        itemReferenceCode: values.item_reference_code || '',
        batchCode: values.batch_code || '',
        uniqueUnitCostCode: values.unique_unit_cost_code || '',
      }}
    >
      {({ setFieldValue, values, handleSubmit }) => (
        <>
          {showItemModal ? (
            <TableModal
              url={Urls.item}
              title="Item Reference Code"
              show={showItemModal}
              handleClose={handleCloseItemModal}
              formName={'item'}
              columnToTransform={itemColumnToTransform}
              handleConfirmation={handleItemReferenceConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showBatchModal ? (
            <TableModal
              url={Urls.batch}
              title="Batch Code"
              show={showBatchModal}
              handleClose={handleCloseBatchModal}
              formName={'batch'}
              columnToTransform={[]}
              handleConfirmation={handleBatchCodeConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showCostTypeModal ? (
            <TableModal
              key={JSON.stringify(itemSelected)}
              url={Urls.cost}
              customFilter={itemSelected ? `item_reference_code__id=${itemSelected}` : ''}
              title="Unique Unit Cost Code"
              show={showCostTypeModal}
              handleClose={handleCloseCostTypeModal}
              formName={'cost'}
              columnToTransform={costTypeColumnToTransform}
              setFieldValue={setFieldValue}
              handleConfirmation={handleUnitCostCodeConfirm}
            />
          ) : null}
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              {itemReferenceFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      item_reference_code: option ? option.id : '',
                    });
                    setItemSelected(option ? option.id : '');
                    setFieldValue('itemReferenceCode', option ? option.id : '');
                    setItemCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, itemDescription: option.description }
                        : { itemDescription: '' }
                    );
                    setUniqueUnitCostCodeDisabled(option ? false : true);
                    setItemReferenceFromModal(false);
                  }}
                  name="itemReferenceCode"
                  label="Item Reference Code"
                  options={itemData.options}
                  setShow={setShowItemModal}
                  defaultValue={itemReferenceDefaultValues}
                  value={itemReferenceDefaultValues}
                  description={itemReferenceDefaultValues.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      item_reference_code: option ? option.id : '',
                    });
                    setItemSelected(option ? option.id : '');
                    setFieldValue('itemReferenceCode', option ? option.id : '');
                    setItemCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, itemDescription: option.description }
                        : { itemDescription: '' }
                    );
                    setUniqueUnitCostCodeDisabled(option ? false : true);
                    setFieldValue('uniqueUnitCostCode', '');
                  }}
                  name="itemReferenceCode"
                  label="Item Reference Code"
                  options={itemData.options}
                  setShow={setShowItemModal}
                  defaultValue={{
                    value: values.itemReferenceCode,
                    label: values.itemReferenceCode,
                  }}
                  description={descriptions.itemDescription}
                />
              )}
            </Form.Row>
            <Form.Row>
              {batchCodeFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      batch_code: option ? option.id : '',
                    });
                    setFieldValue('batchCode', option ? option.id : '');
                    setBatchCodeTransformed(option ? option.value : '');
                    setBatchCodeFromModal(false);
                  }}
                  name="batchCode"
                  label="Batch Code"
                  options={batchData.options}
                  setShow={setShowBatchModal}
                  defaultValue={batchCodeDefaultValues}
                  value={batchCodeDefaultValues}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      batch_code: option ? option.id : '',
                    });
                    setFieldValue('batchCode', option ? option.id : '');
                    setBatchCodeTransformed(option ? option.value : '');
                  }}
                  name="batchCode"
                  label="Batch Code"
                  options={batchData.options}
                  setShow={setShowBatchModal}
                  defaultValue={{
                    value: values.batchCode,
                    label: values.batchCode,
                  }}
                />
              )}
              {uniqueUnitCostCodeFromModal ? (
                <CustomSelect
                  key={JSON.stringify(itemSelected)}
                  disabled={uniqueUnitCostCodeDisabled}
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      unique_unit_cost_code: option ? option.id : '',
                    });
                    setFieldValue('uniqueUnitCostCode', option ? option.id : '');
                    setUniqueUnitCostCodeTransformed(option ? option.value : '');
                    setUniqueUnitCostCodeFromModal(false);
                  }}
                  name="uniqueUnitCostCode"
                  label="Unique Unit Cost Code"
                  options={costTypeData.options}
                  setShow={setShowCostTypeModal}
                  defaultValue={uniqueUnitCostCodeDefaultValues}
                  value={uniqueUnitCostCodeDefaultValues}
                />
              ) : (
                <CustomSelect
                  key={JSON.stringify(itemSelected)}
                  disabled={uniqueUnitCostCodeDisabled}
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      unique_unit_cost_code: option ? option.id : '',
                    });
                    setFieldValue('uniqueUnitCostCode', option ? option.id : '');
                    setUniqueUnitCostCodeTransformed(option ? option.value : '');
                  }}
                  name="uniqueUnitCostCode"
                  label="Unique Unit Cost Code"
                  options={costTypeData.options}
                  setShow={setShowCostTypeModal}
                  defaultValue={{
                    value: values.uniqueUnitCostCode,
                    label: values.uniqueUnitCostCode,
                  }}
                />
              )}
            </Form.Row>
            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ItemBatchRelation;
