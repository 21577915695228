import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../../components/Forms/FormContainer';
import FPBTable from '../../components/FPBTable/FPBTableIndustrial';
import ActionConfirmation from '../../components/ActionConfirmation/ActionConfirmation';
import HeaderTitle from '../../components/CoreComponents/PageTitle/PageTitle';
import useForm from '../../components/useForm';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import { setTotalCountAction } from '../../redux/actions/paginationAction';
import apiService from '../../components/apiService';
import Urls from '../../components/Urls';
import { notification } from '../../components/AlertMessage/ToastifyAlert';
//Calendar
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment-timezone';

const ShiftAssignment = ({ url, formName, title, disableAdd }) => {
  const [addReload, setAddReload] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [formType, setFormType] = useState('');
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteRows, setDeleteRows] = useState();
  const [deleteChange, setDeleteChange] = useState(false);
  const [addedRow, setAddedRow] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageUpdate, setErrorMessageUpdate] = useState('');
  const [columnsToHide, setColumnsToHide] = useState();
  const [urlPost, setUrlPost] = useState('');
  const industrialClient = apiService.industrialClient();
  // pagination
  const [filters, setFilters] = useState([]);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  //resources
  const [resourceMap, setResourceMap] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [calendar, setCalendar] = useState([]);
  const [shifts, setShifts] = useState([]);

  const dispatch = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatch(setTotalCountAction(totalCount));
  };

  moment.tz.setDefault('America/Bogota');
  const localizer = momentLocalizer(moment);

  const { views } = useMemo(
    () => ({
      views: ['month', 'week', 'day', 'agenda'],
    }),
    []
  );

  useEffect(async () => {
    try {
      const resWorkCenter = await apiService.getData(Urls.workCenter, 'code');
      setResourceMap({
        headers: resWorkCenter.headersList,
        tableData: await resWorkCenter.data.map((obj) => {
          obj['resourceId'] = obj['code'];
          delete obj['code'];
          return obj;
        }),
        options: resWorkCenter.results,
      });
      const resCalendar = await apiService.getData(Urls.shiftAssignment, 'id');
      setCalendar(await resCalendar.data);
    } catch (err) {
      console.log(err);
    }
  }, [addReload]);

  useEffect(() => {
    const data = calendar.map((res) => {
      let startDate = res.date + 'T' + res.start_hour;
      let endDate = res.date + 'T' + res.end_hour;
      res.start_date = new Date(startDate);
      res.end_date = new Date(endDate);
      res.title = res.shift + ' [' + res.work_center + ']';
      res.resourceId = res.work_center;
    });
    setShifts(data);
  }, [calendar]);

  useEffect(() => {
    setColumnsToHide(['created_by', 'created_at', 'updated_by', 'updated_at']);
  }, []);

  const {
    handleChange,
    values,
    handleAPIRequest,
    handleAPIRequestUpdate,
    handleAPIDelete,
    added,
    edited,
  } = useForm(
    urlPost,
    setAddForm,
    setAlertMessage,
    setErrorMessage,
    setErrorMessageUpdate,
    errorMessageUpdate
  );

  const onAdded = (r) => {
    setAddForm(false);
    setAddedRow(r);
    setAddReload(!addReload);
  };

  const handleConfirmation = () => {
    setShow(false);
    setConfirmDelete(true);
  };

  const handleClose = () => setShow(false);

  const handleDelete = async (id) => {
    setShow(true);
    setDeleteRows(id);
  };

  const nullableFields = ['planning_closing_date'];

  // data is the array of modified fields ([{field_name:value},{}...{}])
  const handleEdit = async (id, data) => {
    // get the name of the fields to edit
    const fieldsName = Object.keys(data);
    const editedRow = {};
    const res = await Promise.all(
      fieldsName.map(async (fieldName) => {
        // if the edited field is of type date or integer we send null instead of '' (when deleting field content)
        if (nullableFields.includes(fieldName) && data[fieldName] === '') {
          editedRow[fieldName] = null;
          return editedRow;
        }
        editedRow[fieldName] = data[fieldName];
        return editedRow;
      })
    );
    const dataToUpdate = res[res.length - 1];

    industrialClient
      .patch(`${url}/${id}`, { ...dataToUpdate })
      .then(() => {})
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (confirmDelete === true) {
      industrialClient.delete(`${url}/${deleteRows}`).then(() => {
        setDeleteChange(true);
        setConfirmDelete(false);
        setDeleteChange(false);
      });
    }
  }, [confirmDelete]);

  const getQueryString = () => {
    let filter = filters
      .reduce((acc, { columnName, operation, value }) => {
        switch (operation) {
          case 'contains': {
            acc.push(`${columnName}__icontains=${encodeURIComponent(value)}`);
            break;
          }
          case 'equal': {
            acc.push(`${columnName}__exact=${encodeURIComponent(value)}`);
            break;
          }
          default:
            acc.push(`${columnName}__${operation}=${encodeURIComponent(value)}`);
            break;
        }
        return acc;
      }, [])
      .join('&');

    if (filters.length > 1) {
      filter = `${filter}`;
    }

    const urlT = `${url}?page=${currentPage + 1}&page_size=${pageSize}&${filter}`;
    return urlT;
  };

  useEffect(() => {
    const queryString = getQueryString();
    const getTableData = async () => {
      try {
        const response = await industrialClient.get(queryString);
        const tableData = response.data.results;
        setTotalCount(response.data.count);
        if (tableData.length > 0) {
          tableData.map((res) => {
            let startDate = res.date + 'T' + res.start_hour;
            res.month = new Date(startDate)
              .toLocaleString('en-US', { month: 'long' })
              .toUpperCase();
            res.year = new Date(res.date).getFullYear();
          });
          const headersList = Object.keys(tableData[0]).filter((elem) => elem !== 'id');
          setHeaders(headersList);
          setTableData(tableData);
        } else {
          setTableData([]);
          setHeaders([]);
        }
      } catch (err) {
        console.log('error: ', err);
      }
      setAddForm(false);
    };
    getTableData();
  }, [url, pageSize, currentPage, filters, addReload]);

  useEffect(() => {
    alertMessage !== '' ? notification(alertMessage, 'success') : null;
  }, [alertMessage]);

  useEffect(() => {
    errorMessage !== '' ? notification(errorMessage, 'error') : null;
  }, [errorMessage]);
  return (
    <>
      <HeaderTitle title={title} />
      <ActionConfirmation
        show={show}
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
      />
      <div className="text-left btn-container">
        {disableAdd ? null : (
          <Button
            className="fpb-btn"
            onClick={() => {
              setAddForm(true);
              setFormType('Add');
            }}
            disabled={addForm}
          >
            Add
          </Button>
        )}

        <Button
          className="fpb-btn-inverse ml-2"
          onClick={() => {
            setAddForm(true);
            setFormType('Delete');
          }}
          disabled={addForm}
        >
          Delete
        </Button>

        <Modal show={addForm} onHide={setAddForm} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {formType} {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMessage ? (
              <AlertMessage
                variant="danger"
                message={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            ) : null}
            <FormContainer
              handleChange={handleChange}
              values={values}
              handleAPIRequest={handleAPIRequest}
              handleAPIDelete={handleAPIDelete}
              added={added}
              formName={formName}
              onAdded={onAdded}
              setAddForm={setAddForm}
              setAlert={setAlertMessage}
              setError={setErrorMessage}
              setUrlPost={setUrlPost}
              formType={formType}
            />
          </Modal.Body>
        </Modal>
      </div>

      <FPBTable
        readOnly={true}
        editOnly={true}
        deleteOnly={true}
        headers={headers}
        tableData={tableData}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        deleteChange={deleteChange}
        columnsToHide={columnsToHide}
        handleChange={handleChange}
        handleAPIRequestUpdate={handleAPIRequestUpdate}
        added={added}
        onAdded={onAdded}
        setAddForm={setAddForm}
        setAlert={setAlertMessage}
        setError={setErrorMessageUpdate}
        errorMessageUpdate={errorMessageUpdate}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        setFilters={setFilters}
      />
      <Calendar
        events={calendar}
        startAccessor="start_date"
        endAccessor="end_date"
        titleAccessor="title"
        resourceIdAccessor="resourceId"
        resources={resourceMap.tableData}
        resourceTitleAccessor="resourceId"
        step={30}
        views={views}
        localizer={localizer}
        popup
        style={{ height: 700, marginTop: '80px', marginBottom: '80px' }}
      />
    </>
  );
};

export default ShiftAssignment;
