import { createSlice } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';
import Urls from '../../components/Urls';

export const iotProcessControlSlice = createSlice({
  name: 'iotProcessControl',
  initialState: {
    data: {},
    from: '',
    to: '',
  },
  reducers: {
    getIotProcessControl: (state, action) => {
      state.data = action.payload;
    },
    setFromDate: (state, action) => {
      state.from = action.payload;
    },
    setToDate: (state, action) => {
      state.to = action.payload;
    },
  },
});

export const getIotProcessControlAsync = () => async (dispatch) => {
  try {
    const response = await apiService.getData(Urls.iotProcessControl, 'id');
    dispatch(getIotProcessControl(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { getIotProcessControl, setFromDate, setToDate } = iotProcessControlSlice.actions;
export const showIotProcessControl = (state) => state.iotProcessControl.data;

export default iotProcessControlSlice.reducer;
