import Urls from '../Urls';

const baseUrl = Urls.baseFile;

const templatesUrls = {
  item: `${baseUrl}/media/templates/ItemTemplate.xlsx`,
  storageCenter: `${baseUrl}/media/templates/StorageCenterTemplate.xlsx`,
  storageLocation: `${baseUrl}/media/templates/StorageLocationTemplate.xlsx`,
  itemCategory: `${baseUrl}/media/templates/ItemCategoryTemplate.xlsx`,
  cost: `${baseUrl}/media/templates/CostTemplate.xlsx`,
  costType: `${baseUrl}/media/templates/CostTypeTemplate.xlsx`,
  dimensionalUnit: `${baseUrl}/media/templates/DimensionalUnitTemplate.xlsx`,
  supplier: `${baseUrl}/media/templates/SupplierTemplate.xlsx`,
  customer: `${baseUrl}/media/templates/CustomerTemplate.xlsx`,
  entryOrder: `${baseUrl}/media/templates/EntryOrderTemplate.xlsx`,
  itemStorageCenterRelation: `${baseUrl}/media/templates/ItemStorageCenterRelationTemplate.xlsx`,
  batch: `${baseUrl}/media/templates/BatchTemplate.xlsx`,
  dispatchOrder: `${baseUrl}/media/templates/DispatchOrderTemplate.xlsx`,
  itemBatchRelation: `${baseUrl}/media/templates/ItemBatchRelationTemplate.xlsx`,
  adjustment: `${baseUrl}/media/templates/AdjustmentTemplate.xlsx`,
  //INDUSTRIAL MODULE
  product: `${baseUrl}/media/templates/ProductTemplate.xlsx`,
  unitTransformation: `${baseUrl}/media/templates/UnitTransformationTemplate.xlsx`,
  workCenter: `${baseUrl}/media/templates/WorkCenterTemplate.xlsx`,
  workCenterCapacity: `${baseUrl}/media/templates/WorkCenterCapacityTemplate.xlsx`,
  eventCategory: `${baseUrl}/media/templates/EventCategoryTemplate.xlsx`,
  productCategory: `${baseUrl}/media/templates/ProductCategoryTemplate.xlsx`,
  workCenterCategory: `${baseUrl}/media/templates/WorkCenterCategoryTemplate.xlsx`,
  dimensionalUnitA: `${baseUrl}/media/templates/DimensionalUnitATemplate.xlsx`,
  eventType: `${baseUrl}/media/templates/EventTypeTemplate.xlsx`,
  operationCenter: `${baseUrl}/media/templates/OperationCenterTemplate.xlsx`,
  qualityDimension: `${baseUrl}/media/templates/QualityDimensionTemplate.xlsx`,
  variable: `${baseUrl}/media/templates/VariableTemplate.xlsx`,
  shift: `${baseUrl}/media/templates/ShiftTemplate.xlsx`,
  process: `${baseUrl}/media/templates/ProcessTemplate.xlsx`,
  route: `${baseUrl}/media/templates/ProcessRouteTemplate.xlsx`,
  workCenterPerProcess: `${baseUrl}/media/templates/WorkCenterPerProcessTemplate.xlsx`,
  productionOrder: `${baseUrl}/media/templates/ProductionOrderTemplate.xlsx`,
  simplifiedProductionOrder: `${baseUrl}/media/templates/SimplifiedProductionOrderTemplate.xlsx`,
  productionBatch: `${baseUrl}/media/templates/ProductionBatchTemplate.xlsx`,
};

export default templatesUrls;
