export const getFiltersString = (filters) => {
  const codeColumns = ['variable', 'dimensional_unit'];
  let filter = filters
    .reduce((acc, { columnName, operation, value }) => {
      let finalColumnName;
      if (codeColumns.includes(columnName)) {
        finalColumnName = `${columnName}__code`;
      } else {
        finalColumnName = columnName;
      }
      switch (operation) {
        case 'contains': {
          acc.push(`${finalColumnName}__icontains=${encodeURIComponent(value)}`);
          break;
        }
        case 'equal': {
          acc.push(`${finalColumnName}=${encodeURIComponent(value)}`);
          break;
        }
        case 'greaterThanOrEqual': {
          acc.push(`${finalColumnName}__gte=${encodeURIComponent(value)}`);
          break;
        }
        case 'lessThanOrEqual': {
          acc.push(`${finalColumnName}__lte=${encodeURIComponent(value)}`);
          break;
        }
        default:
          acc.push(`${finalColumnName}__${operation}=${encodeURIComponent(value)}`);
          break;
      }
      return acc;
    }, [])
    .join('&');

  if (filters.length > 1) {
    filter = `${filter}`;
  }
  return filter;
};
