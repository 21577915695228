import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    timeZone: 'America/Bogota',
    paymentNotification: 'no',
    company: '',
    defaultModule: '',
    companyModule: '',
  },
  reducers: {
    loginAction: (state, action) => {
      state.user = action.payload.user;
      state.timeZone = action.payload.timeZone;
      state.paymentNotification = action.payload.paymentNotification;
      state.company = action.payload.company;
      state.defaultModule = action.payload.defaultModule;
      state.companyModule = action.payload.companyModule;
    },
    setUserModule: (state, action) => {
      state.defaultModule = action.payload;
    },
  },
});

export const { loginAction, setUserModule } = userSlice.actions;

export default userSlice.reducer;
