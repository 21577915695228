import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';
import Urls from '../../components/Urls';

export const getOeeAsync = createAsyncThunk(
  'oee/getOeeAsync',
  async ({ startDate, endDate, product, shift, workCenter }) => {
    try {
      const res = await apiService.getOeeByFilters(startDate, endDate, product, shift, workCenter);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getAvailabilityAsync = createAsyncThunk(
  'oee/getAvailabilityAsync',
  async ({ startDate, endDate, temporality, product, shift, workCenter }) => {
    try {
      const res = await apiService.getOeeTrend(
        Urls.availability,
        startDate,
        endDate,
        temporality,
        product,
        shift,
        workCenter
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getPerformanceAsync = createAsyncThunk(
  'oee/getPerformanceAsync',
  async ({ startDate, endDate, temporality, product, shift, workCenter }) => {
    try {
      const res = await apiService.getOeeTrend(
        Urls.performance,
        startDate,
        endDate,
        temporality,
        product,
        shift,
        workCenter
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getQualityAsync = createAsyncThunk(
  'oee/getQualityAsync',
  async ({ startDate, endDate, temporality, product, shift, workCenter }) => {
    try {
      const res = await apiService.getOeeTrend(
        Urls.quality,
        startDate,
        endDate,
        temporality,
        product,
        shift,
        workCenter
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const oeeSlice = createSlice({
  name: 'oee',
  initialState: {
    data: {},
    availability: [],
    performance: [],
    quality: [],
    loading: false,
    error: null,
    progress: { pending: true, current: 0, total: 0, percent: 0 },
    status: 'idle',
    selectedWorkCenter: {},
    filters: {},
  },
  reducers: {
    getOee: (state, action) => {
      state.data = action.payload;
    },
    getAvailability: (state, action) => {
      state.availability = action.payload;
    },
    getPerformance: (state, action) => {
      state.performance = action.payload;
    },
    getQuality: (state, action) => {
      state.quality = action.payload;
    },
    setDataOeeTrend: (state, action) => {
      state.data = action.payload;
    },
    setProgressOeeTrend: (state, action) => {
      if (action.payload.current >= state.progress?.current) {
        state.progress = action.payload;
      } else {
        state.progress = state.progress;
      }
    },
    setStatusOeeTrend: (state, action) => {
      state.status = action.payload;
      if (action.payload === 'SUCCESS') {
        state.progress = { ...state.progress, current: 0, percent: 100 };
      }
    },
    setSelectedWorkCenter: (state, action) => {
      state.selectedWorkCenter = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOeeAsync.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getAvailabilityAsync.fulfilled, (state, action) => {
      state.availability = action.payload;
      state.loading = false;
    });
    builder.addCase(getPerformanceAsync.fulfilled, (state, action) => {
      state.performance = action.payload;
      state.loading = false;
    });
    builder.addCase(getQualityAsync.fulfilled, (state, action) => {
      state.quality = action.payload;
      state.loading = false;
    });
    // in case of pending (request is being made) initialState.loading = true
    builder.addCase(getQualityAsync.pending, (state, action) => {
      state.loading = true;
    });
    // in case of rejected (40X code) initialState.error = true
    builder.addCase(getQualityAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const {
  getOee,
  getAvailability,
  getPerformance,
  getQuality,
  setDataOeeTrend,
  setProgressOeeTrend,
  setStatusOeeTrend,
  setSelectedWorkCenter,
  setFilters,
} = oeeSlice.actions;
export const showOee = (state) => state.oee.data;
export const showAvailability = (state) => state.oee.availability;
export const showPerformance = (state) => state.oee.performance;
export const showQuality = (state) => state.oee.quality;
export const loadingState = (state) => state.oee.loading;
export const errorState = (state) => state.oee.error;

export default oeeSlice.reducer;
