import { createSlice } from '@reduxjs/toolkit';

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    selectedWorkCenters: [],
    selectedProductionOrders: [],
  },
  reducers: {
    setSelectedWorkCenters: (state, action) => {
      state.selectedWorkCenters = action.payload;
    },
    setSelectedProductionOrders: (state, action) => {
      state.selectedProductionOrders = action.payload;
    },
  },
});

export const { setSelectedWorkCenters, setSelectedProductionOrders } = tableSlice.actions;
export const showSelectedWorkCenters = (state) => state.table.selectedWorkCenters;
export const showSelectedProductionOrders = (state) => state.table.selectedProductionOrders;

export default tableSlice.reducer;
