import { Form } from 'react-bootstrap';
import Select from 'react-select';

const TransactionSubTypeSelection = ({ onChange, value, ...props }) => {
  const transactionSubTypeOptions = [
    { value: 'total', label: 'Total' },
    { value: 'partial', label: 'Partial' },
  ];

  return (
    <>
      <Form.Label>{props.label || 'Transaction Sub Type'}</Form.Label>
      <Select
        {...props}
        isClearable={true}
        options={transactionSubTypeOptions}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default TransactionSubTypeSelection;
