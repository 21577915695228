import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';

const IndicatorPercentages = ({ percentageSign, percentage, percentageColor }) => {
  const Style = {
    percentage: { color: percentageColor, paddingBottom: 0, marginBottom: 0 },
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <p style={{ fontSize: '10px', paddingBottom: 0, marginBottom: 0 }}>
        Percentage variation corresponding to the selected period
      </p>
    </Tooltip>
  );

  const ArrowIcon = ({ percentageSign }) => {
    if (percentageSign === '+') {
      return <RiIcons.RiArrowUpSFill />;
    } else if (percentageSign === '-') {
      return <RiIcons.RiArrowDownSFill />;
    } else return null;
  };

  return (
    <p style={Style.percentage}>
      <ArrowIcon percentageSign={percentageSign} />
      {percentage}
      %
      <span className="" />
      <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
        <Button
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            padding: 0,
          }}
        >
          <RiIcons.RiQuestionLine
            style={{
              color: 'gray',
              verticalAlign: 'top',
              paddingTop: '1px',
            }}
          />
        </Button>
      </OverlayTrigger>
    </p>
  );
};

export default IndicatorPercentages;
