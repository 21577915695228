import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

function ProductInformation() {
  const productOptions = [
    { value: '1', label: 'Pre Fried Frozen Ripe Plantain Slices' },
    { value: '2', label: 'Pre Fried Frozen Tostones Green Plantain' },
    { value: '3', label: 'Pre Fried Frozen Tostones Hawaianos' },
    { value: '4', label: 'Pre Fried Frozen Ripe Plantain Slices and Green Plantain Tostones' },
    { value: '5', label: 'Frozen Ajiaco' },
    { value: '6', label: 'Pre Fried Frozen Whole Ripe Plantain' },
  ];
  const rawSourceOptions = [
    { value: '1', label: 'El Carmen - Manabí - Ecuador' },
    { value: '2', label: 'Guayas - Ecuador' },
    { value: '3', label: 'Oriente - Ecuador' },
    { value: '4', label: ' Los Ríos - Ecuador ' },
    { value: '5', label: 'Santo Domingo - Ecuador' },
  ];

  return (
    <Form>
      <Form.Group>
        <Form.Label className="mb-1">Product:</Form.Label>
        <Select options={productOptions} />
      </Form.Group>
      <Form.Group>
        <Form.Label className="mb-1">Brand:</Form.Label>
        <Form.Control type="text" />
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <Form.Label className="mb-1">Process:</Form.Label>
          <Form.Control as="textarea" aria-label="With textarea" />
        </Col>
        <Col>
          <Form.Label className="mb-1">Ingredients:</Form.Label>
          <Form.Control as="textarea" aria-label="With textarea" />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <Form.Label className="mb-1">Source of raw material:</Form.Label>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={rawSourceOptions}
          />
        </Col>
        <Col>
          <Form.Label className="mb-1">Allergens:</Form.Label>
          <Form.Control type="text" />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <Form.Label className="mb-1">Intented use:</Form.Label>
          <Form.Control as="textarea" aria-label="With textarea" rows={2} />
        </Col>
        <Col>
          <Form.Label className="mb-1">Shelf life:</Form.Label>
          <Form.Control as="textarea" aria-label="With textarea" rows={2} />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <Form.Label className="mb-1">Storage:</Form.Label>
          <Form.Control as="textarea" aria-label="With textarea" rows={1} />
        </Col>
        <Col>
          <Form.Label className="mb-1">Distributions:</Form.Label>
          <Form.Control as="textarea" aria-label="With textarea" rows={1} />
        </Col>
      </Form.Group>
    </Form>
  );
}

export default ProductInformation;
