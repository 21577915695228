import apiService from '../components/apiService';
import Urls from '../components/Urls';

const industrialClient = apiService.industrialClient();

export const getInfoToDelete = async (id, type) => {
  if (type === 'productionOrder') {
    const url = `${Urls.productPerProductionOrders}?page_size=0&production_order__code=${id}`;
    const productPerProductionOrders = await industrialClient.get(url);
    const productPerProductionOrdersId = productPerProductionOrders.data.map((order) => order.id);
    return productPerProductionOrdersId;
  } else if (type === 'productPerProductionOrder') {
    const productPerProductionOrder = await industrialClient.get(
      `${Urls.productPerProductionOrders}/${id}`
    );
    const productionOrder = productPerProductionOrder.data.production_order;
    const product = productPerProductionOrder.data.product;
    const productPerProductionOrders = await industrialClient.get(
      `${Urls.productPerProductionOrders}?page_size=0&production_order__code=${productionOrder}&product__code=${product}`
    );
    const productPerProductionOrdersId = productPerProductionOrders.data.map((order) => order.id);
    const isProductRouteStarted =
      productPerProductionOrders.data.filter((order) => order.production_status !== 'not_started')
        .length !== 0;

    return isProductRouteStarted ? 'productStarted' : productPerProductionOrdersId;
  }
};

export const getDeleteMessages = async (id, type) => {
  const associatedPPO = await getInfoToDelete(id, type);
  if (associatedPPO === 'productStarted') {
    return {
      message: 'No es posible eliminar el producto porque ha comenzado su proceso de producción',
      type: 'warning',
    };
  }
  const stringPPO = associatedPPO.join(',');
  const PPONumber = associatedPPO.length;

  const manualUrl = `${Urls.manualTable}?product_per_production_order__id__in=${stringPPO}`;
  const associatedManualReports = await industrialClient.get(manualUrl);
  const manualReportsNumber = PPONumber ? associatedManualReports.data?.length : 0;

  const qualityUrl = `${Urls.qualityReport}?production_order__code=${id}`;
  const associatedQualityReports = await industrialClient.get(qualityUrl);
  const qualityReportsNumber = associatedQualityReports.data.count;

  if (type === 'productionOrder') {
    //1. if no reports and no associated productPerProdOrders
    if (PPONumber === 0 && qualityReportsNumber === 0 && manualReportsNumber === 0) {
      return { message: `Are you sure you want to delete order ${id}`, type: 'confirmation' };
    }
    //2. if no reports but associated productPerProdOrders
    else if (PPONumber > 0 && qualityReportsNumber === 0 && manualReportsNumber === 0) {
      return {
        message: `It is not possible to delete this production order, the order has been previously managed. Are you sure you want to delete order ${id},
          If you delete production order ${id}, the process route and (${PPONumber}) product(s) per production order will be also deleted.`,
        type: 'confirmation',
      };
    } //3.  if associated reprots
    else if (qualityReportsNumber > 0 || manualReportsNumber > 0) {
      return {
        message: 'There are associated reports',
        type: 'warning',
      };
    }
  } else if (type === 'productPerProductionOrder') {
    //1. if no reports and no other associated productPerProdOrders
    if (PPONumber === 1 && qualityReportsNumber === 0 && manualReportsNumber === 0) {
      return { message: `Are you sure you want to delete order ${id}`, type: 'confirmation' };
    }
    //2. if no reports but have associated productPerProdOrders
    else if (PPONumber > 1 && qualityReportsNumber === 0 && manualReportsNumber === 0) {
      return {
        message: `To delete this order, ${
          PPONumber - 1
        } additional product per production order(s) will be also deleted.`,
        type: 'confirmation',
      };
    } //3. if associated reports
    else if (qualityReportsNumber > 0 || manualReportsNumber > 0) {
      return { message: 'There are associated reports', type: 'warning' };
    }
  }
};

export const deleteProductionOrder = async (id, PPO) => {
  for (const order of PPO) {
    await industrialClient.delete(`${Urls.productPerProductionOrders}/${order}`);
  }
  await industrialClient.delete(`${Urls.productionOrder}/${id}`);
};

export const deleteProductPerProductionOrder = async (PPO) => {
  for (const order of PPO) {
    await industrialClient.delete(`${Urls.productPerProductionOrders}/${order}`);
  }
};
