import React from 'react';
import { Col, DropdownButton, Dropdown, InputGroup } from 'react-bootstrap';
import Select from 'react-select';

const FilterSelect = ({
  filterSelected,
  filterObjects,
  selectedOption,
  filterData,
  setFilterSelected,
  setFilterValue,
  setFilterCode,
  setSelectedOption,
}) => (
  <Col md={5} className="pl-0">
    <DropdownButton
      data-testid="filterSelection"
      style={{ float: 'left' }}
      as={InputGroup.Prepend}
      variant={filterSelected ? 'secondary' : 'outline-secondary'}
      title={typeof filterSelected !== 'undefined' ? filterSelected.label : ''}
      id="input-group-dropdown-2"
    >
      {filterObjects.map((filterItem, idx) => (
        <Dropdown.Item
          key={idx}
          name={filterItem.label}
          href="#"
          onClick={(e) => {
            setFilterSelected(filterItem);
          }}
        >
          {filterItem.label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
    <>
      <Select
        isDisabled={
          !!(filterSelected && (filterSelected.name === 'total' || filterSelected.name === ''))
        }
        placeholder="Enter code"
        options={filterData.options}
        value={selectedOption}
        onChange={(el) => {
          setFilterValue(el.id);
          setFilterCode(el.value);
          setSelectedOption(el);
        }}
      />
      {/* <div style={Style.requiredLabel}>
           {filterValue == undefined ? 'Required' : ''}
        </div> */}
    </>
  </Col>
);

export default FilterSelect;
