import axios from 'axios';
import Urls from './Urls';

const pageSize = 100;

const getPaginatedData = async (url) => {
  const token = localStorage.getItem('accessToken');
  let isLastPage = false;
  let dataContainer = [];
  let currentPage = 1;
  while (!isLastPage) {
    const response = await axios.get(`${url}?page_size=${pageSize}&page=${currentPage}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dataContainer = [...dataContainer, ...response.data.results];
    currentPage = ++currentPage;

    if (response.data.next === null) {
      isLastPage = true;
    }
  }
  return dataContainer;
};
const Data = {
  getItems: async () => {
    const url = Urls.item;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getStorageCenters: async () => {
    const url = Urls.storageCenter;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getStorageLocations: async () => {
    const url = Urls.storageLocation;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getBatches: async () => {
    const url = Urls.batch;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getItemCategories: async () => {
    const url = Urls.itemCategory;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getChildCategories: async () => {
    const url = Urls.itemCategory;
    const totalResponseResults = await getPaginatedData(url);
    const childResponseResults = totalResponseResults.map((elem) => elem.parent_category !== null);
    return childResponseResults;
  },
  getParentCategories: async () => {
    const url = Urls.itemCategory;
    const totalResponseResults = await getPaginatedData(url);
    const parentResponseResults = totalResponseResults.map((elem) => elem.parent_category === null);
    return parentResponseResults;
  },
  getCosts: async () => {
    const url = Urls.cost;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getCostTypes: async () => {
    const url = Urls.costType;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getSuppliers: async () => {
    const url = Urls.supplier;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getCustomers: async () => {
    const url = Urls.customer;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getEntryOrders: async () => {
    const url = Urls.entryOrder;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getDispatchOrders: async () => {
    const url = Urls.dispatchOrder;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getDimensionalUnits: async () => {
    const url = Urls.dimensionalUnit;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getAdjustments: async () => {
    const url = Urls.adjustment;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getItemBatch: async () => {
    const url = Urls.itemBatchRelation;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getItemStorageCenter: async () => {
    const url = Urls.itemStorageRelation;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getItemCompanies: async () => {
    const url = Urls.company;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getItemUsers: async () => {
    const url = Urls.user;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getItemModules: async () => {
    const url = Urls.module;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getItemCompanyModules: async () => {
    const url = Urls.company_module;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getRoutes: async () => {
    const url = Urls.route;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getProducts: async () => {
    const url = Urls.product;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getWorkCenterPerProcess: async () => {
    const url = Urls.workCenterPerProcess;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getGroupPermission: async () => {
    const url = Urls.group_permission;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
  getUserGroupPermission: async () => {
    const url = Urls.usergroup_permission;
    const responseResults = await getPaginatedData(url);
    return responseResults;
  },
};

export default Data;
