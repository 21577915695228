import { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Form, Col } from 'react-bootstrap';
import Urls from '../Urls';
import axios from 'axios';
import { FaTable } from 'react-icons/fa';
import TableModal from '../TableModal/TableModal';
import { ErrorMessage, useField } from 'formik';

const ProductionBatchSelection = ({ onChange, value, ...props }) => {
  const [field] = useField(props);
  const [token] = useState(localStorage.getItem('accessToken'));
  const url = Urls.productionBatch;
  const [showProductionBatchModal, setShowProductionBatchModal] = useState(false);

  const getUpdatedProduct = async (inputValue, loadedOptions, { page }) => {
    const query = `${url}?page=${page}&page_size=${10}&code__icontains=${inputValue}`;
    let productionBatch = [];
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            productionBatch.push({
              value: result.code,
              label: result.code,
              description: result.description,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return {
      options: productionBatch,
      hasMore: productionBatch.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleModalConfirm = (selected, title) => {
    const e = {
      value: selected.code,
      label: selected.code,
      description: selected.description,
    };
    onChange(e);
    setShowProductionBatchModal(false);
  };

  return (
    <Form.Group className="mt-1" as={Col}>
      {showProductionBatchModal ? (
        <TableModal
          url={url}
          title="Production Batch"
          show={showProductionBatchModal}
          handleClose={() => {
            setShowProductionBatchModal(false);
          }}
          formName={'productionBatch'}
          handleConfirmation={handleModalConfirm}
          columnToTransform={[]}
        />
      ) : null}
      <Form.Label>
        {props.label || 'productionBatch'}
        <FaTable
          className="icon-search ml-2"
          onClick={() => {
            setShowProductionBatchModal(true);
          }}
          data-testid="icon-search"
        />
      </Form.Label>
      <AsyncPaginate
        {...props}
        isClearable={true}
        defaultOptions
        value={value}
        loadOptions={getUpdatedProduct}
        onChange={onChange}
        additional={{
          page: 1,
        }}
      />
      <ErrorMessage component="div" name={field.name} className="fpb-error" />
    </Form.Group>
  );
};

export default ProductionBatchSelection;
