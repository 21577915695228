import axios from 'axios';
import Urls from './Urls';

const pageSize = 100;
const token = localStorage.getItem('accessToken') || null;

const getPaginatedData = async (url) => {
  const token = localStorage.getItem('accessToken');
  let isLastPage = false;
  let dataContainer = [];
  let currentPage = 1;
  while (!isLastPage) {
    const response = await axios.get(`${url}?page_size=${pageSize}&page=${currentPage}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dataContainer = [...dataContainer, ...response.data.results];
    currentPage = ++currentPage;

    if (response.data.next === null) {
      isLastPage = true;
    }
  }
  return dataContainer;
};

const getPaginatedDataWithFilters = async (url, filters) => {
  const token = localStorage.getItem('accessToken');
  const filtersString = filters.join('&');
  let isLastPage = false;
  let dataContainer = [];
  let currentPage = 1;
  const finalUrl = `${url}?page_size=${pageSize}&page=${currentPage}&${filtersString}`;
  while (!isLastPage) {
    const response = await axios.get(finalUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.results) {
      dataContainer = [...dataContainer, ...response.data.results];
      currentPage = ++currentPage;
    }

    if (response.data.next === null) {
      isLastPage = true;
    }
  }
  return dataContainer;
};

const apiService = {
  // Authentication module
  authentication: {
    getCompanyModules: async (company) => {
      const token = localStorage.getItem('accessToken');
      const res = await axios.get(`${Urls.company_module}?page=1&page_size=20&company=${company}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    },
  },

  // GET requests
  getData: async (url, codeName, allowedElements) => {
    const res = await getPaginatedData(url);
    // if --allowedElements-- parameter is provided, we filter the response according to --allowedElements-- list
    let data;
    if (typeof allowedElements !== 'undefined') {
      data = res.filter((elem) => allowedElements.includes(elem.id));
    } else {
      data = res;
    }
    if (data.length > 0) {
      const results = data.map((el) => ({
        description: el.description || el.item_description || el.company_name,
        value: el[codeName],
        label: el[codeName],
        id: el.id,
      }));
      const headersList = Object.keys(data[0]).filter((item) => {
        let returnColumn;
        item !== 'id' && item !== 'created' && item !== 'updated'
          ? (returnColumn = true)
          : (returnColumn = false);
        return returnColumn;
      });
      // headers, tableData, options
      return { headersList, data, results };
    }
    return res;
  },

  getData1: async (url, codeName, allowedElements) => {
    const res = await getPaginatedData(url);
    // if --allowedElements-- parameter is provided, we filter the response according to --allowedElements-- list
    let data;
    if (typeof allowedElements !== 'undefined') {
      data = res.filter((elem) => allowedElements.includes(elem.id));
    } else {
      data = res;
    }
    if (data.length > 0) {
      const results = data.map((el) => ({
        description: el.description,
        value: el[codeName],
        label: el[codeName] + ' - ' + el['description'],
        id: el.id,
      }));
      const headersList = Object.keys(data[0]).filter((item) => {
        let returnColumn;
        item !== 'id' && item !== 'created' && item !== 'updated'
          ? (returnColumn = true)
          : (returnColumn = false);
        return returnColumn;
      });
      // headers, tableData, options
      return { headersList, data, results };
    }
    return res;
  },

  getShift: async (url, codeName, allowedElements) => {
    const res = await getPaginatedData(url);
    let data;
    if (typeof allowedElements !== 'undefined') {
      data = res.filter((elem) => allowedElements.includes(elem.id));
    } else {
      data = res;
    }
    if (data.length > 0) {
      const results = data.map((el) => ({
        description: el.description,
        value: el[codeName],
        label: el[codeName] + ' (' + el['start_hour'] + '-' + el['end_hour'] + ')',
        id: el.id,
      }));
      return { results };
    }
    return res;
  },

  getDataWithFilters: async (url, filters) => {
    const data = await getPaginatedDataWithFilters(url, filters);
    return data;
  },

  getActiveCosts: async (item) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(`${Urls.cost}?item_reference_code__id=${item}&unit_cost_state=active`, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  getChildCategories: async () => {
    const token = localStorage.getItem('accessToken');
    const codeName = 'item_category_code';
    let headersList;
    let results;
    const res = await axios.get(Urls.itemCategory, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
    const childCategories = res.data.results.filter((el) => el.parent_category !== null);
    if (childCategories.length > 0) {
      results = childCategories.map((el) => ({
        description: el.description,
        value: el[codeName],
        label: el[codeName],
        id: el.id,
      }));
    }
    headersList = Object.keys(childCategories[0]).filter((el) => {
      let returnColumn;
      el !== 'id' && el !== 'created' && el !== 'updated'
        ? (returnColumn = true)
        : (returnColumn = false);
      return returnColumn;
    });
    return { headersList, childCategories, results };
  },

  getParentCategories: async () => {
    const token = localStorage.getItem('accessToken');
    const codeName = 'item_category_code';
    let headersList;
    let results;
    const res = await axios.get(Urls.itemCategory, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
    const childCategories = res.data.results.filter((el) => el.parent_category === null);
    if (childCategories.length > 0) {
      results = childCategories.map((el) => ({
        description: el.description,
        value: el[codeName],
        label: el[codeName],
        id: el.id,
      }));
      headersList = Object.keys(childCategories[0]).filter((el) => {
        let returnColumn;
        el !== 'id' && el !== 'created' && el !== 'updated'
          ? (returnColumn = true)
          : (returnColumn = false);
        return returnColumn;
      });
    }
    return { headersList, childCategories, results };
  },

  getStorageCenters: async () => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(Urls.storageCenter, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
    return res.data.results;
  },

  getCeleryTaskProgress: async (taskId) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(`${Urls.celery}/${taskId}`, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
    return res.data;
  },

  getEventsByOrder: async (page, pageSize, productionOrder, workCenter, filters) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.eventQuantities}?page=${page}&page_size=${pageSize}&work_center=${workCenter}&product_per_production_order=${productionOrder}&${filters}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getProductRoute: async (product) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(`${Urls.generateRoute}?product=${product}`, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  getProductionOrderByCode: async (code) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(`${Urls.productionOrder}?code__exact=${code}`, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  //Indicators

  getTimeAnalysisByFilters: async (
    startDate,
    endDate,
    temporality,
    workCenter,
    shift,
    product,
    productionOrder
  ) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.timeAnalysis}?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&work_center=${workCenter}&shift=${shift}&product=${product}&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },
  getTimeAnalysisTask: async (
    startDate,
    endDate,
    temporality,
    workCenter,
    shift,
    product,
    productionOrder
  ) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.timeAnalysis}?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&work_center=${workCenter}&shift=${shift}&product=${product}&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res.data.task_id;
  },

  getProducedUnitsTask: async (
    startDate,
    endDate,
    temporality,
    workCenter,
    shift,
    product,
    productionOrder
  ) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.producedUnits}?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&work_center=${workCenter}&shift=${shift}&product=${product}&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res.data.task_id;
  },

  getQualityByFilters: async (
    startDate,
    endDate,
    temporality,
    product,
    productionOrder,
    shift,
    workCenterList
  ) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${
        Urls.quality
      }?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&product=${product}&shift=${shift}&work_centers_list=${JSON.stringify(
        workCenterList
      )}&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res.data.task_id;
  },

  getQualityByWorkCenter: async (
    startDate,
    endDate,
    temporality,
    product,
    productionOrder,
    shift,
    workCenter
  ) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.quality}?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&product=${product}&shift=${shift}&work_center=${workCenter}&array_format=1&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res.data.task_id;
  },

  getAvailabilityByFilters: async (
    startDate,
    endDate,
    temporality,
    product,
    productionOrder,
    shift,
    workCenterList
  ) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${
        Urls.availability
      }?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&product=${product}&shift=${shift}&work_centers_list=${JSON.stringify(
        workCenterList
      )}&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res.data.task_id;
  },

  getAvailabilityByWorkCenter: async (
    startDate,
    endDate,
    temporality,
    product,
    productionOrder,
    shift,
    workCenter
  ) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.availability}?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&product=${product}&shift=${shift}&work_center=${workCenter}&array_format=1&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res.data.task_id;
  },

  getOeeByFilters: async (startDate, endDate, product, productionOrder, shift, workCenter) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.oeeTrend}?start_date=${startDate}&end_date=${endDate}&product=${product}&shift=${shift}&work_center=${workCenter}&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res.data.task_id;
  },

  getOeeTrend: async (url, startDate, endDate, temporality, product, shift, workCenter) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${url}?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&product=${product}&shift=${shift}&work_center=${workCenter}&array_format=1`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getPerformanceByFilters: async (
    startDate,
    endDate,
    temporality,
    product,
    productionOrder,
    shift,
    workCenterList
  ) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${
        Urls.performance
      }?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&product=${product}&shift=${shift}&work_centers_list=${JSON.stringify(
        workCenterList
      )}&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res.data.task_id;
  },

  getPerformanceByWorkCenter: async (
    startDate,
    endDate,
    temporality,
    product,
    productionOrder,
    shift,
    workCenter
  ) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.performance}?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&product=${product}&shift=${shift}&work_center=${workCenter}&array_format=1&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res.data.task_id;
  },

  getIotProcessControl: async (workCenter, variable) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.iotProcessControl}?current_day=1&work_center__code=${workCenter}&variable__code=${variable}&page_size=1440`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getTimeAccomplishmentByFilters: async (end_date, op_list) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.timeAccomplishment}?current_day=1&end_date=${end_date}&op_list=${op_list}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getIotProcessControlHistorical: async (workCenter, variable, start_date, end_date) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.iotProcessControl}?work_center__code=${workCenter}&variable__code=${variable}&timestamp__gte=${start_date}&timestamp__lt=${end_date}&page_size=0`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getOrdersWithPagination: async (page, pageSize, filters) => {
    const token = localStorage.getItem('accessToken');
    try {
      const res = await axios.get(
        `${Urls.productionOrderWithList}?page=${
          page + 1
        }&page_size=${pageSize}&manufacturing_status=closed&with_list=true&${filters}`,
        {
          headers: {
            Authorization: `Bearer  ${token}`,
          },
        }
      );
      if (res.data.results && res.data.results.length > 0) {
        const headersList = Object.keys(res.data.results[0]).filter((elem) => {
          let returnColumn;
          if (
            elem === 'id' ||
            elem === 'assignment_date' ||
            elem === 'created_at' ||
            elem === 'created_by' ||
            elem === 'loading_date' ||
            elem === 'updated_at' ||
            elem === 'updated_by' ||
            elem === 'manufacturing_status' ||
            elem === 'event' ||
            elem === 'product_list'
          ) {
            returnColumn = false;
          } else {
            returnColumn = true;
          }
          return returnColumn;
        });
        return { results: res.data.results, headers: headersList, count: res.data.count };
      } else {
        return { results: [], headers: [], count: 0 };
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  getOrderTraceability: async (order, product) => {
    const token = localStorage.getItem('accessToken');
    try {
      const res = await axios.get(
        `${Urls.orderTraceability}?production_order=${order}&product=${product}`,
        {
          headers: {
            Authorization: `Bearer  ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      if (error?.error) throw new Error(error?.error);
      throw new Error(
        'The report could not be created for this order, this may be due to inconsistency in the data'
      );
    }
  },

  getWorkCenters: async () => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(`${Urls.workCenter}?page_size=0`, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  getWorkCenterCategory: async () => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(`${Urls.workCenterCategory}?page_size=0`, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  getWorkCenterCapacity: async (workCenter, product) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.workCenterCapacity}?work_center__code=${workCenter}&product__code=${product}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getManualProcessControl: async (workCenter, variable) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.manualQuantities}?work_center=${workCenter}&variable__code=${variable}&page_size=0&data_source=manual`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getManualProcessControlFromDate: async (workCenter, variable, from) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.manualQuantities}?work_center__exact=${workCenter}&variable__code=${variable}&end_date__gte=${from}&page_size=0&data_source=manual`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res?.data;
  },

  getIotProcessControlFromDate: async (workCenter, variable, product, from) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.timeSeriesIot}?work_center__code=${workCenter}&variable__code=${variable}&product=${product}&timestamp__gte=${from}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res?.data;
  },

  getIotProcessControlQualityFromDate: async (workCenter, variable, product, from) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.timeSeriesIot}?work_center__code=${workCenter}&variable__code=${variable}&product=${product}&timestamp__gte=${from}&quality=1`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res?.data;
  },

  getIotSeriesTask: async (workCenter, variable, product, from, to) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.timeSeriesIot}?work_center__code=${workCenter}&variable__code=${variable}&product=${product}&timestamp__gte=${from}&timestamp__lte=${to}&task=1`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res?.data?.task_id;
  },

  getIotQualitySeriesTask: async (workCenter, variable, product, from, to) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.timeSeriesIot}?work_center__code=${workCenter}&variable__code=${variable}&product=${product}&timestamp__gte=${from}&timestamp__lte=${to}&task=1&quality=1`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res?.data?.task_id;
  },

  getManualProcessControlFromToDate: async (workCenter, variable, from, to) => {
    const token = localStorage.getItem('accessToken');
    const res = await axios.get(
      `${Urls.manualQuantities}?work_center__exact=${workCenter}&variable__code=${variable}&end_date__gte=${from}&end_date__lte=${to}&page_size=0&data_source=manual`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
    return res?.data;
  },

  getProducedUnitsByFilters: async (
    startDate,
    endDate,
    temporality,
    workCenter,
    shift,
    product,
    productionOrder
  ) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.producedUnits}?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&work_center=${workCenter}&shift=${shift}&product=${product}&production_order=${productionOrder}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getManualReportsByOrder: async (page, pageSize, productionOrder, workCenter, filters) => {
    const token = localStorage.getItem('accessToken');
    const url = `${Urls.manualQuantities}?page=${page}&page_size=${pageSize}&work_center__exact=${workCenter}&product_per_production_order=${productionOrder}&${filters}`;
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  getShelfLifeByOrder: async (page, pageSize, filters) => {
    const token = localStorage.getItem('accessToken');
    const url = `${Urls.shelfLife}?page=${page}&page_size=${pageSize}&${filters}`;
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  getExportData: async (urlExport, filters) => {
    const token = localStorage.getItem('accessToken');
    const url = `${urlExport}?page_size=0&${filters}`;
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getQualityReportsByOrder: async (
    page,
    pageSize,
    workCenter,
    productionOrder,
    product,
    filters
  ) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.qualityReport}?page=${page}&page_size=${pageSize}&work_center__code=${workCenter}&production_order__code=${productionOrder}&product__code=${product}${filters}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getWorkCenterEvent: async (workCenter) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(`${Urls.event}?page_size=0&unfinished_events=${workCenter}`, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  getSameProductPerProductionOrder: async (productionOrder, product, process) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.productPerProductionOrders}?production_order__code=${productionOrder}&product__code=${product}&process__code=${process}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  getAlreadyExist: async (url, code) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(`${url}?code=${code}`, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  getTimeAccomplishment: async (url) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  getAccomplishmentByQuantity: async (
    startDate,
    endDate,
    temporality,
    productionOrder,
    product
  ) => {
    const token = localStorage.getItem('accessToken');
    return await axios.get(
      `${Urls.accomplishmentbyquantity}?start_date=${startDate}&end_date=${endDate}&temporality=${temporality}&production_order=${productionOrder}&product=${product}`,
      {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      }
    );
  },

  // POST requests
  postStartProductionOrder: async ({ id, startDate, workCenter }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      id,
      work_center: workCenter,
      start_date: startDate,
    };
    return await axios.post(Urls.startProductionOrder, body, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  postPauseProductionOrder: async ({
    id,
    workCenter,
    endDate,
    commentsReport,
    variables,
    quantityNonconforming,
    qualityDimension,
    reportedQuantity,
    evidenceFile,
    commentsQuality,
  }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      id,
      work_center: workCenter,
      comments_report: commentsReport,
      end_date: endDate,
      variables,
      quantity_nonconforming: quantityNonconforming,
      quality_dimension: qualityDimension,
      reported_quantity: reportedQuantity,
      evidence_file: evidenceFile,
      comments_quality: commentsQuality,
    };
    return await axios.post(Urls.pauseProductionOrder, body, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  postResumeProductionOrder: async ({ id, workCenter, startDate }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      id,
      work_center: workCenter,
      start_date: startDate,
    };
    return await axios.post(Urls.resumeProductionOrder, body, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  postEndProductionOrder: async ({
    id,
    workCenter,
    endDate,
    comments,
    variables,
    quantityNonconforming,
    qualityDimension,
    reportedQuantity,
    evidenceFile,
    commentsQuality,
  }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      id,
      work_center: workCenter,
      end_date: endDate,
      comments,
      variables,
      quantity_nonconforming: quantityNonconforming,
      quality_dimension: qualityDimension,
      reported_quantity: reportedQuantity,
      evidence_file: evidenceFile,
      comments_quality: commentsQuality,
    };
    return await axios.post(Urls.endProductionOrder, body, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  postCreatePartialReport: async ({
    id,
    workCenter,
    startDate,
    commentsReport,
    variables,
    quantityNonconforming,
    qualityDimension,
    reportedQuantity,
    evidenceFile,
    commentsQuality,
  }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      product_per_production_order: id,
      work_center: workCenter,
      start_date: startDate,
      comments_report: commentsReport,
      variables: variables,
      quantity_nonconforming: quantityNonconforming,
      quality_dimension: qualityDimension,
      reported_quantity: reportedQuantity,
      evidence_file: evidenceFile,
      comments_quality: commentsQuality,
    };
    return await axios.post(Urls.createPartialReport, body, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  postManualQuantities: async (body) => {
    const token = localStorage.getItem('accessToken');
    return await axios.post(Urls.manualQuantities, body, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  postQuality: async (body) => {
    const token = localStorage.getItem('accessToken');
    return await axios.post(Urls.qualityReport, body, {
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    });
  },

  postStartEvent: async ({
    id,
    workCenter,
    startDate,
    eventType,
    variables,
    quantityNonconforming,
    qualityDimension,
    reportedQuantity,
    evidenceFile,
    commentsQuality,
  }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      product_per_production_order: id,
      work_center: workCenter,
      start_date: startDate,
      event_type: eventType,
      variables: variables,
      quantity_nonconforming: quantityNonconforming,
      quality_dimension: qualityDimension,
      reported_quantity: reportedQuantity,
      evidence_file: evidenceFile,
      comments_quality: commentsQuality,
    };
    return axios.post(Urls.startEvent, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postFinishEvent: async ({ id, workCenter, endDate, variables, comments }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      product_per_production_order: id,
      work_center: workCenter,
      end_date: endDate,
      variables: variables,
      comments: comments,
    };
    return axios.post(Urls.endEvent, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postStartEventWithoutOrder: async ({ workCenter, eventType, startDate }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      work_center: workCenter,
      event_type: eventType,
      start_date: startDate,
    };
    return axios.post(Urls.startEventWithoutOrder, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postFinishEventWithoutOrder: async ({ workCenter, endDate, variables, comments }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      work_center: workCenter,
      end_date: endDate,
      variables: variables,
      comments: comments,
    };
    return axios.post(Urls.endEventWithoutOrder, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  postEndOrderAtAnyPoint: async ({ production_order }) => {
    const token = localStorage.getItem('accessToken');
    const body = {
      production_order: production_order,
    };
    return axios.post(Urls.endOrderAtAnyPoint, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  inventoryClient: () => {
    const token = localStorage.getItem('accessToken');
    return axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  industrialClient: () => {
    const token = localStorage.getItem('accessToken');
    return axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default apiService;
