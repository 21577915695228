import React from 'react';
import Urls from '../Urls';
import TableSection from './TableSection';

const EventReportTableSection = () => {
  return (
    <TableSection
      url={Urls.quantityRegister}
      formName="eventReport"
      title="Event Report"
      disableAdd
      disableDelete
      disableEdit
      columnToTransform={[]}
    />
  );
};

export default EventReportTableSection;
