import { Spinner } from 'react-bootstrap';

const NonconformingQuantity = ({ data, error, loading, label }) => {
  return (
    <div className="mb-2">
      <div className="label" style={{ marginTop: 0 }}>
        {label}
      </div>
      <div style={{ borderRadius: '5px', border: '1px solid #ced4da', padding: '6px 10px' }}>
        <div>
          {data && `${data?.variable || ''} (${data?.dimensional_unit}): `}
          {error && "Couldn't fetch variables from IoT"}
          {!loading ? (
            `${data.result}`
          ) : (
            <Spinner
              animation="border"
              role="status"
              size="sm"
              className="fpb-spinner-sm"
            ></Spinner>
          )}
        </div>
      </div>
    </div>
  );
};

export default NonconformingQuantity;
