import { React, useRef, useLayoutEffect } from 'react';
import { select, scaleLinear } from 'd3';

export const XLabel = ({ keys, xDomain, width, height }) => {
  const legendRef = useRef(null);

  useLayoutEffect(() => {
    const x = scaleLinear().domain(xDomain).range([0, width]);

    select(legendRef.current)
      .selectAll('text')
      .data(keys)
      .join('text')
      .attr('x', (_) => width / 2 - 70)
      .attr('fill', (_) => '#5e5e5e')
      .text((d) => d);
  }, [keys, width, xDomain]);

  return <g transform={`translate(40, ${height - 0})`} ref={legendRef} />;
};
