import { createSlice } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const timeAccomplishmentSlice = createSlice({
  name: 'timeAccomplishment',
  initialState: {
    data: {},
    reloadFlag: '',
    startDate: '',
    endDate: '',
    opList: [],
    shift: '',
    accomplishment: '',
  },
  reducers: {
    getTimeAccomplishment: (state, action) => {
      state.data = action.payload;
    },
    reloadData: (state, action) => {
      state.reloadFlag = action.payload;
    },
    startDateFilter: (state, action) => {
      state.startDate = action.payload;
    },
    endDateFilter: (state, action) => {
      state.endDate = action.payload;
    },
    opListFilter: (state, action) => {
      state.opList = action.payload;
    },
    accomplishmentFilter: (state, action) => {
      state.accomplishment = action.payload;
    },
    shiftFilter: (state, action) => {
      state.shift = action.payload;
    },
  },
});

export const getTimeAccomplishmentAsync = (end_date, op_list) => async (dispatch) => {
  try {
    const response = await apiService.getTimeAccomplishmentByFilters(end_date, op_list);
    dispatch(getTimeAccomplishment(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const {
  getTimeAccomplishment,
  reloadData,
  startDateFilter,
  endDateFilter,
  opListFilter,
  accomplishmentFilter,
  shiftFilter,
} = timeAccomplishmentSlice.actions;
export const showTimeAccomplishment = (state) => state.timeAccomplishment.data;
export default timeAccomplishmentSlice.reducer;
