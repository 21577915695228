import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup, Row, Card } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';

const RealtimeChart = ({ indicatorName, data, labels, title, subtitle, excel }) => {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [paginationCount, setPaginationCount] = useState(0);

  useEffect(() => {
    setSeries([
      {
        name: 'Realtime',
        data: data,
      },
    ]);
  }, [data]);

  useEffect(() => {
    setCategories(labels);
  }, [labels]);

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: false,
    },
    colors: ['#ff3c77', '#49158b', '#ff4f2b', '#08bfff', '#ffae16'],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
      },
      offsetY: -20,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subtitle,
      align: 'left',
      offsetY: 20,
      style: {
        color: '#333',
        fontSize: '12px',
      },
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
  };

  const [showTableData, setShowTableData] = useState(false);
  const handleCloseModal = () => {
    setShowTableData(false);
  };

  const [excelTableData, setExcelTableData] = useState([]);

  useEffect(() => {
    const tableData = [];
    if (data.length > 0 && labels) {
      for (let i = 0; i < labels?.length; i++) {
        tableData.push({
          storage_center: labels[i],
          value: data[i],
          unit: 'USD',
        });
      }
      setExcelTableData(tableData);
      setPaginationCount(labels.length);
    }
  }, [data]);

  return (
    <>
      <Card className="p-3 my-2">
        <SimpleTableModal
          title={title + subtitle}
          headers={['storage_center', 'value', 'unit']}
          tableData={excelTableData}
          show={showTableData}
          handleClose={handleCloseModal}
          totalCount={paginationCount}
        />
        <div>
          <Row className="flex-row-reverse">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle className="fpb-btn-chart" id="dropdown-download" />
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowTableData(true);
                  }}
                >
                  Show Data
                </Dropdown.Item>
                <ExportExcel
                  excelData={excel}
                  fileName={indicatorName + 'Inventory Report'}
                  indicator={title + subtitle}
                  filters={subtitle}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </div>
        <Chart
          options={JSON.parse(JSON.stringify(options))}
          series={JSON.parse(JSON.stringify(series))}
          height={350}
          type="bar"
        />
      </Card>
    </>
  );
};

export default RealtimeChart;
