import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import useForm from '../useForm';
import FormContainer from '../Forms/FormContainer';
import Urls from '../Urls';
import AlertMessage from '../AlertMessage/AlertMessage';
import mapTable from '../mapTableService';
import {
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  IntegratedSelection,
  CustomPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableFilterRow,
  PagingPanel,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

const TableModal = ({
  url,
  show,
  handleClose,
  handleConfirmation,
  title,
  addButton,
  formName,
  columnToTransform,
  customColumnOrder,
  setFieldValue,
  customFilter,
}) => {
  const [addForm, setAddForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [addedRow, setAddedRow] = useState({});
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [selected, setSelected] = useState({});
  const [columnsToHide, setColumnsToHide] = useState();
  const [token, setToken] = useState(localStorage.getItem('accessToken') || null);
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  // table
  const [sorting, setSorting] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [columnsWidth, setColumnsWidth] = useState([]);
  const [rows, setRows] = useState([]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [startingId, setStartingId] = useState(0);
  const [selection, setSelection] = useState([]);
  // pagination
  const [filters, setFilters] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes, setPageSizes] = useState([5, 10, 15]);
  const [totalCount, setTotalCount] = useState(0);
  // Filters options
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [currencyColumns] = useState([
    'amount',
    'n0_cost',
    'n0_units',
    'safety_stock',
    'maximum_stock',
    'minimum_stock',
    'ordering_point',
    'monetary_value_N0',
  ]);
  const [currencyFilterOperations] = useState(['equal', 'greaterThanOrEqual', 'lessThanOrEqual']);
  const [defaultFiltersOperations] = useState(['contains', 'equal']);

  useEffect(() => {
    switch (formName) {
      case 'item':
        setColumnsToHide([
          'item_type',
          'item_model',
          'barcode',
          'contained_pieces_N0',
          'contained_pieces_N1',
          'contained_pieces_N2',
          'contained_pieces_N3',
          'contained_pieces_N4',
          'consolidation_level_N0',
          'consolidation_level_N1',
          'consolidation_level_N2',
          'consolidation_level_N3',
          'consolidation_level_N4',
          'label',
          'item_image',
          'maximum_stock',
          'minimum_stock',
          'ordering_point',
          'safety_stock',
        ]);
        break;
      case 'storageCenter':
        setColumnsToHide([
          'country',
          'province',
          'address',
          'geocoordinate_latitude',
          'geocoordinate_longitude',
        ]);
        break;

      case 'storageLocation':
        setColumnsToHide(['coordinate_x', 'coordinate_y', 'coordinate_z']);
        break;

      case 'customer':
        setColumnsToHide([
          'commercial_name',
          'contact_person',
          'email',
          'telephone_number',
          'cellphone_number',
        ]);
        break;

      case 'supplier':
        setColumnsToHide([
          'commercial_name',
          'contact_person',
          'email',
          'telephone_number',
          'cellphone_number',
        ]);
        break;
      case 'batch':
        setColumnsToHide(['rated_life', 'extended_lifetime', 'responsible']);
        break;
      case 'itemBatchRelation':
        setColumnsToHide(['item_batch_relation_code']);
        break;
      default:
        setColumnsToHide(['', '', '']);
    }
  }, []);

  const { handleChange, values, setValues, handleAPIRequest, added } = useForm(
    url,
    setAddForm,
    setAlertMessage,
    setErrorMessage
  );

  useEffect(async () => {
    mapTable.createEntryOrderMap();
    mapTable.createDimensionalUnitDescriptionMap();
    mapTable.createDispatchOrderMap();
    mapTable.createAdjustmentMap();
    mapTable.createStorageCenterMap();
    mapTable.createStorageLocationMap();
  }, []);

  const getQueryString = () => {
    let filter = filters
      .reduce((acc, { columnName, operation, value }) => {
        const lowerCaseValue = value.toLowerCase();
        if (columnName.includes('consolidation_level')) {
          const dimensionalUnitId = mapTable.getDimensionalUnitIdFromCode(lowerCaseValue);
          acc.push(`${columnName}__id=${encodeURIComponent(dimensionalUnitId)}`);
        } else {
          switch (operation) {
            case 'contains': {
              acc.push(`${columnName}__icontains=${encodeURIComponent(value)}`);
              break;
            }
            case 'equal': {
              acc.push(`${columnName}=${encodeURIComponent(value)}`);
              break;
            }
            case 'greaterThanOrEqual': {
              acc.push(`${columnName}__gte=${encodeURIComponent(value)}`);
              break;
            }
            case 'lessThanOrEqual': {
              acc.push(`${columnName}__lte=${encodeURIComponent(value)}`);
              break;
            }
            default:
              acc.push(`${columnName}__${operation}=${encodeURIComponent(value)}`);
              break;
          }
        }
        return acc;
      }, [])
      .join('&');

    if (filters.length > 1) {
      filter = `${filter}`;
    }

    const urlT = customFilter
      ? `${url}?page=${currentPage + 1}&page_size=${pageSize}&${filter}&${customFilter}`
      : `${url}?page=${currentPage + 1}&page_size=${pageSize}&${filter}`;
    return urlT;
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [pageSize]);

  useEffect(() => {
    const queryString = getQueryString();
    const getTableData = () => {
      if (lastQuery !== queryString) {
        axios
          .get(queryString, {
            headers: {
              Authorization: `Bearer  ${token}`,
            },
          })
          .then(async (res) => {
            const { results } = res.data;
            setTotalCount(res.data.count);
            if (results.length > 0) {
              setIsFirstTime(false);
              // discard the fields the user will not visualize in tables
              const headersList = Object.keys(results[0]).filter((elem) => {
                let returnColumn;
                if (
                  elem === 'fk_info' ||
                  elem === 'id' ||
                  elem === 'created' ||
                  elem === 'updated' ||
                  elem === 'label' ||
                  elem === 'barcode'
                ) {
                  returnColumn = false;
                } else {
                  returnColumn = true;
                }
                return returnColumn;
              });
              const tableInformation = await Promise.all(
                results.map(async (row) => {
                  const transformedResponse = {};
                  // make a request for each id to be converted to code in the current row
                  const [rowModified] = await Promise.all(
                    columnToTransform.map(async (transform) => {
                      if (row[transform.name] != null) {
                        const transformed = await axios
                          .get(`${Urls[transform.url]}/${row[transform.name]}`, {
                            headers: { Authorization: `Bearer ${token}` },
                          })
                          .then((res) => {
                            transformedResponse[transform.name] = res.data[transform.field];
                            return transformedResponse;
                          });

                        return transformed;
                      }
                      transformedResponse[transform.name] = null;
                      return transformedResponse;
                    })
                  );

                  // use default row and replace transformed columns with codes
                  const result = { ...row };
                  columnToTransform.map((transform) => {
                    result[transform.name] =
                      rowModified != null ? rowModified[transform.name] : null;
                  });
                  return result;
                })
              );
              setHeaders(headersList);
              setTableData(tableInformation);
            } else {
              setIsFirstTime(true);
              setTableData([]);
              setHeaders([]);
            }
          })
          .catch((err) => {
            console.log('error: ', err);
          });
      }
      setAddForm(false);
      setLastQuery(queryString);
    };
    getTableData();
  }, [url, pageSize, currentPage, filters]);

  const onAdded = (r) => {
    setAddForm(false);
    setAddedRow(r);
  };

  const getRowId = (row) => row.id || row.code;
  // Configure column headers
  const columnsData = headers.map((headerName) => {
    const container = {};
    container.name = headerName;
    container.title = headerName.replaceAll('_', ' ').toUpperCase();
    return container;
  });

  useEffect(() => {
    if (tableData !== null && tableData.length > 0) {
      setRows(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    setStartingId(rows.length > 0 ? rows[rows.length - 1].id + 1 : 0);
  }, [rows]);

  useEffect(() => {
    if (columnsData.length > 0) {
      setDefaultColumns(headers);
      setColumns(columnsData);
      const resizeColumns = columnsData.map((c) => ({
        columnName: c.name,
        width: 200,
      }));
      const columnNames = columnsData.map((c) => c.name);
      setColumnsWidth(resizeColumns);
      setEditingRowIds([]);
      customColumnOrder ? setColumnOrder(customColumnOrder) : setColumnOrder(columnNames);
    }
  }, [headers]);

  // For column resizing
  useEffect(() => {
    if (columns.length === 0) {
      setTimeout(() => {
        setColumns(columnsData);
        const resizeColumns = columnsData.map((c) => ({
          columnName: c.name,
          width: 200,
        }));
        setColumnsWidth(resizeColumns);
      }, 1);
    }
  }, [columns]);

  function changeSelection(selection) {
    const lastSelected = selection[selection.length - 1];
    if (lastSelected !== undefined) {
      setSelection([lastSelected]);
      setSelected(
        rows.find((row) => (row.id ? row.id === lastSelected : row.code === lastSelected))
      );
      setDisableConfirmButton(false);
    } else {
      setSelection([]);
      setSelected();
      setDisableConfirmButton(true);
    }
  }

  const transformCode = (e, name) => {
    const insertedCode = e.target.value;
    columnToTransform.map((elem) => {
      elem.name === name
        ? axios
            .get(Urls[elem.url], {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              let transformedId;
              const Data = res.data;
              Data.map((row) => {
                row[elem.field] === insertedCode ? (transformedId = row.id) : null;
              });
              setValues({ ...values, [elem.name]: transformedId });
            })
        : null;
    });
  };

  return (
    <Modal show={show} onHide={handleClose} className="fpb-modal">
      <Modal.Header closeButton>
        <Modal.Title>{title.replaceAll('_', ' ').toUpperCase()}</Modal.Title>
        <div className="text-left">
          {addButton ? (
            <Button
              className="fpb-btn ml-4"
              onClick={() => {
                setAddForm(true);
              }}
              disabled={addForm}
            >
              Add
            </Button>
          ) : null}

          <Modal show={addForm} onHide={setAddForm} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add {title.replaceAll('_', ' ')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {errorMessage ? (
                <AlertMessage
                  variant={'danger'}
                  message={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
              ) : null}
              <FormContainer
                handleChange={handleChange}
                values={values}
                handleAPIRequest={handleAPIRequest}
                added={added}
                formName={formName}
                transformCode={transformCode}
                onAdded={onAdded}
                setAddForm={setAddForm}
                setAlert={setAlertMessage}
                setError={setErrorMessage}
              />
            </Modal.Body>
          </Modal>
          {alertMessage ? (
            <AlertMessage
              variant={'success'}
              message={alertMessage}
              setAlertMessage={setAlertMessage}
            />
          ) : null}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <FilteringState onFiltersChange={setFilters} />
            <IntegratedFiltering />
            <DataTypeProvider
              for={defaultColumns}
              availableFilterOperations={defaultFiltersOperations}
            />
            <DataTypeProvider
              for={currencyColumns}
              availableFilterOperations={currencyFilterOperations}
            />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <IntegratedSorting />
            <CustomPaging totalCount={totalCount} />
            <Table />
            <TableColumnResizing
              columnWidths={columnsWidth}
              onColumnWidthsChange={setColumnsWidth}
            />
            <TableHeaderRow />
            <SelectionState selection={selection} onSelectionChange={changeSelection} />
            <IntegratedSelection />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
            <PagingPanel pageSizes={pageSizes} />
            <TableFilterRow showFilterSelector />
          </Grid>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={disableConfirmButton}
          variant="warning"
          onClick={() => {
            setFieldValue
              ? handleConfirmation(selected, title, setFieldValue)
              : handleConfirmation(selected, title);
          }}
        >
          Confirm
        </Button>
        <Button
          variant="fpb-btn-inverse"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TableModal;
