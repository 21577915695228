import { React, useRef, useLayoutEffect } from 'react';
import { select, scaleLinear, axisLeft } from 'd3';

export const YAxis = ({ yDomain, tickCount, height }) => {
  const yAxisRef = useRef(null);

  useLayoutEffect(() => {
    const y = scaleLinear()
      .domain([0, yDomain[1] === 0 ? 1 : yDomain[1]])
      .range([height - 60, 50]);
    const axis = axisLeft;
    const yAxis = axis(y).ticks(tickCount).tickSizeOuter(0);

    select(yAxisRef.current)
      .call(yAxis)
      .attr('class', 'axisY')
      .style('font-size', '13px')
      .style('color', '#3b3c3c');

    select(yAxisRef.current).selectAll('line').style('color', 'gray');
  }, [height, tickCount, yDomain]);

  return <g transform="translate(70, 0)" ref={yAxisRef} />;
};
