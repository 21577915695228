import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import useForm from '../useForm';
import AlertMessage from '../AlertMessage/AlertMessage';
import FormContainer from '../Forms/FormContainer';
import Urls from '../Urls';
import {
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  PagingState,
  SelectionState,
  IntegratedSelection,
  IntegratedPaging,
  CustomPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableFilterRow,
  PagingPanel,
  TableSelection,
  TableColumnReordering,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import { useDispatch } from 'react-redux';
import {
  setModalPageSizeAction,
  setModalCurrentPageAction,
} from '../../redux/actions/paginationAction';
import apiService from '../apiService';

const HighlightedCell = ({ value, style, ...restProps }) => (
  <Table.Cell
    {...restProps}
    style={{
      backgroundColor:
        value == 'started'
          ? '#6cffa7'
          : value == 'paused'
          ? '#ffae16'
          : value == 'closed'
          ? '#ced4da'
          : undefined,
      ...style,
    }}
  >
    <span style={{ fontWeight: 600 }}>{value}</span>
  </Table.Cell>
);

const Cell = (props) => {
  const { column } = props;
  if (column.name === 'production_status') {
    return <HighlightedCell {...props} />;
  }
  return <Table.Cell {...props} />;
};

const SimpleTableModal = ({
  show,
  handleClose,
  handleSelectedOrder,
  headers,
  tableData,
  title,
  addButton,
  confirmButton,
  formName,
  columnsToHide,
  columnToTransform,
  url,
  setFilters,
  customColumnOrder,
  setEntryTransaction,
  setDispatchTransaction,
  totalCount,
  modalCurrentPage,
  pageSize,
  code,
  description,
}) => {
  const [addForm, setAddForm] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [addedRow, setAddedRow] = useState({});
  const [selected, setSelected] = useState({});

  // table
  const [sorting, setSorting] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [columnsWidth, setColumnsWidth] = useState([]);
  const [rows, setRows] = useState([]);
  const pageSizes = [5, 10, 15];
  const [tableColumnExtensions] = useState(columnsWidth);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [startingId, setStartingId] = useState(0);
  const [selection, setSelection] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(columnsToHide || []);
  const industrialClient = apiService.industrialClient();

  const { handleChange, values, setValues, handleAPIRequest, added } = useForm(
    url,
    setAddForm,
    setAlertMessage,
    setErrorMessage
  );

  const onAdded = (r) => {
    setAddForm(false);
    setAddedRow(r);
  };

  const getRowId = (row) => row.id;
  // Configure column headers
  const columnsData = headers?.map((headerName) => {
    const container = {};
    container.name = headerName;
    container.title = headerName.replaceAll('_', ' ').toUpperCase();
    return container;
  });

  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      setRows(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    setStartingId(rows.length > 0 ? rows[rows.length - 1].id + 1 : 0);
  }, [rows]);

  useEffect(() => {
    if (columnsData?.length > 0) {
      setColumns(columnsData);
      const resizeColumns = columnsData.map((c) => ({
        columnName: c.name,
        width: 200,
      }));
      const columnNames = columnsData.map((c) => c.name);
      setColumnsWidth(resizeColumns);
      setEditingRowIds([]);
      customColumnOrder ? setColumnOrder(customColumnOrder) : setColumnOrder(columnNames);
    }
  }, [headers]);

  // columnsToHide contains the names of columns we don't show by default in the current table
  useEffect(() => {
    columnsToHide ? setHiddenColumnNames(columnsToHide) : setHiddenColumnNames([]);
  }, [columnsToHide]);

  // For column resizing
  useEffect(() => {
    if (columns?.length === 0) {
      setTimeout(() => {
        setColumns(columnsData);
        const resizeColumns = columnsData?.map((c) => ({
          columnName: c.name,
          width: 200,
        }));
        setColumnsWidth(resizeColumns);
      }, 1);
    }
  }, [columns]);

  function changeSelection(selection) {
    const lastSelected = selection[selection.length - 1];
    if (lastSelected !== undefined) {
      setSelection([lastSelected]);
      setSelected(rows.find((row) => row.id === lastSelected));
    } else {
      setSelection([]);
      setSelected();
    }
  }

  const transformCode = (e, name) => {
    const insertedCode = e.target.value;
    columnToTransform.map((elem) => {
      elem.name === name
        ? industrialClient.get(Urls[elem.url], {}).then((res) => {
            let transformedId;
            const Data = res.data;
            Data.map((row) => {
              row[elem.field] === insertedCode ? (transformedId = row.id) : null;
            });
            setValues({ ...values, [elem.name]: transformedId });
          })
        : null;
    });
  };

  const dispatch = useDispatch();

  const setPageSize = (pageSize) => {
    dispatch(setModalPageSizeAction(pageSize));
    dispatch(setModalCurrentPageAction(0));
  };
  const setCurrentPage = (currentPage) => {
    dispatch(setModalCurrentPageAction(currentPage));
  };

  return (
    <Modal show={show} onHide={handleClose} className="fpb-modal">
      <Modal.Header closeButton>
        {code ? (
          <Modal.Title>
            Assign {title.replaceAll('_', ' ')} to {description}
          </Modal.Title>
        ) : (
          <Modal.Title>{title.replaceAll('_', ' ').toUpperCase()}</Modal.Title>
        )}
        <div className="text-left">
          {addButton ? (
            <Button
              className="fpb-btn ml-4"
              onClick={() => {
                setAddForm(true);
              }}
              disabled={addForm}
            >
              Add
            </Button>
          ) : null}

          <Modal show={addForm} onHide={setAddForm} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add {title.replaceAll('_', ' ')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {errorMessage ? (
                <AlertMessage
                  variant={'danger'}
                  message={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
              ) : null}
              <FormContainer
                handleChange={handleChange}
                values={values}
                handleAPIRequest={handleAPIRequest}
                added={added}
                formName={formName}
                transformCode={transformCode}
                onAdded={onAdded}
                setAddForm={setAddForm}
                setAlert={setAlertMessage}
                setError={setErrorMessage}
                handleSelectedOrder={handleSelectedOrder}
                setEntryTransaction={setEntryTransaction}
                setDispatchTransaction={setDispatchTransaction}
                title={title}
              />
            </Modal.Body>
          </Modal>
        </div>
      </Modal.Header>

      <Modal.Body>
        {alertMessage ? (
          <AlertMessage
            variant={'success'}
            message={alertMessage}
            setAlertMessage={setAlertMessage}
          />
        ) : null}

        <div className="card">
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <PagingState
              currentPage={modalCurrentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <FilteringState onFiltersChange={setFilters} />
            <IntegratedFiltering />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <IntegratedSorting />
            {code ? (
              <CustomPaging totalCount={totalCount} />
            ) : (
              <IntegratedPaging totalCount={totalCount} />
            )}
            <SelectionState selection={selection} onSelectionChange={changeSelection} />
            <Table columnExtensions={tableColumnExtensions} cellComponent={Cell} />
            <TableColumnResizing
              columnWidths={columnsWidth}
              onColumnWidthsChange={setColumnsWidth}
            />
            <TableHeaderRow />
            <IntegratedSelection />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
            <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
            <PagingPanel pageSizes={pageSizes} />
            <TableFilterRow />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />
            <Toolbar />
            <ColumnChooser />
          </Grid>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {confirmButton ? (
          <Button
            disabled={selected ? false : true}
            className="fpb-btn"
            onClick={() => {
              handleSelectedOrder(selected, title);
              if (title === 'entry_orders') {
                setEntryTransaction('yes');
                setDispatchTransaction('no');
              } else if (title === 'dispatch_orders') {
                setEntryTransaction('no');
                setDispatchTransaction('yes');
              }
            }}
          >
            Confirm
          </Button>
        ) : null}
        <Button
          className="fpb-btn-inverse"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SimpleTableModal;
