import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as IoIcons5 from 'react-icons/io5';
import * as RiIcons from 'react-icons/ri';
import { HiDocumentReport } from 'react-icons/hi';

export const InventorySidebarData = [
  {
    title: 'Home',
    path: '/inventory/home',
    icon: <AiIcons.AiFillHome />,
  },
  {
    title: 'Indicators',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Dashboard',
        icon: <FaIcons.FaTable />,
        cName: 'sub-nav',
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subsubmenu: [
          {
            title: 'Real Time Dashboard',
            path: '/indicators/real_time_dashboard',
            icon: <IoIcons5.IoBarChart />,
          },
          {
            title: 'Historical Dashboard',
            path: '/indicators/dashboard',
            icon: <IoIcons5.IoBarChart />,
          },
          {
            title: 'Advanced Filters & Data Analysis',
            path: '/indicators/advanced_filters',
            icon: <FaIcons.FaFilter />,
          },
          {
            title: 'Shelf Life',
            path: '/indicators/shelf_life',
            icon: <FaIcons.FaTable />,
          },
        ],
      },
      // This menus will be temporarily hidden
      // {
      //   title: 'Stock Per Batch',
      //   path: '/indicators/stock_per_batch',
      //   icon: <FaIcons.FaArrowAltCircleLeft />,
      //   cName: 'sub-nav',
      // },
      // {
      //   title: 'Stock Per Storage Center',
      //   path: '/indicators/stock_per_storage_center',
      //   icon: <FaIcons.FaArrowAltCircleLeft />,
      //   cName: 'sub-nav',
      // },
    ],
  },
  {
    title: 'Transactions',
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Entry',
        path: '/transactions/entry',
        icon: <FaIcons.FaArrowAltCircleRight />,
        cName: 'sub-nav',
      },
      {
        title: 'Output',
        path: '/transactions/output',
        icon: <FaIcons.FaArrowAltCircleLeft />,
        cName: 'sub-nav',
      },
      {
        title: 'Adjustment',
        path: '/transactions/adjustment',
        icon: <AiIcons.AiFillControl />,
      },
      {
        title: 'Transfer',
        path: '/transactions/transfer',
        icon: <FaIcons.FaArrowsAltH />,
      },
      {
        title: 'Approve Return',
        path: '/transactions/return',
        icon: <FaIcons.FaCheckCircle />,
      },
      {
        title: 'Registered',
        path: '/transactions/registered',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Associate orders',
        path: '/transactions/associate_orders',
        icon: <FaIcons.FaLink />,
      },
    ],
  },
  {
    title: 'QR Generation',
    path: '/qr',
    icon: <AiIcons.AiOutlineQrcode />,
  },

  {
    title: 'Operational Tables',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    icon: <FaIcons.FaTable />,
    subNav: [
      {
        title: 'Entry Order',
        path: '/inventory/entry_order',
        icon: <FaIcons.FaArrowAltCircleRight />,
        cName: 'sub-nav',
      },
      {
        title: 'Dispatch Order',
        path: '/inventory/dispatch_order',
        icon: <FaIcons.FaArrowAltCircleLeft />,
        cName: 'sub-nav',
      },
      {
        title: 'Batch',
        path: '/inventory/batch',
        icon: <FaIcons.FaArrowAltCircleRight />,
        cName: 'sub-nav',
      },
      {
        title: 'Item Batch Relation',
        path: '/inventory/item_batch_relation',
        icon: <FaIcons.FaArrowAltCircleLeft />,
        cName: 'sub-nav',
      },
      {
        title: 'Item Storage Center Relation',
        path: '/inventory/item_storagecenter_relation',
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        icon: <FaIcons.FaTable />,
      },
    ],
  },
  {
    title: 'Main Tables',
    icon: <FaIcons.FaTable />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Item',
        path: '/item',
        icon: <FaIcons.FaTable />,
        cName: 'sub-nav',
      },
      {
        title: 'Storage Center',
        path: '/storage_center',
        icon: <FaIcons.FaTable />,
        cName: 'sub-nav',
      },
      {
        title: 'Storage Location',
        path: '/storage_location',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Item Category',
        path: '/item_category',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Cost',
        path: '/cost',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Cost Type',
        path: '/cost_type',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Dimensional Unit',
        path: '/dimensional_unit',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Supplier',
        path: '/supplier',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Customer',
        path: '/customer',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Adjustment',
        path: '/adjustment',
        icon: <FaIcons.FaTable />,
      },
    ],
  },

  {
    title: 'Upload Files',
    path: '/files',
    icon: <FaIcons.FaFileUpload />,
  },

  {
    title: 'Support',
    path: '/support',
    icon: <IoIcons.IoMdHelpCircle />,
  },
];

export const IndustrialSidebarData = [
  {
    title: 'Process Control',
    path: '/industrial/process_control',
    icon: <FaIcons.FaPallet />,
  },
  {
    title: 'Production Order',
    path: '/industrial/production_order',
    icon: <FaIcons.FaClipboardList />,
  },
  {
    title: 'Shift Assignment',
    path: '/industrial/shift_assignment',
    icon: <FaIcons.FaCalendarAlt />,
  },
  {
    title: 'Main Tables',
    icon: <FaIcons.FaTable />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Product',
        path: '/industrial/product',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Unit Tranformation',
        path: '/industrial/unit_transformation',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Work Center',
        path: '/industrial/work_center',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Work Center Capacity',
        path: '/industrial/work_center_capacity',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Event Category',
        path: '/industrial/event_category',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Product Category',
        path: '/industrial/product_category',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Work Center Category',
        path: '/industrial/work_center_category',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Dimensional Unit',
        path: '/industrial/dimensional_unit',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Event Type',
        path: '/industrial/event_type',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Operation Center',
        path: '/industrial/operation_center',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Quality Dimension',
        path: '/industrial/quality_dimension',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Variable',
        path: '/industrial/variable',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Shift',
        path: '/industrial/shift',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Process',
        path: '/industrial/process',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Route',
        path: '/industrial/route',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Work Center Per Process',
        path: '/industrial/workCenterPerProcess',
        icon: <FaIcons.FaTable />,
      },
    ],
  },
  {
    title: 'Operational Tables',
    icon: <FaIcons.FaTable />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Production Batch',
        path: '/industrial/production_batch',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Event Report',
        path: '/industrial/event_report',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Manual Report',
        path: '/industrial/manual_report',
        icon: <FaIcons.FaTable />,
      },
      {
        title: 'Quality Report',
        path: '/industrial/quality_report',
        icon: <FaIcons.FaTable />,
      },
    ],
  },
  {
    title: 'Indicators',
    icon: <FaIcons.FaTable />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Time Analysis',
        path: '/industrial/time_analysis',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'Produced Units',
        path: '/industrial/produced_units',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'IoT Process Control',
        path: '/industrial/iot_process_control',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'Manual Process Control',
        path: '/industrial/manual_process_control',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'Availability',
        path: '/industrial/availability',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'Performance',
        path: '/industrial/performance',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'Quality',
        path: '/industrial/oee_quality',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'OEE Control',
        path: '/industrial/oee_control',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'Accomplishment in Time',
        path: '/industrial/accomplishment_in_time',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'Accomplishment By Quantity',
        path: '/industrial/accomplishment_by_quantity',
        icon: <IoIcons5.IoBarChart />,
      },
      {
        title: 'Order Traceability',
        path: '/industrial/order_traceability',
        icon: <HiDocumentReport />,
      },
    ],
  },
  {
    title: 'Upload Files',
    path: '/industrial/files',
    icon: <FaIcons.FaFileUpload />,
  },
];

export const AuthSidebarData = [
  {
    title: 'Companies',
    path: '/administration/company',
    icon: <FaIcons.FaBuilding />,
  },
  {
    title: 'Users',
    path: '/administration/user',
    icon: <FaIcons.FaUserFriends />,
  },
  {
    title: 'Modules',
    path: '/administration/module',
    icon: <FaIcons.FaUserFriends />,
  },
  {
    title: 'Company & Modules',
    path: '/administration/company_module',
    icon: <FaIcons.FaUserFriends />,
  },
  {
    title: 'Group & Permission',
    path: '/administration/group_permission',
    icon: <FaIcons.FaUserFriends />,
  },
  {
    title: 'User & Group',
    path: '/administration/usergroup_permission',
    icon: <FaIcons.FaUserFriends />,
  },
];
