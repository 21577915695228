import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const getPerformanceAsync = createAsyncThunk(
  'performance/getPerformanceAsync',
  async ({ startDate, endDate, temporality, product, shift, workCenterList }) => {
    try {
      const res = await apiService.getPerformanceByFilters(
        startDate,
        endDate,
        temporality,
        product,
        shift,
        workCenterList
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const performanceSlice = createSlice({
  name: 'performance',
  initialState: {
    data: [],
    dataFromOee: [],
    loading: false,
    error: null,
    progress: { pending: true, current: 0, total: 0, percent: 0 },
    status: 'idle',
    workCenterList: [],
    filters: [],
  },
  reducers: {
    getPerformance: (state, action) => {
      state.data = action.payload;
    },
    setDataPerformance: (state, action) => {
      state.data = action.payload;
    },
    setProgressPerformance: (state, action) => {
      if (action.payload.current >= state.progress?.current) {
        state.progress = action.payload;
      } else {
        state.progress = state.progress;
      }
    },
    setStatusPerformance: (state, action) => {
      state.status = action.payload;
      if (action.payload === 'SUCCESS') {
        state.progress = { ...state.progress, current: 0, percent: 100 };
      }
    },
    setDataPerformanceOee: (state, action) => {
      state.dataFromOee = action.payload;
    },
    setPerformanceWorkCenterList: (state, action) => {
      state.workCenterList = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPerformanceAsync.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getPerformanceAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPerformanceAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const {
  getPerformance,
  setDataPerformance,
  setProgressPerformance,
  setStatusPerformance,
  setDataPerformanceOee,
  setPerformanceWorkCenterList,
  setFilters,
} = performanceSlice.actions;
export const showPerformance = (state) => state.performance.data;
export const performanceLoadingState = (state) => state.performance.loading;
export const performanceErrorState = (state) => state.performance.error;

export default performanceSlice.reducer;
