import { Button } from 'react-bootstrap';
import { FaPlay } from 'react-icons/fa';
const PlayButton = ({ label, handleStart }) => {
  return (
    <div className="d-grid gap-2">
      <Button
        variant="primary"
        className="btn-block fpb-btn-purple"
        size="lg"
        style={{ height: '100px' }}
        onClick={handleStart}
      >
        <FaPlay className="mr-3" />
        <span className="text-center">{label}</span>
      </Button>
    </div>
  );
};

export default PlayButton;
