import { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';

const ProgressBarWithText = ({ progress, label, text }) => {
  const [fadeProp, setFadeProp] = useState({
    fade: 'fade-in',
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (fadeProp.fade === 'fade-in') {
        setFadeProp({
          fade: 'fade-out',
        });
      } else {
        setFadeProp({
          fade: 'fade-in',
        });
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [fadeProp]);

  return (
    <div className="text-center">
      <ProgressBar now={progress} label={`${label}%`} className="mt-5 fpb-progress-bar" animated />
      <div className="mt-2">
        <h4 className={fadeProp.fade}>{text}</h4>
      </div>
    </div>
  );
};

export default ProgressBarWithText;
