import React from 'react';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import ProducedTotal from '../IndustrialGraph/ProducedTotal';
import ProducedTimes from '../IndustrialGraph/ProducedTimes';
import ProducedPercentages from '../IndustrialGraph/ProducedPercentages';
import FiltersProducedUnits from '../FiltersProducedUnits';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useSelector } from 'react-redux';
import { ProgressBarWithText } from '../../Loading';
import { Row } from 'react-bootstrap';
import NonConforming from '../IndustrialGraph/NonConformingChart';
import QualityDimensionsQuantityChart from '../IndustrialGraph/QualityDimensionsQuantityChart';
import QualityDimensionsPercentagesChart from '../IndustrialGraph/QualityDimensionPercentagesChart';

const ProducedUnits = () => {
  const indicatorName = 'Produced Units';
  const progress = useSelector((state) => state.producedUnits.progress);
  const queryStatus = useSelector((state) => state.producedUnits.status);

  return (
    <>
      <HeaderTitle title={indicatorName} />
      <FiltersProducedUnits indicatorName={indicatorName} />
      {queryStatus === 'PROGRESS' ? (
        <Row className="justify-content-center" style={{ 'margin-top': '100px' }}>
          <ProgressBarWithText
            progress={progress.current}
            label={`${progress.current}`}
            text={"We're working on your request"}
          />
        </Row>
      ) : (
        <>
          <div className="pr-4 pt-4 pb-2">
            <ProducedTotal indicatorName={indicatorName} />
          </div>
          <div className="pr-4 py-2">
            <ProducedTimes indicatorName={indicatorName} />
          </div>
          <div className="pr-4 pt-2 pb-4">
            <ProducedPercentages indicatorName={indicatorName} />
          </div>
          <div className="pr-4 pt-2 pb-4">
            <NonConforming indicatorName={indicatorName} />
          </div>
          <div className="pr-4 pt-2 pb-4">
            <QualityDimensionsQuantityChart indicatorName={indicatorName} />
          </div>
          <div className="pr-4 pt-2 pb-4">
            <QualityDimensionsPercentagesChart indicatorName={indicatorName} />
          </div>
        </>
      )}
    </>
  );
};

export default ProducedUnits;
