import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';
import Urls from '../../components/Urls';
import axios from 'axios';

const ResendMailConfirmation = (valueEmail) => {
  const resend = Urls.resend_mail;

  const axiosResendMail = async (userEmail) => {
    const result = await axios
      .post(`${resend}`, userEmail)
      .then((res) => {
        console.log(res.data.status);
        if (res.data.status === 'success') {
          notification('Mail sent successfully', 'success');
        }
        if (res.data.status === 'error') {
          notification('Error sending mail', 'error');
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
    return result;
  };

  const resendMail = async () => {
    try {
      let res;
      res = await axiosResendMail(valueEmail);
    } catch (error) {
      console.log(error);
    }
  };
  resendMail();
};

export default ResendMailConfirmation;
