import { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Form } from 'react-bootstrap';
import Urls from '../Urls';
import axios from 'axios';
import { FaTable } from 'react-icons/fa';
import TableModal from '../TableModal/TableModal';

const ParentCategorySelection = ({ onChange, value, ...props }) => {
  const [token] = useState(localStorage.getItem('accessToken'));
  const url = Urls.itemCategory;
  const [showItemCategoryModal, setShowItemCategoryModal] = useState(false);
  const columnToTransform = [
    {
      name: 'parent_category',
      url: 'itemCategory',
      field: 'item_category_code',
    },
  ];

  const getUpdatedItemCategory = async (inputValue, loadedOptions, { page }) => {
    const query = `${url}?page=${page}&page_size=${10}&only_parent&item_category_code__icontains=${inputValue}`;
    let itemCategories = [];
    if (page === 1) {
      itemCategories.push({
        value: 'true',
        label: 'None',
      });
      itemCategories.push({
        value: 'false',
        label: 'Not None',
      });
    }
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            itemCategories.push({
              value: result.item_category_code,
              label: result.item_category_code,
              description: result.description,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return {
      options: itemCategories,
      hasMore: itemCategories.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleModalConfirm = (selected, title) => {
    const e = {
      value: selected.item_category_code,
      label: selected.item_category_code,
      description: selected.description,
    };
    onChange(e);
    setShowItemCategoryModal(false);
  };

  return (
    <>
      {showItemCategoryModal ? (
        <TableModal
          url={url}
          title="Item Parent Category"
          show={showItemCategoryModal}
          handleClose={() => {
            setShowItemCategoryModal(false);
          }}
          formName={'itemCategory'}
          columnToTransform={columnToTransform}
          customFilter={'only_parent'}
          handleConfirmation={handleModalConfirm}
        />
      ) : null}
      <Form.Label>
        {props.label || 'Item Parent Category'}
        <FaTable
          className="icon-search ml-2"
          onClick={() => {
            setShowItemCategoryModal(true);
          }}
          data-testid="icon-search"
        />
      </Form.Label>
      <AsyncPaginate
        {...props}
        isClearable={true}
        defaultOptions
        value={value}
        loadOptions={getUpdatedItemCategory}
        onChange={onChange}
        additional={{
          page: 1,
        }}
      />
    </>
  );
};

export default ParentCategorySelection;
