import { useState, useEffect } from 'react';
import {
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableFilterRow,
  PagingPanel,
  DragDropProvider,
  TableColumnReordering,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

const FPBTableSimple = ({
  tableHeaders,
  tableData,
  customColumnOrder,
  columnsToHide,
  defaultPageSize = 10,
  pageSizes = [5, 10, 15],
}) => {
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [columnWidths, setColumnWidths] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(columnsToHide);
  const [columnOrder, setColumnOrder] = useState([]);
  // Filters options
  const [currencyColumns] = useState([
    'amount',
    'n0_cost',
    'n0_units',
    'safety_stock',
    'maximum_stock',
    'minimum_stock',
    'ordering_point',
    'monetary_value_N0',
    'cost',
    'stock_level_n0',
  ]);
  const [currencyFilterOperations] = useState([
    'equal',
    'greaterThanOrEqual',
    'greaterThan',
    'lessThanOrEqual',
    'lessThan',
  ]);

  const columnsData = tableHeaders.map((headerName) => {
    const container = {};
    container.name = headerName;
    container.title = headerName.replaceAll('_', ' ').toUpperCase();
    return container;
  });

  useEffect(() => {
    if (columnsData.length > 0) {
      setCols(columnsData);
      const resizeColumns = columnsData.map((c) => ({
        columnName: c.name,
        width: 200,
      }));
      const columnNames = columnsData.map((c) => c.name);
      setColumnWidths(resizeColumns);
      customColumnOrder ? setColumnOrder(customColumnOrder) : setColumnOrder(columnNames);
    }
  }, [tableHeaders]);

  // columnsToHide contains the names of columns we don't show by default in the current table
  useEffect(() => {
    setHiddenColumnNames(columnsToHide);
  }, [columnsToHide]);

  // For column resizing
  useEffect(() => {
    if (cols.length === 0) {
      setTimeout(() => {
        setCols(columnsData);
        const resizeColumns = columnsData.map((c) => ({
          columnName: c.name,
          width: 200,
        }));
        setColumnWidths(resizeColumns);
      }, 1);
    }
  }, [cols]);

  useEffect(() => {
    if (tableData !== null && tableData.length > 0) {
      setRows(tableData);
    } else {
      setRows([]);
    }
  }, [tableData]);

  return (
    <div className="card">
      <Grid rows={rows} columns={cols}>
        <PagingState defaultCurrentPage={0} defaultPageSize={defaultPageSize} />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <IntegratedPaging />
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />
        <DragDropProvider />
        <Table />
        <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <Toolbar />
        <ColumnChooser />
        <TableHeaderRow />
        <DataTypeProvider
          for={currencyColumns}
          availableFilterOperations={currencyFilterOperations}
        />
        <PagingPanel pageSizes={pageSizes} />
        <TableFilterRow showFilterSelector />
        <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
      </Grid>
    </div>
  );
};

export default FPBTableSimple;
