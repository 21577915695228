import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
// import { AgGridReact } from 'ag-grid-react';
import * as FileSaver from 'file-saver'; //
import * as XLSX from 'xlsx'; //
// import logo from '../CoreComponents/LogoFPB/logo_color_small.png';

// dropdown option to download data as xlsx with header info
const ExportDropdown = ({ excelData, fileName, indicator, filters }) => {
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const formatDate = (date) => {
    let splitDate = [];
    splitDate = date.split('T');
    splitDate[1] = splitDate[1].split('.', 1);
    let newDate = '';
    return newDate.concat(splitDate[0], ', ', splitDate[1]);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const header_info = [
      ['Wiibiq'],
      ['Client: ' + userProfile.company],
      ['User: ' + userProfile.username],
      ['Download Date: ' + new Date()],
      ['Indicator: ' + indicator],
      [filters],
    ];
    const ws_info = XLSX.utils.aoa_to_sheet(header_info);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    XLSX.utils.book_append_sheet(wb, ws_info, 'information');
    ws['!cols'] = [{ wch: 10 }];
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Dropdown.Item onClick={() => exportToExcel()}> Export Data </Dropdown.Item>
    </>
  );
};

export default ExportDropdown;
