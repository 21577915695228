import Data from './dataService';

const entryOrderMap = new Map();
const dispatchOrderMap = new Map();
const dimensionalUnitMap = new Map();
const dimensionalUnitDescriptionMap = new Map();
const adjustmentMap = new Map();
const storageCenterMap = new Map();
const storageCenterDescriptionMap = new Map();
const storageLocationMap = new Map();
const supplierMap = new Map();
const customerMap = new Map();
const itemMap = new Map();
const itemCategoryMap = new Map();
const itemDescriptionMap = new Map();
const batchMap = new Map();
const costMap = new Map();
const costTypeMap = new Map();
const companyMap = new Map();
const userMap = new Map();
const moduleMap = new Map();
const companyModuleMap = new Map();
const groupPermission = new Map();
const userGroupPermission = new Map();

const mapTable = {
  createEntryOrderMap: async () => {
    const entryOrders = await Data.getEntryOrders();
    entryOrders.map((el) => {
      entryOrderMap.set(el.entry_order_code.toLowerCase(), el.id);
    });
  },

  createDispatchOrderMap: async () => {
    const dispatchOrders = await Data.getDispatchOrders();
    dispatchOrders.map((el) => {
      dispatchOrderMap.set(el.dispatch_order_code.toLowerCase(), el.id);
    });
  },
  createDimensionalUnitMap: async () => {
    const dimensionalUnits = await Data.getDimensionalUnits();
    dimensionalUnits.map((el) => {
      dimensionalUnitMap.set(el.dimensional_unit_code.toLowerCase(), el.id);
    });
  },
  createDimensionalUnitDescriptionMap: async () => {
    const dimensionalUnits = await Data.getDimensionalUnits();
    dimensionalUnits.map((el) => {
      dimensionalUnitDescriptionMap.set(el.description.toLowerCase(), el.id);
    });
  },

  createAdjustmentMap: async () => {
    const adjustments = await Data.getAdjustments();
    adjustments.map((el) => {
      adjustmentMap.set(el.adjustment_code.toLowerCase(), el.id);
    });
  },

  createStorageCenterMap: async () => {
    const storageCenters = await Data.getStorageCenters();
    storageCenters.map((el) => {
      storageCenterMap.set(el.storage_center_code.toLowerCase(), el.id);
    });
  },

  createStorageCenterDescriptionCenterMap: async () => {
    const storageCenters = await Data.getStorageCenters();
    storageCenters.map((el) => {
      storageCenterDescriptionMap.set(el.description.toLowerCase(), el.id);
    });
  },

  createStorageLocationMap: async () => {
    const storageLocations = await Data.getStorageLocations();
    storageLocations.map((el) => {
      storageLocationMap.set(el.description.toLowerCase(), el.id);
    });
  },

  createSupplierMap: async () => {
    const suppliers = await Data.getSuppliers();
    suppliers.map((el) => {
      supplierMap.set(el.supplier_identification.toLowerCase(), el.id);
    });
  },

  createCustomerMap: async () => {
    const customers = await Data.getCustomers();
    customers.map((el) => {
      customerMap.set(el.customer_identification.toLowerCase(), el.id);
    });
  },

  createItemMap: async () => {
    const items = await Data.getItems();
    items.map((el) => {
      itemMap.set(el.reference_code.toLowerCase(), el.id);
    });
  },

  createItemCategoryMap: async () => {
    const items = await Data.getItemCategories();
    items.map((el) => {
      itemCategoryMap.set(el.item_category_code.toLowerCase(), el.id);
    });
  },

  createItemDescription: async () => {
    const items = await Data.getItems();
    items.map((el) => {
      itemDescriptionMap.set(el.item_description.toLowerCase(), el.id);
    });
  },

  createCostMap: async () => {
    const costs = await Data.getCosts();
    costs.map((el) => {
      costMap.set(el.unique_unit_cost_code.toLowerCase(), el.id);
    });
  },
  createCostTypeMap: async () => {
    const costTypes = await Data.getCostTypes();
    costTypes.map((el) => {
      costTypeMap.set(el.unique_cost_type_code.toLowerCase(), el.id);
    });
  },

  createBatchMap: async () => {
    const batches = await Data.getBatches();
    batches.map((el) => {
      batchMap.set(el.batch_code.toLowerCase(), el.id);
    });
  },
  createCompanyMap: async () => {
    const companies = await Data.getItemCompanies();
    companies.map((el) => {
      companyMap.set(el.name.toLowerCase(), el.id);
    });
  },
  createUserMap: async () => {
    const users = await Data.getItemUsers();
    users.map((el) => {
      userMap.set(el.username.toLowerCase(), el.id);
    });
  },
  createModuleMap: async () => {
    const modules = await Data.getItemModules();
    modules.map((el) => {
      moduleMap.set(el.name.toLowerCase(), el.id);
    });
  },
  createCompanyModuleMap: async () => {
    const companyModules = await Data.getItemModules();
    companyModules.map((el) => {
      companyModuleMap.set(el.name.toLowerCase(), el.id);
    });
  },
  createGroupPermission: async () => {
    const groupPermission = await Data.getGroupPermission();
    groupPermission.map((el) => {
      groupPermission.set(el.name.toLowerCase(), el.id);
    });
  },
  createUserGroupPermission: async () => {
    const userGroupPermission = await Data.getUserGroupPermission();
    userGroupPermission.map((el) => {
      userGroupPermission.set(el.name.toLowerCase(), el.id);
    });
  },
  getUserGroupPermissionIdFromCode: (code) => {
    const id = userGroupPermission.get(code);
    return id;
  },
  getGroupPermissionIdFromCode: (code) => {
    const id = groupPermission.get(code);
    return id;
  },
  getModuleIdFromCode: (code) => {
    const id = moduleMap.get(code);
    return id;
  },
  getUserIdFromCode: (code) => {
    const id = userMap.get(code);
    return id;
  },
  getCompanyIdFromCode: (code) => {
    const id = companyMap.get(code);
    return id;
  },
  getOrderEntryIdFromCode: (code) => {
    const id = entryOrderMap.get(code);
    return id;
  },
  getOrderDispatchIdFromCode: (code) => {
    const id = dispatchOrderMap.get(code);
    return id;
  },
  getDimensionalUnitIdFromCode: (code) => {
    const id = dimensionalUnitMap.get(code);
    return id;
  },
  getDimensionalUnitIdFromDescription: (description) => {
    const id = dimensionalUnitDescriptionMap.get(description);
    return id;
  },
  getAdjustmentIdFromCode: (code) => {
    const id = adjustmentMap.get(code);
    return id;
  },
  getStorageCenterIdFromCode: (code) => {
    const id = storageCenterMap.get(code);
    return id;
  },
  getStorageCenterIdFromDescription: (description) => {
    const id = storageCenterDescriptionMap.get(description);
    return id;
  },
  getStorageLocationIdFromCode: (code) => {
    const id = storageLocationMap.get(code);
    return id;
  },
  getSupplierIdFromCode: (code) => {
    const id = supplierMap.get(code);
    return id;
  },
  getCustomerIdFromCode: (code) => {
    const id = customerMap.get(code);
    return id;
  },
  getItemIdFromCode: (code) => {
    const id = itemMap.get(code);
    return id;
  },
  getItemCategoryIdFromCode: (code) => {
    const id = itemCategoryMap.get(code);
    return id;
  },

  getItemIdFromDescription: (description) => {
    const id = itemDescriptionMap.get(description);
    return id;
  },
  getBatchIdFromCode: (code) => {
    const id = batchMap.get(code);
    return id;
  },
  getCostIdFromCode: (code) => {
    const id = costMap.get(code);
    return id;
  },
  getCostIdFromCostTypeCode: (code) => {
    const id = costTypeMap.get(code);
    return id;
  },
};

export default mapTable;
