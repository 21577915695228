import { addDays } from 'date-fns';

const dateFuncs = {
  getCompleteYearRanges: (yearS, yearE) => {
    const yearsRange = (yearStart, yearEnd) =>
      [...Array(yearEnd - yearStart + 1).keys()].map((i) => i + yearStart);
    const yearsR = yearsRange(yearS, yearE);

    return yearsR.map((year) => ({
      start: new Date(`${year}-01-01`),
      end: new Date(`${year}-12-31`),
      label: `${year}`,
      value: `${year}`,
    }));
  },

  // get the list of days between start and end date
  getDaysArray: (s, e) => {
    let endDate = new Date(e);

    const days = [];
    for (let d = new Date(s); d <= endDate; d.setDate(d.getDate() + 1)) {
      days.push(new Date(d));
    }
    return days;
  },

  getMinutesInDays: (timeInMinutes) => {
    if (timeInMinutes === 0) {
      return '0 minutes';
    }

    // Convert minutes to hours
    let hours = Math.floor(timeInMinutes / 60);
    // Calculate remaining minutes after converting to hours
    let minutes = timeInMinutes % 60;
    // Convert hours to days
    let days = Math.floor(hours / 24);
    // Calculate remaining hours after converting to days
    hours = hours % 24;

    // Initialize an empty result string
    let result = '';

    // Add days to the result string if necessary
    if (days > 0) {
      result += days + ' ' + (days > 1 ? 'days' : 'day') + ' ';
    }

    // Add hours to the result string if necessary
    if (hours > 0) {
      result += hours + ' ' + (hours > 1 ? 'hours' : 'hour') + ' ';
    }

    // Add minutes to the result string if necessary
    if (minutes > 0) {
      result += Math.round(minutes) + ' ' + (minutes > 1 ? 'minutes' : 'minute');
    }

    // Return the result string
    return result.trim();
  },
};
export default dateFuncs;
