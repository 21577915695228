import axios from 'axios';
import { Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import * as AiIcons from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import FPBTable from '../FPBTable/FPBTable';
import Urls from '../Urls';
import QRModal from '../QRModal/QRModal';
import { setCurrentPageAction, setTotalCountAction } from '../../redux/actions/paginationAction';
import mapTable from '../mapTableService';
import apiService from '../apiService';
import { notification } from '../AlertMessage/ToastifyAlert';

const QRSection = () => {
  const [addedRow] = useState({});
  const [selected, setSelected] = useState();
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [qrModal, setQrModal] = useState(false);
  const [consolidationLevelSelected, setConsolidationLevelSelected] = useState();
  const [token] = useState(localStorage.getItem('accessToken') || null);
  const [QRImage, setQRImage] = useState('');
  const [isQRGenerated, setIsQRGenerated] = useState(false);
  const consolidationLevels = [1, 2, 3];
  // custom css according to specific printer
  const [selectedPrinter, setSelectedPrinter] = useState();
  const [printQRTextSize, setPrintQRTextSize] = useState('10px');
  const [labelDimensions, setLabelDimensions] = useState();
  const handleClose = () => setQrModal(false);
  // pagination
  const [filters, setFilters] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  const dispatchTotalCount = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatchTotalCount(setTotalCountAction(totalCount));
  };
  const dispatch = useDispatch();
  const inventoryClient = apiService.inventoryClient();

  const createQR = async () => {
    const dataToSend = {
      text: {
        ibr: selected.id,
        unit: selected.consolidationLevelSelected,
      },
    };
    try {
      const responseQRGeneration = await inventoryClient.post(Urls.createQR, dataToSend);
      setQRImage(responseQRGeneration.data.image_base64);
      setIsQRGenerated(true);
    } catch (error) {
      notification('Error generating the QR', 'error');
      console.log(error);
      setIsQRGenerated(false);
      setQRImage('');
    }
  };

  //Generate the QR after user selects: 1. a register and 2. consolidation level
  useEffect(() => {
    selected?.consolidationLevelSelected && createQR();
  }, [selected]);

  useEffect(async () => {
    mapTable.createItemMap();
    mapTable.createBatchMap();
    mapTable.createItemDescription();
  }, []);

  useEffect(() => {
    if (filters.length > 0) {
      dispatch(setCurrentPageAction(0));
    }
  }, [filters]);

  const getQueryString = () => {
    let filter = filters
      .reduce((acc, { columnName, operation, value }) => {
        switch (operation) {
          case 'contains': {
            acc.push(`${columnName}__icontains=${encodeURIComponent(value)}`);
            break;
          }
          case 'equal': {
            acc.push(`${columnName}=${encodeURIComponent(value)}`);
            break;
          }
          case 'greaterThanOrEqual': {
            acc.push(`${columnName}__gte=${encodeURIComponent(value)}`);
            break;
          }
          case 'lessThanOrEqual': {
            acc.push(`${columnName}__lte=${encodeURIComponent(value)}`);
            break;
          }
          default:
            acc.push(`${columnName}__${operation}=${encodeURIComponent(value)}`);
            break;
        }
        return acc;
      }, [])
      .join('&');

    if (filters.length > 1) {
      filter = `${filter}`;
    }

    const urlT = `${Urls.itemBatchRelation}?page=${
      currentPage + 1
    }&page_size=${pageSize}&${filter}`;
    return urlT;
  };

  useEffect(() => {
    const getTableData = () => {
      const queryString = getQueryString();
      if (lastQuery !== queryString) {
        axios
          .get(queryString, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async (res) => {
            const { results } = res.data;
            setTotalCount(res.data.count);
            if (results.length > 0) {
              let headersList = Object.keys(results[0]).filter((item) => {
                // Filter the columns that aren't displayed in the table
                let returnColumn;
                item !== 'fk_info' &&
                item !== 'id' &&
                item !== 'item_batch_relation_code' &&
                item !== 'unique_unit_cost_code' &&
                item !== 'created' &&
                item !== 'updated'
                  ? (returnColumn = true)
                  : (returnColumn = false);
                return returnColumn;
              });
              // create columns for additional information to extend default table
              headersList = [...headersList, 'item_description', 'cost', 'consolidation_levels'];
              const tableInformation = await Promise.all(
                results.map(async (row) => {
                  let dimensionalUnits = [];
                  const consolidationLevelValues = [0, 1, 2, 3, 4];
                  let transformedResponse;

                  // Each promise will return the additional information of the items, costs and batches table from the respective ids
                  const [itemData, costData, batchData] = await Promise.all([
                    axios
                      .get(`${Urls.item}/${row.item_reference_code}`, {
                        headers: { Authorization: `Bearer ${token}` },
                      })
                      .then((res) => {
                        const response_item = res.data;
                        transformedResponse = {
                          item_reference_id: response_item.id,
                          item_reference_code: response_item.reference_code,
                          item_description: response_item.item_description,
                          item_image: response_item.item_image,
                          item_dimensional_unit: response_item.consolidation_level_N0,
                        };
                        consolidationLevelValues.map((level) => {
                          if (response_item[`consolidation_level_N${level}`] !== null) {
                            dimensionalUnits = [
                              ...dimensionalUnits,
                              response_item[`consolidation_level_N${level}`],
                            ];
                          }
                        });
                        return [transformedResponse, dimensionalUnits];
                      })
                      .then(() => transformedResponse),

                    (async () => {
                      if (row.unique_unit_cost_code != null) {
                        const transformed = await axios
                          .get(`${Urls.cost}/${row.unique_unit_cost_code}`, {
                            headers: { Authorization: `Bearer ${token}` },
                          })
                          .then((res) => {
                            transformedResponse = {
                              cost_id: res.data.id,
                              cost_value: res.data.monetary_value_N0,
                              cost_unit: res.data.dimensional_unit_code,
                              cost_unique_unit_code: res.data.unique_unit_cost_code,
                            };
                            return transformedResponse;
                          });
                        return transformed;
                      }
                      return null;
                    })(),

                    (async () => {
                      if (row.batch_code != null) {
                        const transformed = await axios
                          .get(`${Urls.batch}/${row.batch_code}`, {
                            headers: { Authorization: `Bearer ${token}` },
                          })
                          .then((res) => {
                            transformedResponse = {
                              batch_id: res.data.id,
                              batch_code: res.data.batch_code,
                              manufacturing_date: res.data.manufacturing_date,
                              expiration_date: res.data.expiration_date,
                            };
                            return transformedResponse;
                          });
                        return transformed;
                      }
                      return null;
                    })(),
                  ]);
                  const consolidationLevels = await Promise.all(
                    dimensionalUnits.map(async (du) => {
                      const dimensionalUnitCodes = await axios
                        .get(`${Urls.dimensionalUnit}/${du}`, {
                          headers: { Authorization: `Bearer ${token}` },
                        })
                        .then((res) => res.data.dimensional_unit_code);
                      return dimensionalUnitCodes;
                    })
                  );

                  const result = {
                    ...row,
                    item_reference_code: itemData.item_reference_code,
                    item_description: itemData.item_description,
                    item_image: itemData.item_image,
                    item_dimensional_unit: itemData.item_dimensional_unit,
                    batch_code: batchData.batch_code,
                    manufacturing_date: batchData.manufacturing_date,
                    expiration_date: batchData.expiration_date,
                    cost: costData !== null ? costData.cost_value : null,
                    cost_unit: costData !== null ? costData.cost_unit : null,
                    unique_unit_cost_code:
                      costData !== null ? costData.cost_unique_unit_code : null,
                    consolidation_levels: consolidationLevels || null,
                  };

                  return result;
                })
              );
              setHeaders(headersList);
              setTableData(tableInformation);
            } else {
              return transformedResponse;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setLastQuery(queryString);
    };
    getTableData();
  }, [currentPage, pageSize, filters]);

  useEffect(() => {
    setIsQRGenerated(false);
    if (consolidationLevelSelected) {
      setSelected({
        ...selected,
        consolidationLevelSelected,
      });
    }
  }, [consolidationLevelSelected]);

  // when a printer is selected set the dimensions of the page according to printer label dimensions
  useEffect(() => {
    switch (selectedPrinter) {
      case 'printerZebra':
        setLabelDimensions('40mm 50mm');
        setPrintQRTextSize('10px');
        break;

      case 'printerTSC':
        setLabelDimensions('40mm 50mm');
        setPrintQRTextSize('10px');
        break;

      case 'printerZebraGC-10x15':
        setLabelDimensions('100mm 150mm');
        setPrintQRTextSize('10px');
        break;

      case 'printerZebraGC-10x6.5':
        setLabelDimensions('100mm 65mm');
        setPrintQRTextSize('10px');
        break;

      case 'printerZebraGC-3.2x1.3':
        setLabelDimensions('32mm 13mm');
        setPrintQRTextSize('10px');
        break;

      case 'printerZebraGC-5x2.5':
        setLabelDimensions('50mm 25mm');
        setPrintQRTextSize('10px');
        break;

      case 'printerZebra7.6x5.1':
        setLabelDimensions('76mm 51mm');
        setPrintQRTextSize('16px');
        break;
    }
  }, [selectedPrinter]);

  return (
    <div>
      <style>
        {`@media print {
          @page {
            size: ${labelDimensions} !important;
            margin: 0;
            padding: 0;
          }
          .qr-print {
            padding: 0;
            margin: 1mm;
            display: block !important;
          }
          .qr-data .qr-description {
            font-size: calc(${printQRTextSize} + 2px) !important;
            line-height: 13px !important;
          }
          .qr-data .qr-title {
            font-size: ${printQRTextSize} !important;
            line-height: 13px !important;
          }
        }`}
      </style>
      <HeaderTitle title="QR Generation" />
      <div className="btn-container ">
        <Button
          className="fpb-btn"
          disabled={!isQRGenerated}
          onClick={() => {
            setQrModal(true);
          }}
        >
          Create QR
          <AiIcons.AiOutlineQrcode className="ml-2" />
        </Button>
      </div>
      <FPBTable
        consolidationLevels={consolidationLevels}
        setConsolidationLevelSelected={setConsolidationLevelSelected}
        isCustomConsolidationLevel
        isModal
        readOnly
        setSelected={setSelected}
        headers={headers}
        tableData={tableData}
        handleDelete={() => {}}
        handleEdit={() => {}}
        deleteChange={() => {}}
        addedRow={addedRow}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        setFilters={setFilters}
      />
      <QRModal
        show={qrModal}
        title="QR Generation"
        QRImage={QRImage}
        handleClose={handleClose}
        qrData={selected}
        setSelectedPrinter={setSelectedPrinter}
      />
    </div>
  );
};

export default QRSection;
