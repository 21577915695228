import React from 'react';
import StorageLocation from './StorageLocation';
import DimensionalUnit from './DimensionalUnit';
import StorageCenter from './StorageCenter';
import Customer from './Customer';
import Supplier from './Supplier';
import Adjustment from './Adjustment';
import Item from './Item';
import ItemCategory from './ItemCategory';
import Cost from './Cost';
import CostType from './CostType';
import Batch from './Batch';
import LabelForm from './LabelForm';
import EntryOrder from './EntryOrder';
import DispatchOrder from './DispatchOrder';
import ItemBatchRelation from './ItemBatchRelation';
import ItemStorageCenterRelation from './ItemStorageCenterRelation';

//industrial forms
import OperationCenter from '../IndustrialForms/OperationCenter';
import IndustrialDimensionalUnit from '../IndustrialForms/DimensionalUnit';
import UnitTransformation from '../IndustrialForms/UnitTransformation';
import Product from '../IndustrialForms/Product';
import ProductCategory from '../IndustrialForms/ProductCategory';
import WorkCenter from '../IndustrialForms/WorkCenter';
import WorkCenterCategory from '../IndustrialForms/WorkCenterCategory';
import Variable from '../IndustrialForms/Variable';
import QualityDimension from '../IndustrialForms/QualityDimension';
import EventCategory from '../IndustrialForms/EventCategory';
import Shift from '../IndustrialForms/Shift';
import ShiftAssignment from '../IndustrialForms/ShiftAssignment';
import TimeAccomplishment from '../IndustrialForms/TimeAccomplishment';
import EventType from '../IndustrialForms/EventType';
import WorkCenterCapacity from '../IndustrialForms/WorkCenterCapacity';
import ProductionOrder from '../IndustrialForms/ProductionOrder';
import ProductPerProdOrder from '../IndustrialForms/ProductPerProdOrder';
import ProductionBatch from '../IndustrialForms/ProductionBatch';
import WorkCenterFilter from '../IndustrialForms/WorkCenterFilter';

//authentication forms
import Company from './Company';
import User from './User';
import Module from './Module';
import CompanyModule from './companyModule';
import GroupPermissions from './GroupPermission';
import UserGroupPermissions from './UserGroupPermission';
import Route from '../IndustrialForms/Route';
import Process from '../IndustrialForms/Process';
import WorkCenterPerProcess from '../IndustrialForms/WorkCenterPerProcess';
import Quality from '../IndustrialForms/Quality';

const FormContainer = ({
  formName,
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  handleAPIDelete,
  added,
  setAddForm,
  setAlert,
  setError,
  onEdited,
  edited,
  handleSelectedOrder,
  setEntryTransaction,
  setDispatchTransaction,
  title,
  productionOrder,
  setUrlPost,
  formType,
  status,
}) => {
  const getForm = (formName) => {
    switch (formName) {
      case 'label':
        return (
          <LabelForm
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'storageLocation':
        return (
          <StorageLocation
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'dimensionalUnit':
        return (
          <DimensionalUnit
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'storageCenter':
        return (
          <StorageCenter
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'customer':
        return (
          <Customer
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'supplier':
        return (
          <Supplier
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'item':
        return (
          <Item
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            setError={setError}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'adjustment':
        return (
          <Adjustment
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            setError={setError}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'itemCategory':
        return (
          <ItemCategory
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'costType':
        return (
          <CostType
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            edited={edited}
          />
        );
      case 'cost':
        return (
          <Cost
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'batch':
        return (
          <Batch
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'itemBatchRelation':
        return (
          <ItemBatchRelation
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'itemStorageCenterRelation':
        return (
          <ItemStorageCenterRelation
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'entryOrder':
        return (
          <EntryOrder
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            handleConfirmation={handleSelectedOrder}
            setEntryTransaction={setEntryTransaction}
            setDispatchTransaction={setDispatchTransaction}
            title={title}
          />
        );
      case 'dispatchOrder':
        return (
          <DispatchOrder
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            handleConfirmation={handleSelectedOrder}
            setEntryTransaction={setEntryTransaction}
            setDispatchTransaction={setDispatchTransaction}
            title={title}
          />
        );
      //INDUSTRIAL FORMS
      case 'operationCenter':
        return (
          <OperationCenter
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'dimensionalUnitA':
        return (
          <IndustrialDimensionalUnit
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'unitTransformation':
        return (
          <UnitTransformation
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'product':
        return (
          <Product
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'productCategory':
        return (
          <ProductCategory
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'workCenter':
        return (
          <WorkCenter
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'workCenterCategory':
        return (
          <WorkCenterCategory
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'variable':
        return (
          <Variable
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'qualityDimension':
        return (
          <QualityDimension
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'eventCategory':
        return (
          <EventCategory
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'shift':
        return (
          <Shift
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'eventType':
        return (
          <EventType
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'workCenterCapacity':
        return (
          <WorkCenterCapacity
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'productionOrder':
        return (
          <ProductionOrder
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'productPerProductionOrder':
        return (
          <ProductPerProdOrder
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            productionOrder={productionOrder}
            setOpenEditForm={setAddForm}
            status={status}
          />
        );
      case 'shiftAssignment':
        return (
          <ShiftAssignment
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            handleAPIDelete={handleAPIDelete}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            setUrlPost={setUrlPost}
            formType={formType}
          />
        );
      case 'timeAccomplishment':
        return <TimeAccomplishment setAddForm={setAddForm} />;
      case 'workCenterFilterInAvailability':
        return <WorkCenterFilter setAddForm={setAddForm} formName={formName} />;
      case 'workCenterFilterInPerformance':
        return <WorkCenterFilter setAddForm={setAddForm} formName={formName} />;
      case 'workCenterFilterInQuality':
        return <WorkCenterFilter setAddForm={setAddForm} formName={formName} />;
      case 'route':
        return (
          <Route
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            setUrlPost={setUrlPost}
          />
        );
      case 'process':
        return (
          <Process
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            setUrlPost={setUrlPost}
          />
        );
      case 'productionBatch':
        return (
          <ProductionBatch
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            setUrlPost={setUrlPost}
          />
        );
      case 'workCenterPerProcess':
        return (
          <WorkCenterPerProcess
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            setUrlPost={setUrlPost}
          />
        );
      case 'quality':
        return (
          <Quality
            onAdded={onAdded}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
            setUrlPost={setUrlPost}
            setError={setError}
          />
        );
      //Auth module
      case 'company':
        return (
          <Company
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'user':
        return (
          <User
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'module':
        return (
          <Module
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'companyModule':
        return (
          <CompanyModule
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'groupPermission':
        return (
          <GroupPermissions
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
      case 'userGroupPermission':
        return (
          <UserGroupPermissions
            onAdded={onAdded}
            transformCode={transformCode}
            values={values}
            handleAPIRequest={handleAPIRequest}
            added={added}
            setAddForm={setAddForm}
            setAlert={setAlert}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            onEdited={onEdited}
          />
        );
    }
  };
  return <div>{getForm(formName)}</div>;
};

export default FormContainer;
