import { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

const SpinnerWithText = ({ text }) => {
  const [fadeProp, setFadeProp] = useState({
    fade: 'fade-in',
  });
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (fadeProp.fade === 'fade-in') {
        setFadeProp({
          fade: 'fade-out',
        });
      } else {
        setFadeProp({
          fade: 'fade-in',
        });
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [fadeProp]);

  return (
    <div className="text-center">
      <Spinner animation="border" role="status" className="fpb-spinner mb-3" size="lg"></Spinner>
      <h2 className={fadeProp.fade}>{text}</h2>
    </div>
  );
};

export default SpinnerWithText;
