import { Alert } from 'react-bootstrap';
import { useState, useEffect } from 'react';

const AlertMessage = ({
  message,
  setIsError,
  variant,
  setAdded,
  setErrorMessage,
  setAlertMessage,
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
      setIsError ? setIsError(false) : null;
      setAdded ? setAdded(false) : null;
      setAlertMessage ? setAlertMessage('') : null;
      setErrorMessage ? setErrorMessage('') : null;
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  if (!show) {
    return null;
  }

  let termTableDimensional = 'Dimensional';
  let termTableStorageCenter = 'Storage';
  let termTableStorageCenter2 = 'center';
  let termTableStorageLocation = 'Location';
  let termTableCustomer = 'Customer';
  let termTableSupplier = 'Supplier';
  let termTableAdjustment = 'Adjustment';
  let termTableItemCategory = 'Item';
  let termTableItemCategory2 = 'category';
  let termTableCostType = 'Cost';
  let termTableCostType2 = 'type';
  let termTableCost = 'Cost';
  let termTableEntryOrder = 'Entry';
  let termTableEntryOrder2 = 'Order';
  let termTableItem = 'Item';
  let termTableItemAddForm = 'consolidation';
  let termTableItemAddForm2 = 'level';
  let termTableBatch = 'Batch';
  let termTableDispatchOrder = 'Dispatch';
  let termTableDispatchOrder2 = 'Order';
  let termTableItemStorageCenterRelation = 'Item';
  let termTableItemStorageCenterRelation2 = 'storage';
  let termTableItemStorageCenterRelation3 = 'center';
  let termTableItemStorageCenterRelation4 = 'relation';
  let termTableCompany = 'Company';
  let termTableUser = 'User';
  let termTableModule = 'Module';
  let termTableCompanyModule = 'companyModule';
  let termTableGroupPermission = 'GroupPermission';
  let termTableUserGroupPermission = 'UserGroupPermission';

  let positionTDU = message.indexOf(termTableDimensional);
  let positionTSC = message.indexOf(termTableStorageCenter);
  let positionTSC_2 = message.indexOf(termTableStorageCenter2);
  let positionTSL = message.indexOf(termTableStorageLocation);
  let positionTCU = message.indexOf(termTableCustomer);
  let positionTSU = message.indexOf(termTableSupplier);
  let positionTAD = message.indexOf(termTableAdjustment);
  let positionTIC = message.indexOf(termTableItemCategory);
  let positionTIC_2 = message.indexOf(termTableItemCategory2);
  let positionTCT = message.indexOf(termTableCostType);
  let positionTCT_2 = message.indexOf(termTableCostType2);
  let positionTCO = message.indexOf(termTableCost);
  let positionTEO = message.indexOf(termTableEntryOrder);
  let positionTEO_2 = message.indexOf(termTableEntryOrder2);
  let positionTIT = message.indexOf(termTableItem);
  let positionTITAF = message.indexOf(termTableItemAddForm);
  let positionTITAF_2 = message.indexOf(termTableItemAddForm2);
  let positionTBA = message.indexOf(termTableBatch);
  let positionTDO = message.indexOf(termTableDispatchOrder);
  let positionTDO_2 = message.indexOf(termTableDispatchOrder2);
  let positionTISCR = message.indexOf(termTableItemStorageCenterRelation);
  let positionTISCR_2 = message.indexOf(termTableItemStorageCenterRelation2);
  let positionTISCR_3 = message.indexOf(termTableItemStorageCenterRelation3);
  let positionTISCR_4 = message.indexOf(termTableItemStorageCenterRelation4);
  let positionTC = message.indexOf(termTableCompany);
  let positionTU = message.indexOf(termTableUser);
  let positionTMOD = message.indexOf(termTableModule);
  let positionTCOMOD = message.indexOf(termTableCompanyModule);
  let positionTGPER = message.indexOf(termTableGroupPermission);
  let positionTUGPER = message.indexOf(termTableUserGroupPermission);

  if (positionTDU !== -1) {
    return '';
  } else if (positionTSC !== -1 && positionTSC_2 !== -1) {
    return '';
  } else if (positionTSL !== -1) {
    return '';
  } else if (positionTCU !== -1) {
    return '';
  } else if (positionTSU !== -1) {
    return '';
  } else if (positionTAD !== -1) {
    return '';
  } else if (positionTIC !== -1 && positionTIC_2 !== -1) {
    return '';
  } else if (positionTEO !== -1 && positionTEO_2 !== -1) {
    return '';
  } else if (positionTCT !== -1 && positionTCT_2 !== -1) {
    return '';
  } else if (positionTCO !== -1) {
    return '';
  } else if (positionTIT !== -1) {
    return '';
  } else if (positionTITAF !== -1 && positionTITAF_2 !== -1) {
    return '';
  } else if (positionTBA !== -1) {
    return '';
  } else if (positionTDO !== -1 && positionTDO_2 !== -1) {
    return '';
  } else if (
    positionTISCR !== -1 &&
    positionTISCR_2 !== -1 &&
    positionTISCR_3 !== -1 &&
    positionTISCR_4
  ) {
    return '';
  } else if (positionTC !== -1) {
    return '';
  } else if (positionTU !== -1) {
    return '';
  } else if (positionTMOD !== -1) {
    return '';
  } else if (positionTCOMOD !== -1) {
    return '';
  } else if (positionTGPER !== -1) {
    return '';
  } else if (positionTUGPER !== -1) {
    return '';
  } else {
    return <Alert variant={variant}>{message}</Alert>;
  }
};

export default AlertMessage;
