import { Form } from 'react-bootstrap';
import Select from 'react-select';

const TransactionTypeSelection = ({ onChange, value, ...props }) => {
  const transactionTypeOptions = [
    { value: 'entry', label: 'Entry' },
    { value: 'output', label: 'Output' },
    { value: 'transfer', label: 'Transfer' },
    { value: 'adjustment_decrease', label: 'Adjustment Decrease' },
    { value: 'adjustment_increase', label: 'Adjustment Increase' },
  ];

  return (
    <>
      <Form.Label>{props.label || 'Transaction Type'}</Form.Label>
      <Select
        {...props}
        isClearable={true}
        options={transactionTypeOptions}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default TransactionTypeSelection;
