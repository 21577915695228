import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import Urls from '../Urls';
import ProductTableSection from '../TableSection/ProductTableSection';
import { notification } from '../AlertMessage/ToastifyAlert';

const ShiftAssignment = ({
  onAdded,
  handleAPIRequest,
  handleAPIDelete,
  added,
  setAddForm,
  setUrlPost,
  values,
  formType,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [selectedWorkCenters, setSelectedWorkCenters] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState([]);

  const shiftValidate = Yup.object({
    startDate: Yup.string().required('Required'),
    endDate: Yup.string().required('Required'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(() => {
    if (formType === 'Add') {
      setUrlPost(
        `${
          Urls.shiftAssignment
        }?start_date=${startDate}&end_date=${endDate}&days_list=${JSON.stringify(
          selectedDays
        )}&shifts_list=${JSON.stringify(selectedShifts)}&work_centers_list=${JSON.stringify(
          selectedWorkCenters
        )}`
      );
    } else if (formType === 'Delete') {
      setUrlPost(
        `${
          Urls.shiftAssignment
        }/0?start_date=${startDate}&end_date=${endDate}&days_list=${JSON.stringify(
          selectedDays
        )}&shifts_list=${JSON.stringify(selectedShifts)}&work_centers_list=${JSON.stringify(
          selectedWorkCenters
        )}`
      );
    }
  }, [startDate, endDate, selectedDays, selectedShifts, selectedWorkCenters]);

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={shiftValidate}
        onSubmit={async () => {
          if (
            selectedDays.length === 0 ||
            selectedShifts.length === 0 ||
            selectedWorkCenters.length === 0
          ) {
            setAddForm(true);
            notification('Be sure to select values for each section of the form', 'warning');
          } else {
            let userEmail = userProfile.email ? userProfile.email : '';
            if (formType == 'Add') {
              const processedValues = {
                ...values,
                start_date: 'string',
                end_date: 'string',
                day_list: 'string',
                created_by: userEmail,
                updated_by: userEmail,
                work_center: 'string',
                shift: 'T1',
              };
              setProcessedFormValues(processedValues);
              const created = await handleAPIRequest(processedValues);
              if (created.data !== 'Everything is OK') {
                notification(created.data, 'warning');
              }
            } else if (formType == 'Delete') {
              const processedValues = {
                ...values,
                date: '2022-04-26',
                start_hour: 'string',
                end_hour: 'string',
                work_center: 'string',
                shift: 'T1',
                deleted_by: userEmail,
              };
              setProcessedFormValues(processedValues);
              const created = await handleAPIDelete(processedValues);
            }
          }
        }}
        initialValues={{
          startDate: '',
          endDate: '',
          checked: [],
        }}
      >
        {({ values, handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Start date"
                name="startDate"
                type="date"
                value={(values.startDate || '').toString().substring(0, 16)}
                onChange={(e) => {
                  handleChange(e);
                  setStartDate(e.target.value);
                }}
              />
              <TextField
                label="End date"
                name="endDate"
                type="date"
                value={(values.endDate || '').toString().substring(0, 16)}
                onChange={(e) => {
                  handleChange(e);
                  setEndDate(e.target.value);
                }}
              />
            </Form.Row>

            <b style={{ fontSize: 'large' }}>Select weekdays</b>
            <Form.Row role="group" aria-labelledby="checkbox-group">
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
                (day) => (
                  <div key={`${day}`} className="m-2">
                    <Form.Check
                      inline
                      type="checkbox"
                      id={`${day}`}
                      label={`${day}`}
                      name="checked"
                      value={`${day}`}
                      onChange={(e) => {
                        handleChange(e);
                        e.target.checked === true
                          ? setSelectedDays([...selectedDays, day.toLowerCase()])
                          : setSelectedDays(
                              selectedDays.filter((d) => {
                                return d !== day.toLowerCase();
                              })
                            );
                      }}
                    />
                  </div>
                )
              )}
            </Form.Row>
            <ProductTableSection
              url={Urls.shift}
              title="Select shifts"
              formName="shifts"
              withCheckBox
              readOnly={true}
              showAddButton={false}
              setSelected={setSelectedShifts}
            />
            <ProductTableSection
              url={Urls.workCenter}
              title="Select work centers"
              formName="shiftWorkCenters"
              withCheckBox
              readOnly={true}
              showAddButton={false}
              setSelected={setSelectedWorkCenters}
            />
            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ShiftAssignment;
