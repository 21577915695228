import apiService from '../components/apiService';
import * as Yup from 'yup';
import Urls from '../components/Urls';

const industrialClient = apiService.industrialClient();

export const getVariablesMap = async () => {
  const variablesRes = await industrialClient.get(`${Urls.variable}?page_size=0`);
  const variables = variablesRes.data;
  const variablesMap = {};
  variables.forEach((variable) => (variablesMap[variable.code] = variable.calculation_variable));
  return variablesMap;
};

export const getCalculationVariableIndex = async (currentWorkCenterConfiguration) => {
  const variablesMap = await getVariablesMap();
  const calculationVariableIndex = currentWorkCenterConfiguration?.findIndex(
    (config) => variablesMap[config.variable] === 'yes' && config.data_source === 'manual'
  );
  return calculationVariableIndex;
};

export const getVariablesValidations = (
  currentWorkCenterConfiguration,
  calculationVariableIndex
) => {
  const variablesValidation = {};
  for (let index in currentWorkCenterConfiguration) {
    if (parseInt(index) === calculationVariableIndex)
      variablesValidation[`variable_${index}`] = Yup.number()
        .typeError('Enter a value')
        .required('Required');
    else variablesValidation[`variable_${index}`] = Yup.number().nullable();
  }
  return variablesValidation;
};
