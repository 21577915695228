import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';

const StorageCenter = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});

  const costTypeValidate = Yup.object({
    storageCenterCode: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string().required('Required'),
    country: Yup.string(),
    city: Yup.string(),
    province: Yup.string(),
    address: Yup.string(),
    geocoordinateLatitude: Yup.number().nullable().typeError('Must be a  number (5, 5.6, 7.85)'),
    geocoordinateLongitude: Yup.number().nullable().typeError('Must be a  number (5, 5.6, 7.85)'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={costTypeValidate}
        onSubmit={async (data) => {
          const processedValues = {
            storage_center_code: data.storageCenterCode,
            description: data.description,
            country: data.country,
            city: data.city,
            province: data.province,
            address: data.address,
            geocoordinate_latitude: data.geocoordinateLatitude || null,
            geocoordinate_longitude: data.geocoordinateLongitude || null,
          };
          setProcessedFormValues(processedValues);
          if (data.id == null) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Storage center',
              code: data.storageCenterCode,
            });
            if (created.statusText !== 'Created') {
              notification('Error creating item - duplicate item', 'error');
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Storage center',
              code: data.storageCenterCode,
            });
            if (updated.statusText == 'OK') {
              onEdited(processedValues);
              setAddForm(false);
              notification('Item updated successfully', 'success');
            } else {
              notification('Error updating item - duplicate item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.id,
          storageCenterCode: values.storage_center_code || '',
          description: values.description || '',
          country: values.country || '',
          city: values.city || '',
          province: values.province || '',
          address: values.address || '',
          geocoordinateLatitude: values.geocoordinate_latitude || null,
          geocoordinateLongitude: values.geocoordinate_longitude || null,
        }}
      >
        {({ values, touched, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Storage Center Code"
                name="storageCenterCode"
                type="text"
                value={values.storageCenterCode}
              />
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
              />
            </Form.Row>
            <Form.Row>
              <TextField label="Country" name="country" type="text" value={values.country} />
              <TextField label="City" name="city" type="text" value={values.city} />
            </Form.Row>
            <Form.Row>
              <TextField label="Province" name="province" type="text" />
              <TextField label="Address" name="address" type="text" value={values.address} />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Geocoordinate Latitude"
                name="geocoordinateLatitude"
                type="text"
                value={values.geocoordinateLatitude}
              />
              <TextField
                label="Geocoordinate Longitude"
                name="geocoordinateLongitude"
                type="text"
                value={values.geocoordinateLongitude}
              />
            </Form.Row>
            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default StorageCenter;
