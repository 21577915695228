import subDays from 'date-fns/subDays';
import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Row, Form, InputGroup } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import dateFuncs from '../../utils/dateFuncs';

const RangeDateInput = ({ dateOptionSelected, dateFilters, setDateFilters, disabled }) => {
  const [selectedStartYear, setSelectedStartYear] = useState('');
  const [selectedEndYear, setSelectedEndYear] = useState('');
  const [selectedStartMonth, setSelectedStartMonth] = useState(0);
  const [selectedEndMonth, setSelectedEndMonth] = useState(0);
  const [selectedStartWeek, setSelectedStartWeek] = useState();
  const [selectedEndWeek, setSelectedEndWeek] = useState();
  const now = useRef(new Date());
  const [to, setTo] = useState(now.current);
  const [from, setFrom] = useState(subDays(now.current, 6));
  const [selectedStartDay, setSelectedStartDay] = useState();
  const [selectedEndDay, setSelectedEndDay] = useState();
  const [isStartDateInput, setIsStartDateInput] = useState();
  const [isEndDateInput, setIsEndDateInput] = useState();
  const [dayPicker, setDayPicker] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);

  useEffect(() => {
    if (dateOptionSelected?.label === 'day') {
      setShowDateRange(true);
    } else {
      setShowDateRange(false);
    }
    setDateFilters({});
  }, [dateOptionSelected]);

  const ranges = useMemo(
    () => [
      {
        startDate: from,
        endDate: to,
        key: 'selection',
      },
    ],
    [from, to]
  );

  const twoDigitMonth = (n) => (n < 10 ? `0${n}` : `${n}`);

  const getWeekNumber = () => {
    const currentdate = new Date();
    const oneJan = new Date(currentdate.getFullYear(), 0, 1);
    const numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
    const result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
    return result;
  };

  const maxDates = {
    day: now.current,
    week: `${now.current.getFullYear()}-W${getWeekNumber() - 1}`,
    month: `${now.current.getFullYear()}-${twoDigitMonth(now.current.getMonth() + 1)}`,
    year: now.current.getFullYear(),
  };

  const getDateOfISOWeek = (w, y) => {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  };

  const getEndDateOfISOWeek = (w, y) => {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    const ISOweekEnd = simple;
    if (dow <= 4) {
      ISOweekEnd.setDate(simple.getDate() + 7 - simple.getDay());
    } else {
      ISOweekEnd.setDate(simple.getDate() + 14 - simple.getDay());
    }
    return ISOweekEnd;
  };

  const onChangeStartYear = (e) => {
    if (e) {
      setSelectedStartYear(e.target.value);
      setDateFilters({ ...dateFilters, transaction_date__year__gte: e.target.value });
    }
  };

  const onChangeEndYear = (e) => {
    if (e) {
      setSelectedEndYear(e.target.valueAsNumber);
      setDateFilters({ ...dateFilters, transaction_date__year__lte: e.target.valueAsNumber });
    }
  };

  const onChangeStartMonthYear = (e) => {
    setSelectedStartMonth(e.target.value);
    setDateFilters({ ...dateFilters, transaction_date_after: `${e.target.value}-01` });
  };
  const onChangeEndMonthYear = (e) => {
    setSelectedEndMonth(e.target.value);
    const date = new Date(e.target.value);
    const month = date.getMonth();
    const lastDay = new Date(date.getFullYear(), month + 2, 0).getDate();
    setDateFilters({ ...dateFilters, transaction_date_before: `${e.target.value}-${lastDay}` });
  };
  const onChangeStartWeek = (e) => {
    setSelectedStartWeek(e.target.value);
    const formatedDate = getDateOfISOWeek(
      e.target.value.split('-')[1].substring(1),
      e.target.value.split('-')[0]
    );
    setDateFilters({
      ...dateFilters,
      transaction_date_after: formatedDate.toISOString().split('T', 1)[0],
    });
  };

  const onChangeEndWeek = (e) => {
    setSelectedEndWeek(e.target.value);
    const formatedDate = getEndDateOfISOWeek(
      e.target.value.split('-')[1].substring(1),
      e.target.value.split('-')[0]
    );
    setDateFilters({
      ...dateFilters,
      transaction_date_before: formatedDate.toISOString().split('T', 1)[0],
    });
  };

  const onChangeDayPicker = useCallback(({ selection: { startDate, endDate } }) => {
    let daylist = dateFuncs.getDaysArray(startDate, endDate);
    daylist = daylist.map((v) => v.toISOString().split('T', 1)[0]);
    setSelectedStartDay(startDate.toISOString().split('T', 1)[0]);
    setSelectedEndDay(endDate.toISOString().split('T', 1)[0]);
    setDateFilters({
      transaction_date_after: startDate.toISOString().split('T', 1)[0],
      transaction_date_before: endDate.toISOString().split('T', 1)[0],
    });
    setFrom(startDate);
    setTo(endDate);
    daylist.length > 2 ? setShowDateRange(false) : null;
  });

  return (
    <>
      <InputGroup.Text className="ml-2">Start</InputGroup.Text>
      {dateOptionSelected.label === 'year' ? (
        <Form.Control
          type={'number'}
          min={'2018'}
          max={maxDates.year}
          value={selectedStartYear}
          onChange={(e) => onChangeStartYear(e)}
        />
      ) : dateOptionSelected.label === 'month' ? (
        <Form.Control
          type={'month'}
          value={selectedStartMonth}
          onChange={onChangeStartMonthYear}
          disabled={disabled}
        />
      ) : dateOptionSelected.value === 'week' ? (
        <Form.Control
          type={'week'}
          value={selectedStartWeek}
          onChange={onChangeStartWeek}
          disabled={disabled}
        />
      ) : (
        <Form.Control
          type={'day'}
          value={selectedStartDay}
          onClick={() => {
            setShowDateRange(true);
            setDayPicker(true);
            setIsStartDateInput(true);
            setIsEndDateInput();
          }}
          disabled={disabled}
        />
      )}
      <InputGroup.Text className="ml-2">End</InputGroup.Text>
      {dateOptionSelected.label === 'year' ? (
        <Form.Control
          type={'number'}
          min={'2018'}
          max={maxDates.year}
          value={selectedEndYear}
          onChange={(e) => onChangeEndYear(e)}
        />
      ) : dateOptionSelected.label === 'month' ? (
        <Form.Control
          type={'month'}
          value={selectedEndMonth}
          onChange={onChangeEndMonthYear}
          disabled={disabled}
        />
      ) : dateOptionSelected.value === 'week' ? (
        <Form.Control
          type={'week'}
          value={selectedEndWeek}
          onChange={onChangeEndWeek}
          disabled={disabled}
        />
      ) : (
        <Form.Control
          type={'day'}
          value={selectedEndDay}
          onClick={() => {
            setShowDateRange(true);
            setDayPicker(true);
            setIsEndDateInput(true);
            setIsStartDateInput();
          }}
          disabled={disabled}
        />
      )}
      {showDateRange ? (
        <Row>
          <DateRange
            ranges={ranges}
            onChange={onChangeDayPicker}
            shoSelectionPreview
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            calendarFocus="forwards"
            maxDate={maxDates.day}
            retainEndDateOnFirstSelection
            {...(dayPicker && {
              focusedRange: isEndDateInput ? [0, 1] : isStartDateInput ? [0, 0] : {},
            })}
          />
        </Row>
      ) : null}
    </>
  );
};

export default RangeDateInput;
