import {
  SET_TOTAL_COUNT,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  SET_MODAL_TOTAL_COUNT,
  SET_MODAL_CURRENT_PAGE,
  SET_MODAL_PAGE_SIZE,
  SET_IS_MODAL_PAGINATION,
} from '.';

export const setTotalCountAction = (totalCount) => ({
  type: SET_TOTAL_COUNT,
  payload: totalCount,
});

export const setCurrentPageAction = (currentPage) => ({
  type: SET_CURRENT_PAGE,
  payload: currentPage,
});

export const setPageSizeAction = (pageSize) => ({
  type: SET_PAGE_SIZE,
  payload: pageSize,
});

export const setModalTotalCountAction = (totalCount) => ({
  type: SET_MODAL_TOTAL_COUNT,
  payload: totalCount,
});
export const setModalCurrentPageAction = (currentPage) => ({
  type: SET_MODAL_CURRENT_PAGE,
  payload: currentPage,
});

export const setModalPageSizeAction = (pageSize) => ({
  type: SET_MODAL_PAGE_SIZE,
  payload: pageSize,
});

export const setIsModalPaginationAction = () => ({
  type: SET_IS_MODAL_PAGINATION,
});
