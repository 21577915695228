import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ActionConfirmCustomMessage = ({ show, handleClose, handleConfirmation, message }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Confirmation</Modal.Title>
    </Modal.Header>
    <Modal.Body>{message}</Modal.Body>
    <Modal.Footer>
      <Button className="fpb-btn " onClick={handleConfirmation}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ActionConfirmCustomMessage;
