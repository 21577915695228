import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const fetchManualReportsByOrder = createAsyncThunk(
  'events/fetchManualReportsByOrder',
  async ({ page, pageSize, productionOrder, workCenter, filters }) => {
    try {
      const res = await apiService.getManualReportsByOrder(
        page,
        pageSize,
        productionOrder,
        workCenter,
        filters
      );
      const { results } = res.data || 0;
      if (results.length > 0) {
        // discard the fields the user will not visualize in tables
        const headersList = Object.keys(results[0]).filter((elem) => {
          let returnColumn;
          if (
            elem === 'id' ||
            elem === 'manual_table' ||
            elem === 'product_per_production_order' ||
            elem === 'segmentation_in_minutes' ||
            elem === 'quality_report'
          ) {
            returnColumn = false;
          } else {
            returnColumn = true;
          }
          return returnColumn;
        });
        return {
          page,
          pageSize,
          total: res.data.count,
          data: res.data.results,
          headers: headersList,
        };
      }
      return {
        page,
        pageSize,
        total: res.data.count,
        data: res.data.results,
        headers: [],
      };
    } catch (err) {
      return err.message;
    }
  }
);

const initialState = {
  currentOrderReports: {
    page: 1,
    pageSize: 10,
    total: 0,
    data: [],
    headers: [],
  },
  reports: [],
  columnsToHide: [
    'work_center_description',
    'work_center_category',
    'variable_description',
    'calculation_variable',
    'dimensional_unit_description',
    'operation_center',
    'product_description',
    'process_description',
    'created_by',
    'created_at',
    'updated_by',
    'updated_at',
  ],
};

const manualReportSlice = createSlice({
  name: 'manualReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchManualReportsByOrder.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.currentOrderReports.data = action.payload.data;
      state.currentOrderReports.page = action.payload.page;
      state.currentOrderReports.pageSize = action.payload.pageSize;
      state.currentOrderReports.total = action.payload.total;
      state.currentOrderReports.headers = action.payload.headers;
    });
  },
});

export const selectCurrentOrderReports = (state) => state.manualReports.currentOrderReports.data;
export const selectReportsHeaders = (state) => state.manualReports.currentOrderReports.headers;
export const selectReportsTotalCount = (state) => state.manualReports.currentOrderReports.total;
export const manualReportsColumnsToHide = (state) => state.manualReports.columnsToHide;

export default manualReportSlice.reducer;
