export const formatDateToTime = (ms_time) => {
  const MS = ms_time % 1000;
  const St = Math.floor((ms_time - MS) / 1000);
  const seconds = Number(St);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  if (d > 0) {
    const dDisplay = d + 'd ';
    const hDisplay = h + 'h ';
    const mDisplay = m + 'm';
    return dDisplay + hDisplay + mDisplay;
  }
  const hDisplay = h > 0 ? h + 'h ' : '';
  const mDisplay = m > 0 ? m + 'm ' : '';
  const sDisplay = s > 0 ? s + 's ' : '';
  return hDisplay + mDisplay + sDisplay;
};
