import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ExportButton from '../../components/ExportExcel/ExportButton';
import FormContainer from '../../components/Forms/FormContainer';
import FPBTable from '../../components/FPBTable/FPBTableIndustrial';
import ActionConfirmation from '../../components/ActionConfirmation/ActionConfirmation';
import HeaderTitle from '../../components/CoreComponents/PageTitle/PageTitle';
import useForm from '../../components/useForm';
import { setTotalCountAction } from '../../redux/actions/paginationAction';
import { getExportDataAsync } from '../../redux/slices/exportSlice';
import * as FaIcons from 'react-icons/fa';
import Urls from '../../components/Urls';
import { notification } from '../../components/AlertMessage/ToastifyAlert';
import apiService from '../../components/apiService';
import ActionConfirmationWarning from '../../components/ActionConfirmation/ActionConfirmationWarning';
import { Link } from 'react-router-dom';
import { getDeleteMessages, getInfoToDelete } from '../../utils/productionOrdersDeletion';
import {
  deleteProductionOrder,
  deleteProductPerProductionOrders,
  touchedOrder,
  setCurrentProductionOrderCode,
} from '../../redux/slices/productionOrderSlice';

const ProductionOrderSection = ({ formName, title }) => {
  const [addReload, setAddReload] = useState(false);
  const [url, setUrl] = useState(Urls.productionOrder);
  const [addForm, setAddForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteRows, setDeleteRows] = useState();
  const [deleteChange, setDeleteChange] = useState(false);
  const [addedRow, setAddedRow] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageUpdate, setErrorMessageUpdate] = useState('');
  const [columnsToHide, setColumnsToHide] = useState();
  const [handleFormName, setHandleFormName] = useState(formName);
  const [handleTitle, setHandleTitle] = useState(title);
  const [columnOrder, setColumnOrder] = useState(title);
  const [editOnly, setEditOnly] = useState(false);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState('');
  const [selected, setSelected] = useState([]);
  const [disableClose, setDisableClose] = useState(true);
  const [enableSelection, setEnableSelection] = useState(true);
  const [closeConfirmationMessage, setCloseConfirmationMessage] = useState('');
  const [closeOrderSuccess, setCloseOrderSuccess] = useState(false);

  // pagination
  const [filters, setFilters] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);

  //change view
  const [view, setView] = useState('orders');
  const dispatch = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatch(setTotalCountAction(totalCount));
  };

  const industrialClient = apiService.industrialClient();

  useEffect(() => {
    dispatch(setCurrentProductionOrderCode(null));
  }, [addForm]);

  const handleView = () => {
    if (view == 'orders') {
      setView('products');
      setUrl(Urls.productPerProductionOrders);
      setHandleTitle('Product per production order');
      setHandleFormName('productPerProductionOrder');
      setDisableClose(true);
      setEnableSelection(false);
    } else {
      setView('orders');
      setUrl(Urls.productionOrder);
      setHandleTitle('Production order');
      setHandleFormName('productionOrder');
      setEnableSelection(true);
    }
  };

  useEffect(() => {
    if (formName === 'quality') {
      setEditOnly(true);
    }
  }, []);

  useEffect(() => {
    alertMessage !== '' ? notification(alertMessage, 'success') : null;
  }, [alertMessage]);

  useEffect(() => {
    errorMessage !== '' ? notification(errorMessage, 'error') : null;
  }, [errorMessage]);

  useEffect(() => {
    if (view === 'products') {
      setColumnsToHide([
        //hide adicional field from produtionOrder
        'operation_center',
        'loading_date',
        'start_date',
        'end_date',
        'planned_closing_date',
        'assignment_date',
        'manufacturing_status',
        'responsible',
        'work_center_category',
        'work_center_description',
        'work_center_status',
        'product_category',
        'product_description',
        //hide adicional measures from productsPerProductionOrder
        'amount_2',
        'amount_3',
        'dimensional_unit_2',
        'dimensional_unit_3',
        'dimensional_unit_1_description',
        //hide metadata fields
        'created_by',
        'created_at',
        'updated_by',
        'updated_at',
      ]);
      setColumnOrder([
        'production_order',
        'product',
        'process',
        'work_center',
        'production_status',
        'amount_1',
        'dimensional_unit_1',
        'dispatch_date',
        'production_batch',
      ]);
    } else {
      setColumnsToHide([
        'operation_center',
        'dimensional_unit_1_description',
        'product',
        //hide metadata fields
        'created_by',
        'created_at',
        'updated_by',
        'updated_at',
      ]);
      setColumnOrder([
        'code',
        'operation_center_description',
        'manufacturing_status',
        'loading_date',
        'start_date',
        'end_date',
        'planned_closing_date',
        'assignment_date',
      ]);
    }
  }, [view]);

  const { handleChange, values, handleAPIRequest, handleAPIRequestUpdate, added, edited } = useForm(
    url,
    setAddForm,
    setAlertMessage,
    setErrorMessage,
    setErrorMessageUpdate
  );

  const onAdded = (r) => {
    setAddForm(false);
    setAddedRow(r);
    setAddReload(!addReload);
  };

  const handleDeleteConfirmation = async () => {
    const type = handleFormName; //productionOrder or productPerProductionOrder
    const productPerProductionOrdersIdList = await getInfoToDelete(deleteRows, type);
    const productionOrderCode = deleteRows[0];
    type === 'productionOrder'
      ? dispatch(deleteProductionOrder({ productionOrderCode, productPerProductionOrdersIdList }))
      : dispatch(deleteProductPerProductionOrders(productPerProductionOrdersIdList));
    setDeleteChange(true);
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
    setShowWarning(false);
    setDeleteConfirmationMessage(null);
    setShowCloseConfirmation(false);
  };

  //handle delete messages
  const handleDelete = async (id) => {
    setDeleteChange(false);
    setDeleteConfirmationMessage(null);
    setDeleteRows(id);
    const type = handleFormName;
    const deleteMessages = await getDeleteMessages(id, type);
    setDeleteConfirmationMessage(
      deleteMessages?.message || 'Error cannot delete this production order'
    );
    if (deleteMessages && deleteMessages?.type === 'confirmation') {
      setShow(true);
    } else if (deleteMessages && deleteMessages?.type === 'warning') {
      setShowWarning(true);
    } else {
      setShowWarning(true);
    }
  };

  const nullableFields = [
    'dimensional_unit_2',
    'dimensional_unit_2',
    'amount_2',
    'amount_3',
    'dispatch_date',
    'responsible',
    'work_center',
    //ProductionOrder
    'loading_date',
    'start_date',
    'end_date',
    'planned_closing_date',
    'assignment_date',
    'manufacturing_status',
  ];

  // data is the array of modified fields ([{field_name:value},{}...{}])
  const handleEdit = async (id, data) => {
    // get the name of the fields to edit
    const fieldsName = Object.keys(data);
    const editedRow = {};
    const res = await Promise.all(
      fieldsName.map(async (fieldName) => {
        // if the edited field is of type date or integer we send null instead of '' (when deleting field content)
        if (nullableFields.includes(fieldName) && data[fieldName] === '') {
          editedRow[fieldName] = null;
          return editedRow;
        }
        editedRow[fieldName] = data[fieldName];
        return editedRow;
      })
    );
    const dataToUpdate = res[res.length - 1];
    try {
      await industrialClient.patch(`${url}/${id}`, { ...dataToUpdate });
    } catch {
      (err) => console.log(err);
    }
  };

  const getQueryString = () => {
    let filter = filters
      .reduce((acc, { columnName, operation, value }) => {
        switch (operation) {
          case 'contains': {
            acc.push(`${columnName}__icontains=${encodeURIComponent(value)}`);
            break;
          }
          case 'equal': {
            acc.push(`${columnName}__exact=${encodeURIComponent(value)}`);
            break;
          }
          default:
            acc.push(`${columnName}__${operation}=${encodeURIComponent(value)}`);
            break;
        }
        return acc;
      }, [])
      .join('&');

    if (filters.length > 1) {
      filter = `${filter}`;
    }
    const urlTable = `${url}?page=${currentPage + 1}&page_size=${pageSize}&${filter}`;
    return { urlTable, filter };
  };

  const updateReload = useSelector(touchedOrder);

  useEffect(() => {
    const queryString = getQueryString();
    const getTableData = async () => {
      try {
        const res = await industrialClient.get(queryString.urlTable);
        const { results } = res.data;
        setTotalCount(res.data.count);
        if (results.length > 0) {
          // discard the fields the user will not visualize in tables
          const headersList = Object.keys(results[0]).filter((elem) => {
            let returnColumn;
            if (elem === 'id') {
              returnColumn = false;
            } else {
              returnColumn = true;
            }
            return returnColumn;
          });
          setHeaders(headersList);
          setTableData(results);
        } else {
          setTableData([]);
          setHeaders([]);
        }
      } catch {
        (err) => {
          console.log('error: ', err);
        };
      }
      setAddForm(false);
      setLastQuery(queryString.urlTable);
    };
    getTableData();
  }, [url, pageSize, currentPage, addReload, closeOrderSuccess, updateReload, filters]);

  const handleExport = async () => {
    const queryString = getQueryString();
    dispatch(getExportDataAsync({ urlExport: url, filters: queryString.filter }));
  };

  const handleSelectionChange = (selection) => {
    const lastSelection = selection[selection.length - 1];
    if (lastSelection) {
      setSelected([lastSelection]);
      setDisableClose(false);
      setCloseConfirmationMessage(`Are you sure you want to close order: ${lastSelection}?`);
    }
  };

  const handleCloseConfirmation = async () => {
    setShowCloseConfirmation(false);
    if (selected.length > 0) {
      try {
        const res = await apiService.postEndOrderAtAnyPoint({ production_order: selected[0] });
        if (res.status === 201) {
          setAlertMessage('Order closed successfully');
          setCloseOrderSuccess((prev) => !prev);
        }
      } catch (err) {
        setErrorMessage(err.error);
        console.log(err);
      }
    }
  };

  return (
    <>
      <HeaderTitle title={title} />

      <div className="btn-container d-flex justify-content-between">
        <div>
          <Button
            className="fpb-btn "
            onClick={() => {
              setAddForm(true);
            }}
          >
            Add
          </Button>
          <Modal show={addForm} onHide={setAddForm} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Add {title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormContainer
                handleChange={handleChange}
                values={values}
                handleAPIRequest={handleAPIRequest}
                added={added}
                formName={formName}
                onAdded={onAdded}
                setAddForm={setAddForm}
                setAlert={setAlertMessage}
                setError={setErrorMessage}
                handleView={handleView}
              />
            </Modal.Body>
          </Modal>
          <ExportButton
            formName={formName}
            fileName={title + ' Table'}
            handleExport={handleExport}
          />
          <Button className="fpb-btn-inverse mr-4">
            <Link to="/upload/simplified_production_order">Import</Link>
          </Button>
          <Button
            className="fpb-btn-inverse mr-4"
            disabled={disableClose}
            onClick={() => {
              setShowCloseConfirmation(true);
            }}
          >
            Close Order
          </Button>
        </div>
        <Button
          pill="true"
          className="product ml-4"
          onClick={() => {
            handleView();
          }}
        >
          {view == 'orders' ? <FaIcons.FaBoxes /> : <FaIcons.FaClipboard />}
        </Button>
      </div>

      <ActionConfirmation
        show={show}
        handleClose={handleClose}
        handleConfirmation={handleDeleteConfirmation}
        message={deleteConfirmationMessage}
      />
      <ActionConfirmation
        show={showCloseConfirmation}
        handleClose={handleClose}
        handleConfirmation={handleCloseConfirmation}
        message={closeConfirmationMessage}
      />
      <ActionConfirmationWarning
        show={showWarning}
        handleClose={handleClose}
        title={'Warning'}
        message={deleteConfirmationMessage}
      />

      <FPBTable
        withSelection={enableSelection}
        onSelectionChange={handleSelectionChange}
        selection={selected}
        readOnly={editOnly || false}
        editOnly={!editOnly}
        headers={headers}
        tableData={tableData}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        deleteChange={deleteChange}
        addedRow={addedRow}
        columnsToHide={columnsToHide}
        handleChange={handleChange}
        handleAPIRequestUpdate={handleAPIRequestUpdate}
        added={added}
        formName={handleFormName}
        onAdded={onAdded}
        setAddForm={setAddForm}
        setAlert={setAlertMessage}
        setError={setErrorMessageUpdate}
        edited={edited}
        errorMessageUpdate={errorMessageUpdate}
        title={handleTitle}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        customColumnOrder={columnOrder}
        setFilters={setFilters}
      />
    </>
  );
};

export default ProductionOrderSection;
