export const getColumnsToHideAndOrder = (formName) => {
  switch (formName) {
    case 'product':
      return {
        columnsToHide: [
          'product_category_description',
          'product_parent_category',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
        columnOrder: [
          'code',
          'description',
          'product_category',
          'product_category_description',
          'product_parent_category',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'unitTransformation':
      return {
        columnsToHide: [
          'work_center_description',
          'work_center_category',
          'variable_description',
          'entry_dimensional_unit_description',
          'output_dimensional_unit_description',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
        columnOrder: [
          'work_center',
          'product',
          'variable',
          'entry_dimensional_unit',
          'output_dimensional_unit',
          'transformation_value',
          'work_center_description',
          'work_center_category',
          'variable_description',
          'entry_dimensional_unit_description',
          'output_dimensional_unit_description',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'workCenter':
      return {
        columnsToHide: [
          'work_center_category_description',
          'work_center_parent_category',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
        columnOrder: [
          'code',
          'description',
          'status',
          'work_center_category',
          'operation_center',
          'work_center_category_description',
          'work_center_parent_category',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'workCenterCapacity':
      return {
        columnsToHide: [
          'work_center_description',
          'work_center_category',
          'work_center_status',
          'product_description',
          'product_category',
          'process_description',
          'variable_description',
          'dimensional_unit_description',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
        columnOrder: [
          'work_center',
          'product',
          'process',
          'variable',
          'rated_capacity',
          'dimensional_unit',
          'minimum_unit_standard_time',
          'maximum_threshold',
          'minimum_threshold',
          'work_center_description',
          'work_center_category',
          'work_center_status',
          'product_description',
          'product_category',
          'process_description',
          'variable_description',
          'dimensional_unit_description',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'productCategory':
      return {
        columnsToHide: ['created_by', 'created_at', 'updated_by', 'updated_at'],
        columnOrder: [
          'code',
          'description',
          'parent_category',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'workCenterCategory':
      return {
        columnsToHide: ['created_by', 'created_at', 'updated_by', 'updated_at'],
        columnOrder: [
          'code',
          'description',
          'parent_category',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'eventType':
      return {
        columnsToHide: ['created_by', 'created_at', 'updated_by', 'updated_at'],
        columnOrder: [
          'code',
          'description',
          'planned',
          'event_category',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'variable':
      return {
        columnsToHide: ['created_by', 'created_at', 'updated_by', 'updated_at'],
        columnOrder: [
          'code',
          'description',
          'calculation_variable',
          'comments',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'route':
      return {
        columnsToHide: [
          'product_description',
          'product_category',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
        columnOrder: [
          'product',
          'process',
          'process_description',
          'process_order',
          'product_description',
          'product_category',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'workCenterPerProcess':
      return {
        columnsToHide: [
          'work_center_description',
          'work_center_category',
          'work_center_status',
          'process_description',
          'variable_description',
          'entry_dimensional_unit_description',
          'output_dimensional_unit_description',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
        columnsOrder: [
          'process',
          'work_center',
          'variable',
          'data_source',
          'entry_dimensional_unit',
          'output_dimensional_unit',
          'partial_reporting_time',
          'comments',
          'work_center_description',
          'work_center_category',
          'work_center_status',
          'process_description',
          'variable_description',
          'entry_dimensional_unit_description',
          'output_dimensional_unit_description',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'quality':
      return {
        columnsToHide: [
          'work_center_description',
          'work_center_category',
          'shift_start_hour',
          'shift_end_hour',
          'product_description',
          'dimensional_unit_description',
          'quality_dimension_description',
          'order_operation_center',
          'order_loading_date',
          'order_start_date',
          'order_end_date',
          'order_planned_closing_date',
          'order_assignment_date',
          'order_manufacturing_status',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
        columnOrder: [
          'work_center',
          'work_center_description',
          'work_center_category',
          'shift',
          'shift_start_hour',
          'shift_end_hour',
          'production_order',
          'order_operation_center',
          'order_loading_date',
          'order_start_date',
          'order_end_date',
          'order_planned_closing_date',
          'order_assignment_date',
          'order_manufacturing_status',
          'product',
          'product_description',
          'start_hour',
          'end_hour',
          'quantity_nonconforming',
          'dimensional_unit',
          'dimensional_unit_description',
          'quality_dimension',
          'quality_dimension_description',
          'comments',
          'evidence_file',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
      };
    case 'productionBatch':
      return {
        columnsToHide: [
          'rated_life',
          'extended_lifetime',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ],
        columnOrder: [],
      };
    default:
      return {
        columnsToHide: ['created_by', 'created_at', 'updated_by', 'updated_at'],
        columnOrder: [],
      };
  }
};
