import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ProductionOrderProcessSection from '../../pages/industrial/ProductionOrderProcessSection';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ProductionOrderModal = ({ show, handleCloseProductionOrder }) => {
  const [showEvent, setShowEvent] = useState(false);
  const productPerProductionOrder = useSelector(
    (state) => state.productPerProductionOrders.currentProductPerProductionOrder
  );

  return (
    <Modal show={show} onHide={handleCloseProductionOrder} className="fpb-modal">
      <Modal.Header closeButton>
        <Modal.Title>{`Production Order Processing ${productPerProductionOrder?.production_order}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductionOrderProcessSection setShowEvent={setShowEvent} />
      </Modal.Body>
    </Modal>
  );
};

ProductionOrderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleCloseProductionOrder: PropTypes.func.isRequired,
};

export default ProductionOrderModal;
