import { React, useRef, useLayoutEffect } from 'react';
import { select, axisBottom, scaleBand } from 'd3';

export const XAxisBarChart = ({ xDomain, tickCount, width, height, data }) => {
  const xAxisRef = useRef(null);

  useLayoutEffect(() => {
    const x = scaleBand()
      .domain(data.map((d, i) => i))
      .range([70, width - 20]);
    const axis = axisBottom;
    const xAxis = axis(x)
      .ticks(data.length)
      .tickFormat((d) => (data[d] ? data[d].storageCenter : ''));

    xAxis.tickSizeOuter(0);
    select(xAxisRef.current).attr('class', 'axisX').call(xAxis).style('font-size', '13px');

    select(xAxisRef.current).selectAll('line').style('color', 'gray');
  }, [tickCount, width, xDomain]);

  return <g transform={`translate(0, ${height - 60})`} ref={xAxisRef} />;
};
