import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const getExportDataAsync = createAsyncThunk(
  'exportData/getExportDataAsync',
  async ({ urlExport, filters }) => {
    try {
      const res = await apiService.getExportData(urlExport, filters);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const exportDataSlice = createSlice({
  name: 'exportData',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    getExportData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExportDataAsync.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getExportDataAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getExportDataAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { getExportData } = exportDataSlice.actions;
export const showExportData = (state) => state.exportData.data;
export const exportDataLoadingState = (state) => state.exportData.loading;
export const exportDataErrorState = (state) => state.exportData.error;

export default exportDataSlice.reducer;
