import React from 'react';
import Urls from '../Urls';
import TableSection from './TableSection';

const ManualReportTableSection = () => {
  return (
    <TableSection
      url={Urls.manualQuantities}
      formName="manualReport"
      title="Manual Report"
      disableAdd
      disableDelete
      disableEdit
      columnToTransform={[]}
    />
  );
};

export default ManualReportTableSection;
