import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { showTimeAnalysis } from '../../../redux/slices/timeAnalysisSlice';
import { showSeriesView } from '../../../redux/slices/indicatorFilterSlice';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup, Row } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';
import { showFilterList } from '../../../redux/slices/indicatorFilterSlice';

const Time = ({ indicatorName }) => {
  const see = useSelector(showSeriesView);
  const timeAnalysisData = useSelector(showTimeAnalysis);
  const filters = useSelector((state) => state.timeAnalysis.filters);
  const { workCenter, product, productionOrder, shift, from, to, temporality } = filters;
  const [subtitle, setSubtitle] = useState('');
  const data = timeAnalysisData?.time;
  const [categories, setCategories] = useState([]);
  const [seriesByMinutes, setSeriesByMinutes] = useState([]);
  const [seriesByHours, setSeriesByHours] = useState([]);
  const [paginationCount, setPaginationCount] = useState(0);
  const filterList = useSelector(showFilterList);

  useEffect(() => {
    const subtitle = `Filtered by work center ${workCenter}${
      product ? ', product ' + product : ''
    }${productionOrder ? ', production order ' + productionOrder : ''}${
      shift ? ', shift ' + shift : ''
    }, from ${from} to ${to} ${'(' + temporality + ')'}.`;
    setSubtitle(subtitle);
  }, [filters]);

  useEffect(() => {
    if (!data) {
      setSeriesByHours([]);
      setSeriesByMinutes([]);
      setCategories([]);
    } else {
      const timeByMinutes = data?.timeByMinutes || [];
      const timeByHours = data?.timeByHours || [];
      const dates = timeAnalysisData?.dates || [];
      setSeriesByHours(timeByHours);
      setSeriesByMinutes(timeByMinutes);
      setCategories(dates);
    }
  }, [data]);

  const options = {
    chart: {
      type: 'bar',
    },
    colors: ['#ff3c77', '#49158b', '#ff4f2b', '#08bfff', '#ffae16'],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
      },
      offsetY: -20,
    },
    stroke: {
      show: true,
      width: 2,
    },
    title: {
      text: 'Time Analysis',
    },
    subtitle: {
      text: subtitle,
      align: 'left',
      offsetY: 20,
      style: {
        color: '#333',
        fontSize: '12px',
      },
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: see,
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      padding: {
        left: 30,
        right: 30,
      },
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
  };

  const [showTableData, setShowTableData] = useState(false);
  const handleCloseModal = () => {
    setShowTableData(false);
  };

  // Set Produced Times table data
  const [excelTableData, setExcelTableData] = useState([]);
  useEffect(() => {
    let data = [];
    let series = [];
    for (let i = 0; i < categories.length; i++) {
      see == 'minutes' ? (series = seriesByMinutes) : (series = seriesByHours);

      data.push({
        date: categories[i],
        working: series[0]?.data[i] || 0,
        planned: series[1]?.data[i] || 0,
        unplanned: series[2]?.data[i] || 0,
        not_reported: series[3]?.data[i] || 0,
      });
    }
    setExcelTableData(data);
    setPaginationCount(data.length);
  }, [categories]);

  return (
    <>
      <Card className="p-3 my-2">
        <SimpleTableModal
          title={'Time Analysis'}
          headers={['date', 'working', 'planned', 'unplanned', 'not_reported']}
          tableData={excelTableData}
          show={showTableData}
          handleClose={handleCloseModal}
          totalCount={paginationCount}
        />
        <div>
          <Row className="flex-row-reverse">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle className="fpb-btn-chart" id="dropdown-download" />
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowTableData(true);
                  }}
                >
                  Show Data
                </Dropdown.Item>
                <ExportExcel
                  excelData={excelTableData}
                  fileName={indicatorName + ' Report (' + from + ' -' + to + ')'}
                  indicator={indicatorName}
                  filters={subtitle}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </div>
        <Chart
          options={JSON.parse(JSON.stringify(options))}
          series={JSON.parse(JSON.stringify(see == 'minutes' ? seriesByMinutes : seriesByHours))}
          type="bar"
          height={420}
        />
      </Card>
    </>
  );
};

export default Time;
