import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';

const Module = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});

  const moduleValidate = Yup.object({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={moduleValidate}
        onSubmit={async (data) => {
          const processedValues = {
            name: data.name,
            description: data.description,
          };
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          if (data.id == null) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Module',
              code: data.nameModule,
            });
            if (created.statusText !== 'Created') {
              notification('Error creating item - duplicate item', 'error');
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Module',
              code: data.nameModule,
            });
            if (updated.statusText == 'OK') {
              onEdited(processedValues);
              setAddForm(false);
              notification('Item updated successfully', 'success');
            } else {
              notification('Error updating item - duplicate item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.id,
          name: values.name || '',
          description: values.description || '',
        }}
      >
        {({ values, touched, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField label="Module Name" name="name" type="text" value={values.name} />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Module;
