import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import { Card, Col, Row } from 'react-bootstrap';
import apiService from '../../apiService';
import { Formik } from 'formik';
import { showFilterList } from '../../../redux/slices/indicatorFilterSlice';
import { showHistoricValue } from '../../../redux/slices/historicalSlice';
import { notification } from '../../AlertMessage/ToastifyAlert';
import { SpinnerWithText } from '../../Loading';
import CustomSelectGroup from '../../FormComponents/CustomSelect/CustomSelectGroup';
import { ProgressBarWithText } from '../../Loading';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';
import { setDataQuality } from '../../../redux/slices/qualitySlice';

const IotProcessControlChart = ({ idx, workCenter, variable, dimensionalUnit }) => {
  const [intervalMs, setIntervalMs] = useState(2000);
  const now = useRef(new Date());
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [from, setFrom] = useState(now.current);
  const [data, setData] = useState([]);
  const [qualityData, setQualityData] = useState([]);
  const [product, setProduct] = useState('');
  const [minThreshold, setMinThreshold] = useState(-30);
  const [maxThreshold, setMaxThreshold] = useState(-30);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [codes, setCodes] = useState([]);
  const [nextDay, setNextDay] = useState();
  const [celeryTaskProgress, setCeleryTaskProgress] = useState(0);
  const [celeryTaskStatus, setCeleryTaskStatus] = useState('IDLE');
  const [celeryQualityTaskStatus, setCeleryQualityTaskStatus] = useState('IDLE');
  const [celeryQualityTaskProgress, setCeleryQualityTaskProgress] = useState(0);
  const [excelTableData, setExcelTableData] = useState([]);
  const [showTableData, setShowTableData] = useState(false);
  const [paginationCount, setPaginationCount] = useState(0);
  const filterList = useSelector(showFilterList);
  const [qualityOptions, setQualityOptions] = useState({
    chart: {
      id: 'qualityChart2' + idx,
      type: 'area',
      foreColor: '#000',
      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
    },
    colors: ['#00BAEC'],
    stroke: {
      width: 3,
    },
    grid: {
      borderColor: '#d8d8d8',
      clipMarkers: false,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.9,
        opacityTo: 0,
      },
    },
    markers: {
      size: 3,
      colors: ['#000524'],
      strokeColor: '#00BAEC',
      strokeWidth: 2,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (value, timestamp) {
          return new Date(timestamp).toLocaleTimeString();
        },
      },
    },
    yaxis: {
      title: {
        text: `${variable} (${dimensionalUnit})`,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box m-2">' +
          '<h5>' +
          new Date(w.globals.initialSeries[0].data[dataPointIndex][0]).toString().substring(0, 34) +
          '</h5>' +
          '<p>' +
          variable +
          ': ' +
          '<b>' +
          series[seriesIndex][dataPointIndex] +
          ' ' +
          dimensionalUnit +
          '</b>' +
          '</p>' +
          '<p>Product: ' +
          '<b>' +
          w.globals.initialSeries[0].data[dataPointIndex][2].code +
          ' - ' +
          w.globals.initialSeries[0].data[dataPointIndex][2].description +
          '</b>' +
          '</p>' +
          '<p>Production Order: ' +
          '<b>' +
          w.globals.initialSeries[0].data[dataPointIndex][3] +
          '</b>' +
          '</p>' +
          '</div>'
        );
      },
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
    annotations: {
      yaxis: [
        {
          y: minThreshold,
          stroke: {
            width: 7,
          },
          strokeDashArray: 0,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396',
            },
            text: 'Min',
          },
        },
        {
          y: maxThreshold,
          stroke: {
            width: 7,
          },
          strokeDashArray: 0,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396',
            },
            text: 'Max',
          },
        },
      ],
    },
  });

  const [options, setOptions] = useState({
    chart: {
      id: 'chart2' + idx,
      type: 'area',
      foreColor: '#000',
      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
    },
    colors: ['#00BAEC'],
    stroke: {
      width: 3,
    },
    grid: {
      borderColor: '#d8d8d8',
      clipMarkers: false,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.9,
        opacityTo: 0,
      },
    },
    markers: {
      size: 3,
      colors: ['#000524'],
      strokeColor: '#00BAEC',
      strokeWidth: 2,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (value, timestamp) {
          return new Date(timestamp).toLocaleTimeString();
        },
      },
    },
    yaxis: {
      title: {
        text: `${variable} (${dimensionalUnit})`,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box m-2">' +
          '<h5>' +
          new Date(w.globals.initialSeries[0].data[dataPointIndex][0]).toString().substring(0, 34) +
          '</h5>' +
          '<p>' +
          variable +
          ': ' +
          '<b>' +
          series[seriesIndex][dataPointIndex] +
          ' ' +
          dimensionalUnit +
          '</b>' +
          '</p>' +
          '<p>Product: ' +
          '<b>' +
          w.globals.initialSeries[0].data[dataPointIndex][2].code +
          ' - ' +
          w.globals.initialSeries[0].data[dataPointIndex][2].description +
          '</b>' +
          '</p>' +
          '<p>Production Order: ' +
          '<b>' +
          w.globals.initialSeries[0].data[dataPointIndex][3] +
          '</b>' +
          '</p>' +
          '</div>'
        );
      },
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
    annotations: {
      yaxis: [
        {
          y: minThreshold,
          stroke: {
            width: 7,
          },
          strokeDashArray: 0,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396',
            },
            text: 'Min',
          },
        },
        {
          y: maxThreshold,
          stroke: {
            width: 7,
          },
          strokeDashArray: 0,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396',
            },
            text: 'Max',
          },
        },
      ],
    },
  });

  // historical data
  const isHistoric = useSelector(showHistoricValue);
  const startDate = useSelector((state) => state.iotProcessControl.from);
  const endDate = useSelector((state) => state.iotProcessControl.to);

  const filterString = `Filtered by work center ${workCenter}, variable ${variable}, dimensional unit ${dimensionalUnit}, ${
    filterList.from
  } ${filterList.to ? '- ' + filterList.to + ' (historic)' : '(daily)'}.`;

  // Compute the next day in calendar, this is needed because the API returns the data from the start date to the end date, but not including the end date
  useEffect(() => {
    const today = new Date(endDate);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    setNextDay(tomorrow.toISOString().split('T')[0]);
    setProduct('');
    setInitialDataFetched(false);
  }, [endDate, startDate]);

  // Clenup the data of the filter by code
  useEffect(() => {
    setInitialDataFetched(false);
  }, [isHistoric]);

  // process the data from the API
  const processData = (data) => {
    // get small treshold bar chart
    if (data?.series.length > 0) {
      if (data?.series.length > 30 && !isHistoric) {
        const min = new Date(data?.series[30][0]).getTime();
        const max = new Date(data?.series[0][0]).getTime();
        setMinDate(min);
        setMaxDate(max);
      } else if (data?.series.length > 30 && isHistoric) {
        const min = new Date(data?.series[data.series.length - 30][0]).getTime();
        const max = new Date(data?.series[data.series.length - 1][0]).getTime();
        setMinDate(min);
        setMaxDate(max);
      } else {
        // set the movement bar chart to the whole data
        const min = new Date(data?.series[data?.series.length - 1][0]).getTime();
        const max = new Date(data?.series[0][0]).getTime();
        setMinDate(min);
        setMaxDate(max);
      }
    }

    // set max and min tresholds if exists, otherwise set to -30 (hide)
    if (data?.tresholds) {
      setMinThreshold(data.tresholds.min ?? -30);
      setMaxThreshold(data.tresholds.max ?? -30);
    }

    // if codes and descriptions, we prepare the data for the selection component
    if (!initialDataFetched && data?.codes.length > 0) {
      const products = data?.products;
      const codes = [];
      for (let i = 0; i < data?.codes.length; i++) {
        codes.push({
          label: `${products[i].code} - ${products[i].description}`,
          value: products[i].code,
        });
      }
      codes.push({ label: 'undefined', name: 'undefined' });
      setCodes(codes);
    }
  };

  //Export data
  const dataToExport = () => {
    let dataToExport = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        dataToExport.push({
          timestamp: new Date(data[i][0]).toLocaleString(),
          work_center: workCenter,
          variable: variable,
          dimensional_unit: dimensionalUnit,
          produced_units: data[i][1],
          production_order: data[i][3],
          product_code: data[i][2].code,
          product_description: data[i][2].description,
        });
      }
    }
    setExcelTableData(dataToExport);
    setPaginationCount(dataToExport.length);
  };

  const dataToExportWhithNonConforming = () => {
    let dataToExport = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        dataToExport.push({
          timestamp: new Date(data[i][0]).toLocaleString(),
          work_center: workCenter,
          variable: variable,
          dimensional_unit: dimensionalUnit,
          produced_units: data[i][1],
          non_conforming_units: qualityData.length > 0 ? qualityData[i][1] : '',
          production_order: data[i][3],
          product_code: data[i][2].code,
          product_description: data[i][2].description,
        });
      }
    }
    setExcelTableData(dataToExport);
    setPaginationCount(dataToExport.length);
  };

  useEffect(() => {
    if (qualityData.length == 0) {
      dataToExport();
    } else {
      dataToExportWhithNonConforming();
    }
  }, [data]);

  // Fetch initial data for the daily panel, this refetch the data every min
  const { isLoading } = useQuery({
    queryKey: [
      'iotProcessControl',
      workCenter,
      variable,
      product,
      from.toISOString().split('T')[0],
    ],
    queryFn: () =>
      apiService.getIotProcessControlFromDate(
        workCenter,
        variable,
        product,
        from.toISOString().split('T')[0]
      ),
    enabled: !isHistoric,
    refetchInterval: 60000,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      processData(data);
      console.log(data?.series);
      setData(data?.series);
      setInitialDataFetched(true);
    },
  });

  // Fetch initial data for the daily panel, this refetch the data every min for quality
  const { isLoadingQuality } = useQuery({
    queryKey: [
      'iotProcessControlQuality',
      workCenter,
      variable,
      product,
      from.toISOString().split('T')[0],
    ],
    queryFn: () =>
      apiService.getIotProcessControlQualityFromDate(
        workCenter,
        variable,
        product,
        from.toISOString().split('T')[0]
      ),
    enabled: !isHistoric,
    refetchInterval: 60000,
    refetchOnWindowFocus: false,
    onSuccess: (qualityData) => {
      console.log(qualityData?.series);
      setQualityData(qualityData?.series);
      setInitialDataFetched(true);
    },
  });

  // Fetch the task id for the historic panel
  const { data: taskId } = useQuery({
    queryKey: ['iotProcessControlHistoric', workCenter, variable, product, startDate, nextDay],
    queryFn: () => apiService.getIotSeriesTask(workCenter, variable, product, startDate, nextDay),
    enabled: isHistoric && !!nextDay,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      notification('Your task has been added to the queue, this may take several minutes', 'info');
    },
  });

  // fetch the data from the celery task
  useQuery({
    queryKey: ['iotProcessControlTask', taskId],
    queryFn: () => apiService.getCeleryTaskProgress(taskId),
    enabled: !!taskId,
    refetchInterval: intervalMs,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setIntervalMs(2000);
      setCeleryTaskProgress(data?.progress);
      setCeleryTaskStatus(data?.state);
      if (data.state === 'SUCCESS') {
        setIntervalMs(0);
        console.log(data?.result);
        processData(data?.result);
        setData(data?.result?.series);
        setInitialDataFetched(true);
      } else if (data.state === 'ERROR') {
        setIntervalMs(0);
        notification('There was an error processing your request, please try again', 'error');
      }
    },
  });

  // Fetch the task id for the historic panel for quality
  const { data: qualityTaskId } = useQuery({
    queryKey: [
      'iotQualityProcessControlHistoric',
      workCenter,
      variable,
      product,
      startDate,
      nextDay,
    ],
    queryFn: () =>
      apiService.getIotQualitySeriesTask(workCenter, variable, product, startDate, nextDay),
    enabled: isHistoric && !!nextDay,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      notification('Your task has been added to the queue, this may take several minutes', 'info');
    },
  });

  // fetch the data from the celery task for quality
  useQuery({
    queryKey: ['iotQualityProcessControlTask', qualityTaskId],
    queryFn: () => apiService.getCeleryTaskProgress(qualityTaskId),
    enabled: !!qualityTaskId,
    refetchInterval: intervalMs,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setIntervalMs(2000);
      setCeleryQualityTaskProgress(data?.progress);
      setCeleryQualityTaskStatus(data?.state);
      if (data.state === 'SUCCESS') {
        console.log(data?.result?.series);
        setQualityData(data?.result?.series);
      } else if (data.state === 'ERROR') {
        setIntervalMs(0);
        notification('There was an error processing your request, please try again', 'error');
      }
    },
  });

  const series = [
    {
      data: data,
    },
  ];

  const qualitySeries = [
    {
      data: qualityData,
    },
  ];

  const seriesLine = [
    {
      data: data,
    },
  ];
  const optionsLine = {
    chart: {
      id: 'chart1' + idx,
      height: 130,
      type: 'line',
      foreColor: '#000',
      brush: {
        target: 'chart2' + idx,
        enabled: true,
        autoScaleYaxis: true,
      },
      selection: {
        enabled: true,
        xaxis: {
          min: minDate,
          max: maxDate,
        },
      },
    },
    colors: ['#FF0080'],
    stroke: {
      width: 2,
    },
    grid: {
      borderColor: '#d8d8d8',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (value, timestamp) {
          return new Date(timestamp).toLocaleString();
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      tickAmount: 2,
    },
  };

  const qualityOptionsLine = {
    chart: {
      id: 'qualityChart1' + idx,
      height: 130,
      type: 'line',
      foreColor: '#000',
      brush: {
        target: 'qualityChart2' + idx,
        enabled: true,
        autoScaleYaxis: true,
      },
      selection: {
        enabled: true,
        xaxis: {
          min: minDate,
          max: maxDate,
        },
      },
    },
    colors: ['#FF0080'],
    stroke: {
      width: 2,
    },
    grid: {
      borderColor: '#d8d8d8',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (value, timestamp) {
          return new Date(timestamp).toLocaleString();
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      tickAmount: 2,
    },
  };

  // show spinner while data is being fetched
  if (isLoading && isLoadingQuality && !isHistoric)
    return (
      <Row className="justify-content-center" style={{ 'margin-top': '100px' }}>
        <SpinnerWithText text="Loading Data..." />
      </Row>
    );

  // show progress bar for celery task
  if (isHistoric && celeryTaskStatus === 'PROGRESS')
    return (
      <Row className="justify-content-center" style={{ 'margin-top': '100px' }}>
        <ProgressBarWithText
          progress={celeryTaskProgress.current}
          label={`${celeryTaskProgress.current}`}
          text={"We're working on your request"}
        />
      </Row>
    );

  const handleCloseModal = () => {
    setShowTableData(false);
  };

  return (
    <Formik validateOnChange={true}>
      <>
        <Card Card className="p-3 my-2">
          <Card.Body>
            <Row className="pl-4 pb-3 d-flex ">
              <h4 className="float-start">
                {workCenter} | {variable} | {dimensionalUnit}
              </h4>
              <Col>
                <CustomSelectGroup
                  label={'Filter by product'}
                  name={'byCode'}
                  isClearable={true}
                  onChange={(option) => {
                    setProduct(option ? option.value : '');
                  }}
                  options={codes}
                  value={{
                    value: product,
                    label: product,
                  }}
                />
              </Col>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle className="fpb-btn-chart mb-3" id="dropdown-download" />
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setShowTableData(true);
                    }}
                  >
                    Show Data
                  </Dropdown.Item>
                  <ExportExcel
                    excelData={excelTableData}
                    fileName={
                      'IoT Process Control Report (' + filterList[0] + ' -' + filterList[1] + ')'
                    }
                    indicator="IoT Process Control"
                    filters={filterString}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Row>
            <SimpleTableModal
              title={'IoT Process Control'}
              headers={
                qualityData.length > 0
                  ? [
                      'timestamp',
                      'work_center',
                      'variable',
                      'dimensional_unit',
                      'produced_units',
                      'non_conforming_units',
                      'production_order',
                      'product_code',
                      'product_description',
                    ]
                  : [
                      'timestamp',
                      'work_center',
                      'variable',
                      'dimensional_unit',
                      'produced_units',
                      'production_order',
                      'product_code',
                      'product_description',
                    ]
              }
              tableData={excelTableData}
              show={showTableData}
              handleClose={handleCloseModal}
              totalCount={paginationCount}
            />
            <div id="wrapper">
              <div id={'chart-line2' + idx}>
                <Chart options={options} series={series} type="line" height={320} />
              </div>
              <div id={'chart-line' + idx}>
                <Chart options={optionsLine} series={seriesLine} type="area" height={130} />
              </div>
            </div>

            {qualityData.length !== 0 && (
              <div id="wrapper">
                <h5 className="mt-4">Quality - Nonconforming quantity</h5>
                <div id={'quality-chart-line2' + idx}>
                  <Chart options={qualityOptions} series={qualitySeries} type="line" height={320} />
                </div>
                <div id={'quality-chart-line' + idx}>
                  <Chart
                    options={qualityOptionsLine}
                    series={qualitySeries}
                    type="area"
                    height={130}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    </Formik>
  );
};

export default IotProcessControlChart;
