import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FPBTable from '../../FPBTable/FPBTable';
import TableModal from '../../TableModal/SimpleTableModal';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import getTransactionTableData from '../getTransactionTableData';
import Urls from '../../Urls';
import { Alert } from 'react-bootstrap';
import ActionConfirmCustomMessage from '../../ActionConfirmation/ActionConfirmCustomMessage';
import { columnsToHide } from '../transactionTableColumns';
import {
  setCurrentPageAction,
  setIsModalPaginationAction,
  setModalCurrentPageAction,
  setModalPageSizeAction,
  setModalTotalCountAction,
  setTotalCountAction,
} from '../../../redux/actions/paginationAction';
import mapTable from '../../mapTableService';
import { notification } from '../../AlertMessage/ToastifyAlert';
import getQueryString from '../../../utils/getTransactionsQueryString';

const AssociateOrders = () => {
  const urlTransactions = Urls.transaction;
  const [token, setToken] = useState(localStorage.getItem('accessToken') || null);
  const [headers, setHeaders] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [listTitle, setListTitle] = useState('Unassociated transactions');
  const [selected, setSelected] = useState(true);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [orderCodeMessage, setOrderCodeMessage] = useState('');
  const [title, setTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  // Emergent tables (modals)
  const [orderData, setOrderData] = useState([]);
  const [orderHeaders, setOrderHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [modalCall, setModalCall] = useState('');
  const [url, setUrl] = useState(null);
  const [formName, setFormName] = useState(null);
  const [urlEntryOrder] = useState(Urls.entryOrder);
  const [urlDispatchOrder] = useState(Urls.dispatchOrder);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  //Filters
  const [associatedOrder, setAssociatedOrder] = useState('no');
  const [entryTransaction, setEntryTransaction] = useState('');
  const [dispatchTransaction, setDispatchTransaction] = useState('');
  // pagination
  const [filters, setFilters] = useState([]);
  const [filtersModal, setFiltersModal] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const [lastQueryModal, setLastQueryModal] = useState(null);
  const modalCurrentPage = useSelector((state) => state.pagination.modalCurrentPage);
  const modalPageSize = useSelector((state) => state.pagination.modalPageSize);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const modalTotalCount = useSelector((state) => state.pagination.modalTotalCount);
  const dispatchTotalCount = useDispatch();
  const dispatchModalTotalCount = useDispatch();
  const dispatchIsModalPagination = useDispatch();
  const dispatchSetModalCurrentPage = useDispatch();
  const dispatchSetModalPageSize = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatchTotalCount(setTotalCountAction(totalCount));
  };
  const setModalTotalCount = (totalCount) => {
    dispatchModalTotalCount(setModalTotalCountAction(totalCount));
  };
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };
  const setModalCurrentPage = (currentPage) => {
    dispatchSetModalCurrentPage(setModalCurrentPageAction(currentPage));
  };
  const setModalPageSize = (pageSize) => {
    dispatchSetModalPageSize(setModalPageSizeAction(pageSize));
  };
  const dispatch = useDispatch();

  const handleShowEntryOrders = () => {
    setShow(true);
    setIsModalPagination();
    setModalCall('entry_orders');
    setFormName('entryOrder');
  };

  const handleShowDispatchOrders = () => {
    setShow(true);
    setIsModalPagination();
    setModalCall('dispatch_orders');
    setFormName('dispatchOrder');
  };

  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('accessToken');
      if (item) {
        setToken(item);
      }
    }

    window.addEventListener('storage', checkUserData);

    return () => {
      window.removeEventListener('storage', checkUserData);
    };
  }, []);

  useEffect(() => {
    const queryString = getQueryString(
      currentPage,
      pageSize,
      filters,
      `&associated_order=${associatedOrder}&entry_transaction=${entryTransaction}&dispatch_transaction=${dispatchTransaction}`
    );
    getTransactionTableData(
      queryString,
      lastQuery,
      token,
      setTotalCount,
      setHeaders,
      setTableData,
      setLastQuery
    );
  }, [
    urlTransactions,
    pageSize,
    currentPage,
    show,
    associatedOrder,
    entryTransaction,
    dispatchTransaction,
    filters,
  ]);

  useEffect(async () => {
    mapTable.createEntryOrderMap();
    mapTable.createDimensionalUnitMap();
    mapTable.createDispatchOrderMap();
    mapTable.createAdjustmentMap();
    mapTable.createStorageCenterMap();
    mapTable.createStorageLocationMap();
    mapTable.createSupplierMap();
    mapTable.createCustomerMap();
  }, []);

  useEffect(() => {
    alertMessage ? notification(alertMessage, 'success') : null;
    //tableData is reloaded when dispatchTransaction, entryTransaction or selectedOrder change because they are used in filters
    setDispatchTransaction('');
    setEntryTransaction('');
    setSelectedOrder({});
  }, [showNotification]);

  useEffect(() => {
    errorMessage ? notification(errorMessage, 'error') : null;
  }, [showNotification]);

  useEffect(() => {
    if (filters.length > 0) {
      dispatch(setCurrentPageAction(0));
    }
  }, [filters]);

  const listUnassociatedTransac = () => {
    setListTitle('Unassociated transactions');
    setAssociatedOrder('no');
    setDispatchTransaction('no');
    setEntryTransaction('no');
    setSelectedOrder({});
  };

  const listAssociatedTransac = () => {
    setListTitle('Associated transactions');
    setAssociatedOrder('yes');
    setDispatchTransaction('no');
    setEntryTransaction('no');
    setSelectedOrder({});
  };

  const handleSelectedTran = (selected) => {
    setSelectedTransactions(selected);
  };

  async function associateOrder(transaction, data, order_code) {
    const result = await axios
      .patch(`${urlTransactions}/${transaction}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          setAlertMessage(`Order with code ${order_code} associated successfully`);
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage('Error to associate the order. ' + err);
        return err;
      });
    setShowNotification(!showNotification);
    return result;
  }

  const handleCloseMessage = () => {
    setModalConfirmation(false);
  };

  const deleteOrder = () => {
    selectedTransactions.forEach((transaction) => {
      detachOrder(transaction);
    });
  };

  async function detachOrder(transaction) {
    const data = { entry_order_code: null, dispatch_order_code: null };
    const result = await axios
      .patch(`${urlTransactions}/${transaction}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          setAlertMessage('Order detached successfully');
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(`Error with status code: ${err.response.status.toString()}`);
        setAlertMessage(null);
        return err;
      });
    setShowNotification(!showNotification);
    //the following line is important to force tabledata reload
    listUnassociatedTransac();
    return result;
  }

  // Emergent table (modal) to select an order
  const handleSelectedOrder = (selected, title) => {
    setSelectedOrder(selected);
    setShow(false);
    setIsModalPagination();
    setModalCall('');
    setTitle(title);
  };

  useEffect(() => {
    setOrderCodeMessage(
      selectedOrder.entry_order_code
        ? `Entry order with code ${selectedOrder.entry_order_code} has been selected. Now select one or several transactions and press the apply button.`
        : `Dispatch order with code ${selectedOrder.dispatch_order_code} has been selected. Now select one or several transactions and press the apply button.`
    );
  }, [selectedOrder]);

  const applyChanges = () => {
    // show modal message to confirm changes
    setModalMessage('Are you sure to associate this order with the selected transactions?');
    setModalConfirmation(true);
  };

  const handleConfirmation = () => {
    if (title === 'entry_orders') {
      selectedTransactions.forEach((transaction) => {
        //To evoid associate dispatch transactions with entry orders
        const data = { entry_order_code: selectedOrder.id };
        associateOrder(transaction, data, selectedOrder.entry_order_code);
      });
    }
    if (title === 'dispatch_orders') {
      selectedTransactions.forEach((transaction) => {
        //To evoid associate entry transactions with dispatch orders
        const data = { dispatch_order_code: selectedOrder.id };
        associateOrder(transaction, data, selectedOrder.dispatch_order_code);
      });
    }
    setModalConfirmation(false);
  };

  const handleClose = () => {
    setShow(false);
    setIsModalPagination();
    setModalCall('');
  };

  // set emergent tables endpoint
  useEffect(() => {
    if (modalCall != '') {
      setShow(true);
    }
    switch (modalCall) {
      case 'entry_orders':
        setUrl(urlEntryOrder);
        break;

      case 'dispatch_orders':
        setUrl(urlDispatchOrder);
        break;
    }
  }, [modalCall]);

  const getQueryStringModal = () => {
    let filter = filtersModal
      .reduce((acc, { columnName, value }) => {
        switch (columnName) {
          case 'entry_order_code': {
            const orderEntryId = mapTable.getOrderEntryIdFromCode(value);
            acc.push(`${columnName}__id=${encodeURIComponent(orderEntryId)}`);
            break;
          }
          case 'supplier_identification': {
            const supplierId = mapTable.getSupplierIdFromCode(value);
            acc.push(`${columnName}__id=${encodeURIComponent(supplierId)}`);
            break;
          }

          case 'dispatch_order_code': {
            const orderDispatchId = mapTable.getOrderDispatchIdFromCode(value);
            acc.push(`${columnName}__id=${encodeURIComponent(orderDispatchId)}`);
            break;
          }
          case 'customer_identification': {
            const customerId = mapTable.getCustomerIdFromCode(value);
            acc.push(`${columnName}__id=${encodeURIComponent(customerId)}`);
            break;
          }

          default:
            acc.push(`${columnName}=${encodeURIComponent(value)}`);
            break;
        }
        return acc;
      }, [])
      .join('&');

    if (filtersModal.length > 1) {
      filter = `${filter}`;
    }

    let urlT;
    if (filter !== '') {
      urlT = `${url}?page=${modalCurrentPage + 1}&page_size=${modalPageSize}&${filter}`;
    } else {
      urlT = `${url}?page=${modalCurrentPage + 1}&page_size=${modalPageSize}&${filter}`;
    }
    return urlT;
  };

  const getTableDataModal = () => {
    const queryString = getQueryStringModal();
    if (queryString !== lastQueryModal) {
      axios
        .get(queryString, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (res) => {
          const { results } = res.data;
          setModalTotalCount(res.data.count);
          if (results.length > 0) {
            const headersList = Object.keys(results[0]).filter((item) => item !== 'id');

            if (modalCall !== '') {
              const tableInformation = await Promise.all(
                results.map(async (row) => {
                  const transformedResponse = {};
                  // make a request for each id to be converted to code in the current row
                  const [rowModified] = await Promise.all(
                    columnToTransform.map(async (transform) => {
                      if (row[transform.name] != null) {
                        const transformed = await axios
                          .get(`${Urls[transform.url]}/${row[transform.name]}`, {
                            headers: { Authorization: `Bearer ${token}` },
                          })
                          .then((res) => {
                            transformedResponse[transform.name] = res.data[transform.field];
                            return transformedResponse;
                          });

                        return transformed;
                      }
                      transformedResponse[transform.name] = null;
                      return transformedResponse;
                    })
                  );

                  // use default row and replace transformed columns with codes
                  const result = { ...row };
                  columnToTransform.map((transform) => {
                    result[transform.name] =
                      rowModified != null ? rowModified[transform.name] : null;
                  });
                  return result;
                })
              );

              setOrderHeaders(headersList);
              setOrderData(tableInformation);
            }
          } else {
            setOrderData([]);
            setOrderHeaders([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setLastQueryModal(queryString);
  };

  useEffect(() => {
    if (url !== null) {
      getTableDataModal();
    }
  }, [url, modalPageSize, modalCurrentPage, filtersModal, associatedOrder]);

  const columnToTransform = [
    {
      name: 'supplier_identification',
      url: 'supplier',
      field: 'supplier_identification',
    },
    { name: 'customer_identification', url: 'customer', field: 'customer_identification' },
  ];

  return (
    <div>
      <HeaderTitle title="Associate orders to transactions" />
      <ActionConfirmCustomMessage
        message={modalMessage}
        show={modalConfirmation}
        handleClose={handleCloseMessage}
        handleConfirmation={handleConfirmation}
      />
      <TableModal
        title={modalCall}
        headers={orderHeaders}
        tableData={orderData}
        show={show}
        handleClose={handleClose}
        handleSelectedOrder={handleSelectedOrder}
        totalCount={modalTotalCount}
        addButton={true}
        url={url}
        formName={formName}
        columnToTransform={columnToTransform}
        setFilters={setFiltersModal}
        confirmButton={true}
        setEntryTransaction={setEntryTransaction}
        setDispatchTransaction={setDispatchTransaction}
        setModalCurrentPage={setModalCurrentPage}
        currentPage={modalCurrentPage}
        setModalPageSize={setModalPageSize}
        pageSize={modalPageSize}
      />
      <Row className="btn-container">
        <DropdownButton className="ml-3 mr-2" id="dropdown-secondary-button" title={listTitle}>
          <Dropdown.Item onClick={listUnassociatedTransac}>Unassociated transactions</Dropdown.Item>
          <Dropdown.Item onClick={listAssociatedTransac}>Associated transactions</Dropdown.Item>
        </DropdownButton>
        <Button className="fpb-btn px-3" onClick={handleShowEntryOrders}>
          Associate entry order
        </Button>
        <Button className="fpb-btn  ml-2" onClick={handleShowDispatchOrders}>
          Associate dispatch order
        </Button>
        {associatedOrder !== 'no' ? (
          <Button className="fpb-btn-inverse  ml-2" disabled={selected} onClick={deleteOrder}>
            Disassociate orders
          </Button>
        ) : null}
      </Row>

      {selectedOrder.id ? (
        <Alert className="d-flex justify-content-between" variant="warning">
          {orderCodeMessage}
          <Button
            onClick={() => {
              applyChanges();
            }}
            variant="warning"
            disabled={selected}
          >
            Apply
          </Button>
        </Alert>
      ) : null}

      <FPBTable
        readOnly
        withCheckBox
        headers={headers}
        tableData={tableData}
        setSelected={setSelected}
        customColumnOrder={[]}
        columnsToHide={columnsToHide}
        handleSelectedTran={handleSelectedTran}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        setFilters={setFilters}
      />
    </div>
  );
};

export default AssociateOrders;
