import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';
import Urls from '../Urls';

const WorkCenterCategory = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [descriptions, setDescriptions] = useState({ workCenterCategoryDescription: '' });
  const [workCenterCategoryData, setWorkCenterCategoryData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const workCenterCategoryValidate = Yup.object({
    code: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string().required('Required'),
    parentCategory: Yup.string().nullable(),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(async () => {
    try {
      const resWorkCenterCategory = await apiService.getData(Urls.workCenterCategory, 'code');
      setWorkCenterCategoryData({
        headers: resWorkCenterCategory.headersList,
        tableData: resWorkCenterCategory.data,
        options: resWorkCenterCategory.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    workCenterCategoryData.options?.forEach((option) => {
      if (option.value === values.parent_category) {
        setDescriptions(
          option
            ? { ...descriptions, workCenterCategoryDescription: option.description }
            : { ...descriptions, workCenterCategoryDescription: '' }
        );
      }
    });
  }, [workCenterCategoryData]);

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={workCenterCategoryValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            code: data.code,
            description: data.description,
            parent_category: data.parentCategory || null,
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
          };
          setProcessedFormValues(processedValues);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Work Center Category',
              code: data.code,
            });
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Work Center Category',
              code: data.code,
            });
            if (updated.statusText == 'OK') {
              setAddForm(false);
              onEdited(processedValues);
            }
          }
        }}
        initialValues={{
          id: values.code,
          code: values.code || '',
          description: values.description || '',
          parentCategory: values.parent_category || null,
          createdBy: values.created_by || '',
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Work Center Category Code"
                name="code"
                type="string"
                value={values.code}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('parentCategory', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, workCenterCategoryDescription: option.description }
                      : { ...descriptions, workCenterCategoryDescription: '' }
                  );
                }}
                label="Parent Category"
                name="parentCategory"
                options={workCenterCategoryData.options}
                defaultValue={{
                  value: values.parentCategory,
                  label: values.parentCategory,
                }}
                description={descriptions.workCenterCategoryDescription}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WorkCenterCategory;
