import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import HeaderTitle from '../../components/CoreComponents/PageTitle/PageTitle';
import ProductInformation from '../../components/IndustrialForms/ProductInformation';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { MdTimeToLeave } from 'react-icons/md';
import StowedInformation from '../../components/IndustrialForms/StowedInformation';

const DocSection = () => {
  const [title, setTitle] = useState('PRODUCT INFORMATION');

  return (
    <div className="mb-3 pr-3">
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#PRODUCT_INFORMATION">
        <HeaderTitle title={title} />
        <Row>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="#PRODUCT_INFORMATION">
                <ProductInformation />
              </Tab.Pane>
              <Tab.Pane eventKey="#STOWED_INFORMATION">
                <StowedInformation />
              </Tab.Pane>
              <Tab.Pane eventKey="#PRODUCT_SPECIFICATION"></Tab.Pane>
              <Tab.Pane eventKey="#PRODUCT_DEFECTS"></Tab.Pane>
              <Tab.Pane eventKey="#COOKING_INSTRUCTIONS"></Tab.Pane>
              <Tab.Pane eventKey="#MICROBIOLOGICAL_CHEMICAL_INFORMATION"></Tab.Pane>
              <Tab.Pane eventKey="#PACKAGE_INFORMATION"></Tab.Pane>
              <Tab.Pane eventKey="#LABEL"></Tab.Pane>
            </Tab.Content>
          </Col>
          <Col sm={4} gap={2}>
            <h5>Sections</h5>
            <ListGroup>
              <ListGroup.Item
                action
                href="#PRODUCT_INFORMATION"
                onClick={() => setTitle('PRODUCT INFORMATION')}
              >
                PRODUCT INFORMATION
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#STOWED_INFORMATION"
                onClick={() => setTitle('STOWED INFORMATION')}
              >
                STOWED INFORMATION
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#PRODUCT_SPECIFICATION"
                onClick={() => setTitle('PRODUCT SPECIFICATION')}
              >
                PRODUCT SPECIFICATION
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#PRODUCT_DEFECTS"
                onClick={() => setTitle('PRODUCT DEFECTS')}
              >
                PRODUCT DEFECTS
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#COOKING_INSTRUCTIONS"
                onClick={() => setTitle('COCKING INSTRUCTIONS')}
              >
                COOKING INSTRUCTIONS
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#MICROBIOLOGICAL_CHEMICAL_INFORMATION"
                onClick={() => setTitle('MICROBIOLOGICAL & CHEMICAL INFORMATION')}
              >
                MICROBIOLOGICAL & CHEMICAL INFORMATION
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#PACKAGE_INFORMATION"
                onClick={() => setTitle('PACKAGE INFORMATION')}
              >
                PACKAGE INFORMATION
              </ListGroup.Item>
              <ListGroup.Item action href="#LABEL" onClick={() => setTitle('LABEL')}>
                LABEL
              </ListGroup.Item>
            </ListGroup>
            <Button className="fpb-btn mt-5" block>
              Preview
            </Button>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default DocSection;
