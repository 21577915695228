import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LogoFPB from '../CoreComponents/LogoFPB/LogoFPB';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';
import Urls from '../../components/Urls';
import axios from 'axios';

const ResetPasswordByEmail = () => {
  const requestPass = Urls.reset_password;
  const server_domain = window.location.hostname;
  const resetPasswordValidate = Yup.object({
    email: Yup.string().required('Required'),
  });

  const resendMail = async (userEmail) => {
    return await fetch(`${requestPass}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: String(userEmail.email).trim(),
        redirect_url: String(server_domain).trim(),
      }),
    });
  };

  return (
    <Formik
      validationSchema={resetPasswordValidate}
      onSubmit={async (data) => {
        const processedValues = {
          email: data.email,
        };
        try {
          let res;
          res = await resendMail(processedValues);
          const data = await res.json();
          if (data.success === 'We have sent you a link to reset your password') {
            notification('We have sent an email to your email address for your review.', 'success');
          } else if (data.error === 'error') {
            notification('Unable to send mail, user is not registered in the system', 'warning');
          }
        } catch (error) {
          console.log(error);
        }
      }}
      initialValues={{
        email: '',
      }}
    >
      {({ values, touched, handleBlur, handleSubmit }) => (
        <div
          className="row d-flex align-items-center"
          style={{ backgroundColor: '#FFFFFF', height: '50vh' }}
        >
          <div
            className="col-6 col offset-3 card-profile shadow p-5"
            style={{
              backgroundColor: '#E4E4E4',
              borderRadius: '10px',
            }}
          >
            <LogoFPB logoWidth="40%" />
            <Form className="text-left" onSubmit={handleSubmit}>
              <Form.Row>
                <TextField label="Email Address" name="email" type="email" value={values.email} />
              </Form.Row>
              <div style={{ float: 'right', color: '#08bfff' }}>
                <a href="/">Login</a>
              </div>
              <Button className="fpb-btn mt-3" type="submit">
                Send
              </Button>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};
export default ResetPasswordByEmail;
