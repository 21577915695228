import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import TableModal from '../../TableModal/TableModal';
import useForm from '../../useForm';
import TransactionsForm from '../TransactionsForm/TransactionsForm';
import AlertMessage from '../../AlertMessage/AlertMessage';
import Urls from '../../Urls';
import Data from '../../dataService';

const TransactionSection = ({ title, transactionType }) => {
  // Emergent tables (modals)
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [modalCall, setModalCall] = useState('');
  // api calls
  const [url, setUrl] = useState('');
  const [token] = useState(localStorage.getItem('accessToken'));
  // Filter elements according to existence in relation tables
  const [allowedItemCodes, setAllowedItemCodes] = useState([]);
  const [allowedBatches, setAllowedBatches] = useState([]);
  const [allowedCosts, setAllowedCosts] = useState([]);
  const [allowedStorageCenters, setAllowedStorageCenters] = useState([]);
  const [allowedStorageLocations, setAllowedStorageLocations] = useState([]);
  const [allowedStorageLocationDestination, setAllowedStorageLocationDestination] = useState([]);
  const [itemSelected, setItemSelected] = useState();
  const [batchSelected, setBatchSelected] = useState();
  const [costSelected, setCostSelected] = useState();
  const [storageCenterSelected, setStorageCenterSelected] = useState();
  const [storageCenterDestinationSelected, setStorageCenterDestinationSelected] = useState();
  // ibr = item batch relation
  const [ibrFilteredByItem, setIbrFilteredByItem] = useState([]);
  const [ibrFilteredByItemAndBatch, setIbrFilteredByItemAndBatch] = useState([]);
  const [itemStorageCenterRelation, setItemStorageCenterRelation] = useState([]);
  // messages
  const [errorMessage, setErrorMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  // automatic complete fields
  const [autoCompleted, setAutoCompleted] = useState({
    item_description: '',
    dimensional_unit_code: '',
    dimensional_unit_description: '',
  });
  const [user] = useState(JSON.parse(localStorage.getItem('user')).email || '');

  // URLs
  const [urlTransactions] = useState(Urls.transaction);
  const [urlItemBatchRelation] = useState(Urls.itemBatchRelation);
  const [urlItemStorageCenterRelation] = useState(Urls.itemStorageRelation);
  const [urlDimensionalUnit] = useState(Urls.dimensionalUnit);
  const [urlItem] = useState(Urls.item);
  const [urlBatch] = useState(Urls.batch);
  const [urlStorageCenter] = useState(Urls.storageCenter);
  const [urlStorageLocation] = useState(Urls.storageLocation);
  const [urlEntryOrder] = useState(Urls.entryOrder);
  const [urlDispatchOrder] = useState(Urls.dispatchOrder);
  const [urlAdjustmentConcept] = useState(Urls.adjustment);

  const { handleChange, values, setValues, handleAPIRequest, added } = useForm(
    urlTransactions,
    null,
    setAlertMessage,
    setErrorMessage,
    null
  );

  // Emergent tables (modals)
  const handleConfirmation = (selected, title) => {
    setShow(false);
    setModalCall('');
    if (title === 'items') {
      setValues({
        ...values,
        itemReferenceCode: {
          value: selected.reference_code,
          label: selected.reference_code,
          id: selected.id,
        },

        batch_code: '',
        dimensional_unit_code: selected.consolidation_level_N0,
      });
      setAutoCompleted({
        ...autoCompleted,
        item_description: selected.item_description,
      });
      setItemSelected(selected);
      axios
        .get(`${urlDimensionalUnit}/${selected.consolidation_level_N0}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setAutoCompleted({
            ...autoCompleted,
            item_description: selected.item_description,
            dimensional_unit_code: res.data.dimensional_unit_code,
            dimensional_unit_description: res.data.description,
          });
        });
    }
    if (title === 'batches') {
      const selectedItemBatchRelation = ibrFilteredByItem.filter(
        (el) => el.batch_code === selected.id
      );
      setValues({
        ...values,
        batch_code: selected.batch_code,
        item_batch_relation_code: selectedItemBatchRelation[0].id,
      });
    }
    if (title === 'entry_orders') {
      setValues({
        ...values,
        entry_order_code: selected.id,
        entry_order_code_show: selected.entry_order_code,
      });
    }
    if (title === 'adjustment') {
      setValues({
        ...values,
        entry_order_code: selected.id,
        entry_order_code_show: selected.entry_order_code,
      });
    }
    if (title === 'dispatch_orders') {
      setValues({
        ...values,
        dispatch_order_code: selected.id,
        dispatch_order_code_show: selected.dispatch_order_code,
      });
    }
    if (title === 'storage_centers') {
      setValues({
        ...values,
        storage_center_code: selected.id,
        storage_center_code_show: selected.storage_center_code,
      });
    }
    if (title === 'storage_locations') {
      setValues({
        ...values,
        storage_location: selected.id,
        storage_location_show: selected.location_code,
      });
    }
  };

  const handleClose = () => {
    setShow(false);
    setModalCall('');
  };

  // Get the selected item and request additional information of that item
  // to fill automatically dimensional_unit and item_description
  const validateItemCode = async (option) => {
    if (!option) {
      setAutoCompleted({
        item_description: '',
        dimensional_unit_code: '',
        dimensional_unit_description: '',
      });
    } else {
      const itemCode = option.value;
      const items = await Data.getItems();
      const allowedItems = items.filter((item) => allowedItemCodes.includes(item.id));
      const validItem = allowedItems.filter((item) => item.reference_code === itemCode);
      if (validItem) {
        if (validItem.length === 1) {
          setValues({
            ...values,
            dimensional_unit_code: validItem[0].consolidation_level_N0,
          });
          setAutoCompleted({
            ...autoCompleted,
            item_description: validItem[0].item_description,
          });
        }
      }
      let res;
      if (validItem[0] != undefined) {
        res = await axios.get(`${urlDimensionalUnit}/${validItem[0].consolidation_level_N0}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      setAutoCompleted({
        ...autoCompleted,
        dimensional_unit_code: res.data.dimensional_unit_code,
        dimensional_unit_description: res.data.description,
      });
    }
  };

  // when we select a transaction type from transactions main menu, the user, transaction type and subtype are filled automatically.
  useEffect(() => {
    setValues({
      ...values,
      user,
      transaction_type: transactionType,
      transaction_subtype: 'total',
    });
  }, [transactionType]);

  // Get the items that are included in itemBatchRelation table
  const configureAllowedItemCodes = async () => {
    const itemBatch = await Data.getItemBatch();
    const itemCodes = itemBatch.map((elem) => elem.item_reference_code);
    const uniqueItemCodes = [...new Set(itemCodes)];
    setAllowedItemCodes(uniqueItemCodes);
  };

  useEffect(() => {
    configureAllowedItemCodes();
  }, []);

  useEffect(() => {
    // set allowedStorageCenters and allowedBatches from selected item
    const configureAllowedBatchCodes = async () => {
      const itemBatchRelation = await Data.getItemBatch();
      const itemBatchRelationAllowed = itemBatchRelation.filter(
        (ibrFilteredByItem) => ibrFilteredByItem.item_reference_code === itemSelected.id
      );
      setIbrFilteredByItem(itemBatchRelationAllowed);
      const batchCodes = itemBatchRelationAllowed.map((el) => el.batch_code);
      setAllowedBatches(batchCodes);
    };
    const configureAllowedStorageCenterCodes = async () => {
      const itemStorageCenterRelation = await Data.getItemStorageCenter();
      const itemStorageCenterRelationAllowed = itemStorageCenterRelation.filter(
        (itemStorageCenterRelation) =>
          itemStorageCenterRelation.item_reference_code === itemSelected.id
      );
      setItemStorageCenterRelation(itemStorageCenterRelationAllowed);
      const storageCenterCodes = itemStorageCenterRelationAllowed.map(
        (el) => el.storage_center_code
      );
      setAllowedStorageCenters(storageCenterCodes);
    };
    if (itemSelected) {
      configureAllowedBatchCodes();
      configureAllowedStorageCenterCodes();
    } else {
      setAllowedBatches([]);
      setAllowedStorageCenters([]);
    }
  }, [itemSelected]);

  // set allowedStorageLocationCodes from selected storageCenter
  useEffect(() => {
    if (storageCenterSelected) {
      axios
        .get(urlStorageLocation, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const storageLocationsAllowed = res.data.results.filter(
            (storageLocation) => storageLocation.storage_center_code === storageCenterSelected.id
          );
          const storageLocationCodes = storageLocationsAllowed.map((el) => el.id);
          setAllowedStorageLocations(storageLocationCodes);
        });
    } else {
      setAllowedStorageLocations([]);
    }
  }, [storageCenterSelected]);

  // set AllowedStorageLocationDestinationCodes from selected storageCenterDestination (only in transfer transactions)
  useEffect(() => {
    if (storageCenterDestinationSelected) {
      axios
        .get(urlStorageLocation, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const storageLocationsAllowed = res.data.results.filter(
            (storageLocation) =>
              storageLocation.storage_center_code === storageCenterDestinationSelected.id
          );
          const storageLocationCodes = storageLocationsAllowed.map((el) => el.id);
          setAllowedStorageLocationDestination(storageLocationCodes);
        });
    } else {
      setAllowedStorageLocationDestination([]);
    }
  }, [storageCenterDestinationSelected]);

  // set allowedCostCodes from selected batch
  useEffect(() => {
    if (typeof batchSelected !== 'undefined' && batchSelected) {
      const filteredByBatch = ibrFilteredByItem.filter((el) => el.batch_code === batchSelected.id);

      setIbrFilteredByItemAndBatch(filteredByBatch);

      const costCodes = filteredByBatch.map((el) => el.unique_unit_cost_code);
      setAllowedCosts(costCodes);
    }
  }, [batchSelected]);

  // after selecting a cost, set the final ItemBatchRelation code to create transaction
  useEffect(() => {
    if (typeof costSelected !== 'undefined' && costSelected) {
      const selectedItemBatchRelation = ibrFilteredByItemAndBatch.filter(
        (el) => el.unique_unit_cost_code == costSelected.id
      );
      setValues({
        ...values,
        item_batch_relation_code: selectedItemBatchRelation[0].id,
      });
    }
  }, [costSelected]);

  // set emergent tables endpoint
  useEffect(() => {
    if (modalCall != '') {
      setShow(true);
    }
    switch (modalCall) {
      case 'items':
        setUrl(urlItem);
        break;

      case 'batches':
        setUrl(urlBatch);
        break;

      case 'entry_orders':
        setUrl(urlEntryOrder);
        break;

      case 'dispatch_orders':
        setUrl(urlDispatchOrder);
        break;

      case 'storage_centers':
        setUrl(urlStorageCenter);
        break;
      case 'storage_locations':
        setUrl(urlStorageLocation);
        break;

      case 'adjustment_concept':
        setUrl(urlAdjustmentConcept);
        break;
    }
  }, [modalCall]);

  // retrieve information to create emergent tables
  useEffect(() => {
    const getTableData = () => {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let tableInformation;
          if (modalCall === 'items') {
            tableInformation = res.data.results.filter((item) =>
              allowedItemCodes.includes(item.id)
            );
          } else if (modalCall === 'batches') {
            tableInformation = res.data.results.filter((batch) =>
              allowedBatches.includes(batch.id)
            );
          } else {
            tableInformation = res.data;
          }

          if (tableInformation.length > 0) {
            const headersList = Object.keys(res.data[0]).filter((item) => item !== 'id');

            setHeaders(headersList);
            setTableData(tableInformation);
          } else {
            setTableData([]);
            setHeaders([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (url != '') {
      getTableData();
    }
  }, [url]);

  return (
    <>
      <TableModal
        title={modalCall}
        headers={headers}
        tableData={tableData}
        show={show}
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
      />
      <HeaderTitle title={title} />
      {added ? (
        <AlertMessage
          message="The transaction was successfully registered"
          variant="success"
          setErrorMessage={setErrorMessage}
        />
      ) : (
        <></>
      )}
      <TransactionsForm
        added
        setError={setErrorMessage}
        validateItemCode={validateItemCode}
        isItemSelected
        setItemSelected={setItemSelected}
        setBatchSelected={setBatchSelected}
        setCostSelected={setCostSelected}
        setStorageCenterSelected={setStorageCenterSelected}
        setStorageCenterDestinationSelected={setStorageCenterDestinationSelected}
        allowedItems={allowedItemCodes}
        allowedBatches={allowedBatches}
        allowedCosts={allowedCosts}
        allowedStorageCenters={allowedStorageCenters}
        allowedStorageLocations={allowedStorageLocations}
        allowedStorageLocationDestination={allowedStorageLocationDestination}
        autoCompleted={autoCompleted}
        setAutoCompleted={setAutoCompleted}
        setModalCall={setModalCall}
        handleChange={handleChange}
        handleAPIRequest={handleAPIRequest}
        internalValues={values}
        setInternalValues={setValues}
        type={transactionType}
        user={user}
        openModal={(type) => {
          setModalCall(type);
        }}
      />
    </>
  );
};

export default TransactionSection;
