import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';
import Urls from '../Urls';
import ProductTableSection from '../TableSection/ProductTableSection';
import { notification } from '../AlertMessage/ToastifyAlert';
import getUniqueValues from '../../utils/getUniqueValues';
import { useDispatch } from 'react-redux';
import {
  setExistingProducts,
  setTouchedOrder,
  setOpenPPOForm,
  setWorkCentersAllowed,
  setCurrentProductionOrderCode,
} from '../../redux/slices/productionOrderSlice';
import { setProductPerProductionOrderCurrentPage } from '../../redux/slices/productPerProductionOrderSlice';
import { debounce } from '../../utils/optimization';

const ProductionOrder = ({ onAdded, values, onEdited, setAddForm }) => {
  const [added, setAdded] = useState(false);
  const [status, setStatus] = useState('');
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const date = new Date();
  const [productionOrder, setProductionOrder] = useState(null);
  const [operationCenter, setOperationCenter] = useState(null);
  const isEditForm = onEdited !== undefined;
  const [operationCenterData, setOperationCenterData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [isWorkCenterFieldDisabled, setIsWorkCenterFieldDisabled] = useState(false);
  const dispatch = useDispatch();
  const handleAddedProductionOrder = () => {
    setAdded(true);
    dispatch(setCurrentProductionOrderCode(null));
  };
  const handleEditedProductionOrder = () => {
    setAddForm(false);
    dispatch(setTouchedOrder(processedFormValues));
    dispatch(setCurrentProductionOrderCode(null));
  };

  const industrialClient = apiService.industrialClient();

  const productionOrderValidate = Yup.object({
    code: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    operationCenter: Yup.string().required('Required').typeError('Select an Operation Center'),
  });

  const dispatchOpenPPOForm = (val) => {
    dispatch(setOpenPPOForm(val));
  };

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(async () => {
    try {
      const resCategory = await apiService.getData1(Urls.operationCenter, 'code');
      setOperationCenterData({
        headers: resCategory.headersList,
        tableData: resCategory.data,
        options: resCategory.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getProductionOrderSelected = async (code) => {
    const res = await industrialClient.get(`${Urls.productionOrder}?page_size=0&code=${code}`);
    const productionOrderSelected = res.data;
    return productionOrderSelected.length > 0 ? productionOrderSelected : null;
  };

  const [isNewProductionOrder, setIsNewProductionOrder] = useState(true);
  const [orderInfo, setOrderInfo] = useState({});

  const configureProductionOrderInfo = (res) => {
    setOrderInfo({
      operationCenter: res[0].operation_center_description,
      plannedClosingDate: res[0].planned_closing_date,
      manufacturingStatus: res[0].manufacturing_status,
    });
  };
  useEffect(() => {
    setStatus(values.manufacturing_status || orderInfo.manufacturingStatus);
  }, [values, orderInfo]);

  const validateProductionOrder = async (e) => {
    makeDebounceRequest(e);
  };

  const makeDebounceRequest = debounce((e) => {
    const productionOrderCode = e.target.value;
    if (e !== '') {
      setProductionOrder(productionOrderCode);
      dispatch(setCurrentProductionOrderCode(productionOrderCode));
    }
  }, 500);

  useEffect(() => {
    const configureOrderInformation = async () => {
      if (productionOrder !== '' && productionOrder !== null) {
        const existingOrderData = await getProductionOrderSelected(productionOrder);
        if (existingOrderData === null) {
          setIsNewProductionOrder(true);
        } else {
          configureProductionOrderInfo(existingOrderData);
          setIsNewProductionOrder(false);
        }
      }
    };
    configureOrderInformation();
  }, [productionOrder]);

  useEffect(() => {
    const getExistingProductsinProductionOrder = async () => {
      const url = `${Urls.productPerProductionOrders}?page_size=0&production_order__code=${productionOrder}`;
      const productPerProductionOrdersRes = await industrialClient.get(url);
      const orders = productPerProductionOrdersRes.data.map((order) => ({
        value: order.product,
      }));
      const uniqueExistingProducts = await getUniqueValues(orders);

      const existingProducts = uniqueExistingProducts.map((el) => el.value);
      dispatch(setExistingProducts(existingProducts));
    };
    if (isNewProductionOrder) dispatch(setExistingProducts([]));
    else getExistingProductsinProductionOrder();
  }, [isNewProductionOrder]);

  useEffect(() => {
    const configureWorkCentersAllowed = async (operationCenter) => {
      const url = `${Urls.workCenter}?page_size=0&operation_center__code=${operationCenter}`;
      const workCentersRes = await industrialClient.get(url);

      const workCentersAllowed = workCentersRes.data.map((workCenter) => workCenter.code);
      dispatch(setWorkCentersAllowed(workCentersAllowed));
    };
    operationCenter && configureWorkCentersAllowed(operationCenter);
    isEditForm && configureWorkCentersAllowed(values.operation_center);
  }, [operationCenter]);

  useEffect(() => {
    const configureWorkCenterFieldStatus = async (productionOrderCode) => {
      const currentProductionOrder = await industrialClient.get(
        `${Urls.productionOrder}/${productionOrderCode}`
      );

      const orderStatus = currentProductionOrder.data.manufacturing_status;
      const productPerProductionOrders = await industrialClient.get(
        `${Urls.productPerProductionOrders}?page_size=0&production_order__code=${productionOrderCode}`
      );
      const isWorkCenterAssigned =
        productPerProductionOrders.data.filter((PPO) => PPO.work_center !== null).length > 0;

      if (orderStatus !== 'not_started' || isWorkCenterAssigned) {
        setIsWorkCenterFieldDisabled(true);
      } else {
        setIsWorkCenterFieldDisabled(false);
      }
    };
    isEditForm && configureWorkCenterFieldStatus(values.code);
  }, []);

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={productionOrderValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            code: data.code,
            operation_center: data.operationCenter,
            loading_date: data.loadingDate,
            start_date: data.startDate,
            end_date: data.endDate,
            planned_closing_date:
              data.plannedClosingDate !== '' ? new Date(data.plannedClosingDate) : null,
            assignment_date: data.assignmentDate,
            manufacturing_status: data.manufacturingStatus,
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
            created_at: onEdited ? data.created_at : new Date(),
            updated_at: new Date(),
          };
          setProcessedFormValues(processedValues);
          //creation
          if (isNewProductionOrder && !isEditForm) {
            try {
              const res = await industrialClient.post(Urls.productionOrder, processedValues);

              notification(`Production Order ${res.data.code} created sucessfully`, 'success');
              dispatchOpenPPOForm(true);
            } catch (err) {
              notification('Error creating order', 'error');
            }
          }
          //update
          if (isEditForm) {
            try {
              const res = await industrialClient.patch(
                `${Urls.productionOrder}/${data.code}`,
                processedValues
              );
              notification(`Production Order ${res.data.code} modified sucessfully`, 'success');
            } catch (err) {
              notification('Error updating item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.code,
          code: values.code || '',
          operationCenter: values.operation_center || null,
          operationCenterDescription: values.operation_center_description || '',
          loadingDate: values.loading_date || date.toISOString(),
          startDate: values.start_date || null,
          endDate: values.end_date || null,
          plannedClosingDate: values.planned_closing_date || '',
          assignmentDate: values.assignment_date || null,
          manufacturingStatus: values.manufacturing_status || 'not_started',
          createdBy: values.created_by || '',
        }}
      >
        {({ setFieldValue, values, handleSubmit, handleChange }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <TextField
                  label="Production Order"
                  disabled={isEditForm}
                  name="code"
                  type="string"
                  value={values.code}
                  onChange={(e) => {
                    handleChange(e);
                    validateProductionOrder(e);
                  }}
                />
                {isNewProductionOrder ? (
                  <>
                    <CustomSelect
                      onChange={(option) => {
                        setFieldValue('operationCenter', option?.value || null);
                        setFieldValue(
                          'operationCenterDescription',
                          option ? option.description : ''
                        );
                        setOperationCenter(option?.value);
                      }}
                      label="Operation Center"
                      name="operationCenter"
                      options={operationCenterData.options}
                      defaultValue={{
                        value: values.operationCenterDescription,
                        label: values.operationCenterDescription,
                      }}
                      disabled={isWorkCenterFieldDisabled}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      disabled
                      label="Operation Center"
                      name="operationCenterDescription"
                      type="text"
                      value={orderInfo.operationCenter}
                    />
                  </>
                )}
                <TextField
                  disabled={
                    values.manufacturingStatus == 'closed' ||
                    orderInfo.manufacturingStatus == 'closed'
                      ? true
                      : false
                  }
                  label="Planned closing date"
                  name="plannedClosingDate"
                  type="datetime-local"
                  value={(values.plannedClosingDate || '').toString().substring(0, 16)}
                />

                {isNewProductionOrder ? (
                  <CustomSelect
                    disabled
                    label="Manufacturing status"
                    name="manufacturingStatus"
                    options={[
                      { value: 'not_started', label: 'not_started' },
                      { value: 'started', label: 'started' },
                      { value: 'closed', label: 'closed' },
                      { value: 'paused', label: 'paused' },
                    ]}
                    defaultValue={{
                      value: values.manufacturingStatus,
                      label: values.manufacturingStatus,
                    }}
                    onChange={(option) => {
                      setFieldValue('status', option ? option.value : '');
                    }}
                  />
                ) : (
                  <TextField
                    disabled
                    label="Operation Center"
                    name="operationCenterDescription"
                    type="text"
                    value={orderInfo.manufacturingStatus}
                  />
                )}
              </Form.Row>
              {/* Only when edditing the order */}
              {onEdited !== undefined && (
                <>
                  <Form.Row className="mb-3">
                    <TextField
                      disabled
                      label="Loading date"
                      name="loadingDate"
                      type="datetime-local"
                      value={(values.loadingDate || '').toString().substring(0, 16)}
                    />
                    <TextField
                      disabled
                      label="Assignment date"
                      name="assignmentDate"
                      type="datetime-local"
                      value={(values.assignmentDate || '').toString().substring(0, 16)}
                    />
                    <TextField
                      disabled
                      label="Start date"
                      name="startDate"
                      type="datetime-local"
                      value={(values.startDate || '').toString().substring(0, 16)}
                    />
                    <TextField
                      disabled
                      label="End date"
                      name="endDate"
                      type="datetime-local"
                      value={(values.endDate || '').toString().substring(0, 16)}
                    />
                  </Form.Row>
                </>
              )}
              {onEdited ? (
                <Button
                  className="fpb-btn d-block"
                  type="submit"
                  disabled={values.manufacturingStatus == 'closed' ? true : false}
                >
                  Update
                </Button>
              ) : (
                <Button
                  className="fpb-btn d-block"
                  type="submit"
                  disabled={
                    (isNewProductionOrder && values.operationCenter === null) ||
                    values.code === '' ||
                    values.code === null ||
                    orderInfo.manufacturingStatus === 'closed'
                  }
                  onClick={() => {
                    dispatchOpenPPOForm(true);
                  }}
                >
                  {isNewProductionOrder && !isEditForm ? 'Add' : 'Add product'}
                </Button>
              )}
              {!isNewProductionOrder && (
                <small className="text-danger">{`Production order ${values.code} already exists`}</small>
              )}
              <ProductTableSection
                url={Urls.productPerProductionOrders}
                title="Product per production order"
                formName="productPerProductionOrder"
                productionOrder={values.code}
                status={status}
              />
              <Button
                className="fpb-btn mt-3"
                onClick={() => {
                  dispatch(setProductPerProductionOrderCurrentPage(0));
                  onEdited ? handleEditedProductionOrder() : handleAddedProductionOrder();
                }}
              >
                Confirm
              </Button>
              <Button
                className="fpb-btn-inverse mt-3 ml-2"
                onClick={() => {
                  dispatch(setProductPerProductionOrderCurrentPage(0));
                  onEdited ? handleEditedProductionOrder() : handleAddedProductionOrder();
                }}
              >
                Close
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ProductionOrder;
