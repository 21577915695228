import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';
import Urls from '../../components/Urls';
import { getFiltersString } from '../../utils/tableFilters';

export const fetchShifts = createAsyncThunk(
  'shifts/fetchShifts',
  async ({ currentPage, pageSize, filters }) => {
    try {
      const filtersString = getFiltersString(filters);
      const url = `${Urls.shift}?page=${currentPage + 1}&page_size=${pageSize}&${filtersString}`;
      const res = await apiService.industrialClient().get(url);
      const { results } = res.data || 0;
      if (results.length > 0) {
        // discard the fields the user will not visualize in tables
        const headersList = Object.keys(results[0]).filter((elem) => {
          let returnColumn;
          if (
            elem === 'id' ||
            elem === 'product_per_production_order' ||
            elem === 'event' ||
            elem === 'quality_report'
          ) {
            returnColumn = false;
          } else {
            returnColumn = true;
          }
          return returnColumn;
        });
        return {
          currentPage,
          pageSize,
          total: res.data.count,
          data: res.data.results,
          headers: headersList,
        };
      }
      return {
        currentPage,
        pageSize,
        total: res.data.count,
        data: res.data.results,
        headers: [],
      };
    } catch (err) {
      return err.message;
    }
  }
);

const initialState = {
  shiftsModal: {
    page: 0,
    pageSize: 5,
    total: 0,
    data: [],
    headers: [],
  },
  shifts: [],
  columnsToHide: ['event_category', 'variable_description', 'calculation_variable'],
};

const shiftsSlice = createSlice({
  name: 'shifts',
  initialState,
  reducers: {
    setShiftsPageSize: (state, action) => {
      state.shiftsModal.pageSize = action.payload;
    },
    setShiftsCurrentPage: (state, action) => {
      state.shiftsModal.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShifts.fulfilled, (state, action) => {
      state.shiftsModal.data = action.payload.data;
      state.shiftsModal.total = action.payload.total;
      state.shiftsModal.headers = action.payload.headers;
    });
  },
});

export const selectModalShifts = (state) => state.shifts.shiftsModal.data;
export const selectShiftHeaders = (state) => state.shifts.shiftsModal.headers;
export const selectShiftsTotalCount = (state) => state.shifts.shiftsModal.total;
export const selectShiftsCurrentPage = (state) => state.shifts.shiftsModal.page;
export const selectShiftsPageSize = (state) => state.shifts.shiftsModal.pageSize;
export const selectShiftColumnsToHide = (state) => state.shifts.columnsToHide;

export const { setShiftsPageSize, setShiftsCurrentPage } = shiftsSlice.actions;

export default shiftsSlice.reducer;
