import React from 'react';
import { Navbar, Nav, DropdownButton, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import Urls from '../Urls';

const AccountMenu = ({ username, company }) => {
  const urlLogout = Urls.logout;
  const refreshToken = localStorage.getItem('refreshToken');
  const accessToken = localStorage.getItem('accessToken');

  const logout = () => {
    axios
      .post(
        urlLogout,
        { refresh: refreshToken },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(() => {
        localStorage.clear();
        window.location.href = '/';
      })
      .catch(() => {
        localStorage.clear();
        window.location.href = '/';
      });
  };

  return (
    <Navbar expand="lg" className="float-right" style={{ marginLeft: 'auto' }}>
      <Nav className="nav-menu profile-navigation">
        <Nav.Link href="summary" className="main-color">
          {company}
          <i className="fa fa-suitcase px-2" aria-hidden="true" />
        </Nav.Link>
        <DropdownButton
          data-testid="mail"
          id="dropdown-basic-button"
          menuAlign="right"
          title={username || ''}
        >
          <Dropdown.Item href="profile">Profile</Dropdown.Item>
          <Dropdown.Item href="/changePassword">Change Password</Dropdown.Item>
          <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
        </DropdownButton>
      </Nav>
    </Navbar>
  );
};

export default AccountMenu;
