import React, { useState } from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import * as BsIcons from 'react-icons/bs';
import axios from 'axios';
import Papa from 'papaparse';
import urls from '../Urls';
import AlertMessage from '../AlertMessage/AlertMessage';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import templatesUrls from './templatesUrls';
import { GiCancel } from 'react-icons/gi';
import { ProgressBar } from 'react-bootstrap';
import csvIcon from './csvIcon.png';

const urlItemCategory = urls.itemCategory;
const urlBatch = urls.batch;
const urlItemLabel = urls.label;
const urlDimensional = urls.dimensionalUnit;
const urlUpload = urls.upload;
const urlCost = urls.cost;
const urlCostType = urls.costType;
const urlCustomer = urls.customer;
const urlSupplier = urls.supplier;
const urlItem = urls.item;
const urlStorageCenter = urls.storageCenter;
const fileDownload = require('js-file-download');

let fileNameDownload = '';

const TemplatesView = ({ title }) => {
  const [parsedFile, setParsedFile] = useState();
  const [fileLength, setFileLength] = useState(0);
  const [successNumber, setSuccessNumber] = useState(0);
  const [errorNumber, setErrorNumber] = useState(0);
  const [progress, setProgress] = useState(0);
  const [postStarted, setPostStarted] = useState(false);
  const [shortFileName, setShortFileName] = useState('');
  const [uploadDone, setUploadDone] = useState(false);
  const [fileName, setFileName] = useState('');
  const [buttonName, setButtonName] = useState('Upload');
  const [buttonState, setButtonState] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [extraSpace, setExtraSpace] = useState(3);

  const pageTitle = `Table Name: ${title}`;
  const accessToken = localStorage.getItem('accessToken');

  const header = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const uploadFile = () => {
    const uploadData = new FormData();
    uploadData.append('title', 'Test title');
    uploadData.append('file', fileName);
    axios
      .post(urlUpload, uploadData, header)
      .then((res) => {
        console.log(res);
        setButtonState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const newFile = (file) => {
    const csvFile = file.target.files[0];
    setPostStarted(false);
    setSuccessNumber(0);
    setErrorNumber(0);
    setFileName(file.target.value);
    setShortFileName(csvFile?.name);
    if (file.target.value.length > 30) {
      setExtraSpace(6);
    } else {
      setExtraSpace(3);
    }

    setButtonState(false);
    if (csvFile) {
      Papa.parse(csvFile, {
        skipEmptyLines: true,
        complete: function (results) {
          // callback function that will be called once parsing is completed
          let data = results.data; // results is an object with the data, errors and meta data
          let filteredData = data.filter((line) => {
            // here we filter line by line
            return !line.every((field) => field === ''); // if all fields are empty, we return false, else true, by returning false we filter out blank lines
          });
          setParsedFile(filteredData);
        },
      });
    }
  };

  async function processData() {
    const data = parsedFile;
    // File length is the number of rows in the file minus the header row
    setFileLength(data?.length - 1);
    // TODO file should be uploaded when S3 is ready
    //uploadFile();
    const keys = data[0];
    let processedValues;
    let url;
    var errorFlag = 0;

    for (let i = 1; i <= data.length - 1; i++) {
      const values = data[i];
      const mapArrays = (keys, values) => {
        const obj = {};
        for (let i = 0; i < keys.length; i++) {
          if (values) {
            obj[keys[i]] = values[i];
          } else {
            obj[keys[i]] = '';
          }
        }
        return obj;
      };

      const res = mapArrays(keys, values);
      switch (title) {
        case 'Item':
          url = urls.item;
          processedValues = {
            reference_code: res.reference_code,
            item_model: res.item_model,
            item_description: res.item_description,
            barcode: res.barcode,
            documentation: res.documentation,
            safety_stock: res.safety_stock !== '' ? parseInt(res.safety_stock) : 0,
            label:
              res.label !== ''
                ? await axios
                    .get(`${urlItemLabel}?label_code=${res.label}`, header)
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            ordering_point: res.ordering_point !== '' ? parseInt(res.ordering_point) : 0,
            minimum_stock: res.minimum_stock !== '' ? parseInt(res.minimum_stock) : 0,
            maximum_stock: res.maximum_stock !== '' ? parseInt(res.maximum_stock) : 0,
            item_category:
              res.item_category !== ''
                ? await axios
                    .get(`${urlItemCategory}?item_category_code=${res.item_category}`, header)
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            item_type: res.item_type,
            item_image: res.item_image,
            consolidation_level_N0:
              res.consolidation_level_N0 !== ''
                ? await axios
                    .get(
                      `${urlDimensional}?dimensional_unit_code=${res.consolidation_level_N0}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            consolidation_level_N1:
              res.consolidation_level_N1 !== ''
                ? await axios
                    .get(
                      `${urlDimensional}?dimensional_unit_code=${res.consolidation_level_N1}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            consolidation_level_N2:
              res.consolidation_level_N2 !== ''
                ? await axios
                    .get(
                      `${urlDimensional}?dimensional_unit_code=${res.consolidation_level_N2}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            consolidation_level_N3:
              res.consolidation_level_N3 !== ''
                ? await axios
                    .get(
                      `${urlDimensional}?dimensional_unit_code=${res.consolidation_level_N3}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            consolidation_level_N4:
              res.consolidation_level_N4 !== ''
                ? await axios
                    .get(
                      `${urlDimensional}?dimensional_unit_code=${res.consolidation_level_N4}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            contained_pieces_N0:
              res.contained_pieces_N0 !== '' ? parseInt(res.contained_pieces_N0) : 0,
            contained_pieces_N1:
              res.contained_pieces_N1 !== '' ? parseInt(res.contained_pieces_N1) : 0,
            contained_pieces_N2:
              res.contained_pieces_N2 !== '' ? parseInt(res.contained_pieces_N2) : 0,
            contained_pieces_N3:
              res.contained_pieces_N3 !== '' ? parseInt(res.contained_pieces_N3) : 0,
            contained_pieces_N4:
              res.contained_pieces_N4 !== '' ? parseInt(res.contained_pieces_N4) : 0,
          };
          break;
        case 'Storage Center':
          url = urls.storageCenter;
          processedValues = {
            storage_center_code: res.storage_center_code,
            description: res.description,
            country: res.country,
            city: res.city,
            province: res.province,
            address: res.address,
            geocoordinate_latitude: res.geocoordinate_latitude,
            geocoordinate_longitude: res.geocoordinate_longitude,
          };
          break;
        case 'Storage Location':
          url = urls.storageLocation;
          processedValues = {
            location_code: res.location_code,
            description: res.description,
            storage_center_code:
              res.storage_center_code !== ''
                ? await axios
                    .get(
                      `${urlStorageCenter}?storage_center_code=${res.storage_center_code}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            coordinate_x: res.coordinate_x !== '' ? res.coordinate_x : null,
            coordinate_y: res.coordinate_y !== '' ? res.coordinate_y : null,
            coordinate_z: res.coordinate_z !== '' ? res.coordinate_z : null,
          };
          break;
        case 'Item Category':
          url = urls.itemCategory;
          processedValues = {
            item_category_code: res.item_category_code,
            description: res.description,
            parent_category:
              res.parent_category !== ''
                ? await axios
                    .get(`${urlItemCategory}?item_category_code=${res.parent_category}`, header)
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
          };
          break;
        case 'Cost':
          url = urls.cost;
          processedValues = {
            monetary_value_N0: res.monetary_value_N0,
            unit_cost_state: res.unit_cost_state,
            unique_cost_type_code:
              res.unique_cost_type_code !== ''
                ? await axios
                    .get(
                      `${urlCostType}?unique_cost_type_code=${res.unique_cost_type_code}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            item_reference_code:
              res.item_reference_code !== ''
                ? await axios
                    .get(`${urlItem}?reference_code=${res.item_reference_code}`, header)
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            dimensional_unit_code:
              res.dimensional_unit_code !== ''
                ? await axios
                    .get(
                      `${urlDimensional}?dimensional_unit_code=${res.dimensional_unit_code}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
          };
          break;
        case 'Cost Type':
          url = urls.costType;
          processedValues = {
            unique_cost_type_code: res.unique_cost_type_code,
            description: res.description,
            customer_identification:
              res.customer_identification !== ''
                ? await axios
                    .get(
                      `${urlCustomer}?customer_identification=${res.customer_identification}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            supplier_identification:
              res.supplier_identification !== ''
                ? await axios
                    .get(
                      `${urlSupplier}?supplier_identification=${res.supplier_identification}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
          };
          break;
        case 'Dimensional Unit':
          url = urls.dimensionalUnit;
          processedValues = {
            dimensional_unit_code: res.dimensional_unit_code,
            description: res.description,
            symbol: res.symbol,
          };
          break;
        case 'Supplier':
          url = urls.supplier;
          processedValues = {
            supplier_identification: res.supplier_identification,
            company_name: res.company_name,
            commercial_name: res.commercial_name,
            ruc: res.ruc,
            contact_person: res.contact_person,
            email: res.email,
            telephone_number: res.telephone_number,
            cellphone_number: res.cellphone_number,
            type_of_supplier: res.type_of_supplier,
          };
          break;
        case 'Customer':
          url = urls.customer;
          processedValues = {
            customer_identification: res.customer_identification,
            company_name: res.company_name,
            commercial_name: res.commercial_name,
            ruc: res.ruc,
            contact_person: res.contact_person,
            email: res.email,
            telephone_number: res.telephone_number,
            cellphone_number: res.cellphone_number,
            type_of_customer: res.type_of_customer,
          };
          break;
        case 'Adjustment':
          url = urls.adjustment;
          processedValues = {
            adjustment_code: res.adjustment_code,
            description: res.description,
          };
          break;
        case 'Item Storage Center Relation':
          url = urls.itemStorageRelation;
          processedValues = {
            storage_center_code:
              res.storage_center_code !== ''
                ? await axios
                    .get(
                      `${urlStorageCenter}?storage_center_code=${res.storage_center_code}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            item_reference_code:
              res.item_reference_code !== ''
                ? await axios
                    .get(`${urlItem}?reference_code=${res.item_reference_code}`, header)
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
          };
          break;
        case 'Batch':
          url = urls.batch;
          processedValues = {
            batch_code: res.batch_code,
            manufacturing_date: res.manufacturing_date !== '' ? res.manufacturing_date : null,
            expiration_date: res.expiration_date !== '' ? res.expiration_date : null,
            rated_life: data.ratedLife !== '' ? parseInt(res.rated_life) : null,
            extended_lifetime:
              res.extended_lifetime !== '' ? parseInt(res.extended_lifetime) : null,
            quality_characteristics: res.quality_characteristics,
            responsible: res.responsible,
            attachment_file: res.attachment_file,
          };
          break;
        case 'Entry Order':
          url = urls.entryOrder;
          processedValues = {
            entry_order_code: res.entry_order_code,
            responsible: res.responsible,
            attachment_file: res.attachment_file,
            supplier_identification:
              res.supplier_identification !== ''
                ? await axios
                    .get(
                      `${urlSupplier}?supplier_identification=${res.supplier_identification}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
          };
          break;
        case 'Dispatch Order':
          url = urls.dispatchOrder;
          processedValues = {
            dispatch_order_code: res.dispatch_order_code,
            responsible: res.responsible,
            attachment_file: res.attachment_file,
            customer_identification:
              res.customer_identification !== ''
                ? await axios
                    .get(
                      `${urlCustomer}?customer_identification=${res.customer_identification}`,
                      header
                    )
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
          };
          break;
        case 'Item Batch Relation':
          url = urls.itemBatchRelation;
          processedValues = {
            item_batch_relation_code: null,
            item_reference_code:
              res.item_reference_code !== ''
                ? await axios
                    .get(`${urlItem}?reference_code=${res.item_reference_code}`, header)
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
            batch_code: res.batch_code
              ? await axios
                  .get(`${urlBatch}?batch_code=${res.batch_code}`, header)
                  .then((res) => {
                    if (res.data.results.length > 0) {
                      return res.data.results[0].id;
                    } else {
                      return '';
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    return '';
                  })
              : null,
            unique_unit_cost_code:
              res.unique_unit_cost_code !== 0
                ? await axios
                    .get(`${urlCost}?unique_unit_cost_code=${res.unique_unit_cost_code}`, header)
                    .then((res) => {
                      if (res.data.results.length > 0) {
                        return res.data.results[0].id;
                      } else {
                        return '';
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      return '';
                    })
                : null,
          };
          break;
      }
      setPostStarted(true);
      await axios
        .post(url, processedValues, header)
        .then((res) => {
          if (res.status === 201) {
            setSuccessNumber((successNumber) => successNumber + 1);
          }
        })
        .catch((err) => {
          errorFlag = 1;
          setErrorNumber((errorNumber) => errorNumber + 1);
        });
      setProgress((i * 100) / (data.length - 1));
      if (i === data.length - 1) {
        setUploadDone(true);
      }
    }
  }

  async function downloadFile() {
    switch (title) {
      case 'Item':
        fileNameDownload = templatesUrls.item;
        break;
      case 'Storage Center':
        fileNameDownload = templatesUrls.storageCenter;
        break;
      case 'Storage Location':
        fileNameDownload = templatesUrls.storageLocation;
        break;
      case 'Item Category':
        fileNameDownload = templatesUrls.itemCategory;
        break;
      case 'Cost':
        fileNameDownload = templatesUrls.cost;
        break;
      case 'Cost Type':
        fileNameDownload = templatesUrls.costType;
        break;
      case 'Dimensional Unit':
        fileNameDownload = templatesUrls.dimensionalUnit;
        break;
      case 'Supplier':
        fileNameDownload = templatesUrls.supplier;
        break;
      case 'Customer':
        fileNameDownload = templatesUrls.customer;
        break;
      case 'Adjustment':
        fileNameDownload = templatesUrls.adjustment;
        break;
      case 'Item Storage Center Relation':
        fileNameDownload = templatesUrls.itemStorageCenterRelation;
        break;
      case 'Batch':
        fileNameDownload = templatesUrls.batch;
        break;
      case 'Entry Order':
        fileNameDownload = templatesUrls.entryOrder;
        break;
      case 'Dispatch Order':
        fileNameDownload = templatesUrls.dispatchOrder;
        break;
      case 'Item Batch Relation':
        fileNameDownload = templatesUrls.itemBatchRelation;
        break;
    }
    axios
      .get(fileNameDownload, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `${title}.xlsx`);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Container>
        <HeaderTitle title={pageTitle} />
        <h4>Download the .xlsx template</h4>
        <Row>
          <BsIcons.BsCloudDownload
            size={120}
            className="mb-4 col-3"
            style={{
              margin: 'auto',
            }}
          />
        </Row>
        <Row>
          <Button
            className="fpb-btn col-4"
            style={{
              margin: 'auto',
            }}
            onClick={downloadFile}
          >
            Download
          </Button>
        </Row>
        <h4>Upload the .csv file</h4>
        <Row>
          <BsIcons.BsCloudUpload
            size={120}
            className="mb-4 col-3"
            style={{
              margin: 'auto',
            }}
          />
        </Row>
        <Row>
          <Button
            className="fpb-btn col-4"
            style={{
              margin: 'auto',
            }}
            onClick={processData}
            disabled={buttonState}
          >
            {buttonName}
          </Button>
        </Row>
        <Row className="mt-2">
          <Col xs={extraSpace}>
            <input
              type="file"
              onChange={newFile}
              className="mb-3 mr-0"
              accept=".csv"
              value={fileName}
            />
          </Col>
          {fileName !== '' ? (
            <Col>
              <GiCancel size={20} onClick={() => setFileName('')} />
            </Col>
          ) : null}
        </Row>
        {alertMessage || errorMessage ? (
          <AlertMessage
            variant={alertMessage ? 'success' : 'danger'}
            message={alertMessage || errorMessage}
            setAlertMessage={setAlertMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : null}
        {postStarted ? (
          <div className="mt-4">
            <h4>Upload Process</h4>
            <Row className="mt-3">
              <Col xs lg="2" align="center">
                <img src={csvIcon} alt={'csvIcon'} />
                <div className="small-text">{shortFileName}</div>
              </Col>
              <Col xs lg="3">
                <ProgressBar animated now={progress} label={uploadDone ? 'Done!' : ''} />
                <div className="small-text">
                  <div>Number of records: {fileLength}</div>
                  <div>Success: {successNumber}</div>
                  <div>Error: {errorNumber}</div>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
      </Container>
    </>
  );
};
export default TemplatesView;
