import React from 'react';
import { Col, Row } from 'react-bootstrap';

const HeaderTitle = ({ title }) => (
  <Row className="text-left">
    <Col className="pb-5 pt-1">
      <div
        style={{
          backgroundColor: '#eae4e4',
          height: 0.5,
          borderColor: 'black',
        }}
      />
      <h3 className="pt-2" style={{ color: '#49158b', fontSize: 30 }}>
        {title}
      </h3>
    </Col>
  </Row>
);

export default HeaderTitle;
