import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import TextField from '../FormComponents/TextField/TextField';
import TableModal from '../TableModal/TableModal';
import apiService from '../apiService';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import Urls from '../Urls';
import { setIsModalPaginationAction } from '../../redux/actions/paginationAction';
import { notification } from '../AlertMessage/ToastifyAlert';

const CostType = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  edited,
  setAddForm,
  onEdited,
}) => {
  const [valuesToEdit, setValuesToEdit] = useState({});
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [editedFormValues, setEditedFormValues] = useState({});
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [supplierCodeTransformed, setSupplierCodeTransformed] = useState();
  const [customerCodeTransformed, setCustomerCodeTransformed] = useState();
  const [supplierDefaultValues, setSupplierDefaultValues] = useState({});
  const [customerDefaultValues, setCustomerDefaultValues] = useState({});
  const [supplierFromModal, setSupplierFromModal] = useState(false);
  const [customerFromModal, setCustomerFromModal] = useState(false);
  const [descriptions, setDescriptions] = useState({
    customerDescription: '',
    supplierDescription: '',
  });
  const [customerData, setCustomerData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [supplierData, setSupplierData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const costTypeValidate = Yup.object({
    uniqueCostTypeCode: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string().required('Required'),
    customerIdentification: Yup.string(),
    supplierIdentification: Yup.string(),
  });

  //pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
        customer_identification: customerCodeTransformed,
        supplier_identification: supplierCodeTransformed,
      });
    }
  }, [added]);

  useEffect(() => {
    if (edited === true) {
      onEdited(editedFormValues);
      setAddForm(false);
    }
  }, [edited]);

  useEffect(async () => {
    const resSupplier = await apiService.getData(Urls.supplier, 'supplier_identification');
    const resCustomer = await apiService.getData(Urls.customer, 'customer_identification');

    setSupplierData({
      headers: resSupplier.headersList,
      tableData: resSupplier.data,
      options: resSupplier.results,
    });

    setCustomerData({
      headers: resCustomer.headersList,
      tableData: resCustomer.data,
      options: resCustomer.results,
    });
  }, []);

  const handleCloseCustomerModal = () => {
    setIsModalPagination();
    setShowCustomerModal(false);
  };

  const handleCloseSupplierModal = () => {
    setIsModalPagination();
    setShowSupplierModal(false);
  };

  useEffect(() => {
    customerData.options.forEach((option) => {
      if (option.value === values.customer_identification) {
        setDescriptions(
          option
            ? { ...descriptions, customerDescription: option.description }
            : { ...descriptions, customerDescription: '' }
        );
      }
    });
  }, [customerData]);

  useEffect(() => {
    supplierData.options.forEach((option) => {
      if (option.value === values.supplier_identification) {
        setDescriptions(
          option
            ? { ...descriptions, supplierDescription: option.description }
            : { ...descriptions, supplierDescription: '' }
        );
      }
    });
  }, [supplierData]);

  const handleSupplierConfirm = (selected, title, setFieldValue) => {
    setSupplierDefaultValues({
      value: selected.id,
      label: selected.supplier_identification,
      description: selected.company_name,
    });
    setValuesToEdit({ ...valuesToEdit, supplier_identification: selected.id });
    setFieldValue('supplierIdentification', selected.id);
    setSupplierCodeTransformed(selected.supplier_identification);
    setShowSupplierModal(false);
    setSupplierFromModal(true);
  };

  const handleCustomerConfirm = (selected, title, setFieldValue) => {
    setCustomerDefaultValues({
      value: selected.id,
      label: selected.customer_identification,
      description: selected.company_name,
    });
    setValuesToEdit({ ...valuesToEdit, customer_identification: selected.id });
    setFieldValue('customerIdentification', selected.id);
    setCustomerCodeTransformed(selected.customer_identification);
    setShowCustomerModal(false);
    setCustomerFromModal(true);
  };

  return (
    <Formik
      validateOnChange
      validationSchema={costTypeValidate}
      onSubmit={async (data) => {
        const processedValues = {
          unique_cost_type_code: data.uniqueCostTypeCode,
          description: data.description,
          customer_identification: data.customerIdentification,
          supplier_identification: data.supplierIdentification,
        };
        setProcessedFormValues(processedValues);
        setOriginalFormValues(data);
        if (data.id == null) {
          const created = await handleAPIRequest(processedValues, {
            entity: 'Cost type',
            code: data.uniqueCostTypeCode,
          });
          if (created.statusText !== 'Created') {
            notification('Error creating item - duplicate item', 'error');
          }
        } else {
          const editedValues = {
            unique_cost_type_code: data.uniqueCostTypeCode,
            description: data.description,
            customer_identification: customerCodeTransformed || values.customer_identification,
            supplier_identification: supplierCodeTransformed || values.supplier_identification,
          };
          const updated = await handleAPIRequestUpdate(data.id, valuesToEdit, {
            entity: 'Cost type',
            code: data.uniqueCostTypeCode,
          });
          if (updated.statusText == 'OK') {
            onEdited(editedValues);
            setAddForm(false);
            notification('Item updated successfully', 'success');
          } else {
            notification('Error updating item - duplicate item', 'error');
          }
        }
      }}
      initialValues={{
        id: values.id,
        uniqueCostTypeCode: values.unique_cost_type_code || '',
        description: values.description || '',
        customerIdentification: values.customer_identification || '',
        supplierIdentification: values.supplier_identification || '',
      }}
    >
      {({ setFieldValue, values, handleChange, handleSubmit }) => (
        <>
          {showCustomerModal ? (
            <TableModal
              url={Urls.customer}
              title="Customer Identification"
              show={showCustomerModal}
              handleClose={handleCloseCustomerModal}
              formName={'customer'}
              columnToTransform={[]}
              handleConfirmation={handleCustomerConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showSupplierModal ? (
            <TableModal
              url={Urls.supplier}
              title="Supplier Identification"
              show={showSupplierModal}
              handleClose={handleCloseSupplierModal}
              formName={'supplier'}
              columnToTransform={[]}
              handleConfirmation={handleSupplierConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Unit cost type code"
                name="uniqueCostTypeCode"
                type="text"
                value={values.uniqueCostTypeCode}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    unique_cost_type_code: e.target.value,
                  });
                }}
              />
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    description: e.target.value,
                  });
                }}
              />
            </Form.Row>
            <Form.Row>
              {customerFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      customer_identification: option ? option.id : '',
                    });
                    setFieldValue('customerIdentification', option ? option.id : '');
                    setCustomerCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            customerDescription: option.description,
                          }
                        : { ...descriptions, customerDescription: '' }
                    );
                    setCustomerFromModal(false);
                  }}
                  name="customerIdentification"
                  label="Customer Identification"
                  defaultValue={customerDefaultValues}
                  value={customerDefaultValues}
                  options={customerData.options}
                  setShow={setShowCustomerModal}
                  description={customerDefaultValues.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      customer_identification: option ? option.id : '',
                    });
                    setFieldValue('customerIdentification', option ? option.id : '');
                    setCustomerCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            customerDescription: option.description,
                          }
                        : { ...descriptions, customerDescription: '' }
                    );
                  }}
                  name="customerIdentification"
                  label="Customer Identification"
                  defaultValue={{
                    value: values.customerIdentification,
                    label: values.customerIdentification,
                  }}
                  options={customerData.options}
                  setShow={setShowCustomerModal}
                  description={descriptions.customerDescription}
                />
              )}
              {supplierFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      supplier_identification: option ? option.id : '',
                    });
                    setFieldValue('supplierIdentification', option ? option.id : '');
                    setSupplierCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            supplierDescription: option.description,
                          }
                        : { ...descriptions, supplierDescription: '' }
                    );
                    setSupplierFromModal(false);
                  }}
                  name="supplierIdentification"
                  label="Supplier Identification"
                  defaultValue={supplierDefaultValues}
                  value={supplierDefaultValues}
                  options={supplierData.options}
                  setShow={setShowSupplierModal}
                  description={supplierDefaultValues.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      supplier_identification: option ? option.id : '',
                    });
                    setFieldValue('supplierIdentification', option ? option.id : '');
                    setSupplierCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            supplierDescription: option.description,
                          }
                        : { ...descriptions, supplierDescription: '' }
                    );
                  }}
                  name="supplierIdentification"
                  label="Supplier Identification"
                  defaultValue={{
                    value: values.supplierIdentification,
                    label: values.supplierIdentification,
                  }}
                  options={supplierData.options}
                  setShow={setShowSupplierModal}
                  description={descriptions.supplierDescription}
                />
              )}
            </Form.Row>
            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CostType;
