import { useState, useEffect } from 'react';
import { Button, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as ImIcons from 'react-icons/im';
import * as FaIcons from 'react-icons/fa';
import * as RiIcons from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import { setMenuAction } from '../../redux/actions/menuAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Urls from '../Urls';
import axios from 'axios';

const SidebarModules = () => {
  const [moduleSelected, setModuleSelected] = useState('INVENTORY');
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu.menu);
  const userProfile = JSON.parse(localStorage.getItem('user')) || {};
  const history = useHistory();
  const urlCompanyModule = Urls.company_module;
  const [token] = useState(localStorage.getItem('accessToken'));
  const [moduleInventory, setModuleInventory] = useState(false);
  const [moduleIndustrial, setModuleIndustrial] = useState(false);
  const [moduleAdministration, setModuleAdministration] = useState(false);

  useEffect(() => {
    const module =
      localStorage.getItem('menu') !== null ? localStorage.getItem('menu') : 'INVENTORY';
    setModuleSelected(module);
  }, [menu]);

  const enableModule = (variable) => {
    if (variable === 'Inventory') {
      setModuleInventory(true);
    } else if (variable === 'Industrial') {
      setModuleIndustrial(true);
    } else if (variable === 'Administration') {
      setModuleAdministration(true);
    }
  };

  useEffect(() => {
    let nameModule1 = 0;
    let nameModule2 = 0;
    let nameModule3 = 0;

    const query = `${urlCompanyModule}`;
    axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data) {
          const normaliceResponse = () => {
            let result = [];
            Object.keys(res.data).forEach((key) => {
              result.push(res.data[key]);
            });
            return result;
          };
          nameModule1 = normaliceResponse()[3]
            .filter((element) => element.company_name === userProfile.company)
            .filter((element) => element.module_name === 'Inventory')
            .map((element) => element.module_name);
          enableModule(nameModule1[0]);

          nameModule2 = normaliceResponse()[3]
            .filter((element) => element.company_name === userProfile.company)
            .filter((element) => element.module_name === 'Industrial')
            .map((element) => element.module_name);
          enableModule(nameModule2[0]);

          nameModule3 = normaliceResponse()[3]
            .filter((element) => element.company_name === userProfile.company)
            .filter((element) => element.module_name === 'Administration')
            .map((element) => element.module_name);
          enableModule(nameModule3[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const NavItemCustom = ({ name, moduleSelected, onClick, disabled }) => {
    const active = moduleSelected.toLowerCase() === name.toLowerCase();
    const backgroundColor = active ? '#49158b' : 'white';
    const iconColor = active ? 'white' : '#49158b';

    let icon;
    switch (name) {
      case 'Inventory':
        icon = <FaIcons.FaWarehouse />;
        break;
      case 'Industrial':
        icon = <ImIcons.ImStatsDots />;
        break;
      case 'Administration':
        icon = <RiIcons.RiAdminFill />;
        break;
    }

    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{name}</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <Button
            {...triggerHandler}
            className="d-inline-flex align-items-center mb-2"
            disabled={disabled}
            onClick={onClick}
            style={{
              borderColor: '#49158b',
              borderRadius: '5px',
              backgroundColor,
              width: '42px',
              height: '42px',
              justifyContent: 'center',
            }}
          >
            <IconContext.Provider value={{ color: iconColor, className: 'global-class-name' }}>
              <div>{icon}</div>
            </IconContext.Provider>
          </Button>
        )}
      </OverlayTrigger>
    );
  };

  return (
    <Navbar className="float-right" style={{ width: '56px', paddingLeft: '7px' }}>
      <Nav className="nav-menu flex-column">
        <NavItemCustom
          name="Inventory"
          link="/inventory/home"
          disabled={moduleInventory !== true}
          moduleSelected={moduleSelected}
          onClick={() => {
            dispatch(setMenuAction('INVENTORY'));
            localStorage.setItem('menu', 'INVENTORY');
            history.push('/inventory/home');
          }}
        />

        <NavItemCustom
          name="Industrial"
          link="/industrial/home"
          disabled={moduleIndustrial !== true}
          moduleSelected={moduleSelected}
          onClick={() => {
            dispatch(setMenuAction('INDUSTRIAL'));
            localStorage.setItem('menu', 'INDUSTRIAL');
            history.push('/industrial/home');
          }}
        />

        <NavItemCustom
          name="Administration"
          link="/industrial/home"
          disabled={moduleAdministration !== true}
          moduleSelected={moduleSelected}
          onClick={() => {
            dispatch(setMenuAction('ADMINISTRATION'));
            localStorage.setItem('menu', 'ADMINISTRATION');
            history.push('/administration/home');
          }}
        />
      </Nav>
    </Navbar>
  );
};

export default SidebarModules;
