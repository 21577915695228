import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

// async thunk to fetch quality_oee data
export const getQualityAsync = createAsyncThunk(
  'oee_quality/getQualityAsync',
  async ({ startDate, endDate, temporality, product, shift, workCenterList }) => {
    try {
      const res = await apiService.getQualityByFilters(
        startDate,
        endDate,
        temporality,
        product,
        shift,
        workCenterList
      );
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const qualitySlice = createSlice({
  name: 'oee_quality',
  initialState: {
    data: {},
    loading: false,
    error: null,
    progress: { pending: true, current: 0, total: 0, percent: 0 },
    status: 'idle',
    workCenterList: [],
    filters: {},
  },
  reducers: {
    getQuality: (state, action) => {
      state.data = action.payload;
    },
    setDataQuality: (state, action) => {
      state.data = action.payload;
    },
    setProgressQuality: (state, action) => {
      if (action.payload.current >= state.progress?.current) {
        state.progress = action.payload;
      } else {
        state.progress = state.progress;
      }
    },
    setStatusQuality: (state, action) => {
      state.status = action.payload;
      if (action.payload === 'SUCCESS') {
        state.progress = { ...state.progress, current: 0, percent: 100 };
      }
    },
    setQualityWorkCenterList: (state, action) => {
      state.workCenterList = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  // extra reducers for async thunks
  extraReducers: (builder) => {
    // in case of fulfilled (20X code) initialState.data = action.payload
    builder.addCase(getQualityAsync.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    // in case of pending (request is being made) initialState.loading = true
    builder.addCase(getQualityAsync.pending, (state, action) => {
      state.loading = true;
    });
    // in case of rejected (40X code) initialState.error = true
    builder.addCase(getQualityAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const {
  getQuality,
  setDataQuality,
  setProgressQuality,
  setStatusQuality,
  setQualityWorkCenterList,
  setFilters,
} = qualitySlice.actions;
export const showQuality = (state) => state.oee_quality.data;
export const qualityLoadingState = (state) => state.oee_quality.loading;
export const qualityErrorState = (state) => state.oee_quality.error;

export default qualitySlice.reducer;
