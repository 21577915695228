import React, { useState, useEffect } from 'react';
import apiService from '../apiService';
import {
  SelectionState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import { useDispatch } from 'react-redux';
import { setSelectedProductionOrders, setSelectedWorkCenters } from '../../redux/slices/tableSlice';

const Tabla = ({ url, name }) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [data, setData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      const res = await apiService.getDataWithFilters(url, [`manufacturing_status=closed`]);
      setData({ tableData: res, headers: res[0].keys });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    setRows(data.tableData);
    if (name === 'workCenter') {
      setColumns([
        { name: 'code', title: 'Code' },
        { name: 'description', title: 'Description' },
        { name: 'status', title: 'Status' },
        { name: 'work_center_category', title: 'Category' },
        { name: 'operation_center_description', title: 'Operation Center' },
      ]);
    } else if (name === 'productionOrder') {
      setColumns([
        { name: 'code', title: 'Code' },
        { name: 'operation_center_description', title: 'Operation Center' },
        { name: 'start_date', title: 'Start Date' },
        { name: 'end_date', title: 'End Date' },
        { name: 'planning_closing_date', title: 'Planning Closing Date' },
      ]);
    }
  }, [data]);

  useEffect(() => {
    const selectedCodes = [];
    selection.map((option) => selectedCodes.push(rows[option].code));
    if (name == 'workCenter') {
      dispatch(setSelectedWorkCenters(selectedCodes));
    } else if (name == 'productionOrder') {
      dispatch(setSelectedProductionOrders(selectedCodes));
    }
  }, [selection]);

  return (
    <div>
      <span>Total rows selected: {selection.length}</span>
      <div className="card">
        <Grid rows={rows} columns={columns}>
          <PagingState defaultCurrentPage={0} pageSize={5} />
          <SelectionState selection={selection} onSelectionChange={setSelection} />
          <IntegratedPaging />
          <IntegratedSelection />
          <Table />
          <TableHeaderRow />
          <TableSelection showSelectAll />
          <PagingPanel />
        </Grid>
      </div>
    </div>
  );
};

export default Tabla;
