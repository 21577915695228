import React, { useState, useEffect } from 'react';
import { Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { GiCancel } from 'react-icons/gi';
import { HiExternalLink } from 'react-icons/hi';
import { IoMdHelpCircle } from 'react-icons/io';
import TextField from '../FormComponents/TextField/TextField';
import TableModal from '../TableModal/TableModal';
import apiService from '../apiService';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import Urls from '../Urls';
import { setIsModalPaginationAction } from '../../redux/actions/paginationAction';
import 'react-toastify/dist/ReactToastify.css';
import uploadFiles from '../../utils/uploadFiles';
import { notification } from '../AlertMessage/ToastifyAlert';

const DispatchOrder = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  setError,
  onEdited,
  handleConfirmation,
  setEntryTransaction,
  setDispatchTransaction,
  title,
}) => {
  const [valuesToEdit, setValuesToEdit] = useState({});
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [document, setDocument] = useState(values.attachment_file || '');
  const [documentName, setDocumentName] = useState(
    values.attachment_file ? values.attachment_file.split('/')[5] : ''
  );
  const [documentLoading, setDocumentLoading] = useState(false);
  const [customerCodeTransformed, setCustomerCodeTransformed] = useState();
  const [customerDefaultValues, setCustomerDefaultValues] = useState({});
  const [customerFromModal, setCustomerFromModal] = useState(false);
  const [descriptions, setDescriptions] = useState({ customerDescription: '' });
  const [customerData, setCustomerData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const accessToken = localStorage.getItem('accessToken');
  const DispatchOrderValidate = Yup.object({
    dispatchOrderCode: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    responsible: Yup.string(),
    attachmentFile: Yup.string(),
    customerIdentification: Yup.string().required('Required'),
  });

  //pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };

  useEffect(async () => {
    const resCustomer = await apiService.getData(Urls.customer, 'customer_identification');

    setCustomerData({
      headers: resCustomer.headersList,
      tableData: resCustomer.data,
      options: resCustomer.results,
    });
  }, []);

  const handleCloseCustomerModal = () => {
    setIsModalPagination();
    setShowCustomerModal(false);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      File Maximum Size: 10MB
    </Tooltip>
  );

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
        customer_identification: customerCodeTransformed,
      });
    }
  }, [added]);

  useEffect(() => {
    customerData.options.forEach((option) => {
      if (option.value === values.customer_identification) {
        setDescriptions(
          option
            ? { ...descriptions, customerDescription: option.description }
            : { ...descriptions, customerDescription: '' }
        );
      }
    });
  }, [customerData]);

  useEffect(() => {
    setValuesToEdit({
      ...valuesToEdit,
      attachment_file: document,
    });
  }, [document]);

  const handleCustomerConfirm = (selected, title, setFieldValue) => {
    setCustomerDefaultValues({
      value: selected.id,
      label: selected.customer_identification,
      description: selected.company_name,
    });
    setValuesToEdit({ ...valuesToEdit, customer_identification: selected.id });
    setFieldValue('customerIdentification', selected.id);
    setCustomerCodeTransformed(selected.customer_identification);
    setShowCustomerModal(false);
    setCustomerFromModal(true);
  };

  return (
    <Formik
      validateOnChange
      validationSchema={DispatchOrderValidate}
      onSubmit={async (data) => {
        const processedValues = {
          dispatch_order_code: data.dispatchOrderCode,
          responsible: data.responsible,
          attachment_file: document,
          customer_identification: data.customerIdentification,
        };
        setProcessedFormValues(processedValues);
        setOriginalFormValues(data);
        if (data.id == null) {
          const created = await handleAPIRequest(processedValues, {
            entity: 'Dispatch Order',
            code: data.dispatchOrderCode,
          });
          if (created.statusText == 'Created') {
            if (handleConfirmation !== null) {
              handleConfirmation(created.data, title);
              setDispatchTransaction('yes');
              setEntryTransaction('no');
            } else {
              window.location.reload(false);
            }
          } else {
            notification('Error creating item - duplicate item', 'error');
          }
        } else {
          const editedValues = {
            dispatch_order_code: data.dispatchOrderCode,
            responsible: data.responsible,
            attachment_file: document,
            customer_identification: customerCodeTransformed || data.customerIdentification,
          };
          const updated = await handleAPIRequestUpdate(data.id, valuesToEdit, {
            entity: 'Dispatch Order',
            code: data.dispatchOrderCode,
          });
          if (updated.statusText == 'OK') {
            onEdited(editedValues);
            setAddForm(false);
            notification('Item updated successfully', 'success');
          } else {
            notification('Error updating item - duplicate item', 'error');
          }
        }
      }}
      initialValues={{
        id: values.id,
        dispatchOrderCode: values.dispatch_order_code || '',
        responsible: values.responsible || '',
        attachmentFile: document || '',
        customerIdentification: values.customer_identification || '',
      }}
    >
      {({ setFieldValue, values, handleChange, handleSubmit }) => (
        <>
          {showCustomerModal ? (
            <TableModal
              url={Urls.customer}
              title="Customer Identification"
              show={showCustomerModal}
              handleClose={handleCloseCustomerModal}
              formName={'customer'}
              columnToTransform={[]}
              handleConfirmation={handleCustomerConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Dispatch Order Code"
                name="dispatchOrderCode"
                type="text"
                value={values.dispatchOrderCode}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    dispatch_order_code: e.target.value,
                  });
                }}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Responsible"
                name="responsible"
                type="text"
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    responsible: e.target.value,
                  });
                }}
              />
            </Form.Row>
            <Form.Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label className="label">
                  Attach File
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <IoMdHelpCircle />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(file) =>
                    uploadFiles(
                      file,
                      setDocument,
                      setDocumentName,
                      setDocumentLoading,
                      notification,
                      setError,
                      accessToken
                    )
                  }
                  accept=".docx,.pdf,.odt,.txt"
                  size="sm"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <TextField
                label="Attached File"
                name="attatch"
                type="text"
                value={documentName}
                readOnly
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    attachment_file: e.target.value || '',
                  });
                }}
              />
              {documentLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                ></Spinner>
              ) : (
                <GiCancel
                  size={23}
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                  onClick={() => {
                    setDocument('');
                    setDocumentName('');
                  }}
                />
              )}
              {documentName !== '' ? (
                <a target="_blank" href={document} rel="noreferrer">
                  <HiExternalLink
                    size={26}
                    style={{
                      'margin-top': '2.5rem',
                      'margin-left': '0.5rem',
                    }}
                  />
                </a>
              ) : null}
            </Form.Row>
            <Form.Row>
              {customerFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      customer_identification: option ? option.id : '',
                    });
                    setFieldValue('customerIdentification', option ? option.id : '');
                    setCustomerCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            customerDescription: option.description,
                          }
                        : { customerDescription: '' }
                    );
                    setCustomerFromModal(false);
                  }}
                  name="customerIdentification"
                  label="Customer Identification"
                  options={customerData.options}
                  setShow={setShowCustomerModal}
                  defaultValue={customerDefaultValues}
                  value={customerDefaultValues}
                  description={customerDefaultValues.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      customer_identification: option ? option.id : '',
                    });
                    setFieldValue('customerIdentification', option ? option.id : '');
                    setCustomerCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            customerDescription: option.description,
                          }
                        : { customerDescription: '' }
                    );
                  }}
                  name="customerIdentification"
                  label="Customer Identification"
                  options={customerData.options}
                  setShow={setShowCustomerModal}
                  defaultValue={{
                    value: values.customerIdentification,
                    label: values.customerIdentification,
                  }}
                  description={descriptions.customerDescription}
                />
              )}
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default DispatchOrder;
