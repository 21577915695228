import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { showProducedUnits } from '../../../redux/slices/producedUnitsSlice';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup, Row } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';
import { showFilterList } from '../../../redux/slices/indicatorFilterSlice';

const ProducedPercentages = ({ indicatorName }) => {
  const filterList = useSelector(showFilterList);
  const filters = useSelector((state) => state.producedUnits.filters);
  const { workCenter, product, productionOrder, shift, from, to, temporality } = filters;
  const [subtitle, setSubtitle] = useState('');

  // graph information
  const producedUnitsData = useSelector(showProducedUnits);
  const data = producedUnitsData.percentageOfSKUsProduced;
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [paginationCount, setPaginationCount] = useState(0);

  useEffect(() => {
    const subtitle = `Filtered by work center ${workCenter}${
      product ? ', product ' + product : ''
    }${productionOrder ? ', production order ' + productionOrder : ''}${
      shift ? ', shift ' + shift : ''
    }, from ${from} to ${to} ${'(' + temporality + ')'}.`;
    setSubtitle(subtitle);
  }, [filters]);

  useEffect(() => {
    const series = data?.series || [];
    const skus = data?.categories || [];
    setSeries(series);
    setCategories(skus);
  }, [data]);

  const options = {
    chart: {
      height: 350,
      width: 350,
      type: 'pie',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    colors: ['#08bfff', '#6cffa7', '#ffae16', '#ff3c77', '#49158b', '#ff4f2b'],
    labels: categories,
    legend: {
      position: 'right',
      horizontalAlign: 'left',
      offsetY: 50,
      offsetX: 100,
    },
    title: {
      text: 'Percentage of SKUs Produced',
    },
    subtitle: {
      text: subtitle,
      align: 'left',
      offsetY: 20,
      style: {
        color: '#333',
        fontSize: '12px',
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -9,
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
      },
      offsetY: -20,
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
  };

  const [showTableData, setShowTableData] = useState(false);
  const handleCloseModal = () => {
    setShowTableData(false);
  };

  // Set Produced Percentage table data
  const [excelTableData, setExcelTableData] = useState([]);
  useEffect(() => {
    let data = [];
    for (let i = 0; i < categories.length; i++) {
      data.push({
        sku: categories[i],
        percentage: series[i] || 0,
      });
    }
    setExcelTableData(data);
    setPaginationCount(data.length);
  }, [categories]);

  return (
    <>
      <Card className="p-3 my-2">
        <SimpleTableModal
          title={'Percentage of SKUs Produced'}
          headers={['sku', 'percentage']}
          tableData={excelTableData}
          show={showTableData}
          handleClose={handleCloseModal}
          totalCount={paginationCount}
        />
        <div>
          <Row className="flex-row-reverse">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle className="fpb-btn-chart" id="dropdown-download" />
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowTableData(true);
                  }}
                >
                  Show Data
                </Dropdown.Item>
                <ExportExcel
                  excelData={excelTableData}
                  fileName={indicatorName + ' Report (' + from + ' -' + to + ')'}
                  indicator={indicatorName}
                  filters={subtitle}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </div>
        <Chart
          options={JSON.parse(JSON.stringify(options))}
          series={JSON.parse(JSON.stringify(series))}
          type="pie"
          height={420}
        />
      </Card>
    </>
  );
};

export default ProducedPercentages;
