import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Col,
  Row,
  Form,
  Dropdown,
  DropdownButton,
  InputGroup,
  FormLabel,
} from 'react-bootstrap';
import ClearableButton from '../Buttons/ClearableButton';
import { useDispatch, useSelector } from 'react-redux';
import FPBTable from '../FPBTable/FPBTable';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import Urls from '../Urls';
import { setTransactionQueryAction } from '../../redux/actions/transactionQueryAction';
import { setCurrentPageAction, setTotalCountAction } from '../../redux/actions/paginationAction';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FaTable, FaArrowCircleRight } from 'react-icons/fa';
import TableModal from '../TableModal/TableModal';
import { setIsModalPaginationAction } from '../../redux/actions/paginationAction';
import getQueryString from '../../utils/getTransactionsQueryString';
import { Link } from 'react-router-dom';
import SimpleTableModal from '../TableModal/SimpleTableModal';
import { notification } from '../AlertMessage/ToastifyAlert';
import getTransactionTableData from '../Transactions/getTransactionTableData';
import { columnOrder, columnsToHide } from '../Transactions/transactionTableColumns';
import RangeDateInput from '../DateComponents/RangeDateInput';
import InformationTableModal from '../TableModal/InformationTableModal';
import {
  TransactionCodeSelection,
  TransactionTypeSelection,
  TransactionSubTypeSelection,
  UserEmailSelection,
  ParentCategorySelection,
  ChildCategorySelection,
} from '../SelectionComponents';

const fileDownload = require('js-file-download');

const AdvancedFilters = () => {
  const url = Urls.transaction;
  const urlStorageCenter = Urls.storageCenter;
  const urlStorageLocation = Urls.storageLocation;
  const ulrDimensionalUnit = Urls.dimensionalUnit;
  const urlEntryOrder = Urls.entryOrder;
  const urlDispatchOrder = Urls.dispatchOrder;
  const urlAdjustmentCode = Urls.adjustment;
  const urlDimensionalUnit = Urls.dimensionalUnit;
  const urlItemCode = Urls.item;
  const urlBatchCode = Urls.batch;
  const urlSupplier = Urls.supplier;
  const urlCustomer = Urls.customer;
  const urlItemBatchRelation = Urls.itemBatchRelation;
  const [token] = useState(localStorage.getItem('accessToken'));
  const [transactionFilters] = useState(localStorage.getItem('transactionQuery'));
  const [filterSubtitleStorage] = useState(localStorage.getItem('filterSubtitle'));
  const [addedRow] = useState({});
  const [headers, setHeaders] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [downloadButton, setDownloadButton] = useState(true);
  // async select
  const [disableStorageCenter, setDisableStorageCenter] = useState(false);
  const [disableStorageCenterDestination, setDisableStorageCenterDestination] = useState(false);
  // pre-fetched data
  const [showTransactionReportModal, setShowTransactionReportModal] = useState(false);
  const [extraTransactionInfo, setExtraTransactionInfo] = useState([]);
  const dataTransactionHeaders = ['Transaction Data', 'Count'];
  // fixed options
  const amountFilters = [
    { value: '=', label: 'Equal' },
    { value: '>=', label: 'Greater than or equal' },
    { value: '<=', label: 'Less than or equal' },
  ];
  const dateFiltersOptions = [
    { value: 'day', label: 'day' },
    { value: 'week', label: 'week' },
    { value: 'month', label: 'month' },
    { value: 'year', label: 'year' },
  ];
  const returnTransactionOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];
  const returnApprovedOptions = [
    { value: 'true', label: 'None' },
    { value: 'false', label: 'Not None' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];
  const storageColumnToTransform = [
    {
      name: 'storage_center_code',
      url: 'storageCenter',
      field: 'storage_center_code',
    },
  ];
  const entryOrderColumnToTransform = [
    {
      name: 'supplier_identification',
      url: 'supplier',
      field: 'supplier_identification',
    },
  ];
  const dispatchOrderColumnToTransform = [
    {
      name: 'customer_identification',
      url: 'customer',
      field: 'customer_identification',
    },
  ];
  const itemCodeColumnToTransform = [
    {
      name: 'item_category',
      url: 'itemCategory',
      field: 'item_category_code',
    },
    {
      name: 'label',
      url: 'label',
      field: 'label_code',
    },
    {
      name: 'consolidation_level_N0',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N1',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N2',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N3',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N4',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
  ];
  // filters
  const [filterSubtitle, setFilterSubtitle] = useState('None');
  const [filterSubtitleOnConfirm, setFilterSubtitleOnConfirm] = useState('None');
  const [comparisonOptionSelected, setComparisonOptionSelected] = useState({
    value: '=',
    label: '=',
  });
  const [dateOptionSelected, setDateOptionSelected] = useState({ value: 'day', label: '' });
  const [dateFilters, setDateFilters] = useState({});
  const [amountFilterSelected, setAmountFilterSelected] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedDescriptions, setSelectedDescriptions] = useState({});
  const [emptyFilters, setEmptyFilters] = useState({});
  const [amountSelected, setAmountSelected] = useState();
  const [filterString, setFilterString] = useState('');
  const defaultSelectValue = { value: '', label: 'Select...' };
  const [selectedTransactionType, setSelectedTransactionType] = useState(defaultSelectValue);
  const [selectedTransactionSubType, setSelectedTransactionSubType] = useState(defaultSelectValue);
  const [selectedReturnTransaction, setSelectedReturnTransaction] = useState(defaultSelectValue);
  const [selectedReturnAproved, setSelectedReturnAproved] = useState(defaultSelectValue);
  const [selectedTransactionCode, setSelectedTransactionCode] = useState(defaultSelectValue);
  const [selectedTransactionUser, setSelectedTransactionUser] = useState(defaultSelectValue);
  const [selectedStorageCenter, setSelectedStorageCenter] = useState(defaultSelectValue);
  const [selectedStorageCenterDestination, setSelectedStorageCenterDestination] =
    useState(defaultSelectValue);
  const [selectedStorageLocation, setSelectedStorageLocation] = useState(defaultSelectValue);
  const [selectedStorageLocationDestination, setSelectedStorageLocationDestination] =
    useState(defaultSelectValue);
  const [selectedDimensionalUnit, setSelectedDimensionalUnit] = useState(defaultSelectValue);
  const [selectedApprovalUser, setSelectedApprovalUser] = useState(defaultSelectValue);
  const [selectedEntryOrder, setSelectedEntryOrder] = useState(defaultSelectValue);
  const [selectedDispatchOrder, setSelectedDispatchOrder] = useState(defaultSelectValue);
  const [selectedAdjustmentCode, setSelectedAdjustmentCode] = useState(defaultSelectValue);
  const [selectedItemCode, setSelectedItemCode] = useState(defaultSelectValue);
  const [selectedBatchCode, setSelectedBatchCode] = useState(defaultSelectValue);
  const [selectedSupplier, setSelectedSupplier] = useState(defaultSelectValue);
  const [selectedClient, setSelectedClient] = useState(defaultSelectValue);
  const [selectedItemCategory, setSelectedItemCategory] = useState(defaultSelectValue);
  const [selectedParentItemCategory, setSelectedParentItemCategory] = useState(defaultSelectValue);
  // descriptions
  const [storageCenterDescription, setStorageCenterDescription] = useState();
  const [storageLocationDescription, setStorageLocationDescription] = useState();
  const [storageCenterDestinationDescription, setStorageCenterDestinationDescription] = useState();
  const [storageLocationDestinationDescription, setStorageLocationDestinationDescription] =
    useState();
  const [dimensionalUnitDescription, setDimensionalUnitDescription] = useState();
  const [adjustmentCodeDescription, setAdjustmentCodeDescription] = useState();
  const [itemCodeDescription, setItemCodeDescription] = useState();
  const [itemCategoryDescription, setItemCategoryDescription] = useState();
  const [itemParentCategoryDescription, setItemParentCategoryDescription] = useState();
  // table modal
  const [showStorageCenterModal, setShowStorageCenterModal] = useState(false);
  const [showStorageLocationModal, setShowStorageLocationModal] = useState(false);
  const [showDimensionalUnitModal, setShowDimensionalUnitModal] = useState(false);
  const [showEntryOrderModal, setShowEntryOrderModal] = useState(false);
  const [showDispatchOrderModal, setShowDispatchOrderModal] = useState(false);
  const [showAdjustmentCodeModal, setShowAdjustmentCodeModal] = useState(false);
  const [showItemCodeModal, setShowItemCodeModal] = useState(false);
  const [showBatchCodeModal, setShowBatchCodeModal] = useState(false);
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  // pagination
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  const dispatchTotalCount = useDispatch();
  const dispatchTransactionQuery = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatchTotalCount(setTotalCountAction(totalCount));
  };
  const setTransactionQuery = (transactionQuery) => {
    dispatchTransactionQuery(setTransactionQueryAction(transactionQuery));
  };
  const dispatchIsModalPagination = useDispatch();
  const dispatchCurrentPage = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };
  const setCurrentPage = (currentPage) => {
    dispatchCurrentPage(setCurrentPageAction(currentPage));
  };

  const handleEdit = (id, data) => {
    axios.patch(`${url}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const FilterSubtitle = () => (
    <p style={{ fontSize: '12px' }} className="text-secondary">
      Filtered by: {filterSubtitleOnConfirm}
    </p>
  );

  useEffect(() => {
    let filter = '';
    Object.keys(selectedDescriptions).forEach((key) => {
      if (selectedDescriptions[key] !== '') {
        filter = `${filter}&${key}=${selectedDescriptions[key]}`;
      }
    });
    Object.keys(amountFilterSelected).forEach((key) => {
      if (amountFilterSelected[key] !== '') {
        if (key === 'amount__gte') {
          filter = `${filter}&amount>=${encodeURIComponent(amountFilterSelected[key])}`;
        } else if (key === 'amount__lte') {
          filter = `${filter}&amount<=${encodeURIComponent(amountFilterSelected[key])}`;
        } else {
          filter = `${filter}&${key}=${encodeURIComponent(amountFilterSelected[key])}`;
        }
      }
    });
    Object.keys(emptyFilters).forEach((key) => {
      if (emptyFilters[key] !== '') {
        filter = `${filter}&${key}=${encodeURIComponent(emptyFilters[key])}`;
      }
    });
    Object.keys(dateFilters).forEach((key) => {
      if (dateFilters[key] !== '') {
        if (key === 'transaction_date__year__gte') {
          filter = `${filter}&transaction_date__after=${encodeURIComponent(dateFilters[key])}`;
        } else if (key === 'transaction_date__year__lte') {
          filter = `${filter}&transaction_date__before=${encodeURIComponent(dateFilters[key])}`;
        } else {
          filter = `${filter}&${key}=${encodeURIComponent(dateFilters[key])}`;
        }
      }
    });
    const result = filter.substring(1);
    setFilterSubtitle(`${result}`);
  }, [selectedDescriptions, amountFilterSelected, emptyFilters, dateFilters]);

  const getFilterString = () => {
    let filter = '';
    Object.keys(selectedFilters).forEach((key) => {
      if (selectedFilters[key] !== '') {
        filter = `${filter}&${key}=${encodeURIComponent(selectedFilters[key])}`;
      }
    });
    Object.keys(amountFilterSelected).forEach((key) => {
      if (amountFilterSelected[key] !== '') {
        filter = `${filter}&${key}=${encodeURIComponent(amountFilterSelected[key])}`;
      }
    });
    Object.keys(emptyFilters).forEach((key) => {
      if (emptyFilters[key] !== '') {
        filter = `${filter}&${key}=${encodeURIComponent(emptyFilters[key])}`;
      }
    });
    Object.keys(dateFilters).forEach((key) => {
      if (dateFilters[key] !== '') {
        filter = `${filter}&${key}=${encodeURIComponent(dateFilters[key])}`;
      }
    });
    return filter;
  };
  // Refresh data of storage center code Select component based on input
  const getUpdatedStorageCenterCode = async (inputValue, loadedOptions, { page }) => {
    const query = `${urlStorageCenter}?page=${page}&page_size=${10}&storage_center_code__icontains=${inputValue}`;
    let storageCenters = [];
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            storageCenters.push({
              value: result.id,
              label: result.storage_center_code,
              description: result.description,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: storageCenters,
      hasMore: storageCenters.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  const getUpdatedStorageCenterDestinationCode = async (inputValue, loadedOptions, { page }) => {
    const query = `${urlStorageCenter}?page=${page}&page_size=${10}&storage_center_code__icontains=${inputValue}`;
    let storageCenters = [];
    if (page === 1) {
      storageCenters.push({
        value: 'true',
        label: 'None',
      });
      storageCenters.push({
        value: 'false',
        label: 'Not None',
      });
    }
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            storageCenters.push({
              value: result.id,
              label: result.storage_center_code,
              description: result.description,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: storageCenters,
      hasMore: storageCenters.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  // Refresh data of storage location Select component based on input
  const getUpdatedStorageLocationDestination = async (inputValue, loadedOptions, { page }) => {
    let query = '';
    if (
      selectedStorageCenterDestination.value === 'true' ||
      selectedStorageCenterDestination.value === 'false'
    ) {
      query = `${urlStorageLocation}?page=${page}&page_size=${10}&location_code__icontains=${inputValue}`;
    } else {
      query = `${urlStorageLocation}?page=${page}&page_size=${10}&storage_center_code__id=${
        selectedStorageCenterDestination.value
      }&location_code__icontains=${inputValue}`;
    }
    let storageLocations = [];
    if (page === 1) {
      storageLocations.push({
        value: 'true',
        label: 'None',
      });
      storageLocations.push({
        value: 'false',
        label: 'Not None',
      });
    }
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            storageLocations.push({
              value: result.id,
              label: result.location_code,
              description: result.description,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: storageLocations,
      hasMore: storageLocations.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  const getUpdatedStorageLocation = async (inputValue, loadedOptions, { page }) => {
    let query = `${urlStorageLocation}?page=${page}&page_size=${10}&storage_center_code__id=${
      selectedStorageCenter.value
    }&location_code__icontains=${inputValue}`;
    let storageLocations = [];
    if (page === 1) {
      storageLocations.push({
        value: 'true',
        label: 'None',
      });
      storageLocations.push({
        value: 'false',
        label: 'Not None',
      });
    }
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            storageLocations.push({
              value: result.id,
              label: result.location_code,
              description: result.description,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: storageLocations,
      hasMore: storageLocations.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  // Refresh data of dimensional unit Select component based on input
  const getUpdatedDimensionalUnit = async (inputValue, loadedOptions, { page }) => {
    const query = `${ulrDimensionalUnit}?page=${page}&page_size=${10}&dimensional_unit_code__icontains=${inputValue}`;
    let dimensionalUnits = [];
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            dimensionalUnits.push({
              value: result.id,
              label: result.dimensional_unit_code,
              description: result.description,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: dimensionalUnits,
      hasMore: dimensionalUnits.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  // Refresh data of entry order Select component based on input
  const getUpdatedEntryOrder = async (inputValue, loadedOptions, { page }) => {
    const query = `${urlEntryOrder}?page=${page}&page_size=${10}&entry_order_code__icontains=${inputValue}`;
    let entryOrders = [];
    if (page === 1) {
      entryOrders.push({
        value: 'true',
        label: 'None',
      });
      entryOrders.push({
        value: 'false',
        label: 'Not None',
      });
    }
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            entryOrders.push({
              value: result.id,
              label: result.entry_order_code,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: entryOrders,
      hasMore: entryOrders.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  // Refresh data of dispatch order Select component based on input
  const getUpdatedDispatchOrder = async (inputValue, loadedOptions, { page }) => {
    const query = `${urlDispatchOrder}?page=${page}&page_size=${10}&dispatch_order_code__icontains=${inputValue}`;
    let dispatchOrders = [];
    if (page === 1) {
      dispatchOrders.push({
        value: 'true',
        label: 'None',
      });
      dispatchOrders.push({
        value: 'false',
        label: 'Not None',
      });
    }
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            dispatchOrders.push({
              value: result.id,
              label: result.dispatch_order_code,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: dispatchOrders,
      hasMore: dispatchOrders.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  // Refresh data of adjustment code Select component based on input
  const getUpdatedAdjustmentCode = async (inputValue, loadedOptions, { page }) => {
    const query = `${urlAdjustmentCode}?page=${page}&page_size=${10}&adjustment_code__icontains=${inputValue}`;
    let adjustmentCodes = [];
    if (page === 1) {
      adjustmentCodes.push({
        value: 'true',
        label: 'None',
      });
      adjustmentCodes.push({
        value: 'false',
        label: 'Not None',
      });
    }
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            adjustmentCodes.push({
              value: result.id,
              label: result.adjustment_code,
              description: result.description,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: adjustmentCodes,
      hasMore: adjustmentCodes.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  const getUpdatedItemCode = async (inputValue, loadedOptions, { page }) => {
    const query = `${urlItemCode}?page=${page}&page_size=${10}&reference_code__icontains=${inputValue}`;
    let itemCodes = [];
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            itemCodes.push({
              value: result.id,
              label: result.reference_code,
              description: result.item_description,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: itemCodes,
      hasMore: itemCodes.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  const getUpdatedBatchCode = async (inputValue, loadedOptions, { page }) => {
    if (selectedItemCode.value !== '') {
      const query = `${urlItemBatchRelation}?page=${page}&page_size=${10}&item_reference_code__id=${
        selectedItemCode.value
      }`;
      let batchCodes = [];
      await axios
        .get(query, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.results.length > 0) {
            const results = res.data.results;
            results.forEach((result) => {
              batchCodes.push({
                value: result.batch_code,
                label: result.fk_info.batch_code,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return {
        options: batchCodes,
        hasMore: batchCodes.length >= 10,
        additional: {
          page: page + 1,
        },
      };
    } else {
      const query = `${urlBatchCode}?page=${page}&page_size=${10}&batch_code__icontains=${inputValue}`;
      let batchCodes = [];
      await axios
        .get(query, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.results.length > 0) {
            const results = res.data.results;
            results.forEach((result) => {
              batchCodes.push({
                value: result.id,
                label: result.batch_code,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return {
        options: batchCodes,
        hasMore: batchCodes.length >= 10,
        additional: {
          page: page + 1,
        },
      };
    }
  };

  const getUpdatedSupplier = async (inputValue, loadedOptions, { page }) => {
    const query = `${urlSupplier}?page=${page}&page_size=${10}&supplier_code__icontains=${inputValue}`;
    let suppliers = [];
    if (page === 1) {
      suppliers.push({
        value: 'true',
        label: 'None',
      });
      suppliers.push({
        value: 'false',
        label: 'Not None',
      });
    }
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            suppliers.push({
              value: result.supplier_identification,
              label: result.supplier_identification,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: suppliers,
      hasMore: suppliers.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  const getUpdatedClient = async (inputValue, loadedOptions, { page }) => {
    const query = `${urlCustomer}?page=${page}&page_size=${10}&customer_identiication=${inputValue}`;
    let clients = [];
    if (page === 1) {
      clients.push({
        value: 'true',
        label: 'None',
      });
      clients.push({
        value: 'false',
        label: 'Not None',
      });
    }
    await axios
      .get(query, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const results = res.data.results;
          results.forEach((result) => {
            clients.push({
              value: result.customer_identification,
              label: result.customer_identification,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return {
      options: clients,
      hasMore: clients.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    const queryString = getQueryString(currentPage, pageSize, filters, filterString);
    getTransactionTableData(
      queryString,
      lastQuery,
      token,
      setTotalCount,
      setHeaders,
      setTableData,
      setLastQuery
    );
  }, [url, pageSize, currentPage, filters]);

  // Loads previuos filters if exists
  useEffect(async () => {
    if (transactionFilters) {
      if (transactionFilters.length > 0 && transactionFilters[0].value !== '') {
        const subFilter = transactionFilters.substring(1);
        const queryString = getQueryString(currentPage, pageSize, filters, subFilter);
        console.log('loading', queryString, lastQuery);
        getTransactionTableData(
          queryString,
          lastQuery,
          token,
          setTotalCount,
          setHeaders,
          setTableData,
          setLastQuery
        );
        setFilterString(transactionFilters);
        setFilterSubtitleOnConfirm(filterSubtitleStorage);
      }
    }
  }, []);

  // close table modals
  const closeModal = () => {
    setIsModalPagination();
    setShowStorageCenterModal(false);
    setShowStorageLocationModal(false);
    setShowDimensionalUnitModal(false);
    setShowEntryOrderModal(false);
    setShowDispatchOrderModal(false);
    setShowAdjustmentCodeModal(false);
    setShowItemCodeModal(false);
    setShowBatchCodeModal(false);
    setShowCustomerModal(false);
    setShowSupplierModal(false);
  };

  useEffect(() => {
    if (amountSelected) {
      if (comparisonOptionSelected.value === '=') {
        setAmountFilterSelected({ amount: amountSelected });
      } else if (comparisonOptionSelected.value === '>=') {
        setAmountFilterSelected({ amount__gte: amountSelected });
      } else {
        setAmountFilterSelected({ amount__lte: amountSelected });
      }
    }
  }, [comparisonOptionSelected, amountSelected]);

  const handleDownload = () => {
    const filter = getFilterString();
    const queryString = `${url}?download${filter}`;
    if (queryString) {
      axios
        .get(queryString, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          fileDownload(res.data, `transactions${filter}.csv`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeTransactionCode = (e) => {
    setSelectedTransactionCode(e);
    if (e) {
      setSelectedFilters({ ...selectedFilters, unique_transaction_code: e.value });
      setSelectedDescriptions({ ...selectedDescriptions, transaction_code: e.value });
    } else {
      setSelectedFilters({ ...selectedFilters, unique_transaction_code: '' });
      setSelectedDescriptions({ ...selectedDescriptions, transaction_code: '' });
    }
  };

  const onChangeTransactionType = (e) => {
    setSelectedTransactionType(e);
    if (e) {
      setSelectedFilters({ ...selectedFilters, transaction_type: e.value });
      setSelectedDescriptions({ ...selectedDescriptions, transaction_type: e.value });
    } else {
      setSelectedFilters({ ...selectedFilters, transaction_type: '' });
      setSelectedDescriptions({ ...selectedDescriptions, transaction_type: '' });
    }
  };

  const onChangeTransactionSubType = (e) => {
    setSelectedTransactionSubType(e);
    if (e) {
      setSelectedFilters({ ...selectedFilters, transaction_subtype: e.value });
      setSelectedDescriptions({
        ...selectedDescriptions,
        transaction_subtype: e.value,
      });
    } else {
      setSelectedFilters({ ...selectedFilters, transaction_subtype: '' });
      setSelectedDescriptions({ ...selectedDescriptions, transaction_subtype: '' });
    }
  };

  const onChangeReturnApproved = (e) => {
    if (e) {
      setSelectedReturnAproved(e);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, return_approved__is_empty: e.value });
        setSelectedFilters({ ...selectedFilters, return_approved: '' });
        setSelectedDescriptions({ ...selectedDescriptions, return_approved: '' });
      } else {
        setSelectedFilters({ ...selectedFilters, return_approved: e.value });
        setEmptyFilters({ ...emptyFilters, return_approved__is_empty: '' });
        setSelectedDescriptions({ ...selectedDescriptions, return_approved: e.value });
      }
    } else {
      setSelectedReturnAproved({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, return_approved: '' });
      setEmptyFilters({ ...emptyFilters, return_approved__is_empty: '' });
      setSelectedDescriptions({ ...selectedDescriptions, return_approved: '' });
    }
  };

  const onChangeAdjustmentCode = (e) => {
    if (e) {
      setSelectedAdjustmentCode(e);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, adjustment_code__id__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, adjustment_code__id: '' });
        setSelectedDescriptions({ ...selectedDescriptions, adjustment_code: '' });
      } else {
        setSelectedFilters({ ...selectedFilters, adjustment_code__id: e.value });
        setEmptyFilters({ ...emptyFilters, adjustment_code__id__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, adjustment_code: e.label });
      }
      setAdjustmentCodeDescription(e.description);
    } else {
      setSelectedAdjustmentCode({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, adjustment_code__id: '' });
      setEmptyFilters({ ...emptyFilters, adjustment_code__id__isnull: '' });
      setAdjustmentCodeDescription('');
      setSelectedDescriptions({ ...selectedDescriptions, adjustment_code: '' });
    }
  };

  const onChangeDispatchOrder = (e) => {
    if (e) {
      setSelectedDispatchOrder(e);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, dispatch_order_code__id__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, dispatch_order_code__id: '' });
        setSelectedDescriptions({ ...selectedDescriptions, dispatch_order_code: '' });
      } else {
        setSelectedFilters({ ...selectedFilters, dispatch_order_code__id: e.value });
        setEmptyFilters({ ...emptyFilters, dispatch_order_code__id__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, dispatch_order_code: e.label });
      }
    } else {
      setSelectedDispatchOrder({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, dispatch_order_code__id: '' });
      setEmptyFilters({ ...emptyFilters, dispatch_order_code__id__isnull: '' });
      setSelectedDescriptions({ ...selectedDescriptions, dispatch_order_code: '' });
    }
  };

  const onChangeEntryOrder = (e) => {
    if (e) {
      setSelectedEntryOrder(e);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, entry_order_code__id__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, entry_order_code__id: '' });
        setSelectedDescriptions({ ...selectedDescriptions, entry_order_code: '' });
      } else {
        setSelectedFilters({ ...selectedFilters, entry_order_code__id: e.value });
        setEmptyFilters({ ...emptyFilters, entry_order_code__id__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, entry_order_code: e.label });
      }
    } else {
      setSelectedEntryOrder({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, entry_order_code__id: '' });
      setEmptyFilters({ ...emptyFilters, entry_order_code__id__isnull: '' });
      setSelectedDescriptions({ ...selectedDescriptions, entry_order_code: '' });
    }
  };

  const onChangeStorageLocation = (e) => {
    if (e) {
      setDisableStorageCenter(true);
      setSelectedStorageLocation(e);
      setStorageLocationDescription(e.description);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, storage_location__id__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, storage_location__id: '' });
        setSelectedDescriptions({ ...selectedDescriptions, storage_location: '' });
      } else {
        setSelectedFilters({ ...selectedFilters, storage_location__id: e.value });
        setEmptyFilters({ ...emptyFilters, storage_location__id__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, storage_location: e.label });
      }
    } else {
      setDisableStorageCenter(false);
      setSelectedStorageLocation({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, storage_location__id: '' });
      setEmptyFilters({ ...emptyFilters, storage_location__id__isnull: '' });
      setStorageLocationDescription('');
      setSelectedDescriptions({ ...selectedDescriptions, storage_location: '' });
    }
  };

  const onChangeStorageCenterDestination = (e) => {
    if (e) {
      setSelectedStorageCenterDestination(e);
      setStorageCenterDestinationDescription(e.description);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, storage_center_code_destination__id__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, storage_center_code_destination__id: '' });
        setSelectedDescriptions({ ...selectedDescriptions, storage_center_destination: '' });
      } else {
        setSelectedFilters({ ...selectedFilters, storage_center_code_destination__id: e.value });
        setEmptyFilters({ ...emptyFilters, storage_center_code_destination__id__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, storage_center_destination: e.label });
      }
    } else {
      setSelectedStorageCenterDestination({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, storage_center_code_destination__id: '' });
      setEmptyFilters({ ...emptyFilters, storage_center_code_destination__id__isnull: '' });
      setSelectedDescriptions({ ...selectedDescriptions, storage_center_destination: '' });
      setStorageCenterDestinationDescription('');
    }
  };

  const onChangeStorageLocationDestination = (e) => {
    if (e) {
      setDisableStorageCenterDestination(true);
      setSelectedStorageLocationDestination(e);
      setStorageLocationDestinationDescription(e.description);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, storage_location_destination__id__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, storage_location_destination__id: '' });
        setSelectedDescriptions({ ...selectedDescriptions, storage_location_destination: '' });
      } else {
        setSelectedFilters({ ...selectedFilters, storage_location_destination__id: e.value });
        setEmptyFilters({ ...emptyFilters, storage_location_destination__id__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, storage_location_destination: e.label });
      }
    } else {
      setDisableStorageCenterDestination(false);
      setSelectedStorageLocationDestination({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, storage_location_destination__id: '' });
      setEmptyFilters({ ...emptyFilters, storage_location_destination__id__isnull: '' });
      setSelectedDescriptions({ ...selectedDescriptions, storage_location_destination: '' });
      setStorageLocationDestinationDescription('');
    }
  };

  const onChangeUser = (e) => {
    setSelectedTransactionUser(e);
    if (e) {
      setSelectedFilters({ ...selectedFilters, user: e.value });
      setSelectedDescriptions({ ...selectedDescriptions, user: e.value });
    } else {
      setSelectedFilters({ ...selectedFilters, user: '' });
      setSelectedDescriptions({ ...selectedDescriptions, user: '' });
    }
  };

  const onChangeApprovalUser = (e) => {
    setSelectedApprovalUser(e);
    if (e) {
      setSelectedFilters({ ...selectedFilters, return_approval_user: e.value });
      setSelectedDescriptions({
        ...selectedDescriptions,
        return_approval_user: e.value,
      });
    } else {
      setSelectedFilters({ ...selectedFilters, return_approval_user: '' });
      setSelectedDescriptions({ ...selectedDescriptions, return_approval_user: '' });
    }
  };

  const onChangeSupplier = (e) => {
    if (e) {
      setSelectedSupplier(e);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, supplier__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, supplier: '' });
        setSelectedDescriptions({ ...selectedDescriptions, supplier: '' });
      } else {
        setSelectedFilters({ ...selectedFilters, supplier: e.value });
        setEmptyFilters({ ...emptyFilters, supplier__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, supplier: e.label });
      }
    } else {
      setSelectedSupplier({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, supplier: '' });
      setEmptyFilters({ ...emptyFilters, supplier__isnull: '' });
      setSelectedDescriptions({ ...selectedDescriptions, supplier: '' });
    }
  };

  const onChangeCustomer = (e) => {
    if (e) {
      setSelectedClient(e);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, customer__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, customer: '' });
        setSelectedDescriptions({ ...selectedDescriptions, customer: '' });
      } else {
        setSelectedFilters({ ...selectedFilters, customer: e.value });
        setEmptyFilters({ ...emptyFilters, customer__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, customer: e.label });
      }
    } else {
      setSelectedClient({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, customer: '' });
      setEmptyFilters({ ...emptyFilters, customer__isnull: '' });
      setSelectedDescriptions({ ...selectedDescriptions, customer: '' });
    }
  };

  const onChangeItemCategory = (e) => {
    if (e) {
      setSelectedItemCategory(e);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, item_category__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, item_category: '' });
        setSelectedDescriptions({ ...selectedDescriptions, item_category: '' });
        setItemCategoryDescription('');
      } else {
        setSelectedFilters({ ...selectedFilters, item_category: e.value });
        setEmptyFilters({ ...emptyFilters, item_category__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, item_category: e.label });
        setItemCategoryDescription(e.description);
      }
    } else {
      setSelectedItemCategory({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, item_category: '' });
      setEmptyFilters({ ...emptyFilters, item_category__isnull: '' });
      setSelectedDescriptions({ ...selectedDescriptions, item_category: '' });
      setItemCategoryDescription('');
    }
  };

  const onChangeItemParentCategory = (e) => {
    if (e) {
      setSelectedParentItemCategory(e);
      if (e.label === 'None' || e.label === 'Not None') {
        setEmptyFilters({ ...emptyFilters, item_parent_category__isnull: e.value });
        setSelectedFilters({ ...selectedFilters, item_parent_category: '' });
        setSelectedDescriptions({ ...selectedDescriptions, item_parent_category: '' });
        setItemParentCategoryDescription('');
      } else {
        setSelectedFilters({ ...selectedFilters, item_parent_category: e.value });
        setEmptyFilters({ ...emptyFilters, item_parent_category__isnull: '' });
        setSelectedDescriptions({ ...selectedDescriptions, item_parent_category: e.label });
        setItemParentCategoryDescription(e.description);
      }
    } else {
      setSelectedParentItemCategory({ value: '', label: 'Select...' });
      setSelectedFilters({ ...selectedFilters, item_parent_category: '' });
      setEmptyFilters({ ...emptyFilters, item_parent_category__isnull: '' });
      setSelectedDescriptions({ ...selectedDescriptions, item_parent_category: '' });
      setItemParentCategoryDescription('');
    }
  };

  const onClickRemoveAmount = () => {
    setAmountSelected('');
    if (comparisonOptionSelected) {
      if (comparisonOptionSelected.value === '=') {
        setAmountFilterSelected({ amount: '' });
      } else if (comparisonOptionSelected.value === '>=') {
        setAmountFilterSelected({ amount__gte: '' });
      } else {
        setAmountFilterSelected({ amount__lte: '' });
      }
    }
  };

  const onClickDateFilter = (filterItem) => {
    setDateOptionSelected(filterItem);
  };

  const getExtraTransactionInfo = async (filter) => {
    const queryString = `${url}?page=${1}&page_size=${1}${filter}`;
    let transactionData = [];
    await axios
      .get(queryString, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        transactionData.push({
          'Transaction Data': 'Transaction matching filter',
          Count: res.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${queryString}&return_transaction=yes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        transactionData.push({
          'Transaction Data': 'Transaction marked as return transaction',
          Count: res.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${queryString}&return_transaction=yes&return_approved=yes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        transactionData.push({
          'Transaction Data': 'Transaction is marked as return transaction and approved',
          Count: res.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${queryString}&return_transaction=yes&return_approved=no`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        transactionData.push({
          'Transaction Data': 'Transaction is marked as return transaction and denied',
          Count: res.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(`${queryString}&return_transaction=yes&return_approved__is_empty=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        transactionData.push({
          'Transaction Data': 'Transaction is marked as return transaction and pending',
          Count: res.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setExtraTransactionInfo(transactionData);
  };

  const handleFiltersSubmit = () => {
    setCurrentPage(0);
    const filter = getFilterString();
    setFilterString(filter);
    setTransactionQuery(filter);
    localStorage.setItem('transactionQuery', filter);
    const queryString = `${url}?page=${currentPage + 1}&page_size=${pageSize}${filter}`;
    getTransactionTableData(
      queryString,
      lastQuery,
      token,
      setTotalCount,
      setHeaders,
      setTableData,
      setLastQuery,
      setIsLoading,
      notification,
      setDownloadButton,
      setIsNotFound
    );
    setFilterSubtitleOnConfirm(filterSubtitle);
    localStorage.setItem('filterSubtitle', filterSubtitle);
    getExtraTransactionInfo(filter);
  };

  return (
    <div>
      <InformationTableModal
        title={'Transactions Report'}
        headers={dataTransactionHeaders}
        tableData={extraTransactionInfo}
        show={showTransactionReportModal}
        handleClose={() => setShowTransactionReportModal(false)}
      />
      {showStorageCenterModal ? (
        <TableModal
          url={urlStorageCenter}
          title="Storage Centers"
          show={showStorageCenterModal}
          handleClose={closeModal}
          formName={'storageCenter'}
          columnToTransform={[]}
        />
      ) : null}
      {showStorageLocationModal ? (
        <TableModal
          url={urlStorageLocation}
          title="Storage Locations"
          show={showStorageLocationModal}
          handleClose={closeModal}
          formName={'storageLocation'}
          columnToTransform={storageColumnToTransform}
        />
      ) : null}
      {showDimensionalUnitModal ? (
        <TableModal
          url={urlDimensionalUnit}
          title="Dimensional Units"
          show={showDimensionalUnitModal}
          handleClose={closeModal}
          formName={'dimensionalUnit'}
          columnToTransform={[]}
        />
      ) : null}
      {showEntryOrderModal ? (
        <TableModal
          url={urlEntryOrder}
          title="Entry Orders"
          show={showEntryOrderModal}
          handleClose={closeModal}
          formName={'entryOrder'}
          columnToTransform={entryOrderColumnToTransform}
        />
      ) : null}
      {showDispatchOrderModal ? (
        <TableModal
          url={urlDispatchOrder}
          title="Dispatch Orders"
          show={showDispatchOrderModal}
          handleClose={closeModal}
          formName={'dispatchOrder'}
          columnToTransform={dispatchOrderColumnToTransform}
        />
      ) : null}
      {showAdjustmentCodeModal ? (
        <TableModal
          url={urlAdjustmentCode}
          title="Adjustment Codes"
          show={showAdjustmentCodeModal}
          handleClose={closeModal}
          formName={'adjustmentCode'}
          columnToTransform={[]}
        />
      ) : null}
      {showItemCodeModal ? (
        <TableModal
          url={urlItemCode}
          title="Item Codes"
          show={showItemCodeModal}
          handleClose={closeModal}
          formName={'item'}
          columnToTransform={itemCodeColumnToTransform}
        />
      ) : null}
      {showBatchCodeModal ? (
        <TableModal
          url={urlBatchCode}
          title="Batch Codes"
          show={showBatchCodeModal}
          handleClose={closeModal}
          formName={'batch'}
          columnToTransform={[]}
        />
      ) : null}
      {showSupplierModal ? (
        <TableModal
          url={urlSupplier}
          title="Suppliers"
          show={showSupplierModal}
          handleClose={closeModal}
          formName={'supplier'}
          columnToTransform={[]}
        />
      ) : null}
      {showCustomerModal ? (
        <TableModal
          url={urlCustomer}
          title="Customers"
          show={showCustomerModal}
          handleClose={closeModal}
          formName={'customer'}
          columnToTransform={[]}
        />
      ) : null}
      <HeaderTitle title="Advanced Filters" />
      <Form>
        <Row>
          <Col>
            <TransactionCodeSelection
              className="mb-4"
              value={selectedTransactionCode}
              onChange={onChangeTransactionCode}
            />
          </Col>
          <Col>
            <TransactionTypeSelection
              className="mb-3"
              value={selectedTransactionType}
              onChange={onChangeTransactionType}
            />
          </Col>
          <Col>
            <TransactionSubTypeSelection
              className="mb-3"
              value={selectedTransactionSubType}
              onChange={onChangeTransactionSubType}
            />
          </Col>
          <Col>
            <Form.Label>Amount</Form.Label>
            <InputGroup>
              <DropdownButton
                variant={comparisonOptionSelected ? 'secondary' : 'outline-secondary'}
                title={
                  typeof comparisonOptionSelected !== 'undefined'
                    ? comparisonOptionSelected.value
                    : '='
                }
                id="amount-input-dropdown"
              >
                {amountFilters.map((item, idx) => (
                  <Dropdown.Item
                    key={idx}
                    name={item.label}
                    href="#"
                    onClick={() => {
                      setComparisonOptionSelected(item);
                    }}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Form.Control
                placeholder="Amount"
                className="mb-3"
                value={amountSelected}
                onChange={(e) => {
                  e ? setAmountSelected(e.target.value) : setAmountSelected('');
                }}
              />
              <ClearableButton onClick={onClickRemoveAmount} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <UserEmailSelection
              classname="mb-3"
              value={selectedTransactionUser}
              onChange={onChangeUser}
            />
          </Col>
          <Col>
            <Form.Label>Return Transaction</Form.Label>
            <Select
              className="mb-3"
              isClearable={true}
              options={returnTransactionOptions}
              value={selectedReturnTransaction}
              onChange={(e) => {
                setSelectedReturnTransaction(e);
                if (e) {
                  setSelectedFilters({ ...selectedFilters, return_transaction: e.value });
                  setSelectedDescriptions({ ...selectedDescriptions, return_transaction: e.value });
                } else {
                  setSelectedFilters({ ...selectedFilters, return_transaction: '' });
                  setSelectedDescriptions({ ...selectedDescriptions, return_transaction: '' });
                }
              }}
            />
          </Col>
          <Col>
            <Form.Label>Return Approved</Form.Label>
            <Select
              className="mb-3"
              isClearable={true}
              options={returnApprovedOptions}
              value={selectedReturnAproved}
              onChange={(e) => onChangeReturnApproved(e)}
            />
          </Col>
          <Col>
            <UserEmailSelection
              label="Return Approval User"
              classname="mb-3"
              value={selectedApprovalUser}
              onChange={onChangeApprovalUser}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>
              Storage Center
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowStorageCenterModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              key={JSON.stringify(selectedStorageLocation)}
              className="mb-1"
              isClearable={true}
              isDisabled={disableStorageCenter}
              defaultOptions
              value={selectedStorageCenter}
              loadOptions={getUpdatedStorageCenterCode}
              onChange={(e) => {
                if (e) {
                  setSelectedStorageCenter(e);
                  setSelectedFilters({ ...selectedFilters, storage_center_code__id: e.value });
                  setStorageCenterDescription(e.description);
                  setSelectedDescriptions({
                    ...selectedDescriptions,
                    storage_center_code: e.label,
                  });
                } else {
                  setSelectedStorageCenter({ value: '', label: 'Select...' });
                  setSelectedFilters({ ...selectedFilters, storage_center_code__id: '' });
                  setStorageCenterDescription('');
                  setSelectedDescriptions({ ...selectedDescriptions, storage_center_code: '' });
                }
              }}
              additional={{
                page: 1,
              }}
            />
            <FormLabel className="description-filters">{storageCenterDescription}</FormLabel>
          </Col>
          <Col>
            <Form.Label>
              Storage Location
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowStorageLocationModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              key={JSON.stringify(selectedStorageCenter)}
              className="mb-1"
              isClearable={true}
              defaultOptions
              cacheOptions={false}
              value={selectedStorageLocation}
              loadOptions={getUpdatedStorageLocation}
              onChange={(e) => onChangeStorageLocation(e)}
              additional={{
                page: 1,
              }}
            />
            <FormLabel className="description-filters">{storageLocationDescription}</FormLabel>
          </Col>
          <Col>
            <Form.Label>
              Storage Center Destination
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowStorageCenterModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              className="mb-1"
              isClearable={true}
              isDisabled={disableStorageCenterDestination}
              defaultOptions
              value={selectedStorageCenterDestination}
              loadOptions={getUpdatedStorageCenterDestinationCode}
              onChange={(e) => onChangeStorageCenterDestination(e)}
              additional={{
                page: 1,
              }}
            />
            <FormLabel className="description-filters">
              {storageCenterDestinationDescription}
            </FormLabel>
          </Col>
          <Col>
            <Form.Label>
              Storage Location Destination
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowStorageLocationModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              key={JSON.stringify(selectedStorageCenterDestination)}
              className="mb-1"
              isClearable={true}
              defaultOptions
              value={selectedStorageLocationDestination}
              loadOptions={getUpdatedStorageLocationDestination}
              onChange={(e) => onChangeStorageLocationDestination(e)}
              additional={{
                page: 1,
              }}
            />
            <FormLabel className="description-filters">
              {storageLocationDestinationDescription}
            </FormLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>
              Dimensional Unit
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowDimensionalUnitModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              className="mb-1"
              isClearable={true}
              value={selectedDimensionalUnit}
              loadOptions={getUpdatedDimensionalUnit}
              onChange={(e) => {
                setSelectedDimensionalUnit(e);
                if (e) {
                  setSelectedFilters({ ...selectedFilters, dimensional_unit_code__id: e.value });
                  setDimensionalUnitDescription(e.description);
                  setSelectedDescriptions({ ...selectedDescriptions, dimensional_unit: e.label });
                } else {
                  setSelectedFilters({ ...selectedFilters, dimensional_unit_code__id: '' });
                  setDimensionalUnitDescription('');
                  setSelectedDescriptions({ ...selectedDescriptions, dimensional_unit: '' });
                }
              }}
              additional={{
                page: 1,
              }}
            />
            <FormLabel className="description-filters">{dimensionalUnitDescription}</FormLabel>
          </Col>
          <Col>
            <Form.Label>
              Entry Order
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowEntryOrderModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              className="mb-3"
              isClearable={true}
              defaultOptions
              value={selectedEntryOrder}
              loadOptions={getUpdatedEntryOrder}
              onChange={(e) => onChangeEntryOrder(e)}
              additional={{
                page: 1,
              }}
            />
          </Col>
          <Col>
            <Form.Label>
              Dispatch Order
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowDispatchOrderModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              className="mb-3"
              isClearable={true}
              defaultOptions
              value={selectedDispatchOrder}
              loadOptions={getUpdatedDispatchOrder}
              onChange={(e) => onChangeDispatchOrder(e)}
              additional={{
                page: 1,
              }}
            />
          </Col>
          <Col>
            <Form.Label>
              Adjustment Code
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowAdjustmentCodeModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              className="mb-1"
              isClearable={true}
              defaultOptions
              value={selectedAdjustmentCode}
              loadOptions={getUpdatedAdjustmentCode}
              onChange={(e) => onChangeAdjustmentCode(e)}
              additional={{
                page: 1,
              }}
            />
            <FormLabel className="description-filters">{adjustmentCodeDescription}</FormLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>
              Item Code
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowItemCodeModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              className="mb-1"
              isClearable={true}
              defaultOptions
              value={selectedItemCode}
              loadOptions={getUpdatedItemCode}
              onChange={(e) => {
                if (e) {
                  setSelectedItemCode(e);
                  setSelectedFilters({ ...selectedFilters, item_code: e.label });
                  setSelectedDescriptions({ ...selectedDescriptions, item_code: e.label });
                  setItemCodeDescription(e.description);
                } else {
                  setSelectedItemCode({ value: '', label: 'Selected...' });
                  setSelectedFilters({ ...selectedFilters, item_code: '' });
                  setSelectedDescriptions({ ...selectedDescriptions, item_code: '' });
                  setItemCodeDescription('');
                }
              }}
              additional={{
                page: 1,
              }}
            />
            <FormLabel className="description-filters">{itemCodeDescription}</FormLabel>
          </Col>
          <Col>
            <Form.Label>
              Batch Code
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowBatchCodeModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              key={JSON.stringify(selectedItemCode)}
              className="mb-3"
              isClearable={true}
              defaultOptions
              value={selectedBatchCode}
              loadOptions={getUpdatedBatchCode}
              onChange={(e) => {
                setSelectedBatchCode(e);
                if (e) {
                  setSelectedFilters({ ...selectedFilters, batch_code: e.label });
                  setSelectedDescriptions({ ...selectedDescriptions, batch_code: e.label });
                } else {
                  setSelectedFilters({ ...selectedFilters, batch_code: '' });
                  setSelectedDescriptions({ ...selectedDescriptions, batch_code: '' });
                }
              }}
              additional={{
                page: 1,
              }}
            />
          </Col>
          <Col>
            <Form.Label>
              Supplier
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowSupplierModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              className="mb-3"
              isClearable={true}
              defaultOptions
              value={selectedSupplier}
              loadOptions={getUpdatedSupplier}
              onChange={(e) => onChangeSupplier(e)}
              additional={{
                page: 1,
              }}
            />
          </Col>
          <Col>
            <Form.Label>
              Customer
              <FaTable
                className="icon-search ml-2"
                onClick={() => {
                  setShowCustomerModal(true);
                  setIsModalPagination();
                }}
              />
            </Form.Label>
            <AsyncPaginate
              className="mb-3"
              isClearable={true}
              defaultOptions
              value={selectedClient}
              loadOptions={getUpdatedClient}
              onChange={(e) => onChangeCustomer(e)}
              additional={{
                page: 1,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Label>Date Filters</Form.Label>
            <InputGroup>
              <DropdownButton
                variant={dateOptionSelected ? 'secondary' : 'outline-secondary'}
                title={typeof dateOptionSelected !== 'undefined' ? dateOptionSelected.value : 'day'}
                id="amount-input-dropdown"
              >
                {dateFiltersOptions.map((item, idx) => (
                  <Dropdown.Item
                    key={idx}
                    name={item.label}
                    href="#"
                    onClick={() => onClickDateFilter(item)}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <RangeDateInput
                dateOptionSelected={dateOptionSelected}
                dateFilters={dateFilters}
                setDateFilters={setDateFilters}
                disabled={!dateOptionSelected}
              />
            </InputGroup>
          </Col>
          <Col>
            <ChildCategorySelection
              className="mb-1"
              value={selectedItemCategory}
              onChange={onChangeItemCategory}
            />
            <FormLabel className="description-filters">{itemCategoryDescription}</FormLabel>
          </Col>
          <Col>
            <ParentCategorySelection
              className="mb-1"
              value={selectedParentItemCategory}
              onChange={onChangeItemParentCategory}
            />
            <FormLabel className="description-filters">{itemParentCategoryDescription}</FormLabel>
          </Col>
        </Row>
        <Button className="fpb-btn mb-2 mr-2" onClick={handleFiltersSubmit}>
          Apply Filters
        </Button>
        <Button
          className="fpb-btn-inverse mb-2 mr-2"
          onClick={handleDownload}
          disabled={downloadButton}
        >
          Download (.csv)
        </Button>
        <Button
          className="fpb-btn-inverse mb-2 mr-2"
          onClick={() => {
            setShowTransactionReportModal(true);
          }}
          disabled={downloadButton}
        >
          Transaction Report
        </Button>
        <Link to="/indicators/data_analysis">
          <Button className="fpb-btn-inverse mb-2 mr-2 float-right" disabled={downloadButton}>
            Data Analysis
            <FaArrowCircleRight className="ml-2" />
          </Button>
        </Link>
      </Form>
      <FilterSubtitle />
      {isNotFound ? (
        <div className="text-center mt-5">
          <h2>There are no results that match the search criteria.</h2>
        </div>
      ) : (
        <FPBTable
          readOnly
          headers={headers}
          tableData={tableData}
          handleEdit={handleEdit}
          addedRow={addedRow}
          customColumnOrder={[]}
          columnsToHide={columnsToHide}
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setFilters={setFilters}
        />
      )}
    </div>
  );
};

export default AdvancedFilters;
