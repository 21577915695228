import { Spinner } from 'react-bootstrap';

const IoTVariables = ({ workCenterConfiguration, data, error, loading, label }) => {
  return (
    <div className="mb-2">
      <div className="label" style={{ marginTop: 0 }}>
        {label}
      </div>
      <div style={{ borderRadius: '5px', border: '1px solid #ced4da', padding: '6px 10px' }}>
        {workCenterConfiguration?.map((configuration, index) => {
          if (configuration?.data_source === 'iot') {
            return (
              <div>
                {`${configuration?.variable_description} (${configuration?.entry_dimensional_unit}): `}
                {error && "Couldn't fetch variables from IoT"}
                {!loading ? (
                  `${data[configuration.variable] || '0'}`
                ) : (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="fpb-spinner-sm"
                  ></Spinner>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default IoTVariables;
