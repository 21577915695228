import { Container, Row, Col } from 'react-bootstrap';

const LockedMessage = () => (
  <Container>
    <Row className="justify-content-center align-items-center mt-5">
      <Col lg={5}>
        <div className="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-4 h-4"
          >
            <path
              fillRule="evenodd"
              d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
              clipRule="evenodd"
            />
          </svg>
          <h1 className="mt-4">Not Authorized</h1>
          <p>
            Sorry, you are not authorized to view this page. Please ask your administrator for
            authorization.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);

export default LockedMessage;
