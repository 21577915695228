import React, { useEffect, useState } from 'react';
import { Col, Form, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import * as FaIcons from 'react-icons/fa';
import { ErrorMessage, useField } from 'formik';
import { setIsModalPaginationAction } from '../../../redux/actions/paginationAction';
import { useDispatch } from 'react-redux';

const CustomSelectDefault = ({
  onChange,
  openModal,
  options,
  value,
  setShow,
  label,
  clear,
  defaultValue,
  description,
  showIcon,
  ...props
}) => {
  const [field] = useField(props);
  const [selectedOption, setSelectedOption] = useState('');
  // pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };

  useEffect(() => {
    setSelectedOption('');
  }, [clear]);

  return (
    <Form.Group className="mb-2" as={Col}>
      <FormLabel className="label">
        {label}
        {showIcon ? (
          <FaIcons.FaTable
            className="icon-search ml-2"
            onClick={() => {
              setShow(true);
              setIsModalPagination();
              openModal ? openModal() : null;
            }}
          />
        ) : null}
      </FormLabel>

      <Select
        isDisabled={props.disabled ? props.disabled : false}
        defaultValue={defaultValue}
        options={options}
        value={selectedOption}
        onChange={(value) => {
          onChange(value);
          setSelectedOption(value);
        }}
        isClearable
        openMenuOnClick={false}
        style={{ height: '100%' }}
      />
      <ErrorMessage component="div" name={field.name} className="fpb-error" />
      <FormLabel className="description-select">{description}</FormLabel>
    </Form.Group>
  );
};
CustomSelectDefault.defaultProps = {
  showIcon: true,
};

export default CustomSelectDefault;
