import React, { useState, useEffect } from 'react';
import { Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { GiCancel } from 'react-icons/gi';
import { HiExternalLink } from 'react-icons/hi';
import { IoMdHelpCircle } from 'react-icons/io';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';
import Urls from '../Urls';
import 'react-toastify/dist/ReactToastify.css';
import uploadFiles from '../../utils/uploadFiles';
import { notification } from '../AlertMessage/ToastifyAlert';

const Quality = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setError,
  setAddForm,
  onEdited,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [document, setDocument] = useState(values.evidence_file || '');
  const [disabledDataSource, setDisabledDataSource] = useState(false);
  const [documentName, setDocumentName] = useState(
    values.evidence_file ? values.evidence_file.split('/')[5] : ''
  );
  const [documentLoading, setDocumentLoading] = useState(false);

  const [descriptions, setDescriptions] = useState({
    workCenterDescription: '',
    productDescription: '',
    dimensionalUnitDescription: '',
    qualityDimensionDescription: '',
  });
  const [workCenterData, setWorkCenterData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [productData, setProductData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [dimensionalUnitData, setDimensionalUnitData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [qualityDimensionData, setQualityDimensionData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const accessToken = localStorage.getItem('accessToken');

  const qualityValidate = Yup.object({
    startHour: Yup.string().required('Required'),
    endHour: Yup.string().required('Required'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(async () => {
    //Validation for iot data source
    if (values.quality_data_source === 'iot') {
      setDisabledDataSource(true);
    }
    try {
      const resWorkCenter = await apiService.getData(Urls.workCenter, 'code');
      const resProduct = await apiService.getData(Urls.product, 'code');
      const resDimensionalUnit = await apiService.getData(Urls.dimensionalUnitA, 'code');
      const resQualityDimension = await apiService.getData(Urls.qualityDimension, 'code');

      setWorkCenterData({
        headers: resWorkCenter.headersList,
        tableData: resWorkCenter.data,
        options: resWorkCenter.results,
      });
      setProductData({
        headers: resProduct.headersList,
        tableData: resProduct.data,
        options: resProduct.results,
      });
      setDimensionalUnitData({
        headers: resDimensionalUnit.headersList,
        tableData: resDimensionalUnit.data,
        options: resDimensionalUnit.results,
      });
      setQualityDimensionData({
        headers: resQualityDimension.headersList,
        tableData: resQualityDimension.data,
        options: resQualityDimension.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    workCenterData?.options.forEach((option) => {
      if (option.value === values.work_center) {
        setDescriptions(
          option
            ? { ...descriptions, workCenterDescription: option.description }
            : { ...descriptions, workCenterDescription: '' }
        );
      }
    });
  }, [workCenterData]);

  useEffect(() => {
    productData?.options.forEach((option) => {
      if (option.value === values.product) {
        setDescriptions(
          option
            ? { ...descriptions, productDescription: option.description }
            : { ...descriptions, productDescription: '' }
        );
      }
    });
  }, [productData]);

  useEffect(() => {
    dimensionalUnitData?.options.forEach((option) => {
      if (option.value === values.dimensional_unit) {
        setDescriptions(
          option
            ? { ...descriptions, dimensionalUnitDescription: option.description }
            : { ...descriptions, dimensionalUnitDescription: '' }
        );
      }
    });
  }, [dimensionalUnitData]);

  useEffect(() => {
    qualityDimensionData?.options.forEach((option) => {
      if (option.value === values.quality_dimension) {
        setDescriptions(
          option
            ? { ...descriptions, qualityDimensionDescription: option.description }
            : { ...descriptions, qualityDimensionDescription: '' }
        );
      }
    });
  }, [qualityDimensionData]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      File Maximum Size: 10MB
    </Tooltip>
  );

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={qualityValidate}
        onSubmit={async (data) => {
          const processedValues = {
            ...values,
            start_hour: data.startHour,
            end_hour: data.endHour,
            quantity_nonconforming: data.quantityNonconforming || 0,
            evidence_file: document,
            comments: data.comments,
            work_center: data.workCenter,
            shift: data.shift || null,
            production_order: data.productionOrder,
            product: data.product,
            dimensional_unit: data.dimensionalUnit,
            quality_dimension: data.qualityDimension,
            reported_quantity: data.reportedQuantity || 0,
          };
          setProcessedFormValues(processedValues);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues);
          } else {
            if (data.quantityNonconforming <= data.reportedQuantity) {
              const updated = await handleAPIRequestUpdate(data.id, processedValues);
              if (updated.status === 200) {
                setAddForm(false);
                onEdited(processedValues);
              }
            } else {
              notification(
                'The nonconforming quantity cannot be bigger than the reported quantity.',
                'error'
              );
            }
          }
        }}
        initialValues={{
          id: values.id,
          startHour: values.start_date,
          endHour: values.end_date,
          quantityNonconforming: values.quantity_nonconforming || 0,
          evidenceFile: document,
          comments: values.comments,
          workCenter: values.work_center,
          shift: values.shift,
          productionOrder: values.production_order,
          product: values.product,
          dimensionalUnit: values.dimensional_unit,
          qualityDimension: values.quality_dimension,
          reportedQuantity: values.reported_quantity || 0,
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {onEdited ? (
              <>
                <Form.Row className="mb-3">
                  <TextField
                    disabled
                    label="Start hour"
                    name="startHour"
                    type="datetime-local"
                    value={(values.startHour || '').toString().substring(0, 16)}
                  />
                  <TextField
                    disabled
                    label="End hour"
                    name="endHour"
                    type="datetime-local"
                    value={(values.endHour || '').toString().substring(0, 16)}
                  />
                </Form.Row>
                <Form.Row>
                  <CustomSelect
                    disabled
                    onChange={(option) => {
                      setFieldValue('workCenter', option ? option.value : '');
                      setDescriptions(
                        option
                          ? { ...descriptions, workCenterDescription: option.description }
                          : { ...descriptions, workCenterDescription: '' }
                      );
                      setWorkCenter(option.value || '');
                    }}
                    label="Work Center"
                    name="workCenter"
                    options={workCenterData.options}
                    defaultValue={{
                      value: values.workCenter,
                      label: values.workCenter,
                    }}
                    description={descriptions.workCenterDescription}
                  />
                  <TextField disabled label="Shift" name="shift" type="text" value={values.shift} />
                </Form.Row>
                <Form.Row>
                  <TextField
                    disabled
                    label="Production order"
                    name="code"
                    type="string"
                    value={values.productionOrder}
                  />
                  <CustomSelect
                    disabled
                    onChange={(option) => {
                      setFieldValue('product', option ? option.value : '');
                      setDescriptions(
                        option
                          ? { ...descriptions, productDescription: option.description }
                          : { ...descriptions, productDescription: '' }
                      );
                    }}
                    label="Product"
                    name="product"
                    options={productData.options}
                    defaultValue={{
                      value: values.product,
                      label: values.product,
                    }}
                    description={descriptions.productDescription}
                  />
                </Form.Row>
                <Form.Row>
                  <TextField
                    disabled
                    label="Reported quantity"
                    name="reportedQuantity"
                    type="number"
                    step="any"
                    value={values.reportedQuantity}
                  />
                  <TextField
                    disabled
                    label="Dimensional Unit"
                    name="dimensionalUnit"
                    value={values.dimensionalUnit}
                    description={descriptions.dimensionalUnitDescription}
                  />
                </Form.Row>
              </>
            ) : null}
            <Form.Row>
              <TextField
                disabled={disabledDataSource}
                label={`Quantity nonconforming (${values.dimensionalUnit})`}
                name="quantityNonconforming"
                type="number"
                step="any"
                value={values.quantityNonconforming}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('qualityDimension', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, qualityDimensionDescription: option.description }
                      : { ...descriptions, qualityDimensionDescription: '' }
                  );
                }}
                label="Quality dimension"
                name="qualityDimension"
                options={qualityDimensionData.options}
                defaultValue={{
                  value: values.qualityDimension,
                  label: values.qualityDimension,
                }}
                description={descriptions.qualityDimensionDescription}
              />
            </Form.Row>
            <Form.Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label className="label">
                  Select Evidence File
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <IoMdHelpCircle />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(file) =>
                    uploadFiles(
                      file,
                      setDocument,
                      setDocumentName,
                      setDocumentLoading,
                      notification,
                      setError,
                      accessToken
                    )
                  }
                  accept=".docx,.pdf,.odt,.txt"
                  size="sm"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <TextField
                label="Attached Document"
                name="evidenceFile"
                type="text"
                value={documentName}
                readOnly
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    evidenceFile: e.target.value || '',
                  });
                }}
              />
              {documentLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                ></Spinner>
              ) : (
                <GiCancel
                  size={23}
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                  onClick={() => {
                    setDocument('');
                    setDocumentName('');
                  }}
                />
              )}
              {documentName !== '' ? (
                <a target="_blank" href={document} rel="noreferrer">
                  <HiExternalLink
                    size={26}
                    style={{
                      'margin-top': '2.5rem',
                      'margin-left': '0.5rem',
                    }}
                  />
                </a>
              ) : null}
            </Form.Row>
            <Form.Row>
              <TextField label="Comments" name="comments" type="text" value={values.comments} />
            </Form.Row>
            {onEdited ? (
              <>
                <Button className="fpb-btn mt-3" type="submit">
                  Confirm
                </Button>
                <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
                  Close
                </Button>
              </>
            ) : null}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Quality;
