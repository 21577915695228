import { createSlice } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const producedUnitsSlice = createSlice({
  name: 'producedUnits',
  initialState: {
    data: {},
    dataConsolidated: {},
    progress: { pending: true, current: 0, total: 0, percent: 0 },
    progressConsolidated: {},
    status: 'idle',
    selectedWorkCenter: {},
    filters: {},
  },
  reducers: {
    getProducedUnits: (state, action) => {
      state.data = action.payload;
    },
    getProducedUnitsConsolidated: (state, action) => {
      state.dataConsolidated = action.payload;
    },
    setDataProducedUnits: (state, action) => {
      state.data = action.payload;
    },
    setProgressProducedUnits: (state, action) => {
      if (action.payload.current >= state.progress?.current) {
        state.progress = action.payload;
      } else {
        state.progress = state.progress;
      }
    },
    setStatusProducedUnits: (state, action) => {
      state.status = action.payload;
      if (action.payload === 'SUCCESS') {
        state.progress = { ...state.progress, current: 0, percent: 100 };
      }
    },
    setSelectedWorkCenter: (state, action) => {
      state.selectedWorkCenter = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const getProducedUnitsAsync =
  (startDate, endDate, temporality, workCenter, shift, product, productionOrder) =>
  async (dispatch) => {
    try {
      const response = await apiService.getProducedUnitsByFilters(
        startDate,
        endDate,
        temporality,
        workCenter,
        shift,
        product,
        productionOrder
      );
      dispatch(getProducedUnits(response.data));
    } catch (err) {
      console.log(err);
    }
  };

export const getProducedUnitsConsolidatedAsync =
  (startDate, endDate, temporality, workCenterList) => async (dispatch) => {
    try {
      let results = [];
      for (let currentWorkCenter of workCenterList) {
        const res = await apiService.getProducedUnitsByFilters(
          startDate,
          endDate,
          temporality,
          currentWorkCenter,
          '',
          '',
          ''
        );
        if (Object.keys(res.data.total_produced_units).length !== 0) {
          results.push(res.data.total_produced_units);
        } else {
          results.push(null);
        }
      }
      dispatch(getProducedUnitsConsolidated(results));
    } catch (err) {
      console.log(err);
    }
  };

export const {
  getProducedUnits,
  setDataProducedUnits,
  setProgressProducedUnits,
  setStatusProducedUnits,
  setSelectedWorkCenter,
  setFilters,
} = producedUnitsSlice.actions;
export const showProducedUnits = (state) => state.producedUnits.data;

export const { getProducedUnitsConsolidated } = producedUnitsSlice.actions;
export const showProducedUnitsConsolidated = (state) => state.producedUnits.dataConsolidated;

export default producedUnitsSlice.reducer;
