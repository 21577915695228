import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as RiIcons from 'react-icons/ri';
import { Formik } from 'formik';
import FPBTable from '../FPBTable/FPBTable';
import Urls from '../Urls';
import axios from 'axios';
import { setTotalCountAction } from '../../redux/actions/paginationAction';
import { setFilterList } from '../../redux/slices/indicatorFilterSlice';
import { setSelectedWorkCenterData } from '../../redux/slices/indicatorFilterSlice';
import { setFromDate, setToDate } from '../../redux/slices/iotProcessControlSlice';
import { setHistoricValue } from '../../redux/slices/historicalSlice';
import { DateRange } from 'react-date-range';
import subDays from 'date-fns/subDays';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const FiltersIOTControl = () => {
  const [showFilters, setShowFilters] = useState(true);
  const [showHistoric, setShowHistoric] = useState(false);
  const url = Urls.workCenterPerProcessFilter;
  const [token, setToken] = useState(localStorage.getItem('accessToken') || null);
  const [headers, setHeaders] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [selected, setSelected] = useState(true);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const dispatchTotalCount = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatchTotalCount(setTotalCountAction(totalCount));
  };

  // dates
  const now = useRef(new Date());
  const [to, setTo] = useState(subDays(now.current.setHours(0, 0, 0, 0), 1));
  const [from, setFrom] = useState(subDays(now.current, 7));
  const [isEndDateInput, setIsEndDateInput] = useState();
  const [isStartDateInput, setIsStartDateInput] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [newDateRange, setNewDateRange] = useState(false);

  //redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFromDate(from.toISOString().split('T')[0]));
    dispatch(setToDate(to.toISOString().split('T')[0]));
  }, [dispatch]);

  useEffect(() => {
    if (selectedTransactions.length !== 0) {
      const selectedData = tableData.filter((row) => {
        if (selectedTransactions.includes(row.id)) return row;
      });
      dispatch(setSelectedWorkCenterData(selectedData));
    } else {
      dispatch(setSelectedWorkCenterData([]));
    }
  }, [selectedTransactions]);

  // pagination
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  const totalCount = useSelector((state) => state.pagination.totalCount);

  const handleSelectedTran = (selected) => {
    setSelectedTransactions(selected);
  };

  const getQueryString = () =>
    `${url}?page=${currentPage + 1}&page_size=${pageSize}&data_source=iot`;

  const getTableData = (queryString) => {
    axios
      .get(queryString(), {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      })
      .then(async (res) => {
        const { results } = res.data;
        setTotalCount(res.data.count);
        if (results.length > 0) {
          // discard the fields the user will not visualize in tables
          const headersList = Object.keys(results[0]).filter((elem) => {
            let returnColumn;
            if (elem === 'id') {
              returnColumn = false;
            } else {
              returnColumn = true;
            }
            return returnColumn;
          });
          setHeaders(headersList);
          setTableData(results);
        } else {
          setTableData([]);
          setHeaders([]);
        }
      })
      .catch((err) => {
        console.log('error: ', err);
      });
  };

  useEffect(() => {
    getTableData(getQueryString);
  }, [url, token, pageSize, currentPage]);

  useEffect(() => {
    newDateRange ? setNewDateRange(false) : null;
  }, [newDateRange]);

  const handleSelect = useCallback(({ selection: { startDate, endDate } }) => {
    dispatch(setFromDate(startDate.toISOString().split('T')[0]));
    dispatch(setToDate(endDate.toISOString().split('T')[0]));
    setFrom(startDate);
    setTo(endDate);
    setShowCalendar(false);
  });

  const ranges = useMemo(
    () => [
      {
        startDate: from,
        endDate: to,
        key: 'selection',
      },
    ],
    [from, to]
  );

  const handleToggle = () => {
    dispatch(setHistoricValue(!showHistoric));
    setShowHistoric(!showHistoric);
  };

  useEffect(() => {
    showHistoric ? (setFrom(subDays(now.current, 7)), setTo(subDays(now.current, 1))) : null;
  }, [showHistoric]);

  useEffect(() => {
    if (showHistoric) {
      const filters = {
        from: from.toDateString(),
        to: to.toDateString(),
        temporality: 'historic',
      };
      dispatch(setFilterList(filters));
    } else {
      const filters = {
        from: now.current.toDateString(),
      };
      dispatch(setFilterList(filters));
    }
  }, [from]);

  return (
    <>
      <Formik validateOnChange={true}>
        <Form>
          <Form.Row className="ml-2">
            <div className="mr-4">
              <>
                <label
                  className="switch-toggle"
                  htmlFor={`switch-new`}
                  style={{ background: showHistoric ? '#ffae16' : '#6cffa7' }}
                >
                  <input
                    checked={showHistoric}
                    onChange={handleToggle}
                    className="switch-checkbox"
                    id="switch-new"
                    type="checkbox"
                  />
                  <span className="switch-slider" />
                  <span className="switch-labels" data-off="DAILY" data-on="HISTORIC" />
                </label>
              </>
            </div>
            {!showHistoric && (
              <div className="col-md-3 pt-3">
                <h5> {now.current.toDateString()} </h5>
              </div>
            )}
          </Form.Row>
          {showFilters && showHistoric && (
            <>
              <Form.Row>
                <div
                  className="row mx-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  {showCalendar && (
                    <Button
                      className="dark-button mx-2"
                      onClick={() => {
                        setShowCalendar(false);
                      }}
                    >
                      <RiIcons.RiEyeOffFill />
                    </Button>
                  )}
                  <div className="col-md-5">
                    <div
                      className="input-group"
                      onClick={() => {
                        setShowCalendar(true);
                      }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          Start
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type={'day'}
                        value={from.toISOString().split('T', 1)[0]}
                        onClick={() => {
                          setIsEndDateInput();
                          setIsStartDateInput(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="input-group"
                      onClick={() => {
                        setShowCalendar(true);
                      }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          End
                        </span>
                      </div>
                      <input
                        className="form-control"
                        type={'day'}
                        value={to.toISOString().split('T', 1)[0]}
                        max={now.current}
                        onClick={() => {
                          setIsEndDateInput(true);
                          setIsStartDateInput();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Form.Row>
              {showCalendar && (
                <Form.Row>
                  <div className="mx-auto">
                    <DateRange
                      ranges={ranges}
                      onChange={handleSelect}
                      showSelectionPreview
                      moveRangeOnFirstSelection={false}
                      months={2}
                      direction="horizontal"
                      calendarFocus="forwards"
                      maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                      retainEndDateOnFirstSelection
                      {...{
                        focusedRange: isEndDateInput ? [0, 1] : isStartDateInput ? [0, 0] : {},
                      }}
                    />
                  </div>
                </Form.Row>
              )}
            </>
          )}
          {showFilters && (
            <>
              <div className="pr-4 mb-4 mt-4">
                {!showHistoric && (
                  <FPBTable
                    readOnly
                    withCheckBox
                    headers={headers}
                    tableData={tableData}
                    setSelected={setSelected}
                    handleSelectedTran={handleSelectedTran}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                  />
                )}
                {showHistoric && newDateRange && (
                  <FPBTable
                    readOnly
                    withCheckBox
                    headers={headers}
                    tableData={tableData}
                    setSelected={setSelected}
                    handleSelectedTran={handleSelectedTran}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                  />
                )}
                {showHistoric && !newDateRange && (
                  <FPBTable
                    readOnly
                    withCheckBox
                    headers={headers}
                    tableData={tableData}
                    setSelected={setSelected}
                    handleSelectedTran={handleSelectedTran}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                  />
                )}
              </div>
            </>
          )}
          <Form.Row>
            <div className="mb-5">
              <Button
                className="dark-button mx-2"
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                {showFilters ? 'Hide filters ' : 'Show filters '}
                {showFilters ? <RiIcons.RiEyeOffFill /> : <RiIcons.RiEyeFill />}
              </Button>
            </div>
          </Form.Row>
        </Form>
      </Formik>
    </>
  );
};

export default FiltersIOTControl;
