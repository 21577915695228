import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { Card, Row } from 'react-bootstrap';
import { showAvailability } from '../../../redux/slices/availabilitySlice';
import { showPerformance } from '../../../redux/slices/performanceSlice';
import { showQuality } from '../../../redux/slices/qualitySlice';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';
import { showFilterList } from '../../../redux/slices/indicatorFilterSlice';

const OEETrendChart = () => {
  //redux
  const availabilityData = useSelector(showAvailability);
  const performanceData = useSelector((state) => state.performance.dataFromOee);
  const qualityData = useSelector(showQuality);
  const filters = useSelector((state) => state.oee.filters);
  const { workCenter, product, productionOrder, shift, from, to, temporality } = filters;
  const [subtitle, setSubtitle] = useState('');
  const indicatorName = 'OEE';

  const [availabilityArray, setAvailabilityArray] = useState([]);
  const [performanceArray, setPerformanceArray] = useState([]);
  const [qualityArray, setQualityArray] = useState([]);
  const [oeeTrendArray, setOeeTrendArray] = useState([]);
  const [dates, setDates] = useState([]);
  const [excelTableData, setExcelTableData] = useState([]);
  const [showTableData, setShowTableData] = useState(false);
  const [paginationCount, setPaginationCount] = useState(0);
  const filterList = useSelector(showFilterList);

  useEffect(() => {
    const subtitle = `Filtered ${workCenter ? 'by work center ' + workCenter + ', ' : ''}${
      product ? 'product ' + product + ', ' : ''
    }${productionOrder ? 'production order ' + productionOrder + ', ' : ''}${
      shift ? 'shift ' + shift + ', ' : ''
    } from ${from} to ${to} ${'(' + temporality + ')'}.`;
    setSubtitle(subtitle);
  }, [filters]);

  useEffect(() => {
    setAvailabilityArray([]);
    setDates([]);
    if (availabilityData && Object.keys(availabilityData).length !== 0) {
      setAvailabilityArray(availabilityData.availability_percentage?.availability_percentages);
      setDates(availabilityData.availability_percentage?.dates);
    } else {
      setAvailabilityArray([]);
      setDates([]);
    }
  }, [availabilityData]);

  useEffect(() => {
    setPerformanceArray([]);
    if (performanceData && Object.keys(performanceData).length !== 0) {
      setPerformanceArray(performanceData.performance_percentages);
    } else {
      setPerformanceArray([]);
    }
  }, [performanceData]);

  useEffect(() => {
    setQualityArray([]);
    if (qualityData && Object.keys(qualityData).length !== 0) {
      setQualityArray(qualityData.quality_percentages?.quality_percentages);
    } else {
      setQualityArray([]);
    }
  }, [qualityData]);

  useEffect(() => {
    if (availabilityArray && performanceArray && qualityArray) {
      const trend = availabilityArray?.map((value, idx) => {
        const oee = (value * performanceArray[idx] * qualityArray[idx]) / 10000;
        return oee.toFixed(4);
      });
      setOeeTrendArray(trend);
    }
  }, [availabilityArray, performanceArray, qualityArray]);

  //Export data
  const dataToExport = () => {
    let dataToExport = [];
    if (dates) {
      for (let i = 0; i < dates.length; i++) {
        dataToExport.push({
          date: dates[i],
          availability: availabilityArray[i],
          performance: performanceArray[i],
          quality: qualityArray[i],
          oee: oeeTrendArray[i],
        });
      }
      setExcelTableData(dataToExport);
      setPaginationCount(dataToExport.length);
    }
  };

  useEffect(() => {
    dataToExport();
  }, [oeeTrendArray]);

  const handleCloseModal = () => {
    setShowTableData(false);
  };

  const options = {
    series: [
      {
        name: 'Availability',
        type: 'column',
        data: availabilityArray,
      },
      {
        name: 'Performance',
        type: 'column',
        data: performanceArray,
      },
      {
        name: 'Quality',
        type: 'column',
        data: qualityArray,
      },
      {
        name: 'OEE',
        type: 'line',
        data: oeeTrendArray,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        stacked: false,
      },
      colors: ['#ff4f2b', '#08bfff', '#ffae16'],
      markers: {
        size: 6,
        strokeWidth: 2,
      },
      stroke: {
        width: [1, 1, 1, 2],
      },
      title: {
        text: 'OEE TREND',
      },
      subtitle: {
        text: subtitle,
        align: 'left',
        offsetY: 20,
        style: {
          color: '#333',
          fontSize: '12px',
        },
      },
      xaxis: {
        categories: dates,
        tickPlacement: 'between',
      },
      yaxis: [
        {
          axisTicks: {
            show: false,
          },
          min: 0,
          axisBorder: {
            show: false,
          },
        },
      ],
      legend: {
        horizontalAlign: 'center',
      },
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
  };

  return (
    <>
      <Card className="p-3 my-4">
        <SimpleTableModal
          title={'OEE Trend Chart'}
          headers={['date', 'availability', 'performance', 'quality', 'oee']}
          tableData={excelTableData}
          show={showTableData}
          handleClose={handleCloseModal}
          totalCount={paginationCount}
        />
        <div>
          <Row className="flex-row-reverse">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle className="fpb-btn-chart" id="dropdown-download" />
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowTableData(true);
                  }}
                >
                  Show Data
                </Dropdown.Item>
                <ExportExcel
                  excelData={excelTableData}
                  fileName={indicatorName + ' Report (' + from + ' -' + to + ')'}
                  indicator={indicatorName}
                  filters={subtitle}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </div>
        <Chart
          type="line"
          height={300}
          options={JSON.parse(JSON.stringify(options.options))}
          series={JSON.parse(JSON.stringify(options.series))}
        />
      </Card>
    </>
  );
};

export default OEETrendChart;
