import React from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from './logo_color_small.png';

const LogoFPB = ({ logoWidth }) => {
  return (
    <div className="logo-container">
      <Row className="text-left">
        <Col className="pb-5 pt-1">
          <img src={logo} alt="FBP logo" className="logo-image" />
        </Col>
      </Row>
    </div>
  );
};
export default LogoFPB;
