import React from 'react';
import { Form, Col, Row, FormLabel } from 'react-bootstrap';
import { ErrorMessage, useField } from 'formik';

const TextField = ({ label, description, errorMessage, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group as={Col} className="mb-2">
      <Form.Label className="label" htmlFor={field.name}>
        {label}
      </Form.Label>
      <Form.Control
        {...field}
        {...props}
        autoComplete="off"
        className={meta.touched && meta.error && 'is-invalid'}
      />
      <ErrorMessage component="div" name={field.name} className="fpb-error" />
      <small className="fpb-error">{errorMessage}</small>
      <FormLabel className="description-select">{description}</FormLabel>
    </Form.Group>
  );
};

export default TextField;
