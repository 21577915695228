import { React, useRef, useLayoutEffect } from 'react';
import { select, scaleBand, scaleLinear, range, pointer } from 'd3';

export const BarChart = ({ data, xDomain, yDomain, width, height, name }) => {
  const margin = {
    top: 50,
    bottom: 60,
    left: 70,
    right: 30,
  };

  const containerRef = useRef(null);
  const containerRef2 = useRef(null);

  const dataValues = data.map((d) => d.value);

  useLayoutEffect(() => {
    select(containerRef.current).selectAll('*').remove();

    const tooltip = select(containerRef2.current).style('opacity', 0);

    const mouseover = (event, d) => {
      tooltip.style('opacity', 1);
    };

    const mouseleave = (event, d) => {
      tooltip.style('opacity', 0);
    };

    const mousemove = (event, d) => {
      const [x, y] = pointer(event);
      const tooltipContainer = select(`.tooltipContainer${name}`)
        .attr('x', 10)
        .attr('y', -25)
        .attr('width', 125)
        .attr('height', 50)
        .attr('rx', 4)
        .attr('ry', 4);

      const text = select(`.tooltip-area__text${name}`).attr('x', 20).attr('y', 15);
      const text2 = select(`.tooltip-area__text2${name}`).attr('x', 20).attr('y', -5);

      const suf = name === 'b1' || name === 'b5' ? '$' : 'units';
      name === 'b1' || name === 'b5' ? text.text(`${suf}${d}`) : text.text(`${d} ${suf}`);
      name === 'b1' || name === 'b5' ? text2.text('Total cash') : text2.text('Total stock');
      tooltip.attr('transform', `translate(${x},${100})`);
    };

    const x = scaleBand()
      .domain(range(xDomain))
      .range([margin.left, width - margin.right]);
    const y = scaleLinear()
      .domain(yDomain)
      .range([height - margin.bottom, margin.top]);
    const barWidth = dataValues.length < 2 ? x.bandwidth() / 2 : x.bandwidth() / dataValues.length;

    select(containerRef.current)
      .append('g')
      .attr('fill', '#ff3c77')
      .selectAll('rect')
      .data(dataValues)
      .join('rect')
      .attr('x', (_, i) => (dataValues.length < 2 ? width / 4 : barWidth * i))
      .attr('width', barWidth - 10)
      .attr('y', (d) => y(d))
      .attr('height', (d) => y(0) - y(d))
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave)
      .on('mouseover', mouseover);

    select(containerRef2.current).append('rect').attr('class', `tooltipContainer${name}`);

    select(containerRef2.current).append('text').attr('class', `tooltip-area__text${name}`);

    select(containerRef2.current).append('text').attr('class', `tooltip-area__text2${name}`);
  }, [data, height, width, xDomain, yDomain]);

  return (
    <svg>
      <g ref={containerRef} transform={`translate(${margin.left + 1}, -1)`} />
      <g ref={containerRef2} />
    </svg>
  );
};
