import { createSlice } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const timeAnalysisSlice = createSlice({
  name: 'timeAnalysis',
  initialState: {
    data: {},
    dataConsolidated: {},
    progress: { pending: true, current: 0, total: 0, percent: 0 },
    progressConsolidated: {},
    status: 'idle',
    selectedWorkCenter: {},
    filters: {},
  },
  reducers: {
    getTimeAnalysis: (state, action) => {
      state.data = action.payload;
    },
    getTimeAnalysisConsolidated: (state, action) => {
      state.dataConsolidated = action.payload;
    },
    setDataTimeAnalysis: (state, action) => {
      state.data = action.payload;
    },
    setProgressTimeAnalysis: (state, action) => {
      if (action.payload.current >= state.progress?.current) {
        state.progress = action.payload;
      } else {
        state.progress = state.progress;
      }
    },
    setStatusTimeAnalysis: (state, action) => {
      state.status = action.payload;
      if (action.payload === 'SUCCESS') {
        state.progress = { ...state.progress, current: 0, percent: 100 };
      }
    },
    setSelectedWorkCenter: (state, action) => {
      state.selectedWorkCenter = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const getTimeAnalysisAsync =
  (startDate, endDate, temporality, workCenter, shift, product, productionOrder) =>
  async (dispatch) => {
    try {
      const response = await apiService.getTimeAnalysisByFilters(
        startDate,
        endDate,
        temporality,
        workCenter,
        shift,
        product,
        productionOrder
      );
      dispatch(getTimeAnalysis(response.data));
    } catch (err) {
      console.log(err);
    }
  };

export const getTimeAnalysisConsolidatedAsync =
  (startDate, endDate, temporality, workCenterList) => async (dispatch) => {
    try {
      let results = [];
      for (let currentWorkCenter of workCenterList) {
        const res = await apiService.getTimeAnalysisByFilters(
          startDate,
          endDate,
          temporality,
          currentWorkCenter,
          '',
          '',
          ''
        );
        if (Object.keys(res.data.total_time_analysis).length !== 0) {
          results.push(res.data.total_time_analysis);
        } else {
          results.push(null);
        }
      }
      dispatch(getTimeAnalysisConsolidated(results));
      console.log('results', results);
    } catch (err) {
      console.log(err);
    }
  };

export const {
  getTimeAnalysis,
  setDataTimeAnalysis,
  setStatusTimeAnalysis,
  setProgressTimeAnalysis,
  setSelectedWorkCenter,
  setFilters,
} = timeAnalysisSlice.actions;
export const showTimeAnalysis = (state) => state.timeAnalysis.data;

export const { getTimeAnalysisConsolidated } = timeAnalysisSlice.actions;
export const showTimeAnalysisConsolidated = (state) => state.timeAnalysis.dataConsolidated;

export default timeAnalysisSlice.reducer;
