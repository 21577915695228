import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { opListFilter } from '../../redux/slices/timeAccomplishmentSlice';
import Urls from '../Urls';
import Table from '../Table/Table';
import { showSelectedProductionOrders } from '../../redux/slices/tableSlice';

const TimeAccomplishment = ({ setAddForm }) => {
  const dispatch = useDispatch();
  const selectedProductionOrders = useSelector(showSelectedProductionOrders);

  const handleApplyFilter = async () => {
    setAddForm(false);
    dispatch(opListFilter(selectedProductionOrders));
  };

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          startDate: '',
          endDate: '',
          checked: [],
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <Table url={Urls.productionOrder} name={'productionOrder'} />

            <Button className="fpb-btn mt-3" onClick={handleApplyFilter}>
              Apply
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TimeAccomplishment;
