import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';
import Urls from '../Urls';

const Product = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [descriptions, setDescriptions] = useState({ productCategoryDescription: '' });
  const [productCategoryData, setProductCategoryData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const productValidate = Yup.object({
    code: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string().required('Required'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(async () => {
    try {
      const resCategory = await apiService.getData(Urls.productCategory, 'code');
      setProductCategoryData({
        headers: resCategory.headersList,
        tableData: resCategory.data,
        options: resCategory.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    productCategoryData.options?.forEach((option) => {
      console.log(option);
      if (option.value === values.product_category) {
        setDescriptions(
          option
            ? { ...descriptions, productCategoryDescription: option.description }
            : { ...descriptions, productCategoryDescription: '' }
        );
      }
    });
  }, [productCategoryData]);

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={productValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            code: data.code,
            description: data.description,
            product_category: data.productCategory || null,
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
          };
          setProcessedFormValues(processedValues);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Product',
              code: data.code,
            });
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Product',
              code: data.code,
            });
            if (updated.statusText == 'OK') {
              setAddForm(false);
              onEdited(processedValues);
            }
          }
        }}
        initialValues={{
          id: values.code,
          code: values.code || '',
          description: values.description || '',
          productCategory: values.product_category || '',
          createdBy: values.created_by || '',
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField label="Product Code" name="code" type="string" value={values.code} />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('productCategory', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, productCategoryDescription: option.description }
                      : { ...descriptions, productCategoryDescription: '' }
                  );
                }}
                label="Product Category Code"
                name="productCategory"
                options={productCategoryData.options}
                defaultValue={{
                  value: values.productCategory,
                  label: values.productCategory,
                }}
                description={descriptions.productCategoryDescription}
                value={{
                  value: values.productCategory,
                  label: values.productCategory,
                }}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Product;
