import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react';

function StowedInformation() {
  const [totalPallets, setTotalPallets] = useState(0);
  const [ti, setTi] = useState(0);
  const [hi, setHi] = useState(0);
  const [boxesPallet, setBoxesPallet] = useState(0);
  const [totalBoxes, setTotalBoxes] = useState(0);
  const [netWeight, setNetWeight] = useState(0);
  const NET_WEIGHT_PACK = 24;

  useEffect(() => {
    if (ti > 0 && hi > 0) {
      setBoxesPallet(ti * hi);
    }
  }, [ti, hi]);

  useEffect(() => {
    if (totalPallets > 0 && boxesPallet > 0) {
      setTotalBoxes(totalPallets * boxesPallet);
    }
  }, [totalPallets, boxesPallet]);

  useEffect(() => {
    setNetWeight(totalBoxes * NET_WEIGHT_PACK);
  }, [totalBoxes]);

  const stowedOptions = [
    { value: '1', label: 'Pallet' },
    { value: '2', label: 'Estiba' },
  ];
  const containerOptions = [
    { value: '1', label: '40 ft' },
    { value: '2', label: '20 ft' },
  ];
  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Stowed:</Form.Label>
        <Select options={stowedOptions} />
      </Form.Group>
      <Form.Group className="mb-3" as={Row}>
        <Col>
          <Form.Label>Container:</Form.Label>
          <Select options={containerOptions} />
        </Col>
        <Col>
          <Form.Label>Net Weight (lb):</Form.Label>
          <Form.Control type="number" disabled value={netWeight} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Label>Total Boxes:</Form.Label>
          <Form.Control type="number" disabled value={totalBoxes} />
        </Col>
        <Col>
          <Form.Label>Boxes/Pallet:</Form.Label>
          <Form.Control type="number" disabled value={boxesPallet} />
        </Col>
        <Col>
          <Form.Label>Total Pallets:</Form.Label>
          <Form.Control type="number" onChange={(e) => setTotalPallets(e.target.value)} />
        </Col>
        <Col>
          <Form.Label>Ti:</Form.Label>
          <Form.Control type="number" onChange={(e) => setTi(e.target.value)} />
        </Col>
        <Col>
          <Form.Label>Hi:</Form.Label>
          <Form.Control type="number" onChange={(e) => setHi(e.target.value)} />
        </Col>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Destination:</Form.Label>
        <Form.Control as="textarea" aria-label="With textarea" />
      </Form.Group>
    </Form>
  );
}

export default StowedInformation;
