import React from 'react';
import Urls from '../Urls';
import TableSection from '../../pages/industrial/TableSection';

const QualityReportTableSection = () => {
  return (
    <TableSection url={Urls.qualityReport} formName="quality" title="Quality Report" disableAdd />
  );
};

export default QualityReportTableSection;
