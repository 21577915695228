import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import TextField from '../FormComponents/TextField/TextField';
import apiService from '../apiService';
import Urls from '../Urls';

const Variable = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [descriptions, setDescriptions] = useState({
    dimensionalUnitDescription: '',
  });
  const [dimensionalData, setDimensionalData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const variableValidate = Yup.object({
    code: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string().required('Required'),
    calculationVariable: Yup.string().required('Required'),
    comments: Yup.string(),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(async () => {
    try {
      const resDimensionalUnit = await apiService.getData(Urls.dimensionalUnitA, 'code');
      setDimensionalData({
        headers: resDimensionalUnit.headersList,
        tableData: resDimensionalUnit.data,
        options: resDimensionalUnit.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    dimensionalData.options?.forEach((option) => {
      if (option.value === values.dimensional_unit) {
        setDescriptions(
          option
            ? { ...descriptions, dimensionalUnitDescription: option.description }
            : { ...descriptions, dimensionalUnitDescription: '' }
        );
      }
    });
  }, [dimensionalData]);

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={variableValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            code: data.code,
            dimensional_unit: data.dimensionalUnit,
            manual_iot: data.manualIot,
            calculation_variable: data.calculationVariable,
            description: data.description,
            comments: data.comments,
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
          };
          setProcessedFormValues(processedValues);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Variable',
              code: data.code,
            });
            if (created.statusText == 'Created') {
              //window.location.reload(false);
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Variable',
              code: data.code,
            });
            if (updated.statusText == 'OK') {
              setAddForm(false);
              onEdited(processedValues);
              //window.location.reload(false);
            }
          }
        }}
        initialValues={{
          id: values.code,
          code: values.code || '',
          dimensionalUnit: values.dimensional_unit || '',
          manualIot: values.manual_iot || 'manual',
          calculationVariable: values.calculation_variable || 'no',
          description: values.description || '',
          comments: values.comments || '',
          createdBy: values.created_by || '',
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField label="Variable Code" name="code" type="text" value={values.code} />
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
              />
            </Form.Row>
            <Form.Row>
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('calculationVariable', option ? option.value : '');
                }}
                label="Calculation Variable"
                name="calculationVariable"
                options={[
                  { value: 'yes', label: 'yes' },
                  { value: 'no', label: 'no' },
                ]}
                defaultValue={{
                  value: values.calculationVariable,
                  label: values.calculationVariable,
                }}
              />

              <TextField label="Comments" name="comments" type="text" value={values.comments} />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Variable;
