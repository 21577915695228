import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ActionConfirmation = ({
  show,
  handleClose,
  handleConfirmation,
  message = 'Are you sure you want to delete this item?',
}) => {
  return (
    message && (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default ActionConfirmation;
