import React, { useEffect, memo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PaymentNotificationFooter() {
  useEffect(() => {
    toast.warning(
      <div>
        Querido usuario. El sistema ha detectado facturas impagas. Por favor procede a gestionarlas
        inmediatamente para evitar problemas con el servicio.
      </div>,
      {
        theme: 'dark',
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        closeButton: false,
        toastId: 'paySoonToast',
      }
    );
  }, []);

  return (
    <div>
      <ToastContainer />
    </div>
  );
}

export default memo(PaymentNotificationFooter);
