import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const getAccomplishmentByQuantityAsync = createAsyncThunk(
  'accomplishment/getAccomplishmentByQuantityAsync',
  async ({ startDate, endDate, temporality, productionOrder, product }) => {
    try {
      const res = await apiService.getAccomplishmentByQuantity(
        startDate,
        endDate,
        temporality,
        productionOrder,
        product
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const accomplishmentByQuantitySlice = createSlice({
  name: 'accomplishment',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    getaccomplishmentByQuantitySlice: (state, action) => {
      state.data = action.payload;
    },
    startDateFilter: (state, action) => {
      state.startDate = action.payload;
    },
    endDateFilter: (state, action) => {
      state.endDate = action.payload;
    },
    temporalityFilter: (state, action) => {
      state.temporality = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccomplishmentByQuantityAsync.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getAccomplishmentByQuantityAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAccomplishmentByQuantityAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const {
  getaccomplishmentByQuantitySlice,
  startDateFilter,
  endDateFilter,
  temporalityFilter,
} = accomplishmentByQuantitySlice.actions;
export const showAccomplishment = (state) => state.accomplishment.data;
export const availabilityLoadingState = (state) => state.accomplishment.loading;
export const availabilityErrorState = (state) => state.accomplishment.error;

export default accomplishmentByQuantitySlice.reducer;
