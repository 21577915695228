import { combineReducers } from 'redux';
import workCenterReducer from '../slices/workCenterSlice';
import productPerProductionOrderReducer from '../slices/productPerProductionOrderSlice';
import productionOrderReducer from '../slices/productionOrderSlice';
import manualReportsReducer from '../slices/manualReportsSlice';
import qualityReportsReducer from '../slices/qualityReportsSlice';
import eventsReducer from '../slices/eventsSlice';
import timeAnalysisReducer from '../slices/timeAnalysisSlice';
import indicatorFilterReducer from '../slices/indicatorFilterSlice';
import producedUnitsReducer from '../slices/producedUnitsSlice';
import availabilityReducer from '../slices/availabilitySlice';
import qualityReducer from '../slices/qualitySlice';
import iotProcessControlReducer from '../slices/iotProcessControlSlice';
import shiftsReducer from '../slices/shiftSlice';
import performanceReducer from '../slices/performanceSlice';
import oeeReducer from '../slices/oeeSlice';
import accomplishmentReducer from '../slices/accomplishmentByQuantitySlice';
import timeAccomplishmentReducer from '../slices/timeAccomplishmentSlice';
import historicalReducer from '../slices/historicalSlice';
import exportDataReducer from '../slices/exportSlice';
import userReducer from '../slices/userSlice';
import tableReducer from '../slices/tableSlice';

import {
  SET_TOTAL_COUNT,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  SET_MODAL_TOTAL_COUNT,
  SET_MODAL_CURRENT_PAGE,
  SET_MODAL_PAGE_SIZE,
  SET_IS_MODAL_PAGINATION,
  SET_MENU,
  SET_TRANSACTION_QUERY,
  SET_ROUTES,
  SET_PRODUCTS,
} from '../actions';

const userInitialState = {
  user: null,
};
const paginationInitialState = {
  totalCount: 10,
  currentPage: 0,
  pageSize: 10,
  modalTotalCount: 10,
  modalCurrentPage: 0,
  modalPageSize: 10,
  isModalPagination: false,
};
const menuInitialState = {
  menu: '',
};
const queryTransactionStringInitialState = localStorage.getItem('transactionQuery');
const industrialInititalState = {
  routes: [],
  products: [],
};

const paginationReducer = (state = paginationInitialState, action) => {
  switch (action.type) {
    case SET_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case SET_MODAL_TOTAL_COUNT:
      return { ...state, modalTotalCount: action.payload };
    case SET_MODAL_CURRENT_PAGE:
      return { ...state, modalCurrentPage: action.payload };
    case SET_MODAL_PAGE_SIZE:
      return { ...state, modalPageSize: action.payload };
    case SET_IS_MODAL_PAGINATION:
      return { ...state, isModalPagination: !state.isModalPagination };
    default:
      return state;
  }
};

const menuReducer = (state = menuInitialState, action) => {
  switch (action.type) {
    case SET_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    default:
      return state;
  }
};

const transactionQueryReducer = (state = queryTransactionStringInitialState, action) => {
  switch (action.type) {
    case SET_TRANSACTION_QUERY:
      return action.payload;
    default:
      return state;
  }
};

const industrialReducer = (state = industrialInititalState, action) => {
  switch (action.type) {
    case SET_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  pagination: paginationReducer,
  menu: menuReducer,
  transactionQuery: transactionQueryReducer,

  // industrial
  industrial: industrialReducer,
  workCenters: workCenterReducer,
  productPerProductionOrders: productPerProductionOrderReducer,
  productionOrders: productionOrderReducer,
  manualReports: manualReportsReducer,
  qualityReports: qualityReportsReducer,
  events: eventsReducer,
  timeAnalysis: timeAnalysisReducer,
  indicatorFilter: indicatorFilterReducer,
  producedUnits: producedUnitsReducer,
  availability: availabilityReducer,
  oee_quality: qualityReducer,
  iotProcessControl: iotProcessControlReducer,
  shifts: shiftsReducer,
  performance: performanceReducer,
  oee: oeeReducer,
  accomplishment: accomplishmentReducer,
  timeAccomplishment: timeAccomplishmentReducer,
  historical: historicalReducer,
  exportData: exportDataReducer,
  table: tableReducer,
});

export default rootReducer;
