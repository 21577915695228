import { useState, useEffect } from 'react';
import axios from 'axios';
import Urls from '../components/Urls';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentWorkCenterHasQualityIoT } from '../redux/slices/workCenterSlice';

export const useFetchQualityFromIoT = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('accessToken');
  const currentWorkCenter = useSelector((state) => state.workCenters.currentWorkCenter);
  const currentWorkCenterHasQualityIoT = useSelector(
    (state) => state.workCenters.currentWorkCenterHasQualityIoT
  );

  const fetchQualityFromIoT = async () => {
    await axios
      .get(`${Urls.iotQuality}?work_center=${currentWorkCenter.code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCurrentWorkCenterDetails = async () => {
    const token = localStorage.getItem('accessToken');
    return await axios
      .get(`${Urls.workCenter}/${currentWorkCenter.code}`, {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          fetchQualityFromIoT();
          if (res.data.quality_source == 'iot') {
            dispatch(setCurrentWorkCenterHasQualityIoT(true));
            const interval = setInterval(() => {
              if (currentWorkCenterHasQualityIoT) {
                fetchQualityFromIoT();
              }
            }, 30000);
            return () => clearInterval(interval);
          } else {
            dispatch(setCurrentWorkCenterHasQualityIoT(false));
          }
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    getCurrentWorkCenterDetails();
  }, []);

  return { data, loading, error };
};
