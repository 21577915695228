import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExportCSV from '../../ExportExcel/ExportButton';
import FPBTable from '../../FPBTable/FPBTableIndustrial';
import ActionConfirmation from '../../ActionConfirmation/ActionConfirmation';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import AlertMessage from '../../AlertMessage/AlertMessage';
import { setTotalCountAction } from '../../../redux/actions/paginationAction';
import apiService from '../../apiService';
import { notification } from '../../AlertMessage/ToastifyAlert';
import { getExportDataAsync } from '../../../redux/slices/exportSlice';
//Calendar
import 'react-big-calendar/lib/css/react-big-calendar.css';
import FilterAccomplishment from '../FilterAccomplishment';

const TimeAccomplishment = ({ url, formName, title }) => {
  const [addReload, setAddReload] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageUpdate, setErrorMessageUpdate] = useState('');
  const industrialClient = apiService.industrialClient();
  const [columnOrder, setColumnOrder] = useState([]);
  // pagination
  const [filters, setFilters] = useState([]);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  //resources
  const startDate = useSelector((state) => state.timeAccomplishment.startDate);
  const endDate = useSelector((state) => state.timeAccomplishment.endDate);
  const opList = useSelector((state) => state.timeAccomplishment.opList);
  const accomplishment = useSelector((state) => state.timeAccomplishment.accomplishment);
  const shift = useSelector((state) => state.timeAccomplishment.shift);
  const dispatch = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatch(setTotalCountAction(totalCount));
  };

  const onAdded = (r) => {
    setAddedRow(r);
    setAddReload(!addReload);
  };

  const handleClose = () => setShow(false);

  const getQueryString = () => {
    if (opList.length !== 0) {
      const urlT = `${url}?page=${currentPage + 1}&page_size=${pageSize}&op_list=${opList}`;
      return urlT;
    } else {
      const urlT = `${url}?page=${
        currentPage + 1
      }&page_size=${pageSize}&start_date=${startDate}&end_date=${endDate}&shift=${shift}&accomplishment=${accomplishment}`;
      return urlT;
    }
  };

  useEffect(() => {
    const queryString = getQueryString();
    const getTableData = async () => {
      try {
        const response = await industrialClient.get(queryString);
        if (response.data.results) {
          const tableData = response.data.results;
          setTotalCount(response.data.count);
          if (formName === 'timeAccomplishment') {
            tableData.map((res) => {
              res.month = new Date(res.end_date)
                .toLocaleString('en-US', { month: 'long' })
                .toUpperCase();
              res.year = new Date(res.end_date).getFullYear();
              var temporal = res.end_date;
              delete res.end_date;
              res.real_closing_date = temporal;
            });
          }
          const headersList = Object.keys(tableData[0]);
          setHeaders(headersList);
          setTableData(tableData);
        } else {
          setTableData([]);
          setTotalCount(0);
        }
      } catch (err) {
        console.log('error: ', err);
        setTableData([]);
        setTotalCount(0);
      }
    };
    getTableData();
  }, [url, pageSize, currentPage, filters, addReload, startDate, endDate, opList, accomplishment]);

  const handleExport = async () => {
    const filters = `start_date=${startDate}&end_date=${endDate}&op_list=${opList}&accomplishment=${accomplishment}`;
    dispatch(getExportDataAsync({ urlExport: url, filters: filters }));
  };

  useEffect(() => {
    alertMessage !== '' ? notification(alertMessage, 'success') : null;
  }, [alertMessage]);

  useEffect(() => {
    errorMessage !== '' ? notification(errorMessage, 'error') : null;
  }, [errorMessage]);

  useEffect(() => {
    if (formName === 'timeAccomplishment') {
      setColumnOrder([
        'code',
        'accomplishment',
        'time_deviation',
        'planned_closing_date',
        'real_closing_date',
        'month',
        'year',
      ]);
    } else {
      setColumnOrder([
        'code',
        'accomplishment',
        'time_deviation',
        'planned_closing_date',
        'deviation',
        'accomplishment_ratio',
      ]);
    }
  }, [tableData]);

  return (
    <>
      <HeaderTitle title={title} />
      <FilterAccomplishment className="pb-5" formName={formName} />
      <ActionConfirmation show={show} handleClose={handleClose} />
      <div className="text-left btn-container">
        <ExportCSV csvData={tableData} fileName={title} handleExport={handleExport} />
        {alertMessage ? (
          <AlertMessage
            variant="success"
            message={alertMessage}
            setAlertMessage={setAlertMessage}
          />
        ) : null}
      </div>

      <FPBTable
        readOnly={true}
        editOnly={true}
        deleteOnly={false}
        headers={headers}
        tableData={tableData}
        onAdded={onAdded}
        setAlert={setAlertMessage}
        setError={setErrorMessageUpdate}
        errorMessageUpdate={errorMessageUpdate}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        setFilters={setFilters}
        disableFilters
        customColumnOrder={columnOrder}
      />
    </>
  );
};

export default TimeAccomplishment;
