import axios from 'axios';
import { useState } from 'react';

const useForm = (url, setAddForm, setAlertMessage, setErrorMessage, setErrorMessageUpdate) => {
  const accessToken = localStorage.getItem('accessToken');
  const [added, setAdded] = useState(false);
  const [edited] = useState(false);
  const [values, setValues] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const header = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const handleStatusAlert = (isError, meta) => {
    if (isError) {
      setErrorMessage(`${meta.entity} not registered. ${meta.error}`);
    } else {
      meta
        ? setAlertMessage(`${meta.entity} with code ${meta.code} registered successfully`)
        : setAlertMessage('Registered successfully');
    }
  };

  const handleStatusAlertUpdate = (isError, meta) => {
    if (isError) {
      setErrorMessageUpdate(`${meta.entity} not updated. ${meta.error}`);
    } else {
      meta
        ? setAlertMessage(`${meta.entity} with code ${meta.code} updated successfully`)
        : setAlertMessage('Updated successfully');
    }
  };

  const handleAPIRequest = async (val, meta) => {
    const result = await axios
      .post(url, val, header)
      .then((res) => {
        setAdded(true);
        setAddForm ? setAddForm(false) : null;
        handleStatusAlert(false, meta);
        setAdded(false);
        return res;
      })
      .catch((err) => {
        const metaError = { ...meta, error: err.message };
        handleStatusAlert(true, metaError);
        setAdded(false);
        return err;
      });
    return result;
  };

  const handleAPIDelete = async (meta) => {
    const result = await axios
      .delete(url, header)
      .then((res) => {
        setAdded(true);
        setAddForm ? setAddForm(false) : null;
        handleStatusAlert(false, meta);
        setAdded(false);
        return res;
      })
      .catch((err) => {
        const metaError = { ...meta, error: err.message };
        handleStatusAlert(true, metaError);
        setAdded(false);
        return err;
      });
    return result;
  };

  const handleAPIRequestUpdate = async (id, val, meta) => {
    const result = await axios
      .patch(`${url}/${id}`, val, header)
      .then((res) => {
        setAddForm ? setAddForm(false) : null;
        handleStatusAlertUpdate(false, meta);
        return res;
      })
      .catch((err) => {
        const metaError = { ...meta, error: err.message };
        handleStatusAlertUpdate(true, metaError);
        setAdded(false);
        return err;
      });
    return result;
  };

  return {
    handleChange,
    values,
    handleAPIRequest,
    added,
    setAdded,
    edited,
    setValues,
    handleAPIRequestUpdate,
    handleAPIDelete,
  };
};

export default useForm;
