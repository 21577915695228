import React, { useState, useEffect } from 'react';
import {
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  PagingState,
  CustomPaging,
  EditingState,
  SelectionState,
  DataTypeProvider,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableFilterRow,
  PagingPanel,
  ColumnChooser,
  TableColumnVisibility,
  TableEditColumn,
  Toolbar,
  DragDropProvider,
  TableColumnReordering,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../Forms/FormContainer';
import AlertMessage from '../AlertMessage/AlertMessage';
import {
  setPageSizeAction,
  setCurrentPageAction,
  setModalPageSizeAction,
  setModalCurrentPageAction,
} from '../../redux/actions/paginationAction';
import { setShiftsCurrentPage, setShiftsPageSize } from '../../redux/slices/shiftSlice';
import {
  setWorkCenterCurrentPage,
  setWorkCenterPageSize,
} from '../../redux/slices/workCenterSlice';
import {
  setProductPerProductionOrderCurrentPage,
  setProductPerProductionOrderPageSize,
} from '../../redux/slices/productPerProductionOrderSlice';
import {
  setProductionOrderCurrentPage,
  setProductionOrderPageSize,
} from '../../redux/slices/productionOrderSlice';

const FPBTable = ({
  tableData,
  headers,
  handleDelete,
  handleEdit,
  deleteChange,
  addedRow,
  editOnly,
  readOnly,
  isModal,
  setSelected,
  isCustomConsolidationLevel,
  setConsolidationLevelSelected,
  customColumnOrder,
  columnsToHide,
  handleChange,
  handleAPIRequestUpdate,
  added,
  edited,
  transformCode,
  formName,
  onAdded,
  setAlert,
  setError,
  setErrorMessageUpdate,
  errorMessageUpdate,
  title,
  withCheckBox,
  handleSelectedTran,
  totalCount,
  currentPage,
  pageSize,
  setFilters,
  productionOrder,
  status,
}) => {
  const [modalSize, setModalSize] = useState('lg');
  const [cols, setCols] = useState([]);
  const [columnWidths, setColumnWidths] = useState([]);
  const [rows, setRows] = useState([]);
  // Rows manipulation
  // const [filters, setFilters] = useState([{}]);
  const [sorting, setSorting] = useState([]);
  // Pagination
  const [pageSizes] = useState([5, 10, 15]);
  //TODO implement spinner for loading
  //const [loading, setLoading] = useState(false);
  // Columns manipulation
  const [hiddenColumnNames, setHiddenColumnNames] = useState(columnsToHide);
  const [columnOrder, setColumnOrder] = useState([]);
  const [tableColumnExtensions] = useState(columnWidths);
  // Filters options
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [currencyColumns] = useState([
    'amount',
    'n0_cost',
    'n0_units',
    'safety_stock',
    'maximum_stock',
    'minimum_stock',
    'ordering_point',
    'monetary_value_N0',
    'cost',
    //industrial
    'value',
    'reported_quantity',
    'quantity_nonconforming',
    'variable_amount',
  ]);

  const [currencyFilterOperations] = useState(['equal', 'greaterThanOrEqual', 'lessThanOrEqual']);
  const [defaultFiltersOperations] = useState(['contains', 'equal']);
  // Data operations
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [remainingRows, setRemainingRows] = useState({});
  const [startingId, setStartingId] = useState(0);
  const [selection, setSelection] = useState([]);
  const [currentConsolidationLevel, setCurrentConsolidationLevel] = useState();
  // edition
  const [onEdited, setOnEdited] = useState();
  const [rowEditedId, setRowEditedId] = useState();
  // pagination
  const isModalPagination =
    formName === 'productPerProductionOrder'
      ? true
      : useSelector((state) => state.pagination.isModalPagination);

  const dispatch = useDispatch();
  const dispatchPageSize = useDispatch();
  const dispatchCurrentPage = useDispatch();
  const dispatchModalPageSize = useDispatch();
  const dispatchModalCurrentPage = useDispatch();

  const setPageSize = (pageSize) => {
    if (formName === 'shifts') {
      dispatch(setShiftsPageSize(pageSize));
      dispatch(setShiftsCurrentPage(0));
    } else if (formName === 'shiftWorkCenters') {
      dispatch(setWorkCenterPageSize(pageSize));
      dispatch(setWorkCenterCurrentPage(0));
    } else if (formName === 'productPerProductionOrder') {
      dispatch(setProductPerProductionOrderPageSize(pageSize));
      dispatch(setProductPerProductionOrderCurrentPage(0));
    } else if (formName === 'productionOrder') {
      dispatch(setProductionOrderPageSize(pageSize));
      dispatch(setProductionOrderCurrentPage(0));
    } else if (isModalPagination) {
      dispatchModalPageSize(setModalPageSizeAction(pageSize));
    } else {
      dispatchPageSize(setPageSizeAction(pageSize));
    }
  };

  const setCurrentPage = (currentPage) => {
    if (formName === 'shifts') {
      dispatch(setShiftsCurrentPage(currentPage));
    } else if (formName === 'shiftWorkCenters') {
      dispatch(setWorkCenterCurrentPage(currentPage));
    } else if (formName === 'productPerProductionOrder') {
      dispatch(setProductPerProductionOrderCurrentPage(currentPage));
    } else if (formName === 'productionOrder') {
      dispatch(setProductionOrderCurrentPage(currentPage));
    } else if (isModalPagination) {
      dispatchModalCurrentPage(setModalCurrentPageAction(currentPage));
    } else {
      dispatchCurrentPage(setCurrentPageAction(currentPage));
    }
  };

  useEffect(() => {
    if (title !== 'Product Per Production Order') {
      setCurrentPage(0);
    }
  }, [title]);
  const changeAddedRows = (value) => {
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  // Hide default messages
  const editColumnMessages = {
    editCommand: '',
    deleteCommand: '',
    commitCommand: '',
    cancelCommand: '',
  };

  const getRowId = (row) => row.id || row.code || row.unique_transaction_code;
  // Configure column headers
  const columnsData = headers?.map((headerName) => {
    const container = {};
    container.name = headerName;
    container.title = headerName.replaceAll('_', ' ').toUpperCase();
    return container;
  });

  function changeSelection(selection) {
    const lastSelected = selection[selection.length - 1];
    if (lastSelected !== undefined) {
      setSelection([lastSelected]);
      setConsolidationLevelSelected ? setConsolidationLevelSelected() : null;

      setCurrentConsolidationLevel(null);
      // pass the object corresponding to the lastSelected id
      setSelected(rows.find((row) => row.id === lastSelected || row.code === lastSelected));
    } else {
      setSelection([]);
      setSelected();
      setConsolidationLevelSelected ? setConsolidationLevelSelected() : null;
    }
  }

  function multipleSelection(selection) {
    if (selection) {
      setSelection(selection);
      setSelected(selection);
    } else {
      setSelection([]);
      setSelected();
    }
  }

  useEffect(() => {
    if (tableData !== null && tableData?.length > 0) {
      setRows(tableData);
      //the following line reset selected rows at the table
      setSelection([]);
    } else {
      setRows([]);
    }
  }, [tableData]);

  useEffect(() => {
    setStartingId(rows.length > 0 ? rows[rows.length - 1].id + 1 : 0);
  }, [rows]);

  useEffect(() => {
    if (columnsData?.length > 0) {
      setDefaultColumns(headers);
      setCols(columnsData);
      const resizeColumns = columnsData?.map((c) => ({
        columnName: c.name,
        width: 200,
      }));
      const columnNames = columnsData?.map((c) => c.name);
      setColumnWidths(resizeColumns);
      setEditingRowIds([]);
      customColumnOrder ? setColumnOrder(customColumnOrder) : setColumnOrder(columnNames);
    }
  }, [headers]);

  // columnsToHide contains the names of columns we don't show by default in the current table
  useEffect(() => {
    setHiddenColumnNames(columnsToHide);
  }, [columnsToHide]);

  // For column resizing
  useEffect(() => {
    if (cols?.length === 0) {
      setTimeout(() => {
        setCols(columnsData || []);
        const resizeColumns = columnsData?.map((c) => ({
          columnName: c.name,
          width: 200,
        }));
        setColumnWidths(resizeColumns);
      }, 1);
    }
  }, [cols]);

  // rows manipulation
  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      const editedId = parseInt(Object.keys(changed));
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      handleEdit(editedId, changed[editedId]);
      setRows(changedRows);
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.id));
      handleDelete(deleted);
      setRemainingRows(changedRows);
    }
  };

  useEffect(() => {
    if (deleteChange === true) {
      setRows(remainingRows);
    }
  }, [deleteChange]);

  useEffect(() => {
    if (addedRow !== undefined && Object.keys(addedRow).length > 0) {
      addedRow.id = startingId;
    }
  }, [addedRow]);

  // When select change the values
  const handleChangeC = (e) => {
    setConsolidationLevelSelected(e.target.value.slice(3));
    setCurrentConsolidationLevel(e.target.value);
  };

  // Associate orders to transactions
  useEffect(() => {
    // selected transactions ids
    if (handleSelectedTran !== undefined) {
      handleSelectedTran(selection);
      if (selection.length !== 0) {
        setSelected(false);
      } else {
        setSelected(true);
      }
    }
  }, [selection]);

  const consolidationLevelsFormatter = ({ value, row }) => {
    const isRowSelected =
      selection.length > 0 && (row.id === selection[0] || row.code === selection[0]);
    return (
      <div data-tap-disabled="true">
        <Form.Control
          as="select"
          name={name}
          disabled={!isRowSelected}
          onChange={handleChangeC}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {isRowSelected ? (
            <option disabled selected>
              {currentConsolidationLevel || '...'}
            </option>
          ) : (
            <option value="" disabled selected hidden>
              ...
            </option>
          )}

          {value
            ? value.map((option, index) => {
                if (isRowSelected) {
                  return (
                    <option
                      key={index}
                      value={`N${index} ${option}`}
                    >{`N${index}_${option}`}</option>
                  );
                }
                return <option key={index} value={option}>{`N${index}_${option}`}</option>;
              })
            : null}
        </Form.Control>
      </div>
    );
  };

  const ConsolidationLevelsTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={consolidationLevelsFormatter} {...props} />
  );

  const DateFormatter = ({ value }) => {
    if (value) {
      return value.replace(
        /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{6})Z/,
        '$3-$2-$1 ($4:$5)'
      );
    }
    return null;
  };

  const DateTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
  );

  // Edit with pop up form
  const Popup = ({ row, onChange, onApplyChanges, setAddForm, open }) => {
    // We need useEffect to only call setRowEditedId when the component is mounted
    useEffect(() => {
      setRowEditedId(row.id);
    }, []);
    return (
      <Modal show={open} onHide={setAddForm} aria-labelledby="form-dialog-title" size={modalSize}>
        <Modal.Header id="form-dialog-title" closeButton>
          <Modal.Title>Edit {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessageUpdate ? (
            <AlertMessage
              variant="danger"
              message={errorMessageUpdate}
              setErrorMessage={setErrorMessageUpdate}
            />
          ) : null}
          <FormContainer
            handleChange={handleChange}
            onAdded={onAdded}
            values={row}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            added={added}
            transformCode={transformCode}
            formName={formName}
            setAddForm={setAddForm}
            setAlert={setAlert}
            setError={setError}
            title={title}
            onEdited={(row) => {
              setOnEdited(row);
            }}
            edited={edited}
            productionOrder={productionOrder}
            status={status}
          />
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    if (onEdited) {
      const newRows = rows.map((row) => {
        if (row.id === rowEditedId) {
          return { id: rowEditedId, ...onEdited };
        }
        return row;
      });
      setRows(newRows);
    }
  }, [onEdited]);

  const PopupEditing = React.memo(function PopupEditing({ popupComponent: Popup }) {
    return (
      <Plugin>
        <Template name="popupEditing">
          <TemplateConnector>
            {(
              { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
              {
                changeRow,
                changeAddedRow,
                commitChangedRows,
                commitAddedRows,
                stopEditRows,
                cancelAddedRows,
                cancelChangedRows,
              }
            ) => {
              const isNew = addedRows.length > 0;
              let editedRow;
              let rowId;
              if (isNew) {
                rowId = 0;
                editedRow = addedRows[rowId];
              } else {
                [rowId] = editingRowIds;
                const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
                editedRow = { ...targetRow, ...rowChanges[rowId] };
              }

              const processValueChange = ({ target: { name, value } }) => {
                const changeArgs = {
                  rowId,
                  change: createRowChange(editedRow, value, name),
                };
                if (isNew) {
                  changeAddedRow(changeArgs);
                } else {
                  changeRow(changeArgs);
                }
              };
              const rowIds = isNew ? [0] : editingRowIds;
              const applyChanges = () => {
                if (isNew) {
                  commitAddedRows({ rowIds });
                } else {
                  stopEditRows({ rowIds });
                  commitChangedRows({ rowIds });
                }
              };
              const cancelChanges = () => {
                if (isNew) {
                  cancelAddedRows({ rowIds });
                } else {
                  stopEditRows({ rowIds });
                  cancelChangedRows({ rowIds });
                }
              };

              const open = editingRowIds.length > 0 || isNew;
              return (
                <Popup
                  open={open}
                  row={editedRow}
                  onChange={processValueChange}
                  onApplyChanges={applyChanges}
                  setAddForm={cancelChanges}
                />
              );
            }}
          </TemplateConnector>
        </Template>
        <Template name="root">
          <TemplatePlaceholder />
          <TemplatePlaceholder name="popupEditing" />
        </Template>
      </Plugin>
    );
  });

  useEffect(() => {
    if (formName === 'productionOrder' || formName === 'productPerProductionOrder') {
      setModalSize('xl');
    }
  }, [formName]);

  return (
    <div className="card">
      <Grid rows={rows} columns={cols} getRowId={getRowId}>
        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={setEditingRowIds}
          rowChanges={rowChanges}
          onRowChangesChange={setRowChanges}
          addedRows={addedRows}
          onAddedRowsChange={changeAddedRows}
          onCommitChanges={commitChanges}
        />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <FilteringState onFiltersChange={setFilters} />
        <IntegratedFiltering />
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />
        <CustomPaging totalCount={totalCount} />
        <DragDropProvider />

        {withCheckBox ? (
          <SelectionState selection={selection} onSelectionChange={multipleSelection} />
        ) : (
          <SelectionState selection={selection} onSelectionChange={changeSelection} />
        )}
        {isCustomConsolidationLevel ? (
          <ConsolidationLevelsTypeProvider for={['consolidation_levels']} />
        ) : null}
        <DateTypeProvider for={['return_approval_date', 'transaction_date']} />
        <DataTypeProvider
          for={defaultColumns}
          availableFilterOperations={defaultFiltersOperations}
        />
        <DataTypeProvider
          for={currencyColumns}
          availableFilterOperations={currencyFilterOperations}
        />
        <Table columnExtensions={tableColumnExtensions} />

        <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
        <TableHeaderRow showSortingControls />
        <IntegratedSelection />
        {isModal ? (
          <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
        ) : (
          <div />
        )}
        {withCheckBox ? <TableSelection selectByRowClick highlightRow showSelectAll /> : <div />}
        <PagingPanel pageSizes={pageSizes} />

        <TableFilterRow showFilterSelector />
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <Toolbar />
        <ColumnChooser />
        {readOnly ? null : (
          <TableEditColumn showEditCommand showDeleteCommand messages={editColumnMessages} />
        )}
        {editOnly ? <TableEditColumn showEditCommand messages={editColumnMessages} /> : null}
        <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
        <PopupEditing popupComponent={Popup} />
      </Grid>
    </div>
  );
};

export default FPBTable;
