const getUniqueValues = (valuesList) => {
  const uniqueOptions = valuesList.reduce((unique, listElement) => {
    if (!unique.some((obj) => obj.value === listElement.value)) {
      unique.push(listElement);
    }
    return unique;
  }, []);
  return uniqueOptions;
};

export default getUniqueValues;
