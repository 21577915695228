import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FPBTable from '../FPBTable/FPBTable';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import ActionConfirmation from '../ActionConfirmation/ActionConfirmation';
import Urls from '../Urls';
import { setCurrentPageAction, setTotalCountAction } from '../../redux/actions/paginationAction';
import mapTable from '../mapTableService';
import { notification } from '../AlertMessage/ToastifyAlert';
import getTransactionTableData from './getTransactionTableData';
import { columnsToHide } from '../Transactions/transactionTableColumns';
import { useHistory } from 'react-router-dom';
import getQueryString from '../../utils/getTransactionsQueryString';

const RegisteredTransactions = () => {
  const history = useHistory();
  const url = Urls.transaction;
  const [token] = useState(localStorage.getItem('accessToken'));
  const [addedRow] = useState({});
  const [headers, setHeaders] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [deleteRows, setDeleteRows] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteChange, setDeleteChange] = useState(false);
  // pagination
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  const dispatchTotalCount = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatchTotalCount(setTotalCountAction(totalCount));
  };
  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    setDeleteRows(id);
    setShow(true);
  };

  const handleEdit = (id, data) => {
    axios.patch(`${url}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  useEffect(() => {
    if (confirmDelete === true) {
      axios
        .delete(`${url}/${deleteRows}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          setDeleteChange(true);
          setConfirmDelete(false);
          setDeleteChange(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [confirmDelete]);

  const handleConfirmation = () => {
    setShow(false), setConfirmDelete(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleReload = () => {
    notification('Refreshing information', 'success');
    const timer = setTimeout(() => {
      history.go(0);
    }, 3000);
  };

  useEffect(async () => {
    mapTable.createEntryOrderMap();
    mapTable.createDimensionalUnitDescriptionMap();
    mapTable.createDispatchOrderMap();
    mapTable.createAdjustmentMap();
    mapTable.createStorageCenterDescriptionCenterMap();
    mapTable.createStorageLocationMap();
  }, []);

  useEffect(() => {
    if (filters.length > 0) {
      dispatch(setCurrentPageAction(0));
    }
  }, [filters]);

  useEffect(() => {
    const queryString = getQueryString(currentPage, pageSize, filters, '');
    getTransactionTableData(
      queryString,
      lastQuery,
      token,
      setTotalCount,
      setHeaders,
      setTableData,
      setLastQuery,
      setIsLoading
    );
  }, [url, pageSize, currentPage, filters]);

  return (
    <div>
      <HeaderTitle title="Registered transactions" />
      <ActionConfirmation
        show={show}
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
      />
      <Button className="fpb-btn mb-4" onClick={handleReload}>
        Reload
      </Button>
      {isLoading ? (
        <div className="text-center">
          <h2>Loading...</h2>
        </div>
      ) : (
        <FPBTable
          readOnly
          headers={headers}
          tableData={tableData}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          deleteChange={deleteChange}
          addedRow={addedRow}
          customColumnOrder={[]}
          columnsToHide={columnsToHide}
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setFilters={setFilters}
        />
      )}
    </div>
  );
};

export default RegisteredTransactions;
