import React, { useState, useEffect } from 'react';
import { Form, Button, Col, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import SelectItems from '../FormComponents/SelectItems/SelectItems';
import { notification } from '../AlertMessage/ToastifyAlert';

const Supplier = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});

  const supplierValidate = Yup.object({
    supplierIdentification: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    typeOfSupplier: Yup.string().required('Required'),
    companyName: Yup.string().required('Required'),
    commercialName: Yup.string(),
    RUC: Yup.string().required('Required'),
    contactPerson: Yup.string(),
    email: Yup.string().email(),
    telephoneNumber: Yup.number().typeError('Must be a  number'),
    cellphoneNumber: Yup.number().typeError('Must be a  number'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={supplierValidate}
        onSubmit={async (data) => {
          const processedValues = {
            supplier_identification: data.supplierIdentification,
            type_of_supplier: data.typeOfSupplier,
            company_name: data.companyName,
            commercial_name: data.commercialName,
            ruc: data.RUC,
            contact_person: data.contactPerson,
            email: data.email,
            telephone_number: data.telephoneNumber,
            cellphone_number: data.cellphoneNumber,
          };
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          if (data.id == null) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Supplier',
              code: data.supplierIdentification,
            });
            if (created.statusText !== 'Created') {
              notification('Error creating item - duplicate item', 'error');
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Supplier',
              code: data.supplierIdentification,
            });
            if (updated.statusText == 'OK') {
              onEdited(processedValues);
              setAddForm(false);
              notification('Item updated successfully', 'success');
            } else {
              notification('Error updating item - duplicate item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.id,
          supplierIdentification: values.supplier_identification || '',
          typeOfSupplier: values.type_of_supplier || '',
          companyName: values.company_name || '',
          commercialName: values.commercial_name || '',
          RUC: values.ruc || '',
          contactPerson: values.contact_person || '',
          email: values.email || '',
          telephoneNumber: values.telephone_number || '',
          cellphoneNumber: values.telephone_number || '',
        }}
      >
        {({ values, touched, handleBlur, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Supplier Identification"
                name="supplierIdentification"
                type="text"
                value={values.supplierIdentification}
              />
              <SelectItems
                options={['internal', 'external']}
                label="Type of Supplier"
                name="typeOfSupplier"
                handleChange={handleChange}
                onBlur={handleBlur}
                errorMessageEnabled
                defaultValue={values.typeOfSupplier}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Company name"
                name="companyName"
                type="text"
                value={values.companyName}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Commercial Name"
                name="commercialName"
                type="text"
                value={values.commercialName}
              />
              <TextField label="RUC" name="RUC" type="text" value={values.RUC} />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Contact person"
                name="contactPerson"
                type="text"
                value={values.contactPerson}
              />
              <TextField label="Email" name="email" type="text" value={values.email} />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Telephone number"
                name="telephoneNumber"
                type="text"
                value={values.telephoneNumber}
              />
              <TextField
                label="Cellphone number"
                name="cellphoneNumber"
                type="text"
                value={values.cellphoneNumber}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Supplier;
