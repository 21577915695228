import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';
import Urls from '../Urls';
import axios from 'axios';
import MultiSelectListBox from 'react-multiselect-listbox';

const UserGroupPermissions = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [listado, setListado] = useState([]);
  const [userId, setUserId] = useState([]);
  const [listadoBox2, setListadoBox2] = useState([]);
  const [selectedTwo, setSelectedTwo] = useState([]);
  const [token] = useState(localStorage.getItem('accessToken'));
  const urlGroupsPermissions = Urls.group_permission;
  const urlUserGroupsPermissions = Urls.usergroup_permission;

  const groupPermissionValidate = Yup.object({
    email: Yup.string().required('Required'),
  });

  useEffect(() => {
    const getGroups = async (inputValue, loadedOptions) => {
      const query = `${urlGroupsPermissions}?page_size=${0}`;
      let optionsResults = [];
      await axios
        .get(query, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            const results = res.data;
            results.forEach((result) => {
              optionsResults.push({
                value: result.id,
                desc: result.name,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setListado(optionsResults);
      return optionsResults;
    };
    getGroups();
  }, []);

  useEffect(() => {
    if (onEdited !== undefined) {
      const getPermissions = async (inputValue, loadedOptions) => {
        const query = `${urlUserGroupsPermissions}?page_size=${0}`;
        let arreglo = [0];
        await axios
          .get(query, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data) {
              const normaliceResponse = () => {
                let result = [];
                Object.keys(res.data).forEach((key) => {
                  result.push(res.data[key]);
                });

                return result;
              };
              arreglo = normaliceResponse()
                .filter((element) => element.email === userId)
                .map((element) => element.groups);
              arreglo = arreglo[0];
            }
          })
          .catch((err) => {
            console.log(err);
          });

        setListadoBox2(arreglo || []);
      };
      getPermissions();
    }
  }, [userId]);

  useEffect(() => {
    setSelectedTwo(listadoBox2);
  }, [listadoBox2]);

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={groupPermissionValidate}
        onSubmit={async (data) => {
          const processedValues = {
            email: data.email,
            groups: selectedTwo,
          };
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          if (data.id == null) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'UserGroupPermission',
              code: data.email,
            });
            if (created.statusText !== 'Created') {
              notification('Error creating item - duplicate item', 'error');
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'UserGroupPermission',
              code: data.email,
            });
            if (updated.statusText == 'OK') {
              onEdited(processedValues);
              setAddForm(false);
              notification('Item updated successfully', 'success');
            } else {
              notification('Error updating item - duplicate item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.id,
          email: values.email || '',
          groups: values.groups || '',
        }}
      >
        {({ values, touched, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="User"
                name="email"
                type="text"
                value={values.email}
                onKeyUp={setUserId(values.email)}
              />
            </Form.Row>
            <Form.Row>
              <MultiSelectListBox
                sortable={true}
                options={listado}
                textField="desc"
                valueField="value"
                value={selectedTwo}
                rowHeight={25}
                onSelect={({ item, sortedList }) => {
                  setSelectedTwo(sortedList.map((i) => i.value));
                }}
                onRemove={({ item }) => {
                  setSelectedTwo([...selectedTwo.filter((i) => i !== item.value)]);
                }}
                onSelectAll={(selectedItems) => {
                  const selected = [...selectedTwo, ...selectedItems.map((item) => item.value)];
                  setSelectedTwo(selected);
                }}
                onRemoveAll={() => setSelectedTwo([])}
                onSort={({ sortedList }) => setSelectedTwo([...sortedList.map((i) => i.value)])}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserGroupPermissions;
