import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import TableModal from '../TableModal/TableModal';
import apiService from '../apiService';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import Urls from '../Urls';
import { setIsModalPaginationAction } from '../../redux/actions/paginationAction';
import { notification } from '../AlertMessage/ToastifyAlert';

const ItemStorageCenterRelation = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [valuesToEdit, setValuesToEdit] = useState({});
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [showItemModal, setShowItemModal] = useState(false);
  const [showCenterCodeModal, setShowCenterCodeModal] = useState(false);
  const [itemDefaultValues, setItemDefaultValues] = useState({});
  const [storageCenterCodeDefaultValues, setStorageCenterCodeDefaultValues] = useState({});
  const [itemFromModal, setItemFromModal] = useState(false);
  const [storageCenterCodeFromModal, setStorageCenterCodeFromModal] = useState(false);
  const [itemCodeTransformed, setItemCodeTransformed] = useState();
  const [storageCenterCodeTransformed, setStorageCenterCodeTransformed] = useState();
  const [descriptions, setDescriptions] = useState({
    itemDescription: '',
    storageCenterDescription: '',
  });
  const [itemData, setItemData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [storageCenterData, setStorageCenterData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const itemStorageCenterRelationValidate = Yup.object({
    itemReferenceCode: Yup.string().required('Required'),
    storageCenterCode: Yup.string().required('Required'),
  });

  //pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };

  const itemColumnToTransform = [
    {
      name: 'item_category',
      url: 'itemCategory',
      field: 'item_category_code',
    },
    {
      name: 'label',
      url: 'label',
      field: 'label_code',
    },
    {
      name: 'consolidation_level_N0',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N1',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N2',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N3',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N4',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
  ];

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
        storage_center_code: storageCenterCodeTransformed,
        item_reference_code: itemCodeTransformed,
      });
    }
  }, [added]);

  useEffect(async () => {
    const resItem = await apiService.getData(Urls.item, 'reference_code');
    const resStorageCenter = await apiService.getData(Urls.storageCenter, 'storage_center_code');
    setItemData({
      headers: resItem.headersList,
      tableData: resItem.data,
      options: resItem.results,
    });
    setStorageCenterData({
      headers: resStorageCenter.headersList,
      tableData: resStorageCenter.data,
      options: resStorageCenter.results,
    });
  }, []);

  const handleCloseItemModal = () => {
    setIsModalPagination();
    setShowItemModal(false);
  };
  const handleCloseCenterCodeModal = () => {
    setIsModalPagination();
    setShowCenterCodeModal(false);
  };

  useEffect(() => {
    itemData.options.forEach((option) => {
      if (option.value === values.item_reference_code) {
        setDescriptions(
          option
            ? { ...descriptions, itemDescription: option.description }
            : { ...descriptions, itemDescription: '' }
        );
      }
    });
  }, [itemData]);

  useEffect(() => {
    storageCenterData.options.forEach((option) => {
      if (option.value === values.storage_center_code) {
        setDescriptions(
          option
            ? { ...descriptions, storageCenterDescription: option.description }
            : { ...descriptions, storageCenterDescription: '' }
        );
      }
    });
  }, [storageCenterData]);

  const handleItemConfirm = (selected, title, setFieldValue) => {
    setItemDefaultValues({
      value: selected.id,
      label: selected.reference_code,
      description: selected.item_description,
    });
    setValuesToEdit({
      ...valuesToEdit,
      item_reference_code: selected.id,
    });
    setFieldValue('itemReferenceCode', selected.id);
    setItemCodeTransformed(selected.reference_code);
    setShowItemModal(false);
    setItemFromModal(true);
  };

  const handleStorageCenterCodeConfirm = (selected, title, setFieldValue) => {
    setStorageCenterCodeDefaultValues({
      value: selected.id,
      label: selected.storage_center_code,
      description: selected.description,
    });
    setValuesToEdit({
      ...valuesToEdit,
      storage_center_code: selected.id,
    });
    setFieldValue('storageCenterCode', selected.id);
    setStorageCenterCodeTransformed(selected.storage_center_code);
    setShowCenterCodeModal(false);
    setStorageCenterCodeFromModal(true);
  };

  return (
    <Formik
      validateOnChange
      validationSchema={itemStorageCenterRelationValidate}
      onSubmit={async (data) => {
        const processedValues = {
          storage_center_code: data.storageCenterCode,
          item_reference_code: data.itemReferenceCode,
        };
        setProcessedFormValues(processedValues);
        setOriginalFormValues(data);
        if (data.id == null) {
          const created = await handleAPIRequest(processedValues, {
            entity: 'Item storage center relation',
            code: data.itemReferenceCode,
          });
          if (created.statusText !== 'Created') {
            notification('Error creating item - duplicate item', 'error');
          }
        } else {
          const editedValues = {
            storage_center_code: storageCenterCodeTransformed || data.storageCenterCode,
            item_reference_code: itemCodeTransformed || data.itemReferenceCode,
          };
          const updated = await handleAPIRequestUpdate(data.id, valuesToEdit, {
            entity: 'Item storage center relation',
            code: data.itemReferenceCode,
          });
          if (updated.statusText == 'OK') {
            onEdited(editedValues);
            setAddForm(false);
            notification('Item updated successfully', 'success');
          } else {
            notification('Error updating item - duplicate item', 'error');
          }
        }
      }}
      initialValues={{
        id: values.id,
        itemReferenceCode: values.item_reference_code || '',
        storageCenterCode: values.storage_center_code || '',
      }}
    >
      {({ setFieldValue, values, handleSubmit }) => (
        <>
          {showItemModal ? (
            <TableModal
              url={Urls.item}
              title="Item Code"
              show={showItemModal}
              handleClose={handleCloseItemModal}
              formName={'item'}
              columnToTransform={itemColumnToTransform}
              handleConfirmation={handleItemConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showCenterCodeModal ? (
            <TableModal
              url={Urls.storageCenter}
              title="Storage Center Code"
              show={showCenterCodeModal}
              handleClose={handleCloseCenterCodeModal}
              formName={'storageCenter'}
              columnToTransform={[]}
              handleConfirmation={handleStorageCenterCodeConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              {itemFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      item_reference_code: option ? option.id : '',
                    });
                    setFieldValue('itemReferenceCode', option ? option.id : '');
                    setItemCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, itemDescription: option.description }
                        : { itemDescription: '' }
                    );
                    setItemFromModal(false);
                  }}
                  name="itemReferenceCode"
                  label="Item Reference Code"
                  options={itemData.options}
                  setShow={setShowItemModal}
                  defaultValue={itemDefaultValues}
                  value={itemDefaultValues}
                  description={itemDefaultValues.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      item_reference_code: option ? option.id : '',
                    });
                    setFieldValue('itemReferenceCode', option ? option.id : '');
                    setItemCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, itemDescription: option.description }
                        : { itemDescription: '' }
                    );
                  }}
                  name="itemReferenceCode"
                  label="Item Reference Code"
                  options={itemData.options}
                  setShow={setShowItemModal}
                  defaultValue={{
                    value: values.itemReferenceCode,
                    label: values.itemReferenceCode,
                  }}
                  description={descriptions.itemDescription}
                />
              )}
            </Form.Row>
            <Form.Row>
              {storageCenterCodeFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      storage_center_code: option ? option.id : '',
                    });
                    setFieldValue('storageCenterCode', option ? option.id : '');
                    setStorageCenterCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            storageCenterDescription: option.description,
                          }
                        : { storageCenterDescription: '' }
                    );
                    setStorageCenterCodeFromModal(false);
                  }}
                  name="storageCenterCode"
                  label="Storage Center Code"
                  options={storageCenterData.options}
                  setShow={setShowCenterCodeModal}
                  defaultValue={storageCenterCodeDefaultValues}
                  value={storageCenterCodeDefaultValues}
                  description={storageCenterCodeDefaultValues.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      storage_center_code: option ? option.id : '',
                    });
                    setFieldValue('storageCenterCode', option ? option.id : '');
                    setStorageCenterCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            storageCenterDescription: option.description,
                          }
                        : { storageCenterDescription: '' }
                    );
                  }}
                  name="storageCenterCode"
                  label="Storage Center Code"
                  options={storageCenterData.options}
                  setShow={setShowCenterCodeModal}
                  defaultValue={{
                    value: values.storageCenterCode,
                    label: values.storageCenterCode,
                  }}
                  description={descriptions.storageCenterDescription}
                />
              )}
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ItemStorageCenterRelation;
