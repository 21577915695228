import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setAvailabilityWorkCenterList } from '../../redux/slices/availabilitySlice';
import { setPerformanceWorkCenterList } from '../../redux/slices/performanceSlice';
import { setQualityWorkCenterList } from '../../redux/slices/qualitySlice';
//import Table from '../TableSection/ProductTableSection';
import Table from '../Table/Table';
import Urls from '../Urls';
import { showSelectedWorkCenters } from '../../redux/slices/tableSlice';

const WorkCenterFilter = ({ setAddForm, formName }) => {
  const dispatch = useDispatch();
  const selectedWorkCenters = useSelector(showSelectedWorkCenters);

  const handleApplyFilter = async () => {
    setAddForm(false);
    switch (formName) {
      case 'workCenterFilterInAvailability':
        dispatch(setAvailabilityWorkCenterList(selectedWorkCenters));
        break;
      case 'workCenterFilterInPerformance':
        dispatch(setPerformanceWorkCenterList(selectedWorkCenters));
        break;
      case 'workCenterFilterInQuality':
        dispatch(setQualityWorkCenterList(selectedWorkCenters));
        break;
    }
  };

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={{
          checked: [],
        }}
      >
        {({ handleChange }) => (
          <Form>
            <Table url={Urls.workCenter} name={'workCenter'} />
            <Button className="fpb-btn mt-3" onClick={handleApplyFilter}>
              Apply
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WorkCenterFilter;
