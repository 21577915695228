import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';

const Adjustment = ({
  onAdded,
  transformCode,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  edited,
  setAddForm,
  onEdited,
}) => {
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [editedFormValues, setEditedFormValues] = useState({});

  const costTypeValidate = Yup.object({
    adjustmentCode: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string(),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(() => {
    if (edited == true) {
      onEdited(editedFormValues);
      setAddForm(false);
    }
  }, [edited]);

  useEffect(() => {
    console.log('VALUES', values);
    console.log('Form values', originalFormValues);
  }, [values, originalFormValues]);

  return (
    <>
      <Formik
        validateOnChange
        validationSchema={costTypeValidate}
        onSubmit={async (data) => {
          const processedValues = {
            adjustment_code: data.adjustmentCode,
            description: data.description,
          };
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          if (data.id == null) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Adjustment',
              code: data.adjustmentCode,
            });
            if (created.statusText !== 'Created') {
              notification('Error creating item - duplicate item', 'error');
            }
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Adjustment',
              code: data.adjustmentCode,
            });
            if (updated.statusText == 'OK') {
              onEdited(processedValues);
              setAddForm(false);
              notification('Item updated successfully', 'success');
            } else {
              notification('Error updating item - duplicate item', 'error');
            }
          }
        }}
        initialValues={{
          id: values.id,
          adjustmentCode: values.adjustment_code || '',
          description: values.description || '',
        }}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <TextField
                label="Adjustment code"
                name="adjustmentCode"
                type="text"
                value={values.adjustmentCode}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
              />
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Adjustment;
