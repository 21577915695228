import React, { useEffect } from 'react';
import { FaSuitcase, FaWarehouse, FaTruckLoading, FaFileUpload } from 'react-icons/fa';
import LogoFPB from '../CoreComponents/LogoFPB/LogoFPB';
import DashboardCard from '../CoreComponents/DashboardCard/DashboardCard';
import apiService from '../apiService';
import Urls from '../Urls';

const Home = () => {
  useEffect(async () => {
    apiService
      .getData(Urls.item, 'reference_code')
      .then()
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <h1>Inventory Home</h1>
      <LogoFPB logoWidth="450px" />
      <div className="d-flex flex-wrap justify-content-start fpb-cards">
        <DashboardCard
          title="Item"
          description="Items table"
          icon={<FaSuitcase />}
          path="/item"
          color="blueColor"
        />
        <DashboardCard
          title="Storage Center"
          description="Storage center tables"
          icon={<FaWarehouse />}
          path="/storage_center"
          color="pinkColor"
        />
        <DashboardCard
          title="Suppliers"
          description="Suppliers table"
          icon={<FaTruckLoading />}
          path="/supplier"
          color="mainColor"
        />
      </div>
    </div>
  );
};

export default Home;
