import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { showExportData, exportDataLoadingState } from '../../redux/slices/exportSlice';

const ExportButton = ({ formName, fileName, handleExport }) => {
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [exportStart, setExportStart] = useState(false);
  const data = useSelector(showExportData);
  const loading = useSelector(exportDataLoadingState);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const formatDate = (date) => {
    let splitDate = [];
    splitDate = date.split('T');
    splitDate[1] = splitDate[1].split('.', 1);
    let newDate = '';
    return newDate.concat(splitDate[0], ', ', splitDate[1]);
  };

  const exportExcelData = (excelData) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const header_info = [
      ['Wiibiq'],
      ['Client: ' + userProfile.company],
      ['Table: ' + fileName],
      ['User: ' + userProfile.username],
      ['Download Date: ' + new Date()],
    ];
    const ws_info = XLSX.utils.aoa_to_sheet(header_info);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    XLSX.utils.book_append_sheet(wb, ws_info, 'information');
    ws['!cols'] = [{ wch: 10 }];
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const exportData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(exportData, fileName + fileExtension);
  };

  useEffect(() => {
    if (data !== undefined && exportStart) {
      if (data.length > 0) {
        let excelData = [];
        switch (formName) {
          case 'manualReport':
            excelData = data.map(
              ({ id, manual_table, product_per_production_order, quality_report, ...rest }) => {
                return rest;
              }
            );
            break;
          default:
            excelData = data;
        }
        exportExcelData(excelData);
        setExportStart(false);
      }
    }
  }, [data]);

  return (
    <Button
      className="fpb-btn-inverse mx-2"
      onClick={() => {
        setExportStart(true);
        handleExport();
      }}
      disabled={loading}
    >
      {loading ? <i>Exporting...</i> : `Export`}
    </Button>
  );
};

export default ExportButton;
