import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  hideProgressBar: false,
  pauseOnFocusLoss: false,
};
export const notification = (message, type) => {
  switch (type) {
    case 'info':
      return toast.info(
        <div className="ml-2 mr-2">
          <p>{message}</p>
        </div>,
        options
      );
    case 'success':
      return toast.success(
        <div className="ml-2 mr-2">
          <p>{message}</p>
        </div>,
        { ...options, icon: '🚀' }
      );
    case 'error':
      return toast.error(
        <div className="ml-2 mr-2">
          <p>{message}</p>
        </div>,
        options
      );
    case 'warning':
      return toast.warning(
        <div className="ml-2 mr-2">
          <p>{message}</p>
        </div>,
        options
      );
    default:
      return toast.warning(
        <div className="ml-2 mr-2">
          <p>Toast not defined...</p>
        </div>
      );
  }
};
