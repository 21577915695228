import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { useSelector } from 'react-redux';
import SubMenu from './SubMenu';
import {
  IndustrialSidebarData,
  InventorySidebarData,
  AuthSidebarData,
} from '../../components/Sidebar/SidebarData';
import logo from './logo_white.webp';

const Nav = styled.div`
  background: #15171c;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  width: ${({ sidebarFull }) => (sidebarFull ? '355px' : '160px')};
  transition: 0.5s;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #49158b;
  scrollbar-color: #aaaaaa #dfdfdf;
  overflow-y: scroll;
  width: ${({ sidebarFull }) => (sidebarFull ? '300px' : '110px')};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 55px;
  transition: 0.5s;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const sidebarMenus = {
  industrial: IndustrialSidebarData,
  inventory: InventorySidebarData,
  administration: AuthSidebarData,
  default: InventorySidebarData,
};

const Sidebar = () => {
  const [sidebarFull, setSidebarFull] = useState(true);
  const showSidebar = () => setSidebarFull(!sidebarFull);
  useSelector((state) => state.menu.menu);

  const getSidebarData = () => {
    const selectedMenu =
      localStorage.getItem('menu') !== null && localStorage.getItem('menu') !== 'undefined'
        ? localStorage.getItem('menu')
        : 'default';
    const sidebarData = sidebarMenus[selectedMenu.toLowerCase()];
    return sidebarData;
  };
  const sidebarData = getSidebarData();

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav className="sidebarTest" sidebarFull={sidebarFull}>
          <SidebarNav sidebarFull={sidebarFull}>
            <SidebarWrap>
              <NavIcon to="#">
                <FaIcons.FaBars onClick={showSidebar} style={{ width: '0.5em' }} />
                {sidebarFull && <img src={logo} width="50%" className="pl-3" alt="FPBooster" />}
              </NavIcon>
              {sidebarData?.map((item, index) => (
                <SubMenu item={item} key={index} sidebarFull={sidebarFull} />
              ))}
            </SidebarWrap>
          </SidebarNav>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
