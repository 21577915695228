import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import apiService from '../apiService';
import Urls from '../Urls';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import getUniqueValues from '../../utils/getUniqueValues';
import { format, isValid, parseISO } from 'date-fns';
import { setErrorForm } from '../../redux/slices/productPerProductionOrderSlice';

const configureWorkCenterOptions = (workCenterPerProcess, workCentersAllowed) => {
  const options = workCenterPerProcess
    .filter((el) => workCentersAllowed.includes(el.work_center))
    .map((el) => ({
      description: el.work_center_description,
      value: el.work_center,
      label: el.work_center,
      id: el.id,
    }));

  const uniqueOptions = getUniqueValues(options);
  return uniqueOptions;
};

const configureVariableOptions = (workCenterPerProcess) => {
  const options = workCenterPerProcess.map((el) => ({
    description: el.output_dimensional_unit_description,
    value: el.output_dimensional_unit,
    label: el.output_dimensional_unit,
    id: el.id,
  }));

  const uniqueOptions = getUniqueValues(options);
  return uniqueOptions;
};

const Step = ({
  index,
  onEdited,
  step,
  stepValues,
  formValues,
  setFormValues,
  calculationVariableMap,
  statusProductionOrder,
}) => {
  const values = onEdited ? stepValues?.[index] : stepValues;
  const [workCenter, setWorkCenter] = useState(values?.work_center || '');
  const [amount1, setAmount1] = useState(values?.amount_1 || 0);
  const [amount1Error, setAmount1Error] = useState(false);
  const [amount2, setAmount2] = useState(values?.amount_2);
  const [amount3, setAmount3] = useState(values?.amount_3);
  const [dimensionalUnit1, setDimensionalUnit1] = useState(values?.dimensional_unit_1);
  const [dimensionalUnit2, setDimensionalUnit2] = useState(values?.dimensional_unit_2);
  const [dimensionalUnit3, setDimensionalUnit3] = useState(values?.dimensional_unit_3);
  const [responsible, setResponsible] = useState(values?.responsible || '');
  const [dispatchDate, setDispatchDate] = useState(
    typeof values?.dispatch_date !== undefined ? values?.dispatch_date : null
  );

  const [viewMore, setViewMore] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [variableData, setVariableData] = useState({});
  const [variableOptionsSize, setVariableOptionsSize] = useState(0);
  const [workCenterData, setWorkCenterData] = useState({});
  const [workCenterPerProcess, setWorkCenterPerProcess] = useState([]);
  const [descriptions, setDescriptions] = useState({
    workCenterCategoryDescription: '',
    dimensionalUnit1Description: '',
  });
  const workCentersAllowed = useSelector((state) => state.productionOrders.workCentersAllowed);
  const isLast = index === 0;

  const dispatch = useDispatch();

  //Validation for error boundary
  if (onEdited && values?.id == null) throw new Error('Missing process');

  useEffect(() => {
    const getWorkCenterPerProcessInfo = async () => {
      const workCenterCapacityFiltered = await apiService.getDataWithFilters(
        Urls.workCenterPerProcess,
        [`process__code=${step.process}`]
      );
      const workCenterOptions = configureWorkCenterOptions(
        workCenterCapacityFiltered,
        workCentersAllowed
      );
      const variableOptions = configureVariableOptions(workCenterCapacityFiltered);
      setVariableOptionsSize(variableOptions.length);
      setWorkCenterPerProcess(workCenterCapacityFiltered);
      setWorkCenterData({ options: workCenterOptions });
      setVariableData({ options: variableOptions });
    };

    getWorkCenterPerProcessInfo();
  }, []);

  useEffect(() => {
    const findDimensionalUnit = () => {
      const processCalculationVariable = workCenterPerProcess.find(
        (config) => calculationVariableMap[config.variable]?.isCalculation
      );
      setDimensionalUnit1(processCalculationVariable?.output_dimensional_unit);
    };
    workCenterPerProcess && calculationVariableMap && findDimensionalUnit();
  }, [workCenterPerProcess, calculationVariableMap]);

  const [stepStartDate, setStepStartDate] = useState(null);
  const [stepEndDate, setStepEndDate] = useState(null);

  useEffect(() => {
    const startDate = values?.start_date;
    const endDate = values?.end_date;
    const parsedStartDate = parseISO(startDate);
    const parseEndDate = parseISO(endDate);
    isValid(parsedStartDate) && setStepStartDate(format(parsedStartDate, 'dd/MM/yyyy HH:mm'));
    isValid(parseEndDate) && setStepEndDate(format(parseEndDate, 'dd/MM/yyyy HH:mm'));
    if (statusProductionOrder === 'closed') {
      setIsConfirmed(true);
    } else {
      setIsConfirmed(false);
    }
  }, [values]);

  const DefaultDate = () => <span style={{ color: '#aaa' }}>dd/mm/aaaa hh:mm</span>;

  useEffect(() => {
    const newValues = {};
    const key = step.process_order;
    const product = step.product;
    const process = step.process;
    newValues[key] = {
      id: values?.id || null,
      product: product,
      process: process,
      dimensionalUnit1: dimensionalUnit1,
      dimensionalUnit2: dimensionalUnit2,
      dimensionalUnit3: dimensionalUnit3,
      amount1: amount1,
      amount2: amount2,
      amount3: amount3,
      workCenter: workCenter,
      responsible: responsible,
      dispatchDate: dispatchDate,
    };
    setFormValues({ ...formValues, ...newValues });
    //Validation of planned amount depending on the final process of the route
    if ((isLast && amount1 < 1) || amount1 === null || (!isLast && amount1 < 0)) {
      setAmount1Error(true);
      dispatch(setErrorForm(true));
    } else {
      setAmount1Error(false);
      dispatch(setErrorForm(false));
    }
  }, [
    workCenter,
    dimensionalUnit1,
    dimensionalUnit2,
    dimensionalUnit3,
    amount1,
    amount2,
    amount3,
    responsible,
    dispatchDate,
  ]);

  useEffect(() => {
    const flag = false;
    if (values?.length > 0) {
      values.map((step, idx) => {
        if (
          (idx === 0 && step.amount_1 < 1) ||
          step.amount_1 === null ||
          (idx !== 0 && step.amount_1 < 0)
        ) {
          flag = true;
        }
      });
      if (flag) {
        setAmount1Error(true);
        dispatch(setErrorForm(true));
      } else {
        setAmount1Error(false);
        dispatch(setErrorForm(false));
      }
    }
  }, [values]);

  return (
    <>
      <Form>
        <Form.Row className="ml-2">
          <Button className="control-button text-center ml-3 big-title mt-1">
            {step.process_order}
          </Button>
          <div>
            <div>
              <span className="big-title pl-4">{step.process_description}</span>
              <b className="status mx-3 px-2" style={{ letterSpacing: '1px', fontSize: '14px' }}>
                {values?.production_status?.replace('_', ' ')?.toUpperCase()}
              </b>
            </div>
            <div className="d-flex mt-0 pt-0 ml-4">
              {stepStartDate ? <span>{`${stepStartDate}`} </span> : <DefaultDate />}
              <span className="px-2 mx-0 text-muted">-</span>
              {stepEndDate ? <span>{`${stepEndDate}`}</span> : <DefaultDate />}
            </div>
          </div>
        </Form.Row>

        <div className={`${step.process_order > 1 ? 'stepper' : ''} px-5 ml-5 pt-2 pb-3`}>
          <Form.Row>
            <CustomSelect
              onChange={(option) => {
                setWorkCenter(option?.value || null);
                setDescriptions(
                  option
                    ? { ...descriptions, workCenterDescription: option.description }
                    : { ...descriptions, workCenterDescription: '' }
                );
              }}
              label="Work Center"
              name="workCenter"
              options={workCenterData.options}
              defaultValue={{
                value: values?.work_center,
                label: values?.work_center,
              }}
              description={descriptions.workCenterDescription}
              disabled={isConfirmed}
            />
            <div>
              <TextField
                label="Planned quantity"
                name="amount1"
                type="number"
                step="any"
                min={isLast ? '1' : '0'}
                errorMessage={amount1Error ? 'Must be greater than 0' : ''}
                onChange={(option) => {
                  setAmount1(option.target.value || values?.amount_1 || '');
                }}
                defaultValue={amount1}
                disabled={isConfirmed}
              />
            </div>
            <TextField
              label="Dimensional Unit"
              name="dimensionalUnit1"
              description={descriptions.dimensionalUnit1Description}
              onChange={(option) => {
                setDimensionalUnit1(option.target.value || '');
              }}
              defaultValue={dimensionalUnit1}
              value={dimensionalUnit1}
              disabled
            />
          </Form.Row>
          <Form.Row>
            <TextField
              label="Responsible"
              name="responsible"
              type="string"
              defaultValue={values?.responsible}
              disabled={isConfirmed}
              onChange={(option) => {
                setResponsible(option.target.value || values?.responsible);
              }}
            />
            <TextField
              label="Dispatch date"
              name="dispatch_date"
              type="datetime-local"
              defaultValue={values?.dispatch_date?.toString().substring(0, 16)}
              disabled={isConfirmed}
              onChange={(option) => {
                setDispatchDate(option.target.value || values?.dispatch_date);
              }}
            />
            {variableOptionsSize > 1 && (
              <div className="d-flex align-items-center justify-content-center ">
                <Button
                  className="more px-4 mx-1 align-items-center"
                  onClick={() => setViewMore(!viewMore)}
                >
                  {viewMore ? 'View less ' : 'View More '}
                  {viewMore ? <IoMdArrowDropup size={18} /> : <IoMdArrowDropdown size={18} />}
                </Button>
              </div>
            )}
          </Form.Row>
          {variableOptionsSize === 2 && viewMore === true && (
            <Form.Row>
              <span className="mr-4 d-flex align-items-center pb-4">
                <b style={{ fontSize: 'large' }}>Optional variable</b>
              </span>
              <TextField
                label="Value"
                name="amount2"
                type="number"
                step="any"
                onChange={(option) => {
                  setAmount2(option?.target.value || values?.amount_2);
                }}
                defaultValue={values?.amount_2}
                disabled={isConfirmed}
              />
              <CustomSelect
                onChange={(option) => {
                  setDimensionalUnit2(option?.value || values?.dimensional_unit_2);
                  setDescriptions(
                    option
                      ? { ...descriptions, dimensionalUnit2Description: option.description }
                      : { ...descriptions, dimensionalUnit2Description: '' }
                  );
                }}
                label="Dimensional Unit"
                name="dimensionalUnit2"
                options={variableData.options.filter((el) => el.value !== dimensionalUnit1)}
                defaultValue={{
                  value: values?.dimensional_unit_2,
                  label: values?.dimensional_unit_2,
                }}
                description={descriptions.dimensionalUnit2Description}
                disabled={isConfirmed}
              />
            </Form.Row>
          )}
          {variableOptionsSize === 3 && viewMore === true && (
            <Form.Row>
              <span className="mr-4 d-flex align-items-center pb-4">
                <b style={{ fontSize: 'large' }}>Optional variable</b>
              </span>
              <TextField
                label="Value"
                name="amount3"
                type="number"
                step="any"
                onChange={(option) => {
                  setAmount3(option?.target.value || values?.amount_3);
                }}
                defaultValue={values?.amount_3}
                disabled={isConfirmed}
              />
              <CustomSelect
                onChange={(option) => {
                  setDimensionalUnit3(option?.value || values?.dimensional_unit_3);
                  setDescriptions(
                    option
                      ? { ...descriptions, dimensionalUnit3Description: option.description }
                      : { ...descriptions, dimensionalUnit3Description: '' }
                  );
                }}
                label="Dimensional Unit"
                name="dimensionalUnit3"
                options={variableData.options.filter((el) => el.value !== dimensionalUnit1)}
                defaultValue={{
                  value: values?.dimensional_unit_3,
                  label: values?.dimensional_unit_3,
                }}
                description={descriptions.dimensionalUnit3Description}
                disabled={isConfirmed}
              />
            </Form.Row>
          )}
        </div>
      </Form>
    </>
  );
};

export default Step;
