import axios from 'axios';

function getTransactionTableData(
  queryString,
  lastQuery,
  token,
  setTotalCount,
  setHeaders,
  setTableData,
  setLastQuery,
  setIsLoading,
  notification,
  setButtonDisabled,
  setIsNotFound
) {
  if (queryString !== lastQuery) {
    axios
      .get(queryString, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        const results = res.data.results;
        setTotalCount(res.data.count);
        if (results.length > 0) {
          const headersList = Object.keys(results[0]).filter((item) => {
            let returnColumn;
            item !== 'fk_info' &&
            item !== 'id' &&
            item !== 'created' &&
            item !== 'updated' &&
            item !== 'item_batch_relation_code'
              ? (returnColumn = true)
              : (returnColumn = false);
            return returnColumn;
          });
          const tableInformation = results.map((row) => {
            const storageCenterModified = row.fk_info.storage_center_description;
            const storageLocationModified = row.fk_info.storage_location_description;
            const dimensionalUnitModified = row.fk_info.dimensional_unit_description;
            const entryOrderModified = row.fk_info.entry_order_code;
            const dispatchOrderModified = row.fk_info.dispatch_order_code;
            const storageCenterDestinationModified =
              row.fk_info.storage_center_destination_description;
            const storageLocationDestinationModified =
              row.fk_info.storage_location_destination_description;
            const adjustmentModified = row.fk_info.adjustment_code;
            const n0CostRounded = parseFloat(row.n0_cost).toFixed(2);

            const result = {
              ...row,
              n0_cost: n0CostRounded,
              dimensional_unit_code: dimensionalUnitModified,
              storage_location: storageLocationModified,
              entry_order_code: entryOrderModified,
              dispatch_order_code: dispatchOrderModified,
              storage_center_code: storageCenterModified,
              storage_center_code_destination: storageCenterDestinationModified,
              storage_location_destination: storageLocationDestinationModified,
              adjustment_code: adjustmentModified,
            };
            return result;
          });
          setHeaders(headersList);
          setTableData(tableInformation);
          setIsLoading ? setIsLoading(false) : null;
          setIsNotFound ? setIsNotFound(false) : null;
          setButtonDisabled ? setButtonDisabled(false) : null;
          notification ? notification('Transaction data loaded successfully!', 'success') : null;
        } else {
          setTableData([]);
          setHeaders([]);
          setIsNotFound ? setIsNotFound(true) : null;
          setButtonDisabled ? setButtonDisabled(true) : null;
          notification ? notification('There is no data to show', 'error') : null;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  setLastQuery(queryString);
}

export default getTransactionTableData;
