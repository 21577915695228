import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { showOee } from '../../../redux/slices/oeeSlice';

const OEEChart = () => {
  //redux
  const data = useSelector(showOee);
  const [oee, setOee] = useState(0);
  const [background, setBackground] = useState('');

  useEffect(() => {
    const oee_percentaje = parseFloat(data?.total_oee || 0);
    setOee(oee_percentaje.toFixed(2));
  }, [data]);

  useEffect(() => {
    if (oee < 65) {
      setBackground('#E2482D');
    } else if (oee < 75) {
      setBackground('#DDDF0D');
    } else if (oee < 85) {
      setBackground('#77DD77');
    } else if (oee < 95) {
      setBackground('#71BC78');
    } else {
      setBackground('#009D71');
    }
  }, [oee]);

  const options = {
    series: [oee || ''],
    options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      colors: [background],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
              name: {
                offsetY: -10,
                show: true,
                color: background,
                fontSize: '17px',
              },
            },
            value: {
              formatter: function (val) {
                return val + '%';
              },
              color: background,
              fontSize: '28px',
              show: true,
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      labels: ['Average Results'],
    },
  };

  return (
    <>
      <Card className="p-3">
        <Chart
          height={300}
          weidht={300}
          options={JSON.parse(JSON.stringify(options.options))}
          series={JSON.parse(JSON.stringify(options.series))}
          type="radialBar"
        />
        <h5 className="text-center">OEE</h5>
      </Card>
    </>
  );
};

export default OEEChart;
