import React from 'react';
import { Badge } from 'react-bootstrap';

const InventoryBanner = ({ total, itemUnit }) => {
  const Style = {
    unit: { fontSize: '14px' },
  };
  return (
    <h3 className="pb-1 mb-0">
      <Badge className=" pb-1 pt-2" style={{ backgroundColor: '#ff3c77', color: 'white' }}>
        <span>
          {!itemUnit && '$'}
          {total}
          {itemUnit && ' units'}
        </span>
        {itemUnit && <span style={Style.unit}> ({itemUnit})</span>}
      </Badge>
    </h3>
  );
};

export default InventoryBanner;
