import axios from 'axios';
import Urls from './Urls';
import { notification } from './AlertMessage/ToastifyAlert';

const refreshUrl = Urls.refresh;

const axiosInterceptor = axios.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;
    let message = '';
    let u = 0;

    if (error.response) {
      if (error.response.status === 403) {
        unauthorizedOperation();
      } else if (error.response.status === 401) {
        message = 'Session expired. Please login again';
        localStorage.clear();
        window.location.replace('/');
      } else if (
        error.response.status === 401 &&
        originalRequest &&
        !originalRequest.__isRetryRequest
      ) {
        const refreshToken = localStorage.getItem('refreshToken');
        originalRequest.__isRetryRequest = true;
        try {
          const response = await axios.post(refreshUrl, {
            refresh: refreshToken,
          });
          localStorage.setItem('accessToken', response.data.access);
          localStorage.setItem('refreshToken', response.data.refresh);
          originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
          return axios(originalRequest);
        } catch (error) {
          localStorage.clear();
          window.location.replace('/');
        }
      } else if (error.response.data.error) message = error.response.data.error;
    } else {
      if (error.code === 'ECONNABORTED') {
        message = 'Timeout error, action was aborted';
        notification(message, 'warning');
      } else {
        message = 'Oops! Something went wrong';
      }
    }

    return Promise.reject({ error: message });
  }
);

export default axiosInterceptor;

var unauthorized = 0;
const unauthorizedOperation = () => {
  if (unauthorized == 0) {
    notification('You dont have permission to perform this action', 'warning');
  }
  unauthorized += 1;
};
