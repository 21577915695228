import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { showProducedUnits } from '../../../redux/slices/producedUnitsSlice';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup, Row } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';
import { showFilterList } from '../../../redux/slices/indicatorFilterSlice';

const NonConformingChart = ({ indicatorName }) => {
  const filterList = useSelector(showFilterList);
  const filters = useSelector((state) => state.producedUnits.filters);
  const { workCenter, product, productionOrder, shift, from, to, temporality } = filters;
  const [subtitle, setSubtitle] = useState('');

  // graph information
  const producedUnits = useSelector(showProducedUnits);
  const data = producedUnits.nonConformingUnits;
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [paginationCount, setPaginationCount] = useState(0);

  useEffect(() => {
    const subtitle = `Filtered by work center ${workCenter}${
      product ? ', product ' + product : ''
    }${productionOrder ? ', production order ' + productionOrder : ''}${
      shift ? ', shift ' + shift : ''
    }, from ${from} to ${to} ${'(' + temporality + ')'}.`;
    setSubtitle(subtitle);
  }, [filters]);

  useEffect(() => {
    if (!data) {
      setSeries([]);
      setCategories([]);
    } else {
      const series = data?.series || [];
      const dates = data?.dates || [];
      setSeries(series);
      setCategories(dates);
    }
  }, [data]);

  // Apex chart
  const options = {
    colors: ['#08bfff', '#6cffa7', '#ffae16', '#ff3c77', '#49158b', '#ff4f2b'],
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'center',
        },
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    stroke: {
      show: true,
      width: 2,
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: 'Units',
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'bottom',
    },
    title: {
      text: 'Non-conforming Units',
    },
    subtitle: {
      text: subtitle,
      align: 'left',
      offsetY: 20,
      style: {
        color: '#333',
        fontSize: '12px',
      },
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
  };

  const [showTableData, setShowTableData] = useState(false);
  const handleCloseModal = () => {
    setShowTableData(false);
  };

  // Set Produced Total table data
  const [excelTableData, setExcelTableData] = useState([]);
  useEffect(() => {
    let data = [];
    for (let i = 0; i < categories.length; i++) {
      data.push({
        date: categories[i],
        good_units: series[0]?.data[i] || 0,
        non_conforming_units: series[1]?.data[i] || 0,
        total: parseInt(series[0]?.data[i] || 0) + parseInt(series[1]?.data[i] || 0),
      });
    }
    setExcelTableData(data);
    setPaginationCount(data.length);
  }, [categories]);

  return (
    <>
      <Card className="p-3 my-2">
        <SimpleTableModal
          title={'Non-conforming Units'}
          headers={['date', 'good_units', 'non_conforming_units', 'total']}
          tableData={excelTableData}
          show={showTableData}
          handleClose={handleCloseModal}
          totalCount={paginationCount}
        />
        <div>
          <Row className="flex-row-reverse">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle className="fpb-btn-chart" id="dropdown-download" />
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowTableData(true);
                  }}
                >
                  Show Data
                </Dropdown.Item>
                <ExportExcel
                  excelData={excelTableData}
                  fileName={indicatorName + ' Report (' + from + ' -' + to + ')'}
                  indicator={indicatorName}
                  filters={subtitle}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </div>
        <Chart
          options={JSON.parse(JSON.stringify(options))}
          series={JSON.parse(JSON.stringify(series))}
          type="bar"
          height={350}
        />
      </Card>
    </>
  );
};

export default NonConformingChart;
