import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { showTimeAnalysis } from '../../../redux/slices/timeAnalysisSlice';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup, Row } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';
import { showFilterList } from '../../../redux/slices/indicatorFilterSlice';
import { showSeriesView } from '../../../redux/slices/indicatorFilterSlice';

const TimePercentages = ({ indicatorName }) => {
  const filterList = useSelector(showFilterList);
  const see = useSelector(showSeriesView);
  const data = useSelector(showTimeAnalysis);
  const filters = useSelector((state) => state.timeAnalysis.filters);
  const { workCenter, product, productionOrder, shift, from, to, temporality } = filters;
  const [subtitle, setSubtitle] = useState('');
  const [timeTotals, setTimeTotals] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [paginationCount, setPaginationCount] = useState(0);
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const subtitle = `Filtered by work center ${workCenter}${
      product ? ', product ' + product : ''
    }${productionOrder ? ', production order ' + productionOrder : ''}${
      shift ? ', shift ' + shift : ''
    }, from ${from} to ${to} ${'(' + temporality + ')'}.`;
    setSubtitle(subtitle);
  }, [filters]);

  useEffect(() => {
    if (data && see == 'minutes') {
      const timeByMinutes = data.time?.timeByMinutes || [];
      setTimeData(timeByMinutes);
    } else {
      const timeByHours = data.time?.timeByHours || [];
      setTimeData(timeByHours);
    }
  }, [see]);

  useEffect(() => {
    if (
      data.timePercentages?.data[0] == 0 &&
      data.timePercentages?.data[1] == 0 &&
      data.timePercentages?.data[2] == 0 &&
      data.timePercentages?.data[3] == 0
    ) {
      setSeries([]);
      setLabels([]);
    } else {
      const series = data.timePercentages?.data || [];
      const labels = data.timePercentages?.categories || [];
      setSeries(series);
      setLabels(labels);
    }
  }, [data]);

  useEffect(() => {
    let working_totals = 0;
    let planned_totals = 0;
    let unplanned_totals = 0;
    let not_reported_totals = 0;
    for (let i = 0; i < timeData[0]?.data.length; i++) {
      working_totals = working_totals + timeData[0]?.data[i];
      planned_totals += timeData[1]?.data[i];
      unplanned_totals += timeData[2]?.data[i];
      not_reported_totals += timeData[3]?.data[i];
    }
    setTimeTotals([working_totals, planned_totals, unplanned_totals, not_reported_totals]);
  }, [timeData]);

  const options = {
    chart: {
      type: 'pie',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    colors: ['#ff3c77', '#49158b', '#ff4f2b', '#08bfff', '#ffae16'],
    title: {
      text: 'Time Analysis (percentages)',
    },
    subtitle: {
      text: subtitle,
      align: 'left',
      offsetY: 20,
      style: {
        color: '#333',
        fontSize: '12px',
      },
    },
    labels: labels,
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -9,
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
      },
      offsetY: -20,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.45,
      },
    },
    legend: {
      position: 'right',
      horizontalAlign: 'center',
      offsetY: 150,
      offsetX: 150,
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
  };

  const [showTableData, setShowTableData] = useState(false);
  const handleCloseModal = () => {
    setShowTableData(false);
  };

  // Set Time Percentages table data
  const [excelTableData, setExcelTableData] = useState([]);
  useEffect(() => {
    let data = [];
    for (let i = 0; i < labels.length; i++) {
      data.push({
        time_type: labels[i],
        time: timeTotals[i] || 0,
        percentage: series[i] || 0,
      });
    }
    setExcelTableData(data);
    setPaginationCount(data.length);
  }, [timeTotals]);

  return (
    <>
      <Card className="p-3 my-2">
        <SimpleTableModal
          title={'Time Analysis Percentages'}
          headers={['time_type', 'time', 'percentage']}
          tableData={excelTableData}
          show={showTableData}
          handleClose={handleCloseModal}
          totalCount={paginationCount}
        />
        <div>
          <Row className="flex-row-reverse">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle className="fpb-btn-chart" id="dropdown-download" />
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowTableData(true);
                  }}
                >
                  Show Data
                </Dropdown.Item>
                <ExportExcel
                  excelData={excelTableData}
                  fileName={indicatorName + ' Report (' + from + ' -' + to + ')'}
                  indicator={indicatorName}
                  filters={subtitle}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </div>
        <Chart
          options={JSON.parse(JSON.stringify(options))}
          series={JSON.parse(JSON.stringify(series))}
          type="pie"
          height={420}
        />
      </Card>
    </>
  );
};

export default TimePercentages;
