import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LogoFPB from '../CoreComponents/LogoFPB/LogoFPB';
import TextField from '../FormComponents/TextField/TextField';
import { notification } from '../AlertMessage/ToastifyAlert';
import Urls from '../../components/Urls';
import { useHistory } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';

const ChangePasswordByEmail = () => {
  const prc = Urls.password_reset_complete;
  const iconEye = <FaIcons.FaEye size={25} />;
  const iconEyeSlash = <FaIcons.FaEyeSlash size={25} />;
  const [iconNew, setIconNew] = useState(iconEyeSlash);
  const [iconNewConfirm, setIconNewConfirm] = useState(iconEyeSlash);
  const [typeNew, setTypeNew] = useState('password');
  const [typeNewConfirm, setTypeNewConfirm] = useState('password');
  const history = useHistory();

  const handleToggle = () => {
    if (typeNew === 'password') {
      setIconNew(iconEye);
      setTypeNew('text');
    } else {
      setIconNew(iconEyeSlash);
      setTypeNew('password');
    }
  };
  const handleToggleConfirm = () => {
    if (typeNewConfirm === 'password') {
      setIconNewConfirm(iconEye);
      setTypeNewConfirm('text');
    } else {
      setIconNewConfirm(iconEyeSlash);
      setTypeNewConfirm('password');
    }
  };
  const changePasswordValidate = Yup.object({
    password: Yup.string().required('Required'),
  });

  const password_reset = async (pass) => {
    const params = new URLSearchParams(window.location.href);
    return await fetch(`${prc}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: String(pass.password).trim(),
        repassword: String(pass.repassword).trim(),
        uidb64: String(params.get('uidb64')).trim(),
        token: String(params.get('token')).trim(),
      }),
    });
  };

  const redirectToHome = () => {
    setTimeout(() => {
      history.push('/');
    }, 3000);
  };
  return (
    <Formik
      validationSchema={changePasswordValidate}
      onSubmit={async (data) => {
        const processedValues = {
          password: data.password,
          repassword: data.repassword,
        };
        try {
          let res;
          res = await password_reset(processedValues);
          const data = await res.json();
          if (data.message === 'Password reset success') {
            notification('Password changed successfully', 'success');
            redirectToHome();
          } else if (data.message === 'error') {
            notification('Passwords do not match', 'warning');
          } else if (data.message === 'errorValidate') {
            notification('The reset link is invalid', 'warning');
            redirectToHome();
          } else if (data.error === 'passwordValidate') {
            notification(data.message, 'warning');
          }
        } catch (error) {
          console.log(error);
        }
      }}
      initialValues={{
        password: '',
        repassword: '',
      }}
    >
      {({ values, touched, handleBlur, handleSubmit }) => (
        <div
          className="row d-flex align-items-center"
          style={{ backgroundColor: '#FFFFFF', height: '50vh' }}
        >
          <div
            className="col-6 col offset-3 card-profile shadow p-5"
            style={{
              backgroundColor: '#E4E4E4',
              borderRadius: '10px',
            }}
          >
            <LogoFPB logoWidth="40%" />
            <Form className="text-left" onSubmit={handleSubmit}>
              <Form.Row>
                <TextField
                  label="New Password"
                  name="password"
                  type={typeNew}
                  value={values.password}
                />
                <span className="changepassword-toogle-icon" onClick={handleToggle}>
                  {iconNew}
                </span>
              </Form.Row>
              <Form.Row>
                <TextField
                  label="Confirm New Password"
                  name="repassword"
                  type={typeNewConfirm}
                  value={values.repassword}
                />
                <span className="changepassword-toogle-icon2" onClick={handleToggleConfirm}>
                  {iconNewConfirm}
                </span>
              </Form.Row>
              <Button className="fpb-btn mt-3" type="submit">
                Send
              </Button>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};
export default ChangePasswordByEmail;
