import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ExportButton from '../../components/ExportExcel/ExportButton';
import FormContainer from '../../components/Forms/FormContainer';
import FPBTable from '../../components/FPBTable/FPBTableIndustrial';
import ActionConfirmation from '../../components/ActionConfirmation/ActionConfirmation';
import HeaderTitle from '../../components/CoreComponents/PageTitle/PageTitle';
import useForm from '../../components/useForm';
import { setTotalCountAction } from '../../redux/actions/paginationAction';
import { setProductsAction, setRoutesAction } from '../../redux/actions/industrialAction';
import { getExportDataAsync } from '../../redux/slices/exportSlice';
import { notification } from '../../components/AlertMessage/ToastifyAlert';
import Data from '../../components/dataService';
import apiService from '../../components/apiService';
import { getColumnsToHideAndOrder } from '../../utils/tableColumns';

const TableSection = ({ url, formName, title, disableAdd }) => {
  const [addReload, setAddReload] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteRows, setDeleteRows] = useState();
  const [deleteChange, setDeleteChange] = useState(false);
  const [addedRow, setAddedRow] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageUpdate, setErrorMessageUpdate] = useState('');
  const [columnsToHide, setColumnsToHide] = useState();
  const [columnOrder, setColumnOrder] = useState(title);
  const [editOnly, setEditOnly] = useState(false);
  const industrialClient = apiService.industrialClient();
  const dispatch = useDispatch();

  // pagination
  const [filters, setFilters] = useState([]);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);

  useEffect(() => {
    const getRoutes = async () => {
      const routes = await Data.getRoutes();
      dispatch(setRoutesAction(routes));
    };
    if (formName === 'route' || formName === 'workCenterCapacity') {
      getRoutes();
    }
  }, [addForm]);

  useEffect(() => {
    const columnsInfo = getColumnsToHideAndOrder(formName);
    setColumnsToHide(columnsInfo.columnsToHide);
    setColumnOrder(columnsInfo.columnOrder);
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      const products = await Data.getProducts();
      dispatch(setProductsAction(products));
    };
    if (formName === 'workCenterCapacity' || formName === 'unitTransformation') {
      getProducts();
    }
    if (formName === 'quality') {
      setEditOnly(true);
    }
  }, []);

  const dispatchTotalCount = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatchTotalCount(setTotalCountAction(totalCount));
  };

  const { handleChange, values, handleAPIRequest, handleAPIRequestUpdate, added, edited } = useForm(
    url,
    setAddForm,
    setAlertMessage,
    setErrorMessage,
    setErrorMessageUpdate,
    errorMessageUpdate
  );

  const onAdded = (r) => {
    setAddForm(false);
    setAddedRow(r);
    setAddReload(!addReload);
  };

  const handleConfirmation = () => {
    setShow(false);
    setConfirmDelete(true);
  };

  const handleClose = () => setShow(false);

  const handleDelete = async (id) => {
    setShow(true);
    setDeleteRows(id);
  };

  const nullableFields = [
    'planning_closing_date',
    'assignment date',
    'start_date',
    'end_date',
    'dimensional_unit_2',
    'dimensional_unit_2',
    'amount_2',
    'amount_3',
    'dispatch_date',
  ];

  // data is the array of modified fields ([{field_name:value},{}...{}])
  const handleEdit = async (id, data) => {
    // get the name of the fields to edit
    const fieldsName = Object.keys(data);
    const editedRow = {};
    const res = await Promise.all(
      fieldsName.map(async (fieldName) => {
        // if the edited field is of type date or integer we send null instead of '' (when deleting field content)
        if (nullableFields.includes(fieldName) && data[fieldName] === '') {
          editedRow[fieldName] = null;
          return editedRow;
        }
        editedRow[fieldName] = data[fieldName];
        return editedRow;
      })
    );
    const dataToUpdate = res[res.length - 1];

    industrialClient
      .patch(`${url}/${id}`, { ...dataToUpdate })
      .then(() => {})
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (confirmDelete === true) {
      industrialClient.delete(`${url}/${deleteRows}`).then(() => {
        setDeleteChange(true);
        setConfirmDelete(false);
        setDeleteChange(false);
      });
    }
  }, [confirmDelete]);

  const getQueryString = () => {
    let filter = filters
      .reduce((acc, { columnName, operation, value }) => {
        switch (operation) {
          case 'contains': {
            acc.push(`${columnName}__icontains=${encodeURIComponent(value)}`);
            break;
          }
          case 'equal': {
            acc.push(`${columnName}__exact=${encodeURIComponent(value)}`);
            break;
          }
          default:
            acc.push(`${columnName}__${operation}=${encodeURIComponent(value)}`);
            break;
        }
        return acc;
      }, [])
      .join('&');

    if (filters.length > 1) {
      filter = `${filter}`;
    }

    let urlT;

    urlT = `${url}?page=${currentPage + 1}&page_size=${pageSize}&${filter}`;
    return { urlT, filter };
  };

  useEffect(() => {
    const queryString = getQueryString();
    const getTableData = async () => {
      try {
        const response = await industrialClient.get(queryString.urlT);
        const tableData = response.data.results;
        setTotalCount(response.data.count);
        if (tableData.length > 0) {
          const headersList = Object.keys(tableData[0]).filter((elem) => elem !== 'id');
          setHeaders(headersList);
          setTableData(tableData);
        } else {
          setTableData([]);
          setHeaders([]);
        }
      } catch (err) {
        console.log('error: ', err);
      }
      setAddForm(false);
    };
    getTableData();
  }, [url, pageSize, currentPage, filters, addReload]);

  const handleExport = async () => {
    const queryString = getQueryString();
    dispatch(getExportDataAsync({ urlExport: url, filters: queryString.filter }));
  };

  useEffect(() => {
    alertMessage !== '' ? notification(alertMessage, 'success') : null;
  }, [alertMessage]);

  useEffect(() => {
    errorMessage !== '' ? notification(errorMessage, 'error') : null;
  }, [errorMessage]);

  return (
    <>
      <HeaderTitle title={title} />
      <ActionConfirmation
        show={show}
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
      />
      <div className="text-left btn-container">
        {disableAdd ? null : (
          <>
            <Button
              className="fpb-btn "
              onClick={() => {
                setAddForm(true);
              }}
              disabled={addForm}
            >
              Add
            </Button>

            <Modal show={addForm} onHide={setAddForm} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Add {title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormContainer
                  handleChange={handleChange}
                  values={values}
                  handleAPIRequest={handleAPIRequest}
                  added={added}
                  formName={formName}
                  onAdded={onAdded}
                  setAddForm={setAddForm}
                  setAlert={setAlertMessage}
                  setError={setErrorMessage}
                />
              </Modal.Body>
            </Modal>
            {/*<Button className="fpb-btn-inverse">Import</Button>*/}
          </>
        )}
        <ExportButton formName={formName} fileName={title + ' Table'} handleExport={handleExport} />
      </div>

      <FPBTable
        readOnly={editOnly || false}
        editOnly={!editOnly}
        headers={headers}
        tableData={tableData}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        deleteChange={deleteChange}
        addedRow={addedRow}
        columnsToHide={columnsToHide}
        handleChange={handleChange}
        handleAPIRequestUpdate={handleAPIRequestUpdate}
        added={added}
        formName={formName}
        onAdded={onAdded}
        setAddForm={setAddForm}
        setAlert={setAlertMessage}
        setError={setErrorMessageUpdate}
        edited={edited}
        errorMessageUpdate={errorMessageUpdate}
        title={title}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        setFilters={setFilters}
        customColumnOrder={columnOrder}
      />
    </>
  );
};

export default TableSection;
