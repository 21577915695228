import { useState, useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import HeaderTitle from '../../CoreComponents/PageTitle/PageTitle';
import OrderTableSelection from './OrderTableSelection';
import { useSelector } from 'react-redux';
import { Button, Table, Col, Card, Accordion } from 'react-bootstrap';
import apiService from '../../apiService';
import dateFuncs from '../../../utils/dateFuncs';
import FPBTableSimple from '../../FPBTable/FPBTableSimple';
import ReactToPrint from 'react-to-print';
import { AiFillPrinter } from 'react-icons/ai';
import { notification } from '../../AlertMessage/ToastifyAlert';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

const SelectProductModal = ({ show, handleClose, products, onChangeSelection, handleConfirm }) => {
  const options = products.map((product) => ({
    value: product,
    label: product,
  }));
  return (
    <Modal show={show} onHide={handleClose} a>
      <Modal.Header closeButton>
        <Modal.Title>Please select a product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          isClearable
          options={options}
          showIcon={false}
          onChange={onChangeSelection}
          openMenuOnClick={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const HistoryInfo = ({ history }) => {
  return (
    <>
      <h3 className="mt-4">History</h3>
      {history.map((ppo) => (
        <Card className="mt-4" key={ppo.code}>
          <Card.Header style={{ backgroundColor: '#F2F2F2' }}>
            <Card.Text
              style={{ fontSize: 22, fontWeight: 500 }}
            >{`Work center: ${ppo.code} - ${ppo.description}`}</Card.Text>
          </Card.Header>
          <Card.Body style={{ backgroundColor: '#F6F7F8' }}>
            <Col md={3} className="mb-3">
              <Table className="mt-3 shadow-none" responsive="sm" striped bordered size="sm">
                <tbody>
                  <tr>
                    <td>Real Quantity Produced</td>
                    <td>{ppo.work_center_produced_units}</td>
                  </tr>
                  <tr>
                    <td>Non-conforming Quantity</td>
                    <td>{ppo.work_center_non_conforming_units}</td>
                  </tr>
                  <tr>
                    <td>Planned Events</td>
                    <td>{ppo.total_of_planned_events}</td>
                  </tr>
                  <tr>
                    <td>Time in Planned Events</td>
                    <td>{dateFuncs.getMinutesInDays(ppo.total_time_planned_events)}</td>
                  </tr>
                  <tr>
                    <td>Unplanned Events</td>
                    <td>{ppo.total_of_unplanned_events}</td>
                  </tr>
                  <tr>
                    <td>Time in Unplanned Events</td>
                    <td>{dateFuncs.getMinutesInDays(ppo.total_time_unplanned_events)}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            {ppo.product_per_production_order.map((record) => (
              <>
                <div key={JSON.stringify(`${ppo.code}-${record.product}-${record.process}`)}>
                  <Card.Title>
                    <Card.Text style={{ fontSize: 20, fontWeight: 500 }}>
                      {`Process: ${record.process} - ${record.process_description}`}
                    </Card.Text>
                  </Card.Title>
                  <Col md={3} className="mb-3">
                    <Table className="mt-3 shadow-none" responsive="sm" striped bordered size="sm">
                      <tbody>
                        <tr>
                          <td>Product</td>
                          <td>{`${record.product} - ${record.product_description}`}</td>
                        </tr>
                        <tr>
                          <td>Production Batch</td>
                          <td>
                            {record.production_batch ? record.production_batch : 'Not specified'}
                          </td>
                        </tr>
                        <tr>
                          <td>Planned Dispatch Date</td>
                          <td>
                            {record.dispatch_date
                              ? `${new Date(record.dispatch_date)}`
                              : 'No planned'}
                          </td>
                        </tr>
                        <tr>
                          <td>Real Start Date</td>
                          <td>{`${new Date(record.start_date)}`}</td>
                        </tr>
                        <tr>
                          <td>Real End Date</td>
                          <td>{`${new Date(record.end_date)}`}</td>
                        </tr>
                        <tr>
                          <td>Duration</td>
                          <td>{dateFuncs.getMinutesInDays(record.duration_minutes)}</td>
                        </tr>
                        <tr>
                          <td>Real Product Quantity Produced</td>
                          <td>{record.total_product_produced_units}</td>
                        </tr>
                        <tr>
                          <td>Non-conforming Quantity</td>
                          <td>{record.total_quantity_non_conforming}</td>
                        </tr>
                        <tr>
                          <td>Planned Events</td>
                          <td>{record.planned_events}</td>
                        </tr>
                        <tr>
                          <td>Time in Planned Events</td>
                          <td>{dateFuncs.getMinutesInDays(record.time_planned_events)}</td>
                        </tr>
                        <tr>
                          <td>Unplanned Events</td>
                          <td>{record.unplanned_events}</td>
                        </tr>
                        <tr>
                          <td>Time in Unplanned Events</td>
                          <td>{dateFuncs.getMinutesInDays(record.time_unplanned_events)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </div>
                <div className="container-fluid px-0">
                  <Accordion>
                    <Card style={{ backgroundColor: 'white' }}>
                      <Card.Header style={{ fontSize: 20 }}>
                        <Accordion.Toggle className="mt-2" as={Card.Title} eventKey="0">
                          {`Manual Reports (${record.manual_reports.length})`}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <FPBTableSimple
                            pageSizes={[5, 10, 15, 0]}
                            defaultPageSize={5}
                            tableHeaders={[
                              'start_date',
                              'end_date',
                              'duration_minutes',
                              'record_type',
                              'registered_quantity',
                              'real_quantity',
                              'quantity_non_conforming',
                              'user',
                            ]}
                            tableData={record.manual_reports}
                            columnsToHide={[]}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <Accordion className="mt-3">
                    <Card>
                      <Card.Header style={{ fontSize: 20 }}>
                        <Accordion.Toggle className="mt-2" as={Card.Title} eventKey="0">
                          {`Event Reports (${record.event_reports.length})`}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <FPBTableSimple
                            pageSizes={[5, 10, 15, 0]}
                            defaultPageSize={5}
                            tableHeaders={[
                              'start_date',
                              'end_date',
                              'duration_minutes',
                              'event_type',
                              'event_type_description',
                              'planned',
                              'quantity_non_conforming',
                              'user',
                            ]}
                            tableData={record.event_reports}
                            columnsToHide={[]}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </>
            ))}
          </Card.Body>
        </Card>
      ))}
    </>
  );
};

const GeneralInfo = ({ productionOrder }) => {
  return (
    <>
      <h3>General Information</h3>
      <Card className="mt-4">
        <Card.Header style={{ backgroundColor: '#F2F2F2' }}>
          <Card.Text
            style={{ fontSize: 22, fontWeight: 500 }}
          >{`Order: ${productionOrder.code}`}</Card.Text>
        </Card.Header>
        <Card.Body style={{ backgroundColor: '#F6F7F8' }}>
          <Col md={1} className="mb-3">
            <Table className="mt-3 shadow-none" responsive="sm" striped bordered size="sm">
              <tbody>
                <tr>
                  <td>Planned Closing Date</td>
                  <td>
                    {productionOrder.planned_closing_date
                      ? `${new Date(productionOrder.planned_closing_date)}`
                      : 'No planned'}
                  </td>
                </tr>
                <tr>
                  <td>Real Start Date</td>
                  <td>{`${new Date(productionOrder.start_date)}`}</td>
                </tr>
                <tr>
                  <td>Real End Date</td>
                  <td>{`${new Date(productionOrder.end_date)}`}</td>
                </tr>
                <tr>
                  <td>Elapsed Time</td>
                  <td>{dateFuncs.getMinutesInDays(productionOrder.duration_minutes)}</td>
                </tr>
                <tr>
                  <td>Last Process</td>
                  <td>{productionOrder.order_last_process}</td>
                </tr>
                <tr>
                  <td>Product</td>
                  <td>{productionOrder.last_process_result.product}</td>
                </tr>
                <tr>
                  <td>Dimensional Unit</td>
                  <td>{productionOrder.last_process_result.dimensional_unit}</td>
                </tr>
                <tr>
                  <td>Planned Quantity</td>
                  <td>{productionOrder.last_process_result.planned_quantity}</td>
                </tr>
                <tr>
                  <td>Real Quantity Produced</td>
                  <td>{productionOrder.last_process_result.total_produced_units}</td>
                </tr>
                <tr>
                  <td>Total Non-conforming Quantity</td>
                  <td>{productionOrder.order_non_conforming_units}</td>
                </tr>
                <tr>
                  <td>Planned Events</td>
                  <td>{productionOrder.order_planned_events}</td>
                </tr>
                <tr>
                  <td>Time in Planned Events</td>
                  <td>{dateFuncs.getMinutesInDays(productionOrder.order_time_planned_events)}</td>
                </tr>
                <tr>
                  <td>Unplanned Events</td>
                  <td>{productionOrder.order_unplanned_events}</td>
                </tr>
                <tr>
                  <td>Time in Unplanned Events</td>
                  <td>{dateFuncs.getMinutesInDays(productionOrder.order_time_unplanned_events)}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

const OrderTraceability = () => {
  let componentRef = useRef();
  const [startFetching, setStartFetching] = useState(false);
  const [showSecondScreen, setShowSecondScreen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const selectedOrder = useSelector((state) => state.productionOrders.orderToTrace);
  const orderProducts = useSelector((state) => state.productionOrders.orderToTraceProducts);

  const { data } = useQuery({
    queryKey: ['orderTraceability', selectedOrder, selectedProduct],
    queryFn: () => apiService.getOrderTraceability(selectedOrder, selectedProduct),
    enabled: startFetching,
    onSuccess: () => setShowSecondScreen(true),
    retry: false,
    onError: (error) => {
      console.log('error', error);
      notification(error?.message, 'error');
    },
    refetchOnWindowFocus: false,
  });

  const handleGenerateReport = () => {
    if (orderProducts.length > 1) {
      setShowModal(true);
    } else if (orderProducts.length === 1) {
      setSelectedProduct(orderProducts[0]);
      setStartFetching(true);
    } else {
      notification('No products found for this order', 'error');
    }
  };

  const onChangeProductSelection = (selection) => {
    console.log('selection', selection);
    setSelectedProduct(selection?.value);
  };

  const handleModalConfirm = () => {
    if (selectedProduct !== '') {
      setShowModal(false);
      setStartFetching(true);
    }
  };

  return (
    <div style={{ paddingRight: '50px' }}>
      <HeaderTitle title="Order Traceability" />
      <SelectProductModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        products={orderProducts}
        onChangeSelection={onChangeProductSelection}
        handleConfirm={handleModalConfirm}
      />
      {!showSecondScreen && (
        <>
          <div className="text-left btn-container">
            <Button disabled={!selectedOrder} className="fpb-btn" onClick={handleGenerateReport}>
              Generate Report
            </Button>
          </div>
          <OrderTableSelection />
        </>
      )}
      {showSecondScreen && data && (
        <>
          <div className="btn-container d-flex justify-content-between mb-3">
            <Button
              disabled={!selectedOrder}
              className="fpb-btn"
              onClick={() => {
                setShowSecondScreen(false);
                setStartFetching(false);
              }}
            >
              New Report
            </Button>
            <ReactToPrint
              documentTitle={`Order_report_${data.production_order.code}`}
              pageStyle={'padding: 15; margin: 0;'}
              trigger={() => (
                <Button className="product ml-4" pill="true">
                  <AiFillPrinter size={18} />
                </Button>
              )}
              content={() => componentRef}
            />
            {/* <Button disabled={!selectedOrder} className="fpb-btn-inverse mx-2" onClick={() => {}}>
              Export
            </Button> */}
          </div>
          <div ref={(el) => (componentRef = el)}>
            <GeneralInfo productionOrder={data.production_order} />
            <HistoryInfo history={data.production_order.work_centers} />
          </div>
        </>
      )}
    </div>
  );
};

export default OrderTraceability;
