import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Urls from '../../components/Urls';
import { getFiltersString } from '../../utils/tableFilters';

const initialState = {
  currentProductPerProductionOrder: {},
  productPerProductionOrders: [],
  errorForm: false,
  statusToColors: {
    Started: '#ffae16',
    'Not Started': 'gray',
    Paused: 'gray',
    Closed: '#ff3c77',
  },
  columnsToHide: [
    'product_description',
    'product_category',
    'work_center_description',
    'work_center_category',
    'work_center_status',
    'dimensional_unit_1_description',
    'amount_1',
    'dimensional_unit_2_description',
    'amount_2',
    'dimensional_unit_3_description',
    'amount_3',
    'dispatch_date',
    'responsible',
    'created_by',
    'created_at',
    'updated_by',
    'updated_at',
  ],
  productPerProductionOrderModal: {
    page: 0,
    pageSize: 5,
    total: 0,
    data: [],
    headers: [],
  },
  ppoIsLoading: true,
};

const productPerProductionOrderSlice = createSlice({
  name: 'productPerProductionOrder',
  initialState,
  reducers: {
    setCurrentProductPerProductionOrder: (state, action) => {
      state.currentProductPerProductionOrder = action.payload;
    },
    setProductPerProductionOrderPageSize: (state, action) => {
      state.productPerProductionOrderModal.pageSize = action.payload;
    },
    setProductPerProductionOrderCurrentPage: (state, action) => {
      state.productPerProductionOrderModal.page = action.payload;
    },
    setProductPerProductionOrderModal: (state, action) => {
      state.productPerProductionOrderModal = action.payload;
    },
    setErrorForm: (state, action) => {
      state.errorForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductPerProductionOrdersPerOrder.fulfilled, (state, action) => {
      state.productPerProductionOrderModal.data = action.payload?.data ?? [];
      state.productPerProductionOrderModal.total = action.payload?.total ?? 0;
      state.productPerProductionOrderModal.headers = action.payload?.headers ?? [];
      state.productPerProductionOrderModal.page = action.payload?.currentPage ?? 0;
      state.ppoIsLoading = false;
    });
    builder.addCase(fetchProductPerProductionOrdersPerOrder.pending, (state) => {
      state.ppoIsLoading = true;
    });
  },
});

export const fetchProductPerProductionOrdersPerOrder = createAsyncThunk(
  'productPerProductionOrder/fetchProductPerProductionOrderPerOrder ',
  async ({ currentPage, pageSize, productionOrder, filters }) => {
    try {
      const filtersString = getFiltersString(filters);
      const url = `${Urls.productPerProductionOrders}?page=${
        currentPage + 1
      }&page_size=${pageSize}&production_order__code=${productionOrder}&${filtersString}filter1=1`;
      const token = localStorage.getItem('accessToken');
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { results } = res.data ?? [];
      if (results.length > 0) {
        // discard the fields the user will not visualize in tables
        const headersList = Object.keys(results[0]).filter((elem) => {
          let returnColumn;
          if (
            elem === 'id' ||
            elem === 'order_loading_date' ||
            elem === 'order_start_date' ||
            elem === 'order_end_date' ||
            elem === 'order_planned_closing_date' ||
            elem === 'order_assignment_date' ||
            elem === 'order_manufacturing_status' ||
            elem === 'operation_center' ||
            elem === 'operation_center_description' ||
            elem === 'work_center_category' ||
            elem === 'work_center_status' ||
            elem === 'product_category' ||
            elem === 'production_order'
          ) {
            returnColumn = false;
          } else {
            returnColumn = true;
          }
          return returnColumn;
        });
        return {
          currentPage,
          pageSize,
          total: res.data.count,
          data: res.data.results,
          headers: headersList,
        };
      }
      return {
        currentPage,
        pageSize,
        total: 0,
        data: [],
        headers: [],
      };
    } catch (err) {
      return err.message;
    }
  }
);

export const productPerProductionOrderColumnsToHide = (state) =>
  state.productPerProductionOrders.columnsToHide;
export const selectModalProductPerProductionOrder = (state) =>
  state.productPerProductionOrders.productPerProductionOrderModal.data;
export const selectProductPerProductionOrderHeaders = (state) =>
  state.productPerProductionOrders.productPerProductionOrderModal.headers;
export const selectProductPerProductionOrderTotalCount = (state) =>
  state.productPerProductionOrders.productPerProductionOrderModal.total;
export const selectProductPerProductionOrderCurrentPage = (state) =>
  state.productPerProductionOrders.productPerProductionOrderModal.page;
export const selectProductPerProductionOrderPageSize = (state) =>
  state.productPerProductionOrders.productPerProductionOrderModal.pageSize;
export const selectProductPerProductionOrderColumnsToHide = (state) =>
  state.productPerProductionOrders.columnsToHide;
export const ppoIsLoading = (state) => state.productPerProductionOrders.ppoIsLoading;

export const {
  setCurrentProductPerProductionOrder,
  setProductPerProductionOrderPageSize,
  setProductPerProductionOrderCurrentPage,
  setProductPerProductionOrderModal,
  setErrorForm,
} = productPerProductionOrderSlice.actions;

export default productPerProductionOrderSlice.reducer;
