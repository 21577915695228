import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';
import Urls from '../Urls';
import { ProductSelection } from '../SelectionComponents';

const Route = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [product, setProduct] = useState();

  const [descriptions, setDescriptions] = useState({
    processDescriptions: '',
  });
  const [processData, setProcessData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const productValidate = Yup.object({
    product: Yup.object().required('Required'),
    process: Yup.string().required('Required'),
  });

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  useEffect(async () => {
    try {
      const resProcess = await apiService.getData(Urls.process, 'code');
      setProcessData({
        headers: resProcess.headersList,
        tableData: resProcess.data,
        options: resProcess.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    processData.options?.forEach((option) => {
      if (option.value === values.code) {
        setDescriptions(
          option
            ? { ...descriptions, processDescriptions: option.description }
            : { ...descriptions, processDescriptions: '' }
        );
      }
    });
  }, [processData]);

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={productValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            process: data.process,
            process_order: data.processOrder || '',
            product: product || values.product,
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
          };
          setProcessedFormValues(processedValues);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Route',
              code: data.id,
            });
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Route',
              code: data.id,
            });
            if (updated.statusText == 'OK') {
              setAddForm(false);
              onEdited(processedValues);
            }
          }
        }}
        initialValues={{
          id: values.id || '',
          processOrder: parseInt(values.process_order) || '',
          process: values.process || '',
          product: {
            value: values.product,
            label: values.product,
            description: values.product_description,
          },
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <ProductSelection
                value={values.product}
                name={'product'}
                onChange={(option) => {
                  setFieldValue(
                    'product',
                    option ? option : { value: 'Error', label: 'Select...', description: 'Error' }
                  );
                  setProduct(option?.value || '');
                }}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('process', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, processDescriptions: option.description }
                      : { ...descriptions, processDescriptions: '' }
                  );
                }}
                label="Process Code"
                name="process"
                options={processData.options}
                defaultValue={{
                  value: values.process,
                  label: values.process,
                }}
                description={descriptions.processDescriptions}
                value={{
                  value: values.process,
                  label: values.process,
                }}
              />
              {onEdited && (
                <TextField
                  label="Process Order"
                  name="processOrder"
                  type="number"
                  value={onEdited === undefined ? processOrder : values.processOrder}
                  disabled={onEdited === undefined}
                />
              )}
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Route;
