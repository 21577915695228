import { Button, Modal } from 'react-bootstrap';
import FPBTableSimple from '../FPBTable/FPBTableSimple';

const InformationTableModal = ({ show, handleClose, title, headers, tableData }) => {
  return (
    <Modal show={show} onHide={handleClose} className="fpb-modal">
      <Modal.Header
        closeButton
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Modal.Title style={{ maxWidth: '50%' }}>
          {title.replaceAll('_', ' ').toUpperCase()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FPBTableSimple tableHeaders={headers} tableData={tableData} />
      </Modal.Body>
    </Modal>
  );
};

export default InformationTableModal;
