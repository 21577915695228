import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const fetchQualityReportsByOrder = createAsyncThunk(
  'qualityReports/fetchQualityReportsByOrder',
  async ({ page, pageSize, workCenter, productionOrder, product, filters }) => {
    try {
      const res = await apiService.getQualityReportsByOrder(
        page,
        pageSize,
        workCenter,
        productionOrder,
        product,
        filters
      );
      const { results } = res.data || 0;
      if (results.length > 0) {
        // discard the fields the user will not visualize in tables
        const headersList = Object.keys(results[0]).filter((elem) => {
          let returnColumn;
          if (elem === 'id') {
            returnColumn = false;
          } else {
            returnColumn = true;
          }
          return returnColumn;
        });
        return {
          page,
          pageSize,
          total: res.data.count,
          data: res.data.results,
          headers: headersList,
        };
      }
      return {
        page,
        pageSize,
        total: res.data.count,
        data: res.data.results,
        headers: [],
      };
    } catch (err) {
      console.log(err);
      return err.message;
    }
  }
);

const initialState = {
  currentOrderQualityReports: {
    page: 1,
    pageSize: 10,
    total: 0,
    data: [],
    headers: [],
  },
  reports: [],
  columnsToHide: [
    'work_center_description',
    'work_center_category',
    'shift_start_hour',
    'shift_end_hour',
    'product_description',
  ],
};

const qualityReportSlice = createSlice({
  name: 'qualityReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQualityReportsByOrder.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.currentOrderQualityReports.data = action.payload.data;
      state.currentOrderQualityReports.page = action.payload.page;
      state.currentOrderQualityReports.pageSize = action.payload.pageSize;
      state.currentOrderQualityReports.total = action.payload.total;
      state.currentOrderQualityReports.headers = action.payload.headers;
    });
  },
});

export const selectCurrentOrderQualityReports = (state) =>
  state.qualityReports.currentOrderQualityReports.data;
export const selectQualityReportsHeaders = (state) =>
  state.qualityReports.currentOrderQualityReports.headers;
export const selectQualityReportsTotalCount = (state) =>
  state.qualityReports.currentOrderQualityReports.total;
export const qualityReportsColumnsToHide = (state) => state.qualityReports.columnsToHide;

export default qualityReportSlice.reducer;
