import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '../FormComponents/TextField/TextField';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import apiService from '../apiService';
import Urls from '../Urls';

const WorkCenterPerProcess = ({
  onAdded,
  values,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  onEdited,
}) => {
  const [processedFormValues, setProcessedFormValues] = useState({});
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [dimensionalUnit, setDimensionalUnit] = useState('');
  const [entryDimensionalUnit, setEntryDimensionalUnit] = useState('');
  const [outputDimensionalUnit, setOutputDimensionalUnit] = useState('');
  const [workCenter, setWorkCenter] = useState('');
  const [variable, setVariable] = useState('');
  const [dataSource, setDataSource] = useState('');

  const [descriptions, setDescriptions] = useState({
    processDescription: '',
  });
  const [processData, setProcessData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [workCenterData, setWorkCenterData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [variableData, setVariableData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [dimensionalUnitData, setDimensionalUnitData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const productValidate = Yup.object({
    dataSource: Yup.string(),
    partialReportingTime: Yup.number(),
    comments: Yup.string(),
    process: Yup.string().required('Required'),
    workCenter: Yup.string().required('Required'),
    variable: Yup.string().required('Required'),
  });

  useEffect(async () => {
    try {
      const resProcess = await apiService.getData(Urls.process, 'code');
      const resWorkCenter = await apiService.getData(Urls.workCenter, 'code');
      const resVariable = await apiService.getData(Urls.variable, 'code');
      const resDimensionalUnit = await apiService.getData(Urls.dimensionalUnitA, 'code');

      setWorkCenterData({
        headers: resWorkCenter.headersList,
        tableData: resWorkCenter.data,
        options: resWorkCenter.results,
      });

      setVariableData({
        headers: resVariable.headersList,
        tableData: resVariable.data,
        options: resVariable.results,
      });

      setProcessData({
        headers: resProcess.headersList,
        tableData: resProcess.data,
        options: resProcess.results,
      });

      setDimensionalUnitData({
        headers: resDimensionalUnit.headersList,
        tableData: resDimensionalUnit.data,
        options: resDimensionalUnit.results,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    processData.options?.forEach((option) => {
      if (option.value === values.code) {
        setDescriptions(
          option
            ? { ...descriptions, processDescriptions: option.description }
            : { ...descriptions, processDescriptions: '' }
        );
      }
    });
  }, [processData]);

  useEffect(() => {
    workCenterData.options?.forEach((option) => {
      if (option.value === values.work_center) {
        setDescriptions(
          option
            ? { ...descriptions, workCenterDescription: option.description }
            : { ...descriptions, workCenterDescription: '' }
        );
      }
    });
  }, [workCenterData]);

  useEffect(() => {
    dimensionalUnitData?.options.forEach((option) => {
      if (option.value === values.dimensional_unit || option.value === dimensionalUnit) {
        setDescriptions(
          option
            ? { ...descriptions, dimensionalUnitDescription: option.description }
            : { ...descriptions, dimensionalUnitDescription: '' }
        );
      }
    });
    console.log(descriptions);
  }, [dimensionalUnitData]);

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
      });
    }
  }, [added]);

  return (
    <>
      <Formik
        validateOnChange={true}
        validationSchema={productValidate}
        onSubmit={async (data) => {
          let userEmail = userProfile.email ? userProfile.email : '';
          const processedValues = {
            ...values,
            data_source: data.dataSource,
            partial_reporting_time: data.partialReportingTime || null,
            comments: data.comments || '',
            created_by: onEdited ? data.createdBy : userEmail,
            updated_by: userEmail,
            process: data.process,
            work_center: data.workCenter,
            variable: data.variable,
            entry_dimensional_unit: data.entryDimensionalUnit,
            output_dimensional_unit: data.outputDimensionalUnit,
          };
          setProcessedFormValues(processedValues);
          if (onEdited == undefined) {
            const created = await handleAPIRequest(processedValues, {
              entity: 'Process',
              code: data.id,
            });
          } else {
            const updated = await handleAPIRequestUpdate(data.id, processedValues, {
              entity: 'Process',
              code: data.id,
            });
            if (updated.statusText == 'OK') {
              setAddForm(false);
              onEdited(processedValues);
            }
          }
        }}
        initialValues={{
          id: values.id || '',
          dataSource: values.data_source || '',
          partialReportingTime: values.partial_reporting_time || '',
          process: values.process || '',
          variable: values.variable || '',
          workCenter: values.work_center || '',
          entryDimensionalUnit: values.entry_dimensional_unit || '',
          outputDimensionalUnit: values.output_dimensional_unit || '',
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('process', option.value || '');
                  setDescriptions(
                    option
                      ? { ...descriptions, processDescriptions: option.description }
                      : { ...descriptions, processDescriptions: '' }
                  );
                }}
                label="Process Code"
                name="process"
                options={processData.options}
                defaultValue={{
                  value: values.process,
                  label: values.process,
                }}
                description={descriptions.processDescriptions}
                value={{
                  value: values.process,
                  label: values.process,
                }}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('workCenter', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, workCenterDescription: option.description }
                      : { ...descriptions, workCenterDescription: '' }
                  );
                  setWorkCenter(option.value || '');
                }}
                label="Work Center"
                name="workCenter"
                options={workCenterData.options}
                defaultValue={{
                  value: values.workCenter,
                  label: values.workCenter,
                }}
                description={descriptions.workCenterDescription}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('variable', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, variableDescription: option.description }
                      : { ...descriptions, variableDescription: '' }
                  );
                  setVariable(option.value);
                }}
                label="Variable Code"
                name="variable"
                options={variableData.options}
                defaultValue={{
                  value: values.variable,
                  label: values.variable,
                }}
                description={descriptions.variableDescription}
              />
            </Form.Row>
            <Form.Row>
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('entryDimensionalUnit', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, entryDimensionalUnitDescription: option.description }
                      : { ...descriptions, entryDimensionalUnitDescription: '' }
                  );
                }}
                label="Entry Dimensional Unit"
                name="entryDimensionalUnit"
                options={dimensionalUnitData.options}
                defaultValue={{
                  value: values.entryDimensionalUnit,
                  label: values.entryDimensionalUnit,
                }}
                description={descriptions.entryDimensionalUnitDescription}
              />
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('outputDimensionalUnit', option ? option.value : '');
                  setDescriptions(
                    option
                      ? { ...descriptions, outputDimensionalUnitDescription: option.description }
                      : { ...descriptions, outputDimensionalUnitDescription: '' }
                  );
                }}
                label="Ouput Dimensional Unit"
                name="outputDimensionalUnit"
                options={dimensionalUnitData.options}
                defaultValue={{
                  value: values.outputDimensionalUnit,
                  label: values.outputDimensionalUnit,
                }}
                description={descriptions.outputDimensionalUnitDescription}
              />
            </Form.Row>

            <Form.Row>
              <CustomSelect
                onChange={(option) => {
                  setFieldValue('dataSource', option.value || '');
                  setDataSource(option.value || '');
                }}
                label="Data Source (manual/iot)"
                name="dataSource"
                options={[
                  { value: 'manual', label: 'Manual' },
                  { value: 'iot', label: 'IOT' },
                ]}
                defaultValue={{ value: values.dataSource, label: values.dataSource }}
              />
              <TextField
                label="Partial Reporting Time (minutes)"
                name="partialReportingTime"
                type="number"
                value={values.partialReportingTime}
              />
            </Form.Row>
            <Form.Row>
              <TextField label="Comments" name="comments" type="string" value={values.comments} />
            </Form.Row>
            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WorkCenterPerProcess;
