import React, { useState, useEffect } from 'react';
import { Form, Button, Col, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { GiCancel } from 'react-icons/gi';
import { HiExternalLink } from 'react-icons/hi';
import { IoMdHelpCircle } from 'react-icons/io';
import TextField from '../FormComponents/TextField/TextField';
import SelectItems from '../FormComponents/SelectItems/SelectItems';
import apiService from '../apiService';
import CustomSelect from '../FormComponents/CustomSelect/CustomSelect';
import TableModal from '../TableModal/TableModal';
import Urls from '../Urls';
import { setIsModalPaginationAction } from '../../redux/actions/paginationAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadFiles from '../../utils/uploadFiles';
import { notification } from '../AlertMessage/ToastifyAlert';
import { set } from 'date-fns';

const Item = ({
  onAdded,
  handleAPIRequest,
  handleAPIRequestUpdate,
  added,
  setAddForm,
  setError,
  values,
  onEdited,
}) => {
  const [valuesToEdit, setValuesToEdit] = useState({});
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [processedFormValues, setProcessedFormValues] = useState({});
  const options = ['sale', 'purchase', 'purchase/sale'];
  const [show, setShow] = useState(false);
  const [document, setDocument] = useState(values.documentation || '');
  const [documentName, setDocumentName] = useState(
    values.documentation ? values.documentation.split('/')[5] : ''
  );
  const [image, setImage] = useState(values.item_image || '');
  const [imageName, setImageName] = useState(
    values.item_image ? values.item_image.split('/')[5] : ''
  );
  const [imageLoading, setImageLoading] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [dimensionalUnitCodeTransformed, setDimensionalUnitCodeTransformed] = useState({});
  const [showDimensionalUnitModal, setShowDimensionalUnitModal] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [optionFromModal, setShowOptionFromModal] = useState(false);
  const [itemCategoryDefaultOptions, setItemCategoryDefaultOptions] = useState({});
  const [labelCodeTransformed, setLabelCodeTransformed] = useState();
  const [itemCategoryCodeTransformed, setItemCategoryCodeTransformed] = useState();
  const [descriptions, setDescriptions] = useState({
    itemCategoryDescription: '',
    consolidationLevelN0: '',
    consolidationLevelN1: '',
    consolidationLevelN2: '',
    consolidationLevelN3: '',
    consolidationLevelN4: '',
  });

  const itemCategoryColumnToTransform = [
    {
      name: 'parent_category',
      url: 'itemCategory',
      field: 'item_category_code',
    },
  ];

  const accessToken = localStorage.getItem('accessToken');
  // temporal variable to hide label and barcode fields
  const showFields = false;

  // enable the consolidation level when the previous level is complete
  const [consolidationLevels, setConsolidationLevels] = useState([
    { id: 0, disabled: false },
    { id: 1, disabled: true },
    { id: 2, disabled: true },
    { id: 3, disabled: true },
    { id: 4, disabled: true },
  ]);
  const [levelsFilled, setLevelsFilled] = useState({
    level0_unit: false,
    level0_pieces: false,
    level1_unit: false,
    level1_pieces: false,
    level2_unit: false,
    level2_pieces: false,
    level3_unit: false,
    level3_pieces: false,
    level4_unit: false,
    level4_pieces: false,
  });
  const [dimensionalUnitData, setDimensionalUnitData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });
  const [labelData, setLabelData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [itemCategoryData, setItemCategoryData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const ItemValidate = Yup.object({
    referenceCode: Yup.string()
      .matches(/^[A-Za-z0-9_-]*$/, 'Code cannot contain special characters or spaces')
      .required('Required'),
    description: Yup.string().required('Required'),
    itemType: Yup.string().required('Required'),
    itemCategory: Yup.string(),
    model: Yup.string(),
    barcode: Yup.string(),
    itemImage: Yup.string(),
    consolidationLevelN0: Yup.string().required('Required'),
    containedPiecesN0: Yup.number().typeError('Must be a  number').required('Required'),
    consolidationLevelN1: Yup.string(),
    containedPiecesN1: Yup.number().typeError('Must be a  number'),
    consolidationLevelN2: Yup.string(),
    containedPiecesN2: Yup.number().typeError('Must be a  number'),
    consolidationLevelN3: Yup.string(),
    containedPiecesN3: Yup.number().typeError('Must be a  number'),
    consolidationLevelN4: Yup.string(),
    containedPiecesN4: Yup.number().typeError('Must be a  number'),
    documentation: Yup.string(),
    safetyStock: Yup.number().typeError('Must be a  number'),
    label: Yup.string(),
    orderingPoint: Yup.number().typeError('Must be a  number'),
  });

  //pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };

  useEffect(() => {
    if (added === true) {
      onAdded({
        ...processedFormValues,
        item_category: itemCategoryCodeTransformed,
        label: labelCodeTransformed,
        consolidation_level_N0: dimensionalUnitCodeTransformed.consolidationLevelN0
          ? dimensionalUnitCodeTransformed.consolidationLevelN0
          : '',
        consolidation_level_N1: dimensionalUnitCodeTransformed.consolidationLevelN1
          ? dimensionalUnitCodeTransformed.consolidationLevelN1
          : '',
        consolidation_level_N2: dimensionalUnitCodeTransformed.consolidationLevelN2
          ? dimensionalUnitCodeTransformed.consolidationLevelN2
          : '',
        consolidation_level_N3: dimensionalUnitCodeTransformed.consolidationLevelN3
          ? dimensionalUnitCodeTransformed.consolidationLevelN3
          : '',
        consolidation_level_N4: dimensionalUnitCodeTransformed.consolidationLevelN4
          ? dimensionalUnitCodeTransformed.consolidationLevelN4
          : '',
      });
    }
  }, [added]);

  const requestOptions = async (url, code, setter) => {
    try {
      const result = await apiService.getData(url, code);
      setter({
        headers: result.headersList,
        tableData: result.data,
        options: result.results,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseItemModal = () => {
    setIsModalPagination();
    setShowItemModal(false);
  };

  const handleCloseDimensionalTypeModal = () => {
    setIsModalPagination();
    setShowDimensionalUnitModal(false);
  };

  useEffect(() => {
    requestOptions(Urls.label, 'label_code', setLabelData);
    requestOptions(Urls.dimensionalUnit, 'dimensional_unit_code', setDimensionalUnitData);
    requestOptions(Urls.itemCategory, 'item_category_code', setItemCategoryData);
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      File Maximum Size: 10MB
    </Tooltip>
  );

  //check if the dimensional unit and number of pieces are filled out to enable the next level
  useEffect(() => {
    consolidationLevels.map((level) => {
      if (levelsFilled[`level${level.id}_unit`] && levelsFilled[`level${level.id}_pieces`]) {
        const levels = [...consolidationLevels];
        const newLevel = { ...consolidationLevels[level.id + 1] };
        newLevel.disabled = false;
        levels[level.id + 1] = newLevel;
        level.id < 4 ? setConsolidationLevels(levels) : null;
      }
    });
  }, [levelsFilled]);

  // show enabled levels on edit modal
  useEffect(() => {
    const levels = [...consolidationLevels];
    if (values.consolidation_level_N0) {
      const newLevel = { ...consolidationLevels[1] };
      newLevel.disabled = false;
      levels[1] = newLevel;
      setConsolidationLevels(levels);
      if (values.contained_pieces_N0) {
        const levels = { ...levelsFilled };
        levels.level0_pieces = true;
        levels.level0_unit = true;
        setLevelsFilled(levels);
      }
    }
    if (values.consolidation_level_N1) {
      const newLevel = { ...consolidationLevels[2] };
      newLevel.disabled = false;
      levels[2] = newLevel;
      setConsolidationLevels(levels);
      if (values.contained_pieces_N1) {
        const levels = { ...levelsFilled };
        levels.level1_pieces = true;
        levels.level1_unit = true;
        setLevelsFilled(levels);
      }
    }
    if (values.consolidation_level_N2) {
      const newLevel = { ...consolidationLevels[3] };
      newLevel.disabled = false;
      levels[3] = newLevel;
      setConsolidationLevels(levels);
      if (values.contained_pieces_N2) {
        const levels = { ...levelsFilled };
        levels.level2_pieces = true;
        levels.level2_unit = true;
        setLevelsFilled(levels);
      }
    }
    if (values.consolidation_level_N3 || values.consolidation_level_N4) {
      const newLevel = { ...consolidationLevels[4] };
      newLevel.disabled = false;
      levels[4] = newLevel;
      setConsolidationLevels(levels);
      if (values.contained_pieces_N3 || values.contained_pieces_N4) {
        const levels = { ...levelsFilled };
        levels.level3_pieces = true;
        levels.level3_unit = true;
        levels.level4_pieces = true;
        levels.level4_unit = true;
        setLevelsFilled(levels);
      }
    }
  }, [values]);

  useEffect(() => {
    itemCategoryData.options.forEach((option) => {
      if (option.value === values.item_category) {
        setDescriptions(
          option
            ? { ...descriptions, itemCategoryDescription: option.description }
            : { ...descriptions, itemCategoryDescription: '' }
        );
      }
    });
  }, [itemCategoryData]);

  useEffect(() => {
    let consolidationDesc = { ...descriptions };
    dimensionalUnitData.options.forEach((option) => {
      if (option.value === values.consolidation_level_N0) {
        consolidationDesc = {
          ...consolidationDesc,
          consolidationLevelN0: option.description,
        };
      }
      if (option.value === values.consolidation_level_N1) {
        consolidationDesc = {
          ...consolidationDesc,
          consolidationLevelN1: option.description,
        };
      }
      if (option.value === values.consolidation_level_N2) {
        consolidationDesc = {
          ...consolidationDesc,
          consolidationLevelN2: option.description,
        };
      }
      if (option.value === values.consolidation_level_N3) {
        consolidationDesc = {
          ...consolidationDesc,
          consolidationLevelN3: option.description,
        };
      }
      if (option.value === values.consolidation_level_N4) {
        consolidationDesc = {
          ...consolidationDesc,
          consolidationLevelN4: option.description,
        };
      }
    });
    setDescriptions(consolidationDesc);
  }, [dimensionalUnitData]);

  useEffect(() => {
    setValuesToEdit({
      ...valuesToEdit,
      documentation: document,
    });
  }, [document]);

  useEffect(() => {
    setValuesToEdit({
      ...valuesToEdit,
      item_image: image,
    });
  }, [image]);

  const handleItemConfirm = (selected, title, setFieldValue) => {
    setItemCategoryDefaultOptions({
      value: selected.id,
      label: selected.item_category_code,
      description: selected.description,
    });
    setValuesToEdit({
      ...valuesToEdit,
      item_category: selected.id,
    });
    setFieldValue('itemCategory', selected.id);
    setItemCategoryCodeTransformed(selected.item_category_code);
    setShowItemModal(false);
    setShowOptionFromModal(true);
  };

  const handleDimensionalUnitConfirm = (selected) => {
    setShowDimensionalUnitModal(false);
  };

  return (
    <Formik
      validateOnChange
      validationSchema={ItemValidate}
      onSubmit={async (data) => {
        const processedValues = {
          reference_code: data.referenceCode,
          item_model: data.model,
          item_description: data.description,
          barcode: data.barcode,
          documentation: document,
          safety_stock: data.safetyStock || 0,
          label: data.label,
          ordering_point: data.orderingPoint || 0,
          minimum_stock: data.minimumStock || 0,
          maximum_stock: data.maximumStock || 0,
          item_category: data.itemCategory,
          item_type: data.itemType,
          item_image: image,
          consolidation_level_N0:
            data.consolidationLevelN0 !== '' ? data.consolidationLevelN0 : null,
          consolidation_level_N1:
            data.consolidationLevelN1 !== '' ? data.consolidationLevelN1 : null,
          consolidation_level_N2:
            data.consolidationLevelN2 !== '' ? data.consolidationLevelN2 : null,
          consolidation_level_N3:
            data.consolidationLevelN3 !== '' ? data.consolidationLevelN3 : null,
          consolidation_level_N4:
            data.consolidationLevelN4 !== '' ? data.consolidationLevelN4 : null,
          contained_pieces_N0: data.containedPiecesN0 !== '' ? data.containedPiecesN0 : 0,
          contained_pieces_N1: data.containedPiecesN1 !== '' ? data.containedPiecesN1 : 0,
          contained_pieces_N2: data.containedPiecesN2 !== '' ? data.containedPiecesN2 : 0,
          contained_pieces_N3: data.containedPiecesN3 !== '' ? data.containedPiecesN3 : 0,
          contained_pieces_N4: data.containedPiecesN4 !== '' ? data.containedPiecesN4 : 0,
        };
        if (data.id == null) {
          setProcessedFormValues(processedValues);
          setOriginalFormValues(data);
          const created = await handleAPIRequest(processedValues, {
            entity: 'Item',
            code: data.referenceCode,
          });
          if (created.statusText !== 'Created') {
            notification('Error creating item - duplicate item', 'error');
          }
        } else {
          const editedValues = {
            reference_code: data.referenceCode,
            item_model: data.model,
            item_description: data.description,
            barcode: data.barcode,
            documentation: document,
            safety_stock: data.safetyStock,
            label: data.label,
            ordering_point: data.orderingPoint,
            minimum_stock: data.minimumStock,
            maximum_stock: data.maximumStock,
            item_category: itemCategoryCodeTransformed || data.itemCategory,
            item_type: data.itemType,
            item_image: image,
            consolidation_level_N0:
              dimensionalUnitCodeTransformed.consolidationLevelN0 || data.consolidationLevelN0,
            consolidation_level_N1:
              dimensionalUnitCodeTransformed.consolidationLevelN1 || data.consolidationLevelN1,
            consolidation_level_N2:
              dimensionalUnitCodeTransformed.consolidationLevelN2 || data.consolidationLevelN2,
            consolidation_level_N3:
              dimensionalUnitCodeTransformed.consolidationLevelN3 || data.consolidationLevelN3,
            consolidation_level_N4:
              dimensionalUnitCodeTransformed.consolidationLevelN4 || data.consolidationLevelN4,
            contained_pieces_N0: data.containedPiecesN0 !== '' ? data.containedPiecesN0 : 0,
            contained_pieces_N1: data.containedPiecesN1 !== '' ? data.containedPiecesN1 : 0,
            contained_pieces_N2: data.containedPiecesN2 !== '' ? data.containedPiecesN2 : 0,
            contained_pieces_N3: data.containedPiecesN3 !== '' ? data.containedPiecesN3 : 0,
            contained_pieces_N4: data.containedPiecesN4 !== '' ? data.containedPiecesN4 : 0,
          };
          const updated = await handleAPIRequestUpdate(data.id, valuesToEdit, {
            entity: 'Item',
            code: data.referenceCode,
          });
          if (updated.statusText == 'OK') {
            onEdited(editedValues);
            setAddForm(false);
            notification('Item updated successfully', 'success');
          } else {
            notification('Error updating item - duplicate item', 'error');
          }
        }
      }}
      initialValues={{
        id: values.id,
        referenceCode: values.reference_code || '',
        model: values.item_model || '',
        description: values.item_description || '',
        barcode: values.barcode || '',
        documentation: document || '',
        label: values.label || '',
        safetyStock: values.safety_stock || 0,
        orderingPoint: values.ordering_point || 0,
        minimumStock: values.minimum_stock || 0,
        maximumStock: values.maximum_stock || 0,
        itemCategory: values.item_category || '',
        itemType: values.item_type || '',
        itemImage: image || '',
        consolidationLevelN0: values.consolidation_level_N0 || '',
        containedPiecesN0: values.contained_pieces_N0 || '',
        consolidationLevelN1: values.consolidation_level_N1 || '',
        containedPiecesN1: values.contained_pieces_N1 || '',
        consolidationLevelN2: values.consolidation_level_N2 || '',
        containedPiecesN2: values.contained_pieces_N2 || '',
        consolidationLevelN3: values.consolidation_level_N3 || '',
        containedPiecesN3: values.contained_pieces_N3 || '',
        consolidationLevelN4: values.consolidation_level_N4 || '',
        containedPiecesN4: values.contained_pieces_N4 || '',
      }}
    >
      {({ setFieldValue, values, handleChange, handleBlur, handleSubmit }) => (
        <>
          {showItemModal ? (
            <TableModal
              url={Urls.itemCategory}
              title="Item Category"
              show={showItemModal}
              handleClose={handleCloseItemModal}
              formName={'itemCategory'}
              columnToTransform={itemCategoryColumnToTransform}
              handleConfirmation={handleItemConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showDimensionalUnitModal ? (
            <TableModal
              url={Urls.dimensionalUnit}
              title="Dimensional Unit Code"
              show={showDimensionalUnitModal}
              handleClose={handleCloseDimensionalTypeModal}
              columnToTransform={[]}
              handleConfirmation={handleDimensionalUnitConfirm}
            />
          ) : null}
          <Form
            onSubmit={(e) => {
              let completeLevel = true;
              consolidationLevels.map((level) => {
                if (
                  values[`consolidationLevelN${level.id}`] !== '' &&
                  values[`consolidationLevelN${level.id}`] !== null
                ) {
                  if (
                    values[`containedPiecesN${level.id}`] !== '' &&
                    values[`containedPiecesN${level.id}`]
                  ) {
                    completeLevel = true;
                  } else {
                    completeLevel = false;
                    notification('Complete the consolidation level and contained pieces', 'error');
                  }
                } else if (
                  values[`containedPiecesN${level.id}`] !== '' &&
                  values[`containedPiecesN${level.id}`]
                ) {
                  completeLevel = false;
                  notification('Complete the consolidation level and contained pieces', 'error');
                }
              });
              e.preventDefault();
              completeLevel
                ? handleSubmit()
                : setError('Complete the consolidation level and contained pieces');
            }}
          >
            <Form.Row>
              <TextField
                label="Reference Code"
                name="referenceCode"
                type="text"
                value={values.referenceCode}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    reference_code: e.target.value,
                  });
                }}
              />
              <TextField
                label="Description"
                name="description"
                type="text"
                value={values.description}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    item_description: e.target.value,
                  });
                }}
              />
            </Form.Row>
            <Form.Row>
              {optionFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setValuesToEdit({
                      ...valuesToEdit,
                      item_category: option ? option.id : '',
                    });
                    setFieldValue('itemCategory', option ? option.id : '');
                    setItemCategoryCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            itemCategoryDescription: option.description,
                          }
                        : { ...descriptions, itemCategoryDescription: '' }
                    );
                    setShowOptionFromModal(false);
                  }}
                  name="itemCategory"
                  label="Item Category"
                  options={itemCategoryData.options}
                  setShow={setShowItemModal}
                  defaultValue={itemCategoryDefaultOptions}
                  value={itemCategoryDefaultOptions}
                  description={itemCategoryDefaultOptions.description}
                />
              ) : (
                <CustomSelect
                  onChange={(option) => {
                    console.log(option);
                    setValuesToEdit({
                      ...valuesToEdit,
                      item_category: option ? option.id : '',
                    });
                    setFieldValue('itemCategory', option ? option.id : '');
                    setItemCategoryCodeTransformed(option ? option.value : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            itemCategoryDescription: option.description,
                          }
                        : { ...descriptions, itemCategoryDescription: '' }
                    );
                  }}
                  name="itemCategory"
                  label="Item Category"
                  options={itemCategoryData.options}
                  setShow={setShowItemModal}
                  defaultValue={{
                    value: values.itemCategory,
                    label: values.itemCategory,
                  }}
                  description={descriptions.itemCategoryDescription}
                />
              )}
              <TextField
                label="Model"
                name="model"
                type="text"
                value={values.model}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    item_model: e.target.value,
                  });
                }}
              />
              {showFields ? (
                <TextField
                  label="Barcode"
                  name="barcode"
                  value={values.barcode}
                  onChange={(e) => {
                    handleChange(e);
                    setValuesToEdit({
                      ...valuesToEdit,
                      barcode: e.target.value,
                    });
                  }}
                />
              ) : null}
            </Form.Row>

            <Form.Row>
              <SelectItems
                options={options}
                label="Item Type"
                name="itemType"
                handleChange={handleChange}
                onBlur={handleBlur}
                errorMessageEnabled
                defaultValue={values.itemType}
                onChange={(option) => {
                  handleChange(option);
                  setValuesToEdit({
                    ...valuesToEdit,
                    item_type: option ? option.target.value : '',
                  });
                }}
              />
              <Form.Group controlId="formImage" className="mb-3">
                <Form.Label className="label">
                  Item Image
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <IoMdHelpCircle />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(file) =>
                    uploadFiles(
                      file,
                      setImage,
                      setImageName,
                      setImageLoading,
                      notification,
                      setError,
                      accessToken
                    )
                  }
                  accept=".gif,.jpg,.jpeg,.png"
                  size="sm"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <TextField
                label="Image Name"
                name="item image"
                type="text"
                value={imageName}
                readOnly
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    documentation: e.target.value || '',
                  });
                }}
              />
              {imageLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                ></Spinner>
              ) : (
                <GiCancel
                  size={25}
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                  onClick={() => {
                    setImage('');
                    setImageName('');
                  }}
                />
              )}
              {imageName !== '' ? (
                <a target="_blank" href={image} rel="noreferrer">
                  <HiExternalLink
                    size={26}
                    style={{
                      'margin-top': '2.5rem',
                      'margin-left': '0.5rem',
                    }}
                  />
                </a>
              ) : null}
            </Form.Row>
            {consolidationLevels.map((level, idx) => (
              <Form.Row key={idx}>
                <CustomSelect
                  disabled={level.disabled}
                  onChange={(option) => {
                    const levels = { ...levelsFilled };
                    if (
                      values[`containedPiecesN${level.id}`] !== '' &&
                      values[`containedPiecesN${level.id}`] !== null
                    ) {
                      levels[`level${level.id}_pieces`] = true;
                    }
                    if (
                      values[`consolidationLevelN${level.id}`] !== '' &&
                      values[`consolidationLevelN${level.id}`] !== null
                    ) {
                      levels[`level${level.id}_unit`] = true;
                    }
                    setLevelsFilled(levels);
                    setValuesToEdit({
                      ...valuesToEdit,
                      [`consolidation_level_N${level.id}`]: option ? option.id : '',
                    });
                    setFieldValue(`consolidationLevelN${level.id}`, option ? option.id : '');
                    const dimensionalUnitCodes = {
                      ...dimensionalUnitCodeTransformed,
                    };
                    dimensionalUnitCodes[`consolidationLevelN${level.id}`] = option
                      ? option.value
                      : '';
                    setDimensionalUnitCodeTransformed(dimensionalUnitCodes);
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            [`consolidationLevelN${level.id}`]: option.description,
                          }
                        : {
                            ...descriptions,
                            [`consolidationLevelN${level.id}`]: '',
                          }
                    );
                  }}
                  name={`consolidationLevelN${level.id}`}
                  label={`Consolidation level N${level.id}`}
                  options={dimensionalUnitData.options}
                  setShow={setShowDimensionalUnitModal}
                  errorMessageEnablen
                  defaultValue={{
                    value:
                      values[`consolidationLevelN${level.id}`] !== ''
                        ? values[`consolidationLevelN${level.id}`]
                        : null,
                    label:
                      values[`consolidationLevelN${level.id}`] !== ''
                        ? values[`consolidationLevelN${level.id}`]
                        : null,
                  }}
                  description={descriptions[`consolidationLevelN${level.id}`]}
                />

                <TextField
                  disabled={level.disabled}
                  label={`Contained pieces N${level.id}`}
                  name={`containedPiecesN${level.id}`}
                  value={values[`containedPiecesN${level.id}`]}
                  errorMessageEnabled
                  type="number"
                  min="1"
                  onChange={(e) => {
                    handleChange(e);
                    const levels = { ...levelsFilled };
                    if (e.target.value !== '' && e.target.value !== null) {
                      levels[`level${level.id}_pieces`] = true;
                    }
                    if (
                      values[`consolidationLevelN${level.id}`] !== '' &&
                      values[`consolidationLevelN${level.id}`] !== null
                    ) {
                      levels[`level${level.id}_unit`] = true;
                    }
                    setLevelsFilled(levels);
                    setValuesToEdit({
                      ...valuesToEdit,
                      [`contained_pieces_N${level.id}`]: e.target.value || 0,
                    });
                  }}
                />

                <Col md={2}>
                  <Form.Group>
                    <p className="pt-5">
                      {dimensionalUnitCodeTransformed[`consolidationLevelN${level.id - 1}`] ||
                        values[`consolidationLevelN${level.id - 1}`]}
                    </p>
                  </Form.Group>
                </Col>
              </Form.Row>
            ))}

            <Form.Row>
              <TextField
                label="Safety Stock"
                name="safetyStock"
                type="text"
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    safety_stock: e.target.value || 0,
                  });
                }}
              />

              <TextField
                label="Ordering Point"
                name="orderingPoint"
                type="text"
                value={values.orderingPoint}
              />
            </Form.Row>
            <Form.Row>
              <TextField
                label="Mininum Stock"
                name="minimumStock"
                type="text"
                value={values.minimumStock}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    minimum_stock: e.target.value || 0,
                  });
                }}
              />
              <TextField
                label="Maximum Stock"
                name="maximumStock"
                type="text"
                value={values.maximumStock}
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    maximum_stock: e.target.value || 0,
                  });
                }}
              />
            </Form.Row>
            <Form.Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label className="label">
                  Select Documentation
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <IoMdHelpCircle />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(file) =>
                    uploadFiles(
                      file,
                      setDocument,
                      setDocumentName,
                      setDocumentLoading,
                      notification,
                      setError,
                      accessToken
                    )
                  }
                  accept=".docx,.pdf,.odt,.txt"
                  size="sm"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <TextField
                label="Attached Document"
                name="documentation"
                type="text"
                value={documentName}
                readOnly
                onChange={(e) => {
                  handleChange(e);
                  setValuesToEdit({
                    ...valuesToEdit,
                    documentation: e.target.value || '',
                  });
                }}
              />
              {showFields ? (
                <CustomSelect
                  onChange={(option) => {
                    setFieldValue('label', option ? option.id : '');
                    setLabelCodeTransformed(option ? option.value : '');
                  }}
                  name="label"
                  label="Label"
                  options={labelData.options}
                  setShow={setShow}
                  defaultValue={values.label}
                />
              ) : null}
              {documentLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                ></Spinner>
              ) : (
                <GiCancel
                  size={23}
                  style={{
                    'margin-top': '2.6rem',
                    'margin-left': '0.5rem',
                  }}
                  onClick={() => {
                    setDocument('');
                    setDocumentName('');
                  }}
                />
              )}
              {documentName !== '' ? (
                <a target="_blank" href={document} rel="noreferrer">
                  <HiExternalLink
                    size={26}
                    style={{
                      'margin-top': '2.5rem',
                      'margin-left': '0.5rem',
                    }}
                  />
                </a>
              ) : null}
            </Form.Row>

            <Button className="fpb-btn mt-3" type="submit">
              Confirm
            </Button>
            <Button className="fpb-btn-inverse mt-3 ml-2" onClick={() => setAddForm(false)}>
              Close
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Item;
