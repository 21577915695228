import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ExportButton from '../ExportExcel/ExportButton';
import FormContainer from '../Forms/FormContainer';
import FPBTable from '../FPBTable/FPBTable';
import ActionConfirmation from '../ActionConfirmation/ActionConfirmation';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import Urls from '../Urls';
import useForm from '../useForm';
import AlertMessage from '../AlertMessage/AlertMessage';
import { setCurrentPageAction, setTotalCountAction } from '../../redux/actions/paginationAction';
import { getExportDataAsync } from '../../redux/slices/exportSlice';
import mapTable from '../mapTableService';
import { notification } from '../AlertMessage/ToastifyAlert';

const TableSection = ({
  url,
  formName,
  title,
  disableAdd,
  disableDelete,
  disableEdit,
  columnToTransform,
}) => {
  const [addReload, setAddReload] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteRows, setDeleteRows] = useState();
  const [deleteChange, setDeleteChange] = useState(false);
  const [addedRow, setAddedRow] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageUpdate, setErrorMessageUpdate] = useState('');
  const [columnsToHide, setColumnsToHide] = useState();
  const [token, setToken] = useState(localStorage.getItem('accessToken') || null);
  // pagination
  const [filters, setFilters] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);

  const dispatchTotalCount = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatchTotalCount(setTotalCountAction(totalCount));
  };
  const dispatch = useDispatch();

  useEffect(() => {
    switch (formName) {
      case 'item':
        setColumnsToHide([
          'item_type',
          'item_model',
          'barcode',
          'contained_pieces_N0',
          'contained_pieces_N1',
          'contained_pieces_N2',
          'contained_pieces_N3',
          'contained_pieces_N4',
          'consolidation_level_N0',
          'consolidation_level_N1',
          'consolidation_level_N2',
          'consolidation_level_N3',
          'consolidation_level_N4',
          'label',
          'item_image',
          'maximum_stock',
          'minimum_stock',
          'ordering_point',
          'safety_stock',
        ]);
        break;
      case 'storageCenter':
        setColumnsToHide([
          'country',
          'province',
          'address',
          'geocoordinate_latitude',
          'geocoordinate_longitude',
        ]);
        break;

      case 'storageLocation':
        setColumnsToHide(['coordinate_x', 'coordinate_y', 'coordinate_z']);
        break;

      case 'customer':
        setColumnsToHide([
          'commercial_name',
          'contact_person',
          'email',
          'telephone_number',
          'cellphone_number',
        ]);
        break;

      case 'supplier':
        setColumnsToHide([
          'commercial_name',
          'contact_person',
          'email',
          'telephone_number',
          'cellphone_number',
        ]);
        break;
      case 'batch':
        setColumnsToHide(['rated_life', 'extended_lifetime', 'responsible']);
        break;

      case 'itemBatchRelation':
        setColumnsToHide(['ibr']);
        break;

      case 'company':
        setColumnsToHide(['logo', 'created_at', 'updated_at', 'time_zone']);
        break;

      case 'user':
        setColumnsToHide([
          'default_module',
          'is_verified',
          'is_active',
          'is_staff',
          'created_at',
          'updated_at',
          'auth_provider',
          'last_login',
          'is_superuser',
        ]);
      case 'module':
        setColumnsToHide(['created_at', 'updated_at']);
      case 'companyModule':
        setColumnsToHide(['company', 'module', 'created_at', 'updated_at']);
        break;
      case 'groupPermission':
        setColumnsToHide(['permissions']);
        break;
      case 'userGroupPermission':
        setColumnsToHide(['groups']);
        break;
      case 'manualReport':
        setColumnsToHide([
          'work_center_description',
          'work_center_category',
          'variable_description',
          'calculation_variable',
          'dimensional_unit_description',
          'operation_center',
          'product_description',
          'process_description',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
          'segmentation_in_minutes',
        ]);
        break;
      case 'eventReport':
        setColumnsToHide([
          'product_per_production_order',
          'event_type',
          'event_category',
          'calculation_variable',
          'event',
          'variable',
          'dimensional_unit',
          'work_center_category',
          'process',
          'comments',
          'created_by',
          'created_at',
          'updated_by',
          'updated_at',
        ]);
        break;

      default:
        setColumnsToHide(['', '', '']);
    }
  }, []);

  const {
    handleChange,
    values,
    setValues,
    handleAPIRequest,
    handleAPIRequestUpdate,
    added,
    edited,
  } = useForm(
    url,
    setAddForm,
    setAlertMessage,
    setErrorMessage,
    setErrorMessageUpdate,
    errorMessageUpdate
  );

  const onAdded = (r) => {
    setAddForm(false);
    setAddedRow(r);
    setAddReload(!addReload);
    notification('Item created successfully', 'success');
  };

  const handleConfirmation = () => {
    setShow(false);
    setConfirmDelete(true);
  };

  const handleClose = () => setShow(false);

  const handleDelete = async (id) => {
    setShow(true);
    setDeleteRows(id);
  };

  const nullableFields = [
    'coordinate_x',
    'coordinate_y',
    'coordinate_z',
    'manufacturing_date',
    'expiration_date',
    'customer_identification',
    'supplier_identification',
    'geocoordinate_latitude',
    'geocoordinate_longitude',
    'consolidation_level_N1',
    'consolidation_level_N2',
    'consolidation_level_N3',
    'consolidation_level_N4',
  ];

  const transformEditedCode = (insertedCode, name) => {
    let transformedResult;
    columnToTransform.map((elem) => {
      if (elem.name === name) {
        transformedResult = axios
          .get(Urls[elem.url], {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            let transformedId;
            const Data = res.data;
            Data.map((row) => {
              row[elem.field] === insertedCode ? (transformedId = row.id) : null;
            });
            return transformedId;
          });
      }
    });
    return transformedResult;
  };

  // data is the array of modified fields ([{field_name:value},{}...{}])
  const handleEdit = async (id, data) => {
    // get the name of the fields to edit
    const fieldsName = Object.keys(data);
    const editedRow = {};
    const res = await Promise.all(
      fieldsName.map(async (fieldName) => {
        // verify that the field requires code transformation
        const foundTransform = columnToTransform.find((el) => el.name === fieldName);
        // if the edited field is of type date or integer we send null instead of '' (when deleting field content)
        if (nullableFields.includes(fieldName) && data[fieldName] === '') {
          editedRow[fieldName] = null;
          return editedRow;
        }
        // in the case of editing a foreign key, first transform the code to the corresponding id
        if (foundTransform) {
          const transformedCode = await transformEditedCode(data[fieldName], fieldName);
          editedRow[fieldName] = transformedCode;
          return editedRow;
        }
        editedRow[fieldName] = data[fieldName];
        return editedRow;
      })
    );
    const dataToUpdate = res[res.length - 1];

    axios
      .patch(
        `${url}/${id}`,
        { ...dataToUpdate },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {})
      .catch((err) => console.log(err));
  };

  // Transform the inserted code to the respective id
  const transformCode = (e, name) => {
    const insertedCode = e.target.value;

    columnToTransform.map((elem) => {
      elem.name === name
        ? axios
            .get(Urls[elem.url], {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              let transformedId;
              const Data = res.data;
              Data.map((row) => {
                row[elem.field] === insertedCode ? (transformedId = row.id) : null;
              });
              setValues({ ...values, [elem.name]: transformedId });
            })
        : null;
    });
  };

  useEffect(() => {
    function checkUserData() {
      const item = localStorage.getItem('accessToken');
      if (item) {
        setToken(item);
      }
    }

    window.addEventListener('storage', checkUserData);

    return () => {
      window.removeEventListener('storage', checkUserData);
    };
  }, []);

  useEffect(() => {
    if (confirmDelete === true) {
      axios
        .delete(`${url}/${deleteRows}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          setDeleteChange(true);
          setConfirmDelete(false);
          setDeleteChange(false);
          notification('Item removed successfully', 'success');
        });
    }
  }, [confirmDelete]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setToken(localStorage.getItem('accessToken'));
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (filters.length > 0) {
      dispatch(setCurrentPageAction(0));
    }
  }, [filters]);

  const getQueryString = () => {
    let filter = filters
      .reduce((acc, { columnName, operation, value }) => {
        const lowerCaseValue = value.toLowerCase();
        if (columnName.includes('consolidation_level')) {
          const dimensionalUnitId = mapTable.getDimensionalUnitIdFromCode(lowerCaseValue);
          acc.push(`${columnName}__id=${encodeURIComponent(dimensionalUnitId)}`);
        } else {
          switch (operation) {
            case 'contains': {
              acc.push(`${columnName}__icontains=${encodeURIComponent(value)}`);
              break;
            }
            case 'equal': {
              acc.push(`${columnName}=${encodeURIComponent(value)}`);
              break;
            }
            case 'greaterThanOrEqual': {
              acc.push(`${columnName}__gte=${encodeURIComponent(value)}`);
              break;
            }
            case 'lessThanOrEqual': {
              acc.push(`${columnName}__lte=${encodeURIComponent(value)}`);
              break;
            }
            default:
              acc.push(`${columnName}__${operation}=${encodeURIComponent(value)}`);
              break;
          }
        }
        return acc;
      }, [])
      .join('&');

    if (filters.length > 1) {
      filter = `${filter}`;
    }

    const urlTable = `${url}?page=${currentPage + 1}&page_size=${pageSize}&${filter}`;
    return { urlTable, filter };
  };

  useEffect(() => {
    const queryString = getQueryString();
    const getTableData = async () => {
      try {
        const res = await axios.get(queryString.urlTable, {
          headers: {
            Authorization: `Bearer  ${token}`,
          },
        });
        const { results } = res.data;
        setTotalCount(res.data.count);
        if (results.length > 0) {
          // discard the fields the user will not visualize in tables
          var headersList = [];
          let returnColumn;
          if (formName == 'manualReport') {
            headersList = Object.keys(results[0]).filter((elem) => {
              if (
                elem === 'id' ||
                elem === 'manual_table' ||
                elem === 'product_per_production_order' ||
                elem === 'quality_report'
              ) {
                returnColumn = false;
              } else {
                returnColumn = true;
              }
              return returnColumn;
            });
          } else {
            headersList = Object.keys(results[0]).filter((elem) => {
              if (
                elem === 'fk_info' ||
                elem === 'id' ||
                elem === 'created' ||
                elem === 'updated' ||
                elem === 'label' ||
                elem === 'barcode' ||
                elem === 'password'
              ) {
                returnColumn = false;
              } else {
                returnColumn = true;
              }
              return returnColumn;
            });
          }
          const tableInformation = await Promise.all(
            results.map(async (row) => {
              const transformedResponse = {};
              // make a request for each id to be converted to code in the current row
              const [rowModified] = await Promise.all(
                columnToTransform.map(async (transform) => {
                  if (row[transform.name] != null) {
                    const transformed = await axios
                      .get(`${Urls[transform.url]}/${row[transform.name]}`, {
                        headers: { Authorization: `Bearer ${token}` },
                      })
                      .then((res) => {
                        transformedResponse[transform.name] = res.data[transform.field];
                        return transformedResponse;
                      });

                    return transformed;
                  }
                  transformedResponse[transform.name] = null;
                  return transformedResponse;
                })
              );

              // use default row and replace transformed columns with codes
              const result = { ...row };
              columnToTransform.map((transform) => {
                result[transform.name] = rowModified != null ? rowModified[transform.name] : null;
              });
              return result;
            })
          );

          setHeaders(headersList);
          setTableData(tableInformation);
        } else {
          setTableData([]);
          setHeaders([]);
        }
      } catch (err) {
        console.log(err);
      }

      setAddForm(false);
      setLastQuery(queryString.urlTable);
    };
    getTableData();
  }, [url, token, pageSize, currentPage, filters, addReload]);

  const handleExport = async () => {
    const queryString = getQueryString();
    dispatch(getExportDataAsync({ urlExport: url, filters: queryString.filter }));
  };

  return (
    <>
      <HeaderTitle title={title} />
      <ActionConfirmation
        show={show}
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
      />
      <div className="text-left btn-container ">
        {disableAdd ? null : (
          <Button
            className="fpb-btn "
            onClick={() => {
              setAddForm(true);
            }}
            disabled={addForm}
          >
            Add
          </Button>
        )}

        <Modal show={addForm} onHide={setAddForm} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add {title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMessage ? (
              <AlertMessage
                variant="danger"
                message={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            ) : null}
            <FormContainer
              handleChange={handleChange}
              values={values}
              handleAPIRequest={handleAPIRequest}
              added={added}
              transformCode={transformCode}
              formName={formName}
              onAdded={onAdded}
              setAddForm={setAddForm}
              setAlert={setAlertMessage}
              setError={setErrorMessage}
            />
          </Modal.Body>
        </Modal>
        <ExportButton formName={formName} fileName={title + ' Table'} handleExport={handleExport} />
        {alertMessage ? (
          <AlertMessage
            variant="success"
            message={alertMessage}
            setAlertMessage={setAlertMessage}
          />
        ) : null}
      </div>

      <FPBTable
        readOnly={disableDelete ? true : false}
        editOnly={disableEdit ? false : disableDelete}
        headers={headers}
        tableData={tableData}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        deleteChange={deleteChange}
        addedRow={addedRow}
        columnsToHide={columnsToHide}
        handleChange={handleChange}
        handleAPIRequestUpdate={handleAPIRequestUpdate}
        added={added}
        transformCode={transformCode}
        formName={formName}
        onAdded={onAdded}
        setAddForm={setAddForm}
        setAlert={setAlertMessage}
        setError={setErrorMessageUpdate}
        edited={edited}
        errorMessageUpdate={errorMessageUpdate}
        title={title}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        setFilters={setFilters}
      />
    </>
  );
};

export default TableSection;
