import React, { useEffect, useState } from 'react';
import { Col, Form, Button, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import AlertMessage from '../../AlertMessage/AlertMessage';
import apiService from '../../apiService';
import CustomSelect from '../../FormComponents/CustomSelect/CustomSelect';
import CustomSelectDefault from '../../FormComponents/CustomSelect/CustomSelectDefault';
import Select from 'react-select';
import Urls from '../../Urls';
import TextField from '../../FormComponents/TextField/TextField';
import TableModal from '../../TableModal/TableModal';
import { setIsModalPaginationAction } from '../../../redux/actions/paginationAction';
import { notification } from '../../AlertMessage/ToastifyAlert';
import ActionConfirmationTransaction from '../../ActionConfirmation/ActionConfirmationTransaction';
import axios from 'axios';

const TransactionsForm = ({
  handleAPIRequest,
  internalValues,
  type,
  autoCompleted,
  setAutoCompleted,
  validateItemCode,
  setItemSelected,
  setBatchSelected,
  setCostSelected,
  setStorageCenterSelected,
  setStorageCenterDestinationSelected,
  allowedItems,
  allowedBatches,
  allowedCosts,
  allowedStorageCenters,
  allowedStorageLocations,
  allowedStorageLocationDestination,
  user,
}) => {
  const [isError, setIsError] = useState(false);
  const [show, setShow] = useState(false);
  const [costDisabled, setCostDisabled] = useState(true);
  const [batchAndStorageCenterDisabled, setBatchAndStorageCenterDisabled] = useState(true);
  const [storageLocationDisabled, setStorageLocationDisabled] = useState(true);
  const [storageLocationDestinationDisabled, setStorageLocationDestinationDisabled] =
    useState(true);
  const [clearItemChange, setClearItemChange] = useState(false);
  const [clearBatchChange, setClearBatchChange] = useState(false);
  const [clearStorageCenterChange, setClearStorageCenterChange] = useState(false);
  const [clearStorageCenterDestinationChange, setClearStorageCenterDestinationChange] =
    useState(false);
  const [clearForm, setClearForm] = useState(false);

  const [locationRequired, setLocationRequired] = useState(false);
  const [locationDestinationRequired, setLocationDestinationRequired] = useState(false);

  const [showItemModal, setShowItemModal] = useState(false);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const [showStorageCenterModal, setShowStorageCenterModal] = useState(false);
  const [showStorageLocationModal, setShowStorageLocationModal] = useState(false);
  const [showCostModal, setShowCostModal] = useState(false);
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [showStorageLocationDestinationModal, setShowStorageLocationDestinationModal] =
    useState(false);
  const [showStorageDestinationCodeModal, setShowStorageDestinationCodeModal] = useState(false);
  const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);
  const [fakeModal, setFakeModal] = useState(false);
  const [itemValueSelected, setItemValueSelected] = useState();

  const [storageLocationSelected, setStorageLocationSelected] = useState();
  const [storageLocationValueSelected, setStorageLocationValueSelected] = useState();
  const [storageCenterValueSelected, setStorageCenterValueSelected] = useState();

  const [itemIdSelected, setItemIdSelected] = useState();
  const [storageCenterCodeValueSelected, setStorageCenterCodeValueSelected] = useState();
  const [storageCenterDestinationCodeValueSelected, setStorageCenterDestinationCodeValueSelected] =
    useState();
  const [itemDefaultValues, setItemDefaultValues] = useState({});
  const [itemFromModal, setItemFromModal] = useState(false);
  const [batchDefaultValues, setBatchDefaultValues] = useState({});
  const [batchFromModal, setBatchFromModal] = useState(false);
  const [batchIdSelected, setBatchIdSelected] = useState();
  const [storageCenterDefaultValues, setStorageCenterDefaultValues] = useState({});
  const [storageCenterFromModal, setStorageCenterFromModal] = useState(false);
  const [storageLocationDefaultValues, setStorageLocationDefaultValues] = useState({});
  const [storageLocationFromModal, setStorageLocationFromModal] = useState(false);
  const [costCodeDefaultValues, setCostCodeDefaultValues] = useState({});
  const [costCodeFromModal, setCostCodeFromModal] = useState(false);
  const [entryOrderDefaultValues, setEntryOrderDefaultValues] = useState({});
  const [entryOrderFromModal, setEntryOrderFromModal] = useState(false);
  const [dispatchOrderDefaultValues, setDispatchOrderDefaultValues] = useState({});
  const [dispatchOrderFromModal, setDispatchOrderFromModal] = useState(false);
  const [adjustmentDefaultValues, setAdjustmentDefaultValues] = useState({});
  const [adjustmentFromModal, setAdjustmentFromModal] = useState(false);
  const [storageLocationDestinationDefaultValues, setStorageLocationDestinationDefaultValues] =
    useState({});
  const [storageLocationDestinationFromModal, setStorageLocationDestinationFromModal] =
    useState(false);
  const [storageDestinationCodeDefaultValues, setStorageDestinationCodeDefaultValues] = useState(
    {}
  );
  const [storageDestinationCodeFromModal, setStorageDestinationCodeFromModal] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [dimensionalDescription, setDimensionalDescription] = useState('');
  const [descriptions, setDescriptions] = useState({
    itemDescription: '',
    storageCenterDescription: '',
    storageLocationDescription: '',
    storageCenterDestinDescription: '',
    storageLocationDestinDescription: '',
    adjustmentConceptDescription: '',
  });

  // data containers to share information to react-select components
  const [itemData, setItemData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [batchData, setBatchData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [costData, setCostData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [entryOrderData, setEntryOrderData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [dispatchOrderData, setDispatchOrderData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [storageCenterData, setStorageCenterData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [storageLocationData, setStorageLocationData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [storageLocationDestinationData, setStorageLocationDestinationData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [adjustmentConceptData, setAdjustmentConceptData] = useState({
    headers: [],
    tableData: [],
    options: [],
  });

  const [handleValues, setHandleValues] = useState({});
  const [handleData, setHandleData] = useState({});
  const [totalActualStock, setTotalActualStock] = useState();
  const [changeStock, setChangeStock] = useState();
  const [costValueSelected, setCostValueSelected] = useState();
  const [batchValueSelected, setBatchValueSelected] = useState();
  const [token] = useState(localStorage.getItem('accessToken'));
  const urlCompute = Urls.compute;
  const itemColumnToTransform = [
    {
      name: 'item_category',
      url: 'itemCategory',
      field: 'item_category_code',
    },
    {
      name: 'label',
      url: 'label',
      field: 'label_code',
    },
    {
      name: 'consolidation_level_N0',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N1',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N2',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N3',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
    {
      name: 'consolidation_level_N4',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
  ];
  const storageLocationColumnToTransform = [
    {
      name: 'storage_center_code',
      url: 'storageCenter',
      field: 'storage_center_code',
    },
  ];
  const costColumnToTransform = [
    {
      name: 'unique_cost_type_code',
      url: 'costType',
      field: 'unique_cost_type_code',
    },
    {
      name: 'item_reference_code',
      url: 'item',
      field: 'reference_code',
    },
    {
      name: 'dimensional_unit_code',
      url: 'dimensionalUnit',
      field: 'dimensional_unit_code',
    },
  ];
  const entryColumnToTransform = [
    {
      name: 'supplier_identification',
      url: 'supplier',
      field: 'supplier_identification',
    },
  ];
  const dispatchColumnToTransform = [
    {
      name: 'customer_identification',
      url: 'customer',
      field: 'customer_identification',
    },
  ];

  //pagination
  const dispatchIsModalPagination = useDispatch();
  const setIsModalPagination = () => {
    dispatchIsModalPagination(setIsModalPaginationAction());
  };

  // Fill selectable fields when first loading the page
  useEffect(async () => {
    if (allowedItems) {
      try {
        const resItem = await apiService.getData(Urls.item, 'reference_code', allowedItems);

        const resEntryOrder = await apiService.getData(Urls.entryOrder, 'entry_order_code');

        const resDispatchOrder = await apiService.getData(
          Urls.dispatchOrder,
          'dispatch_order_code'
        );

        const resAdjustmentConcept = await apiService.getData(Urls.adjustment, 'adjustment_code');
        if (resItem !== undefined) {
          setItemData({
            headers: resItem.headersList,
            tableData: resItem.data,
            options: resItem.results,
          });
        }
        if (resEntryOrder !== undefined) {
          setEntryOrderData({
            headers: resEntryOrder.headersList,
            tableData: resEntryOrder.data,
            options: resEntryOrder.results,
          });
        }

        if (resDispatchOrder !== undefined) {
          setDispatchOrderData({
            headers: resDispatchOrder.headersList,
            tableData: resDispatchOrder.data,
            options: resDispatchOrder.results,
          });
        }

        if (resAdjustmentConcept !== undefined) {
          setAdjustmentConceptData({
            headers: resAdjustmentConcept.headersList,
            tableData: resAdjustmentConcept.data,
            options: resAdjustmentConcept.results,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [allowedItems]);

  // Fill selectable batches after selecting a item
  useEffect(() => {
    if (allowedBatches.length > 0) {
      (async () => {
        const resBatch = await apiService.getData(Urls.batch, 'batch_code', allowedBatches);
        setBatchData({
          headers: resBatch.headersList,
          tableData: resBatch.data,
          options: resBatch.results,
        });
      })();
    } else {
      setBatchData({});
    }
  }, [allowedBatches]);

  // Fill selectable storage centers after selecting a item
  useEffect(async () => {
    if (allowedStorageCenters.length > 0) {
      const resStorageCenter = await apiService.getData(
        Urls.storageCenter,
        'storage_center_code',
        allowedStorageCenters
      );
      setStorageCenterData({
        headers: resStorageCenter.headersList,
        tableData: resStorageCenter.data,
        options: resStorageCenter.results,
      });
    } else {
      setStorageCenterData({});
    }
  }, [allowedStorageCenters]);

  // Fill selectable costs  after selecting a batch
  useEffect(async () => {
    if (allowedCosts.length > 0) {
      const resCostCode = await apiService.getData(
        Urls.cost,
        'unique_unit_cost_code',
        allowedCosts
      );
      setCostData({
        headers: resCostCode.headersList,
        tableData: resCostCode.data,
        options: resCostCode.results,
      });
    } else {
      setCostData({});
    }
  }, [allowedCosts]);

  // Fill selectable storageLocations after selecting a storage center
  useEffect(async () => {
    if (allowedStorageLocations.length > 0) {
      setLocationRequired(true);
      const resStorageLocation = await apiService.getData(
        Urls.storageLocation,
        'location_code',
        allowedStorageLocations
      );
      setStorageLocationData({
        headers: resStorageLocation.headersList,
        tableData: resStorageLocation.data,
        options: resStorageLocation.results,
      });
    } else {
      setLocationRequired(false);
      setStorageLocationData({});
    }
  }, [allowedStorageLocations]);

  // Fill selectable storage location destination after selecting a storage center destination (only when creating transfer transaction)
  useEffect(async () => {
    if (allowedStorageLocationDestination.length > 0) {
      setLocationDestinationRequired(true);
      const resStorageLocationDestination = await apiService.getData(
        Urls.storageLocation,
        'location_code',
        allowedStorageLocationDestination
      );
      setStorageLocationDestinationData({
        headers: resStorageLocationDestination.headersList,
        tableData: resStorageLocationDestination.data,
        options: resStorageLocationDestination.results,
      });
    } else {
      setLocationDestinationRequired(false);
      setStorageLocationDestinationData({});
    }
  }, [allowedStorageLocationDestination]);

  useEffect(() => {
    if (clearItemChange === true) {
      setClearItemChange(false);
    }
  }, [clearItemChange]);

  useEffect(() => {
    if (clearBatchChange === true) {
      setClearBatchChange(false);
    }
  }, [clearBatchChange]);

  useEffect(() => {
    if (clearStorageCenterChange === true) {
      setClearStorageCenterChange(false);
    }
  }, [clearStorageCenterChange]);

  useEffect(() => {
    if (clearStorageCenterDestinationChange === true) {
      setClearStorageCenterDestinationChange(false);
    }
  }, [clearStorageCenterDestinationChange]);

  useEffect(() => {
    if (clearForm === true) {
      setClearForm(false);
    }
  }, [clearForm]);

  useEffect(() => {
    setDimensionalDescription(autoCompleted.dimensional_unit_description);
  }, [autoCompleted]);

  useEffect(() => {
    setDescriptions({ ...descriptions, storageLocationDestinDescription: '' });
  }, [descriptions.storageCenterDestinDescription]);

  // only validate the fields entered by the user
  const transactionValidate = Yup.object({
    itemReferenceCode: Yup.string().required('Required'),
    amount: Yup.number()
      .required('Required')
      .typeError('Must be a  number (5, 5.6, 7.85)')
      .positive('Must be a positive number'),
    batchCode: Yup.string().required('Required'),
    costCode: Yup.string().required('Required'),
    entryOrderCode: Yup.string().nullable(),
    dispatchOrderCode: Yup.string().nullable(),
    storageCenterCode: Yup.string().required('Required'),
    storageLocationCode: locationRequired
      ? Yup.string().required('Required').nullable()
      : Yup.string().nullable(),
    storageCenterDestinationCode:
      type == 'transfer' ? Yup.string().required('Required').nullable() : Yup.string().nullable(),
    storageLocationDestinationCode: locationDestinationRequired
      ? Yup.string().required('Required').nullable()
      : Yup.string().nullable(),
    returnTransaction:
      type == 'entry' || type == 'output' ? Yup.string().required('Required') : Yup.string(),
    adjustmentType: type == 'adjustment' ? Yup.string().required('Required') : Yup.string(),
    adjustmentConcept:
      type == 'adjustment' ? Yup.string().required('Required') : Yup.string().nullable(),
  });

  // function that closes all modals
  const handleCloseModals = () => {
    setIsModalPagination();
    setShowItemModal(false);
    setShowBatchModal(false);
    setShowStorageCenterModal(false);
    setShowStorageLocationModal(false);
    setShowCostModal(false);
    setShowEntryModal(false);
    setShowDispatchModal(false);
    setShowStorageLocationDestinationModal(false);
    setShowStorageDestinationCodeModal(false);
    setShowAdjustmentModal(false);
  };

  const handleItemReferenceCodeConfirm = (selected, title, setFieldValue) => {
    setItemDefaultValues({
      id: selected.id,
      value: selected.reference_code,
      label: selected.reference_code,
      description: selected.item_description,
    });
    setClearItemChange(true);
    setItemSelected({
      id: selected.id,
      value: selected.reference_code,
      label: selected.reference_code,
      description: selected.item_description,
    });
    setItemValueSelected(selected.reference_code);
    setItemIdSelected(selected.id);
    setBatchAndStorageCenterDisabled(false);
    validateItemCode({
      id: selected.id,
      value: selected.reference_code,
      label: selected.reference_code,
      description: selected.item_description,
    });
    setFieldValue('itemReferenceCode', selected.id);
    setItemFromModal(true);
    setShowItemModal(false);
  };

  const handleBatchCodeConfirm = (selected, title, setFieldValue) => {
    setBatchDefaultValues({
      id: selected.id,
      value: selected.batch_code,
      label: selected.batch_code,
    });
    setClearBatchChange(true);
    setBatchSelected({
      id: selected.id,
      value: selected.batch_code,
      label: selected.batch_code,
    });
    setBatchValueSelected({
      id: selected.id,
      value: selected.batch_code,
      label: selected.batch_code,
    });
    setCostDisabled(false);
    setFieldValue('batchCode', selected.id);
    setBatchIdSelected(selected.id);
    setBatchFromModal(true);
    setShowBatchModal(false);
  };

  const handleStorageCenterCodeConfirm = (selected, title, setFieldValue) => {
    setStorageCenterDefaultValues({
      id: selected.id,
      value: selected.storage_center_code,
      label: selected.storage_center_code,
      description: selected.description,
    });
    setClearStorageCenterChange(true);
    setStorageCenterSelected({
      id: selected.id,
      value: selected.storage_center_code,
      label: selected.storage_center_code,
      description: selected.description,
    });
    setStorageCenterValueSelected({
      id: selected.id,
      value: selected.storage_center_code,
      label: selected.storage_center_code,
      description: selected.description,
    });
    setStorageLocationDisabled(false);

    setStorageCenterCodeValueSelected(selected.id);
    setFieldValue('storageCenterCode', selected.id);
    setFieldValue('storageLocationCode', '');
    setStorageCenterFromModal(true);
    setShowStorageCenterModal(false);
  };

  const handleStorageLocationCodeConfirm = (selected, title, setFieldValue) => {
    setStorageLocationDefaultValues({
      id: selected.id,
      value: selected.location_code,
      label: selected.location_code,
      description: selected.description,
    });
    setStorageLocationSelected({
      id: selected.id,
      value: selected.value,
      label: selected.reference_code,
      description: selected.item_description,
    });
    setStorageLocationValueSelected({ value: selected.value });

    setFieldValue('storageLocationCode', selected.id);
    setStorageLocationFromModal(true);
    setShowStorageLocationModal(false);
  };

  const handleCostCodeConfirm = (selected, title, setFieldValue) => {
    setCostCodeDefaultValues({
      id: selected.id,
      value: selected.unique_unit_cost_code,
      label: selected.unique_unit_cost_code,
    });
    setCostSelected({
      id: selected.id,
      value: selected.unique_unit_cost_code,
      label: selected.unique_unit_cost_code,
    });

    setCostValueSelected({
      id: selected.id,
      value: selected.unique_unit_cost_code,
      label: selected.unique_unit_cost_code,
    });

    setFieldValue('costCode', selected.id);
    setCostCodeFromModal(true);
    setShowCostModal(false);
  };

  const handleEntryOrderConfirm = (selected, title, setFieldValue) => {
    setEntryOrderDefaultValues({
      id: selected.id,
      value: selected.entry_order_code,
      label: selected.entry_order_code,
    });
    setFieldValue('entryOrderCode', selected.id);
    setEntryOrderFromModal(true);
    setShowEntryModal(false);
  };

  const handleDispatchOrderConfirm = (selected, title, setFieldValue) => {
    setDispatchOrderDefaultValues({
      id: selected.id,
      value: selected.dispatch_order_code,
      label: selected.dispatch_order_code,
    });
    setFieldValue('dispatchOrderCode', selected.id);
    setDispatchOrderFromModal(true);
    setShowDispatchModal(false);
  };

  const handleAdjustmentConfirm = (selected, title, setFieldValue) => {
    setAdjustmentDefaultValues({
      id: selected.id,
      value: selected.adjustment_code,
      label: selected.adjustment_code,
      description: selected.description,
    });
    setFieldValue('adjustmentConcept', selected.id);
    setAdjustmentFromModal(true);
    setShowAdjustmentModal(false);
  };

  const handleStorageCenterDestinationConfirm = (selected, title, setFieldValue) => {
    setStorageDestinationCodeDefaultValues({
      id: selected.id,
      value: selected.storage_center_code,
      label: selected.storage_center_code,
      description: selected.description,
    });
    setClearStorageCenterDestinationChange(true);
    setStorageCenterDestinationSelected({
      id: selected.id,
      value: selected.storage_center_code,
      label: selected.storage_center_code,
      description: selected.description,
    });
    setStorageLocationDestinationDisabled(false);
    setFieldValue('storageCenterDestinationCode', selected.id);
    setFieldValue('storageLocationDestinationCode', '');
    setStorageCenterCodeValueSelected(selected.id);
    setStorageDestinationCodeFromModal(true);
    setShowStorageDestinationCodeModal(false);
  };

  const handleStorageLocationDestinationConfirm = (selected, title, setFieldValue) => {
    setStorageLocationDestinationDefaultValues({
      id: selected.id,
      value: selected.location_code,
      label: selected.location_code,
      description: selected.description,
    });
    setFieldValue('storageLocationDestinationCode', selected.id);
    setStorageLocationDestinationFromModal(true);
    setShowStorageLocationDestinationModal(false);
  };

  const handleClose = () => setShow(false);

  const handleConfirmation = async () => {
    await finalSubmit(handleData, handleValues);
    setShow(false);
  };

  const finalSubmit = async (data, processedValues) => {
    // send transaction using handleAPIRequest
    const result = await handleAPIRequest(processedValues, {
      entity: 'Cost',
      code: data.uniqueUnitCostCode,
    });
    // check if transaction was created
    if (result.statusText === 'Created') {
      notification('Transaction created successfully', 'success');
      setItemDefaultValues({
        id: '',
        value: '',
        label: '',
        description: '',
      });
      setBatchDefaultValues({
        id: '',
        value: '',
        label: '',
      });
      setStorageCenterDefaultValues({
        id: '',
        value: '',
        label: '',
        description: '',
      });
      setStorageLocationDefaultValues({
        id: '',
        value: '',
        label: '',
        description: '',
      });
      setCostCodeDefaultValues({
        id: '',
        value: '',
        label: '',
      });
      setEntryOrderDefaultValues({
        id: '',
        value: '',
        label: '',
      });
      setDispatchOrderDefaultValues({
        id: '',
        value: '',
        label: '',
      });
      setAdjustmentDefaultValues({
        id: '',
        value: '',
        label: '',
        description: '',
      });
      setStorageDestinationCodeDefaultValues({
        id: '',
        value: '',
        label: '',
        description: '',
      });
      setStorageLocationDestinationDefaultValues({
        id: '',
        value: '',
        label: '',
        description: '',
      });
    } else {
      notification('Error creating the transaction', 'error');
    }
  };

  return (
    <Formik
      validateOnChange
      validationSchema={transactionValidate}
      onSubmit={async (data, { resetForm }) => {
        setHandleData(data);
        resetForm({});
        setClearItemChange(true);
        setClearBatchChange(true);
        setClearStorageCenterChange(true);
        setClearStorageCenterDestinationChange(true);
        setClearForm(true);
        setAutoCompleted({
          ...autoCompleted,
          dimensional_unit_code: '',
          dimensional_unit_description: '',
          item_description: '',
        });
        setDescriptions({
          itemDescription: '',
          storageCenterDescription: '',
          storageLocationDescription: '',
          adjustmentConceptDescription: '',
        });

        // if the type of adjustment is selected (only in adjustment transactions),
        // the transaction_type takes the adjustmentType value.
        const transactionType = data.adjustmentType
          ? data.adjustmentType
          : internalValues.transaction_type;
        const returnTransaction = data.returnTransaction ? data.returnTransaction : 'no';

        const processedValues = {
          item_reference_code: data.itemReferenceCode,
          amount: data.amount,
          batch_code: data.batchCode,
          cost_code: data.costCode,
          transaction_type: transactionType,
          transaction_subtype: 'total',
          return_aproved: '',
          user,
          return_transaction: returnTransaction,
          justification: data.justification,
          item_batch_relation_code: internalValues.item_batch_relation_code,
          storage_center_code: data.storageCenterCode,
          storage_center_code_destination: data.storageCenterDestinationCode,
          storage_location: data.storageLocationCode === '' ? null : data.storageLocationCode,
          storage_location_destination:
            data.storageLocationDestinationCode === '' ? null : data.storageLocationDestinationCode,
          entry_order_code: data.entryOrderCode,
          dispatch_order_code: data.dispatchOrderCode,
          dimensional_unit_code: internalValues.dimensional_unit_code,
          adjustment_code: data.adjustmentConcept,
        };

        setHandleValues(processedValues);

        //Function for calculating negative stock
        let totalSumItems = 0;
        let stockResult = 0;
        const query = `${urlCompute}?item_reference_code=${encodeURIComponent(
          itemValueSelected
        )}&batch_code=${encodeURIComponent(
          batchValueSelected.value
        )}&unique_unit_cost_code=${encodeURIComponent(
          costValueSelected.value
        )}&group_by=location&compute_type=total`;
        await axios
          .get(query, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res?.data) {
              const normaliceResponse = () => {
                let result = [];
                Object.keys(res.data).forEach((key) => {
                  result.push(res.data[key]);
                });
                return result;
              };
              if (processedValues.storage_location === null) {
                totalSumItems = normaliceResponse()
                  .filter((element) => element.storage_center === storageCenterValueSelected.value)
                  .map((element) => element.stock_level_n0)
                  .reduce((previousValue, currentValue) => previousValue + currentValue);
              } else {
                totalSumItems = normaliceResponse()
                  .filter((element) => element.storage_center === storageCenterValueSelected.value)
                  .filter((element) => element.storage_location === storageLocationSelected.value)
                  .map((element) => element.stock_level_n0)
                  .reduce((previousValue, currentValue) => previousValue + currentValue);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        if (
          processedValues.transaction_type == 'entry' ||
          processedValues.transaction_type == 'adjustment_increase'
        ) {
          stockResult = totalSumItems + processedValues.amount;
        } else {
          stockResult = totalSumItems - processedValues.amount;
        }

        setTotalActualStock(totalSumItems);
        setChangeStock(stockResult);

        if (stockResult < 0 && processedValues.transaction_type !== 'entry') {
          if (processedValues.transaction_type == 'adjustment_increase') {
            finalSubmit(data, processedValues);
          } else {
            setShow(true);
          }
        } else {
          finalSubmit(data, processedValues);
        }
      }}
      initialValues={{
        itemReferenceCode: '',
        amount: '',
        batchCode: '',
        costCode: '',
        entryOrder: '',
        dispatchOrder: '',
        storageCenterCode: '',
        storageLocationCode: null,
        storageCenterDestinationCode: null,
        storageLocationDestinationCode: null,
        entryOrderCode: null,
        dispatchOrderCode: null,
        returnTransaction: '',
        justification: '',
        adjustmentType: '',
        adjustmentConcept: '',
      }}
    >
      {({ setFieldValue, handleChange, handleSubmit, values }) => (
        <>
          {alertMessage !== '' || errorMessage !== '' ? (
            <AlertMessage
              setIsError={setIsError}
              variant={errorMessage !== '' ? 'danger' : 'success'}
              setAlertMessage={setAlertMessage}
              setErrorMessage={setErrorMessage}
              message={errorMessage !== '' ? errorMessage : alertMessage}
            />
          ) : null}
          <ActionConfirmationTransaction
            show={show}
            handleClose={handleClose}
            handleConfirmation={handleConfirmation}
            message={
              'Actual stock: (' +
              totalActualStock +
              '). New stock: (' +
              changeStock +
              '). Do you want to continue? '
            }
          />
          {showItemModal ? (
            <TableModal
              url={Urls.item}
              customFilter={'batch_related'}
              title="Item Reference Code"
              show={showItemModal}
              handleClose={handleCloseModals}
              formName={'item'}
              columnToTransform={itemColumnToTransform}
              handleConfirmation={handleItemReferenceCodeConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showBatchModal ? (
            <TableModal
              key={JSON.stringify(itemValueSelected)}
              url={Urls.batch}
              customFilter={
                itemValueSelected ? `item_code=${encodeURIComponent(itemValueSelected)}` : ''
              }
              title="Batch Code"
              show={showBatchModal}
              handleClose={handleCloseModals}
              formName={'batch'}
              columnToTransform={[]}
              handleConfirmation={handleBatchCodeConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showStorageCenterModal ? (
            <TableModal
              key={JSON.stringify(itemValueSelected)}
              url={Urls.storageCenter}
              customFilter={
                itemValueSelected ? `item_code=${encodeURIComponent(itemValueSelected)}` : ''
              }
              title="Storage Center Code"
              show={showStorageCenterModal}
              handleClose={handleCloseModals}
              formName={'storageCenter'}
              columnToTransform={[]}
              handleConfirmation={handleStorageCenterCodeConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showStorageLocationModal ? (
            <TableModal
              key={JSON.stringify(storageCenterCodeValueSelected)}
              url={Urls.storageLocation}
              customFilter={
                storageCenterCodeValueSelected
                  ? `storage_center_code__id=${encodeURIComponent(storageCenterCodeValueSelected)}`
                  : ''
              }
              title="Storage Location Code"
              show={showStorageLocationModal}
              handleClose={handleCloseModals}
              formName={'storageLocation'}
              columnToTransform={storageLocationColumnToTransform}
              handleConfirmation={handleStorageLocationCodeConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showCostModal ? (
            <TableModal
              key={JSON.stringify(itemIdSelected) & JSON.stringify(batchIdSelected)}
              url={Urls.cost}
              customFilter={
                itemIdSelected
                  ? batchIdSelected
                    ? `item_related__id=${itemIdSelected}&batch_related__id=${batchIdSelected}`
                    : ''
                  : ''
              }
              title="Cost Code"
              show={showCostModal}
              handleClose={handleCloseModals}
              formName={'cost'}
              columnToTransform={costColumnToTransform}
              handleConfirmation={handleCostCodeConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showEntryModal ? (
            <TableModal
              url={Urls.entryOrder}
              title="Entry Order Code"
              show={showEntryModal}
              handleClose={handleCloseModals}
              formName={'entryOrder'}
              columnToTransform={entryColumnToTransform}
              handleConfirmation={handleEntryOrderConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showDispatchModal ? (
            <TableModal
              url={Urls.dispatchOrder}
              title="Dispatch Order Code"
              show={showDispatchModal}
              handleClose={handleCloseModals}
              formName={'dispatchOrder'}
              columnToTransform={dispatchColumnToTransform}
              handleConfirmation={handleDispatchOrderConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showStorageLocationDestinationModal ? (
            <TableModal
              key={JSON.stringify(storageCenterDestinationCodeValueSelected)}
              url={Urls.storageLocation}
              customFilter={
                storageCenterCodeValueSelected
                  ? `storage_center_code__id=${encodeURIComponent(storageCenterCodeValueSelected)}`
                  : ''
              }
              title="Storage Location Destination Code"
              show={showStorageLocationDestinationModal}
              handleClose={handleCloseModals}
              formName={'storageLocation'}
              columnToTransform={storageLocationColumnToTransform}
              handleConfirmation={handleStorageLocationDestinationConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showStorageDestinationCodeModal ? (
            <TableModal
              key={JSON.stringify(itemValueSelected)}
              url={Urls.storageCenter}
              customFilter={
                itemValueSelected ? `item_code=${encodeURIComponent(itemValueSelected)}` : ''
              }
              title="Storage Center Destination Code"
              show={showStorageDestinationCodeModal}
              handleClose={handleCloseModals}
              formName={'storageCenter'}
              columnToTransform={[]}
              handleConfirmation={handleStorageCenterDestinationConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          {showAdjustmentModal ? (
            <TableModal
              url={Urls.adjustment}
              title="Adjustment Concept"
              show={showAdjustmentModal}
              handleClose={handleCloseModals}
              formName={'adjustment'}
              columnToTransform={[]}
              handleConfirmation={handleAdjustmentConfirm}
              setFieldValue={setFieldValue}
            />
          ) : null}
          <Form id="transactionsForm" onSubmit={handleSubmit}>
            <Form.Row>
              {itemFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setClearItemChange(true);
                    setItemSelected(option);
                    setItemValueSelected(option ? option.value : '');
                    setItemIdSelected(option ? option.id : '');
                    setBatchAndStorageCenterDisabled(false);
                    validateItemCode(option);
                    setFieldValue('itemReferenceCode', option ? option.id : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, itemDescription: option.description }
                        : { itemDescription: '' }
                    );
                    setItemFromModal(false);
                  }}
                  name="itemReferenceCode"
                  label="Item Reference Code"
                  options={itemData.options}
                  setShow={setShowItemModal}
                  clear={clearForm}
                  description={itemDefaultValues.description}
                  defaultValue={itemDefaultValues}
                  value={itemDefaultValues}
                />
              ) : (
                <CustomSelectDefault
                  onChange={(option) => {
                    setClearItemChange(true);
                    setItemSelected(option);
                    setItemValueSelected(option ? option.value : '');
                    setItemIdSelected(option ? option.id : '');
                    setBatchAndStorageCenterDisabled(false);
                    validateItemCode(option);
                    setFieldValue('itemReferenceCode', option ? option.id : '');
                    setDescriptions(
                      option
                        ? { ...descriptions, itemDescription: option.description }
                        : { itemDescription: '' }
                    );
                  }}
                  name="itemReferenceCode"
                  label="Item Reference Code"
                  options={itemData.options}
                  setShow={setShowItemModal}
                  clear={clearForm}
                  description={descriptions.itemDescription}
                  defaultValue={values.itemReferenceCode}
                />
              )}
              {batchFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setClearBatchChange(true);
                    setBatchSelected(option);
                    setCostDisabled(false);
                    setFieldValue('batchCode', option ? option.id : '');
                    setBatchIdSelected(option ? option.id : '');
                    setBatchFromModal(false);
                  }}
                  name="batchCode"
                  label="Batch Code"
                  options={batchData.options}
                  setShow={allowedBatches.length > 0 ? setShowBatchModal : setFakeModal}
                  clear={clearItemChange}
                  disabled={batchAndStorageCenterDisabled}
                  defaultValue={batchDefaultValues}
                  value={batchDefaultValues}
                />
              ) : (
                <CustomSelectDefault
                  onChange={(option) => {
                    setBatchValueSelected(option);
                    setClearBatchChange(true);
                    setBatchSelected(option);
                    setCostDisabled(false);
                    setFieldValue('batchCode', option ? option.id : '');
                    setBatchIdSelected(option ? option.id : '');
                  }}
                  name="batchCode"
                  label="Batch Code"
                  options={batchData.options}
                  setShow={allowedBatches.length > 0 ? setShowBatchModal : setFakeModal}
                  clear={clearItemChange}
                  disabled={batchAndStorageCenterDisabled}
                  defaultValue={values.batchCode}
                />
              )}
              {/* Automatically generated (hidden to user) */}
              <Form.Group
                as={Col}
                controlId="formGridItemBatchRelation"
                style={{ display: 'none' }}
              >
                <Form.Control
                  type="hidden"
                  placeholder="item_batch_relation_code"
                  name="item_batch_relation_code"
                  value={internalValues.item_batch_relation_code}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              {storageCenterFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setClearStorageCenterChange(true);
                    setStorageCenterSelected(option);
                    setStorageCenterValueSelected(option);
                    setStorageLocationDisabled(false);
                    setStorageCenterCodeValueSelected(option ? option.id : '');
                    setFieldValue('storageCenterCode', option ? option.id : '');
                    setFieldValue('storageLocationCode', '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            storageCenterDescription: option.description,
                          }
                        : { storageCenterDescription: '' }
                    );
                    setStorageCenterFromModal(false);
                  }}
                  name="storageCenterCode"
                  label="Storage Center Code"
                  options={storageCenterData.options}
                  setShow={
                    allowedStorageCenters.length > 0 ? setShowStorageCenterModal : setFakeModal
                  }
                  clear={clearItemChange}
                  disabled={batchAndStorageCenterDisabled}
                  description={storageCenterDefaultValues.description}
                  defaultValue={storageCenterDefaultValues}
                  value={storageCenterDefaultValues}
                />
              ) : (
                <CustomSelectDefault
                  onChange={(option) => {
                    setClearStorageCenterChange(true);
                    setStorageCenterSelected(option);
                    setStorageCenterValueSelected(option);
                    setStorageLocationDisabled(false);
                    setStorageCenterCodeValueSelected(option ? option.id : '');
                    setFieldValue('storageCenterCode', option ? option.id : '');
                    setFieldValue('storageLocationCode', '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            storageCenterDescription: option.description,
                          }
                        : { storageCenterDescription: '' }
                    );
                  }}
                  name="storageCenterCode"
                  label="Storage Center Code"
                  options={storageCenterData.options}
                  setShow={
                    allowedStorageCenters.length > 0 ? setShowStorageCenterModal : setFakeModal
                  }
                  clear={clearItemChange}
                  disabled={batchAndStorageCenterDisabled}
                  description={descriptions.storageCenterDescription}
                  defaultValue={values.storageCenterCode}
                />
              )}
              {storageLocationFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setFieldValue('storageLocationCode', option ? option.id : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            storageLocationDescription: option.description,
                          }
                        : { storageLocationDescription: '' }
                    );
                    setStorageLocationFromModal(false);
                  }}
                  name="storageLocationCode"
                  label="Storage Location Code"
                  options={storageLocationData.options}
                  setShow={
                    allowedStorageLocations.length > 0 ? setShowStorageLocationModal : setFakeModal
                  }
                  clear={clearItemChange || clearStorageCenterChange}
                  disabled={storageLocationDisabled}
                  description={storageLocationDefaultValues.description}
                  defaultValue={storageLocationDefaultValues}
                  value={storageLocationDefaultValues}
                />
              ) : (
                <CustomSelectDefault
                  onChange={(option) => {
                    setStorageLocationSelected(option);
                    setFieldValue('storageLocationCode', option ? option.id : '');
                    setDescriptions(
                      option
                        ? {
                            ...descriptions,
                            storageLocationDescription: option.description,
                          }
                        : { storageLocationDescription: '' }
                    );
                  }}
                  name="storageLocationCode"
                  label="Storage Location Code"
                  options={storageLocationData.options}
                  setShow={
                    allowedStorageLocations.length > 0 ? setShowStorageLocationModal : setFakeModal
                  }
                  clear={clearItemChange || clearStorageCenterChange}
                  disabled={storageLocationDisabled}
                  description={descriptions.storageLocationDescription}
                  defaultValue={values.storageLocationCode}
                />
              )}
            </Form.Row>

            <Form.Row>
              {costCodeFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setCostValueSelected(option);
                    setCostSelected(option);
                    setFieldValue('costCode', option ? option.id : '');
                    setCostCodeFromModal(false);
                  }}
                  name="costCode"
                  label="Cost Code"
                  options={costData.options}
                  setShow={allowedCosts.length > 0 ? setShowCostModal : setFakeModal}
                  clear={clearBatchChange}
                  disabled={costDisabled}
                  defaultValue={costCodeDefaultValues}
                  value={costCodeDefaultValues}
                />
              ) : (
                <CustomSelectDefault
                  onChange={(option) => {
                    setCostValueSelected(option);
                    setCostSelected(option);
                    setFieldValue('costCode', option ? option.id : '');
                  }}
                  name="costCode"
                  label="Cost Code"
                  options={costData.options}
                  setShow={allowedCosts.length > 0 ? setShowCostModal : setFakeModal}
                  clear={clearBatchChange}
                  disabled={costDisabled}
                  defaultValue={values.costCode}
                />
              )}

              <Form.Group as={Col} controlId="formAmount">
                <TextField
                  label="Amount "
                  name="amount"
                  type="number"
                  min="1"
                  value={values.amount}
                />
              </Form.Group>

              {/* Automatically generated with item code (only used as aditional visual information) */}
              <Form.Group as={Col}>
                <Form.Label className="label">Dimensional Unit Code</Form.Label>
                <Form.Control disabled type="text" value={autoCompleted.dimensional_unit_code} />
                <Form.Label className="description-select">{dimensionalDescription}</Form.Label>
                <Form.Control
                  type="hidden"
                  name="dimensionalUnitCode"
                  value={internalValues.dimensional_unit_code}
                  className="mb-0"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              {entryOrderFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setFieldValue('entryOrderCode', option ? option.id : '');
                    setEntryOrderFromModal(false);
                  }}
                  name="entryOrderCode"
                  label="Entry Order Code"
                  options={entryOrderData.options}
                  setShow={setShowEntryModal}
                  clear={clearForm}
                  defaultValue={entryOrderDefaultValues}
                  value={entryOrderDefaultValues}
                />
              ) : (
                <CustomSelectDefault
                  onChange={(option) => {
                    setFieldValue('entryOrderCode', option ? option.id : '');
                  }}
                  name="entryOrderCode"
                  label="Entry Order Code"
                  options={entryOrderData.options}
                  setShow={setShowEntryModal}
                  clear={clearForm}
                  defaultValue={values.entryOrderCode}
                />
              )}
              {dispatchOrderFromModal ? (
                <CustomSelect
                  onChange={(option) => {
                    setFieldValue('dispatchOrderCode', option ? option.id : '');
                    setDispatchOrderFromModal(false);
                  }}
                  name="dispatchOrderCode"
                  label="Dispatch Order Code"
                  options={dispatchOrderData.options}
                  setShow={setShowDispatchModal}
                  clear={clearForm}
                  defaultValue={dispatchOrderDefaultValues}
                  value={dispatchOrderDefaultValues}
                />
              ) : (
                <CustomSelectDefault
                  onChange={(option) => {
                    setFieldValue('dispatchOrderCode', option ? option.id : '');
                  }}
                  name="dispatchOrderCode"
                  label="Dispatch Order Code"
                  options={dispatchOrderData.options}
                  setShow={setShowDispatchModal}
                  clear={clearForm}
                  defaultValue={values.dispatchOrderCode}
                />
              )}
            </Form.Row>

            {type === 'entry' || type === 'output' ? (
              <Row>
                <div className="p-0 m-0">
                  <CustomSelectDefault
                    onChange={(option) => {
                      setFieldValue('returnTransaction', option ? option.value : '');
                    }}
                    name="returnTransaction"
                    label="Return Transaction"
                    options={[
                      { value: 'yes', label: 'yes', id: 0 },
                      { value: 'no', label: 'no', id: 1 },
                    ]}
                    clear={clearForm}
                    showIcon={false}
                  />
                </div>
              </Row>
            ) : (
              <div />
            )}
            {type === 'transfer' ? (
              <Form.Row>
                {storageDestinationCodeFromModal ? (
                  <CustomSelect
                    onChange={(option) => {
                      setClearStorageCenterDestinationChange(true);
                      setStorageCenterDestinationSelected(option);
                      setStorageLocationDestinationDisabled(false);
                      setFieldValue('storageCenterDestinationCode', option ? option.id : '');
                      setDescriptions(
                        option
                          ? {
                              ...descriptions,
                              storageCenterDestinDescription: option.description,
                            }
                          : { storageCenterDestinDescription: '' }
                      );
                      setFieldValue('storageLocationDestinationCode', '');
                      setStorageCenterCodeValueSelected(option ? option.id : '');
                      setStorageDestinationCodeFromModal(false);
                    }}
                    name="storageCenterDestinationCode"
                    label="Storage Center Destination Code "
                    options={storageCenterData.options}
                    setShow={
                      allowedStorageCenters.length > 0
                        ? setShowStorageDestinationCodeModal
                        : setFakeModal
                    }
                    clear={clearItemChange}
                    disabled={batchAndStorageCenterDisabled}
                    description={storageDestinationCodeDefaultValues.description}
                    deafultValue={storageDestinationCodeDefaultValues}
                    value={storageDestinationCodeDefaultValues}
                  />
                ) : (
                  <CustomSelectDefault
                    onChange={(option) => {
                      setClearStorageCenterDestinationChange(true);
                      setStorageCenterDestinationSelected(option);
                      setStorageLocationDestinationDisabled(false);
                      setFieldValue('storageCenterDestinationCode', option ? option.id : '');
                      setDescriptions(
                        option
                          ? {
                              ...descriptions,
                              storageCenterDestinDescription: option.description,
                            }
                          : { storageCenterDestinDescription: '' }
                      );
                      setFieldValue('storageLocationDestinationCode', '');
                      setStorageCenterCodeValueSelected(option ? option.id : '');
                    }}
                    name="storageCenterDestinationCode"
                    label="Storage Center Destination Code "
                    options={storageCenterData.options}
                    setShow={
                      allowedStorageCenters.length > 0
                        ? setShowStorageDestinationCodeModal
                        : setFakeModal
                    }
                    clear={clearItemChange}
                    disabled={batchAndStorageCenterDisabled}
                    description={descriptions.storageCenterDestinDescription}
                    deafultValue={values.storageCenterDestinationCode}
                  />
                )}
                {storageLocationDestinationFromModal ? (
                  <CustomSelect
                    onChange={(option) => {
                      setFieldValue('storageLocationDestinationCode', option ? option.id : '');
                      setDescriptions(
                        option
                          ? {
                              ...descriptions,
                              storageLocationDestinDescription: option.description,
                            }
                          : { storageLocationDestinDescription: '' }
                      );
                      setStorageLocationDestinationFromModal(false);
                    }}
                    name="storageLocationDestinationCode"
                    label="Storage Location Destination Code"
                    options={storageLocationDestinationData.options}
                    setShow={
                      allowedStorageLocationDestination.length > 0
                        ? setShowStorageLocationDestinationModal
                        : setFakeModal
                    }
                    clear={clearItemChange || clearStorageCenterDestinationChange}
                    disabled={storageLocationDestinationDisabled}
                    description={storageLocationDestinationDefaultValues.description}
                    defaultValue={storageLocationDestinationDefaultValues}
                    value={storageLocationDestinationDefaultValues}
                  />
                ) : (
                  <CustomSelectDefault
                    onChange={(option) => {
                      setFieldValue('storageLocationDestinationCode', option ? option.id : '');
                      setDescriptions(
                        option
                          ? {
                              ...descriptions,
                              storageLocationDestinDescription: option.description,
                            }
                          : { storageLocationDestinDescription: '' }
                      );
                    }}
                    name="storageLocationDestinationCode"
                    label="Storage Location Destination Code"
                    options={storageLocationDestinationData.options}
                    setShow={
                      allowedStorageLocationDestination.length > 0
                        ? setShowStorageLocationDestinationModal
                        : setFakeModal
                    }
                    clear={clearItemChange || clearStorageCenterDestinationChange}
                    disabled={storageLocationDestinationDisabled}
                    description={descriptions.storageLocationDestinDescription}
                    defaultValue={values.storageLocationDestinationCode}
                  />
                )}
              </Form.Row>
            ) : (
              <div />
            )}
            {type === 'adjustment' ? (
              <div>
                <Form.Row>
                  <CustomSelectDefault
                    onChange={(option) => {
                      setFieldValue('adjustmentType', option ? option.value : '');
                    }}
                    name="adjustmentType"
                    label="Adjustment Type"
                    options={[
                      {
                        label: 'Increase',
                        value: 'adjustment_increase',
                        id: 0,
                      },
                      {
                        label: 'Decrease',
                        value: 'adjustment_decrease',
                        id: 1,
                      },
                    ]}
                    setShow={setShow}
                    clear={clearForm}
                    showIcon={false}
                  />
                  {adjustmentFromModal ? (
                    <CustomSelect
                      onChange={(option) => {
                        setFieldValue('adjustmentConcept', option ? option.id : '');
                        setDescriptions(
                          option
                            ? {
                                ...descriptions,
                                adjustmentConceptDescription: option.description,
                              }
                            : { adjustmentConceptDescription: '' }
                        );
                        setAdjustmentFromModal(false);
                      }}
                      name="adjustmentConcept"
                      label="Adjustment Concept"
                      options={adjustmentConceptData.options}
                      setShow={setShowAdjustmentModal}
                      clear={clearForm}
                      description={adjustmentDefaultValues.description}
                      defaultValue={adjustmentDefaultValues}
                      value={adjustmentDefaultValues}
                    />
                  ) : (
                    <CustomSelectDefault
                      onChange={(option) => {
                        setFieldValue('adjustmentConcept', option ? option.id : '');
                        setDescriptions(
                          option
                            ? {
                                ...descriptions,
                                adjustmentConceptDescription: option.description,
                              }
                            : { adjustmentConceptDescription: '' }
                        );
                      }}
                      name="adjustmentConcept"
                      label="Adjustment Concept"
                      options={adjustmentConceptData.options}
                      setShow={setShowAdjustmentModal}
                      clear={clearForm}
                      description={descriptions.adjustmentConceptDescription}
                      defaultValue={values.adjustmentConcept}
                    />
                  )}
                  <Form.Group as={Col} controlId="formGridJustification">
                    <Form.Label className="label">Justification</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Justification"
                      name="justification"
                      value={values.justification}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>
              </div>
            ) : (
              <div />
            )}
            <Button
              className="fpb-btn"
              type="submit"
              style={{ marginBottom: '48px', marginTop: '24px' }}
            >
              Confirm
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default TransactionsForm;
