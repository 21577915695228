import * as Yup from 'yup';
import { getVariablesValidations } from './variablesValidations';

export const getManualValidationSchema = (
  calculationVariableIndex,
  currentWorkCenterConfiguration
) => {
  const yupVariablesValidation = getVariablesValidations(
    currentWorkCenterConfiguration,
    calculationVariableIndex
  );
  return Yup.object({
    ...yupVariablesValidation,
    comments: Yup.string(),
    qualityComments: Yup.string(),
    quantityNonconforming: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError('Enter a value')
      .nullable()
      .notRequired()
      .when(`variable_${calculationVariableIndex}`, {
        is: (value) => value > -1,
        then: Yup.number()
          .typeError('Enter a value')
          .nullable()
          .notRequired()
          .max(
            Yup.ref(`variable_${calculationVariableIndex}`),
            'El valor no puede ser mayor al de la variable de cálculo '
          ),
      }),
  });
};

export const getPauseValidationSchema = (
  calculationVariableIndex,
  currentWorkCenterConfiguration
) => {
  const yupVariablesValidation = getVariablesValidations(
    currentWorkCenterConfiguration,
    calculationVariableIndex
  );
  return Yup.object({
    ...yupVariablesValidation,
    comments: Yup.string(),
    qualityComments: Yup.string(),
    quantityNonconforming: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError('Enter a value')
      .nullable()
      .notRequired()
      .when(`variable_${calculationVariableIndex}`, {
        is: (value) => value > -1,
        then: Yup.number()
          .typeError('Enter a value')
          .nullable()
          .notRequired()
          .max(
            Yup.ref(`variable_${calculationVariableIndex}`),
            'El valor no puede ser mayor al de la variable de cálculo '
          ),
      }),
  });
};

export const getEventValidationSchema = (
  calculationVariableIndex,
  currentWorkCenterConfiguration
) => {
  const yupVariablesValidation = getVariablesValidations(
    currentWorkCenterConfiguration,
    calculationVariableIndex
  );
  return Yup.object({
    eventType: Yup.object({
      value: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
    })
      .required('Required')
      .nullable(),
    ...yupVariablesValidation,
    comments: Yup.string(),
    qualityComments: Yup.string(),
    quantityNonconforming: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError('Enter a value')
      .nullable()
      .notRequired()
      .when(`variable_${calculationVariableIndex}`, {
        is: (value) => value > -1,
        then: Yup.number()
          .typeError('Enter a value')
          .nullable()
          .notRequired()
          .max(
            Yup.ref(`variable_${calculationVariableIndex}`),
            'El valor no puede ser mayor al de la variable de cálculo '
          ),
      }),
  });
};

export const getDoneSchema = (calculationVariableIndex, currentWorkCenterConfiguration) => {
  const yupVariablesValidation = getVariablesValidations(
    currentWorkCenterConfiguration,
    calculationVariableIndex
  );
  return Yup.object({
    ...yupVariablesValidation,
    comments: Yup.string(),
    qualityComments: Yup.string(),
    quantityNonconforming: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError('Enter a value')
      .nullable()
      .notRequired()
      .when(`variable_${calculationVariableIndex}`, {
        is: (value) => value > -1,
        then: Yup.number()
          .typeError('Enter a value')
          .nullable()
          .notRequired()
          .max(
            Yup.ref(`variable_${calculationVariableIndex}`),
            'El valor no puede ser mayor al de la variable de cálculo '
          ),
      }),
  });
};

export const getFinishEventSchema = (calculationVariableIndex, currentWorkCenterConfiguration) => {
  const yupVariablesValidation = getVariablesValidations(
    currentWorkCenterConfiguration,
    calculationVariableIndex
  );
  return Yup.object({
    ...yupVariablesValidation,
    comments: Yup.string(),
  });
};
