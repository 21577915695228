import React from 'react';
import { Form } from 'react-bootstrap';
import { ErrorMessage, useField } from 'formik';
import { MdKeyboardArrowDown } from 'react-icons/md';

const SelectItems = ({ handleChange, options, label, name, errorMessageEnabled, ...props }) => (
  <Form.Group className="content-select">
    <Form.Label className="label">{label}</Form.Label>
    <MdKeyboardArrowDown className="icon-select" htmlFor="#sel" />
    <Form.Control
      as="select"
      name={name}
      onChange={handleChange}
      {...props}
      className="select"
      id="sel"
    >
      <option value="" selected disabled hidden className="select-option">
        Select...
      </option>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </Form.Control>
    {errorMessageEnabled ? (
      <ErrorMessage component="div" name={name} className="fpb-error" />
    ) : null}
  </Form.Group>
);

export default SelectItems;
