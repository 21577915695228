import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FPBTable from '../FPBTable/FPBTable';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import Urls from '../Urls';
import { setCurrentPageAction, setTotalCountAction } from '../../redux/actions/paginationAction';
import ExportButton from '../ExportExcel/ExportButton';
import apiService from '../apiService';
import { getFiltersString } from '../../utils/tableFilters';
import { getExportDataAsync } from '../../redux/slices/exportSlice';

const ShelfLifeTableSection = () => {
  const [ShelfLifeTableData, setShelfLifeTableData] = useState([]);
  const [ShelfLifeHeaders, setShelfLifeHeaders] = useState([]);
  const [filters, setFilters] = useState([]);
  const inventoryClient = apiService.inventoryClient();
  const dispatch = useDispatch();
  const [columnsToHide, setColumnsToHide] = useState();

  // pagination
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  const setTotalCount = (totalCount) => {
    dispatch(setTotalCountAction(totalCount));
  };

  useEffect(() => {
    setColumnsToHide(['code', 'unique_unit_cost_code']);
  }, []);

  useEffect(() => {
    if (filters.length > 0) {
      dispatch(setCurrentPageAction(0));
    }
  }, [filters]);

  const getQueryStringShelfLife = () => {
    const filtersString = getFiltersString(filters);
    const urlTable = `${Urls.shelfLife}?page=${
      currentPage + 1
    }&page_size=${pageSize}&${filtersString}`;
    return { urlTable, filtersString };
  };

  useEffect(() => {
    const queryString = getQueryStringShelfLife();
    const getTableData = async () => {
      try {
        const response = await inventoryClient.get(queryString.urlTable);
        const tableData = response.data.results;
        setTotalCount(response.data.count);
        if (tableData.length > 0) {
          // discard the fields the user will not visualize in tables
          const headersList = Object.keys(tableData[0]).filter((elem) => elem !== 'id');
          setShelfLifeHeaders(headersList);
          setShelfLifeTableData(tableData);
        }
      } catch (err) {
        console.log('error: ', err);
      }
    };
    getTableData();
  }, [pageSize, currentPage, filters]);

  const handleExport = async () => {
    const queryString = getQueryStringShelfLife();
    dispatch(getExportDataAsync({ urlExport: Urls.shelfLife, filters: queryString.filtersString }));
  };

  return (
    <div>
      <HeaderTitle title={'Shelf Life'} />
      <div className="text-left btn-container">
        <ExportButton
          formName={'shelfLife'}
          fileName={'Shelf Life Table'}
          handleExport={handleExport}
        />
      </div>
      <FPBTable
        readOnly
        headers={ShelfLifeHeaders}
        tableData={ShelfLifeTableData}
        customColumnOrder={[]}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        columnsToHide={columnsToHide}
        setFilters={(val) => {
          setFilters(val);
        }}
      />
    </div>
  );
};

export default ShelfLifeTableSection;
