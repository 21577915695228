import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { showFilterList } from '../../../redux/slices/indicatorFilterSlice';
import { showPerformance } from '../../../redux/slices/performanceSlice';
import SimpleTableModal from '../../TableModal/SimpleTableModal';
import { Dropdown, ButtonGroup, Row, Card } from 'react-bootstrap';
import ExportExcel from '../../ExportExcel/ExportDropdown';

const PerformanceChart = ({ indicatorName }) => {
  const filters = useSelector((state) => state.performance.filters);
  const { productionOrder, product, shift, from, to, temporality } = filters;
  const [subtitle, setSubtitle] = useState('');
  //redux
  const data = useSelector(showPerformance);
  const filterList = useSelector(showFilterList);
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [paginationCount, setPaginationCount] = useState(0);

  useEffect(() => {
    const subtitle = `Filtered ${product ? 'by product ' + product + ', ' : ''}${
      shift ? 'by shift ' + shift + ', ' : ''
    }from ${from} to ${to} ${'(' + temporality + ')'}.`;
    setSubtitle(subtitle);
  }, [filters]);

  useEffect(() => {
    const subtitle = `Filtered ${
      productionOrder ? 'by production order ' + productionOrder + ', ' : ''
    }${product ? 'by product ' + product + ', ' : ''}${
      shift ? 'by shift ' + shift + ', ' : ''
    }from ${from} to ${to} ${'(' + temporality + ')'}.`;
    setSubtitle(subtitle);
  }, [filters]);

  useEffect(() => {
    const series = data?.performance_percentages || [];
    const dates = data?.dates || [];
    setSeries(series);
    setCategories(dates);
  }, [data]);

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: false,
    },
    colors: ['#ff3c77', '#49158b', '#ff4f2b', '#08bfff', '#ffae16'],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
      },
      offsetY: -20,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    title: {
      text: 'Performance (%)',
    },
    subtitle: {
      text: subtitle,
      align: 'left',
      offsetY: 20,
      style: {
        color: '#333',
        fontSize: '12px',
      },
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      showForSingleSeries: true,
    },
    noData: {
      text: 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#d8d8d8',
        fontSize: '72px',
        fontFamily: undefined,
      },
    },
  };

  const [showTableData, setShowTableData] = useState(false);
  const handleCloseModal = () => {
    setShowTableData(false);
  };

  // Set table data
  const [excelTableData, setExcelTableData] = useState([]);
  useEffect(() => {
    let tableData = [];
    for (let i = 0; i < categories?.length; i++) {
      for (let j = 0; j < series?.length; j++) {
        tableData.push({
          date: categories[i],
          work_center: series[j].name,
          performance: series[j].data[i] || 0,
        });
      }
    }
    setExcelTableData(tableData);
    setPaginationCount(tableData.length);
  }, [series]);

  return (
    <>
      <Card className="p-3 my-2">
        <SimpleTableModal
          title={'Performance'}
          headers={['date', 'work_center', 'performance']}
          tableData={excelTableData}
          show={showTableData}
          handleClose={handleCloseModal}
          totalCount={paginationCount}
        />
        <div>
          <Row className="flex-row-reverse">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle className="fpb-btn-chart" id="dropdown-download" />
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowTableData(true);
                  }}
                >
                  Show Data
                </Dropdown.Item>
                <ExportExcel
                  excelData={excelTableData}
                  fileName={indicatorName + ' Report (' + from + ' -' + to + ')'}
                  indicator={indicatorName}
                  filters={subtitle}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </div>
        <Chart
          options={JSON.parse(JSON.stringify(options))}
          series={JSON.parse(JSON.stringify(series))}
          height={350}
          type="bar"
        />
      </Card>
    </>
  );
};

export default PerformanceChart;
