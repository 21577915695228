import React, { useState, useEffect } from 'react';
import {
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  PagingState,
  CustomPaging,
  EditingState,
  GroupingState,
  IntegratedGrouping,
  DataTypeProvider,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableFilterRow,
  PagingPanel,
  ColumnChooser,
  TableColumnVisibility,
  TableEditColumn,
  Toolbar,
  TableColumnReordering,
  TableSelection,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import Modal from 'react-bootstrap/Modal';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../Forms/FormContainer';
import { notification } from '../AlertMessage/ToastifyAlert';
import {
  setPageSizeAction,
  setCurrentPageAction,
  setModalPageSizeAction,
  setModalCurrentPageAction,
} from '../../redux/actions/paginationAction';

const FPBTableIndustrial = ({
  tableData,
  headers,
  handleDelete,
  handleEdit,
  deleteChange,
  addedRow,
  readOnly,
  editOnly,
  deleteOnly,
  withSelection,
  onSelectionChange,
  selection,
  customColumnOrder,
  columnsToHide,
  handleChange,
  handleAPIRequestUpdate,
  added,
  edited,
  formName,
  onAdded,
  setAlert,
  setError,
  errorMessageUpdate,
  title,
  totalCount,
  setUncontroledCurrentPage,
  currentPage,
  pageSize,
  setFilters,
  productionOrder,
  disableFilters,
  showGroupingControls = true,
}) => {
  const [cols, setCols] = useState([]);
  const [columnWidths, setColumnWidths] = useState([]);
  const [rows, setRows] = useState([]);
  const [modalSize, setModalSize] = useState('lg');
  // Rows manipulation
  const [sorting, setSorting] = useState([]);
  // Pagination
  const [pageSizes] = useState([5, 10, 15]);
  const isModalPagination = useSelector((state) => state.pagination.isModalPagination);
  // Columns manipulation
  const [hiddenColumnNames, setHiddenColumnNames] = useState(columnsToHide);
  const [columnOrder, setColumnOrder] = useState([]);
  const [tableColumnExtensions] = useState(columnWidths);
  // Data operations
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [remainingRows, setRemainingRows] = useState({});
  const [startingId, setStartingId] = useState(0);
  // edition
  const [onEdited, setOnEdited] = useState();
  const [rowEditedId, setRowEditedId] = useState();
  // Filters options
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [currencyColumns] = useState([
    'value',
    'variable_amount',
    'reported_quantity',
    'quantity_nonconforming',
    'quality_quantity_nonconforming',
  ]);
  const [currencyFilterOperations] = useState(['equal', 'greaterThanOrEqual', 'lessThanOrEqual']);
  const [defaultFiltersOperations] = useState(['contains', 'equal']);

  const dispatch = useDispatch();

  const setPageSize = (pageSize) => {
    //when we change the pageSize we do not know if the current page exist.
    //Eg. if there are 10 rows and the pageSize is 5 we have page 2, but if pageSize is 10 we do not have page 2.
    if (setUncontroledCurrentPage) {
      setUncontroledCurrentPage(0);
    }
    if (isModalPagination) {
      dispatch(setModalPageSizeAction(pageSize));
      dispatch(setModalCurrentPageAction(0));
    } else {
      dispatch(setPageSizeAction(pageSize));
      dispatch(setCurrentPageAction(0));
    }
  };

  const setCurrentPage = (currentPage) => {
    if (setUncontroledCurrentPage) {
      setUncontroledCurrentPage(currentPage);
    } else {
      if (isModalPagination) {
        dispatch(setModalCurrentPageAction(currentPage));
      } else {
        dispatch(setCurrentPageAction(currentPage));
      }
    }
  };

  useEffect(() => {
    errorMessageUpdate ? notification(errorMessageUpdate, 'error') : null;
  }, [errorMessageUpdate]);

  useEffect(() => {
    setCurrentPage(0);
  }, [title]);

  const changeAddedRows = (value) => {
    const initialized = value.map((row) => (Object.keys(row).length ? row : {}));
    setAddedRows(initialized);
  };

  // Hide default messages
  const editColumnMessages = {
    editCommand: '',
    deleteCommand: '',
    commitCommand: '',
    cancelCommand: '',
  };

  const getRowId = (row) => row.id || row.code;
  // Configure column headers
  const columnsData = headers?.map((headerName) => {
    const container = {};
    container.name = headerName;
    container.title = headerName.replaceAll('_', ' ').toUpperCase();
    return container;
  });

  useEffect(() => {
    if (tableData !== null && tableData?.length > 0) {
      setRows(tableData);
    } else {
      setRows([]);
    }
  }, [tableData]);

  useEffect(() => {
    setStartingId(rows.length > 0 ? rows[rows.length - 1].id + 1 : 0);
  }, [rows]);

  useEffect(() => {
    if (columnsData.length > 0) {
      setDefaultColumns(headers);
      setCols(columnsData);
      const resizeColumns = columnsData.map((c) => ({
        columnName: c.name,
        width: 200,
      }));
      const columnNames = columnsData.map((c) => c.name);
      setColumnWidths(resizeColumns);
      setEditingRowIds([]);
      customColumnOrder ? setColumnOrder(customColumnOrder) : setColumnOrder(columnNames);
    }
  }, [headers]);

  // columnsToHide contains the names of columns we don't show by default in the current table
  useEffect(() => {
    setHiddenColumnNames(columnsToHide);
  }, [columnsToHide]);

  // For column resizing
  useEffect(() => {
    if (cols.length === 0) {
      setTimeout(() => {
        setCols(columnsData);
        const resizeColumns = columnsData?.map((c) => ({
          columnName: c.name,
          width: 200,
        }));
        setColumnWidths(resizeColumns);
      }, 1);
    }
  }, [cols]);

  // rows manipulation
  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      const editedId = parseInt(Object.keys(changed));
      changedRows = rows.map((row) =>
        changed[row.id || row.code]
          ? {
              ...row,
              ...changed[row.id || row.code],
            }
          : row
      );
      handleEdit(editedId, changed[editedId]);
      setRows(changedRows);
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter((row) => !deletedSet.has(row.id || row.code));
      handleDelete(deleted);
      setRemainingRows(changedRows);
    }
  };

  useEffect(() => {
    if (deleteChange === true) {
      setRows(remainingRows);
    }
  }, [deleteChange]);

  useEffect(() => {
    if (addedRow !== undefined && Object.keys(addedRow).length > 0) {
      let cod = addedRow.id || addedRow.code;
      cod = startingId;
    }
  }, [addedRow]);

  // Edit with pop up form
  const Popup = ({ row, setAddForm, open, rowChanges }) => {
    // We avoid that the new state with the value is lost at the moment the component is rendered
    // We need useEffect to only call setRowEditedId when the component is mounted
    useEffect(() => {
      if (rowEditedId === undefined || rowEditedId === null) {
        setRowEditedId(row.code || row.id);
      }
    }, []);

    return (
      <Modal show={open} onHide={setAddForm} aria-labelledby="form-dialog-title" size={modalSize}>
        <Modal.Header id="form-dialog-title" closeButton>
          <Modal.Title>Edit {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormContainer
            handleChange={handleChange}
            onAdded={onAdded}
            values={row}
            handleAPIRequestUpdate={handleAPIRequestUpdate}
            added={added}
            formName={formName}
            setAddForm={setAddForm}
            setAlert={setAlert}
            setError={setError}
            title={title}
            onEdited={(row) => {
              setOnEdited(row);
            }}
            edited={edited}
            productionOrder={productionOrder}
          />
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    if (onEdited) {
      const newRows = rows.map((row) => {
        if (row.id === rowEditedId) {
          return { id: rowEditedId, ...onEdited };
        } else if (row.code === rowEditedId) {
          return { code: rowEditedId, ...onEdited };
        }
        return row;
      });
      setRows(newRows);
      //We initialize the state to re-edit the records
      setRowEditedId();
    }
  }, [onEdited]);

  const PopupEditing = React.memo(function PopupEditing({ popupComponent: Popup }) {
    return (
      <Plugin>
        <Template name="popupEditing">
          <TemplateConnector>
            {(
              { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
              {
                changeRow,
                changeAddedRow,
                commitChangedRows,
                commitAddedRows,
                stopEditRows,
                cancelAddedRows,
                cancelChangedRows,
              }
            ) => {
              const isNew = addedRows.length > 0;
              let editedRow;
              let rowId;
              if (isNew) {
                rowId = 0;
                editedRow = addedRows[rowId];
              } else {
                [rowId] = editingRowIds;
                const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
                editedRow = { ...targetRow, ...rowChanges[rowId] };
              }

              const processValueChange = ({ target: { name, value } }) => {
                const changeArgs = {
                  rowId,
                  change: createRowChange(editedRow, value, name),
                };
                if (isNew) {
                  changeAddedRow(changeArgs);
                } else {
                  changeRow(changeArgs);
                }
              };
              const rowIds = isNew ? [0] : editingRowIds;
              const applyChanges = () => {
                if (isNew) {
                  commitAddedRows({ rowIds });
                } else {
                  stopEditRows({ rowIds });
                  commitChangedRows({ rowIds });
                }
              };
              const cancelChanges = () => {
                if (isNew) {
                  cancelAddedRows({ rowIds });
                } else {
                  stopEditRows({ rowIds });
                  cancelChangedRows({ rowIds });
                }
              };

              const open = editingRowIds.length > 0 || isNew;
              return (
                <Popup
                  open={open}
                  row={editedRow}
                  onChange={processValueChange}
                  onApplyChanges={applyChanges}
                  setAddForm={cancelChanges}
                  rowChanges={rowChanges}
                />
              );
            }}
          </TemplateConnector>
        </Template>
        <Template name="root">
          <TemplatePlaceholder />
          <TemplatePlaceholder name="popupEditing" />
        </Template>
      </Plugin>
    );
  });

  useEffect(() => {
    if (formName === 'productionOrder' || formName === 'productPerProductionOrder') {
      setModalSize('xl');
    }
  }, [formName]);

  return (
    <div className="card">
      <Grid key={title} rows={rows} columns={cols} getRowId={getRowId}>
        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={setEditingRowIds}
          rowChanges={rowChanges}
          onRowChangesChange={setRowChanges}
          addedRows={addedRows}
          onAddedRowsChange={changeAddedRows}
          onCommitChanges={commitChanges}
        />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <FilteringState onFiltersChange={setFilters} />
        <IntegratedFiltering />
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />
        <CustomPaging totalCount={totalCount} />
        <DragDropProvider />
        <GroupingState />
        <IntegratedGrouping />
        <DataTypeProvider
          for={defaultColumns}
          availableFilterOperations={defaultFiltersOperations}
        />
        <DataTypeProvider
          for={currencyColumns}
          availableFilterOperations={currencyFilterOperations}
        />

        <Table columnExtensions={tableColumnExtensions} />

        <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
        <TableHeaderRow showSortingControls showGroupingControls={showGroupingControls} />

        <SelectionState selection={selection} onSelectionChange={onSelectionChange} />
        {withSelection ? (
          <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
        ) : (
          <div />
        )}
        <TableGroupRow />
        <PagingPanel pageSizes={pageSizes} />
        {disableFilters ? null : <TableFilterRow showFilterSelector />}

        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <Toolbar />
        {showGroupingControls ? <GroupingPanel showGroupingControls /> : null}
        <ColumnChooser />
        {readOnly ? null : (
          <TableEditColumn showEditCommand showDeleteCommand messages={editColumnMessages} />
        )}
        {editOnly ? null : <TableEditColumn showEditCommand messages={editColumnMessages} />}
        {deleteOnly ? <TableEditColumn showDeleteCommand messages={editColumnMessages} /> : null}
        <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
        <PopupEditing popupComponent={Popup} />
      </Grid>
    </div>
  );
};

export default FPBTableIndustrial;
