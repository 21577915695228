import React, { useState, useEffect } from 'react';
import { Button, Card, Accordion, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { interval } from 'd3';
import apiService from '../../components/apiService';
import { fetchWorkCenters } from '../../redux/slices/workCenterSlice';
import FPBTable from '../../components/FPBTable/FPBTableIndustrial';
import { notification } from '../../components/AlertMessage/ToastifyAlert';
import Badge from '../../components/CoreComponents/Badge/Badge';
import PlayButton from '../../components/Buttons/PlayButton';
import ControlButtons from '../../components/Buttons/ControlButtons';
import {
  fetchEventsByOrder,
  selectCurrentOrderEvents,
  selectEventHeaders,
  selectEventTotalCount,
  eventColumnsToHide,
} from '../../redux/slices/eventsSlice';
import {
  fetchManualReportsByOrder,
  selectCurrentOrderReports,
  selectReportsHeaders,
  selectReportsTotalCount,
  manualReportsColumnsToHide,
} from '../../redux/slices/manualReportsSlice';
import {
  fetchQualityReportsByOrder,
  selectCurrentOrderQualityReports,
  selectQualityReportsHeaders,
  selectQualityReportsTotalCount,
  qualityReportsColumnsToHide,
} from '../../redux/slices/qualityReportsSlice';
import Manual from '../../components/IndustrialForms/Manual';
import Pause from '../../components/IndustrialForms/Pause';
import Done from '../../components/IndustrialForms/Done';
import StartEvent from '../../components/IndustrialForms/StartEvent';
import FinishEvent from '../../components/IndustrialForms/FinishEvent';
import { formatDateToTime } from '../../utils/formatDateToTime';
import { getFiltersString } from '../../utils/tableFilters';

const ProductionOrderProcessSection = ({ disableAdd }) => {
  const [filters, setFilters] = useState([]);
  // redux
  const dispatch = useDispatch();
  const productPerProductionOrder =
    useSelector((state) => state.productPerProductionOrders.currentProductPerProductionOrder) || {};
  const workCenter = useSelector((state) => state.workCenters.currentWorkCenter) || {};
  const currentWorkCenterConfiguration = useSelector(
    (state) => state.workCenters.currentWorkCenterConfigurations
  );
  const workCenterStatusToColor = useSelector((state) => state.workCenters.statusToColors) || {};
  const orderStatusToColor =
    useSelector((state) => state.productPerProductionOrders.statusToColors) || {};

  // manual reports redux
  const manualReportsData = useSelector(selectCurrentOrderReports);
  const manualReportsHeaders = useSelector(selectReportsHeaders);
  const manualReportsTotalCount = useSelector(selectReportsTotalCount);
  const manualTableColumnsToHide = useSelector(manualReportsColumnsToHide);

  // events redux
  const eventsData = useSelector(selectCurrentOrderEvents);
  const eventsHeaders = useSelector(selectEventHeaders);
  const eventsTotalCount = useSelector(selectEventTotalCount);
  const eventTableColumnsToHide = useSelector(eventColumnsToHide);

  // quality redux
  const qualityData = useSelector(selectCurrentOrderQualityReports);
  const qualityHeaders = useSelector(selectQualityReportsHeaders);
  const qualityTotalCount = useSelector(selectQualityReportsTotalCount);
  const qualityTableColumnsToHide = useSelector(qualityReportsColumnsToHide);

  const [hasIOT, setHasIOT] = useState(false);
  const disableReports =
    productPerProductionOrder.production_status === 'paused' ||
    productPerProductionOrder.production_status === 'closed';

  // manual report states
  const [showManualForm, setShowManualForm] = useState(false);
  const [manualReportSuccess, setManaualReportSuccess] = useState(false);

  // pause report states
  const [showPauseForm, setShowPauseForm] = useState(false);
  const [pauseReportSuccess, setPauseReportSuccess] = useState(false);

  // done report states
  const [showDoneForm, setShowDoneForm] = useState(false);
  const [doneReportSuccess, setDoneReportSuccess] = useState(false);

  // event report states
  const [startEventSuccess, setStartEventSuccess] = useState(false);
  const [finishEventSuccess, setFinishEventSucess] = useState(false);
  const [showStartEventForm, setShowStartEventForm] = useState(false);
  const [showFinishEventForm, setShowFinishEventForm] = useState(false);
  const [productStatus, setProductStatus] = useState(
    productPerProductionOrder.production_status.charAt(0).toUpperCase() +
      productPerProductionOrder.production_status.slice(1)
  );

  const [workCenterStatus, setWorkCenterStatus] = useState(workCenter.status);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  //timer
  const [clock, setClock] = useState(productPerProductionOrder.start_date ? true : false);
  const [time, setTime] = useState('');

  // pagination
  const [qualityCurrentPage, setQualityCurrentPage] = useState(0);
  const [eventCurrentPage, setEventCurrentPage] = useState(0);
  const [manualCurrentPage, setManualCurrentPage] = useState(0);
  const pageSize = useSelector((state) => state.pagination.pageSize);

  useEffect(() => {
    alertMessage !== '' ? notification(alertMessage, 'success') : null;
  }, [alertMessage]);

  useEffect(() => {
    errorMessage !== '' ? notification(errorMessage, 'error') : null;
  }, [errorMessage]);

  useEffect(() => {
    currentWorkCenterConfiguration?.forEach(
      (config) => config.data_source === 'iot' && setHasIOT(true)
    );
  }, [currentWorkCenterConfiguration]);

  const handleStart = async () => {
    // this is a workaround to work without miliseconds
    const partialDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
    const date = new Date(partialDate);
    try {
      const response = await apiService.postStartProductionOrder({
        id: productPerProductionOrder.id,
        workCenter: workCenter.code,
        startDate: date.toISOString(),
      });
      if (response.status === 201) {
        setProductStatus('Started');
        setWorkCenterStatus('In process');
        setAlertMessage(`Production order ${productPerProductionOrder.code} started successfully`);
        dispatch(fetchWorkCenters());
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(`Error while starting production order ${productPerProductionOrder.code}`);
    }
  };

  const handlePause = async () => {
    setShowPauseForm(true);
  };

  const handleDone = () => {
    setShowDoneForm(true);
  };

  const handleResume = async () => {
    // this is a workaround to work without miliseconds
    const partialDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
    const date = new Date(partialDate);
    try {
      const response = await apiService.postResumeProductionOrder({
        id: productPerProductionOrder.id,
        workCenter: workCenter.code,
        startDate: date.toISOString(),
      });
      if (response.status === 201) {
        setProductStatus('Started');
        setWorkCenterStatus('In process');
        dispatch(fetchWorkCenters());
        setAlertMessage(
          `Production order ${productPerProductionOrder.production_order} resumed successfully`
        );
      } else {
        setErrorMessage(
          `Error while resuming production order ${productPerProductionOrder.production_order}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (pauseReportSuccess) {
      pause();
      setProductStatus('Paused');
      dispatch(fetchWorkCenters());
    }
  }, [pauseReportSuccess]);

  useEffect(() => {
    if (doneReportSuccess) {
      pause();
      setProductStatus('Closed');
      dispatch(fetchWorkCenters());
    }
  }, [doneReportSuccess]);

  useEffect(() => {
    if (startEventSuccess || finishEventSuccess) {
      dispatch(fetchWorkCenters());
    }
  }, [startEventSuccess, finishEventSuccess]);

  function pause() {
    setClock(false);
  }

  // Compute time difference between now and the start_date from the product per production order
  useEffect(() => {
    const refTime = new Date(productPerProductionOrder.start_date);
    interval.current = setInterval(() => {
      if (clock) {
        let ms = Date.now() - refTime;
        setTime(formatDateToTime(ms));
      }
    }, 1000 / 60);

    return () => {
      clearInterval(interval.current);
      interval.current = null;
    };
  }, []);

  // fetch manual reports for current workCenter and order
  useEffect(() => {
    const filtersString = getFiltersString(filters);
    dispatch(
      fetchManualReportsByOrder({
        page: manualCurrentPage + 1,
        pageSize,
        productionOrder: productPerProductionOrder.id,
        workCenter: workCenter.code,
        filters: filtersString,
      })
    );
  }, [dispatch, pageSize, manualCurrentPage, manualReportSuccess, filters]);

  // fetch events for current workCenter and order
  useEffect(() => {
    const filtersString = getFiltersString(filters);
    dispatch(
      fetchEventsByOrder({
        page: eventCurrentPage + 1,
        pageSize,
        productionOrder: productPerProductionOrder.id,
        workCenter: workCenter.code,
        filters: filtersString,
      })
    );
  }, [dispatch, pageSize, eventCurrentPage, filters]);

  useEffect(() => {
    const filtersString = getFiltersString(filters);
    dispatch(
      fetchQualityReportsByOrder({
        page: qualityCurrentPage + 1,
        pageSize,
        productionOrder: productPerProductionOrder.production_order,
        workCenter: workCenter.code,
        product: productPerProductionOrder.product,
        filters: filtersString,
      })
    );
  }, [dispatch, pageSize, qualityCurrentPage, manualReportSuccess, filters]);

  return (
    <>
      <Row className="mb-3">
        <Col className="col-sm-12 col-md-4">
          <Card className="w-100 h-100">
            <Card.Body className="process-order-card">
              <Card.Title>{`[${workCenter.code}] ${workCenter.description}`}</Card.Title>
              <div className="mt-5">
                <Card.Text className="text-muted">
                  <Badge color={workCenterStatusToColor[workCenterStatus]} /> {workCenterStatus}
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-sm-12 col-md-5 mx-0 px-0">
          <Card className="w-100 h-100">
            <Card.Body className="process-order-card">
              <div className="d-flex justify-content-between">
                <Card.Title>{`[${productPerProductionOrder.product}] ${productPerProductionOrder.product_description}`}</Card.Title>
                <span className="font-weight-bold blue">{`${productPerProductionOrder.amount_1} ${productPerProductionOrder.dimensional_unit_1}`}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">
                  Work Center:
                  {` ${
                    productPerProductionOrder.work_center
                      ? productPerProductionOrder.work_center
                      : ' no planned'
                  }`}
                </span>
                <span className="text-muted">
                  Dispatch date:
                  {` ${
                    productPerProductionOrder.dispatch_date
                      ? new Date(productPerProductionOrder.dispatch_date)
                          .toString()
                          .substring(0, 25)
                      : ' no planned'
                  }`}
                </span>
              </div>
              <div className="mt-3">
                <span className="text-muted">
                  <Badge color={orderStatusToColor[productStatus]} /> {productStatus}
                </span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="justify-content-center col-sm-12 col-md-3">
          {productStatus !== 'Paused' &&
            productStatus !== 'Closed' &&
            workCenterStatus === 'Available' && (
              <PlayButton handleStart={handleStart} label={'Click to start processing'} />
            )}
          {productStatus === 'Started' && workCenterStatus === 'In process' && (
            <ControlButtons handleDone={handleDone} handlePause={handlePause} timer={time} />
          )}
          {productStatus === 'Paused' && workCenterStatus === 'Available' && (
            <PlayButton handleStart={handleResume} label={'Click to resume order'} />
          )}
          {productStatus === 'Started' && workCenterStatus === 'In event' && (
            <ControlButtons
              handleDone={() => {}}
              handlePause={() => {}}
              timer={time}
              disabled={true}
            />
          )}
        </Col>
      </Row>

      {workCenterStatus !== 'Available' ||
      productStatus === 'Paused' ||
      productStatus === 'Closed' ? (
        <>
          <div className="btn-container d-flex justify-content-between">
            <div>
              {disableAdd ? null : (
                <Button
                  disabled={disableReports || hasIOT || workCenterStatus === 'In event'}
                  className="fpb-btn mr-2"
                  onClick={() => {
                    setShowManualForm(true);
                  }}
                >
                  Add partial report
                </Button>
              )}

              <Modal
                show={showManualForm}
                onHide={setShowManualForm}
                size="lg"
                style={{ zIndex: 8000 }}
              >
                <Modal.Header>
                  <Modal.Title>Partial report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5>{`[${workCenter.code}] ${workCenter.description}`}</h5>
                  <h5>{`[${productPerProductionOrder.product}] ${productPerProductionOrder.product_description}`}</h5>
                  <Manual
                    added={manualReportSuccess}
                    setAdded={setManaualReportSuccess}
                    setAddForm={setShowManualForm}
                    setAlertMessage={setAlertMessage}
                    setErrorMessage={setErrorMessage}
                  />
                </Modal.Body>
              </Modal>

              <Modal show={showPauseForm} onHide={setShowPauseForm} size="lg">
                <Modal.Header>
                  <Modal.Title>Pause report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5>{`[${workCenter.code}] ${workCenter.description}`}</h5>
                  <h5>{`[${productPerProductionOrder.product}] ${productPerProductionOrder.product_description}`}</h5>
                  <Pause
                    added={pauseReportSuccess}
                    setAdded={setPauseReportSuccess}
                    setAddForm={setShowPauseForm}
                    setAlertMessage={setAlertMessage}
                    setErrorMessage={setErrorMessage}
                  />
                </Modal.Body>
              </Modal>

              <Modal show={showDoneForm} onHide={setShowDoneForm} size="lg">
                <Modal.Header>
                  <Modal.Title>Done report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5>{`[${workCenter.code}] ${workCenter.description}`}</h5>
                  <h5>{`[${productPerProductionOrder.product}] ${productPerProductionOrder.product_description}`}</h5>
                  <Done
                    added={doneReportSuccess}
                    setAdded={setDoneReportSuccess}
                    setAddForm={setShowDoneForm}
                    setAlertMessage={setAlertMessage}
                    setErrorMessage={setErrorMessage}
                  />
                </Modal.Body>
              </Modal>

              <Modal show={showStartEventForm} onHide={setShowStartEventForm} size="lg">
                <Modal.Header>
                  <Modal.Title>Event report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5>{`[${workCenter.code}] ${workCenter.description}`}</h5>
                  <h5>{`[${productPerProductionOrder.product}] ${productPerProductionOrder.product_description}`}</h5>
                  <StartEvent
                    added={startEventSuccess}
                    setAdded={setStartEventSuccess}
                    setAddForm={setShowStartEventForm}
                    setAlertMessage={setAlertMessage}
                    setErrorMessage={setErrorMessage}
                  />
                </Modal.Body>
              </Modal>

              <Modal show={showFinishEventForm} onHide={setShowFinishEventForm} size="lg">
                <Modal.Header>
                  <Modal.Title>Finish event report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5>{`[${workCenter.code}] ${workCenter.description}`}</h5>
                  <h5>{`[${productPerProductionOrder.product}] ${productPerProductionOrder.product_description}`}</h5>
                  <FinishEvent
                    added={finishEventSuccess}
                    setAdded={setFinishEventSucess}
                    setAddForm={setShowFinishEventForm}
                    setAlertMessage={setAlertMessage}
                    setErrorMessage={setErrorMessage}
                  />
                </Modal.Body>
              </Modal>

              {workCenterStatus !== 'In event' ? (
                <Button
                  disabled={disableReports}
                  className="fpb-btn mr-4"
                  onClick={() => setShowStartEventForm(true)}
                >
                  Add event report
                </Button>
              ) : (
                <Button
                  disabled={disableReports}
                  className="fpb-btn mr-4"
                  onClick={() => setShowFinishEventForm(true)}
                >
                  Finish Event
                </Button>
              )}
            </div>
          </div>

          <div className="container-fluid px-0">
            <Accordion defaultActiveKey={['0']}>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Card.Title} eventKey="0" className="font-weight-bold mt-2">
                    Manual reports
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {manualReportsData.length > 0 ? (
                      <FPBTable
                        readOnly={true}
                        editOnly={true}
                        headers={manualReportsHeaders}
                        tableData={manualReportsData}
                        columnsToHide={manualTableColumnsToHide}
                        title={'Manual Reports'}
                        totalCount={manualReportsTotalCount}
                        pageSize={pageSize}
                        currentPage={manualCurrentPage}
                        customColumnOrder={[]}
                        setFilters={setFilters}
                        setUncontroledCurrentPage={setManualCurrentPage}
                      />
                    ) : (
                      'There are no manual reports for this order yet.'
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Card.Title} eventKey="1" className="font-weight-bold mt-2">
                    Events
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    {eventsData?.length > 0 ? (
                      <FPBTable
                        readOnly={true}
                        editOnly={true}
                        headers={eventsHeaders}
                        tableData={eventsData}
                        title={'Event Reports'}
                        totalCount={eventsTotalCount}
                        pageSize={pageSize}
                        currentPage={eventCurrentPage}
                        columnsToHide={eventTableColumnsToHide}
                        setFilters={setFilters}
                        setUncontroledCurrentPage={setEventCurrentPage}
                      />
                    ) : (
                      'There are no registered events for this work center.'
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Card.Title} eventKey="2" className="font-weight-bold mt-2">
                    Quality
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    {qualityData.length > 0 ? (
                      <FPBTable
                        readOnly={true}
                        editOnly={true}
                        headers={qualityHeaders}
                        tableData={qualityData}
                        title={'Quality Reports'}
                        totalCount={qualityTotalCount}
                        pageSize={pageSize}
                        currentPage={qualityCurrentPage}
                        columnsToHide={qualityTableColumnsToHide}
                        setUncontroledCurrentPage={setQualityCurrentPage}
                        setFilters={setFilters}
                      />
                    ) : (
                      'There are no quality reports for this order yet.'
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ProductionOrderProcessSection;
