import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Button, Dropdown, DropdownButton, Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterList } from '../../redux/slices/indicatorFilterSlice';
import { setFilters } from '../../redux/slices/availabilitySlice';
import {
  startDateFilter,
  endDateFilter,
  accomplishmentFilter,
  opListFilter,
} from '../../redux/slices/timeAccomplishmentSlice';
import * as RiIcons from 'react-icons/ri';
import { DateRange } from 'react-date-range';
import { lastDayOfWeek, startOfYear, endOfYear } from 'date-fns';
import { Formik } from 'formik';
import dateFuncs from '../../utils/dateFuncs';
import CustomSelectGroup from '../FormComponents/CustomSelect/CustomSelectGroup';
import Modal from 'react-bootstrap/Modal';
import FormContainer from '../Forms/FormContainer';

const FiltersAcomplishment = ({ formName }) => {
  const [showFilters, setShowFilters] = useState(true);
  const [addForm, setAddForm] = useState(false);
  const [disableFilters, setDisableFilters] = useState(true);
  const opList = useSelector((state) => state.timeAccomplishment.opList);
  const dispatch = useDispatch();

  // filters
  const [predefinedTimeFilter, setPredefinedTimeFilter] = useState('');
  const [dateFilterSelected, setDateFilterSelected] = useState('day');
  const [accomplishment, setAccomplishment] = useState({ value: 'all', label: 'All' });

  // dates
  const now = useRef(new Date());
  const [to, setTo] = useState(null);
  const [from, setFrom] = useState(null);
  const [inputTo, setInputTo] = useState(null);
  const [inputFrom, setInputFrom] = useState(null);
  const [dayList, setDayList] = useState([]);
  const [dayPicker, setDayPicker] = useState(false);
  const [isEndDateInput, setIsEndDateInput] = useState();
  const [isStartDateInput, setIsStartDateInput] = useState();
  const [dayFilterCalendar, setDayFilterCalendar] = useState(false);
  const [isCustomRange, setIsCustomRange] = useState(false);

  useEffect(() => {
    if (opList.length !== 0) {
      setDisableFilters(true);
      setFrom(null);
      setTo(null);
      setInputFrom('');
      setInputTo('');
      setAccomplishment({ value: 'all', label: 'All' });
      setIsCustomRange(false);
    } else {
      setDisableFilters(false);
      setIsCustomRange(true);
    }
  }, [opList]);

  const handleClearFilter = () => {
    dispatch(setFilterList([]));
    dispatch(startDateFilter(''));
    dispatch(endDateFilter(''));
    dispatch(accomplishmentFilter(''));
    dispatch(opListFilter([]));
    setFrom('');
    setTo('');
    setInputFrom('');
    setInputTo('');
    setAccomplishment({ value: 'all', label: 'All' });
    setIsCustomRange(true);
  };

  const handleDateFilter = (filterItem) => {
    setPredefinedTimeFilter('');
    setIsCustomRange(true);
    setFrom(now.current);
    setTo(now.current);
    if (filterItem == 'Week') {
      setDateFilterSelected('week');
    } else if (filterItem == 'Month') {
      setDateFilterSelected('month');
    } else if (filterItem == 'Day') {
      setDateFilterSelected('day');
      setDayPicker(true);
    } else if (filterItem == 'Year') {
      setInputFrom('');
      setInputTo('');
      setDateFilterSelected('year');
    }
  };

  const handleDateInput = () => {
    setDayPicker(true);
  };

  const handleApplyFilter = () => {
    const fromDate = from ? from.toISOString().split('T', 1)[0] : '';
    const toDate = to ? to.toISOString().split('T', 1)[0] : '';
    const filters = {
      accomplishment: accomplishment || null,
      from: from ? from.toDateString() : null,
      to: to ? to.toDateString() : null,
      temporality: dateFilterSelected,
    };
    dispatch(startDateFilter(fromDate));
    dispatch(endDateFilter(toDate));
    dispatch(accomplishmentFilter(accomplishment.value || ''));
    setDisableFilters(true);
    setIsCustomRange(false);
  };

  function getDateOfISOWeek(w, y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  // Returns the ISO week of the date.
  Date.prototype.getWeek = function () {
    const date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
      )
    );
  };

  const twoDigitMonth = (n) => (n < 10 ? `0${n}` : `${n}`);

  const getWeekNumber = () => {
    const currentdate = new Date();
    const oneJan = new Date(currentdate.getFullYear(), 0, 1);
    const numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
    const result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
    return result;
  };

  const maxDates = {
    day: now.current,
    week: `${now.current.getFullYear()}-W${getWeekNumber() - 1}`,
    month: `${now.current.getFullYear()}-${twoDigitMonth(now.current.getMonth() + 1)}`,
    year: now.current.getFullYear(),
  };

  // handle selection of days in day picker
  const handleSelect = useCallback(({ selection: { startDate, endDate } }) => {
    let daylist = dateFuncs.getDaysArray(startDate, endDate);
    daylist = daylist.map((v) => v.toISOString().split('T', 1)[0]);
    setDayList(daylist);
    setFrom(startDate);
    setTo(endDate);
    daylist.length > 2 ? setDayPicker(false) : null;
  });

  const ranges = useMemo(
    () => [
      {
        startDate: from,
        endDate: to,
        key: 'selection',
      },
    ],
    [from, to]
  );

  const validateYearFilter = () => {
    if (
      parseInt(inputFrom) > 2000 &&
      parseInt(inputTo) > 2000 &&
      parseInt(inputTo) < new Date().getFullYear() + 1 &&
      parseInt(inputFrom) < parseInt(inputTo)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (dateFilterSelected === 'year') {
      const date = new Date();
      const startYear = startOfYear(new Date(date.getFullYear() - 2, 0, 1, 0, 0, 0));
      setFrom(startYear);
      setInputFrom(new Date(date.toISOString().split('T', 1)[0]).getFullYear() - 2);
      const endYear = new Date(date.getFullYear(), 11, 31);
      setTo(endYear);
      setInputTo(new Date(date.toISOString().split('T', 1)[0]).getFullYear());
    }
  }, [dateFilterSelected]);

  useEffect(() => {
    let isValidDate = true;
    if (dateFilterSelected === 'year') {
      isValidDate = validateYearFilter();
    }

    if (from !== '' && to !== '' && isValidDate) {
      const sd = from;
      const ed = to;
      let daylist = dateFuncs.getDaysArray(sd, ed);
      daylist = daylist.map((v) => v.toISOString().split('T', 1)[0]);
      setDayList(daylist);
    }
  }, [to, from]);

  const handleInputChangeStart = (date) => {
    if (dateFilterSelected === 'week') {
      const year = date.split('-', 1)[0];
      const week = date.split('W')[1];
      const startDayOfW = getDateOfISOWeek(week, year);
      //From contain the date of the first day of the week
      setFrom(new Date(startDayOfW));
      //InputFrom contain the raw input (2022-W10)
      setInputFrom(date);
    } else if (dateFilterSelected === 'month') {
      const startMonth = `${date}-01T00:00:00`;
      setFrom(new Date(startMonth));
      setInputFrom(date);
    } else if (dateFilterSelected === 'year') {
      const startYear = startOfYear(new Date(date, 0, 1, 0, 0, 0));
      setInputFrom(date);
      setFrom(startYear);
    }
  };

  const handleInputChangeEnd = (date) => {
    if (dateFilterSelected === 'week') {
      //week format is 2022-W10
      const year = date.split('-', 1)[0];
      const week = date.split('W')[1];
      const startDate = getDateOfISOWeek(week, year);
      const lastDayOfW = lastDayOfWeek(startDate, { weekStartsOn: 1 });
      //To contain the date of the last day of the week
      setTo(lastDayOfW);
      //InputTo contain the raw input (2022-W10)
      setInputTo(date);
    } else if (dateFilterSelected === 'month') {
      const month = date.split('-')[1];
      const lastDate = new Date(date.split('-')[0], month, 0);
      setTo(lastDate);
      setInputTo(date);
    } else if (dateFilterSelected === 'year') {
      const endYear = endOfYear(new Date(date, 11, 31, 12, 0, 0));
      setTo(endYear);
      setInputTo(date);
    }
  };

  useEffect(() => {
    const filters = {
      opList: [],
      accomplishment: accomplishment || null,
      from: from ? from.toDateString() : null,
      to: to ? to.toDateString() : null,
      temporality: dateFilterSelected,
    };
    dispatch(setFilters(filters));
  }, []);

  return (
    <>
      <Formik validateOnChange={true}>
        <Form className="ml-0 pr-3">
          {showFilters ? (
            <>
              <Modal show={addForm} onHide={setAddForm} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Production Order List </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormContainer formName={'timeAccomplishment'} setAddForm={setAddForm} />
                </Modal.Body>
              </Modal>
              <Form.Row className="pl-1 ">
                <Col className="col-sm-12 col-md-3 pb-3">
                  <Button
                    disabled={disableFilters}
                    block
                    variant="secondary"
                    onClick={() => {
                      setAddForm(true);
                    }}
                  >
                    Production order list
                  </Button>
                </Col>
                {formName !== 'AccomplishmentByQuantity' && (
                  <Col className="col-sm-12 col-md-9">
                    <CustomSelectGroup
                      disabled={disableFilters}
                      className="select-group"
                      onChange={(option) => {
                        setAccomplishment(option);
                      }}
                      label="Accomplishment"
                      name="accomplishment"
                      options={[
                        { value: 'all', label: 'All' },
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                        { value: 'na', label: 'N/A' },
                      ]}
                      defaultValue={{ value: 'All', label: 'All' }}
                      value={accomplishment}
                    />
                  </Col>
                )}
              </Form.Row>
              <Form.Row>
                <Col className="col-sm-12 col-md-6 px-0">
                  <Row className="px-0 mx-0">
                    <Col className="col-sm-12 col-md-2 d-flex px-2">
                      <DropdownButton
                        disabled={disableFilters}
                        align={{ lg: 'end' }}
                        variant={dateFilterSelected ? 'secondary' : 'outline-secondary'}
                        title={dateFilterSelected || 'Custom range'}
                        id="input-group-dropdown-2"
                      >
                        {['Day', 'Week', 'Month', 'Year'].map((filterItem, idx) => (
                          <Dropdown.Item
                            key={idx}
                            href="#"
                            onClick={() => handleDateFilter(filterItem)}
                          >
                            {filterItem}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Col>

                    {/* if filter is year, month or  week, show default date inputs */}

                    <Col className="col-md-10">
                      <Row
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Col className="col-md-6">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="basic-addon1">
                                Start
                              </span>
                            </div>
                            <input
                              className="form-control"
                              style={
                                dateFilterSelected == 'year'
                                  ? { paddingLeft: '3rem', paddingRight: '1rem' }
                                  : null
                              }
                              type={dateFilterSelected == 'year' ? 'number' : dateFilterSelected}
                              value={
                                (dateFilterSelected === 'day' || predefinedTimeFilter !== '') &&
                                from
                                  ? from?.toISOString().split('T', 1)[0]
                                  : inputFrom
                              }
                              min={dateFilterSelected === 'year' ? '2018' : undefined}
                              max={dateFilterSelected === 'year' ? maxDates.year : undefined}
                              disabled={!isCustomRange}
                              onChange={(e) => {
                                handleInputChangeStart(e.target.value);
                              }}
                              onClick={() => {
                                handleDateInput();
                                setIsEndDateInput();
                                setIsStartDateInput(true);
                                setDayFilterCalendar(true);
                              }}
                            />
                          </div>
                        </Col>
                        <Col className="col-md-6">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="basic-addon1">
                                End
                              </span>
                            </div>
                            <input
                              className="form-control"
                              style={
                                dateFilterSelected == 'year'
                                  ? { paddingLeft: '3rem', paddingRight: '1rem' }
                                  : null
                              }
                              min={dateFilterSelected === 'year' ? '2018' : undefined}
                              type={dateFilterSelected == 'year' ? 'number' : dateFilterSelected}
                              value={
                                (dateFilterSelected === 'day' || predefinedTimeFilter !== '') && to
                                  ? to?.toISOString().split('T', 1)[0]
                                  : inputTo
                              }
                              max={
                                dateFilterSelected === 'year'
                                  ? maxDates.year
                                  : dateFilterSelected === 'month'
                                  ? maxDates.month
                                  : dateFilterSelected === 'week'
                                  ? maxDates.week
                                  : dateFilterSelected === 'day'
                                  ? maxDates.day
                                  : ''
                              }
                              disabled={!isCustomRange}
                              onChange={(e) => {
                                handleInputChangeEnd(e.target.value);
                              }}
                              onClick={() => {
                                handleDateInput();
                                setIsEndDateInput(true);
                                setIsStartDateInput();
                                setDayFilterCalendar(true);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Form.Row>
              {/* day filter */}
              {dateFilterSelected === 'day' && dayPicker && (
                <Form.Row>
                  <DateRange
                    ranges={ranges}
                    onChange={handleSelect}
                    showSelectionPreview
                    moveRangeOnFirstSelection={false}
                    months={2}
                    direction="horizontal"
                    calendarFocus="forwards"
                    maxDate={maxDates.day}
                    retainEndDateOnFirstSelection
                    {...(dayFilterCalendar && {
                      focusedRange: isEndDateInput ? [0, 1] : isStartDateInput ? [0, 0] : {},
                    })}
                  />
                </Form.Row>
              )}
            </>
          ) : null}
          <Form.Row className="mb-3">
            <Button
              className="dark-button mx-2"
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              {showFilters ? 'Hide filters ' : 'Show filters '}
              {showFilters ? <RiIcons.RiEyeOffFill /> : <RiIcons.RiEyeFill />}
            </Button>
            {showFilters ? (
              <>
                <Button className="fpb-btn" onClick={handleApplyFilter}>
                  Apply Filters
                </Button>
                <Button className="fpb-btn-inverse ml-2" onClick={handleClearFilter}>
                  Clear Filters
                </Button>
              </>
            ) : null}
          </Form.Row>
        </Form>
      </Formik>
    </>
  );
};

export default FiltersAcomplishment;
