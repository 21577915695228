import { useEffect, useState } from 'react';
import FPBTableIndustrial from '../../FPBTable/FPBTableIndustrial';
import { useQuery } from '@tanstack/react-query';
import apiService from '../../apiService';
import { useSelector, useDispatch } from 'react-redux';
import {
  setOrderToTrace,
  setOrderToTraceProducts,
} from '../../../redux/slices/productionOrderSlice';

const OrderTableSelection = () => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  const [filters, setFilters] = useState('');
  const [filtersFromTable, setFiltersFromTable] = useState([]);
  const [selected, setSelected] = useState([]);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['orders', page, pageSize, filters],
    queryFn: () => apiService.getOrdersWithPagination(page, pageSize, filters),
    keepPreviousData: true,
  });

  useEffect(() => {
    const filter = filtersFromTable
      .reduce((acc, { columnName, operation, value }) => {
        switch (operation) {
          case 'contains': {
            acc.push(`${columnName}__icontains=${encodeURIComponent(value)}`);
            break;
          }
          case 'equal': {
            acc.push(`${columnName}__exact=${encodeURIComponent(value)}`);
            break;
          }
          default:
            acc.push(`${columnName}__${operation}=${encodeURIComponent(value)}`);
            break;
        }
        return acc;
      }, [])
      .join('&');
    setFilters(filter);
  }, [filtersFromTable]);

  const handleSelectionChange = (selection) => {
    const lastSelection = selection[selection.length - 1];
    if (lastSelection) {
      const selectedProduct = data?.results?.filter((order) => order.code === lastSelection)[0];
      dispatch(setOrderToTrace(lastSelection));
      dispatch(setOrderToTraceProducts(selectedProduct?.product_list));
      setSelected([lastSelection]);
    }
  };

  if (error) {
    return null;
  }

  return (
    <>
      {!isLoading && !isError && data && (
        <FPBTableIndustrial
          tableData={data.results}
          headers={data.headers}
          formName={''}
          readOnly
          editOnly
          deleteOnly={false}
          withSelection
          showGroupingControls={false}
          selection={selected}
          onSelectionChange={handleSelectionChange}
          totalCount={data.count}
          pageSize={pageSize}
          currentPage={page}
          setFilters={setFiltersFromTable}
        />
      )}
    </>
  );
};

export default OrderTableSelection;
