import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import FPBTable from '../FPBTable/FPBTable';
import HeaderTitle from '../CoreComponents/PageTitle/PageTitle';
import Urls from '../Urls';
import AlertMessage from '../AlertMessage/AlertMessage';
import ActionConfirmCustomMessage from '../ActionConfirmation/ActionConfirmCustomMessage';
import { setCurrentPageAction, setTotalCountAction } from '../../redux/actions/paginationAction';
import mapTable from '../mapTableService';
import { notification } from '../AlertMessage/ToastifyAlert';
import getTransactionTableData from './getTransactionTableData';
import { columnsToHide } from '../Transactions/transactionTableColumns';
import getQueryString from '../../utils/getTransactionsQueryString';

const AproveReturn = () => {
  var flag = 0;
  const url = Urls.transaction;
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const [token] = useState(localStorage.getItem('accessToken'));
  const [addedRow, setAddedRow] = useState({});
  const [headers, setHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteChange, setDeleteChange] = useState(false);
  const [selected, setSelected] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [option, setOption] = useState(true);
  // pagination
  const [filters, setFilters] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const totalCount = useSelector((state) => state.pagination.totalCount);
  const currentPage = useSelector((state) => state.pagination.currentPage);
  const pageSize = useSelector((state) => state.pagination.pageSize);
  const dispatchTotalCount = useDispatch();
  const dispatch = useDispatch();
  const setTotalCount = (totalCount) => {
    dispatchTotalCount(setTotalCountAction(totalCount));
  };

  useEffect(async () => {
    mapTable.createEntryOrderMap();
    mapTable.createDimensionalUnitDescriptionMap();
    mapTable.createDispatchOrderMap();
    mapTable.createAdjustmentMap();
    mapTable.createStorageCenterMap();
    mapTable.createStorageLocationMap();
  }, []);

  useEffect(() => {
    if (filters.length > 0) {
      dispatch(setCurrentPageAction(0));
    }
  }, [filters]);

  useEffect(() => {
    const queryString = getQueryString(currentPage, pageSize, filters, '&pending_transaction=yes');
    getTransactionTableData(
      queryString,
      lastQuery,
      token,
      setTotalCount,
      setHeaders,
      setTableData,
      setLastQuery
    );
  }, [url, pageSize, currentPage, filters]);

  const handleClose = () => {
    setShow(false);
  };

  const filteredData = tableData;

  async function handleApprove() {
    let userEmail;
    if (userProfile.email) {
      userEmail = userProfile.email;
    } else {
      userEmail = '';
    }
    const date = new Date();
    const dateString = date.toISOString();
    if (selected) {
      for (let i = 0; i <= selected.length - 1; i++) {
        const id = selected[i];
        await axios
          .patch(
            `${url}/${id}`,
            {
              return_approved: 'yes',
              return_approval_user: userEmail,
              return_approval_date: dateString,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              if (flag === 0) {
                notification('Transaction return approved', 'success');
                flag = 1;
              }
              console.log(res);
            }
          })
          .catch((err) => {
            if (flag === 0) {
              notification('Error approving the transaction', 'error');
              flag = 1;
            }
            console.log(err);
          });
      }
    }
    setTimeout(() => {
      window.location.reload(1);
    }, 1000);
  }

  async function handleDenny() {
    let userEmail;
    if (userProfile.email) {
      userEmail = userProfile.email;
    } else {
      userEmail = '';
    }
    const date = new Date();
    const dateString = date.toISOString();
    if (selected) {
      for (let i = 0; i <= selected.length - 1; i++) {
        const id = selected[i];
        await axios
          .patch(
            `${url}/${id}`,
            {
              return_approved: 'no',
              return_approval_user: userEmail,
              return_approval_date: dateString,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              if (flag === 0) {
                notification('Transaction return denied', 'success');
                flag = 1;
              }
            }
          })
          .catch((err) => {
            if (flag === 0) {
              notification('Error approving the transaction', 'error');
              flag = 1;
            }
            console.log(err);
          });
      }
    }
    setTimeout(() => {
      window.location.reload(1);
    }, 1000);
  }

  const handleConfirmation = () => {
    if (option) {
      handleApprove();
    } else {
      handleDenny();
    }
    setShow(false);
  };

  const handleModal = (flag) => {
    if (flag) {
      setModalMessage('Are you sure to approve this return?');
      setOption(true);
    } else {
      setModalMessage('Are you sure to deny this return?');
      setOption(false);
    }
    setShow(true);
  };

  return (
    <div>
      <HeaderTitle title="Approve Return" />
      <ActionConfirmCustomMessage
        message={modalMessage}
        show={show}
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
      />
      <div className="btn-container">
        <Button
          className="fpb-btn mx-2"
          onClick={() => handleModal(true)}
          disabled={selected.length === 0}
        >
          Approve
        </Button>
        <Button
          className="fpb-btn mx-2"
          onClick={() => handleModal(false)}
          disabled={selected.length === 0}
        >
          Deny
        </Button>
      </div>
      {alertMessage || errorMessage ? (
        <AlertMessage
          variant={alertMessage ? 'success' : 'danger'}
          message={alertMessage || errorMessage}
          setAlertMessage={setAlertMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      <FPBTable
        readOnly
        withCheckBox
        headers={headers}
        tableData={filteredData}
        deleteChange={deleteChange}
        addedRow={addedRow}
        customColumnOrder={[]}
        columnsToHide={columnsToHide}
        setSelected={setSelected}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        setFilters={setFilters}
      />
    </div>
  );
};

export default AproveReturn;
