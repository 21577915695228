import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const getAvailabilityAsync = createAsyncThunk(
  'availability/getAvailabilityAsync',
  async ({ startDate, endDate, temporality, product, shift, workCenterList }) => {
    try {
      const res = await apiService.getAvailabilityByFilters(
        startDate,
        endDate,
        temporality,
        product,
        shift,
        workCenterList
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const availabilitySlice = createSlice({
  name: 'availability',
  initialState: {
    data: [],
    loading: false,
    error: null,
    progress: { pending: true, current: 0, total: 0, percent: 0 },
    status: 'idle',
    workCenterList: [],
    filters: {},
  },
  reducers: {
    getAvailability: (state, action) => {
      state.data = action.payload;
    },
    setDataAvailability: (state, action) => {
      state.data = action.payload;
    },
    setProgressAvailability: (state, action) => {
      if (action.payload.current >= state.progress?.current) {
        state.progress = action.payload;
      } else {
        state.progress = state.progress;
      }
    },
    setStatusAvailability: (state, action) => {
      state.status = action.payload;
      if (action.payload === 'SUCCESS') {
        state.progress = { ...state.progress, current: 0, percent: 100 };
      }
    },
    setAvailabilityWorkCenterList: (state, action) => {
      state.workCenterList = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailabilityAsync.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getAvailabilityAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAvailabilityAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const {
  getAvailability,
  setDataAvailability,
  setProgressAvailability,
  setStatusAvailability,
  setAvailabilityWorkCenterList,
  setFilters,
} = availabilitySlice.actions;
export const showAvailability = (state) => state.availability.data;
export const availabilityLoadingState = (state) => state.availability.loading;
export const availabilityErrorState = (state) => state.availability.error;

export default availabilitySlice.reducer;
