import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../../components/apiService';

export const fetchEventsByOrder = createAsyncThunk(
  'events/fetchEventsByOrder',
  async ({ page, pageSize, productionOrder, workCenter, filters }) => {
    try {
      const res = await apiService.getEventsByOrder(
        page,
        pageSize,
        productionOrder,
        workCenter,
        filters
      );
      const { results } = res.data || 0;
      if (results.length > 0) {
        // discard the fields the user will not visualize in tables
        const headersList = Object.keys(results[0]).filter((elem) => {
          let returnColumn;
          if (
            elem === 'id' ||
            elem === 'product_per_production_order' ||
            elem === 'event' ||
            elem === 'quality_report'
          ) {
            returnColumn = false;
          } else {
            returnColumn = true;
          }
          return returnColumn;
        });
        return {
          page,
          pageSize,
          total: res.data.count,
          data: res.data.results,
          headers: headersList,
        };
      }
      return {
        page,
        pageSize,
        total: res.data.count,
        data: res.data.results,
        headers: [],
      };
    } catch (err) {
      return err.message;
    }
  }
);

const initialState = {
  currentOrderEvents: {
    page: 1,
    pageSize: 10,
    total: 0,
    data: [],
    headers: [],
  },
  events: [],
  columnsToHide: [
    'event_category',
    'variable_description',
    'calculation_variable',
    'dimensional_unit_description',
    'work_center_description',
    'work_center_category',
    'work_center_status',
    'event_type_category',
    'created_by',
    'created_at',
    'event_type',
    'created_by',
    'created_at',
    'updated_by',
    'updated_at',
  ],
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEventsByOrder.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.currentOrderEvents.data = action.payload.data;
      state.currentOrderEvents.page = action.payload.page;
      state.currentOrderEvents.pageSize = action.payload.pageSize;
      state.currentOrderEvents.total = action.payload.total;
      state.currentOrderEvents.headers = action.payload.headers;
    });
  },
});

export const selectCurrentOrderEvents = (state) => state.events.currentOrderEvents.data;
export const selectEventHeaders = (state) => state.events.currentOrderEvents.headers;
export const selectEventTotalCount = (state) => state.events.currentOrderEvents.total;
export const eventColumnsToHide = (state) => state.events.columnsToHide;

export default eventsSlice.reducer;
