import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import TextField from '../FormComponents/TextField/TextField';

const DimensionalUnitField = ({ dimensionalUnitValue, dimensionalUnit }) => {
  const methods = useFormikContext();

  useEffect(() => {
    dimensionalUnitValue && methods.setFieldValue(dimensionalUnit.name, dimensionalUnitValue);
  }, [dimensionalUnitValue]);

  return (
    <TextField
      label={dimensionalUnit.label}
      name={dimensionalUnit.name}
      value={methods.values[dimensionalUnit.name]}
      disabled
    />
  );
};

export default DimensionalUnitField;
