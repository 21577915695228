import { createSlice } from '@reduxjs/toolkit';

const indicatorFilterSlice = createSlice({
  name: 'indicatorFilter',
  initialState: {
    see: '',
    selectedWorkCenter: [],
    from: '',
    to: '',
    filterList: [],
  },
  reducers: {
    setSeriesView: (state, action) => {
      state.see = action.payload;
    },
    setSelectedWorkCenter: (state, action) => {
      state.selectedWorkCenter = action.payload;
    },
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setFilters: (state, action) => {
      state.filterList = action.payload;
    },
  },
});

export const setSeriesByTime = (view) => (dispatch) => {
  dispatch(setSeriesView(view));
};
export const setSelectedWorkCenterData = (array) => (dispatch) => {
  dispatch(setSelectedWorkCenter(array));
};
export const setFromDate = (date) => (dispatch) => {
  dispatch(setFrom(date));
};
export const setToDate = (date) => (dispatch) => {
  dispatch(setTo(date));
};
export const setFilterList = (array) => (dispatch) => {
  dispatch(setFilters(array));
};

export const { setSeriesView, setSelectedWorkCenter, setFrom, setTo, setFilters } =
  indicatorFilterSlice.actions;
export const showSeriesView = (state) => state.indicatorFilter.see;
export const showSelectedWorkCenterData = (state) => state.indicatorFilter.selectedWorkCenter;
export const showFrom = (state) => state.indicatorFilter.from;
export const showTo = (state) => state.indicatorFilter.to;
export const showFilterList = (state) => state.indicatorFilter.filterList;

export default indicatorFilterSlice.reducer;
